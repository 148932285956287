<app-datatable [rows$]="bills$"
               [config]="config"
               (rowSelected)="goToOpenEditBill($event)">
  <app-datatable-column title="Folio">
    <ng-template let-row #column>
      {{ row.documentNumber }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Proveedor">
    <ng-template let-row #column>
      {{ row.provider }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Monto">
    <ng-template let-row #column>
      {{ row.total | clearCurrency | swapCommasAndDots }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Categoría">
    <ng-template let-row #column>
      {{ !!row.category ? (row.category | doc | async)?.name : 'No asignada'}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha">
    <ng-template let-row #column>
      {{ row.startDate | date : 'dd/MM/yyyy'}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="">
    <ng-template let-row #column>
      <span class="badge badge-info badge-pill" *ngIf="row.isPaid">Pagada</span>
      <span class="badge badge-primary badge-pill" *ngIf="!row.isPaid">No Pagada</span>
    </ng-template>
  </app-datatable-column>
</app-datatable>
