import {Injectable} from '@angular/core';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {Record} from '../../admin/interfaces/comment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  getAll(): Observable<Record[]> {
    return this.afs.collectionGroup<Record>(`comments`, ref => ref
      .where('trash', '==', false)
      .orderBy('date', 'desc'))
      .snapshotChanges()
      .pipe(map(actions => actions.map((a:any) => {
        return {
          ...a.payload.doc.data(),
          key: a.payload.doc.id,
          personKey: a.payload.doc.ref.parent.parent.id
        }
      })))
  }

  async add(applicantKey: any, comment: any): Promise<any> {
    return await this.afs.collection<Record>(`applicants/${applicantKey}/comments`).add(comment);
  }

  async addCommentUser(userKey: any, comment: any): Promise<any> {
    return await this.afs.collection<Record>(`users/${userKey}/comments`).add(comment);
  }

  async delete(applicantKey: string, commentKey: string): Promise<void> {
    return await this.afs.doc<Record>(`applicants/${applicantKey}/comments/${commentKey}`).update({trash: true});
  }

  update(applicantKey: any, commentKey: any, comment: any) {
    this.afs.doc(`applicants/${applicantKey}/comments/${commentKey}`).update(comment);
  }

  get(applicantKey: string, commentKey: string) {
    return this.afs.doc(`applicants/${applicantKey}/comments/${commentKey}`).valueChanges();
  }
}
