import {Component, OnInit} from '@angular/core';
import {Observable, of, Subscription} from 'rxjs';
import {User} from '../../../shared/interfaces/user';
import {UserType} from '../../enums/user-type.enum';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BoardService} from '../../services/board.service';
import {UserService} from '../../../shared/services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DocumentReference} from '@angular/fire/firestore';
import {Task} from '../kanban/interfaces/task';
import {KanbanModalComponent} from '../kanban/kanban-modal/kanban-modal.component';
import {map, take} from 'rxjs/operators';
import {KanbanService} from '../kanban/kanban.service';
import moment from 'moment';

@Component({
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.css']
})
export class TasksListComponent implements OnInit {
  tasks$: Observable<Task[]>;
  tasksFiltered$: Observable<Task[]>;
  tags = [];
  users: User[];
  usersSubscription: Subscription;
  userType: number;
  userTypeEnum = UserType;
  permission: number;
  config: DataTableConfig = {
    notFoundText: 'No se encontraron tareas completadas',
    title: 'Lista de tareas',
    hasSearch: true
  };
  boardKey: string;
  startDate: any = moment(new Date()).subtract(1, 'months');
  finalDate: any = new Date().getTime();

  constructor(private _modal: BsModalService,
              private _board: BoardService,
              private _user: UserService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private _kanban: KanbanService) {
    this.boardKey = this.activatedRoute.snapshot.params['boardKey'];
  }

  async ngOnInit() {
    if (!this._user.user.permissions.find(permission => permission.section == 'TAREAS')) return this.router.navigate(['admin/default']);
    this.tags = await this._kanban.getAllTags().pipe(take(1)).toPromise();
    this.tasks$ = this._board.getTasksCompleted(this.boardKey);

    this.usersSubscription = this._user.getAll()
      .pipe(
        map((users) => users.filter((user) => user.type != UserType.ADMIN)),
        map((users) => users.filter((user) => !user.isApplicant)),
        map((users) => users.filter((user) => !user.trash))
      )
      .subscribe(users => {
      this.tasks$.subscribe(tasks => {
        tasks = tasks.map(task => ({
          ...task,
          members: (<DocumentReference[]>task.members).map(member => users.find(user => user.key == member.id)),
          tags: (<DocumentReference[]>task.tags).map(tag => this.tags.find(tagItem => tagItem.key == tag.id)),
          isExpirated: task.expiratedDate < new Date().getTime()
        }));
        this.tasks$ = of(tasks);
        this.tasksFiltered$ = this.tasks$;
        this.filterTasks();
      });
    });
  }

  openTask(item) {
    this._modal.show(KanbanModalComponent, {
      initialState: {
        item,
        boardKey: this.boardKey
      },
      class: 'modal-lg'
    });
  }

  ngOnDestroy() {
    if (!!this.usersSubscription) this.usersSubscription.unsubscribe();
  }

  filterTasks() {
    const startDate = new Date(this.startDate).getTime();
    const finalDate = new Date(this.finalDate).getTime();

    this.tasksFiltered$ = this.tasks$.pipe(map(tasks => tasks.filter(task => startDate <= task.finishDate && task.finishDate <= finalDate)));
  }

  resetTasks() {
    return this.tasksFiltered$ = this.tasks$;
  }
}
