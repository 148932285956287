import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../shared/interfaces/user';
import { JobService } from '../../../shared/services/job.service';
import { Applicant } from '../../interfaces/applicant';

@Component({
  selector: 'app-recruitment-sheet',
  templateUrl: './recruitment-sheet.component.html',
  styleUrls: ['./recruitment-sheet.component.css']
})
export class RecruitmentSheetComponent implements OnInit {
  @Input() user: User;
  @Input() applicant: Applicant;

  constructor(private _job: JobService) { }

  async ngOnInit(): Promise<void> {
    this.user = {
      ...this.user,
      position: await this._job.get(this.user.position.reference.id)
    }
  }

}
