<app-datatable [rows$]="requests$"
               [config]="config">
  <app-datatable-column title="Bodeguero">
    <ng-template let-row #column>
      {{row?.user}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Email">
    <ng-template let-row #column>
      {{row.userEmail}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="ticketID">
    <ng-template let-row #column>
      {{row.ticketID | slice:0 : -3}}<span class="text-muted font-size-xsmall">#{{row?.ticketID | slice:-3 }}</span>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha">
    <ng-template let-row #column>
      {{row?.date | date: 'd/MM/yyyy'}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false" [isVisiblePrint]="false">
    <ng-template let-row #column>
      <ng-container *ngIf="!row.accept">
        <button class="btn btn-success btn-sm" (click)="ticketAuthorizationAdd(row)">Aceptar</button>
      </ng-container>
    </ng-template>
  </app-datatable-column>

</app-datatable>
