import { AfterViewInit, Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AjScreenRercoderComponent } from './lib/aj-screen-recoder.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AlertService } from '../../template-services/alert.service';
import { ReportService } from './report.service';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../template-services/auth.service';

@Component({
  selector: 'app-report-button',
  templateUrl: './report-button.component.html',
  styleUrls: ['./report-button.component.scss']
})
export class ReportButtonComponent implements OnInit, AfterViewInit {
  @ViewChild(AjScreenRercoderComponent) startRecordElm: AjScreenRercoderComponent;
  @BlockUI() blockUI: NgBlockUI;
  visiblePanel: boolean = true;
  recording: boolean = false;
  videoStreamUrl: any;
  video: any = null;
  message: string = '';
  @Output() closeReportPanel = new EventEmitter();

  constructor(private sanitizer: DomSanitizer,
    private _report: ReportService,
    private _user: UserService,
    private _auth: AuthService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.startRecordElm.eventEmitterStopRecord.subscribe(() => {
      this.stopRecord();
    });
  }

  startRecord() {
    this.visiblePanel = false;
    this.startRecordElm.startRecorder();
    this.recording = true;
  }

  stopRecord() {
    this.startRecordElm.stopRecording();
    this.recording = false;
    this.blockUI.start('Cargando video...');
    this.visiblePanel = false;

    setTimeout(() => {
      this.video = this.startRecordElm.getBlobObject();
      this.videoStreamUrl = this.sanitizer.bypassSecurityTrustUrl(this.startRecordElm.getVideoStreamURL());

      this.blockUI.stop();
      this.visiblePanel = true;
    }, 2000);
  }

  async deleteRecord() {
    this.visiblePanel = false;
    if (!await AlertService.confirm('¿Está seguro de eliminar el video?')) return this.visiblePanel = true;

    this.visiblePanel = true;
    this.startRecordElm.destroyRecorder();
    this.videoStreamUrl = null;
    this.video = null;
  }

  async sendReport() {
    const user = await this._user.get(this._auth.user.uid);

    this.visiblePanel = false;
    this.blockUI.start('Enviando reporte...');

    const formData = new FormData();
    formData.append('file', this.video);
    formData.append('fileType', 'video/webm');
    formData.append('fileExtension', 'webm');
    formData.append('userName', !!user.fullname ? user.fullname : 'N/A');
    formData.append('userKey', !!user.key ? user.key : 'N/A');
    formData.append('userEmail', !!user.email ? user.email : 'N/A');
    formData.append('message', this.message);

    this._report.sendReport(formData).subscribe(data => {
      AlertService.success('Reporte enviado', 'Trabajaremos en ello lo antes posible');
      this.blockUI.stop();
      this.reset();
    });
  }

  toggleMenu() {
    this.closeReportPanel.next();
  }

  reset() {
    this.visiblePanel = false;
    this.videoStreamUrl = null;
    this.video = null;
    this.message = '';
  }
}
