<div class="p-4">
  <h3 *ngIf="serialNumberCollected">Artículo {{article.name}} ({{article.internalId}})
    - {{serialNumberCollected.length}} artículo(s) faltante(s)</h3>
  <div class="form-group mt-3">
    <label>Cantidad a devolver <span class="required">*</span></label>
    <input [(ngModel)]="quantity"
           type="number"
           class="form-control">
  </div>

  <div class="form-group">
    <label>Comentarios <span class="required">*</span></label>
    <textarea [(ngModel)]="comments"
              type="text"
              class="form-control"
              [ngClass]="{'text-black-50': comments == 'Artículo en buen estado'}"></textarea>
  </div>

  <div class="form-group" *ngIf="article.serialNumber.length > 0">
    <label>Número de serie a regresar <span class="required">*</span></label>
    <ng-select [items]="selectedSerialNumbers"
               [multiple]="true"
               [searchable]="false"
               [(ngModel)]="serialNumberReturned"
               bindLabel="serialNumber"
               notFoundText=""
               [class.hide-arrow]="true"
               placeholder="Selecciones los números seriales">
    </ng-select>
  </div>

  <button class="btn btn-primary" (click)="finish()">Finalizar</button>
</div>
