import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountabilityReceipt } from '../../interfaces/accountability-receipt';
import { Observable, of, Subscription } from 'rxjs';
import { DataTableConfig } from '../../../../../shared/interfaces/data-table-config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BreakdownAmountCategoryService } from '../../services/breakdown-amount-category.service';
import { BreakdownAmountCategory } from '../../interfaces/breakdown-amount-catengory';
import { AlertService } from '../../../../../shared/template-services/alert.service';
import { Accountability } from '../../interfaces/accountability';
import { AccountabilityService } from '../../services/accountability.service';
import { UserType } from '../../../../enums/user-type.enum';
import { UserService } from '../../../../../shared/services/user.service';
import { AccountabilityReceiptStatusEnum } from '../../enums/accountability-receipt-status.enum';
import { AccountabilityReceiptStatusLabel } from '../../labels/accountability-receipt-status.label';
import { AccountabilityStatusEnum } from '../../enums/accountability-status.enum';
import { Permission } from '../../../../enums/permissions.enum';
import { FundToRender } from '../../interfaces/fund-to-render';
import { first, take } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReceiptService } from '../../services/receipt.service';
import { DocumentReference } from '@angular/fire/firestore';
import { Receipt } from '../../interfaces/receipt';
import { FundToRenderService } from 'src/app/shared/services/fund-to-render.service';
import { User } from 'src/app/shared/interfaces/user';
import { AccountabilityStatusLabel } from '../../labels/accountability-status.label';
import { UploadFileStorageComponent } from '../../../../../shared/template-components/upload-file-storage/upload-file-storage.component';
import { ReceiptComponent } from '../receipt/receipt.component';
import { LogsService } from '../../../../services/logs.service';
import { MatTab } from '@angular/material/tabs';
import { FundToRenderStatusEnum } from '../../enums/fund-to-render-status.enum';
import { AccountabilityStatusClasses } from '../../../../data/accountability-status-classes';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';

declare const $;

@Component({
  selector: 'app-accountability-receipts',
  templateUrl: './accountability-receipts.component.html',
  styleUrls: ['./accountability-receipts.component.css']
})
export class AccountabilityReceiptsComponent implements OnInit, OnDestroy {
  @ViewChild(UploadFileStorageComponent) filesStorage: UploadFileStorageComponent;
  @ViewChild('logTab') logTab: MatTab;
  accountabilityKey: string;
  receipts$: Observable<AccountabilityReceipt[]>;
  receiptsSubscription: Subscription = new Subscription();
  receipts: AccountabilityReceipt[] = [];
  fundToRender: FundToRender;
  breakdownAmountsCategories: BreakdownAmountCategory[] = [];
  accountability: Accountability;
  permission;
  permissionEnum: Permission;
  userTypeEnum = UserType;
  fundUser: User;
  accountabilityReceiptStatus = AccountabilityReceiptStatusEnum;
  accountabilityReceiptStatusLabel = AccountabilityReceiptStatusLabel;
  accountabilityStatus = AccountabilityStatusEnum;
  accountabilityStatusLabel = AccountabilityStatusLabel;
  accountabilitySubscription: Subscription = new Subscription();
  accountabilities: Accountability[] = [];
  totalReceiptsAmount: number = 0;
  finalBalance: number = 0;
  requiredAmount: number = 0;
  isImageLoaded: boolean;
  logTabIsActive: boolean;
  debtPaidReceiptUrl: string = '';

  dataTableConfig: DataTableConfig = {
    hasSearch: false,
    title: 'Lista de comprobantes',
    notFoundText: 'No se encontraron resultados'
  };

  accountabilityStatusClasses = AccountabilityStatusClasses;

  constructor(
    private _log: LogsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _receipt: ReceiptService,
    private _fundToRender: FundToRenderService,
    private _breakdownAmountCategory: BreakdownAmountCategoryService,
    private modal: BsModalService,
    private _accountability: AccountabilityService,
    private _user: UserService,
    private spinner: NgxSpinnerService,
    private http: HttpClient
  ) {
    this.accountabilityKey =
      this.activatedRoute.snapshot.paramMap.get('accountabilityKey');
    if (!this.accountabilityKey) {
      this.router.navigateByUrl('/admin/accountabilities');
    }
  }

  async ngOnInit() {
    this.permission = this._user.getPermission('FONDOS POR RENDIR');
    await this.loadBreakdownAmountCategories();
    this.loadAccountability();
    let allFundsToRender = await this._fundToRender
      .getAll()
      .pipe(take(1))
      .toPromise();
    this.fundToRender = allFundsToRender.find(
      (fund) => fund.key == this.accountability.fundToRender.id
    );
    await this.getFundToRenderUser();
  }

  ngOnDestroy() {
    this.receiptsSubscription.unsubscribe();
    this.accountabilitySubscription.unsubscribe();
  }

  async getFundToRenderUser() {
    this.fundUser = await this._user.getSpecificUser(
      this.fundToRender.user['id']
    );
  }

  async loadAccountability() {
    this.accountabilitySubscription = this._accountability
      .get(this.accountabilityKey)
      .subscribe((accountability) => {
        this.accountability = accountability;
        this.dataChecker();
      });
  }

  dataChecker() {
    if (!this.accountability) return;
    this.receiptsSubscription.unsubscribe();
    this.listenForReceipts();
  }

  listenForReceipts() {
    this.receiptsSubscription = this._receipt.getAll().subscribe((receipts) => {
      this.receipts = this.filterReceiptsInAccountability(receipts).map(
        (receipt) => ({
          ...receipt,
          category: this.breakdownAmountsCategories.find(
            (category) =>
              category.key == (<DocumentReference>receipt.category).id
          )
        })
      );
      this.calculateAmounts();
      this.receipts$ = of(this.receipts);
    });
  }

  filterReceiptsInAccountability(receipts: Receipt[]) {
    if (!this.accountability) return [];
    return receipts.filter((receipt) =>
      this.accountability.receipts[receipt.key]
    );
  }

  validateAccountabilityStatus(accountability: Accountability, statusExpected: AccountabilityStatusEnum) {
    return !!accountability && accountability.status == statusExpected;
  }

  statusClasses = {
    [AccountabilityReceiptStatusEnum.ACCEPTED]: 'badge-success',
    [AccountabilityReceiptStatusEnum.REJECTED]: 'badge-danger',
    [AccountabilityReceiptStatusEnum.PENDING]: 'badge-warning',
    [AccountabilityReceiptStatusEnum.CORRECTION]: 'badge-info'
  };

  openAddReceipt() {
    const modalRef = this.modal.show(ReceiptComponent, {
      backdrop: 'static',
      initialState: {
        categories: this.breakdownAmountsCategories,
        isModal: true
      }
    });

    modalRef.onHide.subscribe(async () => {
      if (!modalRef.content.newReceipt) return;
      const newReceipt = modalRef.content.newReceipt;

      if (!newReceipt) return;
      await this._receipt.updateReceipt(newReceipt.id, {
        isUsed: true
      } as Receipt);

      await this._accountability.update(this.accountabilityKey, {
        receipts: {
          ...this.accountability.receipts,
          [newReceipt.id]: true
        }
      } as Accountability);
      this.spinner.hide();
    });
  }

  openEditReceipt(accountabilityReceipt: Receipt) {
    this.modal.show(ReceiptComponent, {
      backdrop: 'static',
      initialState: {
        isEdit: true,
        receipt: accountabilityReceipt,
        categories: this.breakdownAmountsCategories,
        receiptKey: this.accountabilityKey
      }
    });
  }

  async loadBreakdownAmountCategories() {
    this.breakdownAmountsCategories = await this._breakdownAmountCategory
      .getAll()
      .pipe(first())
      .toPromise();
  }

  async deleteReceipt({ key: receiptKey }: AccountabilityReceipt) {
    if (!receiptKey || !this.accountability) return;
    if (
      !(await AlertService.confirm(
        'Eliminar comprobante',
        '¿Estás seguro de eliminar este comprobante?'
      ))
    ) {
      return;
    }

    if (!this.accountability.receipts[receiptKey]) {
      return AlertService.toastError('No se puede eliminar el comprobante');
    }

    this.accountability.receipts[receiptKey] = false;

    await this._accountability.update(
      this.accountabilityKey,
      { receipts: this.accountability.receipts } as Accountability
    );

    await this._receipt.updateReceipt(receiptKey, {
      trash: true
    } as Receipt);

    AlertService.toastSuccess('Comprobante eliminado');
  }

  validateUserPermission(permissionExpected) {
    return this.permission == permissionExpected || this.permission == Permission.ADMIN;
  }

  async sendAccountabilityToReview() {
    if (
      !(await AlertService.confirm(
        `Enviar la rendición a revisión?`,
        '¿Está seguro de que desea enviar a aprobación la rendición?',
        'Enviar a aprobación'
      ))
    ) {
      return;
    }
    this._accountability.update(this.accountabilityKey, {
      status: this.accountabilityStatus.REVIEW
    } as Accountability);

    AlertService.toastSuccess(`La rendición se ha enviado a revisión`);
    this.modal.hide();
  }

  async rejectAccountabilitySupervisor() {
    let [rejectReason] = await AlertService.withHtml(
      'Rendición',
      `<label>Razón del rechazo:</label>
      <textarea id="swal-input1" rows="3" class="swal2-input"></textarea>`,
      function() {
        return new Promise(function(resolve) {
          resolve([$('#swal-input1').val()]);
        });
      }
    );

    if (!rejectReason.trim()) {
      return AlertService.toastError(
        'Debe ingresar una razón para rechazar la rendición'
      );
    }

    this._accountability.update(this.accountabilityKey, {
      status: this.accountabilityStatus.GENERATED,
      rejectReason: rejectReason.trim()
    } as Accountability);

    AlertService.toastSuccess(`La rendición se ha rechazado`);
    this.modal.hide();
  }

  async sendToSupervisor() {
    if (
      !(await AlertService.confirm(
        `Enviar la rendición a supervisión?`,
        '¿Está seguro de que desea enviar la supervisión al supervisor?',
        'Enviar al supervisor'
      ))
    ) {
      return;
    }
    this._accountability.update(this.accountabilityKey, {
      status: this.accountabilityStatus.SUPERVISION
    } as Accountability);

    AlertService.toastSuccess(`La rendición se ha enviado al supervisor`);
    this.modal.hide();
  }

  userSendReceiptSupervision() {
    return (
      this.permission == this.userTypeEnum.USER &&
      !!this.accountability &&
      this.accountability.status == this.accountabilityStatus.GENERATED &&
      !!this.receipts.length
    );
  }

  supervisorReceiptsChecked() {
    return (
      !!this.receipts.length &&
      this.permission == this.userTypeEnum.SUPERVISOR &&
      !!this.accountability &&
      this.accountability.status == this.accountabilityStatus.SUPERVISION &&
      !this.receipts.some(
        (receipt) => receipt.status == AccountabilityReceiptStatusEnum.PENDING
      )
    );
  }

  isOnSupervision() {
    return ((this.permission == this.userTypeEnum.SUPERVISOR || this.permission == this.userTypeEnum.ADMIN)
      && this.accountability.status == this.accountabilityStatus.SUPERVISION
    );
  }

  async acceptReceiptSupervisor(receipt) {
    if (
      !(await AlertService.confirm(
        '¿Está seguro de que desea aceptar este comprobante?'
      ))
    ) {
      return;
    }
    this.spinner.show();

    await this._receipt.updateReceipt(receipt.key, {
      status: this.accountabilityReceiptStatus.ACCEPTED
    } as Receipt);

    this.spinner.hide();
  }

  async rejectReceiptSupervisor(receipt) {
    if (
      !(await AlertService.confirm(
        '¿Está seguro de que desea rechazar este comprobante?'
      ))
    ) {
      return;
    }

    const correctionReject = await AlertService.confirm(
      'Rechazar',
      '¿Desea que el usuario tenga la posibilidad de corregir el comprobante?', 'Enviar a corrección', 'Cancelar definitivamente'
    );

    if (correctionReject) {
      let rejectReason = await AlertService.withHtml(
        'Rechazo de comprobante',
        `<label>Razón del rechazo:</label>
        <textarea id="swal-input1" rows="3" class="swal2-input"></textarea>`,
        function() {
          return new Promise(function(resolve) {
            resolve($('#swal-input1').val());
          });
        }
      );

      if (!rejectReason || !rejectReason.trim()) {
        return AlertService.toastError(
          'Debe ingresar una razón para rechazar el comprobante'
        );
      }
      this.spinner.show();

      const receiptReference = this._receipt.getReference(`receipts/${receipt.key}`);

      const receiptLogRef = await this._log.addReceiptLog(
        `El comprobante "${receipt.description}" ha sido rechazado por el supervisor con posibilidad de corrección: ${rejectReason.trim()}`,
        receipt.user,
        receiptReference
      );
      if (!receiptLogRef) {
        this.spinner.hide();
        return AlertService.toastError(
          'No se pudo registrar el rechazo del comprobante'
        );
      }
    }

    await this._receipt.updateReceipt(receipt.key, {
      status: correctionReject ? this.accountabilityReceiptStatus.CORRECTION : this.accountabilityReceiptStatus.REJECTED
    } as Receipt);

    this.spinner.hide();
  }

  async reject() {
    let rejectReason = await AlertService.withHtml(
      'Rechazo de rendición',
      `<label>Razón del rechazo:</label>
      <textarea id="swal-input1" rows="3" class="swal2-input"></textarea>`,
      function() {
        return new Promise(function(resolve) {
          resolve($('#swal-input1').val());
        });
      }
    );

    if (!rejectReason.trim()) {
      return AlertService.toastError(
        'Debe ingresar una razón para rechazar la rendición'
      );
    }

    this._accountability.update(this.accountabilityKey, {
      status: this.accountabilityStatus.REJECTED,
      rejectReason: rejectReason.trim()
    } as Accountability);

    this.spinner.hide();
    AlertService.toastSuccess('Rendición rechazada');
    this.router.navigateByUrl('/admin/accountabilities');
  }

  async authorize() {
    if (
      !(await AlertService.confirm(
        'Autorizar rendición',
        '¿Está seguro de autorizar esta rendición?'
      ))
    ) {
      return;
    }

    this.spinner.show();

    const userReference = this._user.getReference(this._user.user.key);

    let accountabilityStatus;
    let fundToRenderStatus;
    if (this.finalBalance < 0) {
      accountabilityStatus = this.accountabilityStatus.CLOSED_USER;
      fundToRenderStatus = FundToRenderStatusEnum.CLOSED_USER;
    }

    if (this.finalBalance > 0) {
      accountabilityStatus = this.accountabilityStatus.CLOSED_COLSAN;
      fundToRenderStatus = FundToRenderStatusEnum.CLOSED_COLSAN;

      if (!(this.fundToRender.user as DocumentReference).id && !(this.fundToRender.user as User).key) {
        this.spinner.hide();
        return AlertService.toastError('No se encontró el usuario para la rendición');
      }

      const userKey = (this.fundToRender.user as DocumentReference).id ?? (this.fundToRender.user as User).key;

      await this._user.update(userKey, {
        pendingAccountability: Math.abs(
          this.finalBalance
        )
      } as User);
    }

    if (this.finalBalance == 0) {
      accountabilityStatus = this.accountabilityStatus.CLOSED;
      fundToRenderStatus = FundToRenderStatusEnum.CLOSED;
    }

    await this._fundToRender.update(this.fundToRender.key, {
      status: fundToRenderStatus
    } as FundToRender);

    await this._accountability.update(this.accountabilityKey, {
      status: accountabilityStatus,
      closedBy: userReference
    } as Accountability);

    AlertService.toastSuccess('Rendición autorizada');
    this.spinner.hide();
    this.router.navigateByUrl('/admin/accountabilities');
  }

  calculateReceiptsTotalAmount() {
    if (!this.receipts.length) return;
    this.totalReceiptsAmount = this.receipts.reduce(
      (acc, { amount }) => acc + amount,
      0
    );
  }

  calculateFinalBalance() {
    if (!this.receipts.length || !this.fundToRender) return;
    this.finalBalance = this.fundToRender.receivedAmount - this.totalReceiptsAmount;
  }

  calculateRequiredAmount() {
    if (!this.fundToRender) return;
    this.requiredAmount = this.fundToRender.breakdownAmounts.reduce(
      (acc, { amount }) => acc + amount,
      0
    );
  }

  calculateAmounts() {
    this.calculateRequiredAmount();
    this.calculateReceiptsTotalAmount();
    this.calculateFinalBalance();
  }

  handleImageLoaded(e) {
    this.isImageLoaded = e;

    this.uploadDebtReceipt();
  }

  async uploadDebtReceipt() {
    if (this.isImageLoaded) {
      const title = 'Subir foto de comprobante';
      const text = 'Al aceptar la imagen del comprobante será adjuntada a la rendición, su estatus pasará a pagado y se cerrará el fondo por rendir asociado a esta rendición';

      if (!(await AlertService.confirm(title, text))) return;

      this.spinner.show();

      const url = await this.filesStorage.uploadDocument(
        'accountability-receipt',
        this.accountability.key
      );

      if (!url) {
        this.spinner.hide();
        return AlertService.toastError('Error al subir el comprobante');
      }
      const accountabilityStatus = this.accountability.status;

      await this._accountability.update(this.accountability.key, {
        paidReceiptUrl: url,
        status: accountabilityStatus == AccountabilityStatusEnum.CLOSED_USER ? AccountabilityStatusEnum.CLOSED : AccountabilityStatusEnum.PAID_COLSAN
      } as Accountability);

      await this._fundToRender.update(this.fundToRender.key, {
        status: accountabilityStatus == AccountabilityStatusEnum.CLOSED_USER ? FundToRenderStatusEnum.CLOSED : FundToRenderStatusEnum.PAID_COLSAN
      } as FundToRender);

      this.spinner.hide();
      AlertService.toastSuccess('Comprobante subido correctamente');
    }
  }

  setLogTabIsActive() {
    if (!this.validateUserPermission(this.userTypeEnum.USER)) return;
    this.logTabIsActive = this.logTab.isActive;
  }

  validateEditButtonsDisplay(receipt: Receipt) {
    return (this.validateUserPermission(this.userTypeEnum.USER) && this.validateAccountabilityStatus(
        this.accountability,
        this.accountabilityStatus.SUPERVISION) &&
      (
        receipt.status == this.accountabilityReceiptStatus.PENDING ||
        receipt.status == this.accountabilityReceiptStatus.CORRECTION
      ));
  }

  setPath(path: string) {
    this.debtPaidReceiptUrl = path;
  }

  handleUploadReceiptButtonClick() {
    this.filesStorage.inputFile.nativeElement.click();
  }

  downloadImage(photoUrl: string) {
    try {
      this.spinner.show();

      this.http.post(`${environment.apiBaseURLV1}/storage`, {
        'url': photoUrl,
        'type': 'pdf'
      }).subscribe(({ data }: any) => {
        this.spinner.hide();
        window.open(data.url);
      });
    } catch (e) {
      this.spinner.hide();
      console.log(e);
    }
  }

  async changeFinalBalance() {
    const finalBalance = await AlertService.input('Nuevo saldo final', 'Ingrese el nuevo saldo final', 'Guardar');
    const finalBalanceNumber = Number(finalBalance.replaceAll(',', '').replaceAll('.', ''));

    await this._accountability.update(this.accountability.key, {
      finalBalance: finalBalanceNumber
    } as Accountability);

    AlertService.toastSuccess('Saldo final actualizado');
  }

  async closeAccountability() {
    if (await AlertService.confirm('Cerrar rendición', '¿Está seguro que desea cerrar la rendición?')) {
      this.spinner.show();

      await this._accountability.update(this.accountability.key, {
        status: this.accountabilityStatus.CLOSED
      } as Accountability);

      await this._fundToRender.update(this.fundToRender.key, {
        status: FundToRenderStatusEnum.CLOSED
      } as FundToRender);

      this.spinner.hide();
      AlertService.toastSuccess('Rendición cerrada');
    }
  }
}
