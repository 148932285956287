import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Article } from '../../../interfaces/article';
import { Warehouse } from '../../../interfaces/warehouse';
import { TicketService } from '../../../../../../shared/services/ticket.service';
import { WarehouseService } from '../../../../../../shared/services/warehouse.service';
import { TicketStatus } from '../../../enums/ticket-status.enum';
import { formatDate } from '@angular/common';
import moment from 'moment';
import * as _ from 'lodash';
import { WarehouseDetailService } from '../../../services/warehouse-detail.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { delay } from 'rxjs/operators';
import { DataTableConfig } from '../../../../../../shared/interfaces/data-table-config';
import { of } from 'rxjs';

@Component({
  selector: 'app-warehouse-summary',
  templateUrl: './warehouse-summary.component.html',
  styleUrls: ['./warehouse-summary.component.css']
})
export class WarehouseSummaryComponent implements OnInit, OnDestroy {
  @Input() articles: Article[] = [];
  @Input() articlesConsumable: Article[] = [];
  @Input() warehouse: Warehouse;
  hitRateOptions = {
    bodyClass: ['bg-hexagons', 'pt-0'],
    headerClass: ['bg-hexagons'],
    cardClass: [''],
    close: false,
    expand: false,
    minimize: true
  };
  donutChart2: any;
  donutChart3: any;
  totalRetired: number = 0;
  totalStored: number = 0;
  totalArticles: number = 0;
  tickets = {
    generated: 0,
    authorized: 0,
    retired: 0,
    incomplete: 0,
    returned: 0
  };

  totalRetiredConsumable: number = 0;
  totalStoredConsumable: number = 0;
  totalArticlesConsumable: number = 0;

  warehouseReference: any;
  allTickets: any[] = [];
  startDate = null;
  finalDate = null;
  config: DataTableConfig = {
    title: 'Artículos',
    notFoundText: 'No se encontraron artículos en este rango de fechas',
    hasSearch: true
  };
  mostRetiredArticles = [];

  chartLabels;
  chartData;
  chartType = 'horizontalBar';
  chartOptions = {
    responsive: true,
    legend: false
  };

  dataLoadedSubscription: Subscription = new Subscription();

  dateSelected;

  dateRangeTypeSelected;

  dateRangeType = {
    WEEKLY: 0,
    MONTHLY: 1,
    YEARLY: 2
  };
  years: string[] = [];

  momentUnitOfTime: any[] = ['W', 'month', 'y'];

  constructor(
    private _ticket: TicketService,
    private _warehouse: WarehouseService,
    private _warehouseDetail: WarehouseDetailService
  ) {
  }

  async ngOnInit() {
    if (!this.warehouse) {
      await of().pipe(delay(2000)).toPromise();

      this.articles = this._warehouseDetail.articles;
      this.articlesConsumable = this._warehouseDetail.articlesConsumable;
      this.warehouse = this._warehouseDetail.warehouse;
    }
    this.years = this.getYears();
    this.dateRangeTypeSelected = this.dateRangeType.MONTHLY;
    this.handleDateRangeTypeSelected();
    const date = new Date(
      `${new Date().getMonth() + 1}/01/${new Date().getFullYear()}`
    );
    this.startDate = this.formatDate(moment(date));
    this.finalDate = this.formatDate(moment(date).add(1, 'month').toDate());
    this.calculateTotalTickets();
    this.calculateTotals();
  }

  ngOnDestroy() {
    this.dataLoadedSubscription.unsubscribe();
  }

  private async calculateTotalTickets() {
    this._ticket
      .getAll(this._warehouse.getReference(this.warehouse.key))
      .subscribe((tickets) => {
        this.tickets = {
          generated: 0,
          authorized: 0,
          retired: 0,
          incomplete: 0,
          returned: 0
        };

        tickets.forEach((ticket) => {
          switch (ticket.status) {
            case TicketStatus.GENERATED:
              this.tickets.generated++;
              break;
            case TicketStatus.AUTHORIZED:
              this.tickets.authorized++;
              break;
            case TicketStatus.COLLECTED:
              this.tickets.retired++;
              break;
            case TicketStatus.INCOMPLETE:
              this.tickets.incomplete++;
              break;
            case TicketStatus.RETURNED:
              this.tickets.returned++;
              break;
          }
        });
        if (tickets.length > 0) {
          this.allTickets = _.orderBy(tickets, 'createdDate', 'desc');
          this.filterMostRetiredArticlesByDate();
        }
      });
  }

  isTicketWithArticlesRetired(ticketStatus): Boolean {
    return (
      ticketStatus == TicketStatus.COLLECTED ||
      ticketStatus == TicketStatus.INCOMPLETE ||
      ticketStatus == TicketStatus.RETURNED
    );
  }

  private async calculateTotals() {
    this.donutChart2 = null;
    this.totalStored = 0;
    this.totalArticles = 0;
    this.totalRetired = 0;

    this.totalStoredConsumable = 0;
    this.totalArticlesConsumable = 0;
    this.totalRetiredConsumable = 0;

    this.articles.forEach((article) => {
      if (isNaN(+article.price)) article.price = 0;
      if (isNaN(+article.quantity)) article.quantity = 0;
      if (isNaN(+article.totalQuantity)) article.totalQuantity = 0;

      this.totalStored += +article.quantity * +article.price;
      this.totalArticles += +article.totalQuantity * +article.price;
      this.totalRetired +=
        (+article.totalQuantity - +article.quantity) * +article.price;
    });

    this.articlesConsumable.forEach((article) => {
      if (isNaN(+article.price)) article.price = 0;
      if (isNaN(+article.quantity)) article.quantity = 0;
      if (isNaN(+article.totalQuantity)) article.totalQuantity = 0;

      this.totalStoredConsumable += +article.quantity * +article.price;
      this.totalArticlesConsumable += +article.totalQuantity * +article.price;
      this.totalRetiredConsumable +=
        (+article.totalQuantity - +article.quantity) * +article.price;
    });

    await this._warehouse.update(this.warehouse.key, {
      totalStored: this.totalStored + this.totalStoredConsumable,
      totalRetired: this.totalRetired + this.totalRetiredConsumable
    } as Warehouse);

    this.getChartdata();
    this.getChartdataConsumable();
  }

  getChartdata() {
    const Chartdata = {
      donut1: {
        series: [this.totalStored, this.totalArticlesConsumable]
      }
    };
    // Doughnut
    this.donutChart2 = {
      type: 'Pie',
      data: Chartdata['donut1'],
      options: {
        chartPadding: 0,
        fullwidth: true,
        height: '200px',
        donut: true,
        showLabel: true,
        startAngle: 0,
        labelInterpolationFnc: (value) => {
          if (this.totalArticles == 0) {
            return '0%';
          }
          const total = (
            (this.totalStored * 100) /
            (this.totalArticles + this.totalArticlesConsumable)
          )
            .toString()
            .slice(0, 5);
          return total + '%';
        }
      },
      events: {
        draw(dataLoad: any): void {
          if (dataLoad.type == 'label') {
            if (dataLoad.index == 0) {
              dataLoad.element.attr({
                dx: dataLoad.element.root().width() / 2,
                dy: dataLoad.element.root().height() / 2
              });
            } else {
              dataLoad.element.remove();
            }
          }
        }
      }
    };
  }

  private formatDate(date) {
    const deliveryDateDate = new Date(date);
    const format = 'yyyy/MM/dd';
    const locale = 'en-US';
    const zone = 'UTC';
    const formattedDate = formatDate(deliveryDateDate, format, locale, zone);
    return formattedDate.replace(/\//g, '-');
  }

  filterMostRetiredArticlesByDate() {
    if (this.allTickets.length == 0) {
      return;
    }
    let filteredTickets = this.allTickets.filter((ticket) => {
      return moment(ticket.createdDate).isBetween(
        moment(this.startDate),
        moment(this.finalDate)
      );
    });
    let retiredArticles = this.getAllRetiredArticles(filteredTickets);
    let retiredArticlesGrouped = _.groupBy(retiredArticles, 'key');
    retiredArticles = this.fetchRetiredArticles(retiredArticlesGrouped);

    if (retiredArticles.length == 0) {
      return (this.mostRetiredArticles = []);
    }
    retiredArticles = _.orderBy(retiredArticles, 'quantity', 'desc');
    this.mostRetiredArticles = [...retiredArticles];
    this.assignChartData();
  }

  assignChartData() {
    this.chartData = [];
    this.chartLabels = [];
    this.mostRetiredArticles.forEach((retiredArticle, index) => {
      if (index <= 9) {
        this.chartData = [...this.chartData, retiredArticle.quantity];
        this.chartLabels = [...this.chartLabels, retiredArticle.name];
      }
    });
  }

  fetchRetiredArticles(retiredArticlesGrouped) {
    let retiredArticles = [];

    for (const articleKey in retiredArticlesGrouped) {
      retiredArticles = [
        ...retiredArticles,
        {
          key: articleKey,
          name: retiredArticlesGrouped[articleKey][0].name,
          quantity: this.getQuantityRetiredArticles(
            retiredArticlesGrouped[articleKey]
          )
        }
      ];
    }
    return retiredArticles;
  }

  getQuantityRetiredArticles(articles) {
    return articles.reduce((acc, el) => acc + el.quantity, 0);
  }

  getAllRetiredArticles(tickets) {
    return tickets
      .filter((ticket) => this.isTicketWithArticlesRetired(ticket.status))
      .map((ticket) => ticket.articles)
      .reduce((acc, el) => [...acc, ...el], []);
  }

  getChartdataConsumable() {
    const Chartdata = {
      donut2: {
        series: [this.totalStoredConsumable, this.totalRetiredConsumable]
      }
    };
    // Doughnut
    this.donutChart3 = {
      type: 'Pie',
      dataLoad: Chartdata['donut2'],
      options: {
        chartPadding: 0,
        fullwidth: true,
        height: '200px',
        donut: true,
        showLabel: true,
        startAngle: 0,
        labelInterpolationFnc: (value) => {
          if (this.totalArticlesConsumable == 0) {
            return '0%';
          }
          const total = (
            (this.totalStoredConsumable * 100) /
            this.totalArticlesConsumable
          )
            .toString()
            .slice(0, 5);
          return total + '%';
        }
      },
      events: {
        draw(dataLoad: any): void {
          if (dataLoad.type == 'label') {
            if (dataLoad.index == 0) {
              dataLoad.element.attr({
                dx: dataLoad.element.root().width() / 2,
                dy: dataLoad.element.root().height() / 2
              });
            } else {
              dataLoad.element.remove();
            }
          }
        }
      }
    };
  }

  handleDateRangeTypeSelected() {
    if (this.validateDateRangeTypeSelected(this.dateRangeType.MONTHLY)) {
      this.dateSelected = moment().format('yyyy-MM');
    } else if (this.validateDateRangeTypeSelected(this.dateRangeType.WEEKLY)) {
      this.dateSelected = `${moment().format(`yyyy`)}-W${
        +moment().format('W') < 9 ? '0' : ''
      }${moment().format('W')}`;
    } else if (this.validateDateRangeTypeSelected(this.dateRangeType.YEARLY)) {
      this.dateSelected = this.years[0];
    }
    this.assignStartDateAndFinalDate();
    this.filterMostRetiredArticlesByDate();
  }

  getYears(): string[] {
    const yearsLength: number = moment(`${moment().format('yyyy')}`).diff(
      moment('2020'),
      'y'
    );
    let years = ['2020'];
    for (let i = 1; i <= yearsLength; i++) {
      years = [moment('2020').add(i, 'y').format('yyyy').toString(), ...years];
    }
    return years;
  }

  assignStartDateAndFinalDate() {
    this.startDate = moment(this.dateSelected)
      .startOf(this.momentUnitOfTime[this.dateRangeTypeSelected])
      .toDate();
    this.finalDate = moment(this.dateSelected)
      .endOf(this.momentUnitOfTime[this.dateRangeTypeSelected])
      .toDate();
  }

  validateDateRangeTypeSelected(expectedDateRangeType: number) {
    return this.dateRangeTypeSelected == expectedDateRangeType;
  }
}
