import { Component, OnInit } from '@angular/core';
import { BillDataService } from '../../services/bill-data.service';

@Component({
  selector: 'app-bill-pdf',
  templateUrl: './bill-pdf.component.html',
  styleUrls: ['./bill-pdf.component.css']
})
export class BillPdfComponent implements OnInit {

  constructor(public _billData: BillDataService) {
  }

  ngOnInit(): void {
  }

}
