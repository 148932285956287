import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PurchaseStatus} from '../../../../../../enums/purchase-status.enum';
import {AlertService} from '../../../../../../../shared/template-services/alert.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {PurchaseOrderService} from '../../../../../../../shared/services/purchase-order.service';
import {ProviderService} from '../../../../../../../shared/services/provider.service';
import {ProjectService} from '../../../../../../../shared/services/project.service';
import {LogsService} from '../../../../../../services/logs.service';
import {PurchaseOrder} from '../../../../interfaces/purchase-order';
import {ValidationService} from '../../../../../../../shared/template-services/validation.service';
import {UploadFileStorageComponent} from '../../../../../../../shared/template-components/upload-file-storage/upload-file-storage.component';
import {ProviderArticle} from '../../../../../../interfaces/provider-article';
import {ProviderArticleService} from '../../../../../../services/provider-article.service';
import {DomSanitizer} from '@angular/platform-browser';
import {FirebaseDataService} from '../../../../../../../shared/template-services/firebase-data.service';
import {UserService} from '../../../../../../../shared/services/user.service';

@Component({
  selector: 'app-draft-purchase-order',
  templateUrl: './draft-purchase-order.component.html',
  styleUrls: ['./draft-purchase-order.component.css']
})
export class DraftPurchaseOrderComponent implements OnInit {
  @ViewChild(UploadFileStorageComponent) filesStorage: UploadFileStorageComponent;
  @Input() purchaseOrder: PurchaseOrder = {key: ''} as PurchaseOrder;
  @Output() purchaseOrderCreated: EventEmitter<any> = new EventEmitter<any>();
  @Output() articlesPurchaseEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  deliveryDate: any = new Date();
  articlesPurchase: any;
  purchaseStatus = PurchaseStatus;
  provider: any;
  dates: any;
  submitted: boolean = false;
  quotation = null;
  currentDate = new Date().getTime();
  isCreatePurchaseOrder: boolean = true;
  quotationNumber: string;
  loading: boolean = false;
  passBudget: boolean = false;
  isPdf: boolean = true;
  url: any;
  finalBudget: number;
  description: string = '';
  bussinessConditions: string = '';

  constructor(private SpinnerService: NgxSpinnerService,
              private _purchase: PurchaseOrderService,
              private _provider: ProviderService,
              private _project: ProjectService,
              private _log: LogsService,
              private _providerArticle: ProviderArticleService,
              private sanitizer: DomSanitizer,
              private _firebase: FirebaseDataService,
              private _user: UserService) {
  }

  ngOnInit(): void {
    this.provider = {
      provider: null,
      project: null,
      budget: null
    };

    this.isCreatePurchaseOrder = !this.purchaseOrder.status && this.purchaseOrder.status != 0;
    this.passBudget = true;

    if (!this.isCreatePurchaseOrder) {
      this.passBudget = false;
      this.provider = {
        provider: this.purchaseOrder.provider,
        project: this.purchaseOrder.project,
        budget: {reference: this.purchaseOrder.budget}
      };

      if (!!this.purchaseOrder.description) this.description = this.purchaseOrder.description;
      if (!!this.purchaseOrder.bussinessConditions) this.bussinessConditions = this.purchaseOrder.bussinessConditions;

      this.articlesPurchase = {
        articles: this.purchaseOrder.articles,
        subtotal: this.purchaseOrder.subtotal,
        discount: this.purchaseOrder.discount,
        net: this.purchaseOrder.net,
        iva: this.purchaseOrder.iva,
        total: this.purchaseOrder.total,
        paymentType: this.purchaseOrder.paymentType,
        currency: this.purchaseOrder.currency
      };

      this.deliveryDate = this.purchaseOrder.deliveryDate;

      this.quotationNumber = this.purchaseOrder.quotationNumber;
      this.quotation = this.purchaseOrder.quotation;
      this.getUrlPdf(this.quotation);
    }
  }

  getDeliveryDate(deliveryDate: any) {
    this.dates = deliveryDate;
  }

  async getProviderForm(providerForm: any) {
    this.provider = providerForm;
    await this.validateBudget();
  }

  async getArticlesPurchaseForm(articlesPurchaseForm: any) {
    this.articlesPurchase = articlesPurchaseForm;
    await this.validateBudget();
  }

  getUrlPdf(url: any) {
    this.isPdf = url.includes('pdf');
    if (!this.isPdf) {
      return this.url = url;
    }
    this.url = this.sanitizer.bypassSecurityTrustHtml(`<iframe width="100%" height="915" src="${url}"></iframe>`);
  }

  validateBudget() {
    if (this.articlesPurchase && !!this.provider.budget && !!this.provider.project.budgets && !!this.provider.provider) {
      let budget = this.provider.project.budgets.find(budget => budget.reference == this.provider.budget.reference);
      this.finalBudget = (budget.totalBudget - budget.usedBudget) - this.articlesPurchase.total;
      return this.passBudget = false;
    }

    return this.passBudget = true;
  }

  async addPurchase(isNew) {
    let purchaseId;

    if (this.isCreatePurchaseOrder) {
      if (!this.filesStorage.isInputValid) return AlertService.toastError('Debe agregar una cotización');
      if (!this.quotationNumber) return AlertService.toastError('Debe de agregar el número de la cotización');
      if (!ValidationService.isObjectValid(this.provider)) return AlertService.toastError('Debe agregar Proveedor y Centro de costos');
      if (!ValidationService.isObjectValid(this.articlesPurchase) || !this.description) return AlertService.toastError('Debe completar todos los campos');
      if (!this.provider.provider.key || !this.provider.project.key) return AlertService.toastError('Debe agregar un Proveedor y Centro de costos');

      this.loading = true;
      this.purchaseOrder.key = this._purchase.createId();
      this.SpinnerService.show();
      purchaseId = await this.getPurchaseID();
      this.SpinnerService.hide();
    }

    this.submitted = true;

    if (this.filesStorage.isInputValid) {
      isNew ? this.quotation = await this.filesStorage.uploadDocument('purchaseOrders', purchaseId) : this.quotation = await this.filesStorage.uploadDocument('purchaseOrders', this.purchaseOrder.purchaseID);
    }

    const purchaseOrderKey = isNew ? this._firebase.createID() : this.purchaseOrder.key;

    for (let articleItem of this.articlesPurchase.articles) {
      let article: ProviderArticle = {
        name: articleItem.description,
        provider: this._provider.getReference(this.provider.provider.key),
        unitPrice: articleItem.unitValue,
        discount: articleItem.discount,
        unitType: articleItem.unit,
        code: articleItem.code,
        providerCode: articleItem.providerCode,
        exempt: articleItem.exempt,
        trash: false,
        createdAt: new Date().getTime(),
        brand: null,
        purchaseOrder: this._firebase.getReference(`purchaseOrders/${purchaseOrderKey}`),
      };

      // @ts-ignore
      await this._providerArticle.set(article.name.replaceAll('/', ''), article);
    }

    this.purchaseOrder = {
      ...this.purchaseOrder,
      ...this.articlesPurchase,
      provider: this._provider.getReference(this.provider.provider.key),
      project: this._project.getReference(this.provider.project.key),
      budget: this.provider.budget.reference,
      purchaseID: this.isCreatePurchaseOrder ? purchaseId : this.purchaseOrder.purchaseID,
      date: this.currentDate,
      status: this.isCreatePurchaseOrder ? this.purchaseStatus.DRAFT : this.purchaseStatus.GENERATED,
      quotation: this.quotation,
      deliveryDate: this.dates.deliveryDate,
      approvalDate: null,
      received: false,
      invoiced: false,
      invoice: [],
      trash: false,
      supervisor: null,
      signature: null,
      description: this.description,
      bussinessConditions: this.bussinessConditions,
      quotationNumber: this.quotationNumber
    };

    if (isNew) {
      this.purchaseOrder['createdBy'] = this._firebase.getReference(`users/${this._user.user.key}`);
      await this._purchase.set(purchaseOrderKey, this.purchaseOrder);
      this.createLog(purchaseOrderKey);
      this.purchaseOrder['key'] = purchaseOrderKey;
    } else {
      this._purchase.set(this.purchaseOrder.key, this.purchaseOrder);
      this.createLog(this.purchaseOrder.key);
    }

    this.isCreatePurchaseOrder = !this.purchaseOrder.status && this.purchaseOrder.status != 0;
    this.purchaseOrderCreated.emit(this.purchaseOrder);
  }

  async getPurchaseID() {
    const numberPurchase = (await this._purchase.purchaseId()) + 1;
    this._purchase.updatePurchaseID(numberPurchase);
    let milliseconds = (new Date().getMilliseconds()).toString();
    let millisecondsString = ('000' + milliseconds);
    const ticketMilliseconds = millisecondsString.slice(millisecondsString.length - 3, millisecondsString.length);
    return `${numberPurchase}${ticketMilliseconds}`;
  }

  private createLog(key) {
    if (this.isCreatePurchaseOrder) {
      this._log.addPurchaseOrder(key, {
        description: `La orden de compra ${this.purchaseOrder.purchaseID.slice(0, -3)} fue creada`
      });
    } else {
      this._log.addPurchaseOrder(key, {
        description: `La orden de compra ${this.purchaseOrder.purchaseID.slice(0, -3)} se guardó`
      });
    }
  }

  close() {
    this.closeModal.emit();
  }
}
