<div class="p-2">
  <h2>Filtrar aplicantes</h2>

  <form [formGroup]="filterForm" (ngSubmit)="submit()">
    <div class="row mt-2">
      <div class="col-md-6">
        <div class="form-group">
          <label>Estatus</label>
          <ng-select formControlName="status" [clearable]="false" [items]="selectArrayStatus" class="select-width mr-1"
                     bindLabel="name"></ng-select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Cargo</label>
          <ng-select formControlName="job" [clearable]="false" [items]="selectArrayJob" class="select-width mr-1"
                     bindLabel="name"></ng-select>
        </div>
      </div>
      <div class="col-md-6">
        <label>Ciudad</label>
        <ng-select formControlName="city" [clearable]="false" [items]="cities" class="select-width mr-1"
                   bindLabel="name"></ng-select>
      </div>
    </div>
    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn">Filtrar</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide()">Cancelar</div>
    </div>
  </form>
</div>
