<div class="d-flex flex-column">
  <button class="btn btn-warning w-25" (click)="print()">Imprimir</button>
  <div #componentID>
    <div class="p-5">
      <app-datatable [rows$]="articles$"
                     [config]="config">
        <app-datatable-column title="Código">
          <ng-template let-row #column>
            {{row?.internalId}}
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Artículo">
          <ng-template let-row #column>
            {{row.name}}
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Imagen">
          <ng-template let-row #column>
            <img src="{{row?.imageUrl}}" class="imageSize">
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Cantidad">
          <ng-template let-row #column>
            {{row?.quantity}}
          </ng-template>
        </app-datatable-column>

        <app-datatable-column title="Locación">
          <ng-template let-row #column>
            {{row?.location}}
          </ng-template>
        </app-datatable-column>
      </app-datatable>
    </div>
  </div>
</div>
