<app-datatable [rows$]="categories$"
               [config]="config">
  <app-datatable-column title="Nombre" field="name">
    <ng-template let-row #column>
      {{ row.name }}
    </ng-template>
  </app-datatable-column>
  <app-datatable-column title="" field="name">
    <ng-template let-row #column>
      <button class="btn btn-primary" (click)="delete(row.key)">Eliminar</button>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="openAddCategoryModal()" ></app-add-float-button>
