import {Component, OnInit} from '@angular/core';
import {SalesBoard} from '../../interfaces/sales-board';
import {SalesBoardService} from '../../services/sales-board.service';
import {first} from 'rxjs/operators';
import * as Chartist from 'chartist';
import {UserType} from '../../enums/user-type.enum';
import {UserService} from '../../../shared/services/user.service';
import {Router} from '@angular/router';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Sale} from '../../interfaces/sale';
import {SaleService} from '../../services/sale.service';
import moment from 'moment';
import {SaleStatusEnum} from '../../enums/sale-status.enum';
import {SaleStatusLabel} from '../../labels/sale-status.label';

@Component({
  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.css'],
})
export class SalesReportComponent implements OnInit {
  @BlockUI('revenue') blockUIRevenue: NgBlockUI;
  salesBoards: SalesBoard[] = [];
  salesBoardSelected: SalesBoard;
  sales: Sale[] = [];
  filteredSales: Sale[] = [];
  userPermission: UserType;
  lineArea: any;
  salesData: any;
  dateRangeTypeSelected;
  dateSelected;
  saleStatusEnum = SaleStatusEnum;
  saleStatusLabel = SaleStatusLabel;
  saleStatusSelected;
  currentTotalSaleValue = 0;
  previousTotalSaleValue = 0;

  dateRangeType = {
    WEEKLY: 0,
    MONTHLY: 1,
    YEARLY: 2,
  };

  dateRangeTypeLabel = {
    0: 'semana',
    1: 'mes',
    2: 'año',
  };

  years: string[] = [];

  momentUnitOfTime: any[] = ['W', 'month', 'y'];

  startDate = null;
  finalDate = null;

  cardOptions = {
    close: false,
    expand: false,
    minimize: true,
    reload: false,
  };

  chartOptions = {
    bodyClass: ['pt-0'],
    close: false,
    expand: false,
    minimize: false,
    reload: true,
  };

  constructor(
    private _salesBoard: SalesBoardService,
    private _user: UserService,
    private router: Router,
    private _sale: SaleService
  ) {
    const access = this._user.user.permissions.find(
      (user) => user.section == 'VENTAS'
    );
    if (!!access && access.permission == UserType.ADMIN)
      this.userPermission = access.permission;
    else this.router.navigate(['/admin/home']);
  }

  async ngOnInit() {
    this.years = this.getYears();
    this.dateRangeTypeSelected = this.dateRangeType.MONTHLY;
    this.handleDateRangeTypeSelected();
    this.saleStatusSelected = SaleStatusEnum.LEAFLET;

    await this.loadData();
  }

  async loadData() {
    await this.loadSalesBoards();
    await this.loadSales();
    this.filterSales();
    this.setSalesData();
    this.currentTotalSaleValue = this.getTotalSaleValue(
      this.salesData.series[0]
    );
    this.previousTotalSaleValue = this.getTotalSaleValue(
      this.salesData.series[1]
    );
    this.getChartdata();
  }

  getTotalSaleValue(saleValues) {
    return saleValues.reduce((acc, el) => acc + el, 0);
  }

  async loadSalesBoards() {
    this.salesBoards = await this._salesBoard
      .getAll()
      .pipe(first())
      .toPromise();
    if (this.salesBoards.length > 0) {
      const [firstBoard] = this.salesBoards;
      this.salesBoardSelected = firstBoard;
    }
  }

  async onSendClick() {
    await this.loadSales();
    this.filterSales();
    this.setSalesData();
    this.currentTotalSaleValue = this.getTotalSaleValue(
      this.salesData.series[0]
    );
    this.previousTotalSaleValue = this.getTotalSaleValue(
      this.salesData.series[1]
    );
    this.getChartdata();
  }

  setSalesData() {
    this.salesData = {
      labels: this.getLabels(),
      series: [this.getSeries(), this.getSeries(true)],
    };
  }

  getLabels() {
    if (this.validateDateRangeTypeSelected(this.dateRangeType.MONTHLY)) {
      return this.getMonthLabels();
    } else if (this.validateDateRangeTypeSelected(this.dateRangeType.WEEKLY)) {
      return this.getWeekLabels();
    } else if (this.validateDateRangeTypeSelected(this.dateRangeType.YEARLY)) {
      return this.getYearLabels();
    }
  }

  getMonthLabels() {
    let labels = [];
    for (let day = 1; day <= moment(this.startDate).daysInMonth(); day++) {
      labels = [...labels, day];
    }
    return labels;
  }

  getWeekLabels() {
    let labels = [];
    let currentDay;
    for (let day = 0; day < 7; day++) {
      currentDay = moment(this.startDate)
        .locale('es')
        .add(day, 'days')
        .format('dd');
      labels = [...labels, currentDay];
    }
    return labels;
  }

  getYearLabels() {
    let labels = [];
    for (let month = 0; month < 12; month++) {
      labels = [
        ...labels,
        moment(this.startDate).locale('es').add(month, 'months').format('MMMM'),
      ];
    }
    return labels;
  }

  getSeries(previous?: boolean) {
    if (this.validateDateRangeTypeSelected(this.dateRangeType.MONTHLY)) {
      return this.getMonthSaleValueTotalSerie(previous);
    } else if (this.validateDateRangeTypeSelected(this.dateRangeType.WEEKLY)) {
      return this.getWeekSaleValueTotalSerie(previous);
    } else if (this.validateDateRangeTypeSelected(this.dateRangeType.YEARLY)) {
      return this.getYearSaleValueTotalSerie(previous);
    }
  }

  getMonthSaleValueTotalSerie(previous?: boolean) {
    let serie = [];
    const startDate = previous
      ? moment(this.startDate)
          .subtract(1, this.momentUnitOfTime[this.dateRangeTypeSelected])
          .toDate()
      : this.startDate;
    const finalDate = previous ? this.startDate : this.finalDate;

    let filteredSales = previous
      ? this.filterPreviousSales(startDate, finalDate)
      : this.filteredSales;

    for (let day = 1; day <= moment(startDate).daysInMonth(); day++) {
      const currentTotalSaleValue = filteredSales
        .filter((sale) => +moment(sale.createdAt).format('DD') == day)
        .reduce((acc, el) => acc + el.saleValue, 0);
      serie = [...serie, currentTotalSaleValue];
    }

    return serie;
  }

  filterPreviousSales(startDate, finalDate) {
    return this.sales.filter((sale) => {
      return moment(sale.createdAt).isBetween(startDate, finalDate);
    });
  }

  getWeekSaleValueTotalSerie(previous?: boolean) {
    let serie = [];
    const startDate = previous
      ? moment(this.startDate)
          .subtract(1, this.momentUnitOfTime[this.dateRangeTypeSelected])
          .toDate()
      : this.startDate;
    const finalDate = previous ? this.startDate : this.finalDate;

    const filteredSales = previous
      ? this.filterPreviousSales(startDate, finalDate)
      : this.filteredSales;

    for (let day = 0; day < 7; day++) {
      const currentTotalSaleValue = filteredSales
        .filter((sale) => +moment(sale.createdAt).format('d') == day)
        .reduce((acc, el) => acc + el.saleValue, 0);
      serie = [...serie, currentTotalSaleValue];
    }

    return serie;
  }

  getYearSaleValueTotalSerie(previous?: boolean) {
    let serie = [];
    const startDate = previous
      ? moment(this.startDate)
          .subtract(1, this.momentUnitOfTime[this.dateRangeTypeSelected])
          .toDate()
      : this.startDate;
    const finalDate = previous ? this.startDate : this.finalDate;

    const filteredSales = previous
      ? this.filterPreviousSales(startDate, finalDate)
      : this.filteredSales;

    for (let month = 1; month <= 12; month++) {
      const currentTotalSaleValue = filteredSales
        .filter((sale) => +moment(sale.createdAt).format('MM') == month)
        .reduce((acc, el) => acc + el.saleValue, 0);
      serie = [...serie, currentTotalSaleValue];
    }

    return serie;
  }

  async loadSales() {
    this.sales = [];
    if (!this.salesBoardSelected) return;
    this.sales = await this._sale
      .getAll(this.salesBoardSelected.key)
      .pipe(first())
      .toPromise();
  }

  filterSales() {
    this.filteredSales = [];
    if (this.sales.length == 0 || !this.startDate || !this.finalDate) return;
    this.filteredSales = this.sales.filter(
      (sale) =>
        sale.status == this.saleStatusSelected &&
        moment(sale.createdAt).isBetween(this.startDate, this.finalDate)
    );
  }

  async reloadSales() {
    this.blockUIRevenue.start('Cargando..');
    await this.loadSales();
    this.filterSales();
    this.setSalesData();
    this.getChartdata();
    this.blockUIRevenue.stop();
  }

  getChartdata() {
    const Chartdata = this.salesData;
    this.lineArea = {
      type: 'Line',
      data: Chartdata,
      options: {
        lineSmooth: Chartist.Interpolation.simple({
          divisor: 2.8,
        }),
        fullWidth: true,
        height: '270px',
        showArea: false,
        showPoint: false,
        axisX: {
          showGrid: true,
          showLabel: true,
          offset: 32,
        },
        axisY: {
          showGrid: true,
          showLabel: true,
          scaleMinSpace: 28,
          offset: 44,
        },
      },
      events: {
        created(data: any): void {
          const defs = data.svg.elem('defs');
          defs
            .elem('linearGradient', {
              id: 'gradient2',
              x1: 0,
              y1: 0,
              x2: 1,
              y2: 0,
            })
            .elem('stop', {
              offset: 1,
              'stop-color': 'rgb(255,73,97)',
            })
            .parent()
            .elem('stop', {
              offset: 0,
              'stop-color': 'rgb(255,73,97)',
            });
        },
        draw(data: any): void {
          const circleRadius = 4;
          if (data.type == 'point') {
            const circle = new Chartist.Svg('circle', {
              cx: data.x,
              cy: data.y,
              r: circleRadius,
              class: 'ct-point-circle',
            });
            data.element.replace(circle);
          } else if (data.type == 'label') {
            // adjust label position for rotation
            const dX = data.width / 2 + (26 - data.width);
            data.element.attr({x: data.element.attr('x') - dX});
          }
        },
      },
    };
  }

  handleDateRangeTypeSelected() {
    if (this.validateDateRangeTypeSelected(this.dateRangeType.MONTHLY)) {
      this.dateSelected = moment().format('yyyy-MM');
    } else if (this.validateDateRangeTypeSelected(this.dateRangeType.WEEKLY)) {
      this.dateSelected = `${moment().format(`yyyy`)}-W${
        +moment().format('W') < 9 ? '0' : ''
      }${moment().format('W')}`;
    } else if (this.validateDateRangeTypeSelected(this.dateRangeType.YEARLY)) {
      this.dateSelected = this.years[0];
    }
    this.assignStartDateAndFinalDate();
  }

  getYears(): string[] {
    const yearsLength: number = moment(`${moment().format('yyyy')}`).diff(
      moment('2020'),
      'y'
    );
    let years = ['2020'];
    for (let i = 1; i <= yearsLength; i++) {
      years = [moment('2020').add(i, 'y').format('yyyy').toString(), ...years];
    }
    return years;
  }

  assignStartDateAndFinalDate() {
    this.startDate = moment(this.dateSelected)
      .startOf(this.momentUnitOfTime[this.dateRangeTypeSelected])
      .toDate();
    this.finalDate = moment(this.dateSelected)
      .endOf(this.momentUnitOfTime[this.dateRangeTypeSelected])
      .toDate();
  }

  validateDateRangeTypeSelected(expectedDateRangeType: number) {
    return this.dateRangeTypeSelected == expectedDateRangeType;
  }
}
