import {Injectable} from '@angular/core';
import {Project} from '../../admin/interfaces/project';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {ProjectCategory} from '../../admin/interfaces/project-category';
import {take} from 'rxjs/operators';
import { ProjectStatus } from '../../admin/enums/project-status.enum';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  projects = []

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  getAllWithDeleted(): Observable<Project[]> {
    return this.db.colWithIds$<Project>('projects', ref => ref
      .orderBy('startDate', 'desc'));
  }

  getAll(): Observable<Project[]> {
    return this.db.colWithIds$<Project>('projects', ref => ref
      .where('trash', '==', false)
      .orderBy('startDate', 'desc'));
  }

  getAllByStatus(status: ProjectStatus): Observable<Project[]> {
    return this.db.colWithIds$<Project>('projects', ref => ref
      .where('trash', '==', false)
      .where('status','==', status)
      .orderBy('startDate', 'desc')
    );
  }

  get(projectKey: string): Observable<Project> {
    return this.db.docWithId$(`projects/${projectKey}`);
  }

  getReference(projectKey: string): DocumentReference {
    return this.afs.doc(`projects/${projectKey}`).ref;
  }

  add(project: Project) {
    this.afs.collection(`projects`).add(project);
  }

  update(projectKey: string, project: Project): Promise<void> {
    return this.afs.doc<Project>(`projects/${projectKey}`).update(project);
  }

  getAllCategories(): Observable<ProjectCategory[]> {
    return this.db.colWithIds$<ProjectCategory>('projectCategories', ref => ref
      .where('trash', '==', false));
  }

  getAllCategoriesThatAreNotSubCategories(): Observable<ProjectCategory[]> {
    return this.db.colWithIds$<ProjectCategory>('projectCategories', ref => ref
      .where('trash', '==', false)
      .where('subCategory', '==', false));
  }

  getCategory(categoryKey: string): Observable<ProjectCategory> {
    return this.db.docWithId$(`projectCategories/${categoryKey}`);
  }

  addCategory(category: ProjectCategory) {
    return this.afs.collection(`projectCategories`).add(category);
  }

  updateCategory(categoryKey: string, category: ProjectCategory): Promise<void> {
    return this.afs.doc<ProjectCategory>(`projectCategories/${categoryKey}`).update(category);
  }

  deleteCategory(categoryKey: string): Promise<void> {
    return this.afs.doc<ProjectCategory>(`projectCategories/${categoryKey}`).update({trash: true});
  }

  getID(): any {
    return this.db.docWithId$('settings/setting').pipe(take(1)).toPromise();
  }

  updateProjectID(projectID) {
    return this.afs.doc('settings/setting').update({projectID});
  }

  delete(projectKey: string) {
    return this.afs.doc(`projects/${projectKey}`).update({trash: true});
  }
}
