import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../shared/services/user.service';
import {CostCentersService} from '../../services/cost-centers.service';
import {CostCenter} from '../../interfaces/cost-center';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {CostCenterStatusLabel} from '../../labels/costCenterLabel';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';

@Component({
  selector: 'app-cost-center-modal',
  templateUrl: './cost-center-modal.component.html',
  styleUrls: ['./cost-center-modal.component.css']
})
export class CostCenterModalComponent implements OnInit {
  costCenterForm: FormGroup;
  submitted: boolean = false;
  CostCenterType = CostCenterStatusLabel;
  isUpdate: boolean = false;
  costCenter: CostCenter = {key: ''} as CostCenter;

  constructor(private formBuilder: FormBuilder,
              private _user: UserService,
              private _costCenter: CostCentersService,
              public modal: BsModalRef,
              private _firebase: FirebaseDataService) {
    this.costCenterForm = formBuilder.group({
      name: ['', Validators.required],
      status: ['', Validators.required],
      createdBy: [''],
      createdAt: [],
      trash: [false],
    });
  }

  ngOnInit(): void {
    if (this.isUpdate) this.costCenterForm.patchValue(this.costCenter);
  }

  get formControls() {
    return this.costCenterForm.controls;
  }

  setSupervisorReferences() {
    this.costCenterForm.patchValue({createdBy: this._user.getReference(this._user.user.key)});
  }

  async submit() {
    this.submitted = true;
    if (this.costCenterForm.valid) {

      if (!this.isUpdate) {
        this.costCenter.key = await this._firebase.createID();
        this.costCenterForm.patchValue({createdAt: new Date().getTime()});
      }

      if (!this.isUpdate) this.setSupervisorReferences();
      await this._costCenter.set(this.costCenter.key, this.costCenterForm.value);
      this.modal.hide();
    }
  }
}
