import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { FirebaseDataService } from 'src/app/shared/template-services/firebase-data.service';
import { SalesBoard } from '../interfaces/sales-board';

@Injectable({
  providedIn: 'root'
})
export class SalesBoardService extends FirebaseDataService {

  constructor(public _db: AngularFirestore,
              private _storage: AngularFireStorage,
              private fds: FirebaseDataService) {
    super(_db, _storage);
  }

  getAll(): Observable<SalesBoard[]> {
    return this.colWithIds$('salesBoards', ref => ref.where('trash', '==', false));
  }

  async add(salesBoard: SalesBoard) {
    return await this.db.collection('salesBoards').add(salesBoard);
  }

  updateSalesBoard(salesBoardKey: string, salesBoard: SalesBoard) {
    salesBoard['updatedAt'] = new Date().getTime();
    this.update(`salesBoards/${salesBoardKey}`, salesBoard);
  }

  
  delete(salesBoardKey: string) {
    this.db.doc(`salesBoards/${salesBoardKey}`).update({trash: true});
  }
  
  get(salesBoardKey: string): Observable<SalesBoard> {
    return this.docWithId$(`salesBoards/${salesBoardKey}`);
  }

  getSettings(salesBoardKey: string): Observable<any> {
    return this.colWithIds$(`salesBoards/${salesBoardKey}/settings`);
  }

  addSettings(salesBoardKey: string, settings) {
    return this.db.collection(`salesBoards/${salesBoardKey}/settings`).add(settings);   
  }
  
  updateSettings(salesBoardKey: string, settingsKey, setting) {
    return this.fds.doc(`salesBoards/${salesBoardKey}/settings/${settingsKey}`).update(setting);
  }
}
