import { Injectable } from '@angular/core';
import { States } from '../../../assets/json/states';
import { Cities } from '../../../assets/json/cities';


@Injectable({
  providedIn: 'root'
})

export class UtilsService {

  loader: any;
  isLoading = false;

  public statelist;
  public citylist;

  private stateUrl: string = 'assets/json/states.ts';
  private cityurl: string = 'assets/json/cities.ts';

  constructor() {
    this.statelist = States;
    this.citylist = Cities;
  }

  static convertToTime(date) {
    if (date == null) {
      return null;
    }
    let newDeliveryDate = date.replaceAll('-', '/');
    return new Date(newDeliveryDate).getTime();
  }

}
