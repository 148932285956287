<div class="overflow-y-scroll" style="max-height: calc(100vh - 100px)">
  <div class="modal-header border-0 d-flex align-items-center justify-content-center ">
    <div class="w-100 d-flex justify-content-center ">
      <h2 class="text-center mb-0"
          [ngClass]="getStatusClass(purchaseOrder.status)">{{purchaseStatusLabel[purchaseOrder.status]}}</h2>
    </div>

    <button class="close pull-right" (click)="modalService.hide(31)">
      <span class="">&times;</span>
    </button>
  </div>

  <app-draft-purchase-order *ngIf="purchaseOrder.status == purchaseStatus.DRAFT || !purchaseOrder.status"
                            [purchaseOrder]="purchaseOrder"
                            (purchaseOrderCreated)="changePurchaseOrder($event)"
                            (closeModal)="modal.hide()"></app-draft-purchase-order>

  <app-generated-purchase-order *ngIf="purchaseOrder.status == purchaseStatus.GENERATED"
                                [purchaseOrder]="purchaseOrder"
                                (purchaseOrderChanged)="changePurchaseOrder($event)"
                                (closeModal)="modal.hide()"></app-generated-purchase-order>

  <app-incomplete-purchase-order *ngIf="purchaseOrder.status == purchaseStatus.INCOMPLETE"
                                 [purchaseOrder]="purchaseOrder"
                                 (purchaseOrderChanged)="changePurchaseOrder($event)"
                                 (closeModal)="modal.hide()"></app-incomplete-purchase-order>

  <app-authorized-purchase-order *ngIf="purchaseOrder.status == purchaseStatus.AUTHORIZED || purchaseOrder.status == purchaseStatus.CANCELLED"
                                 [purchaseOrder]="purchaseOrder"
                                 (purchaseOrderChanged)="changePurchaseOrder($event)"
                                 (closeModal)="modal.hide()"></app-authorized-purchase-order>
</div>
