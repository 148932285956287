import {Injectable} from '@angular/core';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {Provider} from '../../admin/interfaces/provider';

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  providers: Provider[] = [];

  constructor(private db: FirebaseDataService, private afs: AngularFirestore) {}

  getAll(): Observable<Provider[]> {
    return this.db.colWithIds$<Provider>('providers', (ref) =>
      ref.where('trash', '==', false)
    );
  }

  add(provider: Provider): Promise<DocumentReference> {
    return this.afs.collection('providers').add(provider);
  }

  set(providerKey: string, provider: Provider): void {
    this.afs.doc(`providers/${providerKey}`).set(provider, {merge: true});
  }

  delete(providerKey: string): Promise<void> {
    return this.afs
      .doc<Provider>(`providers/${providerKey}`)
      .update({trash: true});
  }

  createId(): string {
    return this.afs.createId();
  }

  getReference(providerKey: string): DocumentReference {
    return this.afs.doc(`providers/${providerKey}`).ref;
  }

  get(providerKey: string) {
    return this.db.docWithId$(`providers/${providerKey}`);
  }
}
