<div *ngIf="!multiline">
  <input
    *ngIf="mode == 'bootstrap'"
    class="form-control"
    [type]="type"
    [placeholder]="placeholder"
    (ngModelChange)="onChange($event)"
    [(ngModel)]="model"
  />

  <mat-form-field
    class="w-100"
    appearance="outline"
    *ngIf="mode == 'material'"
  >
    <mat-label>{{label}}</mat-label>
    <input matInput
           *ngIf="!control"
           [type]="type"
           [placeholder]="placeholder"
           (ngModelChange)="onChange($event)"
           [(ngModel)]="model"
    >
    <ng-container *ngIf="control">
      <input matInput
             [type]="type"
             [placeholder]="placeholder"
             [formControl]="$any(control)"
             [prefix]="prefix"
             [suffix]="suffix"
             [mask]="mask"
             thousandSeparator="."
             [max]="max"
      >
    </ng-container>
  </mat-form-field>
</div>

<div *ngIf="multiline">
  <ng-container *ngIf="mode == 'bootstrap'">
    <textarea
      class="form-control"
      [placeholder]="placeholder"
      (ngModelChange)="onChange($event)"
      [(ngModel)]="model"
      [rows]="rows"></textarea>
  </ng-container>

  <mat-form-field
    *ngIf="mode == 'material'"
    class="w-100"
    appearance="outline"
  >
    <mat-label>{{label}}</mat-label>

    <ng-container *ngIf="!control">
      <textarea
        matInput
        [placeholder]="placeholder"
        (ngModelChange)="onChange($event)"
        [(ngModel)]="model"
        [rows]="rows"
      ></textarea>
    </ng-container>

    <ng-container *ngIf="control">
      <textarea matInput
                [type]="type"
                [placeholder]="placeholder"
                [formControl]="$any(control)"
                [prefix]="prefix"
                [suffix]="suffix"
                [mask]="mask"
                [rows]="rows"
                thousandSeparator="."
      ></textarea>
    </ng-container>
  </mat-form-field>

</div>
