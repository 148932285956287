import { Component, OnDestroy, OnInit } from '@angular/core';
import { SaleStatusEnum } from '../../enums/sale-status.enum';
import { Sale } from '../../interfaces/sale';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SaleService } from '../../services/sale.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddSaleModalComponent } from '../../modals/add-sale-modal/add-sale-modal.component';
import { SalesBoardService } from '../../services/sales-board.service';
import { SalesBoard } from '../../interfaces/sales-board';
import { first, map } from 'rxjs/operators';
import { User } from '../../../shared/interfaces/user';
import { ClientService } from '../../services/client.service';
import { SaleDetailsModalComponent } from '../../modals/sale-details-modal/sale-details-modal.component';
import { take } from 'rxjs/internal/operators/take';
import { ClientModalComponent } from '../../modals/client-modal/client-modal.component';
import { UserType } from '../../enums/user-type.enum';
import _ from 'lodash';
import { ObjectService } from '../../../shared/template-services/object.service';
import { AlertService } from '../../../shared/template-services/alert.service';
import { UserService } from '../../../shared/services/user.service';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { SaleStatusLabel } from '../../labels/sale-status.label';
import { formatDate } from '@angular/common';
import moment from 'moment';

declare const $;

@Component({
  selector: 'app-sales-kanban',
  templateUrl: './sales-kanban.component.html',
  styleUrls: ['./sales-kanban.component.css']
})
export class SalesKanbanComponent implements OnInit, OnDestroy {
  salesBoardKey: string;
  salesBoard: SalesBoard;
  users: User[] = [];
  indexedUsers;
  indexedClients;
  usersSubscription: Subscription = new Subscription();
  salesSubscription: Subscription = new Subscription();
  sales: Sale[] = [];
  saleStatusEnum = SaleStatusEnum;
  leaflet: Sale[] = [];
  opportunity: Sale[] = [];
  proposal: Sale[] = [];
  hot: Sale[] = [];
  earnedClosures: Sale[] = [];
  missedClosures: Sale[] = [];
  leafletTotal: number = 0;
  opportunityTotal: number = 0;
  proposalTotal: number = 0;
  hotTotal: number = 0;
  earnedClosuresTotal: number = 0;
  missedClosuresTotal: number = 0;
  settings;
  userPermission;
  userTypeEnum = UserType;
  startDate = null;
  finalDate = null;
  userFilter: any = '';
  zoom: string = localStorage.getItem('salesZoom');
  clientSelected: any;
  allClients;

  constructor(private _sale: SaleService,
              private activatedRoute: ActivatedRoute,
              private modal: BsModalService,
              private _salesBoard: SalesBoardService,
              private _user: UserService,
              private _client: ClientService) {
    this.salesBoardKey = this.activatedRoute.snapshot.params['salesBoardKey'];
  }

  async ngOnInit() {
    const date = new Date(
      `${new Date().getMonth() + 1}/01/${new Date()
        .getFullYear()
        .toString()
        .slice(-2)}`
    );
    this.startDate = this.formatDate(moment().startOf('year'));
    this.finalDate = this.formatDate(moment(date).add(1, 'month').toDate());

    await this.indexAllUsers();
    await this.indexAllClients();

    this.salesBoard = await this._salesBoard
      .get(this.salesBoardKey)
      .pipe(first())
      .toPromise();

    await this.loadUsers();

    this.sales = await this._sale
      .getAll(this.salesBoardKey)
      .pipe(take(1))
      .toPromise();

    this.userPermission = this._user.user.permissions.find(
      (user) => user.section == 'VENTAS'
    ).permission;

    this.salesSubscription = this._sale
      .getAll(this.salesBoardKey)
      .pipe(
        map((sales) =>
          sales.map((sale) => ({
            ...sale,
            client: this.indexedClients[sale.client.id],
            users: (<any[]>sale.users).map((user) => this.indexedUsers[user.id])
          }))
        )
      )
      .subscribe((sales) => {
        this.sales = sales;
        this.filterAll();
        this.orderColumns();
        this.calculateSalesValueTotals();
      });

    this.settings = await this._salesBoard
      .getSettings(this.salesBoardKey)
      .pipe(take(1))
      .toPromise();
    if (this.settings.length == 0) {
      this._salesBoard.addSettings(this.salesBoardKey, { inactiveTime: 5 });
    }
  }

  ngOnDestroy() {
    this.salesSubscription.unsubscribe();
    this.usersSubscription.unsubscribe();
  }

  private formatDate(date) {
    const deliveryDateDate = new Date(date);
    const format = 'yyyy/MM/dd';
    const locale = 'en-US';
    const zone = 'UTC';
    const formattedDate = formatDate(deliveryDateDate, format, locale, zone);
    return formattedDate.replace(/\//g, '-');
  }

  convertToTime(date) {
    if (date == null) {
      return null;
    }
    let newDate = date.replaceAll('-', '/');
    return new Date(newDate).getTime();
  }

  async filterByDate() {
    const salesRange = this.sales.filter(
      (sale) =>
        sale.createdAt >= this.convertToTime(this.startDate) &&
        sale.createdAt <= this.convertToTime(this.finalDate)
    );

    this.leaflet = salesRange.filter(
      (sale) => sale.status == SaleStatusEnum.LEAFLET
    );

    this.opportunity = salesRange.filter(
      (sale) => sale.status == SaleStatusEnum.OPPORTUNITY
    );

    this.proposal = salesRange.filter(
      (sale) => sale.status == SaleStatusEnum.PROPOSAL
    );

    this.hot = salesRange.filter((sale) => sale.status == SaleStatusEnum.HOT);

    this.earnedClosures = this.sales
      .filter(sale =>
        sale.closeDate >= this.convertToTime(this.startDate) &&
        sale.closeDate <= this.convertToTime(this.finalDate)
      )
      .filter((sale) => sale.status == SaleStatusEnum.EARNED_CLOSURE);

    this.missedClosures = salesRange.filter(
      (sale) => sale.status == SaleStatusEnum.MISSED_CLOSURE
    );

    this.orderColumns();
    this.calculateSalesValueTotals();
  }

  async filterByUser() {
    this.leaflet = this.leaflet.filter(
      (sale) =>
        sale.status == SaleStatusEnum.LEAFLET &&
        sale.users.some((user) => user.key == this.userFilter.key)
    );
    this.opportunity = this.opportunity.filter(
      (sale) =>
        sale.status == SaleStatusEnum.OPPORTUNITY &&
        sale.users.some((user) => user.key == this.userFilter.key)
    );
    this.proposal = this.proposal.filter(
      (sale) =>
        sale.status == SaleStatusEnum.PROPOSAL &&
        sale.users.some((user) => user.key == this.userFilter.key)
    );
    this.hot = this.hot.filter(
      (sale) =>
        sale.status == SaleStatusEnum.HOT &&
        sale.users.some((user) => user.key == this.userFilter.key)
    );
    this.earnedClosures = this.earnedClosures.filter(
      (sale) =>
        sale.status == SaleStatusEnum.EARNED_CLOSURE &&
        sale.users.some((user) => user.key == this.userFilter.key)
    );
    this.missedClosures = this.missedClosures.filter(
      (sale) =>
        sale.status == SaleStatusEnum.MISSED_CLOSURE &&
        sale.users.some((user) => user.key == this.userFilter.key)
    );
    this.orderColumns();
    this.calculateSalesValueTotals();
  }

  filterByClient() {
    this.leaflet = this.leaflet.filter(
      (sale) =>
        sale.status == SaleStatusEnum.LEAFLET &&
        sale.client.key == this.clientSelected.key);
    this.opportunity = this.opportunity.filter(
      (sale) =>
        sale.status == SaleStatusEnum.OPPORTUNITY &&
        sale.client.key == this.clientSelected.key);
    this.proposal = this.proposal.filter(
      (sale) =>
        sale.status == SaleStatusEnum.PROPOSAL &&
        sale.client.key == this.clientSelected.key);
    this.hot = this.hot.filter(
      (sale) =>
        sale.status == SaleStatusEnum.HOT &&
        sale.client.key == this.clientSelected.key);
    this.earnedClosures = this.earnedClosures.filter(
      (sale) =>
        sale.status == SaleStatusEnum.EARNED_CLOSURE &&
        sale.client.key == this.clientSelected.key);
    this.missedClosures = this.missedClosures.filter(
      (sale) =>
        sale.status == SaleStatusEnum.MISSED_CLOSURE &&
        sale.client.key == this.clientSelected.key);
    this.orderColumns();
    this.calculateSalesValueTotals();
  }


  filterAll() {
    if (!!this.startDate && !!this.finalDate) {
      this.filterByDate();
    }

    if (!!this.userFilter) {
      this.filterByUser();
    }

    if (this.clientSelected) {
      this.filterByClient();
    }
  }

  orderColumns() {
    this.leaflet = this.getOrderedDataByIndex(this.leaflet);
    this.opportunity = this.getOrderedDataByIndex(this.opportunity);
    this.proposal = this.getOrderedDataByIndex(this.proposal);
    this.hot = this.getOrderedDataByIndex(this.hot);
    this.earnedClosures = this.getOrderedDataByIndex(this.earnedClosures);
    this.missedClosures = this.getOrderedDataByIndex(this.missedClosures);
  }

  getOrderedDataByIndex(array) {
    return _.orderBy(array, 'index', 'asc');
  }

  async loadUsers() {
    this.usersSubscription = this._user
      .getUsers()
      .pipe(
        map((users) =>
          users.filter(
            (user) =>
              this.salesBoard.users.some(
                (boardUser) => boardUser.id == user.key
              ) ||
              this.validateUserHasSectionPermission(
                user,
                'VENTAS',
                UserType.ADMIN
              )
          )
        )
      )
      .subscribe((users) => {
        this.users = users;
      });
  }

  validateUserHasSectionPermission(
    user: User,
    section: string,
    permissionExpected: UserType
  ): boolean {
    if (!('permissions' in user)) return false;
    const { permission } =
    user.permissions.find((data) => data.section == section) ?? {};
    if (!permission) return false;
    return permission == permissionExpected;
  }

  async drop(event) {
    if (this.userPermission == UserType.GROCER) return;

    if (event.previousContainer == event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      this._sale.updateSale(
        this.salesBoardKey,
        event.previousContainer.data[event.previousIndex].key,
        { status: +event.container.id } as Sale
      );

      await this._sale.setLog(
        this.salesBoardKey,
        event.previousContainer.data[event.previousIndex].key,
        {
          description: `Se envió la tarea a ${
            SaleStatusLabel[+event.container.id]
          }`,
          user: this._user.getReference(this._user.user.key)
        }
      );

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.updateSalesIndex(event.container.data);
    }
  }

  updateSalesIndex(data: Sale[]) {
    for (const index in data) {
      this._sale.updateSale(this.salesBoardKey, data[index].key, {
        index: +index
      } as Sale);
    }
  }

  calculateSalesValueTotals() {
    this.leafletTotal = this.getTotalSaleValue(this.leaflet);
    this.opportunityTotal = this.getTotalSaleValue(this.opportunity);
    this.proposalTotal = this.getTotalSaleValue(this.proposal);
    this.hotTotal = this.getTotalSaleValue(this.hot);
    this.earnedClosuresTotal = this.getTotalSaleValue(this.earnedClosures);
    this.missedClosuresTotal = this.getTotalSaleValue(this.missedClosures);
  }

  getTotalSaleValue(sales: Sale[]) {
    return sales.reduce((acc, el) => acc + el.saleValue, 0);
  }

  async openConfig() {
    let [inactiveTime] = await AlertService.withHtml(
      'Configuración',
      `
        <label>Días de inactividad</label>
        <input type="number" id="swal-input1" class="swal2-input" value="${this.settings[0].inactiveTime}">`,
      function() {
        return new Promise(function(resolve) {
          resolve([$('#swal-input1').val()]);
        });
      }
    );
    if (!inactiveTime) return;
    this.settings.inactiveTime = +inactiveTime;

    this._salesBoard.updateSettings(this.salesBoardKey, this.settings[0].key, {
      inactiveTime: this.settings.inactiveTime
    });
    AlertService.toastSuccess('Guardado exitosamente');
  }

  async indexAllUsers() {
    let allUsers = await this._user
      .getAllByColsan()
      .pipe(first((users) => users.length > 1))
      .toPromise();
    this.indexedUsers = ObjectService.indexArray(allUsers, 'key');
  }

  async indexAllClients() {
    this.allClients = await this._client
      .getAll()
      .pipe(first((users) => users.length > 1))
      .toPromise();
    this.indexedClients = ObjectService.indexArray(this.allClients, 'key');
  }

  openAddSale() {
    this.modal.show(AddSaleModalComponent, {
      initialState: {
        salesBoardKey: this.salesBoardKey,
        users: this.users,
        userPermission: this.userPermission,
        validateUserPermission: this.validateUserPermission,
        openAddclientModal: this.openAddclientModal
      },
      backdrop: 'static'
    });
  }

  validateUserPermission(permissionExpected): boolean {
    return this.userPermission == permissionExpected;
  }

  openSaleDetails(sale: Sale) {
    this.modal.show(SaleDetailsModalComponent, {
      initialState: {
        sale: { ...sale },
        users: this.users,
        salesBoardKey: this.salesBoardKey,
        indexedUsers: this.indexedUsers,
        userPermission: this.userPermission,
        validateUserPermission: this.validateUserPermission,
        openAddclientModal: this.openAddclientModal
      },
      backdrop: 'static',
      class: 'modal-xlg'
    });
  }

  openAddclientModal() {
    this.modal.show(ClientModalComponent, {
      backdrop: 'static'
    });
  }

  isUserAssignedToSale(sale: Sale): boolean {
    return sale.users.some((user) => user.key == this._user.user.key);
  }

  validateCdkDragDisabled(sale: Sale): boolean {
    return (
      this.userPermission == UserType.USER && !this.isUserAssignedToSale(sale)
    );
  }

  addZoom() {
    if (!!localStorage.salesZoom) {
      localStorage.setItem('salesZoom', (+this.zoom + 0.1).toString());
    } else {
      localStorage.setItem('salesZoom', (1 + +this.zoom + 0.1).toString());
    }
    this.zoom = localStorage.getItem('salesZoom');
  }

  substractZoom() {
    if (!!localStorage.salesZoom) {
      localStorage.setItem('salesZoom', (+this.zoom - 0.1).toString());
    } else {
      localStorage.setItem('salesZoom', (1 + +this.zoom - 0.1).toString());
    }
    this.zoom = localStorage.getItem('salesZoom');
  }
}
