import {Component, OnDestroy, OnInit} from '@angular/core';
import {ArticleService} from '../../../../../shared/services/article.service';
import {FormBuilder} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../../../shared/template-services/alert.service';
import {AddSerialNumberComponent} from '../add-serial-number/add-serial-number.component';
import {TicketService} from '../../../../../shared/services/ticket.service';
import {FirebaseDataService} from '../../../../../shared/template-services/firebase-data.service';
import {Subscription} from 'rxjs';
import {TicketStatus} from '../../enums/ticket-status.enum';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-serials-number',
  templateUrl: './serials-number.component.html',
  styleUrls: ['./serials-number.component.css']
})
export class SerialsNumberComponent implements OnInit, OnDestroy {
  article: any;
  articleKey: string;
  warehouseKey: string;
  tickets: any[];
  articleSubscription: Subscription = new Subscription();
  warehouseReference: any;

  constructor(private _article: ArticleService,
              private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private modalService: BsModalService,
              private _ticket: TicketService,
              private _firebase: FirebaseDataService,
              private SpinnerService: NgxSpinnerService) {
  }

  async ngOnInit() {
    this.warehouseReference = this._firebase.getReference(`warehouses/${this.warehouseKey}`);
    this.articleSubscription = this._article.get(this.warehouseKey, this.articleKey).subscribe(data => {
      this.article = data;
    });
  }

  ngOnDestroy() {
    this.articleSubscription.unsubscribe();
  }

  addSerial() {
    if (this.article.serialNumber.length >= this.article.quantity) {
      return AlertService.toastError('No puedes tener más números seriales que la cantidad del artículo.');
    }

    this.modalService.show(AddSerialNumberComponent, {
      initialState: {
        article: this.article,
        warehouseKey: this.warehouseKey
      },
      animated: true,
      backdrop: 'static',
      id: 101
    });
  }

  async updateSerial(serial, indexSerial) {
    let update: boolean = false;

    if (await AlertService.confirm('¿Estás seguro que deseas actualizar este número serial?', '')) {
      this.SpinnerService.show();
      this.tickets = this._ticket.tickets.filter(ticket => ticket.status >= TicketStatus.COLLECTED);
      for (let ticket of this.tickets) {
        let index = ticket.articles.filter(article => article.key == this.article.key);
        if (index == -1) continue;
        for (let iArticle = 0; iArticle < ticket.articles.length; iArticle++) {
          if (!!ticket.articles[iArticle].serialNumbersSelected) {
            for (let iSerial = 0; iSerial < ticket.articles[iArticle].serialNumbersSelected.length; iSerial++) {
              if (ticket.articles[iArticle].serialNumbersSelected[iSerial] == this.article.serialNumber[indexSerial]) {
                ticket.articles[iArticle].serialNumbersSelected[iSerial] = serial.value;
                await this._ticket.update(ticket.key, ticket);
                update = true;
                break;
              }
            }
          }
        }
        if (update) break;
      }

      let index
      if (!!this.article.serialNumberCollected) index = this.article.serialNumberCollected.findIndex(serial => serial == this.article.serialNumber[indexSerial])
      if (index != -1) this.article.serialNumberCollected[index] = serial.value;

      this.article.serialNumber[indexSerial] = serial.value;

      await this._article.update(this.warehouseKey, this.articleKey, this.article);
      this.SpinnerService.hide();

      AlertService.toastSuccess('Se actualizó el número serial', '');
    }
  }

  async removeSerial(serial, indexSerial) {
    if (!!this.article.serialNumberCollected) {
      let index = this.article.serialNumberCollected.findIndex(serial => serial == this.article.serialNumber[indexSerial]);
      if (index != -1) {
        return AlertService.toastError('Este número serial está siendo utilizado');
      }
    }

    if (await AlertService.confirm('¿Estás seguro que deseas eliminar este numero serial?', '')) {
      await this.article.serialNumber.splice(indexSerial, 1);
      await this._article.update(this.warehouseKey, this.article.key, this.article);
      AlertService.toastSuccess('Se eliminó correctamente', '');
    }
  }
}
