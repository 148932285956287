import { Component, OnInit } from '@angular/core';
import { KanbanService } from '../kanban/kanban.service';
import { Task } from '../kanban/interfaces/task';
import { Observable, of, Subscription } from 'rxjs';
import { KanbanStatus } from '../kanban/enums/kanban-status.enum';
import { UserType } from '../../enums/user-type.enum';
import { User } from '../../../shared/interfaces/user';
import moment from 'moment/moment';
import { ExpirationFilterEnum } from '../kanban/enums/expiration-filter.enum';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from '../../../shared/services/user.service';
import { BoardService } from '../../services/board.service';
import { ChecklistService } from '../kanban/services/checklist.service';
import { NotificationService } from '../../services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseDataService } from '../../../shared/template-services/firebase-data.service';
import { TitleCasePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProjectService } from '../../../shared/services/project.service';
import { map, take } from 'rxjs/operators';
import { DocumentReference } from '@angular/fire/firestore';
import * as _ from 'lodash';
import { AlertService } from '../../../shared/template-services/alert.service';
import { TaskOverhaul } from '../kanban/enums/task-overhaul';
import { NotificationType } from '../../enums/notification-type.enum';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { KanbanStatusLabel } from '../kanban/labels/kanban-status.label';
import { KanbanReportComponent } from '../../kanban/components/kanban-report/kanban-report.component';
import { KanbanModalComponent } from '../kanban/kanban-modal/kanban-modal.component';
import { CostCenterType } from '../../enums/cost-center-type.enum';
import { ObjectService } from '../../../shared/template-services/object.service';

declare const $;

@Component({
  selector: 'app-my-tasks-kanban',
  templateUrl: './my-tasks-kanban.component.html',
  styleUrls: ['../kanban/kanban.component.css', './my-tasks-kanban.component.css']
})
export class MyTasksKanbanComponent implements OnInit {
  todo: Task[] = [];
  inProgress: Task[] = [];
  done: Task[] = [];
  taskSubscription: Subscription = new Subscription();
  tagsSubscription: Subscription = new Subscription();
  tags = [];
  status = KanbanStatus;
  userTypeEnum = UserType;
  userPermission: number = this.userTypeEnum.USER;
  team = [];
  users: User[];
  private usersSubscription: Subscription = new Subscription();
  private teamSubscription: Subscription = new Subscription();
  userFilter: any = '';
  tasks: Task[] = [];
  startDate: any = moment().startOf('month').format('YYYY-MM-DD');
  finalDate: any = moment().endOf('month').format('YYYY-MM-DD');
  settings;
  monthSelected: any = moment().locale('es').endOf('month').format('YYYY-MM');
  listMode: boolean = false;
  filteredTasks$: Observable<Task[]>;
  zoom: string = localStorage.getItem('tasksZoom');
  expirationFilterEnum = ExpirationFilterEnum;
  validitySelected;
  project: any;
  selectArray: any;
  private projectSubscription: Subscription = new Subscription();
  allTasksSubscription: Subscription = new Subscription();
  indexedBoards: any = {};

  constructor(private modal: BsModalService,
              private _kanban: KanbanService,
              private _user: UserService,
              private _board: BoardService,
              private _checklist: ChecklistService,
              private _notification: NotificationService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private db: FirebaseDataService,
              private titleCasePipe: TitleCasePipe,
              private spinnerService: NgxSpinnerService,
              private _project: ProjectService) {
  }

  async ngOnInit(): Promise<void> {
    this.validitySelected = this.expirationFilterEnum.ALL;
    this.settings = await this._kanban.getSettings();

    this.tags = await this._kanban.getAllTags().pipe(take(1)).toPromise();
    this.users = await this._user.getAllUndeleted().pipe(take(1)).toPromise();
    this.indexedBoards = ObjectService.indexArray(await this._board.getAll().pipe(take(1)).toPromise(), 'key');

    this.listenAllTasks();

    this.getProjects();
  }

  ngOnDestroy() {
    this.taskSubscription.unsubscribe();
    this.tagsSubscription.unsubscribe();
    this.usersSubscription.unsubscribe();
    this.teamSubscription.unsubscribe();
    this.projectSubscription.unsubscribe();
  }

  listenAllTasks() {
    this.allTasksSubscription = this._kanban.getAllMyTasks().subscribe(data => {
      data = data.map((task: Task) => ({
        ...task,
        members: (<DocumentReference[]>task.members).map((member) =>
          this.users.find((user) => user.key == member.id)
        ),
        tags: (<DocumentReference[]>task.tags).map((tag) =>
          this.tags.find((tagItem) => tagItem.key == tag.id)
        ),
        isExpirated:
          task.expiratedDate < new Date().getTime() &&
          task.expiratedDate < task.finishDate,
        boardKey: task.path.split('/')[1]
      }));

      this.tasks = _.orderBy(data, 'index', 'asc');
      this.filterTasks();
    });
  }

  async drop(event: any) {
    if (event.previousContainer != event.container && !(await AlertService.confirm('¿Está seguro de mover la tarea?'))) {
      return;
    }

    if (event.previousContainer.id == KanbanStatus.IN_PROGRESS
      && event.container.id == KanbanStatus.DONE) {
      let [description, realFinalDate] = await AlertService.withHtml(
        'Finalizar tarea',
        `
        <label>Observaciones:</label>
        <textarea id="swal-input1" rows="10" class="swal2-input"></textarea>
        <label>Fecha de finalización:</label>
        <input id="swal-input2" class="swal2-input" type="date">`,
        function() {
          return new Promise(function(resolve) {
            resolve([$('#swal-input1').val(), $('#swal-input2').val()]);
          });
        }
      );

      if (!description || !realFinalDate) {
        return AlertService.toastError(
          'Necesitas agregar observaciones y fecha final para poder completar la tarea'
        );
      }

      await this._kanban.updateTask(
        {
          overhaul: TaskOverhaul.ACTIVE,
          finishDate: new Date(realFinalDate).getTime()
        } as Task,
        event.previousContainer.data[event.previousIndex].key,
        event.previousContainer.data[event.previousIndex].boardKey
      );

      await this._kanban.setLog(
        event.previousContainer.data[event.previousIndex].boardKey,
        event.previousContainer.data[event.previousIndex].key,
        {
          description: `Se envió a revisión con el comentario: ${description}`,
          user: this.db.getReference(`users/${this._user.user.key}`),
          createdAt: new Date().getTime()
        }
      );

      for (const supervisor of <DocumentReference[]>this.indexedBoards[event.previousContainer.data[event.previousIndex].boardKey].supervisors) {
        this._notification.setUser(supervisor.id, {
          createdDate: new Date().getTime(),
          description: `El usuario ${this._user.user.name} ${this._user.user.surnames} ha enviado una tarea a revisión con el comentario: ${description}`,
          type: NotificationType.USER_MESSAGE,
          redirectUrl: `admin/kanban/${event.previousContainer.data[event.previousIndex].boardKey}`,
          readed: false,
          trash: false
        });
      }

      await this._kanban.addComment(
        event.previousContainer.data[event.previousIndex].key,
        {
          user: this.db.getReference(`users/${this._user.user.key}`),
          trash: false,
          createdAt: new Date().getTime(),
          comment: description
        },
        event.previousContainer.data[event.previousIndex].boardKey
      );
    }

    if (
      event.previousContainer.data[event.previousIndex].overhaul ==
      TaskOverhaul.COMPLETED &&
      event.previousContainer.id == KanbanStatus.DONE &&
      (event.container.id == KanbanStatus.IN_PROGRESS || event.container.id == KanbanStatus.TODO)
    ) {
      return;
    }

    if (
      event.previousContainer.id == KanbanStatus.TODO &&
      event.container.id == KanbanStatus.IN_PROGRESS
    ) {
      this._kanban.setLog(
        event.previousContainer.data[event.previousIndex].boardKey,
        event.previousContainer.data[event.previousIndex].key,
        {
          description: 'Se envió a en proceso',
          user: this.db.getReference(`users/${this._user.user.key}`),
          createdAt: new Date().getTime()
        }
      );
    }

    if (event.previousContainer.id == event.container.id) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      this._kanban.updateTask(
        { status: +event.container.id } as Task,
        event.previousContainer.data[event.previousIndex].key,
        event.previousContainer.data[event.previousIndex].boardKey
      );
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.updateTasks(event.previousContainer.data);
    }

    this.updateTasks(event.container.data);

    this.sendTaskStatusChangedEmail(event);
  }

  async sendTaskStatusChangedEmail({
                                     previousContainer,
                                     container,
                                     currentIndex
                                   }) {
    const previousStatus = +previousContainer.id;
    const currentStatus = +container.id;

    if (previousStatus == currentStatus) return;

    const previousStatusLabel = this.getCapitalizedStatusLabel(previousStatus);
    const currentStatusLabel = this.getCapitalizedStatusLabel(currentStatus);

    const task: Task = container.data[currentIndex];

    const board = this.indexedBoards[container.data[currentIndex].boardKey];

    const supervisorsEmails = board.supervisors.map((user) => user.email);

    if (!supervisorsEmails.length) return;

    this.spinnerService.show();

    try {
      let usersEmail = (<User[]>task.members).map((user) => user.email);
      await this._notification.sendNotificationEmail(
        `La tarea "${task.title}" del tablero "${board.name}" cambió de estado "${previousStatusLabel}" a "${currentStatusLabel}" por el usuario ${this._user.user?.name || ''} ${this._user.user?.surnames || ''}`,
        `Tareas: tarea "${task.title}" cambió de estado por el usuario ${this._user.user?.name || ''} ${this._user.user?.surnames || ''}`,
        usersEmail.concat(supervisorsEmails)
      );
    } catch (e) {
      this.spinnerService.hide();
      console.error(e);
    }

    this.spinnerService.hide();
  }

  getCapitalizedStatusLabel(status: number) {
    const statusLabel = this.getStatusLabel(status);

    let [first, ...rest]: string[] = statusLabel.split(' ');

    first = this.titleCasePipe.transform(first);

    return [first, ...rest].join(' ');
  }

  getStatusLabel(status: number) {
    return KanbanStatusLabel[status];
  }

  updateTasks(data: Task[]) {
    for (const index in data) {
      this._kanban.updateTask(
        { index: +index } as Task,
        data[index].key,
        data[index].boardKey
      );
    }
  }

  filterTasks() {
    this.todo = _.orderBy(
      this.tasks
        .filter((item) => item.status == KanbanStatus.TODO)
        .filter(
          (item) =>
            !this.userFilter ||
            item.members.some((member) => !!member && member.key == this.userFilter.key)
        )
        .filter(task => !this.project || (task.project && task.project.id == this.project.key)),
      'index',
      'asc'
    );

    this.inProgress = _.orderBy(
      this.tasks
        .filter((item) => item.status == KanbanStatus.IN_PROGRESS)
        .filter(
          (item) =>
            !this.userFilter ||
            item.members.some((member) => !!member && member.key == this.userFilter.key)
        )
        .filter(task => !this.project || (task.project && task.project.id == this.project.key)),
      'index',
      'asc'
    );

    this._kanban.pendingTasks = this.todo.concat(this.inProgress);

    this.done = _.orderBy(
      this.tasks
        .filter((item) => item.status == KanbanStatus.DONE)
        .filter(
          (item) =>
            !this.userFilter ||
            item.members.some((member) => !!member && member.key == this.userFilter.key)
        )
        .filter(task => !this.project || (task.project && task.project.id == this.project.key)),
      'index',
      'asc'
    ).filter((item) =>
      moment(item.finishDate).isBetween(
        moment(this.startDate).startOf('day'),
        moment(this.finalDate).endOf('day')
      )
    );

    this.todo = this.getFilterTasksByValidity(this.todo, KanbanStatus.TODO);
    this.inProgress = this.getFilterTasksByValidity(
      this.inProgress,
      KanbanStatus.IN_PROGRESS
    );
    this.done = this.getFilterTasksByValidity(this.done, KanbanStatus.DONE);

    this.filteredTasks$ = of([...this.todo, ...this.inProgress, ...this.done]);
  }

  getFilterTasksByValidity(tasks: Task[], status) {
    return tasks.filter((task) =>
      this.validateFilterTaskByValidity({ ...task, status })
    );
  }

  validateFilterTaskByValidity(task: Task) {
    switch (this.validitySelected) {
      case this.expirationFilterEnum.ALL:
        return true;
      case this.expirationFilterEnum.EXPIRED:
        return moment(task.expiratedDate).isBefore(moment().startOf('day'));
      case this.expirationFilterEnum.NOT_EXPIRED:
        if (task.status == KanbanStatus.DONE) {
          return moment(task.finishDate).isSameOrBefore(
            moment().startOf('day')
          );
        } else {
          return moment(task.expiratedDate).isSameOrAfter(
            moment().startOf('day')
          );
        }
    }
  }

  async handleReports() {
    this.filterTasksByUser(this._user.user);
  }

  async filterTasksByUser(userSelected: User) {
    let [startDate, endDate] = await AlertService.withHtml(
      'Seleccionar rango de fechas',
      `
        <label>Fecha desde:</label>
        <input id="swal-input1" class="swal2-input" value="${this.startDate}" type="date">
        <label>Hasta:</label>
        <input id="swal-input2" class="swal2-input" value="${this.finalDate}" type="date">`,
      function() {
        return new Promise(function(resolve) {
          resolve([$('#swal-input1').val(), $('#swal-input2').val()]);
        });
      }
    );

    if (!startDate || !endDate) return;
    let doneTasks = this.tasks
      .filter((task) => !!task.members)
      .filter(
        (task) =>
          task.status == KanbanStatus.DONE &&
          ((<any[]>task.members)
            .filter((member) => !!member)
            .some((member) => member.key == userSelected.key)) &&
          moment(task.finishDate)
            .endOf('day')
            .isBetween(moment(startDate).startOf('day'), endDate)
      )
      .map((task) => ({
        ...task,
        totalProgress: 100
      }));

    let pendingTasks = await Promise.all(
      this.tasks
        .filter(
          (task) =>
            task.status != KanbanStatus.DONE &&
            task.members.some((member) => !!member && (member.key == userSelected.key)) &&
            task.isAvailable &&
            task.expiratedDate <=
            moment(endDate).endOf('day').toDate().getTime()
        )
        .map(async (task) => ({
          ...task,
          totalProgress: await this.getTotalProgress(task)
        }))
    );

    let tasks = doneTasks.concat(pendingTasks);

    tasks = _.orderBy(
      tasks.map((task) => ({
        ...task,
        points: moment(task.expiratedDate)
          .endOf('day')
          .isBetween(moment(startDate).startOf('day'), endDate)
          ? 1
          : moment(endDate)
            .startOf('month')
            .diff(moment(task.expiratedDate).startOf('month'), 'month') *
          this.settings.penaltyPoints
      })),
      'status',
      'desc'
    );

    this.modal.show(KanbanReportComponent, {
      initialState: {
        tasks,
        user: userSelected,
        totalPending: pendingTasks.length,
        totalDone: doneTasks.length,
        totalPercent:
          (doneTasks.length * 100) / (pendingTasks.length + doneTasks.length)
      },
      class: 'modal-xl'
    });
  }

  async getTotalProgress(task) {
    let checklist = await this._checklist
      .getAll(task.boardKey, task.key)
      .pipe(take(1))
      .toPromise();

    if (checklist.length == 0) {
      return task.status == KanbanStatus.DONE ? 100 : 0;
    }

    let checkedTasks = checklist.filter(
      (item) =>
        item.checked ||
        (!!item.task &&
          this.tasks.find((task) => task.key == item.task.id).status ==
          KanbanStatus.DONE)
    ).length;

    if (checkedTasks == 0) return 0;

    return (checkedTasks / checklist.length) * 100;
  }

  setDateRange() {
    this.startDate = moment(this.monthSelected)
      .startOf('month')
      .format('YYYY-MM-DD');
    this.finalDate = moment(this.monthSelected)
      .endOf('month')
      .format('YYYY-MM-DD');

    this.filterTasks();
  }

  openTask(task: Task) {
    this.modal.show(KanbanModalComponent, {
      initialState: {
        item: { ...task },
        boardKey: task.boardKey,
        userPermission: this.userPermission
      },
      class: 'modal-xlg'
    });
  }

  addZoom() {
    if (!!localStorage.tasksZoom) {
      localStorage.setItem('tasksZoom', (+this.zoom + 0.1).toString());
    } else {
      localStorage.setItem('tasksZoom', (1 + +this.zoom + 0.1).toString());
    }
    this.zoom = localStorage.getItem('tasksZoom');
  }

  substractZoom() {
    if (!!localStorage.tasksZoom) {
      localStorage.setItem('tasksZoom', (+this.zoom - 0.1).toString());
    } else {
      localStorage.setItem('tasksZoom', (1 + +this.zoom - 0.1).toString());
    }
    this.zoom = localStorage.getItem('tasksZoom');
  }

  private getProjects() {
    this.projectSubscription = this._project
      .getAll()
      .pipe(
        map((project) =>
          project.map((project) => ({
            ...project,
            type:
              project.type == CostCenterType.PROJECT
                ? 'Proyectos'
                : 'Centro de Costos'
          }))
        )
      )
      .subscribe(async (data) => {
        this.selectArray = data;
      });
  }

  convertToTime(date) {
    if (date == null) return null;

    let newDeliveryDate = date.replaceAll('-', '/');
    return new Date(newDeliveryDate).getTime();
  }
}
