<div class="p-4 row" [formGroup]="userForm">
  <div class="col-12 mb-4 ">
    <h3>Información general</h3>
  </div>

  <div class="col-6">
    <ti-input
      label="Nombre"
      mode="material"
      [control]="formControls.name"
    ></ti-input>
  </div>

  <div class="col-6">
    <ti-input
      label="Correo electrónico"
      mode="material"
      [control]="formControls.email"
    ></ti-input>
  </div>

  <div class="col-6">
    <ti-input
      label="RUT"
      mode="material"
      [control]="formControls.rut"
    ></ti-input>
  </div>

  <div class="col-6">
    <ti-select
      [control]="formControls.position"
      label="Cargo"
      bindLabel="name"
      [options]="selectArrayJobs"
    ></ti-select>
  </div>

  <div class="col-6">
    <ti-input
      label="Teléfono"
      type="number"
      mode="material"
      [control]="formControls.phone"
    ></ti-input>
  </div>

  <div class="col-6">
    <ti-select
    label="Región"
    bindLabel="name"
    [control]="formControls.region"
    [options]="states"
  ></ti-select>
  </div>

  <div class="col-6">
    <ti-select
    label="Comuna"
    bindLabel="name"
    [control]="formControls.city"
    [options]="cities"
  ></ti-select>
  </div>

  <div class="col-6">
    <ti-input
      label="Edad"
      mode="material"
      [control]="formControls.age"
    ></ti-input>
  </div>

  <div class="col-6">
    <ti-input
      type="date"
      label="Fecha de cumpleaños"
      mode="material"
      [control]="formControls.birthday"
    ></ti-input>
  </div>

  <div class="col-6">
    <ti-input
      type="number"
      label="Experiencia laboral (años)"
      mode="material"
      [control]="formControls.workExperience"
    ></ti-input>
  </div>

  <div class="col-6">
    <ti-select
      label="Nivel educacional"
      [control]="formControls.educationLevel"
      [options]="educationLevelArray"
    ></ti-select>
  </div>

  <div class="col-6">
    <ti-select
      label="Nacionalidad"
      [control]="formControls.nationality"
      [options]="countries"
    ></ti-select>
  </div>

  <div class="col-12 d-flex justify-content-end">
    <button class="btn btn-success" (click)="submit()">Guardar</button>
    <div class="btn btn-danger ml-2" (click)="modal.hide()">Cancelar</div>
  </div>
</div>
