<div
  id="invoice-items-details"
  class="pt-2">
  <div class="row">
    <div class="table-responsive col-sm-12">
      <table class="table">
        <thead>
        <tr>
          <th>CANTIDAD</th>
          <th>UNIDAD</th>
          <th>DESCRIPCIÓN</th>
          <th>VALOR UNITARIO</th>
          <th>% DESCUENTO</th>
          <th>VALOR TOTAL</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let articles of articlesPurchaseEdit.articles; let index = index">
          <td>{{articles?.quantity}}</td>
          <td *ngIf="isReference(articles?.unit)">{{(articles?.unit | doc | async)?.name}}</td>
          <td *ngIf="!isReference(articles?.unit)">{{articles?.unit}}</td>
          <td>{{articles?.description}}</td>
          <td>{{articles?.unitValue | clearCurrency | swapCommasAndDots}}</td>
          <td>{{articles?.discount | clearCurrency | swapCommasAndDots}}</td>
          <td>{{articles?.total | clearCurrency | swapCommasAndDots}}</td>
        </tr>

        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>SUB TOTAL</td>
          <td>{{articlesPurchaseEdit?.subtotal | clearCurrency | swapCommasAndDots}}</td>
        </tr>

        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>DESCUENTO</td>
          <td>{{articlesPurchaseEdit?.discount | clearCurrency | swapCommasAndDots}}</td>
        </tr>

        <tr>
          <td>Fecha de presentación OC:</td>
          <td>{{date | date : 'dd/MM/yyyy HH:mm'}}</td>
          <td></td>
          <td></td>
          <td>NETO</td>
          <td>{{articlesPurchaseEdit?.net | clearCurrency | swapCommasAndDots}}</td>
        </tr>

        <tr>
          <td>Fecha de aprobación OC:</td>
          <td>{{approvalDateReceiver ? (approvalDateReceiver | date : 'dd/MM/yyyy HH:mm') : (currentDate | date : 'dd/MM/yyyy HH:mm')}}</td>
          <td>CONDICIÓN DE PAGO:</td>
          <td>{{articlesPurchaseEdit?.paymentType}}</td>
          <td>19% IVA</td>
          <td>{{articlesPurchaseEdit.iva | clearCurrency | swapCommasAndDots}}</td>
        </tr>

        <tr>
          <td>Fecha de entrega:</td>
          <td>
            <input
              *ngIf="ticketStatus == purchaseStatus.DRAFT"
              class="form-control"
              type="date"
              #event
              (change)="convertToTime(event)">
            <span *ngIf="deliveryReceiver">{{deliveryReceiver | date : 'dd/MM/yyyy' : 'UTC'}}</span>
          </td>
          <td>MONEDA Y TIPO DE CAMBIO:</td>
          <td>{{articlesPurchaseEdit.currency}}</td>
          <td>TOTAL</td>
          <td>{{articlesPurchaseEdit.total | clearCurrency | swapCommasAndDots}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

