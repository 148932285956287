import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {Observable, Subscription} from 'rxjs';
import {BsModalService} from 'ngx-bootstrap/modal';
import {CostCenterPaymentsService} from '../../services/cost-center-payments.service';
import {CostCenterPaymentsModalComponent} from '../../modals/cost-center-payments-modal/cost-center-payments-modal.component';
import {CostCenterPayments} from '../../interfaces/cost-center-payments';
import {take} from 'rxjs/operators';
import {PaymentCategory} from '../../interfaces/payment-category';
import {PaymentCategoryService} from '../../services/payment-category.service';
import {PurchaseStatus} from '../../enums/purchase-status.enum';

@Component({
  selector: 'app-cost-center-payments',
  templateUrl: './cost-center-payments.component.html',
  styleUrls: ['./cost-center-payments.component.css']
})
export class CostCenterPaymentsComponent implements OnInit, OnDestroy {
  @Input() costCenterKey: string;
  config: DataTableConfig = {hasSearch: true, notFoundText: 'No se encontraron pagos', title: 'Pagos'};
  payments$: Observable<CostCenterPayments[]>;
  paymentCategories: PaymentCategory[];
  paymentCategoriesSubscription: Subscription = new Subscription();

  constructor(private _payments: CostCenterPaymentsService,
              private modal: BsModalService,
              private _paymentCategory: PaymentCategoryService) {
  }

  async ngOnInit(): Promise<void> {
    this.paymentCategoriesSubscription = this._paymentCategory.getAll(this.costCenterKey).subscribe(data => {
      this.paymentCategories = data;
    });

    this.payments$ = this._payments.getAll(this.costCenterKey);
  }

  ngOnDestroy() {
    this.paymentCategoriesSubscription.unsubscribe();
  }

  openPaymentEdit(payment) {
    this.modal.show(CostCenterPaymentsModalComponent, {
      initialState: {
        payment: payment,
        costCenterKey: this.costCenterKey,
        isUpdate: true
      },
      id: 34
    });
  }

  getCategory(categoryReference) {
    let category = this.paymentCategories.find(category => category.key == categoryReference.id);
    return category.name;
  }

  getPaidClass(paid) {
    switch (paid) {
      case true:
        return 'badge-success';

      case false:
        return 'badge-primary';
    }
  }
}

