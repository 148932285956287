import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';
import {CostCenterPayments} from '../../interfaces/cost-center-payments';
import {CostCenterPaymentsService} from '../../services/cost-center-payments.service';
import {formatDate} from '@angular/common';
import {PaymentCategoryService} from '../../services/payment-category.service';
import {PaymentCategory} from '../../interfaces/payment-category';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-cost-center-payments-modal',
  templateUrl: './cost-center-payments-modal.component.html',
  styleUrls: ['./cost-center-payments-modal.component.css']
})
export class CostCenterPaymentsModalComponent implements OnInit {
  payment: CostCenterPayments = {key: ''} as CostCenterPayments;
  paymentForm: FormGroup;
  submitted: boolean = false;
  isUpdate: boolean = false;
  costCenterKey: string;
  paymentCategories: PaymentCategory[];
  itHasUpdated: boolean = false;
  date: any;
  oldDate: any;

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalService,
              private _payments: CostCenterPaymentsService,
              private db: FirebaseDataService,
              private _paymentCategory: PaymentCategoryService) {
    this.paymentForm = formBuilder.group({
      payment: ['', Validators.required],
      category: ['', Validators.required],
      date: [this.formatDate(new Date().getTime()), Validators.required],
      paid: [false],
      createdAt: [''],
      trash: [false],
      centerCostKey: [''],
      paymentDate: [''],
      deliveryDateChanged: [false]
    });
  }

  async ngOnInit() {    
    this.paymentCategories = await this._paymentCategory.getAll(this.costCenterKey).pipe(take(1)).toPromise();    
    this.paymentForm.patchValue({centerCostKey: this.costCenterKey});
    if (this.isUpdate) {
      this.oldDate = this.payment.date;
      this.payment.date = this.formatDate(this.payment.date);
      this.paymentForm.patchValue(this.payment);
      let category = this.paymentCategories.find(category => category.key == this.payment.category.id);
      this.paymentForm.patchValue({category: category});      
    }
    console.log(this.paymentForm.value);
  }

  get formControls() {
    return this.paymentForm.controls;
  }

  async submit() {    
    this.submitted = true;
    if (this.paymentForm.valid) {

      if (!this.isUpdate) {
        this.payment.key = this.db.createID();
        this.paymentForm.patchValue({createdAt: new Date().getTime()});
      }

      this.date = this.paymentForm.value.date;

      this.paymentForm.patchValue({category: await this.db.getReference(`costCenters/${this.costCenterKey}/paymentCategories/${this.paymentForm.value.category.key}`),
                                        date: await this.convertToTime(this.paymentForm.value.date)});

      if (this.oldDate != this.paymentForm.value.date) this.paymentForm.patchValue({deliveryDateChanged: true});

      await this._payments.set(this.costCenterKey, this.payment.key, this.paymentForm.value);
      this.itHasUpdated = true;

      this.modal.hide(34);
    }
  }

  private formatDate(date) {
    const deliveryDateDate = new Date(date);
    const format = 'yyyy/MM/dd';
    const locale = 'en-US';
    const zone = 'UTC';
    const formattedDate = formatDate(deliveryDateDate, format, locale, zone);
    date = formattedDate.replace(/\//g, '-');
    return date;
  }

  convertToTime(date) {
    let newDeliveryDate = date.replaceAll('-', '/');
    let deliveryDateMilliseconds = new Date(newDeliveryDate).getTime();
    return deliveryDateMilliseconds;
  }

  updateActualPaymentDate() {
    if (!this.paymentForm.value.isPaid) return this.paymentForm.patchValue({paymentDate: this.formatDate(new Date().getTime())});
    this.paymentForm.patchValue({paymentDate: null});
  }
}
