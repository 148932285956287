import { Component, OnInit } from '@angular/core';
import { Bill } from '../../../../interfaces/bill';
import { BillDataService } from '../../services/bill-data.service';

@Component({
  selector: 'app-bill-general',
  templateUrl: './bill-general.component.html',
  styleUrls: ['./bill-general.component.scss']
})
export class BillGeneralComponent implements OnInit {
  bill: Bill;

  constructor(private _billDataService: BillDataService) {
  }

  ngOnInit(): void {
    this.bill = this._billDataService.bill;
  }

  get billClassifyBadge() {
    return this._billDataService.bill.isUsed
      ? {
        badgeType: 'badge-success',
        tooltip: 'Clasificada',
        icon: 'la-check'
      }
      : {
        badgeType: 'badge-primary',
        tooltip: 'No clasificada',
        icon: 'la-close'
      };
  }

  get billPaidBadge() {
    return this._billDataService.bill.isPaid
      ? {
        badgeType: 'badge-info',
        tooltip: 'Pagada'
      }
      : {
        badgeType: 'badge-primary',
        tooltip: 'No pagada'
      };
  }

}
