<mat-tab-group (click)="setLogTabIsActive()">
  <mat-tab label="Rendición">
    <h3 class="font-weight-bold mt-1">{{ accountability?.title }}

      <span
        *ngIf="!!accountability"
        class="badge mx-1"
        [ngClass]="accountabilityStatusClasses[accountability?.status]">
        {{ accountabilityStatusLabel[accountability?.status] }}
      </span>

    </h3>
    <div class="card p-1">
      <div class="row">
        <div class="col-md-6">
          <p><span class="font-weight-bold">ID:</span> {{ fundToRender?.id }}</p>
          <p>
            <span class="font-weight-bold">Usuario: </span>{{ fundUser?.fullname }}
          </p>
          <p>
            <span class="font-weight-bold">Motivo del fondo: </span>
            {{ fundToRender?.reason }}
          </p>
          <p>
            <span class="font-weight-bold">Proyecto:</span>
            {{
            !!fundToRender?.project
              ? (fundToRender.project | doc | async)?.name
              : '-'
            }}
          </p>
          <div
            class="mt-1 mb-1 mb-md-0"
            *ngIf="accountability?.status >= accountabilityStatus.PAID_COLSAN || accountability?.status == accountabilityStatus.CLOSED">
            <a
              [href]="this.accountability?.paidReceiptUrl"
              target="_blank">
              <button class="btn btn-primary">Clic para ver comprobante de pago</button>
            </a>
          </div>
        </div>

        <div class="col-md-6">
          <p>
            <span class="font-weight-bold">Monto solicitado: </span>
            {{ requiredAmount | currency : 'USD' : 'symbol' : '1.0-0'}}
          </p>
          <p>
            <span class="font-weight-bold">Monto recibido: </span>
            {{ fundToRender?.receivedAmount | currency : 'USD' : 'symbol' : '1.0-0'}}
          </p>
          <p>
            <span class="font-weight-bold">Total de gastos: </span>
            {{ totalReceiptsAmount | currency : 'USD' : 'symbol' : '1.0-0'}}
          </p>
          <p class="mb-0">
            <span class="font-weight-bold">Saldo final: </span>
            <span>{{ (!!accountability?.finalBalance || accountability?.finalBalance == 0 ? accountability?.finalBalance : finalBalance) | currency : 'USD' : 'symbol' : '1.0-0'}}</span>

            <button
              class="btn btn-primary btn-small ml-2"
              *ngIf="permission == userTypeEnum.ADMIN || permission == userTypeEnum.SUPERVISOR"
              (click)="changeFinalBalance()">Cambiar monto
            </button>
          </p>

          <button
            *ngIf="accountability?.status == accountabilityStatus.PAID_COLSAN || accountability?.status == accountabilityStatus.PAID_USER"
            class="btn btn-primary mt-1"
            (click)="closeAccountability()">
            Cerrar rendición
          </button>
        </div>
      </div>
    </div>

    <div
      class="flex-row"
      *ngIf="userSendReceiptSupervision()">
      <button
        class="btn btn-info mr-1"
        (click)="sendToSupervisor()">
        Enviar a supervisión
      </button>
    </div>

    <div
      class="flex-row"
      *ngIf="supervisorReceiptsChecked()">
      <button
        class="btn btn-info mr-1"
        (click)="sendAccountabilityToReview()">
        Enviar a aprobación
      </button>
    </div>

    <div *ngIf="permission == userTypeEnum.ADMIN && accountability?.status == accountabilityStatus.REVIEW">
      <button
        class="btn btn-success mr-1"
        (click)="authorize()">Aceptar
      </button>

      <button
        class="btn btn-danger mr-1"
        (click)="reject()">Rechazar
      </button>
    </div>

    <div
      class="mt-1"
      *ngIf="(validateUserPermission(userTypeEnum.SUPERVISOR) && accountability?.status == accountabilityStatus.CLOSED_USER) || accountability?.status == accountabilityStatus.CLOSED_COLSAN">
      <div>
        <app-upload-file-storage
          class="d-none"
          label="Imagen del comprobante"
          extension="png"
          controlName="debtPaidReceiptUrl"
          fileType="image/*,application/pdf"
          [showPreview]="false"
          (isImageLoaded)="handleImageLoaded($event)"
          (pathEmitter)="setPath($event)">
        </app-upload-file-storage>
        <div
          class="alert alert-info d-inline-block"
          role="alert">
          Para finalizar el proceso debe adjuntar el comprobante del pago
        </div>
      </div>

      <div class="d-flex">
        <button
          class="btn btn-primary mr-1"
          (click)="handleUploadReceiptButtonClick()">
          Subir comprobante
        </button>
      </div>
      <div
        class="mt-1"
        *ngIf="!!debtPaidReceiptUrl && accountability?.status == accountabilityStatus.CLOSED_USER">
        <img
          style="width: 100px; height: 100px"
          [src]="debtPaidReceiptUrl">
      </div>
    </div>

    <h2 class="text-center pb-2 mb-2">Comprobantes</h2>
    <ng-container *ngIf="receipts.length > 0; else notFound">
      <div class="table-responsive">
        <table
          class="table table-bordered table-hover"
          ngxViewer>
          <thead class="header">
          <tr>
            <th>Descripción</th>
            <th>Categoría</th>
            <th>Monto</th>
            <th>Imagen</th>
            <th>Estatus</th>
            <th>Opciones</th>
          </tr>
          </thead>
          <tbody>
          <tr
            *ngFor="let receipt of receipts">
            <td data-label="Descripción">
              {{ receipt.description }}
            </td>
            <td data-label="Categoría">
              {{receipt.category.name}}
            </td>
            <td data-label="Monto">
              {{ receipt.amount | currency: 'USD':'symbol':'1.0-0' }}
            </td>
            <td
              data-label="Imagen"
              class="d-flex">
              <div class="d-flex flex-column align-items-center">
                <img [src]="receipt.photoUrl" />
                <a
                  [href]="receipt.photoUrl"
                  target="_blank">Abrir archivo</a>
              </div>
              <div>
                <button
                  class="btn btn-primary ml-1"
                  tooltip="Descargar imagen"
                  (click)="downloadImage(receipt.photoUrl)"><i class="fa fa-download"></i></button>
              </div>
            </td>
            <td data-label="Estatus">
              <div
                class="badge"
                [ngClass]="statusClasses[receipt.status]">
                {{ accountabilityReceiptStatusLabel[receipt.status] }}
              </div>
            </td>
            <td data-label="Opciones">
              <div class="d-flex justify-content-end">
                <button
                  *ngIf="isOnSupervision()"
                  class="btn btn-success mr-1"
                  ngbTooltip="Aceptar"
                  (click)="acceptReceiptSupervisor(receipt)">
                  <i class="la la-check"></i>
                </button>

                <button
                  *ngIf="isOnSupervision()"
                  class="btn btn-danger mr-1"
                  ngbTooltip="Rechazar"
                  (click)="rejectReceiptSupervisor(receipt)">
                  <i class="la la-times"></i>
                </button>

                <button
                  *ngIf="validateEditButtonsDisplay(receipt)"
                  class="btn btn-success mr-1"
                  ngbTooltip="Editar"
                  (click)="openEditReceipt(receipt)">
                  <i class="la la-edit"></i>
                </button>

                <button
                  *ngIf="validateEditButtonsDisplay(receipt)"
                  class="btn btn-danger"
                  ngbTooltip="Eliminar"
                  (click)="deleteReceipt(receipt)">
                  <i class="la la-trash"></i>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </mat-tab>

  <mat-tab
    label="Registro"
    #logTab
    *ngIf="validateUserPermission(userTypeEnum.USER)">
    <ng-container *ngIf="logTabIsActive">
      <app-receipts-logs></app-receipts-logs>
    </ng-container>
  </mat-tab>
</mat-tab-group>

<app-add-float-button
  (clickEmmiter)="openAddReceipt()"
  *ngIf="validateUserPermission(userTypeEnum.USER) &&
        validateAccountabilityStatus(
          accountability,
          accountabilityStatus.SUPERVISION)">
</app-add-float-button>

<ng-template #notFound>
  <app-not-found
    size="medium"
    title="No se encontraron resultados"></app-not-found>
</ng-template>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
><p style="color: white">Subiendo...</p></ngx-spinner
>
