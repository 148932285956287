<div class="row mt-2">
  <div class="col-lg-8 col-sm-12">
    <m-card [options]="hitRateOptions" *ngIf="!!donutChart2">
      <ng-container mCardHeaderTitle>
        Total bodega almacenables/consumibles {{(totalArticles + totalArticlesConsumable) | clearCurrency | swapCommasAndDots}}
      </ng-container>
      <ng-container mCardBody>
        <div class="height-200 donut-chart2">
          <x-chartist *ngIf="!!donutChart2" [data]="donutChart2.data" [type]="donutChart2.type"
                      [options]="donutChart2.options"
                      [responsiveOptions]="donutChart2.responsiveOptions" [events]="donutChart2.events">
          </x-chartist>
        </div>

        <div class="card-body pt-0 mt-2">
          <div class="row">
            <div class="col-md-3 col-12 border-right-blue-grey border-right-lighten-5 text-center">
              <h4
                class="font-large-1 text-bold-400 text-success">{{totalStored | clearCurrency : false | swapCommasAndDots}}</h4>
              <p class="lighten-2 mb-0 text-success">Almacenado actualmente</p>
            </div>
            <div class="col-md-3 col-12 text-center">
              <h4
                class="font-large-1 text-bold-400 text-warning">{{totalRetired | clearCurrency : false | swapCommasAndDots}}</h4>
              <p class="lighten-2 mb-0 text-warning">Total de $ Artículos por devolver</p>
            </div>
            <div class="col-md-3 col-12 text-center">
              <h4
                class="font-large-1 text-bold-400 text-danger">{{totalStoredConsumable | clearCurrency : false | swapCommasAndDots}}</h4>
              <p class="lighten-2 mb-0 text-danger">Consumibles almacenados</p>
            </div>
            <div class="col-md-3 col-12 text-center">
              <h4
                class="font-large-1 text-bold-400 text-danger">{{(100 - ((((this.totalStored) * 100) / (totalArticles + totalArticlesConsumable)))) | number: '1.0-2'}}
                %</h4>
              <p class="lighten-2 mb-0 text-danger">Porcentaje artículos consumibles</p>
            </div>
          </div>
        </div>
      </ng-container>
    </m-card>
  </div>

  <div class="col-lg-4 col-sm-12">
    <m-card [options]="hitRateOptions">
      <ng-container mCardHeaderTitle>
        Tickets
      </ng-container>
      <ng-container mCardBody>

        <div class="pt-0">
          <div class="row">
            <div class="col-6">
              <div class="card pull-up bg-cyan">
                <div class="card-content">
                  <div class="card-body">
                    <div class="media d-flex">
                      <div class="media-body text-left">
                        <h6 class="text-center text-white">Generados </h6>
                        <h3 class="text-white text-center">{{tickets.generated}}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card pull-up bg-blue">
                <div class="card-content">
                  <div class="card-body">
                    <div class="media d-flex">
                      <div class="media-body text-left">
                        <h6 class="text-center text-white">Autorizados </h6>
                        <h3 class="text-white text-center">{{tickets.authorized}}</h3>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="card pull-up bg-warning">
                <div class="card-content">
                  <div class="card-body">
                    <div class="media d-flex">
                      <div class="media-body text-left">
                        <h6 class="text-center text-white">Retirados </h6>
                        <h3 class="text-white text-center">{{tickets.retired}}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="card pull-up bg-danger">
                <div class="card-content">
                  <div class="card-body">
                    <div class="media d-flex">
                      <div class="media-body text-left">
                        <h6 class="text-center text-white">Incompletos </h6>
                        <h3 class="text-white text-center">{{tickets.incomplete}}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card pull-up bg-success">
                <div class="card-content">
                  <div class="card-body">
                    <div class="media d-flex">
                      <div class="media-body text-left">
                        <h6 class="text-center text-white">Finalizados </h6>
                        <h3 class="text-white text-center">{{tickets.returned}}</h3>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </m-card>
  </div>

  <div class="col-md-12">
    <div class="d-flex align-items-end mb-1">
      <div class="d-flex flex-column mr-1">
        <ng-select [searchable]="false"
                   [(ngModel)]="dateRangeTypeSelected"
                   (ngModelChange)="handleDateRangeTypeSelected()"
                   [clearable]="false">
          <ng-option [value]="dateRangeType.WEEKLY">
            Semanal
          </ng-option>
          <ng-option [value]="dateRangeType.MONTHLY">
            Mensual
          </ng-option>
          <ng-option [value]="dateRangeType.YEARLY">
            Anual
          </ng-option>
        </ng-select>
      </div>

      <div class="animated fadeIn"
           *ngIf="dateRangeTypeSelected == dateRangeType.WEEKLY">
        <label>Seleccione la semana</label>
        <input
          type="week"
          class="form-control"
          [(ngModel)]="dateSelected"
          (ngModelChange)="assignStartDateAndFinalDate()">
      </div>
      <div
        class="animated fadeIn"
        *ngIf="dateRangeTypeSelected == dateRangeType.MONTHLY">
        <label>Seleccione el mes</label>
        <input
          type="month"
          class="form-control"
          [(ngModel)]="dateSelected"
          (ngModelChange)="assignStartDateAndFinalDate()">
      </div>

      <div *ngIf="dateRangeTypeSelected == dateRangeType.YEARLY">
        <label>Seleccione el año</label>
        <ng-select
          [searchable]="false"
          [(ngModel)]="dateSelected"
          (ngModelChange)="assignStartDateAndFinalDate()"
          [clearable]="false">
          <ng-option [value]="year" *ngFor="let year of years">
            {{year}}
          </ng-option>
        </ng-select>
      </div>

      <div class="ml-1">
        <button class="btn btn-danger ml-1"
                (click)="filterMostRetiredArticlesByDate()"
        >Buscar
        </button>
      </div>

    </div>
    <m-card>
      <ng-container mCardHeaderTitle>
        <div class="text-center font-large-1">
          Artículos más retirados
        </div>
      </ng-container>
      <ng-container mCardBody *ngIf="this.mostRetiredArticles.length > 0; else notFound">
        <div class="d-flex justify-content-center">
          <div class="w-50">
            <canvas baseChart
                    [data]="chartData"
                    [labels]="chartLabels"
                    [chartType]="chartType"
                    [options]="chartOptions"
            ></canvas>
          </div>
        </div>

      </ng-container>

      <ng-template #notFound>
        <app-not-found size="medium" [title]="config.notFoundText"></app-not-found>
      </ng-template>

    </m-card>
  </div>
</div>
