<div class="row mt-2">
  <div class="col-12">
    <m-card [options]="hitRateOptions">
      <ng-container mCardHeaderTitle>
        Total: {{total | clearCurrency | swapCommasAndDots}}
      </ng-container>
      <ng-container mCardBody>

        <div class="card-body pt-0 mt-2">
          <div class="row">
            <div class="col-md-6 col-12 border-right-blue-grey border-right-lighten-5 text-center">
              <h4
                class="font-large-2 text-bold-400 text-success">{{totalExpensesForTheWeek | clearCurrency | swapCommasAndDots}}</h4>
              <p class="lighten-2 mb-0 text-success">Gastos totales de la semana</p>
            </div>
            <div class="col-md-6 col-12 text-center">
              <h4
                class="font-large-2 text-bold-400 text-danger">{{pendingExpenses | clearCurrency | swapCommasAndDots}}</h4>
              <p class="lighten-2 mb-0 text-danger">Gastos pendientes</p>
            </div>
          </div>
        </div>
      </ng-container>
    </m-card>
  </div>
</div>

<ng-container *ngIf="overdueCostsLoad">
  <app-datatable [rows$]="purchases$"
                 [config]="config"
                 (rowSelected)="gotoOpenEditPurchaseOrder($event)">

    <app-datatable-column title="OC">
      <ng-template let-row #column>
        {{ row.purchaseID | slice:0 : -3 }}<span class="text-muted font-size-xsmall">#{{row?.purchaseID | slice:-3 }}</span>
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Proveedor">
      <ng-template let-row #column>
        {{ getProvider(row.provider)?.name }}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="RUT">
      <ng-template let-row #column>
        {{ getProvider(row.provider)?.rut }}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Monto">
      <ng-template let-row #column>
        {{ row.total | clearCurrency | swapCommasAndDots}}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Fecha de entrega">
      <ng-template let-row #column>
        {{ row?.deliveryDate | date : 'dd/MM/yyyy' }}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="">
      <ng-template let-row #column>
      <span class="badge badge-pill"
            [ngClass]="getStatusClass(row.status)">{{purchaseStatusLabel[row.status]}}</span>
      </ng-template>
    </app-datatable-column>
  </app-datatable>
</ng-container>

