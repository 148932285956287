<app-datatable [rows$]="status$"
               [config]="config"
               (rowSelected)="openUpdateStatusModal($event, $event.key)">
  <app-datatable-column title="Cargo">
    <ng-template let-row #column>
      {{row?.name}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false" [isVisiblePrint]="false">
    <ng-template let-row #column>
      <button class="btn btn-danger btn-sm" (click)="deleteStatus(row.key)">Eliminar</button>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="openAddStatusModal()"></app-add-float-button>
