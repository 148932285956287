import {Component, Input, OnInit} from '@angular/core';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {BsModalService} from 'ngx-bootstrap/modal';
import {PaymentCategoryService} from '../../services/payment-category.service';
import {Observable} from 'rxjs';
import {PaymentCategory} from '../../interfaces/payment-category';
import {PaymentCategoryModalComponent} from '../../modals/payment-category-modal/payment-category-modal.component';

@Component({
  selector: 'app-payment-categories',
  templateUrl: './payment-categories.component.html',
  styleUrls: ['./payment-categories.component.css']
})
export class PaymentCategoriesComponent implements OnInit {
  @Input() costCenterKey: string;
  config: DataTableConfig = {hasSearch: true, notFoundText: 'No se encontraron categorías de pago', title: 'Categorías de pagos'};
  paymentCategories$: Observable<PaymentCategory[]>;

  constructor(private _paymentCategory: PaymentCategoryService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.paymentCategories$ = this._paymentCategory.getAll(this.costCenterKey);
  }

  openPaymentCategoryEdit(paymentCategory) {
    this.modal.show(PaymentCategoryModalComponent, {
      initialState: {
        paymentCategory: paymentCategory,
        costCenterKey: this.costCenterKey,
        isUpdate: true
      }
    });
  }
}
