<div>
  <div class="custom-modal-header modal-header">
    <h1>Rango salarial</h1>
    <i (click)="closeModal()" class="la la-close"></i>
  </div>
  <div class="select-container  col-md-12">
    <label>Seleccionar cargo:</label>
    <ng-select
      [items]="positions"
      [clearable]="false"
      [(ngModel)]="selectedOption"
      bindValue="position"
      class="position-select"
      bindLabel="name"
      (change)="selectFilter()"
    >
    </ng-select>
  </div>
  <div class="graph-container">
    <canvas id="canvas" #canvas></canvas>
  </div>
  <div class="d-flex align-content-center justify-content-center mb-3">
    <button class="btn btn-primary" (click)="openSalaryRangeForm()">
      Modificar rango salarial
    </button>
  </div>
</div>
