import { Component, OnInit } from '@angular/core';
import { ApplicantService } from '../../../shared/services/applicant.service';
import { Observable } from 'rxjs';
import { Applicant } from '../../interfaces/applicant';
import { StatusService } from '../../../shared/services/status.service';
import { JobService } from '../../../shared/services/job.service';
import { ObjectService } from '../../../shared/template-services/object.service';
import _ from 'lodash';
import { ProjectService } from '../../../shared/services/project.service';
import { take } from 'rxjs/operators';
import { States } from '../../../../assets/json/states';
import { Cities } from '../../../../assets/json/cities';
import { Router } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  applicantsFiltered$: Observable<Applicant[]>;
  city: any = { name: '' };
  graphicStatus = [];
  graphicPosition = [];
  graphicCity = [];
  graphicProject = [];
  graphicState = [];
  applicants: Applicant[] = [];
  loadGraph: boolean = false;
  view: any[] = [1122, 250];
  gradient = false;
  colorScheme = {
    domain: ['#2ecc71', '#e74c3c', '#e67e22', '#95a5a6', '#3498db', '#9b59b6', '#34495e', '#f1c40f', '#1abc9c', '#16a085', '#2980b9', '#8e44ad', '#2c3e50', '#f39c12', '#d35400', '#c0392b', '#bdc3c7', '#7f8c8d']
  };
  indexedProjects = {};
  indexedPositions = {};
  indexedStatus = {};
  lastFiveApplicants: Applicant[] = [];
  zoom = 12;
  applicantStatus: string[] = [];
  lastFilterStatus: string = '';
  isFilteringByStatus: boolean = false;
  isFilteringByWeek: boolean = false;
  isFilteringByMonth: boolean = false;
  isFilteringByYear: boolean = false;

  constructor(private _applicant: ApplicantService,
              private _status: StatusService,
              private _position: JobService,
              private router: Router,
              private _project: ProjectService) {
  }

  async ngOnInit() {
    this.applicantsFiltered$ = this._applicant.getAll();
    this.indexedProjects = ObjectService.indexArray(await this._project.getAllWithDeleted().pipe(take(1)).toPromise(), 'key');
    this.indexedPositions = ObjectService.indexArray(await this._position.getAll().pipe(take(1)).toPromise(), 'key');

    const status = await this._status.getAll().pipe(take(1)).toPromise();
    this.indexedStatus = ObjectService.indexArray(status, 'name');
    this.applicantStatus = status.map(({ name }) => name);

    await this.setValuesGraph();
  }

  formatApplicants(applicants: Applicant[], prop: string) {
    return _.orderBy(
      Object.entries(_.groupBy(applicants, prop)).map(([key, value]) => ({
        name: key,
        value: value.length
      })),
      'value',
      'desc'
    );
  }

  private setValuesGraph() {
    this.applicantsFiltered$.subscribe(applicants => {
      this.applicants = applicants
        .map(applicant => ({
          ...applicant,
          position: applicant.position.name
            ? applicant.position
            : this.indexedPositions[applicant.position.reference.id],
          region: applicant.region
            ? applicant.region
            : States.find(state => state.id == Cities.find(city => city.name == applicant.city.name)?.state_id),
          projectStatus: applicant.project ? 'Con proyecto' : 'Sin proyecto'
        })).filter(({ status }) => !!this.indexedStatus[status?.name]);

      this.lastFiveApplicants = _.orderBy(this.applicants
        .filter(applicant => applicant.contractDate)
        .slice(0, 10), 'contractDate', 'desc');

      this.graphicStatus = this.formatApplicants(this.applicants, 'status.name');
      this.graphicProject = this.formatApplicants(this.applicants.filter(applicant => applicant.status.name == 'contratado'), 'projectStatus');

      if (this.isFilteringByWeek) return this.filterByWeek();
      if (this.isFilteringByMonth) return this.filterByMonth();
      if (this.isFilteringByYear) return this.filterByYear();
      if (this.isFilteringByStatus && this.filterByStatus) return this.filterByStatus(this.lastFilterStatus);

      this.graphicPosition = this.formatApplicants(this.applicants.filter(applicant => applicant.position), 'position.name');
      this.graphicCity = this.formatApplicants(this.applicants, 'city.name');
      this.graphicState = this.formatApplicants(this.applicants, 'region.name');

      this.loadGraph = true;
    });
  }

  redirectToApplicants(statusName: string) {
    this.router.navigateByUrl(`/admin/postulante-applicants/${statusName}`);
  }

  resetGraphics() {
    this.resetPreviousFilter();
    this.loadGraph = false;

    this.graphicPosition = this.formatApplicants(this.applicants.filter(applicant => applicant.position), 'position.name');
    this.graphicCity = this.formatApplicants(this.applicants, 'city.name');
    this.graphicState = this.formatApplicants(this.applicants, 'region.name');

    setTimeout(() => {
      this.loadGraph = true;
    }, 100);
  }

  resetPreviousFilter() {
    this.isFilteringByStatus = false;
    this.isFilteringByWeek = false;
    this.isFilteringByMonth = false;
    this.isFilteringByYear = false;
  }

  filterByWeek() {
    this.resetPreviousFilter();
    this.loadGraph = false;
    this.isFilteringByWeek = true;

    this.graphicPosition = this.formatApplicants(this.applicants
      .filter(applicant => applicant.createdAt >= moment().subtract(1, 'week').toDate().getTime())
      .filter(applicant => applicant.position), 'position.name');

    this.graphicCity = this.formatApplicants(this.applicants
      .filter(applicant => applicant.createdAt >= moment().subtract(1, 'week').toDate().getTime()), 'city.name');

    this.graphicState = this.formatApplicants(this.applicants
      .filter(applicant => applicant.createdAt >= moment().subtract(1, 'week').toDate().getTime()), 'region.name');

    setTimeout(() => {
      this.loadGraph = true;
    }, 100);
  }

  filterByMonth() {
    this.resetPreviousFilter();
    this.loadGraph = false;
    this.isFilteringByMonth = true;

    this.graphicPosition = this.formatApplicants(this.applicants
      .filter(applicant => applicant.createdAt >= moment().startOf('month').toDate().getTime())
      .filter(applicant => applicant.position), 'position.name');

    this.graphicCity = this.formatApplicants(this.applicants
      .filter(applicant => applicant.createdAt >= moment().startOf('month').toDate().getTime()), 'city.name');

    this.graphicState = this.formatApplicants(this.applicants
      .filter(applicant => applicant.createdAt >= moment().startOf('month').toDate().getTime()), 'region.name');

    setTimeout(() => {
      this.loadGraph = true;
    }, 100);
  }

  filterByYear() {
    this.resetPreviousFilter();
    this.loadGraph = false;
    this.isFilteringByYear = true;

    this.graphicPosition = this.formatApplicants(this.applicants
      .filter(applicant => applicant.createdAt >= moment().startOf('year').toDate().getTime())
      .filter(applicant => applicant.position), 'position.name');

    this.graphicCity = this.formatApplicants(this.applicants
      .filter(applicant => applicant.createdAt >= moment().startOf('year').toDate().getTime()), 'city.name');

    this.graphicState = this.formatApplicants(this.applicants
      .filter(applicant => applicant.createdAt >= moment().startOf('year').toDate().getTime()), 'region.name');

    setTimeout(() => {
      this.loadGraph = true;
    }, 100);
  }

  filterByStatus(statusName: string) {
    this.resetPreviousFilter();
    this.loadGraph = false;
    this.isFilteringByStatus = true;
    this.lastFilterStatus = statusName;

    this.graphicPosition = this.formatApplicants(this.applicants
      .filter(applicant => applicant.status.name == statusName)
      .filter(applicant => applicant.position), 'position.name');

    this.graphicCity = this.formatApplicants(this.applicants
      .filter(applicant => applicant.status.name == statusName), 'city.name');

    this.graphicState = this.formatApplicants(this.applicants
      .filter(applicant => applicant.status.name == statusName), 'region.name');

    setTimeout(() => {
      this.loadGraph = true;
    }, 100);
  }
}
