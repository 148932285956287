<div class="row mt-1">

  <div class="col-md-4 col-10">
    <div class="form-group">
      <label>Subcategoría de proyecto</label>
      <ng-select [items]="selectCategories"
                 [searchable]="true"
                 bindLabel="name"
                 [multiple]="false"
                 placeholder="Seleccione una categoría"
                 [(ngModel)]="category">
        <ng-template ng-option-tmp let-item="item">
          {{item.name}}
        </ng-template>
      </ng-select>
    </div>
  </div>

  <div class="col-2 mt-2">
    <button class="btn btn-success btn-sm margin-button-add" ngbTooltip="Agregar categoría" (click)="addBudget()"><i class="la la-plus"></i></button>
  </div>
</div>

<app-datatable
  [rows$]="categories$"
  [config]="dataTableConfig" class="example-viewport-budget"
  (rowSelected)="openCostList($event)">

  <app-datatable-column title="Categoría">
    <ng-template let-row #column>
      {{row.name}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Presupuesto">
    <ng-template let-row let-index="index" #column>
      {{row.totalBudget | clearCurrency | swapCommasAndDots}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Presupuesto gastado">
    <ng-template let-row #column>
      {{row.budgetSpent | clearCurrency | swapCommasAndDots}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Presupuesto restante">
    <ng-template let-row #column>
      {{row.budgetLeft | clearCurrency | swapCommasAndDots}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Sección" [isClickable]="false">
    <ng-template let-row let-index="index" #column>
      <button class="btn btn-success btn-sm ml-1" ngbTooltip="Cambiar presupuesto" (click)="updateBudget(row.key, row.name)"><i
        class="feather ft-plus"></i></button>
    </ng-template>
  </app-datatable-column>
</app-datatable>

