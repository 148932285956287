export const Cities = [
  { name: 'Arica', state_id: '15' },
  { name: 'Camarones', state_id: '15' },
  { name: 'Putre', state_id: '15' },
  { name: 'General Lagos', state_id: '15' },
  { name: 'Iquique', state_id: '01' },
  { name: 'Alto Hospicio', state_id: '01' },
  { name: 'Huara', state_id: '01' },
  { name: 'Camiña', state_id: '01' },
  { name: 'Colchane', state_id: '01' },
  { name: 'Pica', state_id: '01' },
  { name: 'Pozo Almonte', state_id: '01' },
  { name: 'Tocopilla', state_id: '02' },
  { name: 'María Elena', state_id: '02' },
  { name: 'Calama', state_id: '02' },
  { name: 'Ollagüe', state_id: '02' },
  { name: 'San Pedro de Atacama', state_id: '02' },
  { name: 'Antofagasta', state_id: '02' },
  { name: 'Mejillones', state_id: '02' },
  { name: 'Sierra Gorda', state_id: '02' },
  { name: 'Taltal', state_id: '02' },
  { name: 'Chañaral', state_id: '03' },
  { name: 'Diego de Almagro', state_id: '03' },
  { name: 'Copiapó', state_id: '03' },
  { name: 'Caldera', state_id: '03' },
  { name: 'Tierra Amarilla', state_id: '03' },
  { name: 'Vallenar', state_id: '03' },
  { name: 'Freirina', state_id: '03' },
  { name: 'Huasco', state_id: '03' },
  { name: 'Alto del Carmen', state_id: '03' },
  { name: 'La Serena', state_id: '04' },
  { name: 'La Higuera', state_id: '04' },
  { name: 'Coquimbo', state_id: '04' },
  { name: 'Andacollo', state_id: '04' },
  { name: 'Vicuña', state_id: '04' },
  { name: 'Paiguano', state_id: '04' },
  { name: 'Ovalle', state_id: '04' },
  { name: 'Río Hurtado', state_id: '04' },
  { name: 'Monte Patria', state_id: '04' },
  { name: 'Combarbalá', state_id: '04' },
  { name: 'Punitaqui', state_id: '04' },
  { name: 'Illapel', state_id: '04' },
  { name: 'Salamanca', state_id: '04' },
  { name: 'Los Vilos', state_id: '04' },
  { name: 'Canela', state_id: '04' },
  { name: 'La Ligua', state_id: '05' },
  { name: 'Petorca', state_id: '05' },
  { name: 'Cabildo', state_id: '05' },
  { name: 'Zapallar', state_id: '05' },
  { name: 'Papudo', state_id: '05' },
  { name: 'Los Andes', state_id: '05' },
  { name: 'San Esteban', state_id: '05' },
  { name: 'Calle Larga', state_id: '05' },
  { name: 'Rinconada', state_id: '05' },
  { name: 'San Felipe', state_id: '05' },
  { name: 'Putaendo', state_id: '05' },
  { name: 'Santa María', state_id: '05' },
  { name: 'Panquehue', state_id: '05' },
  { name: 'Llaillay', state_id: '05' },
  { name: 'Catemu', state_id: '05' },
  { name: 'Quillota', state_id: '05' },
  { name: 'La Cruz', state_id: '05' },
  { name: 'Calera', state_id: '05' },
  { name: 'Nogales', state_id: '05' },
  { name: 'Hijuelas', state_id: '05' },
  { name: 'Limache', state_id: '05' },
  { name: 'Olmué', state_id: '05' },
  { name: 'Valparaíso', state_id: '05' },
  { name: 'Viña del Mar', state_id: '05' },
  { name: 'Quintero', state_id: '05' },
  { name: 'Puchuncaví', state_id: '05' },
  { name: 'Quilpué', state_id: '05' },
  { name: 'Villa Alemana', state_id: '05' },
  { name: 'Casablanca', state_id: '05' },
  { name: 'Juan Fernández', state_id: '05' },
  { name: 'Concón', state_id: '05' },
  { name: 'San Antonio', state_id: '05' },
  { name: 'Cartagena', state_id: '05' },
  { name: 'El Tabo', state_id: '05' },
  { name: 'El Quisco', state_id: '05' },
  { name: 'Algarrobo', state_id: '05' },
  { name: 'Santo Domingo', state_id: '05' },
  { name: 'Isla  de Pascua', state_id: '05' },
  { name: 'Rancagua', state_id: '06' },
  { name: 'Graneros', state_id: '06' },
  { name: 'Mostazal', state_id: '06' },
  { name: 'Codegua', state_id: '06' },
  { name: 'Machalí', state_id: '06' },
  { name: 'Olivar', state_id: '06' },
  { name: 'Requínoa', state_id: '06' },
  { name: 'Rengo', state_id: '06' },
  { name: 'Malloa', state_id: '06' },
  { name: 'Quinta de Tilcoco', state_id: '06' },
  { name: 'San Vicente', state_id: '06' },
  { name: 'Pichidegua', state_id: '06' },
  { name: 'Peumo', state_id: '06' },
  { name: 'Coltauco', state_id: '06' },
  { name: 'Coinco', state_id: '06' },
  { name: 'Doñihue', state_id: '06' },
  { name: 'Las Cabras', state_id: '06' },
  { name: 'San Fernando', state_id: '06' },
  { name: 'Chimbarongo', state_id: '06' },
  { name: 'Placilla', state_id: '06' },
  { name: 'Nancagua', state_id: '06' },
  { name: 'Chépica', state_id: '06' },
  { name: 'Santa Cruz', state_id: '06' },
  { name: 'Lolol', state_id: '06' },
  { name: 'Pumanque', state_id: '06' },
  { name: 'Palmilla', state_id: '06' },
  { name: 'Peralillo', state_id: '06' },
  { name: 'Pichilemu', state_id: '06' },
  { name: 'Navidad', state_id: '06' },
  { name: 'Litueche', state_id: '06' },
  { name: 'La Estrella', state_id: '06' },
  { name: 'Marchihue', state_id: '06' },
  { name: 'Paredones', state_id: '06' },
  { name: 'Curicó', state_id: '07' },
  { name: 'Teno', state_id: '07' },
  { name: 'Romeral', state_id: '07' },
  { name: 'Molina', state_id: '07' },
  { name: 'Sagrada Familia', state_id: '07' },
  { name: 'Hualañé', state_id: '07' },
  { name: 'Licantén', state_id: '07' },
  { name: 'Vichuquén', state_id: '07' },
  { name: 'Rauco', state_id: '07' },
  { name: 'Talca', state_id: '07' },
  { name: 'Pelarco', state_id: '07' },
  { name: 'Río Claro', state_id: '07' },
  { name: 'San Clemente', state_id: '07' },
  { name: 'Maule', state_id: '07' },
  { name: 'Empedrado', state_id: '07' },
  { name: 'Pencahue', state_id: '07' },
  { name: 'Constitución', state_id: '07' },
  { name: 'Curepto', state_id: '07' },
  { name: 'San Rafael', state_id: '07' },
  { name: 'Linares', state_id: '07' },
  { name: 'Yerbas Buenas', state_id: '07' },
  { name: 'Colbún', state_id: '07' },
  { name: 'Longaví', state_id: '07' },
  { name: 'Parral', state_id: '07' },
  { name: 'Retiro', state_id: '07' },
  { name: 'Villa Alegre', state_id: '07' },
  { name: 'San Javier', state_id: '07' },
  { name: 'Cauquenes', state_id: '07' },
  { name: 'Pelluhue', state_id: '07' },
  { name: 'Chanco', state_id: '07' },
  { name: 'Chillán', state_id: '16' },
  { name: 'San Carlos', state_id: '16' },
  { name: 'Ñiquén', state_id: '16' },
  { name: 'San Fabián', state_id: '16' },
  { name: 'Coihueco', state_id: '16' },
  { name: 'Pinto', state_id: '16' },
  { name: 'San Ignacio', state_id: '16' },
  { name: 'El Carmen', state_id: '16' },
  { name: 'Yungay', state_id: '16' },
  { name: 'Pemuco', state_id: '16' },
  { name: 'Bulnes', state_id: '16' },
  { name: 'Quillón', state_id: '16' },
  { name: 'Ránquil', state_id: '16' },
  { name: 'Portezuelo', state_id: '16' },
  { name: 'Coelemu', state_id: '16' },
  { name: 'Treguaco', state_id: '16' },
  { name: 'Cobquecura', state_id: '16' },
  { name: 'Quirihue', state_id: '16' },
  { name: 'Ninhue', state_id: '16' },
  { name: 'San Nicolás', state_id: '16' },
  { name: 'Chillán Viejo', state_id: '16' },
  { name: 'Los Ángeles', state_id: '08' },
  { name: 'Cabrero', state_id: '08' },
  { name: 'Tucapel', state_id: '08' },
  { name: 'Antuco', state_id: '08' },
  { name: 'Quilleco', state_id: '08' },
  { name: 'Santa Bárbara', state_id: '08' },
  { name: 'Alto Biobío', state_id: '08' },
  { name: 'Quilaco', state_id: '08' },
  { name: 'Mulchén', state_id: '08' },
  { name: 'Negrete', state_id: '08' },
  { name: 'Nacimiento', state_id: '08' },
  { name: 'Laja', state_id: '08' },
  { name: 'San Rosendo', state_id: '08' },
  { name: 'Yumbel', state_id: '08' },
  { name: 'Concepción', state_id: '08' },
  { name: 'Talcahuano', state_id: '08' },
  { name: 'Hualpén', state_id: '08' },
  { name: 'Penco', state_id: '08' },
  { name: 'Tomé', state_id: '08' },
  { name: 'Florida', state_id: '08' },
  { name: 'Hualqui', state_id: '08' },
  { name: 'Santa Juana', state_id: '08' },
  { name: 'Lota', state_id: '08' },
  { name: 'Coronel', state_id: '08' },
  { name: 'San Pedro de la Paz', state_id: '08' },
  { name: 'Lebu', state_id: '08' },
  { name: 'Arauco', state_id: '08' },
  { name: 'Curanilahue', state_id: '08' },
  { name: 'Los Álamos', state_id: '08' },
  { name: 'Cañete', state_id: '08' },
  { name: 'Contulmo', state_id: '08' },
  { name: 'Tirúa', state_id: '08' },
  { name: 'Chiguayante', state_id: '08' },
  { name: 'Angol', state_id: '09' },
  { name: 'Renaico', state_id: '09' },
  { name: 'Collipulli', state_id: '09' },
  { name: 'Lonquimay', state_id: '09' },
  { name: 'Curacautín', state_id: '09' },
  { name: 'Ercilla', state_id: '09' },
  { name: 'Victoria', state_id: '09' },
  { name: 'Traiguén', state_id: '09' },
  { name: 'Lumaco', state_id: '09' },
  { name: 'Purén', state_id: '09' },
  { name: 'Los Sauces', state_id: '09' },
  { name: 'Temuco', state_id: '09' },
  { name: 'Lautaro', state_id: '09' },
  { name: 'Perquenco', state_id: '09' },
  { name: 'Vilcún', state_id: '09' },
  { name: 'Cunco', state_id: '09' },
  { name: 'Melipeuco', state_id: '09' },
  { name: 'Curarrehue', state_id: '09' },
  { name: 'Pucón', state_id: '09' },
  { name: 'Villarrica', state_id: '09' },
  { name: 'Freire', state_id: '09' },
  { name: 'Pitrufquén', state_id: '09' },
  { name: 'Gorbea', state_id: '09' },
  { name: 'Loncoche', state_id: '09' },
  { name: 'Toltén', state_id: '09' },
  { name: 'Teodoro Schmidt', state_id: '09' },
  { name: 'Saavedra', state_id: '09' },
  { name: 'Carahue', state_id: '09' },
  { name: 'Nueva Imperial', state_id: '09' },
  { name: 'Cholchol', state_id: '09' },
  { name: 'Galvarino', state_id: '09' },
  { name: 'Padre Las Casas', state_id: '09' },
  { name: 'Valdivia', state_id: '14' },
  { name: 'Mariquina', state_id: '14' },
  { name: 'Lanco', state_id: '14' },
  { name: 'Los Lagos', state_id: '14' },
  { name: 'Futrono', state_id: '14' },
  { name: 'Corral', state_id: '14' },
  { name: 'Máfil', state_id: '14' },
  { name: 'Panguipulli', state_id: '14' },
  { name: 'La Unión', state_id: '14' },
  { name: 'Paillaco', state_id: '14' },
  { name: 'Río Bueno', state_id: '14' },
  { name: 'Lago Ranco', state_id: '14' },
  { name: 'Osorno', state_id: '10' },
  { name: 'San Pablo', state_id: '10' },
  { name: 'Puyehue', state_id: '10' },
  { name: 'Puerto Octay', state_id: '10' },
  { name: 'Purranque', state_id: '10' },
  { name: 'Río Negro', state_id: '10' },
  { name: 'San Juan de la Costa', state_id: '10' },
  { name: 'Puerto Montt', state_id: '10' },
  { name: 'Puerto Varas', state_id: '10' },
  { name: 'Cochamó', state_id: '10' },
  { name: 'Calbuco', state_id: '10' },
  { name: 'Maullín', state_id: '10' },
  { name: 'Los Muermos', state_id: '10' },
  { name: 'Fresia', state_id: '10' },
  { name: 'Llanquihue', state_id: '10' },
  { name: 'Frutillar', state_id: '10' },
  { name: 'Castro', state_id: '10' },
  { name: 'Ancud', state_id: '10' },
  { name: 'Quemchi', state_id: '10' },
  { name: 'Dalcahue', state_id: '10' },
  { name: 'Curaco de Vélez', state_id: '10' },
  { name: 'Quinchao', state_id: '10' },
  { name: 'Puqueldón', state_id: '10' },
  { name: 'Chonchi', state_id: '10' },
  { name: 'Queilén', state_id: '10' },
  { name: 'Quellón', state_id: '10' },
  { name: 'Chaitén', state_id: '10' },
  { name: 'Hualaihué', state_id: '10' },
  { name: 'Futaleufú', state_id: '10' },
  { name: 'Palena', state_id: '10' },
  { name: 'Coihaique', state_id: '11' },
  { name: 'Lago Verde', state_id: '11' },
  { name: 'Aisén', state_id: '11' },
  { name: 'Cisnes', state_id: '11' },
  { name: 'Guaitecas', state_id: '11' },
  { name: 'Chile Chico', state_id: '11' },
  { name: 'Río Ibáñez', state_id: '11' },
  { name: 'Cochrane', state_id: '11' },
  { name: 'OHiggins', state_id: '11' },
  { name: 'Tortel', state_id: '11' },
  { name: 'Natales', state_id: '12' },
  { name: 'Torres del Paine', state_id: '12' },
  { name: 'Punta Arenas', state_id: '12' },
  { name: 'Río Verde', state_id: '12' },
  { name: 'Laguna Blanca', state_id: '12' },
  { name: 'San Gregorio', state_id: '12' },
  { name: 'Porvenir', state_id: '12' },
  { name: 'Primavera', state_id: '12' },
  { name: 'Timaukel', state_id: '12' },
  { name: 'Cabo de Hornos', state_id: '12' },
  { name: 'Antártica', state_id: '12' },
  { name: 'Santiago', state_id: '13' },
  { name: 'Independencia', state_id: '13' },
  { name: 'Conchalí', state_id: '13' },
  { name: 'Huechuraba', state_id: '13' },
  { name: 'Recoleta', state_id: '13' },
  { name: 'Providencia', state_id: '13' },
  { name: 'Vitacura', state_id: '13' },
  { name: 'Lo Barnechea', state_id: '13' },
  { name: 'Las Condes', state_id: '13' },
  { name: 'Ñuñoa', state_id: '13' },
  { name: 'La Reina', state_id: '13' },
  { name: 'Macul', state_id: '13' },
  { name: 'Peñalolén', state_id: '13' },
  { name: 'La Florida', state_id: '13' },
  { name: 'San Joaquín', state_id: '13' },
  { name: 'La Granja', state_id: '13' },
  { name: 'La Pintana', state_id: '13' },
  { name: 'San Ramón', state_id: '13' },
  { name: 'San Miguel', state_id: '13' },
  { name: 'La Cisterna', state_id: '13' },
  { name: 'El Bosque', state_id: '13' },
  { name: 'Pedro Aguirre Cerda', state_id: '13' },
  { name: 'Lo Espejo', state_id: '13' },
  { name: 'Estación Central', state_id: '13' },
  { name: 'Cerrillos', state_id: '13' },
  { name: 'Maipú', state_id: '13' },
  { name: 'Quinta Normal', state_id: '13' },
  { name: 'Lo Prado', state_id: '13' },
  { name: 'Pudahuel', state_id: '13' },
  { name: 'Cerro Navia', state_id: '13' },
  { name: 'Renca', state_id: '13' },
  { name: 'Quilicura', state_id: '13' },
  { name: 'Colina', state_id: '13' },
  { name: 'Lampa', state_id: '13' },
  { name: 'Tiltil', state_id: '13' },
  { name: 'Puente Alto', state_id: '13' },
  { name: 'San José de Maipo', state_id: '13' },
  { name: 'Pirque', state_id: '13' },
  { name: 'San Bernardo', state_id: '13' },
  { name: 'Buin', state_id: '13' },
  { name: 'Paine', state_id: '13' },
  { name: 'Calera de Tango', state_id: '13' },
  { name: 'Melipilla', state_id: '13' },
  { name: 'María Pinto', state_id: '13' },
  { name: 'Curacaví', state_id: '13' },
  { name: 'Alhué', state_id: '13' },
  { name: 'San Pedro', state_id: '13' },
  { name: 'Talagante', state_id: '13' },
  { name: 'Peñaflor', state_id: '13' },
  { name: 'Isla de Maipo', state_id: '13' },
  { name: 'El Monte', state_id: '13' },
  { name: 'Padre Hurtado', state_id: '13' }
];
