import { Component, OnInit } from '@angular/core';
import { Applicant } from '../../interfaces/applicant';
import { Project } from '../../interfaces/project';
import { Observable, Subscription } from 'rxjs';
import { DataTableConfig } from '../../../shared/interfaces/data-table-config';
import { UserType } from '../../enums/user-type.enum';
import { ApplicantService } from '../../../shared/services/applicant.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusService } from '../../../shared/services/status.service';
import { JobService } from '../../../shared/services/job.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProjectService } from '../../../shared/services/project.service';
import { UserService } from '../../../shared/services/user.service';
import { first, map, take } from 'rxjs/operators';
import { Cities } from '../../../../assets/json/cities';
import { ProjectStatus } from '../../enums/project-status.enum';
import _ from 'lodash';

@Component({
  selector: 'app-applicantsv2',
  templateUrl: './applicantsv2.component.html',
  styleUrls: ['./applicantsv2.component.css']
})
export class Applicantsv2Component implements OnInit {
  applicants: Applicant[] = [];
  applicantsNoRepeat: Applicant[];
  duplicatedApplicants: Applicant[];
  projects: Project[];
  applicantsFiltered$: Observable<Applicant[]>;
  usersConfig: DataTableConfig = {
    title: 'Postulantes registrados',
    notFoundText: 'No se encontraron postulantes',
    hasSearch: true,
    filtersFields: ['fullname'.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''), 'fullname', 'name', 'surnames', 'rut']
  };
  statusSubscription: Subscription = new Subscription();
  projectSubscription: Subscription = new Subscription();
  selectArrayStatus: any = [];
  selectArrayProjects: any = [];
  positionSubscription: Subscription = new Subscription();
  selectArrayPosition: any = [];
  selectMultipleArray: any = [];
  selectedMultipleArray: any = [];
  status: any = { name: '' };
  position: any = { name: '' };
  city: any = { name: '' };
  minExpectation: number;
  maxExpectation: number;
  minYears: number = null;
  maxYears: number = null;
  cities = [];
  applicantCities = [];
  applicantProjects = [];
  noRepeatCities = [];
  userPermission;
  userTypeEnum = UserType;

  constructor(private _applicant: ApplicantService,
              private router: Router,
              private _status: StatusService,
              private _position: JobService,
              private activatedRoute: ActivatedRoute,
              private modal: BsModalService,
              private _project: ProjectService,
              private _user: UserService) {
  }

  async ngOnInit(): Promise<void> {
    this.userPermission = this._user.user.permissions.find(permission => permission.section == 'POSTULANTES').permission;

    this.applicants = await this._applicant.getAll().pipe(first()).toPromise();

    await this.assignFullName();
    this.cities = Cities;

    this.loadStatus();
    if (window.location.pathname.includes('revision-applicants') || window.location.pathname.includes('contratado-applicants') || window.location.pathname.includes('calificado-applicants') || window.location.pathname.includes('postulante-applicants') || window.location.pathname.includes('apto-applicants')) {
      await this.loadProjects();
    }

    await this.loadPositions();
    this.loadLocalStorageItems();

    this.applicantsNoRepeat = _.uniqBy(this.applicants, 'rut');
    this.duplicatedApplicants = this.applicants;
  }

  loadStatus() {
    this.statusSubscription = this._status.getAll()
      .pipe(
        map(status => status.map(status => ({
            name: status.name,
            key: status.key,
            type: 'Estatus'
          }
        )))
      )
      .subscribe(data => {
        this.selectArrayStatus = data;
      });
  }

  async loadProjects() {
    this.projectSubscription = this._project.getAllByStatus(ProjectStatus.ACTIVE)
      .pipe(
        map(project => project.map(project => ({
            name: project.name,
            key: project.key,
            status: project.status,
            type: 'Proyecto'
          }
        )))
      )
      .subscribe(data => {
        this.selectArrayProjects = data;
      });
  }

  assignFullName() {
    this.applicants = this.applicants.map((applicant) => {
      applicant.fullname = (applicant.name + ' ' + (applicant.surnames || '')).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '');
      this._applicant.update(applicant.key, { fullname: `${applicant.fullname}` } as Applicant);

      return applicant;
    });
  }

  async loadPositions() {
    await this.onlyRegisteredCities();
    this.positionSubscription = this._position.getAll()
      .pipe(
        map(position => position.map(position => ({
            name: position.name,
            key: position.key,
            type: 'Cargo'
          }
        )))
      )
      .subscribe(data => {
        this.selectMultipleArray = [
          ...data,
          ...this.noRepeatCities.map(city => ({ name: city.name, type: 'Ciudad' }))]
        ;
      });
  }

  async onlyRegisteredCities() {
    this.noRepeatCities = _.uniq(
      this.applicants
        .filter(applicant => applicant.city.name)
        .map(applicant => applicant.city.name)
    );
  }

  loadLocalStorageItems() {
    this.selectedMultipleArray = JSON.parse(localStorage.getItem('selectFilter')) || this.selectedMultipleArray;
    localStorage.removeItem('selectFilter');

    this.minExpectation = JSON.parse(localStorage.getItem('minExpectation')) || this.minExpectation;
    localStorage.removeItem('minExpectation');

    this.maxExpectation = JSON.parse(localStorage.getItem('maxExpectation')) || this.maxExpectation;
    localStorage.removeItem('maxExpectation');

    this.minYears = JSON.parse(localStorage.getItem('minYears')) || this.minYears;
    localStorage.removeItem('minYears');

    this.maxYears = JSON.parse(localStorage.getItem('maxYears')) || this.maxYears;
    localStorage.removeItem('maxYears');
  }
}
