import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ValidationService} from '../../template-services/validation.service';
import {AlertService} from '../../template-services/alert.service';
import {take} from 'rxjs/operators';
import {AngularFireStorage} from '@angular/fire/storage';

@Component({
  selector: 'app-upload-file-storage',
  templateUrl: './upload-file-storage.component.html',
  styleUrls: ['./upload-file-storage.component.css']
})
export class UploadFileStorageComponent implements OnInit {
  @ViewChild('labelImport', {static: true}) labelImport: ElementRef;
  @ViewChild('inputFile') inputFile: ElementRef;
  @Input() routeFile: string = 'images';
  @Input() extension: string = 'png';
  @Input() showPreview: boolean = false;
  @Input() label: string = 'Imagen';
  @Input() errorLabel: string = 'Es obligatorio';
  @Input() controlName: string;
  path: any;
  fileToUpload: any;
  isInputValid: boolean = false;
  submitted: boolean = false;
  @Input() fileType: string = 'application/pdf';
  @Output() imageLoaded: EventEmitter<void> = new EventEmitter<void>();
  @Output() isImageLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isPreview: EventEmitter<boolean> = new EventEmitter();
  @Output() pathEmitter: EventEmitter<string> = new EventEmitter();

  constructor(private storage: AngularFireStorage) {}

  ngOnInit(): void {}

  onFileChange(event) {
    if (!ValidationService.validateFileSize(event, 10000))
      return AlertService.toastError('El archivo debe ser menor a 10MB');

    this.isInputValid = true;

    this.labelImport.nativeElement.innerText = Array.from(
      event.target.files as FileList
    )
      .map((f) => f.name)
      .join(', ');
    this.fileToUpload = event.target.files.item(0);
    this.isPreview.emit(true);
    this.choosePicture(event);
  }

  choosePicture(event) {
    if (event.target.files && this.fileToUpload) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.path = (<FileReader>event.target).result;
        this.pathEmitter.emit(this.path);
      };
      reader.readAsDataURL(this.fileToUpload);

      this.imageLoaded.emit();
      this.isImageLoaded.emit(true);
    }
  }

  public async uploadDocument(fileFolder, id) {
    this.submitted = true;
    const uploadRef = this.getStorageRefDocument(fileFolder, id);
    await uploadRef.put(this.fileToUpload);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    await this.uploadDocumentStorage(fileFolder, id);

    return url;
  }

  uploadDocumentStorage(fileFolder, id): Promise<void> {
    return new Promise((resolve) => {
      this.storage
        .upload(
          `${fileFolder}/${id}/${this.routeFile}.${this.extension};`,
          this.fileToUpload
        )
        .then(() => {
          resolve();
        });
    });
  }

  getStorageRefDocument(fileFolder, id) {
    return this.storage.ref(
      `${fileFolder}/${id}/${this.routeFile}.${this.extension};`
    );
  }
}

/* TODO: README
*
*  MULTIPLE FILES
  @ViewChildren(UploadFileStorageComponent) filesStorage;

  this.filesStorage.forEach(async component => {
    const url = await component.uploadDocument();
    if (!!url) form.pathValue({[component.controlName]: url})
  });

* --------------------------------------
*
*  ONE FILE
  @ViewChild(UploadFileStorageComponent) filesStorage: UploadFileStorageComponent;

  const url = await this.filesStorage.uploadDocument()
  if (!!url) form.pathValue({[this.filesStorage.controlName]: url})

* */
