import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'swapCommasAndDots',
})
export class SwapCommasAndDotsPipe implements PipeTransform {
  transform(value: string): string {
    return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
}
