import { Injectable } from '@angular/core';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {Afp} from '../../admin/interfaces/afp';

@Injectable({
  providedIn: 'root'
})
export class AfpService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  getAll(): Observable<Afp[]> {
    return this.db.colWithIds$<Afp>('afp', ref => ref
      .where('trash', '==', false));
  }

  update(afpKey: string, afp: Afp): Promise<void> {
    return this.afs.doc<Afp>(`afp/${afpKey}`).update(afp);
  }

  delete(afpKey: string): Promise<void> {
    return this.afs.doc<Afp>(`afp/${afpKey}`).update({trash: true});
  }

  add(afp: Afp) {
    this.afs.collection('afp').add(afp);
  }
}
