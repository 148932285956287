import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuizService } from '../../../shared/services/quiz.service';
import { Quiz } from '../../interfaces/quiz';

@Component({
  selector: 'app-add-answer',
  templateUrl: './add-answer.component.html',
  styleUrls: ['./add-answer.component.css']
})
export class AddAnswerComponent implements OnInit {
  answers = [];
  index: number;
  update: boolean = false;
  questionForm: FormGroup;
  submitted: boolean = false;
  question;
  quiz: Quiz;
  validation: boolean = false;
  isSubmitted: boolean = false;
  @Output() eventUpdateQuestion: EventEmitter<any> = new EventEmitter<any>();

  constructor(public modal: BsModalRef,
              private formBuilder: FormBuilder,
              private _quiz: QuizService) {
    this.questionForm = this.formBuilder.group({
      question: ['', Validators.required],
      answers: this.formBuilder.array([]),
      correctAnswer: [0, Validators.required]
    });
  }

  async ngOnInit() {
    if (this.update) {
      this.questionForm.patchValue({ question: this.answers[this.index].question, correctAnswer: this.answers[this.index].correctAnswer });

      for (let i = 0; i < this.answers[this.index].answers.length; i++) {
        let answer = {
          answer: this.answers[this.index].answers[i]
        };

        await this.questionForm.value.answers.push(answer);
        await this.answer.push(this.newAnswer(answer.answer));
      }

      this.questionForm.value.answers.splice(0, 1);
      this.validation = true;
    } else {
      this.answer.push(this.newAnswer(null));
      this.answer.push(this.newAnswer(null));
      this.answer.push(this.newAnswer(null));
      this.answer.push(this.newAnswer(null));
    }
  }

  get formControls() {
    return this.questionForm.controls;
  }

  get answer(): FormArray {
    return this.questionForm.get('answers') as FormArray;
  }

  newAnswer(answer) {
    if (answer) {
      return this.formBuilder.group({
        answer: [answer, Validators.required]
      });
    }

    return this.formBuilder.group({
      answer: ['', Validators.required]
    });
  }

  addAnswer() {
    this.answer.push(this.newAnswer(null));
  }

  async submit() {
    this.submitted = true;

    if (this.questionForm.valid) {
      this.question = {
        question: this.questionForm.value.question,
        answers: this.questionForm.value.answers,
        correct: this.questionForm.value.correctAnswer
      };

      let UpdateQuestion = {
        index: this.index,
        questions: this.question
      };

      await this.eventUpdateQuestion.emit(UpdateQuestion);
      this.isSubmitted = true;

      this.modal.hide();
    }
  }

  removeAnswer(i: number) {
    this.answer.removeAt(i);
  }

  setCorrectAnswer(index) {
    this.questionForm.patchValue({
      correctAnswer: index
    });
  }
}
