import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {TicketAuthorization} from '../../../interfaces/ticket-authorization';
import {TicketAuthorizationService} from '../../../../../../shared/services/ticket-authorization.service';
import {DataTableConfig} from '../../../../../../shared/interfaces/data-table-config';
import {AlertService} from '../../../../../../shared/template-services/alert.service';
import {map} from 'rxjs/operators';
import {WarehouseDetailService} from '../../../services/warehouse-detail.service';

@Component({
  selector: 'app-ticket-authorization',
  templateUrl: './ticket-authorization.component.html',
  styleUrls: ['./ticket-authorization.component.css']
})
export class TicketAuthorizationComponent implements OnInit {
  requests$: Observable<TicketAuthorization[]>;
  @Input() warehouseKey: string;
  config: DataTableConfig = {
    title: '',
    notFoundText: 'No se encontraron solicitudes',
    hasSearch: true
  };

  constructor(private _ticketAuthorization: TicketAuthorizationService,
              private _warehouseDetail: WarehouseDetailService) {
  }

  ngOnInit(): void {
    if (!this.warehouseKey) this.warehouseKey = this._warehouseDetail.warehouseKey;
    this.requests$ = this._ticketAuthorization.getAllRequests(this.warehouseKey).pipe(map(requests => requests.filter(request => !request.accept)));
  }

  async ticketAuthorizationAdd(request: any) {
    if (await AlertService.confirm('¿Estás seguro que deseas aceptar esta solicitud?')) {
      request.accept = true;
      let ticketAuthorization = {
        ticketKey: request.ticketKey,
        ticketID: request.ticketID,
        trash: false
      };
      await this._ticketAuthorization.add(request.userKey, ticketAuthorization);
      await this._ticketAuthorization.updateRequest(this.warehouseKey, request.key, request);
      AlertService.toastSuccess('Se aceptó la rectificación');
    }
  }
}
