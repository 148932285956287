<app-datatable [rows$]="providers$"
               [config]="config"
               (rowSelected)="gotoOpenEditProvider($event)">

  <app-datatable-column title="Nombre">
    <ng-template let-row #column>
      {{ row.name }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Giro">
    <ng-template let-row #column>
      {{ row.lineOfBusiness }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Ciudad">
    <ng-template let-row #column>
      {{ row.city }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="País">
    <ng-template let-row #column>
      {{ row.country }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Sitio web">
    <ng-template let-row #column>
      {{ row.website }}
    </ng-template>
  </app-datatable-column>
</app-datatable>

<app-add-float-button (clickEmmiter)="openAddProviderModal()"></app-add-float-button>
