<div class="col-md-12">
  <div class="d-flex align-items-end mb-1">
    <div class="animated fadeIn">
      <label>Seleccione el mes</label>
      <input
        type="month"
        class="form-control month-filter"
        [(ngModel)]="dateSelected"
        (ngModelChange)="assignStartDateAndFinalDate()"/>
    </div>

    <div class="ml-1">
      <button
        class="btn btn-danger ml-1"
        (click)="showBillsByDateRange()">
        Buscar
      </button>
    </div>
  </div>
  <div *ngIf="billsFilter.length">
    <h3 class="mt-2">Facturas Mensuales</h3>

    <div class="d-flex row">
      <div class="mr-2 align-self-center">
        <h5>Total de las facturas: {{totalBillsValue | currency: 'USD' | swapCommasAndDots}}</h5>
        <h5>Iva: {{valueAddedTax | currency: 'USD' | swapCommasAndDots}}</h5>
        <h5>Número de facturas: {{totalDocuments}}</h5>
      </div>
      <div class="chart-size">
        <canvas #billsBarCanvas></canvas>
      </div>

      <div *ngIf="chartPieLabel.length">
        <h3 class="d-flex justify-content-center mt-4">Centros de costos</h3>
        <div class="chart-size mt-3">
          <canvas #billsPieCanvas></canvas>
        </div>
      </div>
    </div>
  </div>
  <app-not-found size="medium" subtitle="No se encontraron facturas" *ngIf="!billsFilter.length"></app-not-found>
</div>
