import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {FirstLoginComponent} from './first-login.component';
import {RegisterDataComponent} from './pages/register-data/register-data.component';

const routes: Routes = [{
  path: '',
  component: FirstLoginComponent,
  children: [
    {
      path: 'register-data',
      component: RegisterDataComponent
    },
    {
      path: '**',
      redirectTo: 'register-data'
    }
  ]
},];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FirstLoginRoutingModule { }
