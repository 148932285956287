import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FirstLoginRoutingModule} from './first-login-routing.module';
import {FirstLoginComponent} from './first-login.component';
import {RegisterDataComponent} from './pages/register-data/register-data.component';
import {DocumentsComponent} from './components/documents/documents.component';
import {GeneralDataComponent} from './components/general-data/general-data.component';
import {ArchwizardModule} from 'angular-archwizard';
import {ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {CardModule} from '../shared/template-components/partials/general/card/card.module';
import {NgxSpinnerModule} from 'ngx-spinner';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    FirstLoginComponent,
    RegisterDataComponent,
    DocumentsComponent,
    GeneralDataComponent
  ],
  imports: [
    CommonModule,
    FirstLoginRoutingModule,
    ArchwizardModule,
    ReactiveFormsModule,
    NgSelectModule,
    CardModule,
    NgxSpinnerModule,
    MatInputModule,
    SharedModule
  ]
})
export class FirstLoginModule {
}
