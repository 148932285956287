import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AlertService } from '../../template-services/alert.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddShortcutComponent } from '../add-shortcut/add-shortcut.component';
import { first } from 'rxjs/operators';
import { ShortcutsService } from '../../services/shortcuts.service';
import { AuthService } from '../../template-services/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ShortcutsConfigComponent } from '../shortcuts-config/shortcuts-config.component';

@Component({
  selector: 'app-floating-menu',
  templateUrl: './floating-menu.component.html',
  styleUrls: ['./floating-menu.component.scss']
})
export class FloatingMenuComponent implements OnInit {
  @ViewChild('menu') menu: ElementRef;
  isMobile: boolean = false;
  radius = 100;
  center = 80;
  showMenuItems: boolean = false;
  showRecordPanel: boolean = false;
  isAddingShortcut: boolean = false;
  isEditingShortcuts: boolean = false;
  message: string = '';
  menuItems: any[] = [];
  showAddButton: boolean = true;
  initialMenuData: any[] = [
    {
      icon: 'bug',
      color: 'primary',
      label: 'Reportar un error'
    },
    {
      icon: 'plus',
      color: 'info',
      label: 'Crear nuevo shortcut'
    }
  ];

  constructor(private _shortcuts: ShortcutsService,
    private _user: UserService,
    private modal: BsModalService,
    private _auth: AuthService,
    private deviceService: DeviceDetectorService) {
  }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.getUserShortcuts();
  }

  async getUserShortcuts() {
    await this._auth.getCurrentUser();
    let user = await this._user.get(this._auth.user.uid);

    this._shortcuts.control$.subscribe(control => {
      this.isAddingShortcut = control.adding;
      this.isEditingShortcuts = control.editing;
    });

    let path = '';

    if (!user.shortcuts?.path) {
      const shortcutId = await this._shortcuts.newUserShortcuts();
      path = `shortcuts/${shortcutId}`;
    } else {
      path = user.shortcuts.path;
    }

    this._shortcuts.get(path).subscribe(shortcuts => {
      const items = [];
      if (shortcuts.length < 7) {
        this.showAddButton = true;
        items.push(...this.initialMenuData, ...shortcuts);
      } else {
        this.showAddButton = false;
        items.push(this.initialMenuData[0], ...shortcuts);
      }

      this.setMenuItems(items);
    });
  }

  @HostListener('document:click', ['$event'])
  public onClick(event: Event): void {
    if (this.menu) {
      if (!this.menu.nativeElement.contains(event.target)) this.showMenuItems = false;
    }
  }

  setMenuItems(menuData: any[]) {
    const numItems = menuData.length;
    const baseAngle = 180;
    const angleIncrement = 20;
    const newMenuItems = [];

    for (let i = 0; i < numItems; i++) {
      let angle = baseAngle - (numItems - 1) * (angleIncrement / 2) + i * angleIncrement;
      angle = (angle * Math.PI) / 180;
      const x = this.center + this.radius * Math.cos(angle);
      const y = this.center + this.radius * Math.sin(angle);

      newMenuItems.push({
        ...menuData[i],
        top: y + 'px',
        left: x + 'px'
      });
    }

    this.menuItems = newMenuItems;
  }

  toggleMenu() {
    this.showMenuItems = !this.showMenuItems;
  }

  calculateDelay(index: number): string {
    return (index * 20) + 'ms';
  }

  toggleBugRecorder() {
    this.showRecordPanel = !this.showRecordPanel;
  }

  async toggleNewShortcutMenu() {
    if (this.isAddingShortcut) {
      this._shortcuts.stopAdding();
    } else {
      this.showMenuItems = false;
      this._shortcuts.startAdding();
      await AlertService.info('Navega hasta la página que deseas agregar al menú y presiona el botón "Agregar al menú" ');
    }
  }

  async handleNewShortcut() {
    const modalRef = this.modal.show(AddShortcutComponent, {
      initialState: {
        modalId: 5001
      },
      id: 5001
    });

    await modalRef.onHidden.pipe(first()).toPromise();
  }

  async handleEditShortcuts() {
    const modalRef = this.modal.show(ShortcutsConfigComponent, {
      initialState: {},
      id: 5002
    });

    await modalRef.onHidden.pipe(first()).toPromise();
  }
}
