<div class="p-1">
  <div class="modal-header p-1">
    <h4 class="pt-1">
      Agregar
    </h4>
    <div>
      <div
        *ngIf="file"
        class="btn btn-success mr-1"
        (click)="sendData()">Aceptar
      </div>
      <div
        class="btn btn-danger"
        (click)="modal.hide()">Cerrar
      </div>
    </div>
  </div>

  <div
    *ngIf="file"
    class="alert alert-success">
    Archivo cargado correctamente
  </div>

  <div
    class="modal-body"
    *ngIf="!file">
    <div class="row mt-4">
      <div class="offset-3 col-6">
        <div class="custom-file">
          <input
            type="file"
            class="upload-drop-zone upload-drop-zone.drop"
            id="customFile"
            accept=".csv, .xlsx, .xls"
            #fileInput
            (change)="extractDataFromExcelFile($event)">
        </div>
      </div>
    </div>
    <h1 class="text-center">Haz clic para agregar o arrastra el archivo en el recuadro</h1>
  </div>
</div>
