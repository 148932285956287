<ng-container *ngIf="accountabilities.length">
  <div class="px-1 d-flex">
    <ng-select
      [clearable]="false"
      style="width: 300px"
      appendTo="body"
      placeholder="Filtrar por estado"
      [ngModel]="'all'"
      (change)="filterAccountabilitiesByStatus($event)">
      <ng-option [value]="'all'"> Todos</ng-option>
      <ng-option
        [value]="item"
        *ngFor="let item of filterStatus">
        {{ this.accountabilityStatusLabel[item] }}
      </ng-option>
    </ng-select>
  </div>
</ng-container>

<ng-container *ngIf="filteredAccountabilities.length">
  <div class="d-flex justify-content-start flex-wrap">
    <ng-container *ngFor="let accountability of filteredAccountabilities">
      <div
        class="card mr-1 cursor-pointer"
        (click)="goToAccountabilitiesDetails(accountability)">
        <h3 class="card__title">{{accountability?.fundToRender?.title}}</h3>

        <div class="d-flex">
          <h1
            class="card__accepted-number text-{{statusColor[accountability.status]}}">{{accountability.fundToRender.id}}</h1>

          <div class="d-flex flex-column ml-1 justify-content-center">
            <p class="card__status-title">{{accountability?.totalAccepted}} de {{accountability?.totalReceipts}} gastos aprobados</p>

            <div class="d-flex align-items-center">
              <div class="card__status-totals text-{{statusColor[accountability.status]}}">
                <ng-container *ngIf="accountability.status < accountabilityStatus.CLOSED_COLSAN">
                  <span>{{accountability?.subtotal | currency  : 'USD' : 'symbol' : '1.0-0' | dotsCurrency}} / </span>
                  <span>{{accountability?.totalAmount | currency  : 'USD' : 'symbol' : '1.0-0' | dotsCurrency}}</span>
                </ng-container>

                <ng-container *ngIf="accountability.status >= accountabilityStatus.CLOSED_COLSAN">
                  <span>{{accountability?.amount | currency  : 'USD' : 'symbol' : '1.0-0' | dotsCurrency}}</span>
                </ng-container>
              </div>
              <div class="card__status-badge badge ml-1 text-uppercase badge-{{statusColor[accountability.status]}}">{{accountabilityStatusLabel[accountability.status]}}</div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between mt-1">
          <div class="d-flex">
            <img
              class="card__user-avatar"
              [src]="accountability?.fundToRender?.user?.imageUrl"
              (error)="handleMissingImage($event)"
            />
            <span class="card__user-name">{{accountability?.fundToRender?.user?.fullname}}</span>
          </div>

          <span class="card__date text-muted">{{accountability.createdAt | date : 'dd/MM/YY'}}</span>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="!filteredAccountabilities.length && !loading">
  <app-not-found
    size="medium"
    title="No se encontraron resultados"></app-not-found>
</ng-container>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box">
  <p style="color: white">Cargando...</p>
</ngx-spinner>
