import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() {
  }

  static isValid(field: any): boolean {
    return !(field === null || field === '' || field == undefined);
  }

  static isObjectValid(object: any): boolean {
    if (!object) return false;

    for (let property in object) {
      if (object[property] === null || object[property] === '') {
        return false;
      }
    }
    return true;
  }

  static isArrayValid(array: any[]): boolean {
    for (let object of array) {
      // @ts-ignore
      for (let property in object) {
        if (object[property] === null || object[property] === '') {
          return false;
        }
      }
    }
    return true;
  }

  static isArrayEmpty(array: any[]): boolean {
    return array.length !== 0;
  }

  restrictNumeric(e: any, field?: any): boolean {
    let input: any;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which === 45) {
      return false;
    }
    if (e.which === 46) {
      return !field.includes('.');
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[\d\s]/.test(input);
  }

  restrictNumericPhone(e: any, field?: any): boolean {
    let input: any;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which === 45) {
      return false;
    }
    if (e.which === 46) {
      return false;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[\d\s]/.test(input);
  }

  static validateFileSize(event, kbLimit: number): boolean {
    return event.target.files[0].size / 1000 <= kbLimit;
  }

  validateRut(control: any): { [key: string]: any } | null {
    function checkRut(rut) {
      let valor = rut.value.replaceAll('.', '');
      valor = valor.replace('-', '');

      let cuerpo = valor.slice(0, -1);
      let dv = valor.slice(-1).toUpperCase();

      rut.value = cuerpo + '-' + dv;
      if (cuerpo.length < 7) {
        return false;
      }

      let suma = 0;
      let multiplo = 2;

      for (let i = 1; i <= cuerpo.length; i++) {
        let index = multiplo * valor.charAt(cuerpo.length - i);

        suma = suma + index;

        if (multiplo < 7) {
          multiplo = multiplo + 1;
        } else {
          multiplo = 2;
        }
      }
      let dvEsperado = 11 - (suma % 11);

      dv = (dv == 'K') ? 10 : dv;
      dv = (dv == 0) ? 11 : dv;

      if (dvEsperado != dv) return false;

      rut.value = rut.value.slice(0, 2) + '.' + rut.value.slice(2, 5) + '.' + rut.value.slice(5);
      return true;
    }

    if (control.value) {
      const rutValid = checkRut(control);
      if (!rutValid) return {'rutInvalid': true};
    }
    return null;
  }
}

