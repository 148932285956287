import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {WorkerSalaries} from '../interfaces/worker-salaries';

@Injectable({
  providedIn: 'root'
})
export class WorkerSalariesService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  getAll(): Observable<WorkerSalaries[]> {
    return this.db.colWithIds$<WorkerSalaries>('workerSalaries', ref => ref
      .where('trash', '==', false)
    );
  }

  add(workerSalaries: WorkerSalaries): Promise<DocumentReference> {
    return this.afs.collection('workerSalaries').add(workerSalaries);
  }

  set(workerSalariesKey: string, workerSalaries: WorkerSalaries): void {
    this.afs.doc(`workerSalaries/${workerSalariesKey}`).set(workerSalaries, {merge: true});
  }

  delete(workerSalariesKey: string): Promise<void> {
    return this.afs.doc<WorkerSalaries>(`workerSalaries/${workerSalariesKey}`).update({trash: true});
  }

  async update(workerSalariesKey: string, workerSalaries: WorkerSalaries): Promise<void> {
    return await this.afs.doc<WorkerSalaries>(`workerSalaries/${workerSalariesKey}`).update(workerSalaries);
  }
}
