import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-print-purchase',
  templateUrl: './print-purchase.component.html',
  styleUrls: ['./print-purchase.component.css']
})
export class PrintPurchaseComponent implements OnInit, AfterViewInit {
  @ViewChild('componentID') componentID: ElementRef;
  @Input() purchaseOrder;
  @Output() eventPrint: EventEmitter<any> = new EventEmitter<any>();
  date: any;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.eventPrint.emit(this.componentID.nativeElement);
  }
}
