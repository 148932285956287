import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {Observable} from 'rxjs';
import {FirebaseDataService} from '../../../../shared/template-services/firebase-data.service';
import {AccountabilityStatusEnum} from '../enums/accountability-status.enum';
import {Accountability} from '../interfaces/accountability';

@Injectable({
  providedIn: 'root'
})
export class AccountabilityService extends FirebaseDataService {
  accountabilityStatus = AccountabilityStatusEnum;

  constructor(private _db: AngularFirestore,
              private _storage: AngularFireStorage) {
    super(_db, _storage);
  }

  getAll(): Observable<Accountability[]> {
    return this.colWithIds$('accountabilities', ref => ref.where('trash', '==', false));
  }

  getAllByUser(): Observable<Accountability[]> {
    return this.colWithIds$('accountabilities');
  }

  get(accountabilityKey: string): Observable<Accountability> {
    return this.docWithId$(`accountabilities/${accountabilityKey}`);
  }

  getAllByFundsToRenderGenerated(): Observable<Accountability[]> {
    return this.colWithIds$('accountabilities', (ref) => ref
      .where('trash', '==', false)
    );
  }

  getAllByFundsToRenderSupervision(
    fundsToRender: DocumentReference[]
  ): Observable<Accountability[]> {
    return this.colWithIds$('accountabilities', (ref) =>
      ref
        .where('trash', '==', false)
        .where('status', '==', this.accountabilityStatus.SUPERVISION)
        .where('fundToRender', 'in', fundsToRender)
    );
  }

  getAllByFundsToRender(): any {
    return this.colWithIds$('accountabilities', (ref) => ref
      .where('trash', '==', false)
    );
  }

  add(accountability: Accountability): Promise<DocumentReference> {
    return this._db.collection('accountabilities').add({
      ...accountability,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      trash: false
    });
  }

  update(
    accountabilityKey: string,
    accountability: Accountability
  ): Promise<void> {
    accountability['updatedAt'] = new Date().getTime();
    return this._db
      .doc(`accountabilities/${accountabilityKey}`)
      .update(accountability);
  }

  getByFundToRender(fundToRenderRef: DocumentReference): Observable<Accountability[]> {
    return this.colWithIds$('accountabilities', (ref) =>
      ref.where('trash', '==', false)
        .where('fundToRender', '==', fundToRenderRef)
    );
  }

  delete(accountabilityKey: string): Promise<void> {
    return this._db
      .doc(`accountabilities/${accountabilityKey}`)
      .update({trash: true});
  }
}
