<div class="p-2">
  <h2>Añadir número serial</h2>

  <form [formGroup]="serialForm">
    <div class="row mt-2">

      <div class="col-md-12 col-12">
        <div class="form-group">
          <label>Número serial *</label>
          <input type="text" class="form-control" formControlName="serial"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.serial.errors }"/>
          <div *ngIf="submitted && formControls.serial.errors">
            El serial es obligatorio
          </div>
        </div>
      </div>

    </div>

    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn" (click)="submit()">Aceptar</button>
      <button class="btn btn-danger add-btn" (click)="modal.hide(101)">Cancelar</button>
    </div>
  </form>
</div>
