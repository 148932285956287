import {Component, OnInit} from '@angular/core';
import {ProviderArticle} from '../../interfaces/provider-article';
import {Observable, Subscription} from 'rxjs';
import {DataTableConfig} from 'src/app/shared/interfaces/data-table-config';
import {ProviderArticleService} from '../../services/provider-article.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ProviderArticleComponent} from '../../modals/provider-article/provider-article.component';
import {ProviderService} from '../../../shared/services/provider.service';
import {UnitService} from '../../services/unit.service';
import {map, mergeMap} from 'rxjs/operators';
import {ObjectService} from '../../../shared/template-services/object.service';
import moment from 'moment';
import _ from 'lodash';

@Component({
  selector: 'app-providers-articles',
  templateUrl: './providers-articles.component.html',
  styleUrls: ['./providers-articles.component.css']
})
export class ProvidersArticlesComponent implements OnInit {
  articles$: Observable<ProviderArticle[]>;
  articlesFiltered$: Observable<ProviderArticle[]>;
  config: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron artículos',
    title: '',
    exportCallback: this.decorateProvidersArticlesToExport.bind(this),
    excelFileName: 'Artículos de proveedores',
  };

  providers: any[] = [];
  purchaseOrders: any[] = [];
  providerSelected: any;
  purchaseOrderSelected: any;
  articlesSubscription: Subscription = new Subscription();

  constructor(private _providerArticle: ProviderArticleService,
              private modal: BsModalService,
              private _provider: ProviderService,
              private _unit: UnitService) {
  }

  async ngOnInit(): Promise<void> {
    this.articles$ = this._providerArticle.getAll()
      .pipe(
        mergeMap((articles) => this._providerArticle.populate(articles, ['purchaseOrder', 'provider', 'unitType']))
      );

    this.articlesFiltered$ = this.articles$;

    this.articlesSubscription = this.articles$.subscribe((articles: any) => {
      this.providers = _.uniq(articles.map(article => ({
        value: article.provider.name,
        label: article.provider.name
      })));

      this.purchaseOrders = _.uniq(articles
        .filter(article => article.purchaseOrder)
        .map(article => ({
          value: article.purchaseOrder.purchaseID,
          label: `OC ${article.purchaseOrder.purchaseID.slice(0, -3)}`
        })));
    });
  }

  openArticle(article: any) {
    this.modal.show(ProviderArticleComponent, {
      initialState: {
        article
      },
      class: 'modal-sm'
    });
  }

  filterArticles() {
    this.articlesFiltered$ = this.articles$.pipe(
      map(articles => {
        return articles.filter((article: any) => {
          return (!this.providerSelected || article.provider.name === this.providerSelected) &&
            (!this.purchaseOrderSelected || (article.purchaseOrder ? article.purchaseOrder.purchaseID === this.purchaseOrderSelected : false));
        });
      })
    );
  }

  decorateProvidersArticlesToExport(rows: any[]) {
    return ObjectService.replaceUndefined(rows.map(row => ({
      name: row.name,
      providerCode: row.providerCode,
      provider: row.provider ? row.provider.name : 'No registrado',
      unitType: row.unitType ? row.unitType.name : '',
      unitPrice: row.unitPrice,
      exempt: row.exempt ? 'Si' : 'No',
      purchaseOrder: row.purchaseOrder ? `OC ${row.purchaseOrder.purchaseID.slice(0, -3)}` : '',
      createdAt: moment(row.createdAt).format('DD/MM/YYYY HH:mm'),
    })));
  }
}

