import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from '../../services/utils.service';
import { JobService } from '../../../shared/services/job.service';
import { Subscription } from 'rxjs';
import { CountryListService } from '../../../shared/services/country-list.service';
import { States } from '../../../../assets/json/states';
import moment from 'moment';
import { MaritalStatusArray } from './types';

@Component({
  selector: 'app-general-register',
  templateUrl: './general-register.component.html',
  styleUrls: ['./general-register.component.css']
})
export class GeneralRegisterComponent implements OnInit, OnChanges {
  @Input() formApplicantSubmitted: boolean;
  applicantForm: FormGroup;
  submitted: boolean = false;
  jobsSubscription: Subscription = new Subscription();
  selectArrayJobs: any = [];
  maritalStatusOptions = MaritalStatusArray
  path: any;
  @ViewChild('labelImport', { static: true }) labelImport: ElementRef;
  @Output() eventChangeQuiz: EventEmitter<string> = new EventEmitter<string>();
  @Output() eventChangeApplicantForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventProfilePhoto: EventEmitter<any> = new EventEmitter<any>();
  educationLevelArray = [
    'Enseñanza Media Incompleta',
    'Enseñanza Media Completa',
    'Enseñanza Técnica Completa',
    'Enseñanza Técnica Incompleta',
    'Enseñanza Universitaria Completa',
    'Enseñanza Universitaria Incompleta'
  ];
  states: any[] = [];
  cities: any[] = [];
  state: string = '';
  city: string = '';
  countries: string[] = [];
  selectedCountry;
  maxDateControl: string = moment().subtract(18, 'years').format('YYYY-MM-DD');

  constructor(private formBuilder: FormBuilder,
              private utilsService: UtilsService,
              private _jobs: JobService) {
    const dot = '\\.?';
    const hyphen = '-?';

    this.applicantForm = this.formBuilder.group({
      rut: ['', [Validators.required, Validators.pattern(`0*(\\d{1,3}(${dot}\\d{3})*)${hyphen}([\\dkK])`)]],
      name: ['', Validators.required],
      surnames: ['', Validators.required],
      region: ['', Validators.required],
      city: ['', Validators.required],
      job: ['', Validators.required],
      status: [{ name: 'postulante' }],
      birthday: ['', Validators.required],
      workExperience: ['', Validators.required],
      educationLevel: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      nationality: ['Chile', Validators.required],
      experience: ['', [Validators.required, Validators.minLength(40)]],
      expectation: ['', Validators.required],
      maritalStatus: ['', Validators.required],
    });

    this.applicantForm.controls['region'].valueChanges.subscribe(value => {
      this.stateChange(value);
    });

    this.applicantForm.controls['job'].valueChanges.subscribe(value => {
      this.eventChangeQuiz.emit(value.quiz.id);
    });
  }

  async ngOnInit(): Promise<void> {
    this.countries = CountryListService.countries;
    this.states = States;

    this.jobsSubscription = this._jobs.getAll().subscribe(data => {
      this.selectArrayJobs = data.filter(job => job.isVisible == true);
      this.selectArrayJobs = this.selectArrayJobs.map(job => ({
          name: job.name,
          quiz: job.quiz,
          reference: this._jobs.getReference(job.key),
          key: job.key
        }
      ));
    });

    this.selectedCountry = this.countries.find(country => country == 'Chile');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formApplicantSubmitted.currentValue) {
      this.applicantForm.markAllAsTouched();
    }
  }

  get formControls() {
    return this.applicantForm.controls;
  }

  isValidPath(): boolean {
    return !(this.path == '' || this.path == null);
  }

  stateChange(event) {
    this.cities = this.utilsService.citylist.filter(city => city.state_id == event.id);
  }
}
