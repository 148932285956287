import { Injectable } from '@angular/core';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {Status} from '../../admin/interfaces/status';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  getAll(): Observable<Status[]> {
    return this.db.colWithIds$<Status>('status', ref => ref
      .where('trash', '==', false));
  }

  update(statusKey: string, status: Status): Promise<void> {
    return this.afs.doc<Status>(`status/${statusKey}`).update(status);
  }

  delete(statusKey: string): Promise<void> {
    return this.afs.doc<Status>(`status/${statusKey}`).update({trash: true});
  }

  add(status: Status) {
    this.afs.collection('status').add(status);
  }
}
