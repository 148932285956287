<div class="p-2">
  <h2>{{isUpdate ? 'Actualizar salario' : 'Agregar salario'}}</h2>

  <form [formGroup]="workSalaryForm" (ngSubmit)="submit()">
    <div class="row mt-2">
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Sueldo del trabajador *</label>
          <input type="number" class="form-control" formControlName="workerSalary"
                 placeholder="" [ngClass]="{'is-invalid': submitted && formControls.workerSalary.errors}"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.workerSalary.errors">
            <div *ngIf=" formControls.workerSalary.errors.required">
              El suel del trabajador es obligatorio
            </div>
          </small>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Gastos de ley *</label>
          <input type="number" class="form-control" formControlName="lawExpenses"
                 placeholder="" [ngClass]="{'is-invalid': submitted && formControls.lawExpenses.errors}"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.lawExpenses.errors">
            <div *ngIf=" formControls.lawExpenses.errors.required">
              Los gastos de ley es obligatorio
            </div>
          </small>
        </div>
      </div>
    </div>
    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn">{{isUpdate ? 'Actualizar' : 'Agregar'}}</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide(32)">Cancelar</div>
    </div>
  </form>
</div>

