import {Component, Input, OnInit} from '@angular/core';
import {of} from 'rxjs';
import {ProjectService} from '../../../shared/services/project.service';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {Project} from '../../interfaces/project';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {map, take} from 'rxjs/operators';
import {LogsService} from '../../services/logs.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {CostListComponent} from '../../modals/cost-list/cost-list.component';
import * as _ from 'lodash';
import {TicketService} from '../../../shared/services/ticket.service';
import { BillService } from '../../../shared/services/bill.service';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.css']
})
export class BudgetComponent implements OnInit {
  @Input() project: Project;
  categories: any[];
  selectCategories: any[];
  categories$: Object;
  dataTableConfig: DataTableConfig = {
    title: 'Subcategorías',
    notFoundText: 'No se encontraron categorías',
    hasSearch: false,
    propTotal: 'totalBudget',
    propTotal2: 'budgetLeft',
    propTotal3: 'budgetSpent',
    propLabelTotal: 'Presupuesto total',
    propLabelTotal2: 'Presupuesto total gastado',
    propLabelTotal3: 'Presupuesto total restante',
  };
  totalBudget: number;
  category: any = null;

  constructor(private _project: ProjectService,
              private _bills: BillService,
              private _firebase: FirebaseDataService,
              private modal: BsModalService,
              private _log: LogsService,
              private _ticket: TicketService) {
  }

  async ngOnInit() {
    await this.getCategories();
    !!this.project.totalBudget ? this.totalBudget = this.project.totalBudget : this.totalBudget = 0;
  }

  async getCategories() {
    this.categories = await this._project.getAllCategories()
      .pipe(
        map(categories => categories.map(category => ({
            name: category.name,
            key: category.key,
            subCategory: category.subCategory,
            reference: category.reference,
            totalBudget: this.exist(category.key)
          }
        )))
      ).pipe(take(1)).toPromise();
    this.selectCategories = this.categories.filter(category => category.subCategory == true);
    this.categories = this.categories.filter(category => category.totalBudget != null);
    this.categories = await Promise.all(this.categories.map(async category => {
      const budgetSpent = await this.getBudgetSpent(category.key);
      return ({
          ...category,
          budgetLeft: this.returnBudgetLeft(category.key, budgetSpent),
          budgetSpent
        })
      }
    ));

    this.categories = _.orderBy(this.categories, 'totalBudget', 'desc')

    this.categories$ = of(this.categories);
  }

  async updateBudget(key, name) {
    let index = this.project.budgets.findIndex(budget => budget.reference.id == key);
    let totalBudget = 0;
    let used = 0;

    let quantity = await AlertService.quantity(`Cantidad de presupuesto de ${name}`, '',);
    if (!quantity) return;
    let budget: number = +quantity;

    if (!!this.project.totalBudget) totalBudget = this.project.totalBudget;

    for (let budget of this.project.budgets) {
      if (budget.reference.id != key) used = used + budget.totalBudget;
    }

    if (!budget) return AlertService.toastError('No se puede dejar el campo vacío');
    if (await AlertService.confirm(index == -1 ? `¿Estás seguro que quieres agregar la categoría ${name} con el presupuesto de ${this.currency(budget)}?`
      : `¿Estás seguro que deseas actualizar la categoría de ${name} con el presupuesto de ${this.currency(this.project.budgets[index].totalBudget)} a ${this.currency(budget)}`)) {

      if (!this.project.budgets) this.project.budgets = [];

      if (index == -1) {
        this.project.budgets.push({
          totalBudget: budget,
          reference: this._firebase.getReference(`projectCategories/${key}`),
          usedBudget: 0
        });

        this._log.updateBudget(this.project.key, {
          description: `Se añadió la categoría de ${name} con el presupuesto de ${budget}`
        });
      }

      if (index != -1) {
        this.project.budgets[index].totalBudget = budget;

        this._log.updateBudget(this.project.key, {
          description: `Se actualizó la categoría de ${name} con el presupuesto de ${budget}`
        });
      }
      await this._project.update(this.project.key, this.project);
      await this.getCategories();
      AlertService.toastSuccess('Se ha actualizado con éxito');
    }
  }

  exist(key) {
    let index;
    if (!!this.project.budgets) index = this.project.budgets.findIndex(budget => budget.reference.id == key);
    if (index != -1 && !!this.project.budgets) return this.project.budgets[index].totalBudget;
    return null;
  }

  returnBudgetLeft(categoryKey: string, budgetSpent: number) {
    if (!this.project.budgets || !this.project.budgets.length) return 0;
    const totalBudget = this.project.budgets.find(budget => budget.reference.id == categoryKey).totalBudget;

    return totalBudget - budgetSpent;
  }

  async getBudgetSpent(categoryKey: string) {
    const bills: any[] = await this._bills.getByProjectCategory(categoryKey, this.project.key);

    return bills.reduce((acc, item) =>
        typeof (item['total']) == 'number'
          ? +(item['total'].toFixed(0).toString().replaceAll('.', '')) + acc
          : +(item['total'].toString().replaceAll('.', '')) + acc
      , 0);
  }

  currency(number) {
    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(number).split('.')[0];
  }

  async addBudget() {
    if (!this.category) return AlertService.toastError('Debe seleccionar una categoría para agregar');

    let index = this.project.budgets.findIndex(budget => budget.reference.id == this.category.key);
    if (index != -1 || this.category == null) return AlertService.toastError('Esta categoría ya fue seleccionada');

    let quantity = await AlertService.quantity(`Cantidad de presupuesto de ${this.category.name}`);
    if (!quantity) return;
    let quantityNumber: number = +quantity;

    let total = 0;

    for (let budget of this.project.budgets) {
      total = total + budget.totalBudget;
    }

    let category = {
      name: this.category.name,
      reference: this._firebase.getReference(`projectCategories/${this.category.key}`),
      usedBudget: 0,
      totalBudget: quantityNumber
    };

    this.project.budgets.push(category);

    await this._project.update(this.project.key, {budgets: this.project.budgets} as Project);

    await this.getCategories();

    this.category = null;
  }

  openCostList(budgetCategory: any) {
    this.modal.show(CostListComponent, {
      initialState: {
        category: budgetCategory,
        projectKey: this.project.key
      },
      class: 'modal-xl'
    });
  }
}
