import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {Unit} from '../interfaces/unit';

@Injectable({
  providedIn: 'root'
})
export class UnitService {
  public units: any[];
  units$: ReplaySubject<any[]> = new ReplaySubject<any[]>();

  constructor(private db: FirebaseDataService, private afs: AngularFirestore) {}

  getAll() {
    if (!this.units) {
      this.units = [];
      this.db
        .colWithIds$('units', (ref) => ref.where('trash', '==', false))
        .subscribe((data) => {
          this.units$.next(data);
          this.units = data;
        });
    }

    return this.units$;
  }

  add(file): Promise<DocumentReference> {
    return this.afs.collection('units').add(file);
  }

  get(unitKey: string): Observable<Unit> {
    return this.db.docWithId$(`units/${unitKey}`);
  }

  async update(unitKey: string, unit: Unit): Promise<void> {
    return await this.afs.doc<Unit>(`units/${unitKey}`).update(unit);
  }

  delete(unitKey: string): Promise<void> {
    return this.afs.doc<Unit>(`units/${unitKey}`).update({trash: true});
  }
}
