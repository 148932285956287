import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalService} from 'ngx-bootstrap/modal';
import {WorkerSalariesService} from '../../services/worker-salaries.service';
import {WorkerSalaries} from '../../interfaces/worker-salaries';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';

@Component({
  selector: 'app-worker-salary-modal',
  templateUrl: './worker-salary-modal.component.html',
  styleUrls: ['./worker-salary-modal.component.css']
})
export class WorkerSalaryModalComponent implements OnInit {
  workSalaryForm: FormGroup;
  workerSalary: WorkerSalaries = {key: ''} as WorkerSalaries;
  isUpdate: boolean = false;
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalService,
              private _workerSalaries: WorkerSalariesService,
              private _firebase: FirebaseDataService) {
    this.workSalaryForm = formBuilder.group({
      workerSalary: ['', Validators.required],
      lawExpenses: ['', Validators.required],
      createdAt: [''],
      trash: [false],
    });
  }

  get formControls() {
    return this.workSalaryForm.controls;
  }

  ngOnInit(): void {
    if (this.isUpdate) this.workSalaryForm.patchValue(this.workerSalary);
  }

  async submit() {
    this.submitted = true;
    if (this.workSalaryForm.valid) {

      if (!this.isUpdate) {
        this.workerSalary.key = this._firebase.createID();
        this.workSalaryForm.patchValue({createdAt: new Date().getTime()});
      }

      await this._workerSalaries.set(this.workerSalary.key, this.workSalaryForm.value);

      this.modal.hide(32);
    }
  }
}
