import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DataTableConfig } from '../../../shared/interfaces/data-table-config';
import { ProviderService } from '../../../shared/services/provider.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PurchaseOrderModalComponent } from './modals/purchase-order-modal/purchase-order-modal.component';
import { PurchaseOrderService } from '../../../shared/services/purchase-order.service';
import { PurchaseStatusLabel } from '../../labels/purchase-status.label';
import { PurchaseStatus } from '../../enums/purchase-status.enum';
import { debounceTime, first, map } from 'rxjs/operators';
import { PurchaseOrder } from './interfaces/purchase-order';
import { Provider } from '../../interfaces/provider';
import { formatDate } from '@angular/common';
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { ObjectService } from '../../../shared/template-services/object.service';
import _ from 'lodash';
import { AlertService } from '../../../shared/template-services/alert.service';
import { ProjectService } from '../../../shared/services/project.service';
import { CostCenterType } from '../../enums/cost-center-type.enum';
import { ProjectStatus } from '../../enums/project-status.enum';
import { UtilsService } from 'src/app/applicant/services/utils.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-purchase-orders',
  templateUrl: './purchase-orders.component.html',
  styleUrls: ['./purchase-orders.component.css']
})
export class PurchaseOrdersComponent implements OnInit, OnDestroy {
  @Input() projectKey: string;
  purchaseOrders$: Observable<any[]>;
  config: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron ordenes de compra',
    title: '',
    filtersFields: ['purchaseID', 'providerName', 'providerRut'],
    exportCallback: this.decoratedPurchaseOrdersToExport.bind(this),
    excelFileName: 'Ordenes de compra',
    removeInfiniteScroll: true,
    pagination: true,
    paginationLimit: 25
  };
  providersSubscription: Subscription = new Subscription();
  providers: Provider[];
  purchaseStatusLabel = PurchaseStatusLabel;
  purchaseStatus = PurchaseStatus;
  filter: any = { name: 999 };
  myRadio: any = 999;
  purchasesFiltered$: Observable<PurchaseOrder[]>;
  purchasesFiltered: PurchaseOrder[];
  startDate = null;
  finalDate = null;
  selectArray = [];
  projectSubscription: Subscription = new Subscription();
  selectedProject: any = null;
  purchaseSubscription: Subscription = new Subscription();

  constructor(private _purchase: PurchaseOrderService,
              private modal: BsModalService,
              private _provider: ProviderService,
              private activatedRoute: ActivatedRoute,
              private _project: ProjectService,
              private _location: Location) {
  }

  ngOnInit(): void {
    this.getProviders();
    const date = new Date(
      `${new Date().getMonth() + 1}/01/${new Date()
        .getFullYear()
        .toString()
        .slice(-2)}`
    );
    this.startDate = this.formatDate(date);
    this.finalDate = this.formatDate(moment(date).add(1, 'month').toDate());

    this.loadProjects();
  }

  ngOnDestroy(): void {
    this.providersSubscription.unsubscribe();
    this.projectSubscription.unsubscribe();
    this.purchaseSubscription.unsubscribe();
  }

  private loadProjects() {
    this.projectSubscription = this._project
      .getAll()
      .pipe(
        map((project) =>
          project.map((project) => ({
            ...project,
            type:
              project.type == CostCenterType.PROJECT
                ? 'Proyectos'
                : 'Centro de Costos'
          }))
        )
      )
      .subscribe(async (data) => {
        if (data.length > 0) {
          data = data.filter(
            (d) =>
              d.status == ProjectStatus.ACTIVE ||
              (d.status == ProjectStatus.INACTIVE &&
                UtilsService.convertToTime(d.endDate) >=
                new Date().getTime() - 2629800000)
          );
        }
        this.selectArray = data;
      });
  }

  private formatDate(date) {
    const deliveryDateDate = new Date(date);
    const format = 'yyyy/MM/dd';
    const locale = 'en-US';
    const zone = 'UTC';
    const formattedDate = formatDate(deliveryDateDate, format, locale, zone);
    return formattedDate.replace(/\//g, '-');
  }

  async getPurchaseOrders() {
    this.purchaseOrders$ = this._purchase.getAll().pipe(
      map((purchases) =>
        purchases.map((purchase) => {
          const provider = this.getProvider(purchase.provider);
          return {
            ...purchase,
            providerName: !!provider.name ? provider.name : '',
            providerRut: !!provider.rut ? provider.rut : ''
          };
        })
      )
    );
    if (this.projectKey) {
      this.purchaseOrders$ = this.purchaseOrders$.pipe(
        map((purchases) =>
          purchases.filter((purchase) => purchase.project.id == this.projectKey)
        )
      );
    }
    this.purchasesFiltered$ = this.purchaseOrders$;
    this.purchasesFiltered = await this.purchasesFiltered$.pipe(debounceTime(1000), first()).toPromise();

    this.activatedRoute.params.subscribe(async (params) => {
      if (params.purchaseOrderKey) {
        const purchaseOrders = await this.purchaseOrders$
          .pipe(first())
          .toPromise();
        this.gotoOpenEditPurchaseOrder(
          purchaseOrders.find(
            (purchase) => purchase.key == params.purchaseOrderKey
          )
        );
      }
    });

    this.changePurchaseStatus();
  }

  getProviders() {
    this.providersSubscription = this._provider.getAll().subscribe((data) => {
      this.providers = data;

      this.getPurchaseOrders();
    });
  }

  gotoOpenEditPurchaseOrder(purchaseOrder) {
    this._location.replaceState(`/admin/purchase-orders/${purchaseOrder.key}`);

    const modalRef = this.modal.show(PurchaseOrderModalComponent, {
      initialState: {
        purchaseOrder: { ...purchaseOrder }
      },
      class: 'modal-xxl',
      backdrop: 'static',
      id: 31
    });

    modalRef.onHide.subscribe(() => {
      this._location.replaceState('/admin/purchase-orders');
    });
  }

  openAddPurchaseOrderModal() {
    this.modal.show(PurchaseOrderModalComponent, {
      class: 'modal-xxl',
      backdrop: 'static',
      id: 31
    });
  }

  getProvider(providerReference: any): any {
    let providerIndex = this.providers.findIndex(
      (provider) => provider.key == providerReference.id
    );
    if (providerIndex == -1) {
      return '';
    }
    return this.providers[providerIndex];
  }

  getStatusClass(status: number) {
    switch (status) {
      case PurchaseStatus.CANCELLED:
        return 'cancelled-status-color';

      case PurchaseStatus.DRAFT:
        return 'draft-status-color';

      case PurchaseStatus.GENERATED:
        return 'generated-status-color';

      case PurchaseStatus.AUTHORIZED:
        return 'authorized-status-color';

      case PurchaseStatus.NOT_AUTHORIZED:
        return 'not-authorized-status-color';

      case PurchaseStatus.FINALIZED:
        return 'finalized-status-color';

      case PurchaseStatus.INCOMPLETE:
        return 'incomplete-status-color';

      default:
        return 'finalized-status-color';
    }
  }

  async changePurchaseStatus() {
    if (this.myRadio == 999) {
      (this.purchasesFiltered$ = this.purchaseOrders$);
      this.purchasesFiltered = await this.purchasesFiltered$.pipe(debounceTime(1000), first()).toPromise();

      return;
    }

    this.filter = null;

    switch (this.myRadio) {
      case PurchaseStatus.DRAFT:
        this.filter = { name: 'BORRADORES', status: PurchaseStatus.DRAFT };
        break;

      case PurchaseStatus.GENERATED:
        this.filter = { name: 'GENERADOS', status: PurchaseStatus.GENERATED };
        break;

      case PurchaseStatus.AUTHORIZED:
        this.filter = { name: 'AUTORIZADOS', status: PurchaseStatus.AUTHORIZED };
        break;

      case PurchaseStatus.CANCELLED:
        this.filter = { name: 'CANCELADOS', status: PurchaseStatus.CANCELLED };
        break;

      case PurchaseStatus.NOT_AUTHORIZED:
        this.filter = { name: 'REGRESADOS', status: PurchaseStatus.NOT_AUTHORIZED };
        break;

      case PurchaseStatus.FINALIZED:
        this.filter = { name: 'FINALIZADO', status: PurchaseStatus.FINALIZED };
        break;

      case 'InShippingProcess':
        this.filter = { name: 'EN PROCESO DE ENVÍO' };
        break;

      case 'Unbilled':
        this.filter = { name: 'NO FACTURADO' };
        break;

      default:
        this.filter = { name: 'TODOS' };
        break;
    }

    this.filterPurchases();
  }

  async resetBills() {
    this.purchasesFiltered = await this.purchasesFiltered$.pipe(debounceTime(1000), first()).toPromise();
    return (this.purchasesFiltered$ = this.purchaseOrders$);
  }

  async filterPurchases() {
    let startDate;
    let startDateMilliseconds;
    let finalDate;
    let finalDateMilliseconds;

    if (this.startDate) {
      startDate = this.startDate.replaceAll('-', '/');
      startDateMilliseconds = new Date(startDate).getTime();
    } else {
      startDateMilliseconds = 0;
    }

    if (this.finalDate) {
      finalDate = this.finalDate.replaceAll('-', '/');
      finalDateMilliseconds = new Date(finalDate).getTime();
    } else {
      finalDateMilliseconds = Infinity;
    }

    if (this.filter.name == 'EN PROCESO DE ENVÍO') {
      this.purchasesFiltered$ = this.purchaseOrders$.pipe(
        map((purchase) =>
          purchase
            .filter(
              (bill) =>
                bill.deliveryDate >= startDateMilliseconds &&
                bill.deliveryDate <= finalDateMilliseconds
            )
            .filter((purchase) => !purchase.received)
            .filter(
              (purchase) =>
                purchase.status == PurchaseStatus.AUTHORIZED ||
                purchase.status == PurchaseStatus.NOT_AUTHORIZED
            )
            .filter(purchase => !this.selectedProject || purchase.project.id == this.selectedProject.key)
        )
      );
      this.purchasesFiltered = await this.purchasesFiltered$.pipe(debounceTime(1000), first()).toPromise();
      return;
    }

    if (this.filter.name == 'NO FACTURADO') {
      this.purchasesFiltered$ = this.purchaseOrders$.pipe(
        map((purchase) =>
          purchase
            .filter(
              (bill) =>
                bill.deliveryDate >= startDateMilliseconds &&
                bill.deliveryDate <= finalDateMilliseconds
            )
            .filter((purchase) => purchase.invoiced == false)
            .filter(
              (purchase) =>
                purchase.status == PurchaseStatus.AUTHORIZED ||
                purchase.status == PurchaseStatus.NOT_AUTHORIZED
            )
            .filter(purchase => !this.selectedProject || purchase.project.id == this.selectedProject.key)
        )
      );
      this.purchasesFiltered = await this.purchasesFiltered$.pipe(debounceTime(1000), first()).toPromise();
      return;
    }

    if (this.filter.name == 999) {
      this.purchasesFiltered$ = this.purchaseOrders$.pipe(
        map((purchase) =>
          purchase
            .filter(
              (bill) =>
                bill.deliveryDate >= startDateMilliseconds &&
                bill.deliveryDate <= finalDateMilliseconds)
            .filter(purchase => !this.selectedProject || purchase.project.id == this.selectedProject.key)
        )
      );
      this.purchasesFiltered = await this.purchasesFiltered$.pipe(debounceTime(1000), first()).toPromise();
      return;
    }

    this.purchasesFiltered$ = this.purchaseOrders$.pipe(
      map((purchase) =>
        _.uniqBy(
          purchase
            .filter(
              (bill) =>
                bill.deliveryDate >= startDateMilliseconds &&
                bill.deliveryDate <= finalDateMilliseconds
            )
            .filter((purchase) => purchase.status == this.filter.status)
            .filter(purchase => !this.selectedProject || purchase.project.id == this.selectedProject.key),
          'key')
      )
    );
    this.purchasesFiltered = await this.purchasesFiltered$.pipe(debounceTime(1000), first()).toPromise();
  }

  decoratedPurchaseOrdersToExport(rows: any[]) {
    return ObjectService.replaceUndefined(rows.map((row) => {
      return {
        status: this.purchaseStatusLabel[row.status],
        purchaseID: row.purchaseID.slice(0, -3),
        providerName: row.providerName,
        providerRut: row.providerRut,
        description: row.description,
        total: row.total,
        received: row.received ? 'Entregado' : 'No entregado',
        invoiced: row.invoiced ? 'Facturado' : 'No facturado'
      };
    }));
  }

  async deletePurchase(row: any) {
    if (await AlertService.confirm('¿Está seguro de eliminar esta orden de compra?')) {
      await this._purchase.update(row.key, { trash: true } as any);
      AlertService.toastSuccess('Orden de compra eliminada');
    }
  }
}
