import { Component, ContentChildren, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTableConfig } from '../../interfaces/data-table-config';
import { DataTableColumnComponent } from '../data-table-column/data-table-column.component';
import { PrintService } from '../../services/print.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ExportToCsv } from 'export-to-csv';
import _ from 'lodash';

@Component({
  selector: 'app-datatable',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})
export class DataTableComponent implements OnInit {
  @ContentChildren(DataTableColumnComponent) columns: QueryList<DataTableColumnComponent>;
  @Input() rows$: Observable<any> = new Observable<any>();
  @Input() config: DataTableConfig;
  @Input() printSmall: boolean = false;
  @Output() rowSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadMore: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('componentID') componentID: ElementRef;
  isLocal: boolean = false;
  search: string;
  sortByFieldSelected = null;
  orderType: 'asc' | 'desc' = 'desc';
  isPrinting: boolean = false;
  @ViewChild(CdkVirtualScrollViewport, {static: false})
  public viewPort: CdkVirtualScrollViewport;

  constructor() {
    this.isLocal = window.location.hostname == 'localhost';
  }

  ngOnInit(): void {

  }

  public get inverseOfTranslation(): string {
    if (!this.viewPort || !this.viewPort['_renderedContentOffset']) {
      return '-0px';
    }
    let offset = this.viewPort['_renderedContentOffset'];
    return `-${offset}px`;
  }

  selectRow(isClickable: boolean, row) {
    if (isClickable) {
      this.rowSelected.emit(row);
    }
  }

  printList(): void {
    this.isPrinting = true;
    setTimeout(() => {
      PrintService.print(this.componentID.nativeElement);
      this.isPrinting = false;
    }, 100);
  }

  trackByKey(index: number, item: any) {
    if (!item) return null;
    return item.key;
  }

  selectSortByField(field: string) {
    if (!!this.config.orderByFields && this.config.orderByFields.some(fieldItem => fieldItem == field)) {
      this.sortByFieldSelected = field;
      this.orderType = this.orderType == 'asc' ? 'desc' : 'asc';
    }
  }

  calculateTotal(items): number {
    return items.reduce((acc, item) =>
        typeof (item[this.config.propTotal]) == 'number'
          ? +(item[this.config.propTotal].toFixed(0).toString().replaceAll('.', '')) + acc
          : +(item[this.config.propTotal].toString().replaceAll('.', '')) + acc
      , 0);
  }

  calculateTotal3(items): number {
    return items.reduce((acc, item) =>
        typeof (item[this.config.propTotal2]) == 'number'
          ? +(item[this.config.propTotal2].toFixed(0).toString().replaceAll('.', '')) + acc
          : +(item[this.config.propTotal2].toString().replaceAll('.', '')) + acc
      , 0);
  }

  calculateTotal2(items): number {
    return items.reduce((acc, item) =>
        typeof (item[this.config.propTotal3]) == 'number'
          ? +(item[this.config.propTotal3].toFixed(0).toString().replaceAll('.', '')) + acc
          : +(item[this.config.propTotal3].toString().replaceAll('.', '')) + acc
      , 0);
  }

  emitLoadMore() {
    this.loadMore.emit();
  }

  exportToExcel(rows: any[]) {
    let headers = [];
    this.columns.forEach((column: any) => {
      headers = [
        ...headers,
        column.title
      ];
    });

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      filename: this.config.excelFileName,
      title: this.config.excelFileName,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: headers.filter(header => !!header && (!this.config.columnsToDelete || !this.config.columnsToDelete.length || !this.config.columnsToDelete.includes(header)))
    };

    const exportData = this.config.exportCallback(_.cloneDeep(rows));
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(exportData);
  }
}

/*

TODO: GET DATA BY PAGES

next;

async getPages() {

  const {data, next, isLast} = await this.db.getByPages({
    refCol: 'units',
    queryFn: ref => ref.where('trash', '==', false),
  }, 'trash', 5, this.next);

  this.next = next;

  setTimeout(() => {
    if (!isLast){
      this.getPages();
    }
  }, 1000);

  return data;
}

 */
