import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/internal/operators/map';
import {take} from 'rxjs/internal/operators/take';
import {DataTableConfig} from 'src/app/shared/interfaces/data-table-config';
import {ArticleService} from 'src/app/shared/services/article.service';
import {UserService} from 'src/app/shared/services/user.service';
import {Permission} from '../../../../../enums/permissions.enum';
import {Article} from '../../../interfaces/article';
import {Warehouse} from '../../../interfaces/warehouse';
import {WarehouseDetailService} from '../../../services/warehouse-detail.service';
import {ExportToCsv} from 'export-to-csv';
import {NotificationService} from '../../../../../services/notification.service';
import {PermissionService} from '../../../services/permission.service';

@Component({
  selector: 'app-minimum-stock',
  templateUrl: './minimum-stock.component.html',
  styleUrls: ['./minimum-stock.component.css']
})
export class MinimumStockComponent implements OnInit {
  @Input() warehouseKey: string;
  @Input() warehouse: Warehouse;
  articles$: Observable<Article[]>;
  articlesFiltered$: Observable<Article[]>;
  articles: Article[] = [];
  config: DataTableConfig = {
    title: '',
    notFoundText: 'No se encontraron artículos',
    hasSearch: true
  };
  permission: Permission;
  permissionEnum = Permission;
  filteredArticles;
  buyer: any;

  constructor(private _article: ArticleService,
              private _warehouseDetail: WarehouseDetailService,
              public _user: UserService,
              public _notification: NotificationService,
              public _permission: PermissionService) {
  }

  async ngOnInit() {
    if (!this.warehouseKey) {
      this.warehouseKey = this._warehouseDetail.warehouseKey;
      this.warehouse = this._warehouseDetail.warehouse;
    }
    this.articles = await this._article
      .getAllByWarehouse(this.warehouseKey)
      .pipe(take(1))
      .toPromise();
    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'BODEGAS'
    ).permission;

    if (this.permission != Permission.BUYER && this._permission.hasUserType(Permission.BUYER as any)) {
      this.permission = Permission.BUYER;
    }

    this.articles$ = this._article.getAllByWarehouse(this.warehouseKey);
    this.articlesFiltered$ = this.articles$.pipe(
      map((articles) =>
        articles.filter((article) => article.quantity < article.minStock)
      )
    );
    this.filteredArticles = await this.articles.filter(
      (article) => article.quantity < article.minStock
    );
    this.buyer = await this._user.getSpecificUser(this.warehouse.buyer['id']);
  }

  async exportToCSV() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      filename: `Lista de artículos con menos del stock mínimo`,
      title: 'Stock mínimo',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: [
        'Código',
        'Artículo',
        'Categoría',
        'Precio',
        'Locación',
        'Cantidad',
        'Stock mínimo'
      ]
    };

    let articleReport = [];
    for (let article of this.filteredArticles) {
      articleReport.push({
        internalId: article.internalId,
        article: article.name,
        category: article.categories[0].name,
        price: article.price,
        location: article.location,
        quantity: article.quantity,
        minStock: article.minStock
      });
    }
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(articleReport);
  }
}
