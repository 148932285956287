<form [formGroup]="permissionForm" (ngSubmit)="addPermission()" class="row">
  <div class="col-4">
    <div class="form-group">
      <label>Sección</label>
      <ng-select
        [items]="sections"
        formControlName="section">
      </ng-select>
    </div>
  </div>
  <div class="col-4">
    <div class="form-group">
      <label>Permiso</label>
      <ng-select
        [items]="permissionForm.value.section == 'BODEGAS' ? permissionsWithGrocer : permissions"
        blindName="label"
        formControlName="permission">
      </ng-select>
    </div>
  </div>

  <div class="col-2 mt-2">
    <button class="btn btn-success btn-sm" [ngbTooltip]="'Agregar permiso'" [disabled]="!permissionForm.valid">
      <i class="la la-plus"></i>
    </button>
  </div>
</form>

<app-datatable
  [rows$]="permissions$"
  [config]="dataTableConfig">
  <app-datatable-column title="Sección">
    <ng-template let-row #column>
      {{row.section}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Permiso">
    <ng-template let-row let-index="index" #column>
      <ng-select
        appendTo="body"
        [items]="row.section == 'BODEGAS' ? permissionsWithGrocer : permissions"
        [(ngModel)]="row.permission"
        (change)="updatePermission($event, index)"
        blindName="label">
      </ng-select>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Sección">
    <ng-template let-row let-index="index" #column>
      <button class="btn btn-danger btn-sm" (click)="deletePermission(index, row)"><i class="la la-trash"></i></button>
    </ng-template>
  </app-datatable-column>
</app-datatable>
