<div class="bg-transparent" *ngIf="!isImage()">
  <iframe
    width="100%"
    height="800px"
    frameborder="0"
    [src]="url | safe"></iframe>
</div>

<div class="bg-transparent" ngxViewer *ngIf="isImage()">
  <img [src]="url" style="width: 100%">
</div>
