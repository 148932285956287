import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../shared/interfaces/user';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ObjectService } from '../../../shared/template-services/object.service';
import { AlertService } from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {
  @Input() user: User;
  jsonHeader = 'application/json; odata=verbose';
  headers = { 'Content-Type': this.jsonHeader, Accept: this.jsonHeader };
  filesArray: any[] = [];

  constructor(private httpClient: HttpClient,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
  }

  async readFiles(urlFiles: any[]) {
    for (const { url, label } of urlFiles.filter(file => !!file.url)) {
      const file = await this.getFile(url);
      const b: any = new Blob([file], { type: '' + file.type + '' });
      const reader = new FileReader();

      reader.readAsDataURL(b);
      reader.onloadend = () => {
        this.filesArray.push({
          base64: reader.result,
          label,
          type: file.type
        });
      };
    }
  }

  async getFilesFromLibrary() {
    await this.readFiles([
      {
        url: this.user.imageUrl,
        label: 'Foto tipo carnet'
      },
      {
        url: this.user.cv,
        label: 'CV'
      },
      {
        url: this.user.indentificationCardFront,
        label: 'Cédula de identidad Parte frontal'
      },
      {
        url: this.user.indentificationCardBack,
        label: 'Cédula de identidad Parte trasera'
      },
      {
        url: this.user.backgroundPaper,
        label: 'Papel de Antecedentes'
      },
      {
        url: this.user.birthCertificate,
        label: 'Certificado de nacimiento'
      },
      {
        url: this.user.afpAffiliationCertificate,
        label: 'Certificado de afiliación AFP'
      },
      {
        url: this.user.healthAffiliationCertificate,
        label: 'Certificado de afiliación salud'
      },
      {
        url: this.user.driverLicensePhoto,
        label: 'Licencia de conducir'
      },
      {
        url: this.user.driverResume,
        label: 'Hoja de vida de conductor'
      },
      {
        url: this.user.title,
        label: 'Título'
      },
      {
        url: this.user.signature,
        label: 'Firma'
      },
      {
        url: this.user.finiquitoUrl,
        label: 'Último finiquito'
      }
    ]);
  }

  async downloadFile() {
    if (!await AlertService.confirm('¿Está seguro de descargar los documentos?', 'Se descargaran en un zip')) return;

    this.spinner.show();

    await this.getFilesFromLibrary();

    await this.createZip(this.filesArray, `${(this.user.name || '').toUpperCase()} ${(this.user.surnames || '').toUpperCase()}`);

    this.spinner.hide();
  }

  async getFile(url: string) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return await this.httpClient.get(url, httpOptions).toPromise().catch((err: HttpErrorResponse) => {
      return err.error;
    });
  }

  async createZip(files: any[], zipName: string) {
    const zip = new JSZip();
    const name = zipName + '.zip';

    for (let counter = 0; counter < files.length; counter++) {
      const file = files[counter];

      const blobFile = ObjectService.base64ToBlob(file.base64, file.type);

      zip.file(`${(this.user.name || '').toUpperCase()}_${(this.user.surnames || '').toUpperCase()}_${file.label.toUpperCase()}.${file.type.split('/')[1]}`, blobFile, { dir: false });
    }

    zip.generateAsync({ type: 'blob' }).then((content) => {
      if (content) {
        FileSaver.saveAs(content, name);
      }
    });
  }
}
