import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../shared/template-services/alert.service';
import {ProjectService} from '../../../shared/services/project.service';
import {ProjectCategoriesModalComponent} from '../../modals/project-categories-modal/project-categories-modal.component';
import {ProjectCategory} from '../../interfaces/project-category';
import {map} from 'rxjs/operators';
import {AddToSubCategoryComponent} from '../../modals/add-to-sub-category/add-to-sub-category.component';

@Component({
  selector: 'app-project-categories',
  templateUrl: './project-categories.component.html',
  styleUrls: ['./project-categories.component.css']
})
export class ProjectCategoriesComponent implements OnInit {
  categories$: Observable<ProjectCategory[]>
  categoriesFiltered$: Observable<ProjectCategory[]>;
  subCategoriesFiltered$: Observable<ProjectCategory[]>;
  selectedCategory: any;
  config: DataTableConfig = {
    notFoundText: "No se encontraron categorías",
    title: "Lista de sub categorías",
    hasSearch: true
  };

  configFilter: DataTableConfig = {
    notFoundText: "No se encontraron sub categorías",
    title: "Lista de sub categorías",
    hasSearch: true
  };

  constructor(private _project: ProjectService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.loadCategories();
  }

  loadCategories() {
    this.categories$ = this._project.getAllCategories();

    this.categoriesFiltered$ = this.categories$.pipe(map(category => category.filter(category => {
      return !category.subCategory || category.subCategory == undefined;
    })));
  }

  openAddCategoryModal() {
    this.modal.show(ProjectCategoriesModalComponent, {
      initialState: {
        isSubCategory: false
      }
    });
  }

  async delete(key: string) {
    if (await AlertService.confirm('¿Estás seguro que deseas eliminar esta categoría?')) {
      await this._project.deleteCategory(key);
      AlertService.toastSuccess('Se ha eliminado con éxito');
    }
  }

  openUpdateCategoryModal(projectKey: any) {
    this.modal.show(ProjectCategoriesModalComponent, {
      initialState: {
        categoryKey: projectKey,
        isSubCategory: false,
        isUpdate: true
      }
    });
  }

  selectCategory(project) {
    if (project.subCategory == undefined) return;
    this.configFilter.title = `Lista de sub categorías de ${project.name}`
    this.selectedCategory = project.key;
    this.subCategoriesFiltered$ = this.categories$.pipe(map(category => category.filter(category => {
      return !!category.reference && category.reference.id == project.key;
    })));
  }

  openAddSubCategoryModal() {
    this.modal.show(ProjectCategoriesModalComponent, {
      initialState: {
        categoryKey: this.selectedCategory,
        isSubCategory: true,
      }
    });
  }

  openUpdateSubCategoryModal(projectKey: any) {
    this.modal.show(ProjectCategoriesModalComponent, {
      initialState: {
        categoryKey: this.selectedCategory,
        isUpdate: true,
        isSubCategory: true,
        subCategoryKey: projectKey
      }
    });
  }

  addToSubCategory(key) {
    this.modal.show(AddToSubCategoryComponent, {
      initialState: {
        categoryKey: key,
      }
    });
  }
}
