// Default menu settings configurations

export interface MenuItem {
  title: string;
  icon: string;
  page: string;
  isHidden?: boolean;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  isStarterkitExternalLink?: boolean;
  badge: { type: string; value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {
  horizontal_menu: {
    items: Partial<MenuItem>[];
  };
  vertical_menu: {
    items: Partial<MenuItem>[];
  };
}

export const MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {
    items: []
  },
  vertical_menu: {
    items: [
      {
        title: 'Home',
        icon: 'la-home',
        page: '/admin/home/default'
      },
      {
        title: 'Notificaciones',
        icon: 'la-bell',
        page: '/admin/notifications'
      },
      {
        title: 'Usuarios',
        icon: 'la-users',
        page: '/admin/users'
      },
      {
        title: 'Ventas',
        icon: 'la-tag',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Tableros',
              icon: 'la-tag',
              page: '/admin/sales-boards'
            },
            {
              title: 'Reportes',
              icon: 'la-bar-chart',
              page: '/admin/sales/report'
            }
          ]
        }
      },
      {
        title: 'Bodegas',
        icon: 'la-building',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Lista de bodegas',
              icon: 'la-building',
              page: '/admin/warehouse'
            },
            {
              title: 'Categorías',
              icon: 'la-object-group',
              page: '/admin/categories'
            },
            {
              title: 'Detalles',
              icon: 'la-object-group',
              page: '/admin/warehouseDetails',
              isHidden: true
            }
          ]
        }
      },
      {
        title: 'Proyectos',
        icon: 'la-folder-o',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Proyectos',
              page: '/admin/projects'
            },
            {
              title: 'Centros de costo',
              page: '/admin/costCenters'
            },
            {
              title: 'Categorías de presupuestos',
              page: '/admin/projectCategories'
            },
            {
              title: 'Clientes',
              page: '/admin/clients'
            }
          ]
        }
      },
      {
        title: 'Postulantes',
        icon: 'la-clipboard',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Dashboard',
              page: '/admin/dashboard'
            },
            {
              title: 'Lista General',
              page: '/admin/applicants'
            },
            {
              title: 'Postulantes',
              page: '/admin/postulante-applicants/postulante'
            },
            {
              title: 'Aptos',
              page: '/admin/apto-applicants/apto'
            },
            {
              title: 'Revisión',
              page: '/admin/revision-applicants/revision'
            },
            {
              title: 'Contratados',
              page: '/admin/contratado-applicants/contratado'
            },
            {
              title: 'Calificados Colsan',
              page: '/admin/calificado-applicants/calificado'
            },
            {
              title: 'Calificados Colsan',
              page: '/admin/applicant-details',
              isHidden: true
            },
            {
              title: 'Configuración',
              icon: 'icon-settings',
              page: 'null',
              submenu: {
                items: [
                  {
                    title: 'Cargos',
                    page: '/admin/positions'
                  },
                  {
                    title: 'Cuestionarios',
                    page: '/admin/quiz'
                  },
                  {
                    title: 'Estatus',
                    page: '/admin/status'
                  },
                  {
                    title: 'Afp',
                    page: '/admin/afp'
                  }
                ]
              }
            }
          ]
        }
      },
      {
        title: 'Compras',
        icon: 'feather ft-shopping-cart',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Lista',
              page: '/admin/purchase-orders'
            },
            // {
            //   title: 'Artículos por llegar',
            //   page: '/admin/articles-to-come',
            // },
            {
              title: 'Proveedores',
              page: '/admin/providers'
            },
            {
              title: 'Artículos',
              page: '/admin/providerArticles'
            },
            {
              title: 'Unidades',
              page: '/admin/units'
            },
            {
              title: 'Configuración',
              page: '/admin/id-settings'
            }
          ]
        }
      },
      {
        title: 'Tesorería',
        icon: 'la-money',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Facturas',
              page: '/admin/bills'
            },
            {
              title: 'Comprobantes de pago',
              page: '/admin/proofPayments'
            },
            {
              title: 'Sueldos',
              page: '/admin/workerSalaries'
            },
            {
              title: 'Gastos totales',
              page: '/admin/totalExpenses'
            },
            {
              title: 'Reportes',
              page: '/admin/reports'
            },
            {
              title: 'Reportes por proyectos',
              page: '/admin/project-invoice-reports'
            }
          ]
        }
      },
      {
        title: 'Tareas',
        icon: 'la-list',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Tableros',
              page: '/admin/boards'
            }
          ]
        }
      },
      {
        title: 'Fondos por rendir',
        icon: 'la-exchange',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Lista de fondos',
              page: '/admin/funds-to-render'
            },
            {
              title: 'Comprobantes',
              page: '/admin/receipts'
            },
            {
              title: 'Solicitudes de fondos',
              page: '/admin/funds-to-render-requests'
            },
            {
              title: 'Categorías',
              page: '/admin/breakdown-amount-categories'
            },
            {
              title: 'Registros',
              page: '/admin/funds-to-render-logs'
            },
            {
              title: 'Rendiciones revisión',
              page: '/admin/manage-accountabilities'
            }
          ]
        }
      },
      {
        title: 'Fondos por rendir v2',
        icon: 'la-exchange',
        page: '/admin/fund-to-render-v2'
      },
      {
        title: 'Flota',
        icon: 'la-car',
        page: '/admin/vehicles'
      }
    ]
  }
};
