import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-add-to-excel',
  templateUrl: './add-to-excel.component.html',
  styleUrls: ['./add-to-excel.component.css']
})
export class AddToExcelComponent implements OnInit {
  data: any[] = [];
  file: any;
  headers: string[] = [];
  items: any[] = [];
  count = [];
  send: boolean = false;
  name: string = '';
  description: string = '';

  constructor(public modal: BsModalRef) {
  }

  async ngOnInit(): Promise<void> {
  }

  extractDataFromExcelFile(event) {
    const target: DataTransfer = <DataTransfer>(event.target);

    if (target.files.length !== 1) {
      AlertService.error('No se pueden cargar multiples archivos', '');
      return;
    }

    this.file = target.files[0];
  }

  async sendData() {
    this.send = true;
    this.modal.hide();
  }
}
