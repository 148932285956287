<ul class="d-flex justify-content-center w-100">
  <li
    class="page-item cursor-pointer"
    *ngIf="currentPage > (pagesToShow / 2) + 1 && totalPages > pagesToShow"
    (click)="handlePageChange(1)"
  >
    1
  </li>
  <li
    class="page-item cursor-pointer"
    *ngIf="currentPage > (pagesToShow / 2) + 1 && totalPages > pagesToShow"
  >
    ...
  </li>
  <ng-container *ngFor="let page of pages">
    <li
      class="page-item cursor-pointer"
      *ngIf="page != -1"
      [ngClass]="{'page-active': currentPage === page}"
      (click)="handlePageChange(page)"
    >
      {{page}}
    </li>
  </ng-container>
  <li
    class="page-item cursor-pointer"
    *ngIf="currentPage < totalPages - (pagesToShow / 2) && totalPages > pagesToShow"
  >
    ...
  </li>
  <li
    class="page-item cursor-pointer"
    *ngIf="currentPage != totalPages && totalPages > pagesToShow"
    (click)="handlePageChange(totalPages)"
  >
    {{totalPages}}
  </li>
</ul>
