import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FundToRenderService } from 'src/app/shared/services/fund-to-render.service';
import { FundToRender } from '../../interfaces/fund-to-render';
import { Receipt } from '../../interfaces/receipt';
import { first } from 'rxjs/internal/operators/first';
import { take } from 'rxjs/internal/operators/take';
import { ReceiptService } from '../../services/receipt.service';
import { Subscription } from 'rxjs';
import { AccountabilityService } from '../../services/accountability.service';
import { AlertService } from '../../../../../shared/template-services/alert.service';
import { FirebaseDataService } from '../../../../../shared/template-services/firebase-data.service';
import { Accountability } from '../../interfaces/accountability';
import { AccountabilityStatusEnum } from '../../enums/accountability-status.enum';
import { DocumentReference } from '@angular/fire/firestore';
import { BreakdownAmountCategory } from '../../interfaces/breakdown-amount-catengory';
import { BreakdownAmountCategoryService } from '../../services/breakdown-amount-category.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ReceiptComponent } from '../receipt/receipt.component';

@Component({
  selector: 'app-fund-to-render-details',
  templateUrl: './fund-to-render-details.component.html',
  styleUrls: ['./fund-to-render-details.component.css']
})
export class FundToRenderDetailsComponent implements OnInit {
  fundToRender: FundToRender;
  receipts: Receipt[] = [];
  breakdownAmountsCategories: BreakdownAmountCategory[] = [];
  fundToRenderKey: string;
  receiptsKeys: string[] = [];
  receiptSubscription: Subscription = new Subscription();
  totalReceiptsAmount: number = 0;
  finalBalance: number = 0;
  requiredAmount: number = 0;

  constructor(private router: Router,
              private modal: BsModalService,
              private _fundToRender: FundToRenderService,
              private _breakdownAmountCategory: BreakdownAmountCategoryService,
              private _receipt: ReceiptService,
              private _accountability: AccountabilityService,
              private _db: FirebaseDataService) {
  }

  async ngOnInit() {
    this.loadBreakdownAmountsCategories();
    this.getLocalStorageData();
    await this.loadFundToRender();
    this.listenForReceipts();
    this.calculateAmounts();
  }

  ngOnDestroy() {
    this.receiptSubscription.unsubscribe();
  }

  getLocalStorageData() {
    const fundToRenderKey = localStorage.getItem('fundToRenderKey');
    const receiptsStringified = localStorage.getItem('receiptKeys');
    const receiptsKeys = JSON.parse(receiptsStringified);

    if (!fundToRenderKey || !receiptsKeys) {
      return this.router.navigateByUrl('/admin/receipts');
    }
    this.fundToRenderKey = fundToRenderKey;
    this.receiptsKeys = receiptsKeys;
    localStorage.removeItem('fundToRenderKey');
    localStorage.removeItem('receiptKeys');
  }

  calculateAmounts() {
    this.calculateRequiredAmount();
    this.calculateReceiptsTotalAmount();
    this.calculateFinalBalance();
  }

  async loadFundToRender() {
    this.fundToRender = await this._fundToRender
      .get(this.fundToRenderKey)
      .pipe(first())
      .toPromise();
  }

  async loadBreakdownAmountsCategories() {
    this.breakdownAmountsCategories = await this._breakdownAmountCategory
      .getAll()
      .pipe(take(1))
      .toPromise();
  }

  listenForReceipts() {
    this.receiptSubscription = this._receipt.getAll().subscribe((receipts) => {
      this.receipts = this.filterReceiptsInAccountability(receipts).map(
        (receipt) => ({
          ...receipt,
          isSelected: false,
          category: this.breakdownAmountsCategories.find(
            (category) =>
              category.key == (<DocumentReference>receipt.category).id
          )
        })
      );
      this.calculateAmounts();
    });
  }

  filterReceiptsInAccountability(receipts: Receipt[]) {
    return receipts.filter((receipt) =>
      this.receiptsKeys.some((key) => key == receipt.key)
    );
  }

  calculateReceiptsTotalAmount() {
    if (!this.receipts.length) return;
    this.totalReceiptsAmount = this.receipts.reduce(
      (acc, {amount}) => acc + amount,
      0
    );
  }

  calculateFinalBalance() {
    if (!this.receipts.length || !this.fundToRender) return;
    this.finalBalance = this.requiredAmount - this.totalReceiptsAmount;
  }

  calculateRequiredAmount() {
    if (!this.fundToRender) return;
    this.requiredAmount = this.fundToRender.breakdownAmounts.reduce(
      (acc, {amount}) => acc + amount,
      0
    );
  }

  async goToEditReceiptsModal(receipt: Receipt) {
    this.modal.show(ReceiptComponent, {
      initialState: {
        receipt: {...receipt},
        isEdit: true
      },
      backdrop: 'static'
    });
  }

  async sendToReview() {
    if (
      !(await AlertService.confirm(
        'Crear rendición',
        '¿Está seguro de que quiere crear una rendición y enviarla a revisión?'
      ))
    ) {
      return;
    }

    let indexedReceipts = {};
    this.receipts.forEach((receipt) => {
      indexedReceipts[receipt.key] = true;
    });

    await this._accountability.add({
      title: this.fundToRender.title,
      status: AccountabilityStatusEnum.SUPERVISION,
      fundToRender: this._db.getReference(
        `fundToRender/${this.fundToRenderKey}`
      ),
      amount: this.finalBalance,
      receipts: indexedReceipts,
      trash: false
    } as Accountability);

    await this.updateReceiptsIsUsed();

    AlertService.toastSuccess('Se ha creado su rendición con éxito');
    this.router.navigateByUrl('/admin/accountabilities');
  }

  backToReceipts() {
    this.router.navigateByUrl('/admin/receipts');
  }

  async updateReceiptsIsUsed() {
    for (const receipt of this.receipts) {
      await this._receipt.updateReceipt(receipt.key, {isUsed: true} as Receipt);
    }
  }

  async discardReceipt({key: receiptKey}: Receipt) {
    if (this.receipts.length == 1) {
      return await AlertService.info('Debe haber al menos un comprobante');
    }
    if (
      !(await AlertService.confirm(
        'Descartar comprobante',
        '¿Está seguro de que desea descartar este comprobante?'
      ))
    ) {
      return;
    }

    this.receipts = this.receipts.filter(
      (receipt) => receipt.key != receiptKey
    );
    this.calculateAmounts();
  }

  async openAddReceiptModal() {
    const modalRef = this.modal.show(ReceiptComponent, {
      backdrop: 'static',
      initialState: {
        categories: this.breakdownAmountsCategories,
        isModal: true
      }
    });

    modalRef.onHide.subscribe(async (component) => {
      if (!modalRef.content.newReceipt) return;
      const newReceipt = modalRef.content.newReceipt;
      const receipt = await this._receipt.get(newReceipt.id).pipe(take(1)).toPromise();

      if (!receipt) return;
      this.receipts = [...this.receipts, receipt];
    })
  }
}
