<div class="w-100 d-flex align-items-center">
  <img
    [src]="(fileUrl && fileIcon) ? fileIcon : 'assets/icons/empty.png'"
    style="width: 32px; height: 32px">

  <div class="ml-1 w-100">
    <label>{{label}} * <i class="fa {{fileUrl ? 'fa-check text-success' : 'fa-times text-muted'}} ml-1"></i></label>

    <div class="form-group input-group">
      <div class="custom-file">
        <input type="file" class="custom-file-input"
               (change)="chooseFile($event)"
               [accept]="accept">
        <label class="custom-file-label">{{fileUrl ? 'Archivo cargado' : 'Cargar Archivo'}}</label>
      </div>

      <button
        [disabled]="!fileUrl"
        (click)="downloadFile()"
        class="btn btn-warning ml-1"
      >
        <i class="feather ft-download"></i>
      </button>

      <button
        type="button"
        class="btn btn-info ml-1"
        [disabled]="!fileUrl"
        (click)="openViewer()"
      >
        <i class="feather ft-eye"></i>
      </button>
    </div>
  </div>
</div>
