<app-datatable [rows$]="afp$"
               [config]="config"
               (rowSelected)="openUpdateAfpModal($event)">
  <app-datatable-column title="Afp">
    <ng-template let-row #column>
      {{row?.name}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false" [isVisiblePrint]="false">
    <ng-template let-row #column>
      <button class="btn btn-danger btn-sm" (click)="deleteAfp(row.key)">Eliminar</button>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="openAddAfpModal()"></app-add-float-button>
