import { Component, Input, OnInit } from '@angular/core';
import { ApplicantService } from '../../../../../shared/services/applicant.service';
import { AlertService } from '../../../../../shared/template-services/alert.service';

@Component({
  selector: 'app-interview',
  templateUrl: './interview.component.html',
  styleUrls: ['./interview.component.css']
})
export class InterviewComponent implements OnInit {
  @Input() interviewUrl: string;
  @Input() applicant: any;

  constructor(private _applicant: ApplicantService) {
  }

  ngOnInit(): void {
  }

  async editInterviewUrl() {
    let newUrl = await AlertService.input('Editar entrevista', 'Ingrese la nueva url de la entrevista', 'Guardar', this.interviewUrl);
    if (!newUrl) return;

    newUrl = newUrl.split('view')[0] + 'preview';

    const regex = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

    if (!regex.test(newUrl)) {
      await AlertService.error('Error', 'La url ingresada no es válida');
      return this.editInterviewUrl();
    }

    await this._applicant.update(this.applicant.key, { interviewUrl: newUrl } as any);
    this.interviewUrl = newUrl;
  }
}
