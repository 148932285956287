<div class="content-body" *ngIf="warehouse && hasPermissionsLoaded">
  <div class="row">
    <div class="col-12 mb-1">
      <div class="row">
        <div class="col-3 align-self-end">
          <h3>Código: {{ warehouse?.code }}</h3>
        </div>
        <div class="col-9 text-right d-flex justify-content-end">
          <div
            class="btn-group float-right ml-2"
            *ngIf="
              permission == permissionEnum.SUPERVISOR ||
              permission == permissionEnum.ADMIN
            ">
            <div ngbDropdown class="d-inline-block">
              <button class="btn btn-warning dropdown-toggle" ngbDropdownToggle>
                Configuración
              </button>
              <div ngbDropdownMenu class="open-left">
                <button
                  class="dropdown-item"
                  (click)="deleteWarehouse(warehouse)"
                  *ngIf="permission == permissionEnum.ADMIN">
                  Eliminar bodega
                </button>
                <button
                  class="dropdown-item"
                  *ngIf="permission == permissionEnum.ADMIN"
                  (click)="openUpdateWarehouseModal(warehouse)">
                  Editar
                </button>
                <button
                  class="dropdown-item"
                  *ngIf="permission != permissionEnum.ADMIN"
                  (click)="openUpdateWarehouseModal(warehouse)">
                  Ver bodega
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tabs d-flex flex-row">
    <div
      class="tab"
      [ngClass]="{active: tabIndex == 0}"
      *ngIf="tabsPermissions.SUMMARY | permission"
      (click)="selectTab(0, 'summary')">
      <span>Resumen</span>
    </div>
    <div
      class="tab"
      [ngClass]="{active: tabIndex == 1}"
      *ngIf="tabsPermissions.ARTICLES | permission"
      (click)="selectTab(1, 'articles')">
      <span>Artículos</span>
    </div>
    <div
      class="tab"
      [ngClass]="{active: tabIndex == 2}"
      *ngIf="tabsPermissions.ENTRY_TICKETS | permission"
      (click)="selectTab(2, 'tickets-article-entry')">
      <span>Tickets de entrada</span>
    </div>
    <div
      class="tab"
      [ngClass]="{active: tabIndex == 3}"
      *ngIf="tabsPermissions.TICKETS | permission"
      (click)="selectTab(3, 'tickets')">
      <span>Tickets de salida</span>
    </div>
    <div
      class="tab"
      [ngClass]="{active: tabIndex == 4}"
      *ngIf="tabsPermissions.RETIRED_ARTICLES | permission"
      (click)="selectTab(4, 'articles-returned')">
      <span>Artículos retirados</span>
    </div>
    <div
      class="tab"
      [ngClass]="{active: tabIndex == 5}"
      *ngIf="tabsPermissions.LOGS | permission"
      (click)="selectTab(5, 'logs')">
      <span>Registros</span>
    </div>
    <div
      class="tab"
      [ngClass]="{active: tabIndex == 6}"
      (click)="selectTab(6, 'requests')"
      *ngIf="tabsPermissions.REQUESTS | permission">
      <span>Solicitudes</span>
    </div>
    <div
      class="tab"
      [ngClass]="{active: tabIndex == 7}"
      *ngIf="tabsPermissions.MINIMUM_STOCK | permission"
      (click)="selectTab(7, 'minimum-stock')">
      <span>Stock mínimo</span>
    </div>
  </div>

  <div class="warehouse-content" *ngIf="!!warehouse">
    <router-outlet></router-outlet>
  </div>
</div>

<app-add-float-button
  (clickEmmiter)="openModalAddArticle()"
  *ngIf="validateAddArticlesButtonPermission()"></app-add-float-button>

<button
  class="btn btn-info btn-float-right add-articles-btn"
  *ngIf="validateAddArticlesButtonPermission()"
  app-tooltip="Agregar por excel"
  (click)="openAddArticlesModal()">
  <i class="fa fa-file"></i>
</button>

<button
  class="btn btn-success btn-float-right add-ticket-btn"
  [ngClass]="{'add-ticket-btn-secondary': tabIndex == tabEnum.ARTICLES}"
  *ngIf="!(tabIndex == tabEnum.TICKETS_ENTRY_ARTICLES)"
  app-tooltip="Agregar ticket de salida"
  (click)="openModalAddTicket()">
  <i class="fa fa-sticky-note"></i>
</button>

<button
  class="btn btn-danger btn-float-right add-entry-article-ticket-btn"
  [ngClass]="{
    'add-entry-ticket-btn-secondary': tabIndex == tabEnum.TICKETS_ENTRY_ARTICLES
  }"
  *ngIf="validateAddEntryArticleTicketButton()"
  app-tooltip="Agregar ticket de entrada"
  (click)="openAddEntryArticlesTicketModal()">
  <i class="fa fa-file"></i>
</button>
