import {Injectable} from '@angular/core';
import {Ticket} from '../interfaces/ticket';
import {Observable, ReplaySubject} from 'rxjs';
import {FirebaseDataService} from '../../../../shared/template-services/firebase-data.service';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {take} from 'rxjs/operators';
import {TicketEntry} from '../interfaces/ticket-entry';

@Injectable({
  providedIn: 'root',
})
export class TicketEntryService {
  public tickets: TicketEntry[];
  tickets$: ReplaySubject<TicketEntry[]> = new ReplaySubject<TicketEntry[]>();

  constructor(private db: FirebaseDataService, private afs: AngularFirestore) {}

  getAll(warehouseReference): Observable<TicketEntry[]> {
    return this.db.colWithIds$<TicketEntry>('ticketsEntry', (ref) =>
      ref
        .where('trash', '==', false)
        .where('warehouse', '==', warehouseReference)
        .orderBy('ticketID', 'desc')
    );
  }

  async add(ticket: Ticket, ticketID: string): Promise<DocumentReference> {
    return this.afs.collection('ticketsEntry').add({
      ...ticket,
      ticketID,
    });
  }

  get(ticketKey: string): Observable<TicketEntry> {
    return this.db.docWithId$(`ticketsEntry/${ticketKey}`);
  }

  async getId(): Promise<number> {
    const {ticketEntryID}: any = await this.db
      .doc('settings/setting')
      .valueChanges()
      .pipe(take(1))
      .toPromise();
    return ticketEntryID;
  }

  updateTicketID(ticketID: number) {
    this.afs.doc('settings/setting').update({ticketEntryID: ticketID});
  }

  async update(ticketKey: string, ticket: TicketEntry): Promise<void> {
    return await this.afs
      .doc<TicketEntry>(`ticketsEntry/${ticketKey}`)
      .update(ticket);
  }

  delete(ticketKey: string): Promise<void> {
    return this.afs
      .doc<TicketEntry>(`ticketsEntry/${ticketKey}`)
      .update({trash: true});
  }

  getAllByProject(projectReference): Observable<TicketEntry[]> {
    return this.db.colWithIds$<TicketEntry>('ticketsEntry', (ref) =>
      ref
        .where('trash', '==', false)
        .where('projects', '==', projectReference)
        .orderBy('createdDate', 'asc')
    );
  }
}
