<div class="col-md-12">
  <div class="d-flex align-items-end mb-1">
    <div
      class="d-flex flex-column mr-1"
      style="width: 180px">
      <label>Filtrar facturas</label>
      <ng-select
        [searchable]="false"
        [(ngModel)]="dateFilterTypeSelected"
        [clearable]="false"
        [items]="billsFilter"
        bindLabel="label"
        bindValue="value">
      </ng-select>
    </div>

    <div class="d-flex flex-column mr-1">
      <label>Rango de fechas</label>
      <ng-select
        [searchable]="false"
        [(ngModel)]="dateRangeTypeSelected"
        (ngModelChange)="handleDateRangeTypeSelected()"
        [clearable]="false">
        <ng-option [value]="dateRangeType.DAILY">Diario</ng-option>
        <ng-option [value]="dateRangeType.WEEKLY">Semanal</ng-option>
        <ng-option [value]="dateRangeType.MONTHLY">Mensual</ng-option>
      </ng-select>
    </div>

    <div
      class="animated fadeIn"
      *ngIf="dateRangeTypeSelected == dateRangeType.DAILY">
      <label>Seleccione el mes</label>
      <input
        type="month"
        class="form-control input-date"
        [(ngModel)]="dateSelected"
        (ngModelChange)="assignStartDateAndFinalDate()"/>
    </div>
    <div
      class="animated fadeIn"
      *ngIf="dateRangeTypeSelected == dateRangeType.WEEKLY">
      <label>Seleccione el mes</label>
      <input
        type="month"
        class="form-control input-date"
        [(ngModel)]="dateSelected"
        (ngModelChange)="assignStartDateAndFinalDate()"/>
    </div>

    <div *ngIf="dateRangeTypeSelected == dateRangeType.MONTHLY">
      <label>Seleccione el año</label>
      <ng-select
        [searchable]="false"
        [(ngModel)]="dateSelected"
        (ngModelChange)="assignStartDateAndFinalDate()"
        [clearable]="false">
        <ng-option
          [value]="year"
          *ngFor="let year of years">
          {{ year }}
        </ng-option>
      </ng-select>
    </div>

    <div class="ml-1">
      <button
        class="btn btn-danger ml-1"
        (click)="validateBillsByDateRange()">
        Buscar
      </button>
    </div>
  </div>

  <div>
    <ng-container class="col-12">
      <div class="mb-3">
        <h1 *ngIf="dateRangeTypeSelected == dateRangeType.WEEKLY || dateRangeTypeSelected == dateRangeType.DAILY">
          Mes: {{ monthSelected | titlecase }}</h1>
        <h1 *ngIf="dateRangeTypeSelected == dateRangeType.MONTHLY">Año {{dateSelected}}</h1>
        <h1 *ngIf="dateRangeTypeSelected == dateRangeType.DAILY">Facturas del mes por día: {{total | clearCurrency | swapCommasAndDots}}</h1>
        <h1 *ngIf="dateRangeTypeSelected == dateRangeType.WEEKLY">Facturas del mes por semanas: {{total | clearCurrency | swapCommasAndDots}}</h1>
        <h1 *ngIf="dateRangeTypeSelected == dateRangeType.MONTHLY">Facturas del año por meses: {{total | clearCurrency | swapCommasAndDots}}</h1>
        <canvas #billsCanvas></canvas>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="!!filterBills && filterBills.length != 0">
    <app-datatable
      [rows$]="bills$"
      [config]="configPurchase"
      (rowSelected)="gotoOpenEditBill($event)">

      <app-datatable-column title="Tipo">
        <ng-template
          let-row
          #column>
          <label *ngIf="!!row.deliveryDateChanged && row.deliveryDateChanged"><i class="feather ft-alert-triangle"></i></label>
          {{ row.documentType }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="N. factura">
        <ng-template
          let-row
          #column>
          {{ row.documentNumber }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Fecha de emisión">
        <ng-template
          let-row
          #column>
          {{ row.startDate | date : 'dd/MM/yyyy'}}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="RUT">
        <ng-template
          let-row
          #column>
          {{ getRut(row.provider) }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Proveedor">
        <ng-template
          let-row
          #column>
          {{ getProvider(row.provider) }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Total">
        <ng-template
          let-row
          #column>
          {{ row.total | clearCurrency | swapCommasAndDots}}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Centro de costo">
        <ng-template
          let-row
          #column>
          {{ row.costCenter == 'SIN CC' || !row.costCenter ? '-' : row.costCenter}}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="">
        <ng-template
          let-row
          #column>
          <span
            class="badge badge-success badge-pill"
            *ngIf="row.isUsed || row.project">Clasificada</span>
          <span
            class="badge badge-primary badge-pill"
            *ngIf="!row.isUsed && !row.project">No clasificada</span>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="">
        <ng-template
          let-row
          #column>
          <span
            class="badge badge-info badge-pill"
            *ngIf="row.isPaid">Pagada</span>
          <span
            class="badge badge-primary badge-pill"
            *ngIf="!row.isPaid">No Pagada</span>
        </ng-template>
      </app-datatable-column>
    </app-datatable>
  </ng-container>

  <ng-template #notFound>
    <app-not-found
      size="medium"
      [title]="config.notFoundText"></app-not-found>
  </ng-template>
</div>
