import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../../../../../shared/template-services/alert.service';
import {AuthService} from '../../../../../../../shared/template-services/auth.service';
import {UserService} from '../../../../../../../shared/services/user.service';
import {PurchaseOrderService} from '../../../../../../../shared/services/purchase-order.service';

@Component({
  selector: 'app-purchase-comments',
  templateUrl: './purchase-comments.component.html',
  styleUrls: ['./purchase-comments.component.css']
})
export class PurchaseCommentsComponent implements OnInit {
  @Input() purchaseOrder: any;
  comments: any = [];
  commentsSubscription: Subscription = new Subscription();
  commentForm: FormGroup;
  currentUser: any;
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private _auth: AuthService,
              private _user: UserService,
              private _purchase: PurchaseOrderService) {
    this.commentForm = formBuilder.group({
      comment: ['', Validators.required],
      user: ['', Validators.required],
      date: [new Date().getTime()],
      trash: [false]
    });
  }

  ngOnInit(): void {
    this.commentsSubscription = this._purchase.getComments(this.purchaseOrder.key).subscribe(data => {
      this.comments = data;
    });
  }

  async submitComment() {
    this.currentUser = await this._auth.loadFirebaseUser();
    this.commentForm.patchValue({user: this._user.getReference(this.currentUser.uid)});
    this.submitted = true;
    if (this.commentForm.valid) {
      await this._purchase.addComment(this.purchaseOrder.key, this.commentForm.value as Comment);
      AlertService.toastSuccess('Se agregó el comentario');
      this.commentForm.patchValue({comment: ''});
    }
  }
}
