<div class="p-1">
  <h4 class="font-weight-bold">Elige el fondo por rendir</h4>
  <div class="p-1">
    <mat-radio-group
      [(ngModel)]="fundToRenderSelected"
      aria-label="Select an option"
      class="d-flex flex-column justify-content-start">
      <mat-radio-button [value]="fund" *ngFor="let fund of fundsToRender">
        <h5 class="font-weight-bold mb-0">{{ fund.title }}</h5>
        <small class="text-muted">{{ fund.project.name }}</small>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="d-flex justify-content-end">
    <button
      class="btn btn-primary mr-1"
      [disabled]="!fundToRenderSelected"
      (click)="selectFundToRender()">
      Aceptar
    </button>
    <button class="btn btn-outline-danger" (click)="cancelOperation()">
      Cerrar
    </button>
  </div>
</div>
