<h3 class="d-flex justify-content-center">Total de gastos</h3>
<h3 class="text-success d-flex justify-content-center">
  {{ totalReceiptsAmount | currency }}
</h3>

<div class="p-1">
  <div class="d-flex justify-content-between">
    <h5>Monto solicitado</h5>
    <span class="text-info">{{ requiredAmount | currency }}</span>
  </div>
  <div class="d-flex justify-content-between">
    <h5>Saldo final</h5>
    <span>{{ finalBalance | currency }}</span>
  </div>
</div>

<ng-container *ngIf="receipts.length > 0; else receiptsNotFound">
  <h4>Gastos</h4>
  <div class="row gallery">
    <div class="card mb-0 col-md-4 mw-100" *ngFor="let receipt of receipts">
      <div class="row">
        <div class="col-4 pl-0">
          <img [src]="receipt.photoUrl" alt="Imagen del comprobante" (click)="goToEditReceiptsModal(receipt)" />
        </div>
        <div class="col-8 pl-0" (click)="goToEditReceiptsModal(receipt)">
          <button class="btn btn-dark btn-sm discard-button" (click)="discardReceipt(receipt)" ngbTooltip="Descartar" placement="right">
            <i class="la la-times"></i>
          </button>


          <div class="p-1 h-100 d-flex flex-column justify-content-between">
            <div>
              <h5 class="m-0">
                <strong>{{ receipt.description }}</strong>
              </h5>
              <small>{{ receipt.createdAt | date: 'dd/MM/yyyy' }}</small>
            </div>

            <div class="d-flex justify-content-between">
              <span class="badge badge-info">{{
                receipt?.category?.name
              }}</span>
              <span class="text-success">{{ receipt?.amount | currency }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-1">
    <button class="btn btn-primary mr-1" (click)="sendToReview()">
      Enviar a aprobación
    </button>

    <button class="btn btn-warning" (click)="backToReceipts()">Cancelar</button>
  </div>
</ng-container>

<app-add-float-button (clickEmmiter)="openAddReceiptModal()"></app-add-float-button>

<ng-template #receiptsNotFound>
  <app-not-found
    title="No se encontraron resultados"
    size="medium"></app-not-found>
</ng-template>
