<div class="p-2" *ngIf="generalData && user">
  <form [formGroup]="documentsForm">
    <div class="row">
      <div class="col-md-4 col-12">
        <ti-file
          label="Cédula de identidad Parte frontal"
          [fileUrl]="user.indentificationCardFront"
          name="indentificationCardFront"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>

      <div class="col-md-4 col-12">
        <ti-file
          label="Cédula de identidad Parte trasera"
          [fileUrl]="user.indentificationCardBack"
          name="indentificationCardBack"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>

      <div class="col-md-4 col-12">
        <ti-file
          label="Papel de Antecedentes"
          [fileUrl]="user.backgroundPaper"
          name="backgroundPaper"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>

      <div class="col-md-4 col-12">
        <ti-file
          label="Certificado de Nacimiento"
          [fileUrl]="user.birthCertificate"
          name="birthCertificate"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>

      <div class="col-md-4 col-12">
        <ti-file
          label="Certificado de Afiliación AFP"
          [fileUrl]="user.afpAffiliationCertificate"
          name="afpAffiliationCertificate"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>

      <div class="col-md-4 col-12" *ngIf="generalData?.currentValue?.healthcareSystem != ''">
        <ti-file
          label="Certificado de Afiliación Salud"
          [fileUrl]="user.healthAffiliationCertificate"
          name="healthAffiliationCertificate"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>

      <div class="col-md-4 col-12" *ngIf="generalData?.currentValue?.driverLicense == 'Con licencia'">
        <ti-file
          label="Licencia de conducir"
          [fileUrl]="user.driverLicensePhoto"
          name="driverLicensePhoto"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>

      <div class="col-md-4 col-12" *ngIf="generalData?.currentValue?.driverLicense == 'Con licencia'">
        <ti-file
          label="Hoja de vida de conductor"
          [fileUrl]="user.driverResume"
          name="driverResume"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>

      <div class="col-md-4 col-12">
        <ti-file
          label="Fotografía tipo carnet"
          [fileUrl]="user.imageUrl"
          name="imageUrl"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>

      <div class="col-md-4 col-12">
        <ti-file
          label="Último finiquito"
          [fileUrl]="user.finiquitoUrl"
          name="finiquitoUrl"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>

      <div class="col-md-4 col-12" *ngIf="user?.educationLevel == 'Enseñanza Universitaria Completa'">
        <ti-file
          label="Título"
          [fileUrl]="user.titlePhoto"
          name="titlePhoto"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>
    </div>
  </form>
</div>
