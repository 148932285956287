<app-datatable [rows$]="bills$"
               [config]="config"
               (rowSelected)="gotoOpenEditBill($event)">

  <app-datatable-column title="Tipo">
    <ng-template let-row #column>
      {{ row.documentType }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="N. factura">
    <ng-template let-row #column>
      {{ row.documentNumber }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha de emisión">
    <ng-template let-row #column>
      {{ row.startDate | date : 'dd/MM/yyyy'}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha de pago">
    <ng-template let-row #column>
      <span [ngClass]="{
      'text-danger': row.isExpirated && !row.isPaid,
      'text-success': !row.isExpirated,
      'text-info': row.isPaid
      }">{{ row.expectedPaymentDate | date : 'dd/MM/yyyy'}}</span>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="RUT">
    <ng-template let-row #column>
      {{ getRut(row.provider) }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Proveedor">
    <ng-template let-row #column>
      {{ getProvider(row.provider) }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Total">
    <ng-template let-row #column>
      {{ row.total | clearCurrency | swapCommasAndDots }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Centro de costo">
    <ng-template let-row #column>
      {{ row.costCenter == 'SIN CC' || !!row.costCenter
      ? row.costCenter
      : !!row.purchaseOrder
        ? 'OC ' + (((row.purchaseOrder | doc | async)?.purchaseID)| slice:0 : -3)
        : !!row.project
          ? (row.project | doc | async)?.name
          : '-'}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Estatus">
    <ng-template let-row #column>
      <div class="d-flex">
        <span class="badge badge-pill mr-2"
              [ngClass]="{
              'badge-success': row.isUsed || row.project,
              'badge-primary': !row.isUsed && !row.project}"
              ngbTooltip="{{(row.isUsed || row.project) && 'Clasificada' ||
                            (!row.isUsed && !row.project) && 'No clasificada' }}">
          <i [ngClass]="{
                'la-check': row.isUsed || row.project,
                'la-close': !row.isUsed && !row.project}"
             class="la"></i>
        </span>
        <span class="badge badge-pill"
              [ngClass]="{
              'badge-info': row.isPaid,
              'badge-primary': !row.isPaid}"
              ngbTooltip="{{row.isPaid ? 'Pagada' : 'No pagada'}}">
          <i class="la la-money"></i>
        </span>
      </div>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false" [isVisiblePrint]="false"
                        *ngIf="permission == permissionEnum.ADMIN">
    <ng-template let-row #column>
      <button class="btn btn-outline-danger"
              ngbTooltip="Eliminar"
              (click)="deleteBill(row.key)">
        <i class="la la-trash"></i>
      </button>
    </ng-template>
  </app-datatable-column>

</app-datatable>
