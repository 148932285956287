<button
  class="btn btn-primary mt-2"
  (click)="editInterviewUrl()"
>Modificar link de entrevista
</button>

<iframe
  *ngIf="interviewUrl"
  [src]="interviewUrl | safe"
  class="mt-2"
  width="100%"
  height="500"></iframe>
