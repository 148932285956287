import {Component, OnInit} from '@angular/core';
import {FundToRender} from '../../interfaces/fund-to-render';
import {AlertService} from '../../../../../shared/template-services/alert.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Accountability} from '../../interfaces/accountability';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DocumentReference} from '@angular/fire/firestore';
import {AccountabilityService} from '../../services/accountability.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {AccountabilityStatusEnum} from '../../enums/accountability-status.enum';
import {UserType} from '../../../../enums/user-type.enum';
import {UserService} from 'src/app/shared/services/user.service';
import {DomSanitizer} from '@angular/platform-browser';

declare const $;

@Component({
  selector: 'app-accountability-modal',
  templateUrl: './accountability-modal.component.html',
  styleUrls: ['./accountability-modal.component.css']
})
export class AccountabilityModalComponent implements OnInit {
  isEdit: boolean;
  accountability: Accountability;
  accountabilityKey: string;
  fundsToRender: FundToRender[] = [];
  fundsToRenderReferences: DocumentReference[] = [];
  submitted: boolean;
  accountabilityForm: FormGroup;
  permission: any;
  permissionEnum = UserType;
  accountabilityStatus = AccountabilityStatusEnum;
  url: any;

  constructor(
    public modal: BsModalRef,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private _accountability: AccountabilityService,
    private spinner: NgxSpinnerService,
    private _user: UserService
  ) {
    this.createAccountabilityForm();
  }

  ngOnInit() {
    this.permission = this._user.getPermission('FONDOS POR RENDIR');
    if (this.isEdit) {
      this.accountabilityKey = this.accountability.key;
      delete this.accountability['key'];
      this.accountabilityForm.patchValue(this.accountability);
      this.accountabilityForm.patchValue({
        fundToRender: this.fundsToRender.find(
          (fund) =>
            fund.key ==
            ((<any>this.accountability.fundToRender).key ??
              (<DocumentReference>this.accountability.fundToRender).id)
        )
      });
    }
    if (
      this.permission == this.permissionEnum.SUPERVISOR ||
      this.permission == this.permissionEnum.ADMIN ||
      (this.permission == this.permissionEnum.USER &&
        this.accountabilityForm.value.status !=
          this.accountabilityStatus.GENERATED)
    ) {
      this.accountabilityForm.disable();
    }

    if (!!this.accountability) {
      this.getUrlPdf(
        this.fundsToRender.find(
          (fund) =>
            fund.key ==
            ((<any>this.accountability.fundToRender).key ??
              (<DocumentReference>this.accountability.fundToRender).id)
        ).pdf
      );
    }
  }

  createAccountabilityForm() {
    this.accountabilityForm = this.formBuilder.group({
      title: ['', Validators.required],
      amount: [0, Validators.required],
      fundToRender: ['', Validators.required],
      status: [AccountabilityStatusEnum.GENERATED, Validators.required]
    });
  }

  get formControls() {
    return this.accountabilityForm.controls;
  }

  async submit() {
    this.submitted = true;
    if (!this.accountabilityForm.valid) {
      return;
    }

    if (
      !(await AlertService.confirm(
        `Guardar datos`,
        '¿Está seguro de que desea guardar los datos?',
        'Guardar'
      ))
    ) {
      return;
    }

    this.spinner.show();

    this.accountabilityForm.patchValue({
      fundToRender: this._accountability.getReference(
        `fundsToRender/${this.accountabilityForm.value.fundToRender.key}`
      )
    });

    if (this.isEdit) {
      this._accountability.update(
        this.accountabilityKey,
        this.accountabilityForm.value
      );
    } else {
      this._accountability.add(this.accountabilityForm.value);
    }

    this.spinner.hide();

    AlertService.toastSuccess(`Los datos se han guardado correctamente`);
    this.modal.hide();
  }

  getUrlPdf(url: any) {
    this.url = this.sanitizer.bypassSecurityTrustHtml(
      `<iframe width="100%" height="400" src="${url}"></iframe>`
    );
  }

  async sendToSupervisor() {
    if (
      !(await AlertService.confirm(
        `Enviar la rendición a revisión?`,
        '¿Está seguro de que desea enviar la rendición al supervisor?',
        'Enviar al supervisor'
      ))
    ) {
      return;
    }
    this._accountability.update(this.accountabilityKey, {
      status: this.accountabilityStatus.SUPERVISION
    } as Accountability);

    AlertService.toastSuccess(`La rendición se ha enviado al supervisor`);
    this.modal.hide();
  }

  async rejectAccountabilitySupervisor() {
    let [rejectReason] = await AlertService.withHtml(
      'Rendición',
      `<label>Razón del rechazo:</label>
      <textarea id="swal-input1" rows="3" class="swal2-input"></textarea>`,
      function () {
        return new Promise(function (resolve) {
          resolve([$('#swal-input1').val()]);
        });
      }
    );
    this.accountabilityForm.patchValue({rejectReason: rejectReason.trim()});

    if (!rejectReason.trim()) {
      return AlertService.toastError(
        'Debe ingresar una razón para rechazar la rendición'
      );
    }

    this._accountability.update(this.accountabilityKey, {
      status: this.accountabilityStatus.GENERATED,
      rejectReason: rejectReason
    } as Accountability);

    AlertService.toastSuccess(`La rendición se ha rechazado`);
    this.modal.hide();
  }

  async sendAccountabilityToReview() {
    if (
      !(await AlertService.confirm(
        `Enviar la rendición a revisión?`,
        '¿Está seguro de que desea enviar a aprobación la rendición?',
        'Enviar a aprobación'
      ))
    ) {
      return;
    }
    this._accountability.update(this.accountabilityKey, {
      status: this.accountabilityStatus.REVIEW
    } as Accountability);

    AlertService.toastSuccess(`La rendición se ha enviado a revisión`);
    this.modal.hide();
  }

  supervisorAcceptOrReject() {
    if (!!this.accountability) {
      return (
        this.permission == this.permissionEnum.SUPERVISOR &&
        this.accountability.status == AccountabilityStatusEnum.SUPERVISION
      );
    }
  }

  userGeneratedAccountability() {
    if (!!this.accountability) {
      return (
        this.permission == this.permissionEnum.USER &&
        this.accountability.status == AccountabilityStatusEnum.GENERATED
      );
    }
  }
}
