import {Component, OnInit} from '@angular/core';
import {ProjectStatusLabel} from '../../labels/projectStatusLabel';
import {Observable} from 'rxjs';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {Router} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ProjectStatus} from '../../enums/project-status.enum';
import {PurchaseStatus} from '../../enums/purchase-status.enum';
import {map} from 'rxjs/operators';
import {CostCenterType} from '../../enums/cost-center-type.enum';
import {Project} from '../../interfaces/project';
import {ProjectService} from '../../../shared/services/project.service';
import {AddProjectComponent} from '../../modals/add-project/add-project.component';

@Component({
  selector: 'app-cost-center',
  templateUrl: './cost-center.component.html',
  styleUrls: ['./cost-center.component.css']
})
export class CostCenterComponent implements OnInit {
  projectsFiltered$: Observable<Project[]>;
  projects$: Observable<Project[]>;
  statusLabel = ProjectStatusLabel;
  config: DataTableConfig = {hasSearch: true, notFoundText: 'No se encontraron centro de costos', title: 'Centro de costos'};
  filter: any;
  myRadio: any;
  purchaseStatus = ProjectStatus;

  constructor(private router: Router,
              private _project: ProjectService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.loadProjects();
  }

  loadProjects() {
    this.projects$ = this._project.getAll();
    this.projectsFiltered$ = this.projects$.pipe(map(project => project.filter(project => {
      return project.type == CostCenterType.CO;
    })));
  }

  openAddCostCenterModal() {
    this.modal.show(AddProjectComponent);
  }

  goToCostCenterDetails(costCenterKey: string) {
    this.router.navigateByUrl(`admin/costCenterDetails/${costCenterKey}`);
  }

  changeStatusFilter() {
    if (this.myRadio == 99) return this.projectsFiltered$ = this.projects$;

    this.filter = null;

    switch (this.myRadio) {
      case ProjectStatus.ACTIVE:
        this.filter = {name: 'ACTIVOS', status: PurchaseStatus.DRAFT};
        break;

      case ProjectStatus.INACTIVE:
        this.filter = {name: 'INACTIVOS', status: PurchaseStatus.GENERATED};
        break;
    }

    this.filterPurchases();
  }

  private filterPurchases() {
    this.config.notFoundText = `No se encontraron proyectos ${this.filter.name}`;

    this.projectsFiltered$ = this.projects$.pipe(map(project => project.filter(project => {
      return project.status == this.filter.status && project.type == CostCenterType.CO;
    })));
  }
}
