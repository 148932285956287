<div class="p-2">
  <form [formGroup]="curriculumForm">
    <div class="row mt-2">
      <div class="col-md-4"></div>
      <div class="col-md-4 col-12">
        <div class="form-group">
          <label>Curriculum *</label>
          <div class="custom-file">
            <input type="file" class="custom-file-input"
                   (change)="chooseFile($event, 'curriculum')"
                   accept="*"
                   [ngClass]="{ 'is-invalid': formCurriculumSubmitted && formControls.curriculum.errors }">
            <label class="custom-file-label" #labelImport>{{curriculumForm.value.curriculum ? curriculumForm.value.curriculum.name : 'Solo archivos pdf'}}</label>

            <small class="form-text text-muted danger" *ngIf="formCurriculumSubmitted && formControls.curriculum.errors">
              <div *ngIf=" formControls.curriculum.errors.required">
                El curriculum es obligatorio
              </div>
            </small>
          </div>

          <!-- <ng-container *ngIf="applicantForm?.currentValue?.educationLevel == 'Enseñanza Universitaria Completa'">
          <label class="mt-2">Título</label>
          <div class="custom-file">
            <input type="file" class="custom-file-input"
                   (change)="chooseFile($event, 'title')"
                   accept="pdf/*"
                   [ngClass]="{ 'is-invalid': formCurriculumSubmitted && !curriculumForm.value.title}">
            <label class="custom-file-label" #labelImport>{{curriculumForm.value.title ? curriculumForm.value.title.name : 'Imagen el titulo'}}</label>

            <small class="form-text text-muted danger" *ngIf="formCurriculumSubmitted && !curriculumForm.value.title">
              <div>
                El título es obligatorio
              </div>
            </small>
          </div>
          </ng-container> -->
        </div>
      </div>
    </div>
  </form>
</div>
