import {Injectable} from '@angular/core';
import {Article} from '../interfaces/article';
import {Warehouse} from '../interfaces/warehouse';
import {Observable, Subject} from 'rxjs';
import {Ticket} from '../interfaces/ticket';

@Injectable({
  providedIn: 'root'
})
export class WarehouseDetailService {
  articles: Article[] = [];
  articlesConsumable: Article[] = [];
  warehouse: Warehouse = null;
  tabIndex: number;
  warehouseKey: string;
  submitExcel: Subject<boolean> = new Subject();

  articleReferences: any[] = [];
  ticketAuthorizationReferences: any;
  requestsReference: any;
  ticket: Ticket;

  logs$: Observable<any[]>;

  constructor() {
  }
}
