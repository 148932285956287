import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {

  transform(array: any[], search: any, properties: string[] = []): any {
    return array.filter((item: any) => {
      if (properties.length == 0) {
        if (search == undefined) {
          return true;
        }

        const last = Object.keys(item)[Object.keys(item).length - 1];

        for (const itemProperty in item) {
          if (item[itemProperty] != null) {
            const text = item[itemProperty].toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            const searchText = search.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

            const words = searchText.split(' ');
            let isValid = true;

            for (const word of words) {
              if (text.indexOf(word) === -1) {
                isValid = false;
              }
            }

            if (isValid) {
              return true;
            } else {
              if (last === itemProperty) {
                return false;
              }
            }
          }
        }
      }

      let isValid = false;

      for (const property of properties) {
        if (property === null || property === 'null' || search == undefined) {
          return true;
        }

        if (property == undefined) {
          for (const itemProperty in item) {
            if (item[itemProperty] != null) {
              let index = item[itemProperty].toString().toLowerCase().indexOf(search.toString().toLowerCase());
              if (index >= 0) {
                isValid = true;
              }
            }
          }
        } else {
          let field = item;

          for (let iteration of property.split('.')) {
            field = field[iteration];
          }

          if (field != null) {
            const words = search.split(' '); // Divide la cadena de búsqueda en palabras
            const hasCoincidences = _.every(words, word => {
              return field.toLowerCase().includes(word.toLowerCase());
            });

            if (hasCoincidences) isValid = true;
          }
        }
      }
      return isValid;
    });
  }
}
