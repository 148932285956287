import {Component, OnInit} from '@angular/core';
import {DocumentReference} from '@angular/fire/firestore';
import _ from 'lodash';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Observable} from 'rxjs';
import {take} from 'rxjs/internal/operators/take';
import {Subscription} from 'rxjs/internal/Subscription';
import {map} from 'rxjs/operators';
import {LoginComponent} from 'src/app/auth/pages/login.component';
import {User} from 'src/app/shared/interfaces/user';
import {FundToRenderService} from 'src/app/shared/services/fund-to-render.service';
import {UserService} from 'src/app/shared/services/user.service';
import {AlertService} from 'src/app/shared/template-services/alert.service';
import {UserType} from '../../enums/user-type.enum';
import {Accountability} from '../../pages/travel-advance/interfaces/accountability';
import {AccountabilityReceipt} from '../../pages/travel-advance/interfaces/accountability-receipt';
import {FundToRender} from '../../pages/travel-advance/interfaces/fund-to-render';
import {Receipt} from '../../pages/travel-advance/interfaces/receipt';
import {AccountabilityReceiptService} from '../../pages/travel-advance/services/accountability-receipt.service';
import {ReceiptService} from '../../pages/travel-advance/services/receipt.service';

@Component({
  selector: 'app-summary-category-amounts',
  templateUrl: './summary-category-amounts.component.html',
  styleUrls: ['./summary-category-amounts.component.css']
})
export class SummaryCategoryAmountsComponent implements OnInit {
  accountabilityKey: string;
  accountability: Accountability;
  categories: any[];
  receipts: Receipt[] = [];
  receipts$: Observable<AccountabilityReceipt[]>;
  filteredReceipts: AccountabilityReceipt[] = [];
  fundsToRender: FundToRender[] = [];
  filteredFundToRender: FundToRender;
  receiptsPerCategory: any[] = [];
  expandedIndex = 0;
  usersSubscription: Subscription = new Subscription();
  users: any;
  fundToRenderUser: User;
  permission: any;
  permissionEnum = UserType;

  constructor(
    private _accountabilityReceipt: AccountabilityReceiptService,
    private _receipt: ReceiptService,
    private _fundsToRender: FundToRenderService,
    private _user: UserService,
    public modal: BsModalRef
  ) {}

  async ngOnInit() {
    this.permission = this._user.getPermission('FONDOS POR RENDIR');
    this.receipts = await this._receipt.getAll().pipe(take(1)).toPromise();

    this.receipts = this.receipts
      .filter(
        (receipt) =>
          (<DocumentReference>receipt.user).id == this._user.user.key
      )
      .map((receipt) => ({
        ...receipt,
        category: this.categories.find(
          (category) =>
            category.key == (<DocumentReference>receipt.category).id
        )
      }));

    this.fundsToRender = await this._fundsToRender
      .getAll()
      .pipe(take(1))
      .toPromise();

    await this.loadUsers();

    this.filteredFundToRender = this.fundsToRender.find(
      (fund) =>
        fund.key ==
        ((<any>this.accountability.fundToRender).key ??
          (<DocumentReference>this.accountability.fundToRender).id)
    );
    this.receipts = this.receipts
      .map((receipt) => ({
        ...receipt,
        initialAmount: this.filteredFundToRender.breakdownAmounts.find(
          (breakdownAmount) =>
            (<DocumentReference>receipt.category).id ==
              (<any>breakdownAmount.category).key ??
            (<DocumentReference>breakdownAmount.category).id
        )
      }))
      .map((receipt) => ({
        ...receipt,
        categories: receipt.category['name'],
        firstAmount: this.filteredFundToRender.breakdownAmounts.find(
          (breakdownAmount) =>
            (<DocumentReference>receipt.category).id ==
              (<any>breakdownAmount.category).key ??
            (<DocumentReference>breakdownAmount.category).id
        ).amount
      }));

    let categories = _.groupBy(this.receipts, 'categories');

    this.receiptsPerCategory = Object.keys(categories).map((name) => {
      const orderCategories = _.orderBy(categories[name], 'createdAt', 'desc');
      return {
        categoryName: name,
        categories: orderCategories,
        totalAmount: orderCategories.reduce(
          (acc, receipt) => acc + receipt.amount,
          0
        )
      };
    });
  }

  async loadUsers() {
    this.usersSubscription = this._user.getAllUndeleted().subscribe((users) => {
      this.users = users.filter((user) => {
        if (!user.permissions) return false;
        let section = user.permissions.find(
          (per) => per.section == 'FONDOS POR RENDIR'
        );
        return !!section;
      });
      this.fundToRenderUser = this.users.find(
        (user) => user.key == this.filteredFundToRender.user['id']
      );
    });
  }

  async paidUserDebt() {
    if (
      !(await AlertService.confirm(
        `¿Estás seguro de que deseas marcar como pagado este fondo por rendir?`,
        'El usuario tiene una deuda pendiente del fondo por rendir anterior, esta opción es unicamente cuando el usuario paga su deuda por otro medio',
        'Pagar'
      ))
    ) {
      return;
    }
    await this._fundsToRender.update(this.filteredFundToRender.key, {
      pendingAccountability: 0
    } as FundToRender);
    await this._user.update(this.fundToRenderUser.key, {
      pendingAccountability: 0
    } as User);
    this.modal.hide();
  }

  getPermission(sectionWanted: string) {
    const {permission} =
      this.fundToRenderUser.permissions.find(
        ({section}) => section == sectionWanted
      ) ?? {};
    return permission;
  }

  validateUserDebt() {
    if (!!this.fundToRenderUser.pendingAccountability)
      return (
        this.fundToRenderUser.pendingAccountability > 0 &&
        this.permission == this.permissionEnum.ADMIN
      );
  }
}
