<div class="p-2">
  <div class="d-flex justify-content-between ml-2 mr-2 align-items-center" *ngFor="let checklistTask of checklist">
    <ng-container *ngIf="!!checklistTask.taskText">
      <label class="control control--checkbox">
        <input type="checkbox"
               [disabled]="disableChecklist"
               [(ngModel)]="checklistTask.checked"
               (ngModelChange)="handleChecked(checklistTask)"/>
        {{checklistTask.taskText}}
      </label>

      <button class="btn btn-danger btn-sm" (click)="deleteTask(checklistTask.key)"><i class="fa fa-trash"></i></button>
    </ng-container>

    <div class="d-flex justify-content-between w-100" *ngIf="!checklistTask.taskText">
      <div>
        {{(checklistTask.task | doc | async)?.title }}
        <div class="badge badge-info ml-1">
          {{statusLabel[(checklistTask.task | doc | async)?.status]}}
        </div>
      </div>
      <button class="btn btn-danger btn-sm" (click)="deleteTask(checklistTask.key)"><i class="fa fa-trash"></i></button>
    </div>
  </div>

  <ng-container *ngIf="checklist.length == 0">
    <h5>No hay tareas agregadas</h5>
  </ng-container>
</div>
