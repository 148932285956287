<div class="row">
  <div class="col-6">
    <div class="table-responsive">
      <h2>Últimos postulantes contratados</h2>
      <table class="table table-bordered table-hover">
        <thead class="header">
        <tr>
          <th>Postulante</th>
          <th>Cargo</th>
          <th>Fecha</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let applicant of lastFiveApplicants">
          <td>
            {{applicant.name}} {{applicant.surnames}}
          </td>
          <td>
            {{applicant.position.name}}
          </td>
          <td>
            {{applicant.contractDate | date: 'dd/MM/yyyy'}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-6">
    <div class="table-responsive">
      <h2>Ciudades con más postulantes</h2>
      <table class="table table-bordered table-hover">
        <thead class="header">
        <tr>
          <th>Ciudad</th>
          <th>Postulantes</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let city of graphicCity.slice(0, 5)">
          <td>
            {{city.name}}
          </td>
          <td>
            {{city.value}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
