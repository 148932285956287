import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Warehouse} from '../../interfaces/warehouse';
import {WarehouseService} from '../../../../../shared/services/warehouse.service';
import {UserService} from '../../../../../shared/services/user.service';
import {map} from 'rxjs/operators';
import {UserType} from '../../../../enums/user-type.enum';
import {ValidationService} from '../../../../../shared/template-services/validation.service';
import {AlertService} from '../../../../../shared/template-services/alert.service';
import {PermissionService} from '../../services/permission.service';

@Component({
  selector: 'app-add-warehouse',
  templateUrl: './add-warehouse.component.html',
  styleUrls: ['./add-warehouse.component.css'],
})
export class AddWarehouseComponent implements OnInit {
  warehouseForm: FormGroup;
  submitted: boolean = false;
  imageToUpload: File = null;
  supervisors;
  users;
  grocersAndBuyers: any;
  profilePhoto: any;
  path: any;
  @ViewChild('labelImport', {static: true}) labelImport: ElementRef;
  permissionEnum = UserType;

  constructor(
    private formBuilder: FormBuilder,
    public modal: BsModalRef,
    private _warehouse: WarehouseService,
    private _user: UserService,
    private _permission: PermissionService
  ) {
    this.warehouseForm = formBuilder.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      description: ['', Validators.required],
      location: ['', Validators.required],
      totalStored: [0],
      totalRetired: [0],
      warehouseManager: ['', Validators.required],
      grocer: ['', Validators.required],
      buyer: [null, Validators.required],
      users: [[]],
      trash: [false],
      imageUrl: [''],
    });
  }

  async ngOnInit(): Promise<void> {
    this._user
      .getUsers()
      .pipe(
        map((users) =>
          users.map((user) => ({
            imageUrl: !!user.imageUrl ? user.imageUrl : null,
            name: user.name,
            permissions: user.permissions,
            reference: this._user.getReference(user.key),
          }))
        )
      )
      .subscribe((data) => {
        let supervisors = [];
        let grocers = [];
        let users = [];

        for (let i = 0; i < data.length; i++) {
          if (!!data[i].permissions) {
            let permissionsLength = data[i].permissions.length;
            for (let iPermission = 0; iPermission < permissionsLength; iPermission++) {
              if (this._permission.isSupervisor(data[i].permissions[iPermission], 'BODEGAS')) {
                supervisors.push(data[i]);
                users.push(data[i]);
              }

              if (this._permission.isGrocer(data[i].permissions[iPermission], 'BODEGAS') ||
                this._permission.isBuyer(data[i].permissions[iPermission], 'BODEGAS')) {
                grocers.push(data[i]);
                users.push(data[i]);
              }

              if (this._permission.isUser(data[i].permissions[iPermission], 'BODEGAS')) {
                users.push(data[i]);
              }
            }
          }
        }

        this.supervisors = supervisors;
        this.grocersAndBuyers = grocers;
        this.users = users;
      });
  }

  get formControls() {
    return this.warehouseForm.controls;
  }

  async submit() {
    this.submitted = true;
    if (!this.warehouseForm.valid) return;
    if (!(await AlertService.confirm('¿Está seguro de que desea guardar los datos?'))) return;

    this.warehouseForm.patchValue({
      buyer: this.warehouseForm.value.buyer.reference,
    });

    if (!!this.warehouseForm.get('imageUrl').value) {
      this.warehouseForm.patchValue({
        imageUrl: await this._warehouse.uploadPicture(this.profilePhoto, null),
      });
    }

    this._warehouse.add(this.warehouseForm.value as Warehouse);

    this.modal.hide();
    AlertService.toastSuccess('Datos guardados correctamente');
  }

  onFileChange(event) {
    if (!ValidationService.validateFileSize(event, 10000)) {
      return AlertService.toastError('El archivo debe ser menor a 10MB');
    }

    this.labelImport.nativeElement.innerText = Array.from(
      event.target.files as FileList
    )
      .map((f) => f.name)
      .join(', ');
    this.imageToUpload = event.target.files.item(0);
    this.choosePicture(event);
  }

  choosePicture(event) {
    this.profilePhoto = event.target.files[0];
    if (event.target.files && this.profilePhoto) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.path = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(this.profilePhoto);
      this.warehouseForm.patchValue({imageUrl: 'url'});
    }
  }
}
