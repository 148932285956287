import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {FirebaseDataService} from "../template-services/firebase-data.service";
import {AngularFirestore, DocumentReference} from "@angular/fire/firestore";
import {Category} from "../interfaces/category";

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  getAll(): Observable<Category[]> {
    return this.db.colWithIds$<Category>('categories', ref => ref
      .where('trash', '==', false));
  }

  add(category: Category): Promise<DocumentReference> {
    return this.afs.collection('categories').add(category);
  }

  delete(categoryKey: string): Promise<void> {
    return this.afs.doc<Category>(`categories/${categoryKey}`).update({trash: true});
  }

  addByID(category: Category, categoryKey: string){
    this.afs.doc(`categories/${categoryKey}`).set(category);
  }
}
