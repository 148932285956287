import {Component, EventEmitter, OnInit} from '@angular/core';
import {Article} from "../../pages/warehouses/interfaces/article";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {AlertService} from "../../../shared/template-services/alert.service";

@Component({
  selector: 'app-select-serials-number',
  templateUrl: './select-serials-number.component.html',
  styleUrls: ['./select-serials-number.component.css']
})
export class SelectSerialsNumberComponent implements OnInit {
  article: Article;
  quantity: number;
  serialNumbersAvailable: string[]
  serialNumbersSelected: string[] = [];
  serialNumbersEvent: EventEmitter<string[]> = new EventEmitter<string[]>()

  constructor(private modal: BsModalService) {
  }

  ngOnInit(): any {
    if (!this.article.serialNumberCollected) return this.serialNumbersAvailable = this.article.serialNumber;
    this.serialNumbersAvailable = this.article.serialNumber.filter(el => !this.article.serialNumberCollected.includes(el));

    let serialsInUseSN = []
    serialsInUseSN = this.article.serialNumberCollected.filter(serial => serial == 'S/N');

    if (serialsInUseSN.length > 0) {
      let serialsSN = [];
      serialsSN = this.article.serialNumber.filter(serial => serial == 'S/N');
      let SN_Frees = serialsSN.length - serialsInUseSN.length;

      for (let i = 0; i < SN_Frees; i++) {
        this.serialNumbersAvailable.push("S/N");
      }
    }
  }

  finish() {
    if (this.serialNumbersSelected.length != this.quantity) return AlertService.toastError('Necesitas agregar la misma cantidad de números de serie que la cantidad de artículos por retirar')
    this.serialNumbersEvent.emit(this.serialNumbersSelected);
    this.modal.hide(2)
  }

}
