import {Component, OnInit} from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ProviderArticle } from '../../interfaces/provider-article';

@Component({
  selector: 'app-provider-article',
  templateUrl: './provider-article.component.html',
  styleUrls: ['./provider-article.component.css']
})
export class ProviderArticleComponent implements OnInit {
  article: any;

  constructor() {
  }

  ngOnInit(): void {
  }

}
