import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../shared/services/user.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.css']
})
export class VehiclesComponent implements OnInit {
  vehiclesUrl;

  constructor(public _user: UserService) {
  }

  ngOnInit(): void {
    this.vehiclesUrl = `${environment.embeddedUrl}/#/admin/vehicles?uid=${this._user.user.key}`;
  }

}
