<app-datatable [rows$]="workerSalaries$"
               [config]="config"
               (rowSelected)="openEmployeePaymentEdit($event)">

  <app-datatable-column title="Sueldo trabajador">
    <ng-template let-row #column>
      {{row?.workerSalary | clearCurrency | swapCommasAndDots}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Gastos de ley">
    <ng-template let-row #column>
      {{row?.lawExpenses | clearCurrency | swapCommasAndDots}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Hora creación">
    <ng-template let-row #column>
      {{row?.createdAt | date : 'dd/MM/yyyy HH:mm'}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false">
    <ng-template let-row #column>
      <button class="btn btn-primary" (click)="delete(row.key)">Eliminar</button>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<!--<button class="btn btn-success btn-float-right add-articles-btn"-->
<!--        app-tooltip="Agregar por excel"-->
<!--        (click)="addToExcel()">-->
<!--  <i class="la la-file-excel-o xls-icon"></i>-->
<!--</button>-->

<app-add-float-button (clickEmmiter)="openAddWorkerSalaryModal()" ></app-add-float-button>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
             type="square-jelly-box"><p style="color: white">Subiendo archivo {{fileUploaded}}
  / {{fileQuantityUploaded}}</p></ngx-spinner>
