import { Component, OnInit } from '@angular/core';
import { BillDataService } from '../../services/bill-data.service';
import { AlertService } from '../../../../../shared/template-services/alert.service';
import { ValidationService } from '../../../../../shared/template-services/validation.service';
import { BillService } from '../../../../../shared/services/bill.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-bill-details',
  templateUrl: './bill-details.component.html',
  styleUrls: ['./bill-details.component.scss']
})
export class BillDetailsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  constructor(public _billData: BillDataService,
              private _bill: BillService) {
  }

  ngOnInit(): void {
  }

  async onBillPDFChange(event: any) {
    if (!ValidationService.validateFileSize(event, 10000)) return AlertService.toastError('El archivo debe ser menor a 10MB');

    const file = event.target.files[0];

    this.blockUI.start('Procesando PDF, favor de esperar...');

    const url = await this._bill.uploadBillPdf(file, this._billData.bill.key);

    this._billData.bill.billPdf = url;
    this._billData.billToEdit.billPdf = url;

    this.blockUI.stop();
  }
}
