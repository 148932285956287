<app-datatable [rows$]="quizzes$"
               [config]="config"
               (rowSelected)="gotToQuizDetails($event, $event.key)">

  <app-datatable-column title="Nombre">
    <ng-template let-row #column>
      {{row?.name}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false" [isVisiblePrint]="false">
    <ng-template let-row #column>
      <button class="btn btn-danger btn-sm" (click)="deleteQuiz(row.key)">Eliminar cuestionario</button>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="gotToAddQuiz()"></app-add-float-button>
