import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../../shared/services/user.service';
import { Observable, of, Subscription } from 'rxjs';
import { User } from '../../../shared/interfaces/user';
import { DataTableConfig } from '../../../shared/interfaces/data-table-config';
import { Router } from '@angular/router';
import { AuthService } from '../../../shared/template-services/auth.service';
import { AlertService } from '../../../shared/template-services/alert.service';
import { map, take } from 'rxjs/operators';
import { ApplicantService } from '../../../shared/services/applicant.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SendNotificationComponent } from '../../modals/send-notification/send-notification.component';
import { NgxSpinnerService } from 'ngx-spinner';
import moment from 'moment';
import { ObjectService } from '../../../shared/template-services/object.service';
import { Applicant } from '../../interfaces/applicant';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit, OnDestroy {
  users$: Observable<User[]>;
  usersApplicants$: Observable<User[]>;
  usersSubscription: Subscription = new Subscription();
  whitelist$: Observable<any[]>;
  usersFiltered$: Observable<User[]>;
  user: User;
  userKey: string;
  usersConfig: DataTableConfig = {
    title: 'Usuarios registrados',
    notFoundText: 'No se encontraron usuarios',
    hasSearch: true,
    exportCallback: this.decorateUsersExport.bind(this),
    excelFileName: 'Usuarios registrados'
  };
  whitelistConfig: DataTableConfig = {
    title: 'Usuarios admitidos',
    notFoundText: 'No se encontraron usuarios en whitelist',
    hasSearch: false
  };
  userFilterOption = {
    UNFILTERED: 0,
    APPLICANT: 1,
    INTERN: 2
  };
  userFilterSelected = this.userFilterOption.UNFILTERED;
  applicants: Applicant[] = [];

  constructor(private _user: UserService,
              private _auth: AuthService,
              private router: Router,
              private _applicant: ApplicantService,
              private modal: BsModalService,
              private SpinnerService: NgxSpinnerService) {
  }

  async ngOnInit() {
    this.asignFullname();
    this.userFilterSelected = this.userFilterOption.UNFILTERED;

    this.applicants = await this._applicant.getAll().pipe(take(1)).toPromise();

    this.usersSubscription = this._user.getUsers().pipe(map(users => users.filter(user => !user.trash))).subscribe(users => {
      this.users$ = of(users);
      this.filterUsers();
    });

    this.usersApplicants$ = this._user.getAllApplicants();

    this._auth.getWhiteList().subscribe(data => {
      this.whitelist$ = of(data.sort((a, b) => (a.isRegistered > b.isRegistered) ? 1 : -1));
    });
  }

  ngOnDestroy() {
    this.usersSubscription.unsubscribe();
  }

  asignFullname() {
    this._user.getUsers().subscribe(users => {
      users.forEach(user => {
        if (!user.fullname && user.name && user.surnames) {
          this._user.update(user.key, { fullname: `${user.name} ${user?.surnames || ''}` } as User);
        }
        if (!user.fullname && user.name && !user.surnames) {
          this._user.update(user.key, { fullname: `${user.name}` } as User);
        }
      });
    });
  }

  goToUserDetails(userKey: string): void {
    this.router.navigateByUrl(`admin/user-details/${userKey}`);
  }

  async openAddWhitelist() {
    const email = await AlertService.input('Agregar email a la lista de admitidos', '');

    if (!!email) {
      this._auth.addToWhitelist(email);
      AlertService.toastSuccess('Lista de usuarios admitidos actualizada!');
    }
  }

  async deleteFromWhiteList(whitelistKey: string) {
    if (await AlertService.confirm('¿Estás seguro que deseas eliminar este email?')) {
      this._auth.updateWhitelistItem(whitelistKey, { trash: true });
      AlertService.toastSuccess('Email eliminado!');
    }
  }

  async changeToInternalUser(user: any) {
    if (await AlertService.confirm('¿Estás seguro que quieres convertir a este usuario interno?', '')) {
      user.isApplicant = false;
      await this._user.update(user.key, user);
      AlertService.toastSuccess('El usuario ahora es interno', '');
    }
  }

  async askToResetPassword(user: any) {
    this._user.update(user.key, { lastAccess: new Date().getTime() } as User);

    if (await AlertService.confirm('¿Estás seguro que deseas restablecer la contraseña de este usuario?')) {
      this.SpinnerService.show();
      await this._user.resetPassword(user.key, user.email);
      this.SpinnerService.hide();
      AlertService.success('Contraseña restablecida', `Nueva contraseña: ${user.email}`);
    }
  }

  async goToApplicantDetail(row) {
    const applicants = await this._applicant.getAll().pipe(take(1)).toPromise();

    for (let i = 0; i < applicants.length; i++) {
      delete applicants[i].position.reference;
    }
    this._applicant.applicants = applicants;

    let applicant = applicants.find(applicant => applicant.email == row.email);

    if (!applicant) {
      return AlertService.toastError('No se encontró el perfil del postulante');
    }

    await this.router.navigateByUrl(`admin/applicant-details/${applicant.key}`);
  }

  async deleteUser(user: any) {
    if (await AlertService.confirm('¿Estás seguro que deseas eliminar este usuario?')) {
      this._user.delete(user.key);
      AlertService.toastSuccess('Usuario eliminado!');
    }
  }

  openSendNotificationModal() {
    this.modal.show(SendNotificationComponent, {
      initialState: {
        users$: this.users$
      }
    });
  }

  normalizeRut(rut: string) {
    return (rut || '').replaceAll('.', '').replaceAll('-', '');
  };

  filterUsers() {
    this.usersFiltered$ = this.users$.pipe(
      map(users => users
        .map(user=>({
          ...user,
          applicant: this.applicants.find(applicant => this.normalizeRut(applicant.rut) === this.normalizeRut(user.rut))
        }))
        .filter(user => {
          return !user.isApplicant || (user.applicant && user.applicant.finalAccessDate >= Date.now());
        }))
    );
  }

  decorateUsersExport(rows: any[]) {
    return ObjectService.replaceUndefined(rows.map(row => ({
      fullname: row.fullname,
      email: row.email,
      isApplicant: row?.isApplicant ? 'Postulante' : 'Interno',
      lastAccess: row.lastAccess ? moment(row.lastAccess).format('DD/MM/YY HH:mm') : 'Aún no registrada'
    })));
  }
}
