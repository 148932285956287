export const TicketStatusLabel = {
  '-1': 'Cancelado',
  0: 'Borrador',
  1: 'Generado',
  2: 'Autorizado',
  3: 'Retirado',
  4: 'Entregado incompleto',
  5: 'Devuelto',
  6: 'Agregado'
}
