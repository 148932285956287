import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import moment from 'moment';
import {BillService} from '../../../shared/services/bill.service';
import {Bill} from '../../interfaces/bill';
import {take} from 'rxjs/operators';
import * as Chart from 'chart.js';
import {ProjectService} from '../../../shared/services/project.service';
import randomFlatColors from 'random-flat-colors';
import {CurrencyPipe} from '@angular/common';
import {SwapCommasAndDotsPipe} from '../../pipes/swap-commas-and-dots.pipe';

@Component({
  selector: 'app-project-invoice-reports',
  templateUrl: './project-invoice-reports.component.html',
  styleUrls: ['./project-invoice-reports.component.css']
})
export class ProjectInvoiceReportsComponent implements OnInit {
  @ViewChild('billsBarCanvas') private billsBarCanvas: ElementRef;
  @ViewChild('billsPieCanvas') private billsPieCanvas: ElementRef;
  billsBarChart: any;
  billsPieChart: any;
  dateSelected: any;
  startDate: any = null;
  finalDate: any = null;
  allBills: Bill[];
  billsFilter: any = [];
  chartBarLabel: any = [];
  chartBarData: any = [];
  chartPieLabel: any = [];
  chartPieData: any = [];
  pieChartBackgroundColor: any = [];
  totalDocuments: number;
  totalBillsValue: number;
  valueAddedTax: number = 0;

  constructor(private _bill: BillService,
              private _project: ProjectService,
              private currency: CurrencyPipe,
              private swapCommasAndDots: SwapCommasAndDotsPipe) {
  }

  async ngOnInit() {
    this.dateSelected = moment().format('yyyy-MM');
    this.assignStartDateAndFinalDate();
    this.showBillsByDateRange();
  }

  async getBills() {
    this.allBills = await this._bill.getAll()
      .pipe(take(1)).toPromise();
    this.allBills = this.allBills.map(bill => {
      let total = bill.total = +String(bill.total).replace(/[.]/g, '');
      return {...bill, total: total};
    });
  }

  async showBillsByDateRange() {
    this.chartBarData = [];
    this.chartBarLabel = [];
    this.chartPieData = [];
    this.chartPieLabel = [];
    this.pieChartBackgroundColor = [];
    await this.getBills();
    await this.filterBills();
    this.randomPieChartBackground();
    this.barChartMethod();
  }

  assignStartDateAndFinalDate() {
    this.startDate = moment(this.dateSelected)
      .startOf('month')
      .toDate();
    this.finalDate = moment(this.dateSelected)
      .endOf('month')
      .toDate();
  }

  async filterBills() {
    this.valueAddedTax = 0;
    this.billsFilter = this.allBills.filter((bill) => {
      return moment(bill.startDate).isBetween(
        moment(this.startDate).startOf('month'),
        moment(this.finalDate).endOf('month'))
    }).map((bill) => {
      this.valueAddedTax += +(bill.iva.toString()
        .replaceAll('.', '')
        .replaceAll(',', '')
        .replaceAll('$', '')
      );

      return bill.project?.id ? {...bill, project: bill.project.id} : {...bill, project: bill.project?.key};
    });

    this.totalDocuments = this.billsFilter.length;

    this.billsFilter = this.billsFilter.reduce((bills, bill) => {
      let currentBill = bills.find(billByProject => billByProject.project == bill.project);
      if (currentBill) {
        currentBill.total += bill.total;
      } else {
        bills.push(bill);
      }
      return bills;
    }, []);

    let bills: any = (await Promise.all(this.billsFilter.map(async (bill) => {
      let project = await this._project
        .get(bill.project)
        .pipe(take(1))
        .toPromise();

      if (+project?.type == 1) {
        this.chartPieData.push(bill.total);
        this.chartPieLabel.push(project.name);
      }

      return bill.project ? {
        name: project?.name,
        total: bill.total
      } : {name: 'sin proyecto', total: bill.total};
    })));

    this.chartBarLabel = bills.map((bill: any) => bill.name);
    this.chartBarData = bills.map((bill: any) => bill.total);

    this.totalBillsValue = this.chartBarData.reduce((acc, el) => acc + el, 0);
  }

  randomPieChartBackground() {
    this.chartPieLabel.forEach(() => {
      this.pieChartBackgroundColor.push(randomFlatColors());
    });
  }

  barChartMethod() {
    if (this.billsBarChart) {
      this.billsBarChart.destroy();
    }
    if (this.billsPieChart) {
      this.billsPieChart.destroy();
    }

    this.billsBarChart = new Chart(this.billsBarCanvas.nativeElement, {
      type: 'bar',
      data: {
        labels: this.chartBarLabel,
        datasets: [
          {
            label: 'Total de las facturas por mes',
            data: this.chartBarData,
            backgroundColor: 'rgba(69,105,224,0.5)'
          }
        ],
      }, options: {
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              return this.swapCommasAndDots.transform(
                this.currency.transform(tooltipItem.yLabel, 'USD')
              );
            }
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: (label: number, index, labels) => {
                return this.swapCommasAndDots.transform(
                  this.currency.transform(label, 'USD')
                );
              },
            }
          }],
          xAxes: [{
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90
            }
          }]
        }
      }
    });

    this.billsPieChart = new Chart(this.billsPieCanvas.nativeElement, {
      type: 'pie',
      data: {
        labels: this.chartPieLabel,
        datasets: [
          {
            label: 'Total de las facturas por mes',
            data: this.chartPieData,
            backgroundColor: this.pieChartBackgroundColor
          }
        ],
      },
      options: {
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              return `${data.labels[tooltipItem.index]}: ${
                this.swapCommasAndDots.transform(
                  this.currency.transform(<number>data.datasets[0].data[tooltipItem.index], 'USD')
                )
              }`;
            }
          }
        },
      }
    });
  }
}
