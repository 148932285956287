import {Injectable} from '@angular/core';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {AuthService} from '../../shared/template-services/auth.service';
import {UserService} from '../../shared/services/user.service';
import {Observable} from 'rxjs';
import {Record} from '../interfaces/comment';
import {map} from 'rxjs/operators';
import moment from 'moment';
import { ReceiptLog } from '../pages/travel-advance/interfaces/receipt-log';

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  constructor(
    private db: FirebaseDataService,
    private afs: AngularFirestore,
    private _auth: AuthService,
    private _user: UserService
  ) {}

  getAll(warehouseKey: string): any {
    return this.db.colWithIds$(`warehouses/${warehouseKey}/logs`, (ref) =>
      ref
        .where('createdAt', '>=', moment().startOf('w').toDate().getTime())
        .orderBy('createdAt', 'desc')
    );
  }

  add(warehouseKey: string, log): void {
    this.afs.collection(`warehouses/${warehouseKey}/logs`).add({
      ...log,
      createdAt: new Date().getTime(),
      user: this._user.getReference(this._auth.user.uid)
    });
  }

  addPurchaseOrder(purchaseOrderKey: string, log): void {
    this.afs.collection(`purchaseOrders/${purchaseOrderKey}/logs`).add({
      ...log,
      createdAt: new Date().getTime(),
      user: this._user.getReference(this._auth.user.uid)
    });
  }

  updateBudget(projectKey: string, log): void {
    this.afs.collection(`projects/${projectKey}/logs`).add({
      ...log,
      createdAt: new Date().getTime(),
      user: this._user.getReference(this._auth.user.uid)
    });
  }

  getAllPurchaseOrder(purchaseOrderKey: string): any {
    return this.db.colWithIds$(
      `purchaseOrders/${purchaseOrderKey}/logs`,
      (ref) => ref.orderBy('createdAt', 'desc')
    );
  }

  getAllBudgets(projectKey: string): any {
    return this.db.colWithIds$(`projects/${projectKey}/logs`, (ref) =>
      ref.orderBy('createdAt', 'desc')
    );
  }

  getAllRecords(): Observable<Record[]> {
    return this.afs
      .collectionGroup<Record>(`records`, (ref) =>
        ref.where('trash', '==', false).orderBy('date', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a:any) => {
            return {
              ...a.payload.doc.data(),
              key: a.payload.doc.id,
              articleKey: a.payload.doc.ref.parent.parent.id
            };
          })
        )
      );
  }

  async addRecord(
    warehouseKey: string,
    articleKey: string,
    record: any
  ): Promise<any> {
    return await this.afs
      .collection<Record>(
        `warehouses/${warehouseKey}/articles/${articleKey}/records`
      )
      .add(record);
  }

  async deleteRecord(
    warehouseKey: string,
    articleKey: string,
    recordKey: string
  ): Promise<void> {
    return await this.afs
      .doc<Record>(
        `warehouses/${warehouseKey}/articles/${articleKey}/records/${recordKey}`
      )
      .update({trash: true});
  }

  updateRecord(
    warehouseKey: string,
    articleKey: any,
    commentKey: any,
    record: any
  ) {
    this.afs
      .doc(
        `warehouses/${warehouseKey}/articles/${articleKey}/records/${commentKey}`
      )
      .update(record);
  }

  getRecord(warehouseKey: string, articleKey: string, recordKey: string) {
    return this.afs
      .doc(
        `warehouses/${warehouseKey}/articles/${articleKey}/records/${recordKey}`
      )
      .valueChanges();
  }

  addApplicant(applicantKey: string, log): void {
    this.afs.collection(`applicants/${applicantKey}/logs`).add({
      ...log,
      createdAt: new Date().getTime(),
      user: this._user.getReference(this._auth.user.uid)
    });
  }

  addUser(userKey: string, log): void {
    if(!this._auth.user) return;

    this.afs.collection(`users/${userKey}/logs`).add({
      description: log,
      createdAt: new Date().getTime(),
      user: this._user.getReference(this._auth.user.uid)
    });
  }

  getAllApplicant(applicantKey: string): any {
    return this.db.colWithIds$(`applicants/${applicantKey}/logs`, (ref) =>
      ref.orderBy('createdAt', 'desc')
    );
  }

  getLogsUser(userKey: string) {
    return this.db.colWithIds$(`users/${userKey}/logs`, (ref) =>
      ref.orderBy('createdAt', 'desc')
    );
  }

  getLogsFundsToRender() {
    return this.db.colWithIds$(`fundToRenderLogs`, (ref) =>
      ref.orderBy('createdAt', 'desc')
    );
  }

  addFundsToRender(log): void {
    this.afs.collection(`fundToRenderLogs`).add({
      description: log,
      createdAt: new Date().getTime(),
      user: this._user.getReference(this._auth.user.uid)
    });
  }

  getReceiptLogs(): Observable<ReceiptLog[]> {
    return this.db.colWithIds$(`receiptLogs`, (ref) =>
      ref.where('trash', '==', false)
    );
  }

  addReceiptLog(description: string, user: DocumentReference, receipt: DocumentReference) {
    return this.afs.collection(`receiptLogs`).add({
      description,
      createdAt: new Date().getTime(),
      user,
      receipt,
      trash: false
    });
  }
}
