<div>
  <div class="custom-modal-header modal-header">
    <h1>Modificar rangos salariales</h1>
    <i (click)="closeModal()" class="la la-close"></i>
  </div>
  <form [formGroup]="rangesForm" (ngSubmit)="handleSubmit()">
    <div class="form-container" *ngIf="!loading" formArrayName="ranges">
      <div
        *ngFor="let range of ranges.controls; let i = index"
        [formGroupName]="i"
        class="input-row"
      >
        <div class="form-group col-md-4">
          <label>Desde:</label>
          <input
            type="number"
            placeHolder="0"
            class="form-control"
            formControlName="min"
          />
        </div>
        <div class="form-group  col-md-4">
          <label>Hasta:</label>
          <input
            type="number"
            placeHolder="0"
            class="form-control"
            formControlName="max"
          />
        </div>
        <div class="col-md-1 d-flex align-items-center justify-content-center">
          <button class="btn btn-primary rounded-circle" (click)="removeRange(i)">
            <i class="fa fa-minus"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-12 d-flex justify-content-center mb-2">
      <button class="btn btn-info justify-self-end rounded-circle" (click)="addRange()" type="button">
        <i class="fa fa-plus"></i>
      </button>
    </div>
    <div class="d-flex align-content-center justify-content-center my-2">
      <button class="btn btn-primary" type="submit">
        Guardar
      </button>
    </div>
  </form>
</div>

<div class="page-loader" *ngIf="loading">
  <div class="spinner-border text-light" style="width: 3rem; height: 3rem;">
  </div>
</div>
