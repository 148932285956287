import {Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../../shared/services/user.service';
import {Observable} from 'rxjs';
import {User} from '../../../shared/interfaces/user';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {ShowImageComponent} from '../../modals/show-image/show-image.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ProjectService} from '../../../shared/services/project.service';
import {DocumentReference} from '@angular/fire/firestore';
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-persons',
  templateUrl: './persons.component.html',
  styleUrls: ['./persons.component.scss']
})
export class PersonsComponent implements OnInit {
  @Input() projectKey: string;
  persons$: Observable<User[]>;
  config: DataTableConfig = {
    title: "",
    notFoundText: "No se encontraron usuarios",
    hasSearch: true
  };
  projectReference: DocumentReference;

  constructor(private _user: UserService,
              private _project: ProjectService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.projectReference = this._project.getReference(this.projectKey)
    this.persons$ = this._user.getAllByProject(this.projectReference);
  }

  openModalImage(imageUrl: any) {
    this.modal.show(ShowImageComponent, {
      initialState: {
        image: imageUrl
      },
    });
  }

  async dropPerson(person: any) {
    if (await AlertService.confirm('¿Estás seguro que quieres sacar a este usuario del proyecto?', '')) {
      let indexProject = 0;
      for (let project of person.projects) {
        if (project.id == this.projectReference.id) person.projects.splice(indexProject, 1);
        indexProject++;
      }
      await this._user.update(person.key, person);
      AlertService.toastSuccess('El usuario fue removido del proyecto', '');
      this.modal.hide();
    }
  }
}
