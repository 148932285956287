<div class="modal-header">
  <h4 class="modal-title pull-left">Tablero de ventas</h4>
  <button type="button" class="pull-right btn" (click)="modal.hide()">
    <i class="feather ft-x"></i>
  </button>
</div>

<div class="p-1">
  <form [formGroup]="salesBoardForm">
    <div class="mt-1">
      <label>Nombre</label>
      <input type="text" class="form-control" formControlName="name" [ngClass]="{'is-invalid': !!submitted && formControls.name.errors}"/>
      <small class="invalid-feedback">
        Campo obligatorio
      </small>
    </div>

    <div class="form-group mt-1">
      <div class="custom-file">
        <ng-select formControlName="icon"
                   [items]="icons"
                   placeholder="Seleccione un icono *">
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <div class="icon-select">
              <i class="{{item}} font-medium-5"></i>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <i class="{{item}} font-medium-5"></i>
          </ng-template>
        </ng-select>
        <small
          class="text-danger"
          *ngIf="submitted && formControls.icon.errors">
          El campo es obligatorio
        </small>
      </div>
    </div>

      <div class="form-group">
        <div class="custom-file">
          <ng-select formControlName="bgColor"
                     [items]="bgColors"
                     placeholder="Seleccione color de bodega *">
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <div [style.background-color]="item" class="bg-color-select"></div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div [style.background-color]="item" class="bg-color-select"></div>
            </ng-template>
          </ng-select>
          <small
            class="text-danger"
            *ngIf="submitted && formControls.bgColor.errors">
            El campo es obligatorio
          </small>
        </div>
      </div>

    <div class="mt-1 d-flex">

      <mat-form-field class="w-50 mr-1">
        <mat-label>Asignar usuarios</mat-label>
        <mat-select
          formControlName="users"
          multiple [compareWith]="compareUsers">
          <mat-select-trigger>
            <ul class="list-unstyled users-list m-0">
              <li class="avatar avatar-sm pull-up" *ngFor="let user of salesBoardForm.value.users">
                <img class="media-object rounded-circle avatar-img"
                     container="body"
                     [ngbTooltip]="user.name"
                     (error)="$event.target.src = '/assets/images/default.png'"
                     [src]="!!user.imageUrl ? user.imageUrl : '/assets/images/default.png'" alt="Foto de usuario">
              </li>
            </ul>
          </mat-select-trigger>
          <mat-option *ngFor="let user of users; let i = index"
                      [disabled]="validateUserPermission(userTypeEnum.USER)"
                      [value]="user">
            <img src="{{!!user.imageUrl ? user.imageUrl : '/assets/images/default.png'}}"
                 class="avatar-img rounded-circle"
                 (error)="$event.target.src = '/assets/images/default.png'"
                 alt="Foto de usuario"><span class="ml-1">{{user.name}}</span>
          </mat-option>
        </mat-select>
        <mat-error>
          Campo obligatorio
        </mat-error>
      </mat-form-field>

      <mat-form-field class="w-50">
        <mat-label>Asignar supervisores</mat-label>
        <mat-select
          formControlName="supervisors"
          multiple [compareWith]="compareUsers">
          <mat-select-trigger>
            <ul class="list-unstyled users-list m-0">
              <li class="avatar avatar-sm pull-up" *ngFor="let supervisor of salesBoardForm.value.supervisors">
                <img class="media-object rounded-circle avatar-img"
                     container="body"
                     [ngbTooltip]="supervisor.name"
                     (error)="$event.target.src = '/assets/images/default.png'"
                     [src]="!!supervisor.imageUrl ? supervisor.imageUrl : '/assets/images/default.png'" alt="Foto de usuario">
              </li>
            </ul>
          </mat-select-trigger>
          <mat-option *ngFor="let supervisor of supervisors; let i = index"
                      [disabled]="validateUserPermission(userTypeEnum.USER)"
                      [value]="supervisor">
            <img src="{{!!supervisor.imageUrl ? supervisor.imageUrl : '/assets/images/default.png'}}"
                 class="avatar-img rounded-circle"
                 (error)="$event.target.src = '/assets/images/default.png'"
                 alt="Foto de usuario"><span class="ml-1">{{supervisor.name}}</span>
          </mat-option>
        </mat-select>
        <mat-error>
          Campo obligatorio
        </mat-error>
      </mat-form-field>

    </div>
    <div class="mt-2 mb-2">
      <div class="d-flex justify-content-end">
        <button class="btn btn-outline-danger mr-1" (click)="modal.hide()">Cerrar</button>
        <button class="btn btn-success"
                *ngIf="validateUserPermission(userTypeEnum.ADMIN) || validateUserPermission(userTypeEnum.SUPERVISOR)"
                (click)="submit()">Guardar</button>
      </div>
    </div>
  </form>
</div>
