import { Component, Input, OnInit } from '@angular/core';
import { Bill } from '../../interfaces/bill';
import { PaymentCategory } from '../../interfaces/payment-category';
import { ProofOfPayment } from '../../interfaces/proof-of-payment';
import { UserType } from '../../enums/user-type.enum';
import { BillDataService } from './services/bill-data.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import _ from 'lodash';
import { BillService } from '../../../shared/services/bill.service';
import { PurchaseOrder } from '../../pages/purchase-orders/interfaces/purchase-order';
import { PurchaseOrderService } from '../../../shared/services/purchase-order.service';
import { AlertService } from '../../../shared/template-services/alert.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import moment from 'moment';

@Component({
  selector: 'app-bill-modal-v2',
  templateUrl: './bill-modal-v2.component.html',
  styleUrls: ['./bill-modal-v2.component.scss'],
  providers: [BillDataService]
})
export class BillModalV2Component implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @Input() bill: Bill;
  @Input() indexedBills: any;
  @Input() paymentCategories: PaymentCategory[] = [];
  @Input() indexedProviders: any;
  @Input() proofOfPayments: ProofOfPayment[] = [];
  @Input() categoriesIndexed: any;
  @Input() isUpdate: boolean = false;
  @Input() permission: UserType;
  @Input() purchaseOrders: any;
  @Input() indexedPurchaseOrders: any;
  @Input() indexedProjects: any;
  @Input() projects: any;

  constructor(public _billData: BillDataService,
              public modal: BsModalRef,
              private _bill: BillService,
              private _purchase: PurchaseOrderService) {

  }

  async ngOnInit(): Promise<void> {
    this._billData.purchaseOrders = this.purchaseOrders;
    this._billData.projects = this.projects;
    this._billData.categoriesIndexed = this.categoriesIndexed;
    this._billData.proofOfPayments = this.proofOfPayments;

    this._billData.bill = this.formatBill();
  }

  get detailsSize() {
    return this._billData.bill.billPdf || this._billData.bill.proofOfPayment ? 'col-7' : 'col-12';
  }

  async submit() {
    this.blockUI.start('Guardando...');

    if (this._billData.billToEdit.project) {
      this._billData.billToEdit.project = this._bill.db.getReference(`projects/${this._billData.billToEdit.project.key}`);
    }

    if (this._billData.billToEdit.projectCategory) {
      this._billData.billToEdit.projectCategory = this._bill.db.getReference(`projectCategories/${this._billData.billToEdit.projectCategory.key}`);
    }

    if (this._billData.billToEdit.purchaseOrder) {
      const purchaseOrder = this._billData.billToEdit.purchaseOrder;
      const purchaseOrderBillsTotal = this.calculatePurchaseOrderTotalBillsAmount(purchaseOrder);

      await this._purchase.update(purchaseOrder.key, {
        invoice: [...purchaseOrder.invoice, this._bill.db.getReference(`bills/${this.bill.key}`)],
        invoiced: (this._billData.billToEdit.total + purchaseOrderBillsTotal) >= purchaseOrder.total - 10
      } as PurchaseOrder);

      this._billData.billToEdit.purchaseOrder = this._bill.db.getReference(`purchaseOrders/${purchaseOrder.key}`);
    }

    this._billData.billToEdit.expectedPaymentDate = moment(this._billData.billToEdit.expectedPaymentDate).toDate().getTime();

    if (this._billData.billToEdit.realPaymentDate) {
      this._billData.billToEdit.realPaymentDate = moment(this._billData.billToEdit.realPaymentDate).toDate().getTime();
    }

    if (this._billData.billToEdit.proofOfPayment) {
      await this.setProofPayment();
    }

    this._billData.billToEdit.isUsed = (!!this._billData.billToEdit.projectCategory || !!this._billData.billToEdit.purchaseOrder) && !!this._billData.billToEdit.billPdf;

    await this._bill.set(this._billData.billToEdit.key, this._billData.billToEdit);

    this.stopUpdating();
    this.blockUI.stop();
  }

  calculatePurchaseOrderTotalBillsAmount(purchaseOrder: PurchaseOrder) {
    return purchaseOrder.invoice.reduce((acc, bill) => {
      if (!this.indexedBills[bill.id]) return acc;
      return +this.indexedBills[bill.id].total + acc;
    }, 0);
  }

  async setProofPayment() {
    let proofOfPayment: ProofOfPayment = {
      documentNumber: +this._billData.billToEdit.documentNumber,
      name: 'Voucher',
      proofOfPayment: this._billData.billToEdit.proofOfPayment,
      reference: this._bill.db.getReference(`bills/${this._billData.billToEdit.key}`),
      trash: false,
      type: 0
    };

    await this._bill.setProofPayment(this.bill.key, proofOfPayment);
  }

  startUpdating() {
    this._billData.billToEdit = _.cloneDeep(this._billData.bill);
    this._billData.updating = true;
    this._billData.updatingEmitter.emit(true);
  }

  stopUpdating() {
    this._billData.bill = this.bill = _.cloneDeep(this.formatBill(this._billData.billToEdit));
    this._billData.billToEdit = null;
    this._billData.updating = false;
  }

  cancelUpdating() {
    this._billData.bill = this.formatBill();
    this._billData.billToEdit = null;
    this._billData.updating = false;
  }

  formatBill(bill: Bill = this.bill) {
    return {
      ...bill,
      purchaseOrder: bill.purchaseOrder && this.indexedPurchaseOrders[bill.purchaseOrder.id] || null,
      project: bill.project && this.indexedProjects[bill.project.id || bill.project.key] || null,
      projectCategory: bill.projectCategory && this.categoriesIndexed[bill.projectCategory.id || bill.projectCategory.reference.id] || null
    };
  }
}
