<app-datatable [rows$]="clients$" [config]="config">
  <app-datatable-column title="Nombre" field="name">
    <ng-template let-row #column>
      <h4>{{!!row.nameContact ? row.nameContact : row.name}}</h4>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Cargo" field="positionContact">
    <ng-template let-row #column>
      <h4>{{row.positionContact}}</h4>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Teléfono" field="phoneContact">
    <ng-template let-row #column>
      <h4>{{row.phoneContact}}</h4>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Correo" field="emailContact">
    <ng-template let-row #column>
      <h4>{{row.emailContact}}</h4>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Comuna" field="communeContact">
    <ng-template let-row #column>
      <h4>{{row.communeContact}}</h4>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column [isClickable]="false">
    <ng-template let-row #column>
      <div class="d-flex justify-content-end">
        <button class="btn btn-info mr-1"
                ngbTooltip="Editar" (click)="openEditClientModal(row)">
          <i class="la la-edit"></i>
        </button>
        <button class="btn btn-danger"
                ngbTooltip="Eliminar" (click)="deleteClient(row)">
          <i class="la la-trash"></i>
        </button>
      </div>
    </ng-template>
  </app-datatable-column>
</app-datatable>

<app-add-float-button (clickEmmiter)="openAddclientModal()"></app-add-float-button>
