import {Component, OnInit} from '@angular/core';
import {AlertService} from '../../../../../shared/template-services/alert.service';
import * as XLSX from 'xlsx';
import {Article} from '../../interfaces/article';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ArticleService} from 'src/app/shared/services/article.service';
import {CategoryService} from 'src/app/shared/services/category.service';
import {Category} from '../../../../../shared/interfaces/category';
import {take} from 'rxjs/operators';
import {UnitsMeasureService} from '../../../../../shared/services/units-measure.service';
import {LogsService} from '../../../../services/logs.service';
import {Warehouse} from '../../interfaces/warehouse';
import {WarehouseService} from '../../../../../shared/services/warehouse.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {FirebaseDataService} from '../../../../../shared/template-services/firebase-data.service';

@Component({
  selector: 'app-add-articles',
  templateUrl: './add-articles.component.html',
  styleUrls: ['./add-articles.component.css'],
})
export class AddArticlesComponent implements OnInit {
  data: any[];
  file: any;
  headers: string[] = [
    'name',
    'type',
    'internalId',
    'quantity',
    'unitMeasure',
    'price',
    'categories',
    'minStock',
    'maxStock',
    'location',
    'imageUrl',
    'provider',
    'serialNumber',
  ];
  excelExtension = '.xlsx';
  articles: Article[] = [];
  warehouseKey: string;
  categories: Category[];
  unitMeasure: any[];
  articleTypeLabel = {
    ALMACENABLE: 0,
    CONSUMIBLE: 1,
  };
  articlesBd: Article[] = [];
  isYesAll: boolean = false;
  messageLoad: string;

  constructor(
    public modal: BsModalRef,
    private _article: ArticleService,
    private _category: CategoryService,
    private _unitMeasure: UnitsMeasureService,
    private _warehouse: WarehouseService,
    private _log: LogsService,
    private SpinnerService: NgxSpinnerService,
    private db: FirebaseDataService
  ) {}

  async ngOnInit(): Promise<void> {
    this.categories = await this._category.getAll().pipe(take(1)).toPromise();
    this.unitMeasure = await this._unitMeasure
      .getAll()
      .pipe(take(1))
      .toPromise();
    this.articlesBd = await this._article
      .getAllByWarehouse(this.warehouseKey)
      .pipe(take(1))
      .toPromise();
  }

  extractDataFromExcelFile(event): void {
    const target: DataTransfer = <DataTransfer>event.target;

    if (target.files.length !== 1) {
      AlertService.error('No se pueden cargar multiples archivos', '');
      return;
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = XLSX.utils.sheet_to_json(ws, {header: 1});
      this.deleteUnnecessaryRows();
      this.formatXmlRows();
    };
    reader.readAsBinaryString(target.files[0]);
  }

  deleteUnnecessaryRows(): void {
    delete this.data[0]; // COLSAN ROW
    delete this.data[1]; // EMPTY ROW
    delete this.data[2]; // TITLE ROW
    delete this.data[3]; // HEADERS ROW
  }

  async formatXmlRows(): Promise<void> {
    this.articles = [];
    for (let i = 4; i < this.data.length; i++) {
      if (this.data[i].length < 5) continue;
      let item = {} as Article;
      for (let j = 0; j < this.data[i].length; j++) {
        if (this.data[i][j] == undefined) {
          this.data[i][j] = '';
        }
        item[this.headers[j]] = this.data[i][j];
      }
      const articleFound = this.articlesBd.find(
        (article) => article.internalId == item.internalId
      );
      if (!!articleFound) {
        if (this.isYesAll) {
          item = await this.formatArticle(item);
          this._article.update(
            articleFound.warehouseKey,
            articleFound.key,
            item
          );
          continue;
        }

        if (
          await AlertService.confirm(
            `El artículo ${articleFound.internalId} ya existe en la base de datos`,
            '¿Deseas actualizarlo o descartarlo?',
            'Actualizar todos',
            'Descartar'
          )
        ) {
          this.isYesAll = true;
          item = await this.formatArticle(item);
          this._article.update(
            articleFound.warehouseKey,
            articleFound.key,
            item
          );
          continue;
        }
      }

      let type: any = item.type;
      item.type = type.replace(/\s/g, '');

      this.articles.push(item);
    }
  }

  async saveArticles() {
    this.messageLoad = 'Cargando artículos...';
    this.SpinnerService.show();
    for (let i = 0; i < this.articles.length; i++) {
      let article = this.articles[i];
      this.messageLoad = `Cargando ${i + 1} de ${
        this.articles.length
      } artículos`;
      article = this.formatArticle(article);
      if (!article['quantity']) article['quantity'] = 1;
      await this._article.add(this.warehouseKey, article);
    }
    this._log.add(this.warehouseKey, {
      description: `Se agregaron ${this.articles.length} Artículos`,
    });
    this._warehouse.update(this.warehouseKey, {
      lastUpdate: new Date().getTime(),
    } as Warehouse);
    await AlertService.success(`Se cargaron ${this.articles.length} artículos`);
    this.SpinnerService.hide();
    this.modal.hide();
  }

  private formatArticle(article: any) {
    const categories: any = article.categories;
    article.categories = categories.replace(' ', '').split(',');

    article.categories = article.categories.map((categoryItem) => {
      let category: any = this.categories.find(
        (item) =>
          item.name.replace(/\s/g, '') == categoryItem.replace(/\s/g, '')
      );

      if (!category) {
        const key = this.db.createID();
        this._category.addByID({name: categoryItem, trash: false}, key);
        category = {
          key,
          name: categoryItem,
        };
        this.categories.push(category);
      }

      return {
        key: category.key,
        name: category.name,
      };
    });

    let unitMeasure = this.unitMeasure.find(
      (unitMeasure) => unitMeasure.name == article.unitMeasure
    );
    if (!unitMeasure) {
      const key = this.db.createID();
      this._unitMeasure.addByID({name: article.unitMeasure, trash: false}, key);
      unitMeasure = {
        key: key,
        name: article.unitMeasure,
      };
      this.unitMeasure.push(unitMeasure);
    }

    article.unitMeasure = unitMeasure;

    const serialNumber: any = article.serialNumber;
    if (!!article.serialNumber)
      article.serialNumber = serialNumber
        .toString()
        .replace(' ', '')
        .split(',');

    article.type = article.type.toString().toUpperCase();

    const type: any = this.articleTypeLabel[article.type];
    article.type = type;

    article.trash = false;
    article.totalQuantity = article.quantity;

    return article;
  }
}
