<div class="p-2">
  <h2>Actualizar fecha de pago</h2>

    <div class="row mt-2">
      <div class="col-12">
        <div class="form-group">
          <label>Fecha de pago</label>
          <input class="form-control" type="date" [(ngModel)]="date">
        </div>
      </div>
    </div>

    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn" (click)="submit()">Actualizar</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide(33)">Cancelar</div>
    </div>
</div>

