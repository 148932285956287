import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {ChecklistService} from '../../services/checklist.service';
import {ChecklistTask} from '../../interfaces/checklist-task';
import {Task} from '../../interfaces/task';
import {of, Subscription} from 'rxjs';
import {AlertService} from '../../../../../shared/template-services/alert.service';
import {UserService} from '../../../../../shared/services/user.service';
import {UserType} from '../../../../enums/user-type.enum';
import {SearchItemsModalComponent} from '../../../../../shared/template-components/search-items/search-items-modal.component';
import {take} from 'rxjs/operators';
import {KanbanService} from '../../kanban.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {FirebaseDataService} from 'src/app/shared/template-services/firebase-data.service';
import {FieldDataTableColumnType} from '../../../../../shared/enums/field-data-table-column-type.enum';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.css'],
})
export class ChecklistComponent implements OnInit, OnDestroy {
  @Input() boardKey: string;
  @Input() task: Task;
  @Output() checkedTask: EventEmitter<void> = new EventEmitter();
  userPermission: any;
  checklist: ChecklistTask[] = [];
  disableChecklist: boolean = true;
  userTypeEnum = UserType;
  checklistSubscription: Subscription = new Subscription();
  statusLabel = {
    0: 'POR HACER',
    1: 'EN PROCESO',
    2: 'HECHO',
  };

  constructor(
    private _checklist: ChecklistService,
    private _task: KanbanService,
    private modal: BsModalService,
    private db: FirebaseDataService,
    private _user: UserService,
    private _kanban: KanbanService
  ) {}

  async ngOnInit(): Promise<void> {
    this.userPermission = this._user.user.permissions.find(
      (permission) => permission.section == 'TAREAS'
    ).permission;
    if (
      this.task.members.some(
        (member) => !!member && member.key == this._user.user.key
      ) ||
      this.userPermission != this.userTypeEnum.USER
    ) {
      this.disableChecklist = false;
    }
    this.checklistSubscription = this._checklist
      .getAll(this.boardKey, this.task.key)
      .subscribe((data) => {
        this.checklist = data;
      });
  }

  ngOnDestroy() {
    this.checklistSubscription.unsubscribe();
  }

  handleChecked(checklistTask: ChecklistTask) {
    this._checklist.updateTask(
      this.boardKey,
      this.task.key,
      checklistTask.key,
      {checked: checklistTask.checked} as ChecklistTask
    );
    this._kanban.setLog(this.boardKey, this.task.key, {
      description: `Se ${
        checklistTask.checked ? 'marcó' : 'desmarcó'
      } un checklist`,
      user: this.db.getReference(`users/${this._user.user.key}`),
      createdAt: new Date().getTime(),
    });
    if (!!checklistTask.checked) this.checkedTask.emit();
  }

  public async addTask() {
    let taskText = await AlertService.input('Nueva tarea');

    if (!taskText) return;

    this._checklist.addTask(this.boardKey, this.task.key, {
      taskText,
      checked: false,
      trash: false,
      createdAt: new Date().getTime(),
    });
    this._kanban.setLog(this.boardKey, this.task.key, {
      description: 'Se agregó un checklist',
      user: this.db.getReference(`users/${this._user.user.key}`),
      createdAt: new Date().getTime(),
    });
  }

  public async addExistenTask() {
    let taskSelected;

    const modalRef = this.modal.show(SearchItemsModalComponent, {
      initialState: {
        items$: of(this._kanban.pendingTasks),
        fields: [
          {
            title: 'Tarea',
            column: 'title',
          },
          {
            title: 'Usuarios',
            column: 'members',
            type: FieldDataTableColumnType.ARRAY,
          },
          {
            title: 'Estatus',
            column: 'status',
            type: FieldDataTableColumnType.LABEL,
            labels: {
              0: 'POR HACER',
              1: 'EN PROCESO',
              2: 'LISTO',
            },
          },
        ],
      },
      class: 'modal-lg shadow-lg mt-5',
      id: 100,
    });

    modalRef.onHide.pipe(take(1)).subscribe(() => {
      taskSelected = modalRef.content.itemSelected;
      if (!taskSelected) return;

      this._checklist.addTask(this.boardKey, this.task.key, {
        taskText: null,
        checked: false,
        trash: false,
        task: this.db.getReference(
          `boards/${this.boardKey}/tasks/${taskSelected.key}`
        ),
        createdAt: new Date().getTime(),
      });
      this._kanban.setLog(this.boardKey, this.task.key, {
        description: 'Se ligó una tarea al checklist',
        user: this.db.getReference(`users/${this._user.user.key}`),
        createdAt: new Date().getTime(),
      });
    });
  }

  async deleteTask(key: string) {
    if (
      await AlertService.confirm(
        '¿Estás seguro que deseas eliminar esta tarea?'
      )
    ) {
      this._checklist.updateTask(this.boardKey, this.task.key, key, {
        trash: true,
      } as ChecklistTask);
      this._kanban.setLog(this.boardKey, this.task.key, {
        description: 'Se eliminó un checklist',
        user: this.db.getReference(`users/${this._user.user.key}`),
        createdAt: new Date().getTime(),
      });
    }
  }
}
