import {Component, Input, OnInit} from '@angular/core';
import {EmployeePaymentService} from '../../services/employee-payment.service';
import {Observable} from 'rxjs';
import {ProjectStatusLabel} from '../../labels/projectStatusLabel';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {EmployeePayment} from '../../interfaces/employee-payment';
import {BsModalService} from 'ngx-bootstrap/modal';
import {EmployeePaymentModalComponent} from '../../modals/employee-payment-modal/employee-payment-modal.component';

@Component({
  selector: 'app-employee-payments',
  templateUrl: './employee-payments.component.html',
  styleUrls: ['./employee-payments.component.css']
})
export class EmployeePaymentsComponent implements OnInit {
  @Input() costCenterKey: string;
  employeePayment$: Observable<EmployeePayment[]>;
  statusLabel = ProjectStatusLabel;
  config: DataTableConfig = {hasSearch: true, notFoundText: 'No se encontraron centro de costos', title: 'Centro de costos'};

  constructor(private _employeePaymentService: EmployeePaymentService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.employeePayment$ = this._employeePaymentService.getAll(this.costCenterKey);
  }

  openEmployeePaymentEdit(employeePayment) {
    this.modal.show(EmployeePaymentModalComponent, {
      initialState: {
        employeePayment: employeePayment,
        costCenterKey: this.costCenterKey,
        isUpdate: true
      }
    });
  }
}
