import {Component, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {WorkerSalariesService} from '../../services/worker-salaries.service';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {Observable} from 'rxjs';
import {WorkerSalaries} from '../../interfaces/worker-salaries';
import {WorkerSalaryModalComponent} from '../../modals/worker-salary-modal/worker-salary-modal.component';
import {AddToExcelComponent} from '../../components/add-to-excel/add-to-excel.component';
import {take} from 'rxjs/operators';
import {Bill} from '../../interfaces/bill';
import {NgxSpinnerService} from 'ngx-spinner';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-worker-salaries',
  templateUrl: './worker-salaries.component.html',
  styleUrls: ['./worker-salaries.component.css']
})
export class WorkerSalariesComponent implements OnInit {
  config: DataTableConfig = {hasSearch: true, notFoundText: 'No se encontraron sueldos', title: 'Sueldos'};
  workerSalaries$: Observable<WorkerSalaries[]>;
  fileUploaded: number = 1;
  fileQuantityUploaded: number = 0;

  constructor(private _workerSalaries: WorkerSalariesService,
              private modal: BsModalService,
              private SpinnerService: NgxSpinnerService,
              private _firebase: FirebaseDataService) {
  }

  ngOnInit(): void {
    this.workerSalaries$ = this._workerSalaries.getAll();
  }

  openEmployeePaymentEdit(workerSalary) {
    this.modal.show(WorkerSalaryModalComponent, {
      initialState: {
        isUpdate: true,
        workerSalary: workerSalary
      },
      id: 32
    });
  }

  openAddWorkerSalaryModal() {
    this.modal.show(WorkerSalaryModalComponent, {
      id: 32
    });
  }

  addToExcel() {
    const modalRef = this.modal.show(AddToExcelComponent, {
      initialState: {
        count: ['Sueldo trabajador', 'Gastos de ley']
      },
      class: 'modal-xl',
    });

    modalRef.onHide.pipe(take(1)).subscribe(async () => {
      if (modalRef.content.send) {
        this.fileUploaded = 1;

        this.SpinnerService.show();

        let items = modalRef.content.items;

        this.fileQuantityUploaded = items.length;
        for (let item of items) {
          let workerSalary: WorkerSalaries = {
            createdAt: new Date().getTime(),
            lawExpenses: item['Sueldo trabajador'],
            workerSalary: item['Gastos de ley'],
            trash: false
          };

          let key = await this._firebase.createID();
          await this._workerSalaries.set(key, workerSalary);

          this.fileUploaded++;
        }

        AlertService.toastSuccess(`Se agregaron ${this.fileUploaded} salarios.`)

        this.SpinnerService.hide();
      }
    });
  }

  async delete(key) {
    if (await AlertService.confirm('¿Estás seguro que deseas eliminar este salario?')) {
      await this._workerSalaries.delete(key)
      AlertService.toastSuccess('Se ha eliminado con éxito')
    }
  }
}
