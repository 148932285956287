import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ProjectService} from '../../../shared/services/project.service';
import {Project} from '../../interfaces/project';
import {ProjectStatusLabel} from '../../labels/projectStatusLabel';
import {AlertService} from '../../../shared/template-services/alert.service';
import {UserService} from '../../../shared/services/user.service';
import {take} from 'rxjs/operators';
import {ProjectStatus} from '../../enums/project-status.enum';
import {ApplicantService} from '../../../shared/services/applicant.service';
import {Applicant} from '../../interfaces/applicant';
import {NgxSpinnerService} from 'ngx-spinner';
import {User} from '../../../shared/interfaces/user';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';
import {StatusService} from '../../../shared/services/status.service';
import {ClientService} from '../../services/client.service';

@Component({
  selector: 'app-update-project',
  templateUrl: './update-project.component.html',
  styleUrls: ['./update-project.component.css']
})
export class UpdateProjectComponent implements OnInit {
  editProjectForm: FormGroup;
  submitted: boolean = false;
  project: Project;
  projectKey: string;
  projectType = ProjectStatusLabel;
  projectStatus = ProjectStatus;
  users: User[] = [];
  status;
  clients: any;

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalService,
              private _project: ProjectService,
              private _user: UserService,
              private _applicant: ApplicantService,
              private SpinnerService: NgxSpinnerService,
              private db: FirebaseDataService,
              private _status: StatusService,
              private _client: ClientService) {
    this.editProjectForm = formBuilder.group({
      name: ['', Validators.required],
      startDate: ['', Validators.required],
      status: ['', Validators.required],
      code: ['', Validators.required],
      endDate: ['', Validators.required],
      client: [''],
      saleValue: [null],
      activeLateEndDate: [null]
    });
  }

  async ngOnInit() {
    this.clients = await this._client.getAll().pipe(take(1)).toPromise();
    this.editProjectForm.patchValue(this.project);
    this.findSelectedClient();
    this.status = await this._status.getAll().pipe(take(1)).toPromise();
    this.status = this.status.find(data => data.name == 'calificado');
    if (!!this.status) delete this.status.trash;
  }

  async submit() {
    this.submitted = true;
    if (!this.editProjectForm.valid) return;
    if (!await AlertService.confirm('¿Estás seguro de que deseas guardar estos datos?')) return;
    if (!!this.editProjectForm.value.client) {
      let reference = this.db.getReference(`clients/${this.editProjectForm.value.client.key}`);
      this.editProjectForm.patchValue({client: reference});
    }
    await this._project.update(this.projectKey, this.editProjectForm.value as Project);
    this.modal.hide();
    AlertService.toastSuccess('Proyecto actualizado correctamente');
  }

  get formControls() {
    return this.editProjectForm.controls;
  }

  async changeToInactive() {
    const confirm = await AlertService.input(`Escriba ${this.project.name} para confirmar el proceso de inactividad del proyecto.`);

    if (!confirm) return;
    if (!!confirm && confirm != this.project.name) return AlertService.error('El texto a escribir no coincide ');

    this.SpinnerService.show();
    let projectReference = await this._project.getReference(this.projectKey);
    let persons = await this._user.getAllByProject(projectReference).pipe(take(1)).toPromise();

    await this._project.update(this.projectKey, {status: ProjectStatus.INACTIVE} as Project);

    for (let person of persons) {
      let applicant = await this._applicant.getSpecifyUser(person.name, person.surnames).pipe(take(1)).toPromise();
      if (!!applicant[0]) await this._applicant.update(applicant[0].key, {
        status: {
          key: 'MvaUKcipH7CEW1riqj6h',
          name: 'calificado'
        }
      } as Applicant);
    }

    this.project.status = ProjectStatus.INACTIVE;
    this.SpinnerService.hide();

    AlertService.success('El proyecto ha pasado estatus inactivo. ');
  }

  findSelectedClient() {
    let client = this.clients.find(client => {
      if (!!this.project.client && this.project.client.id) return this.project.client.id == client.key;
      else if (!!this.project.client && !!this.project.client.key) return this.project.client.key == client.key;
    })
    this.editProjectForm.patchValue({client: client || this.project.client});
  }
}
