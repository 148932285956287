import { Component, OnInit } from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ClientModalComponent} from '../../modals/client-modal/client-modal.component';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {Client} from '../../interfaces/client';
import {Observable} from 'rxjs';
import {ClientService} from '../../services/client.service';
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {
  clients$: Observable<Client[]>
  config: DataTableConfig = {
    notFoundText: 'No se encontraron tableros',
    title: 'Lista de tableros',
    hasSearch: true
  };

  constructor(private modal: BsModalService,
              private _client: ClientService) { }

  ngOnInit() {
    this.clients$ = this._client.getAll();
  }

  openAddclientModal() {
    this.modal.show(ClientModalComponent)
  }

  openEditClientModal(client: Client) {
    this.modal.show(ClientModalComponent, {
      initialState: {
        client: {...client}
      }
    })
  }

  async deleteClient({key: clientKey}) {
    if (await AlertService.confirm('¿Estás seguro de que deseas eliminar este cliente?')) {
      await this._client.delete(clientKey);
      AlertService.toastSuccess('El cliente se ha eliminado correctamente');
    }
  }
}
