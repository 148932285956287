<app-datatable [rows$]="jobs$"
               [config]="config"
               (rowSelected)="openUpdatePositionModal($event, $event.key)">
  <app-datatable-column title="Cargo">
    <ng-template let-row #column>
      {{row?.name}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Cuestionario">
    <ng-template let-row #column>
      {{!!row.quiz ? (row?.quiz | doc | async)?.name : 'No asignado'}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false" [isVisiblePrint]="false">
    <ng-template let-row #column>
      <button class="btn btn-danger btn-sm" (click)="deletePosition(row?.key, row.position)">Eliminar</button>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false" [isVisiblePrint]="false">
    <ng-template let-row #column>
      <button class="btn btn-facebook btn-sm" (click)="raisePosition(row?.position, row?.key)"><i class="feather ft-arrow-up"></i></button>
      <button class="btn btn-facebook btn-sm" (click)="lowerPosition(row?.position, row?.key)"><i class="feather ft-arrow-down"></i></button>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false" [isVisiblePrint]="false">
    <ng-template let-row #column>
      <button *ngIf="row?.isVisible" class="btn btn-success btn-sm" (click)="changeToInvisible(row?.position, row?.key, row?.name)"><i class="feather ft-eye"></i></button>
      <button *ngIf="!row?.isVisible" class="btn btn-danger btn-sm" (click)="changeToVisible(row?.position, row?.key, row?.name)"><i class="feather ft-eye-off"></i></button>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="openAddPositionModal()"></app-add-float-button>
