import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {Client} from '../interfaces/client';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  constructor(private afs: AngularFirestore, private db: FirebaseDataService) {}

  add(client: {name: string; trash: boolean}) {
    this.afs.collection('clients').add(client);
  }

  getAll(): Observable<Client[]> {
    return this.db.colWithIds$('clients', (ref) =>
      ref.where('trash', '==', false)
    );
  }

  async set(client: Client, clientKey: string) {
    return this.afs.doc(`clients/${clientKey}`).set(client, {merge: true});
  }

  get(clientKey: string): Observable<Client> {
    return this.db.docWithId$(`clients/${clientKey}`);
  }

  delete(clientKey: string) {
    return this.afs.doc(`clients/${clientKey}`).update({trash: true});
  }

  getReference(clientKey: string) {
    return this.db.getReference(`clients/${clientKey}`);
  }
}
