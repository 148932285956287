<div class="row mt-2">
  <div class="col-12 d-flex flex-row justify-content-around">
    <div class="form-group w-100">
      <label>Filtro</label>
      <ng-select [clearable]="false" [multiple]="true" [items]="selectMultipleArray" groupBy="type"
                 [(ngModel)]="selectedMultipleArray" class="" bindLabel="name"
                 (change)="selectFilter()"></ng-select>
    </div>

    <div class="form-group ml-2" style="width: 120px">
      <label>Renta mínimo</label>
      <input type="number"
             class="form-control"
             style="width: 120px"
             [(ngModel)]="minExpectation"
             placeholder="mínimo" />
    </div>

    <div class="form-group ml-2" style="width: 120px">
      <label>Renta máximo</label>
      <input type="number"
             class="form-control"
             style="width: 120px"
             [(ngModel)]="maxExpectation"
             placeholder="máximo" />
    </div>

    <div class="form-group ml-2" style="width: 120px">
      <label>Edad mínima</label>
      <input type="number"
             class="form-control"
             style="width: 120px"
             [(ngModel)]="this.minYears"
             placeholder="mínimo" />
    </div>

    <div class="form-group ml-2" style="width: 120px">
      <label>Edad máxima</label>
      <input type="number"
             class="form-control"
             style="width: 120px"
             [(ngModel)]="maxYears"
             placeholder="máximo" />
    </div>

    <div class="form-group mr-1 col-md-1 margin-top-button">
      <button class="btn btn-success margin-top-button" (click)="getApplicantsFilter()">Filtrar</button>
    </div>

  </div>

  <div class="col-md-12" *ngIf="duplicatedApplicants?.length > 0">
    <div class="d-flex flex-row mb-1">
      <button class="btn btn-warning" (click)="removeDuplicatedApplicants()">Borrar aplicantes duplicados</button>
    </div>
  </div>
  <div class="col-md-12 col-sm-12">
    <app-datatable-pagination
      [rows]="applicantsFiltered"
      [config]="applicantsConfig"
      [printSmall]="true"
      (rowSelected)="goToApplicantDetails($event.key)">

      <app-datatable-column title="RUT">
        <ng-template let-row #column>
          <div [ngClass]="{'text-danger': !!row.duplicated}">
            {{ row.rut }}
          </div>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Nombre">
        <ng-template let-row #column>
          <div [ngClass]="{'text-danger': !!row.duplicated}">
            {{ row.name }} {{row.surnames}}
          </div>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Correo" [onlyPrint]="true">
        <ng-template let-row #column>
          <div [ngClass]="{'text-danger': !!row.duplicated}">
            {{row.email}}
          </div>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Teléfono" [onlyPrint]="true">
        <ng-template let-row #column>
          <div [ngClass]="{'text-danger': !!row.duplicated}">
            {{ row.phone}}
          </div>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Comuna">
        <ng-template let-row #column>
          <div [ngClass]="{'text-danger': !!row.duplicated}">
            {{ row.city.name | titlecase}}
          </div>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Cargo">
        <ng-template let-row #column>
          <div [ngClass]="{'text-danger': !!row.duplicated}">
            {{ !!row.position.reference ? getPositionName(row.position.reference) : row.position.name }}
          </div>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Estado">
        <ng-template let-row #column>
          <div class="badge"
               [ngClass]="getStatusClass(row.status.name)">{{ row?.status.name | titlecase}}</div>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Fecha" [isVisiblePrint]="false">
        <ng-template let-row #column>
          <div [ngClass]="{'text-danger': !!row.duplicated}">
            {{ row?.createdAt | date: 'dd/MM/yyyy HH:mm' }}
          </div>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Acción" [isClickable]="false" [isVisiblePrint]="false">
        <ng-template let-row #column>
          <button *ngIf="row.status.name == 'postulante'" class="btn btn-warning btn-sm"
                  ngbTooltip="Pasar a revisión"
                  (click)="setStatus(row, 'revisión')">
            <i class="la la-check"></i>
          </button>

          <button *ngIf="row.status.name == 'postulante'" class="btn btn-info btn-sm margin-left-button-status"
                  ngbTooltip="Pasar a apto" (click)="setStatus(row, 'apto')">
            <i class="la la-check-circle"></i>
          </button>

          <button *ngIf="row.status.name == 'postulante'" class="btn btn-danger btn-sm margin-left-button-status"
                  ngbTooltip="Pasar a no apto" (click)="setStatus(row, 'no apto')">
            <i class="la la-close"></i>
          </button>

          <button class="btn btn-dark btn-sm margin-left-button-status"
                  ngbTooltip="Pasar a bloqueado" (click)="setStatus(row, 'bloqueado')">
            <i class="la la-ban"></i>
          </button>

          <button class="btn btn-danger btn-sm margin-left-button-status" *ngIf="!!row.duplicated"
                  ngbTooltip="Eliminar postulante duplicado" (click)="deleteDuplicatedApplicant(row)">
            <i class="la la-trash"></i>
          </button>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column [isClickable]="false"
                            *ngIf="userPermission == userTypeEnum.ADMIN">
        <ng-template let-row #column>
          <button ngbTooltip="Eliminar"
                  class="btn btn-danger"
                  (click)="deleteApplicant(row.key)">
            <i class="la la-trash"></i>
          </button>
        </ng-template>
      </app-datatable-column>

    </app-datatable-pagination>
  </div>
</div>
