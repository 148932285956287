import {Injectable} from '@angular/core';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {PurchaseOrder} from '../../admin/pages/purchase-orders/interfaces/purchase-order';
import {take} from 'rxjs/operators';
import {Record} from '../../admin/interfaces/comment';
import {AngularFireStorage} from '@angular/fire/storage';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderService {
  constructor(
    private db: FirebaseDataService,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private http: HttpClient
  ) {}

  getAll(): Observable<PurchaseOrder[]> {
    return this.db.colWithIds$<PurchaseOrder>('purchaseOrders', (ref) =>
      ref.orderBy('purchaseID', 'desc').where('trash', '==', false)
    );
  }

  get(purchaseKey: string): Observable<PurchaseOrder> {
    return this.db.docWithId$(`purchaseOrders/${purchaseKey}`);
  }

  getAllNotInvoiced(): Observable<PurchaseOrder[]> {
    return this.db.colWithIds$<PurchaseOrder>('purchaseOrders', (ref) =>
      ref
        .orderBy('purchaseID', 'desc')
        .where('trash', '==', false)
        .where('invoiced', '==', false)
    );
  }

  getAllInvoiced(): Observable<PurchaseOrder[]> {
    return this.db.colWithIds$<PurchaseOrder>('purchaseOrders', (ref) =>
      ref
        .orderBy('purchaseID', 'desc')
        .where('trash', '==', false)
        .where('invoiced', '==', true)
    );
  }

  add(purchaseOrder: PurchaseOrder): Promise<DocumentReference> {
    return this.afs.collection(`purchaseOrders`).add(purchaseOrder);
  }

  set(purchaseKey: string, purchaseOrder: PurchaseOrder): void {
    this.afs
      .doc(`purchaseOrders/${purchaseKey}`)
      .set(purchaseOrder, {merge: true});
  }

  delete(purchaseOrderKey: string): Promise<void> {
    return this.afs
      .doc<PurchaseOrder>(`purchaseOrders/${purchaseOrderKey}`)
      .update({trash: true});
  }

  async update(purchaseOrderKey: string, purchaseOrder: PurchaseOrder): Promise<void> {
    return await this.afs
      .doc<PurchaseOrder>(`purchaseOrders/${purchaseOrderKey}`)
      .update(purchaseOrder);
  }

  createId(): string {
    return this.afs.createId();
  }

  async purchaseId(): Promise<number> {
    const settings = await this.db
      .doc('settings/setting')
      .valueChanges()
      .pipe(take(1))
      .toPromise();

    // @ts-ignore
    return settings.purchaseID;
  }

  updatePurchaseID(purchaseID: number) {
    this.afs.doc('settings/setting').update({purchaseID});
  }

  async uploadInvoice(
    file,
    purchaseOrderKey: string,
    nameFile: string,
    extension: string
  ) {
    const uploadRef = this.getStorageRefFile(
      purchaseOrderKey,
      nameFile,
      extension
    );
    await uploadRef.put(file);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadFileStorage(file, purchaseOrderKey, nameFile, extension);

    return url;
  }

  getStorageRefFile(id: string, nameFile: string, extension) {
    return this.storage.ref(`purchase-order/${id}/${nameFile}.${extension}`);
  }

  uploadFileStorage(data, id, nameFile: string, extension) {
    return this.storage.upload(`medics/${id}/${nameFile}.${extension}`, data);
  }

  getComments(purchaseKey: any): Observable<any[]> {
    return this.afs
      .collection(`purchaseOrders/${purchaseKey}/comments`)
      .valueChanges();
  }

  async addComment(purchaseKey: any, comment: any): Promise<any> {
    return await this.afs
      .collection<Record>(`purchaseOrders/${purchaseKey}/comments`)
      .add(comment);
  }

  sendPdfToProvider(
    pdfUrl: string,
    recipients: string[],
    comment: string,
    purchaseID: number
  ) {
    this.http
      .post(`${environment.apiBaseURL}/sendPdfProvider`, {
        pdfUrl,
        recipients,
        comment,
        purchaseID,
      })
      .pipe(take(1))
      .toPromise();
  }

  getReference(key: string) {
    return this.db.getReference(`purchaseOrders/${key}`);
  }
}
