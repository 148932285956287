import {Component, OnInit} from '@angular/core';
import {Article} from '../../interfaces/article';
import {ArticleService} from '../../../../../shared/services/article.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../../../shared/template-services/alert.service';

@Component({
  selector: 'app-add-serial-number',
  templateUrl: './add-serial-number.component.html',
  styleUrls: ['./add-serial-number.component.css']
})
export class AddSerialNumberComponent implements OnInit {
  serialForm: FormGroup;
  article: Article;
  submitted: boolean = false;
  warehouseKey: string;

  constructor(private _article: ArticleService,
              public modal: BsModalService,
              private formBuilder: FormBuilder) {
    this.serialForm = formBuilder.group({
      serial: ['', Validators.required]
    });
  }

  get formControls() {
    return this.serialForm.controls;
  }

  ngOnInit(): void {
  }

  async submit() {
    this.submitted = true;
    if (this.serialForm.valid) {
      this.article.serialNumber.push(this.serialForm.value.serial);
      await this._article.update(this.warehouseKey, this.article.key, this.article);
      AlertService.toastSuccess('Se agregó el numero serial');
      this.modal.hide(101);
    }
  }
}
