import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'ti-input',
  templateUrl: './ti-input.component.html',
  styleUrls: ['./ti-input.component.css']
})
export class TiInputComponent implements OnInit {
  @Input() multiline: boolean = false;
  @Input() placeholder: string = 'Escribe aquí...';
  @Input() rows: number = 5;
  @Input() model: string = '';
  @Input() mode: 'material' | 'bootstrap' = 'bootstrap';
  @Input() label: string = '';
  @Input() control: AbstractControl;
  @Input() type: string = 'text';
  @Input() mask: string = '';
  @Input() suffix: string = '';
  @Input() prefix : string = '';
  @Input() max : string = '';

  @Output() modelChange = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onChange($event: any) {
    this.modelChange.emit($event);
  }
}
