<app-datatable [rows$]="proofPayments$"
               [config]="config"
               (rowSelected)="openProofOfPaymentModal($event)">

  <app-datatable-column title="Nombre">
    <ng-template let-row #column>
      {{ !row.content ? row.name : ''}}
      {{ row.content && includesName(row.name) ? row.name : ''}}
      {{ getRut(row.content) }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="N. factura">
    <ng-template let-row #column>
      {{ row.documentNumber }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false">
    <ng-template let-row #column>
      <button class="btn btn-info" *ngIf="row.reference" (click)="gotoOpenEditBill(row.reference)"><i class="feather ft-eye"></i></button>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false">
    <ng-template let-row #column>
      <button class="btn btn-danger" *ngIf="!row.reference" (click)="deleteProofPayment(row.key)"><i class="fa fa-trash"></i></button>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="addToEmail()"></app-add-float-button>
