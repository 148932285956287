import { Routes } from '@angular/router';
import { ExternalLayoutComponent } from '../layout/external-layout/external-layout.component';
import { ApplicantDetailComponent } from '../admin/pages/applicant-detail/applicant-detail.component';

export const ExternalRoutes: Routes = [
  {
    path: '',
    component: ExternalLayoutComponent,
    children: [
      {
        path: 'applicant-details/:applicantKey',
        component: ApplicantDetailComponent
      }
    ]
  }
];
