import { Injectable } from '@angular/core';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';
import {AuthService} from '../template-services/auth.service';
import {UserService} from './user.service';
import {Observable} from 'rxjs';
import {TicketAuthorization} from '../../admin/pages/warehouses/interfaces/ticket-authorization';
import {Article} from '../../admin/pages/warehouses/interfaces/article';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TicketAuthorizationService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore,
              private _auth: AuthService,
              private _user: UserService) {
  }

  getAllRequests(warehouseKey: string): Observable<TicketAuthorization[]> {
    return this.afs.collection<TicketAuthorization>(`warehouses/${warehouseKey}/requests`, ref => ref
      .where('trash', '==', false)
      .orderBy('date', 'desc'))
      .snapshotChanges()
      .pipe(map(actions => actions.map((a:any) => {
        return {
          ...a.payload.doc.data(),
          key: a.payload.doc.id
        }
      })))
  }

  getAll(): Observable<TicketAuthorization[]> {
    return this.afs.collectionGroup<TicketAuthorization>(`ticketsAuthorization`, ref => ref
      .where('trash', '==', false))
      .snapshotChanges()
      .pipe(map(actions => actions.map((a:any) => {
        return {
          ...a.payload.doc.data(),
          key: a.payload.doc.id,
          userKey: a.payload.doc.ref.parent.parent.id
        }
      })))
  }

  async addRequest(warehouseKey: string, request: any): Promise<any> {
    return await this.afs.collection<TicketAuthorization>(`warehouses/${warehouseKey}/requests`).add(request);
  }

  async add(userKey: string, request: any): Promise<any> {
    return await this.afs.collection<TicketAuthorization>(`users/${userKey}/ticketsAuthorization`).add(request);
  }

  async delete(userKey: string, requestKey: string): Promise<void> {
    return await this.afs.doc<TicketAuthorization>(`users/${userKey}/ticketsAuthorization/${requestKey}`).update({trash: true});
  }

  updateRequest(warehouseKey: string, requestKey: any, request: any) {
    this.afs.doc(`warehouses/${warehouseKey}/requests/${requestKey}`).update(request);
  }

  update(userKey: string, ticketKey: any, request: any) {
    this.afs.doc(`user/${userKey}/ticketsAuthorization/${ticketKey}`).update(request);
  }

  get(userKey: string, requestKey: string) {
    return this.afs.doc(`users/${userKey}/ticketsAuthorization/${requestKey}`).valueChanges();
  }
}
