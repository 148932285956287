import { Injectable } from '@angular/core';
import { ValidationService } from './validation.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectService {

  constructor() {
  }

  static getJSONArray(json): any[] {
    let array = [];

    for (let i in json) {
      if (i != 'default') array.push(json[i]);
    }

    return array;
  }

  static disablePointer(itemToDisablePointer: any): any {
    return JSON.parse(JSON.stringify(itemToDisablePointer));
  }

  static convertToURL(text: string): string {
    return text.replace(/ /g, '%20');
  }

  static urlToText(url: string): string {
    return url.replace(/%20/g, ' ');
  }

  textWithoutAccent(text: string) {
    if (!text) text = '';
    return text.normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, '$1');
  }

  static indexArray(array, propertyIndex?: string) {
    if (!!propertyIndex) {
      return array.reduce((acc, el) => ({
        ...acc,
        [el[propertyIndex]]: el
      }), {});
    } else {
      return array.reduce((acc, el) => ({
        ...acc,
        [el]: el
      }), {});
    }
  }

  static resolveChainPromise(promiseArray, initialValue) {
    return promiseArray.reduce(async (memo: any, fn) => fn(await memo), initialValue);
  }

  static replaceUndefined(array: any[], value: any = ''): any[] {
    return array.map((item) => {
      for (let property in item) {
        if (!ValidationService.isValid(item[property])) item[property] = value;
      }
      return item;
    });
  }

  static base64ToBlob(base64: string, type: string = 'image/png'): Blob {
    const byteString = atob(base64.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) int8Array[i] = byteString.charCodeAt(i);
    return new Blob([int8Array], { type: type });
  }
}
