<div class="p-1">
  <div class="modal-header p-1">
    <h4 class="pt-1">
      Agregar
    </h4>
    <div>
      <div *ngIf="items.length > 0" class="btn btn-success mr-1" (click)="sendData()">Registrar</div>
      <div class="btn btn-danger" (click)="modal.hide()">Cerrar</div>
    </div>
  </div>
  <div class="modal-body">
    <div class="row mt-4">
      <div class="offset-3 col-6">
        <div class="custom-file">
          <input type="file"
                 class="upload-drop-zone upload-drop-zone.drop"
                 id="customFile"
                 accept=".csv, .xlsx, .xls"
                 [(ngModel)]="file"
                 #fileInput
                 (change)="extractDataFromExcelFile($event, fileInput)">
        </div>
      </div>
    </div>
    <h1 class="text-center">Haz clic para agregar o arrastra el archivo en el recuadro</h1>
    <div *ngIf="items.length > 0">
      <h4 class="mt-4">Lista de usuarios cargados (total: {{items.length}})</h4>
      <table class="table table-hover table-striped table-responsive mt-4 overflow-auto">
        <thead>
        <tr>
          <ng-container *ngFor="let count of count; let index = index">
            <th>Columna {{index + 1}}</th>
          </ng-container>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of items">
          <ng-container *ngFor="let count of count">
            <td>{{item[count.count]}}</td>
          </ng-container>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
