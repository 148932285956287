<app-datatable [rows$]="employeePayment$"
               [config]="config"
               (rowSelected)="openEmployeePaymentEdit($event)">

  <app-datatable-column title="Cantidad">
    <ng-template let-row #column>
      {{row?.payments | clearCurrency | swapCommasAndDots}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha de pago">
    <ng-template let-row #column>
      {{row?.date | date : 'dd/MM/yyyy'}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Hora creación">
    <ng-template let-row #column>
      {{row?.createdAt | date : 'dd/MM/yyyy HH:mm'}}
    </ng-template>
  </app-datatable-column>

</app-datatable>
