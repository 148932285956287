import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UserType } from '../../../../enums/user-type.enum';
import { ValidationService } from '../../../../../shared/template-services/validation.service';
import { AlertService } from '../../../../../shared/template-services/alert.service';
import { Applicant } from '../../../../interfaces/applicant';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../../../../../shared/services/user.service';
import { ApplicantService } from '../../../../../shared/services/applicant.service';
import { LogsService } from '../../../../services/logs.service';

@Component({
  selector: 'app-applicant-curriculum',
  templateUrl: './applicant-curriculum.component.html',
  styleUrls: ['./applicant-curriculum.component.css']
})
export class ApplicantCurriculumComponent implements OnInit {
  isPdf: boolean = true;
  isDocx: boolean = false;
  url: any;
  permissionEnum = UserType;
  zoom = 1;
  @Input() formControls;
  @Input() permission: any;
  @Input() submitted: boolean = false;
  @Input() file: any;
  @Input() applicant: Applicant;
  @Input() isMobile: boolean = false;
  @Input() path: any;
  @Input() uploadMessage: string = '';
  @Input() applicants: any = [];
  @Input() indexApplicant: number;
  @Input() applicantKey: string;

  constructor(private _applicant: ApplicantService,
    private SpinnerService: NgxSpinnerService,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private _log: LogsService) {
  }

  ngOnInit(): void {
    this.getPDFUrl(this.applicants[this.indexApplicant]?.curriculum);
  }

  getPDFUrl(url: string) {
    if (!url) {
      return;
    }

    fetch(url).then(response => {
      const fileContentType = response.headers.get('Content-Type');

      this.isPdf = fileContentType == 'application/pdf';
      this.isDocx = fileContentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        || fileContentType == 'application/msword'
        || fileContentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.template'
        || fileContentType == 'application/vnd.ms-word.document.macroEnabled.12';

      this.url = url;

      if (this.isPdf) {
        return this.url = this.sanitizer.bypassSecurityTrustHtml(
          `<iframe width="100%" height="625" src="${url}"></iframe>`
        );
      }

      if (!this.isPdf && !this.isDocx) {
        this.url = this.url.replaceAll('?', '.docx?');
      }
    });
  }

  uploadCurriculum(event) {
    if (
      event.target.files[0].name.slice(event.target.files[0].name.length - 3) ==
      'pdf' ||
      event.target.files[0].name.slice(event.target.files[0].name.length - 4) ==
      'docx'
    ) {
      if (!ValidationService.validateFileSize(event, 10000)) {
        return AlertService.toastError('El archivo debe ser menor a 10MB');
      }
      this.file = event.target.files[0];
      if (event.target.files && this.file) {
        let reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
          this.path = (<FileReader>event.target).result;
        };
        reader.readAsDataURL(this.file);
      }
    } else {
      return AlertService.error('Solo se permiten archivos pdf o docx');
    }
  }

  async updateCurriculum() {
    this.uploadMessage = 'Actualizando curriculum...';
    this.SpinnerService.show();
    this.applicants[this.indexApplicant].curriculum =
      await this._user.uploadDocument(
        'applicant',
        'curriculum',
        this.file,
        this.applicants[this.indexApplicant].key,
        `Curriculum-${this.applicants[this.indexApplicant].name} ${
          this.applicants[this.indexApplicant].surnames
        }`
      );

    await this._applicant.update(
      this.applicants[this.indexApplicant].key,
      this.applicants[this.indexApplicant]
    );

    const applicants = this._applicant.applicantsQueue;
    const applicant = applicants.find(
      (applicantItem) =>
        applicantItem.key == this.applicants[this.indexApplicant].key
    );
    applicant.curriculum = this.applicants[this.indexApplicant].curriculum;

    this._applicant.applicantsQueue = applicants;

    this.file = null;

    await this.getPDFUrl(this.applicants[this.indexApplicant].curriculum);
    this.SpinnerService.hide();
  }

  async sendUpdateCV() {
    await this._applicant.sendUpdateCvEmail(
      this.applicantKey,
      this.applicant.email
    );
    this._log.addApplicant(this.applicantKey, {
      description: `Se solicitó el CV`
    });
    AlertService.toastSuccess('Se ha solicitado el CV correctamente');
  }

  handleZoom(zoomFactor: number) {
    this.zoom += zoomFactor;
  }

  resetZoom() {
    this.zoom = 1;
  }
}
