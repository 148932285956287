<mat-tab-group>
  <mat-tab label="Usuarios">
    <app-datatable [rows$]="usersFiltered$"
                   [config]="usersConfig"
                   (rowSelected)="goToUserDetails($event.key)">

      <app-datatable-column title="Usuario">
        <ng-template let-row #column>
          {{row.name}} {{row.surnames}}
          <div class="badge badge-dark" *ngIf="row.isDisable">Deshabilitado</div>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Correo electrónico">
        <ng-template let-row #column>
          {{ row.email | lowercase }}
          <div class="badge badge-dark" *ngIf="row.isDisable">Deshabilitado</div>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Última conexión">
        <ng-template let-row #column>
          {{!!row.lastAccess ? (row?.lastAccess | date : 'dd/MM/yyyy HH:mm') : 'Aún no registrada'}}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Acceso hasta">
        <ng-template let-row #column>
          <ng-container *ngIf="row.isApplicant">
            {{row?.applicant?.finalAccessDate | date : 'dd/MM/yyyy'}}
          </ng-container>

          <ng-container *ngIf="!row.isApplicant">
            No aplica
          </ng-container>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="" [isClickable]="false" [isVisiblePrint]="false">
        <ng-template let-row #column>
          <button *ngIf="row.isApplicant || row.isApplicant == false" class="btn btn-warning btn-sm" (click)="goToApplicantDetail(row)" ngbTooltip="Ir al perfil de postulante"><i class="feather ft-users"></i></button>
          <button *ngIf="row.isApplicant" class="btn btn-success btn-sm" (click)="changeToInternalUser(row)" ngbTooltip="Convertir a usuario interno"><i class="feather ft-user-plus"></i></button>
          <button class="btn btn-danger btn-sm btn-padding" ngbTooltip="Eliminar" (click)="deleteUser(row)"><i class="fa fa-trash"></i></button>
          <button class="btn btn-primary password-color btn-sm btn-padding" ngbTooltip="Cambiar contraseña" (click)="askToResetPassword(row)"><i class="fa fa-key"></i></button>
        </ng-template>
      </app-datatable-column>
    </app-datatable>
  </mat-tab>

  <mat-tab label="Usuarios admitidos">
    <app-datatable [rows$]="whitelist$"
                  [config]="whitelistConfig">
      <app-datatable-column title="Usuario">
        <ng-template let-row #column>
          {{ row.email | lowercase}}
          <div class="badge badge-dark" *ngIf="row.isRegistered">No registrado</div>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="">
        <ng-template let-row #column>
          <button class="btn btn-danger" (click)="deleteFromWhiteList(row.key)">Eliminar</button>
        </ng-template>
      </app-datatable-column>


    </app-datatable>
  </mat-tab>
</mat-tab-group>



<app-add-float-button (clickEmmiter)="openAddWhitelist()"></app-add-float-button>

<button class="btn btn-info btn-float-right send-notification-btn"
        app-tooltip="Enviar notificación"
        (click)="openSendNotificationModal()">
  <i class="feather ft-message-square"></i>
</button>
