<div class="modal-header">
  <h4 class="modal-title pull-left">Tablero{{userType != userTypeEnum.ADMIN ? ' personal' : ''}}</h4>
  <button type="button" class="pull-right btn" (click)="modal.hide()">
    <i class="feather ft-x"></i>
  </button>
</div>
<div class="container">
  <form [formGroup]="boardForm">
    <div class="form-group mt-2">
      <label>Nombre</label>
      <input type="text" class="form-control"
             formControlName="name"
             [ngClass]="{ 'is-invalid': submitted && formControls.name.errors }">
      <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.name.errors">
        Campo obligatorio
      </small>
    </div>
    <div class="form-group mt-2">
      <label>Descripción</label>
      <textarea class="form-control"
                formControlName="description"></textarea>
    </div>
    <div *ngIf="userType == userTypeEnum.ADMIN">
      <div class="col-md-6 pl-0 mb-1">
        <label>Seleccione el tipo de tablero</label>
        <mat-radio-group formControlName="type">
          <mat-radio-button class="mr-1" [value]="boardTypeEnum.TEAM">Equipo</mat-radio-button>
          <mat-radio-button [value]="boardTypeEnum.PERSONAL">Personal</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col-md-6" *ngIf="!boardForm.value.type || boardForm.value.type == boardTypeEnum.TEAM">
        <mat-form-field class="w-100 mr-1">
          <mat-label>Asignar administradores</mat-label>
          <mat-select
            formControlName="admins"
            multiple
            [compareWith]="compareUsers"
            [disabled]="userType != userTypeEnum.ADMIN">
            <mat-select-trigger>
              <ul class="list-unstyled users-list m-0">
                <li class="avatar avatar-sm pull-up" *ngFor="let admin of boardForm.value.admins">
                  <img class="media-object rounded-circle avatar-img"
                       container="body"
                       [ngbTooltip]="admin?.name"
                       [src]="!!admin.imageUrl ? admin.imageUrl : '/assets/images/default.png'"
                       alt="Foto de supervisor">
                </li>
              </ul>
            </mat-select-trigger>
            <mat-option *ngFor="let admin of admins; let i = index"
                        [value]="admin">
              <img src="{{!!admin.imageUrl ? admin.imageUrl : '/assets/images/default.png'}}"
                   class="avatar-img rounded-circle"
                   alt="Foto de administrador"><span class="ml-1">{{admin?.name}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-6" *ngIf="!boardForm.value.type || boardForm.value.type == boardTypeEnum.TEAM">
        <mat-form-field class="w-100 mr-1">
          <mat-label>Asignar supervisores</mat-label>
          <mat-select
            formControlName="supervisors"
            multiple [compareWith]="compareUsers"
            [disabled]="userType != userTypeEnum.ADMIN">
            <mat-select-trigger>
              <ul class="list-unstyled users-list m-0">
                <li class="avatar avatar-sm pull-up" *ngFor="let supervisor of boardForm.value.supervisors">
                  <img class="media-object rounded-circle avatar-img"
                       container="body"
                       [ngbTooltip]="supervisor?.name"
                       [src]="!!supervisor.imageUrl ? supervisor.imageUrl : '/assets/images/default.png'"
                       alt="Foto de supervisor">
                </li>
              </ul>
            </mat-select-trigger>
            <mat-option *ngFor="let supervisor of supervisors; let i = index"
                        [value]="supervisor">
              <img src="{{!!supervisor.imageUrl ? supervisor.imageUrl : '/assets/images/default.png'}}"
                   class="avatar-img rounded-circle"
                   alt="Foto de supervisor"><span class="ml-1">{{supervisor?.name}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-6" *ngIf="!boardForm.value.type || boardForm.value.type == boardTypeEnum.TEAM">
        <mat-form-field class="w-100">
          <mat-label>Asignar usuarios</mat-label>
          <mat-select
            formControlName="users"
            multiple [compareWith]="compareUsers"
            [disabled]="userType != userTypeEnum.ADMIN && userType != userTypeEnum.SUPERVISOR">
            <mat-select-trigger>
              <ul class="list-unstyled users-list m-0">
                <li class="avatar avatar-sm pull-up" *ngFor="let user of boardForm.value.users">
                  <img class="media-object rounded-circle avatar-img"
                       container="body"
                       [ngbTooltip]="user?.name"
                       [src]="!!user.imageUrl ? user.imageUrl : '/assets/images/default.png'" alt="Foto de usuario">
                </li>
              </ul>
            </mat-select-trigger>
            <mat-option *ngFor="let user of users; let i = index"
                        [value]="user">
              <img src="{{!!user.imageUrl ? user.imageUrl : '/assets/images/default.png'}}"
                   class="avatar-img rounded-circle"
                   alt="Foto de usuario"><span class="ml-1">{{user?.name}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="mt-2 form-group">
      <app-upload-file-storage label="Imagen del tablero"
                               extension="png"
                               controlName="imageUrl"
                               fileType="image/*"
                               [showPreview]="true"
                               (isImageLoaded)="isImageLoaded = $event">
      </app-upload-file-storage>
    </div>
    <div class="mt-2" *ngIf="!!board.imageUrl && !isImageLoaded">
      <img class="w-100" src="{{board?.imageUrl}}" alt="Imagen del tablero" *ngIf="!!board.imageUrl">
    </div>
    <div class="mt-2 mb-2">
      <div class="d-flex justify-content-end">
        <button class="btn btn-outline-danger mr-1" (click)="modal.hide()">Cerrar</button>
        <button class="btn btn-success"
                (click)="submit()">{{!!board.name ? 'Guardar' : 'Agregar'}}</button>
      </div>
    </div>
  </form>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
             type="square-jelly-box"><p style="color: white"> Guardando tablero... </p></ngx-spinner>
