<div class="content-body" *ngIf="project$ | async as project">
  <div class="row">
    <div class="col-12 mb-1">
      <div class="row">
        <div class="col-3">
          <h3 class="card-title">Nombre: {{project?.name}}</h3>
        </div>
        <div class="col-9 text-right d-flex justify-content-end" *ngIf="permission == permissionEnum.ADMIN || permission == permissionEnum.SUPERVISOR">
          <div class="btn-group float-right ml-2">
            <div ngbDropdown class="d-inline-block">
              <button class="btn btn-warning dropdown-toggle"
                      ngbDropdownToggle>Configuración
              </button>
              <div ngbDropdownMenu class="open-left">
                <button class="dropdown-item" (click)="deleteProject(project)">Eliminar proyecto</button>
                <button class="dropdown-item" (click)="modifyProject(project)">Editar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <mat-tab-group [(selectedIndex)]="tabIndex">
    <mat-tab label="Usuarios">
      <app-persons [projectKey]="projectKey"></app-persons>
    </mat-tab>
    <mat-tab label="Tickets">
      <app-tickets [projectKey]="projectKey"
                   [isProject]="true"
                   [unknownWarehouse]="true"
                   [articleReferences]="articleReferences"></app-tickets>
    </mat-tab>
    <ng-container *ngIf="permission == permissionEnum.SUPERVISOR || permission == permissionEnum.ADMIN">
      <mat-tab label="Presupuestos">
        <app-budget [project]="project"></app-budget>
      </mat-tab>

      <mat-tab label="Registros">
        <app-logs [logs$]="logs$"></app-logs>
      </mat-tab>
    </ng-container>
    <mat-tab label="Facturas">
      <app-project-bills [projectKey]="projectKey"></app-project-bills>
    </mat-tab>
    <mat-tab label="Compras">
      <div class="mt-1">
        <app-purchase-orders [projectKey]="projectKey"></app-purchase-orders>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<app-add-float-button (clickEmmiter)="openModalAddPerson()"
                      [@zoomInOnEnter]
                      [@zoomOutOnLeave]
                      *ngIf="tabIndex == 0"></app-add-float-button>
