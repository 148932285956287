import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ValidationService} from '../../../shared/template-services/validation.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {Client} from '../../interfaces/client';
import {AngularFirestore} from '@angular/fire/firestore';
import {ClientService} from '../../services/client.service';
import {UploadFileStorageComponent} from '../../../shared/template-components/upload-file-storage/upload-file-storage.component';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-client-modal',
  templateUrl: './client-modal.component.html',
  styleUrls: ['./client-modal.component.css']
})
export class ClientModalComponent implements OnInit {
  @ViewChild(UploadFileStorageComponent)
  filesStorage: UploadFileStorageComponent;
  client: Client = {key: ''} as Client;
  contactDataForm: FormGroup;
  fileDataForm: FormGroup;
  isImageLoaded: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public modal: BsModalRef,
    private _validation: ValidationService,
    private afs: AngularFirestore,
    private _client: ClientService,
    private spinner: NgxSpinnerService
  ) {
    this.createContactDataForm();
    this.createFileDataForm();
  }

  ngOnInit() {
    if (!!this.client.name) {
      this.contactDataForm.patchValue({
        nameContact: this.client.nameContact,
        positionContact: this.client.positionContact,
        phoneContact: this.client.phoneContact,
        emailContact: this.client.emailContact,
        communeContact: this.client.communeContact
      });

      this.fileDataForm.patchValue({
        rut: this.client.rut,
        name: this.client.name,
        address: this.client.address,
        region: this.client.region,
        logoUrl: this.client.logoUrl,
        trash: this.client.trash
      });
    } else {
      this.client.key = this.afs.createId();
    }
  }

  get contactDataFormControls() {
    return this.contactDataForm.controls;
  }

  get fileDataFormControls() {
    return this.fileDataForm.controls;
  }

  createContactDataForm() {
    this.contactDataForm = this.formBuilder.group({
      nameContact: ['', Validators.required],
      positionContact: ['', Validators.required],
      phoneContact: [null, Validators.required],
      emailContact: ['', Validators.required],
      communeContact: ['', Validators.required]
    });
  }

  createFileDataForm() {
    this.fileDataForm = this.formBuilder.group({
      rut: ['', [Validators.required, this._validation.validateRut]],
      name: ['', Validators.required],
      address: ['', Validators.required],
      region: ['', Validators.required],
      logoUrl: ['']
    });
  }

  async submit() {
    if (!this.fileDataForm.valid) return;
    if (
      !(await AlertService.confirm(
        '¿Estás seguro de que deseas guardar estos datos?'
      ))
    )
      return;
    this.spinner.show();
    if (this.isImageLoaded) {
      const url = await this.filesStorage.uploadDocument(
        'clients',
        this.client.key
      );
      if (!!url) {
        this.fileDataForm.patchValue({[this.filesStorage.controlName]: url});
      }
    }
    let client = {
      ...this.contactDataForm.value,
      ...this.fileDataForm.value,
      trash: false
    };
    await this._client.set(client, this.client.key);
    AlertService.toastSuccess('Los datos se guardaron correctamente');
    this.modal.hide();
  }
}
