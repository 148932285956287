import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AccountabilityReceipt} from '../../interfaces/accountability-receipt';
import {AccountabilityReceiptService} from '../../services/accountability-receipt.service';
import {BreakdownAmountCategory} from '../../interfaces/breakdown-amount-catengory';
import {UploadFileStorageComponent} from '../../../../../shared/template-components/upload-file-storage/upload-file-storage.component';
import {AlertService} from '../../../../../shared/template-services/alert.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {DocumentReference} from '@angular/fire/firestore';
import {UserType} from '../../../../enums/user-type.enum';
import {UserService} from 'src/app/shared/services/user.service';
import {AccountabilityReceiptStatusLabel} from '../../labels/accountability-receipt-status.label';
import {AccountabilityReceiptStatusEnum} from '../../enums/accountability-receipt-status.enum';
import {AccountabilityStatusEnum} from '../../enums/accountability-status.enum';
import {Receipt} from '../../interfaces/receipt';

@Component({
  selector: 'app-accountability-receipt-modal',
  templateUrl: './accountability-receipt-modal.component.html',
  styleUrls: ['./accountability-receipt-modal.component.css']
})
export class AccountabilityReceiptModalComponent implements OnInit {
  @ViewChild(UploadFileStorageComponent)
  filesStorage: UploadFileStorageComponent;
  isEdit: boolean;
  receipt: Receipt;
  receiptForm: FormGroup;
  submitted: boolean;
  categories: BreakdownAmountCategory[] = [];
  isImageLoaded: boolean;
  receiptKey: string;
  accountabilityKey: string;
  permission: any;
  permissionEnum = UserType;
  accountabilityStatus = AccountabilityStatusEnum;
  accountabilityReceiptStatus = AccountabilityReceiptStatusEnum;
  accountabilityReceiptStatusLabel = AccountabilityReceiptStatusLabel;

  constructor(
    public modal: BsModalRef,
    private _accountabilityReceipt: AccountabilityReceiptService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private _user: UserService
  ) {
    this.createReceiptForm();
  }

  ngOnInit(): void {
    this.permission = this._user.getPermission('FONDOS POR RENDIR');
    if (this.isEdit) {
      this.receiptKey = this.receipt.key;
      delete this.receipt['key'];

      this.receiptForm.patchValue(this.receipt);

      this.receiptForm.patchValue({
        category: this.categories.find(
          (category) =>
            category.key ==
            ((<DocumentReference>this.receipt.category).id ??
              (<any>this.receipt.category).key)
        )
      });
    }
    if (
      this.permission == this.permissionEnum.SUPERVISOR ||
      this.permission == this.permissionEnum.ADMIN ||
      (this.permission == this.permissionEnum.USER &&
        this.isEdit &&
        this.receipt.isUsed &&
        this.receipt.status != this.accountabilityReceiptStatus.REJECTED)
    ) {
      this.receiptForm.disable();
    }
  }

  get formControls() {
    return this.receiptForm.controls;
  }

  createReceiptForm() {
    this.receiptForm = this.formBuilder.group({
      amount: [0, Validators.required],
      category: [null, Validators.required],
      description: ['', Validators.required],
      photoUrl: [''],
      status: [this.accountabilityReceiptStatus.PENDING]
    });
  }

  async submit() {
    this.submitted = true;

    if (!this.receiptForm.valid) {
      return;
    }

    if (!this.isEdit && !this.isImageLoaded) {
      return AlertService.toastError(
        'Es necesario subir una foto del comprobante'
      );
    }

    if (
      !(await AlertService.confirm(
        `Guardar datos`,
        '¿Está seguro de que desea guardar los datos?',
        'Guardar'
      ))
    ) {
      return;
    }

    this.spinner.show();

    this.receiptForm.patchValue({
      category: this.getCategoryReference(this.receiptForm.value.category)
    });

    if (this.isImageLoaded) {
      await this.uploadImage();
    }

    if (this.isEdit) {
      this._accountabilityReceipt.updateReceipt(
        this.accountabilityKey,
        this.receiptKey,
        this.receiptForm.value
      );
    } else {
      this._accountabilityReceipt.add(
        this.accountabilityKey,
        this.receiptForm.value
      );
    }

    AlertService.toastSuccess(`Los datos se han guardado correctamente`);
    this.spinner.hide();
    this.modal.hide();
  }

  setIsImageLoaded() {
    this.isImageLoaded = true;
  }

  getCategoryReference(category) {
    return this._accountabilityReceipt.getReference(
      `breakdownAmountCategories/${category.key}`
    );
  }

  async uploadImage() {
    const url = await this.filesStorage.uploadDocument(
      'accountability-receipts',
      new Date().getTime()
    );
    if (!!url) {
      this.receiptForm.patchValue({[this.filesStorage.controlName]: url});
    }
  }

  async acceptReceipt() {
    if (
      !(await AlertService.confirm(
        'Aceptar comprobante',
        `¿Estás seguro de aceptar este comprobante?`
      ))
    )
      return;
    this._accountabilityReceipt.updateReceipt(
      this.accountabilityKey,
      this.receiptKey,
      {
        status: AccountabilityReceiptStatusEnum.ACCEPTED
      } as AccountabilityReceipt
    );
    AlertService.toastSuccess(`El comprobante ha sido aceptado correctamente`);
    this.modal.hide();
  }

  async rejectReceipt() {
    if (
      !(await AlertService.confirm(
        'Rechazar comprobante',
        `¿Estás seguro de rechazar este comprobante?`
      ))
    )
      return;
    this._accountabilityReceipt.updateReceipt(
      this.accountabilityKey,
      this.receiptKey,
      {
        status: AccountabilityReceiptStatusEnum.REJECTED
      } as AccountabilityReceipt
    );
    AlertService.toastSuccess(`El comprobante ha sido rechazado correctamente`);
    this.modal.hide();
  }

  isPosibleEditImage() {
    return (
      this.permission == this.permissionEnum.USER &&
      (!this.isEdit ||
        (this.isEdit &&
          (!this.receipt.isUsed ||
            this.receipt.status == this.accountabilityReceiptStatus.REJECTED)))
    );
  }
}
