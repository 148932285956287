import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import moment from 'moment';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Subscription} from 'rxjs';
import {UserService} from 'src/app/shared/services/user.service';
import {AlertService} from 'src/app/shared/template-services/alert.service';
import {User} from '../../../shared/interfaces/user';
import {SaleStatusEnum} from '../../enums/sale-status.enum';
import {Client} from '../../interfaces/client';
import {ClientService} from '../../services/client.service';
import {SaleService} from '../../services/sale.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {UserType} from '../../enums/user-type.enum';

declare const $;

@Component({
  selector: 'app-add-sale-modal',
  templateUrl: './add-sale-modal.component.html',
  styleUrls: ['./add-sale-modal.component.css'],
})
export class AddSaleModalComponent implements OnInit, OnDestroy {
  salesBoardKey: string;
  saleForm: FormGroup;
  users: User[] = [];
  clientsSubscription: Subscription = new Subscription();
  clients: Client[] = [];
  submitted: boolean;
  editor = ClassicEditor;
  userPermission;
  userTypeEnum = UserType;
  openAddclientModal: () => void;
  validateUserPermission: (permissionExpected) => boolean;

  config = {
    toolbar: [
      'bold',
      'italic',
      'link',
      'undo',
      'redo',
      'numberedList',
      'bulletedList',
      '|',
      'insertTable',
    ],
  };

  constructor(
    private formBuilder: FormBuilder,
    public modalRef: BsModalRef,
    private modal: BsModalService,
    private _client: ClientService,
    private _user: UserService,
    private _sale: SaleService
  ) {
    this.createSaleForm();
  }

  async ngOnInit() {
    if (this.validateUserPermission(this.userTypeEnum.USER)) {
      this.saleForm.patchValue({
        users: [this.users.find((user) => user.key == this._user.user.key)],
      });
    }
    this.clientsSubscription = this._client.getAll().subscribe((clients) => {
      this.clients = clients;
    });
  }

  ngOnDestroy(): void {
    this.clientsSubscription.unsubscribe();
  }

  get formControls() {
    return this.saleForm.controls;
  }

  createSaleForm() {
    this.saleForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: [''],
      closeDate: [moment().format('YYYY-MM-DD')],
      saleValue: [1, [Validators.required, Validators.min(1)]],
      salesMarginPercentage: [1, [Validators.required, Validators.min(1)]],
      referenceCode: ['', Validators.required],
      users: [[], Validators.required],
      client: ['', Validators.required],
      links: [[]],
      status: [SaleStatusEnum.LEAFLET],
      trash: [false],
    });
  }

  compareUsers(user, currentUser) {
    return user.key == currentUser.key;
  }

  async submit() {
    this.submitted = true;
    if (!this.saleForm.valid) return;
    if (
      !(await AlertService.confirm(
        '¿Estás seguro de que deseas guardar estos datos?'
      ))
    )
      return;
    this.saleForm.patchValue({
      closeDate: moment(this.saleForm.value.closeDate).toDate().getTime(),
      users: this.getUsersReference(this.saleForm.value.users),
      client: this._client.getReference(this.saleForm.value.client.key),
    });
    this._sale.add(this.salesBoardKey, this.saleForm.value);
    AlertService.toastSuccess('Los datos se guardaron correctamente');
    this.modalRef.hide();
  }

  getUsersReference(users) {
    return users.map((user) => this._user.getReference(user.key));
  }

  async addLink() {
    let [title, link] = await AlertService.withHtml(
      'Nuevo enlace',
      `
        <label>Título</label>
        <input type="text" id="swal-input1" class="swal2-input form-control">
        <label>Enlace</label>
        <input type="text" id="swal-input2" class="swal2-input form-control">`,
      function () {
        return new Promise(function (resolve) {
          resolve([$('#swal-input1').val(), $('#swal-input2').val()]);
        });
      }
    );
    if (!title.trim() || !link.trim()) return;
    this.saleForm.patchValue({
      links: [
        ...this.saleForm.value.links,
        {
          title,
          link,
        },
      ],
    });
  }

  deleteLink(index: number) {
    let links = this.saleForm.value.links.filter((link, i) => i != index);
    this.saleForm.patchValue({links: links});
  }
}
