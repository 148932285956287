import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApplicantService} from '../../../shared/services/applicant.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {StatusService} from '../../../shared/services/status.service';

@Component({
  selector: 'app-change-status',
  templateUrl: './change-status.component.html',
  styleUrls: ['./change-status.component.css']
})
export class ChangeStatusComponent implements OnInit {
  statusForm: FormGroup;
  applicantKey: string;
  applicantStatus: any;
  statusSubscription: Subscription = new Subscription();
  selectArrayStatus: any = [];

  constructor(public modal: BsModalRef,
              private formBuilder: FormBuilder,
              private _applicant: ApplicantService,
              private _status: StatusService) {
    this.statusForm = this.formBuilder.group({
      status: ['', Validators.required],
    });
  }

  get formControls() {
    return this.statusForm.controls;
  }

  ngOnInit(): void {
    this.statusForm.patchValue({status: this.applicantStatus});
    this.getCategories();
  }

  getCategories() {
    this.statusSubscription = this._status.getAll()
      .pipe(
        map(status => status.map(status => ({
            name: status.name,
            key: status.key
          }
        )))
      )
      .subscribe(data => {
        this.selectArrayStatus = data;
      });
  }

  async submit() {
    if (this.statusForm.valid) {
      await this._applicant.updateStatus(this.applicantKey, this.statusForm.value.status);

      AlertService.toastSuccess('Se ha cambiado el status');
      this.modal.hide();
    }
  }
}
