<div class="p-2">
  <h2>Nuevo artículo</h2>
  <img class="size"
       src="{{!isValidPath() ? 'assets/images/img_default.png' : path}}">

  <form [formGroup]="articleForm" (ngSubmit)="submit()">
    <div class="row mt-2">
      <div class="col-md-4 col-12">
        <div class="form-group">
          <label>Nombre *</label>
          <input type="text" class="form-control" formControlName="name"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.name.errors }"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.name.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-4 col-12">
        <div class="form-group">
          <label>Referencia interna *</label>
          <input type="text" class="form-control" formControlName="internalId"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.internalId.errors }"/>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.internalId.errors">
            <div *ngIf="formControls.internalId.errors.required">
              Referencia interna es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-4 col-12">
        <div class="form-group">
          <label>Cantidad *</label>
          <input type="number"
                 class="form-control"
                 formControlName="quantity"
                 (change)="addQuantity($event)"
                 [ngClass]="{ 'is-invalid': submitted && formControls.quantity.errors }"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.quantity.errors">
            <div *ngIf=" formControls.quantity.errors.required">
              La cantidad es obligatoria
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-4 col-12">
        <div class="form-group">
          <label>Precio *</label>
          <input type="number" class="form-control" formControlName="price"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.price.errors }"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.price.errors">
            <div *ngIf="formControls.price.errors.required">
              El precio es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-4 col-12">
        <div class="form-group">
          <label>Ubicación *</label>
          <input type="text" class="form-control" formControlName="location"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.location.errors }"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.location.errors">
            <div *ngIf=" formControls.location.errors.required">
              La ubicación es obligatoria
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-4 col-12">
        <div class="form-group">
          <label>Imagen</label>
          <div class="custom-file">
            <input type="file" class="custom-file-input"
                   (change)="onFileChange($event)"
                   accept="image/*"
                   [ngClass]="{ 'is-invalid': submitted && formControls.imageUrl.errors }">
            <label class="custom-file-label" #labelImport>Elegir imagen</label>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-12">
        <div class="form-group">
          <label>Tipo *</label>
          <select class="form-control"
                  formControlName="type"
                  [ngClass]="{ 'is-invalid': submitted && formControls.type.errors }">
            <option [value]="type.key" *ngFor="let type of articleType | keyvalue">{{ type.value }}</option>
          </select>
        </div>
      </div>

      <div class="col-md-4 col-12">
        <div class="form-group">
          <label>Proveedor
            <div class="btn btn-success btn-sm" (click)="addProvider()">Agregar nueva</div>
          </label>
          <ng-select formControlName="provider" [items]="selectArrayProviders" [multiple]="false" bindLabel="name" appendTo="body"
                     placeholder="">
          </ng-select>
        </div>
      </div>

      <ng-container *ngIf="articleForm.get('type').value == articleTypeEnum.CONSUMABLE">
        <div class="col-md-4 col-12">
          <div class="form-group">
            <label>Stock mínimo</label>
            <input type="text" class="form-control" formControlName="minStock"
                   placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.minStock.errors }"/>
            <small class="form-text text-muted danger invalid-feedback"
                   *ngIf="submitted && formControls.minStock.errors">
              <div *ngIf=" formControls.minStock.errors.required">
                El stock mínimo es obligatorio
              </div>
            </small>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="form-group">
            <label>Stock máximo</label>
            <input type="text" class="form-control" formControlName="maxStock"
                   placeholder=""/>
          </div>
        </div>
      </ng-container>

      <div class="col-md-4 col-12">
        <div class="form-group">
          <label>Categorías *
            <div class="btn btn-success btn-sm" (click)="addCategory()">Agregar nueva</div>
          </label>
          <ng-select formControlName="categories" [items]="multipleSelectArray" [multiple]="true" bindLabel="name" appendTo="body"
                     placeholder="">
          </ng-select>
          <small class="form-text text-muted danger" *ngIf="submitted && formControls.categories.errors">
            <div>
              Necesita agregar mínimo una categoría
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-4 col-12">
        <div class="form-group">
          <label>Número de serie
            <div class="btn btn-success btn-sm" (click)="addSerialNumber()">Agregar</div>
          </label>
          <ng-select formControlName="serialNumber"
                     [items]="serialNumberSelect"
                     [multiple]="true"
                     [searchable]="false"
                     appendTo="body"
                     bindLabel="serialNumber"
                     notFoundText=""
                     [class.hide-arrow]="true"
                     placeholder="Selecciones los números seriales">
          </ng-select>
        </div>
      </div>

      <div class="col-md-5 col-12">
        <div class="form-group">
          <label>Unidades de medida *
            <div class="btn btn-success btn-sm" (click)="addUnitsMeasure()">Agregar nueva</div>
          </label>
          <ng-select formControlName="unitMeasure" [items]="selectArray" [searchable]="false" bindLabel="name" appendTo="body"
                     placeholder="">
          </ng-select>
          <small class="form-text text-muted danger" *ngIf="submitted && formControls.unitMeasure.errors">
            <div>
              Necesita agregar una unidad de medida
            </div>
          </small>
        </div>
      </div>

    </div>

    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn">Agregar</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide()">Cancelar</div>
    </div>
  </form>

</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
             type="square-jelly-box"><p style="color: white"> Agregando artículo... </p></ngx-spinner>
