<div class="row p-md-4 pl-0 overflow-x-scroll-hidden">
  <div class="col-12" *blockUI="'numberTabs'; message: 'Loading'">
    <ng-container mCardHeaderTitle *ngIf="!complete">
      <div class="w-100 d-flex">
        <div class="mt-lg-2 col-12">
          <div class="background-colsan row p-4 p-md-0">
            <div class="col-md-3 align-self-center">
              <img class="background-logo" src="assets/images/logo_sm.png">
            </div>
            <div class="col-md-9 font">
              <label
                class="row font-size-title sidebar-label mt-2 line-height-1 text-white justify-content-center text-center">Trabaja
                con nosotros</label><br>
              <h1 class="text-center"><label class="sidebar-label mt-1 font text-white">
                Gracias por tu interés en trabajar en Ingeniería y servicios COLSAN LTDA.
              </label></h1>
              <h2 class="text-center"><label class="sidebar-label font margin-top-negative text-white font-weight-bold">
                Recuerda colocar correctamente tus datos de contacto y tener a mano tu Curriculum para el último paso,
                sin éste tu postulación no será válida
              </label></h2>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container mCardBody>
      <div *ngIf="!complete" class="card-block bg-white">
        <aw-wizard #wizard navBarLayout="large-filled-symbols">
          <aw-wizard-step [stepTitle]="'Datos generales'" [navigationSymbol]="{ symbol: '1'}" >
            <app-general-register
              (eventProfilePhoto)="getProfilePhoto($event)"
              (eventChangeQuiz)="changeQuiz($event)"
              [formApplicantSubmitted]="formApplicantSubmitted"
            ></app-general-register>

            <div class="form-group text-center">
              <button type="button" class="btn btn-info btn-raised"
                      (click)="formApplicantSubmittedToTrue()">
                Siguiente
                <span style="margin-left:10px;"><i class="feather ft-chevron-right"></i></span>
              </button>
            </div>
          </aw-wizard-step>

          <aw-wizard-step [stepTitle]="'Cuestionario'" [navigationSymbol]="{ symbol: '2'}">
            <app-quiz (eventQuiz)="getQuiz($event)" [quizPosition]="quizPosition"></app-quiz>
            <div class="form-group text-center mt-2">
              <button type="button" class="btn btn-raised btn-danger mr-1" awPreviousStep>
                  <span style="margin-right:10px;">
                    <i class="feather ft-chevron-left"></i>
                  </span> Anterior
              </button>
              <button type="button" class="btn btn-info btn-raised" awNextStep>
                Siguiente
                <span style="margin-left:10px;"><i class="feather ft-chevron-right"></i></span>
              </button>
            </div>
          </aw-wizard-step>

          <aw-wizard-step [stepTitle]="'Subir archivos'" [navigationSymbol]="{ symbol: '3'}">
            <app-curriculum (eventDocuments)="getDocuments($event)" [applicantForm]="applicantForm"
                            [formCurriculumSubmitted]="formCurriculumSubmitted"></app-curriculum>
            <div class="form-group text-center mt-2">
              <button type="button" class="btn btn-raised btn-danger mr-1" awPreviousStep>
                  <span style="margin-right:10px;">
                    <i class="feather ft-chevron-left"></i>
                  </span> Anterior
              </button>
              <button *ngIf="documents" (click)="submitApplicant()" type="button" class="btn btn-success btn-raised"
                      awNextStep>
                Finalizar
                <span style="margin-left:10px;"><i class="feather ft-chevron-right"></i></span>
              </button>
              <button *ngIf="!documents" type="button" class="btn btn-success btn-raised"
                      (click)="formCurriculumSubmittedToTrue()">
                Finalizar
                <span style="margin-left:10px;"><i class="feather ft-chevron-right"></i></span>
              </button>
            </div>
          </aw-wizard-step>
        </aw-wizard>
      </div>

      <div *ngIf="complete" class="card-block">
        <div class="text-center">
          <div class="col-md-12 text-center text-sm-left">
            <div class="media d-flex align-items-center flex-column justify-content-center">
              <img src="/assets/images/logo.png" class="logoSize" alt="company logo" />
              <div class="media-body ml-4">
                <h2 class="text-bold-800">Ingeniería y Servicios Colsan Ltda.</h2>
              </div>
            </div>
          </div>
          <h1><strong>{{completeMessage}}</strong>
          </h1>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
             type="square-jelly-box"><p style="color: white"> {{messageLoading}} </p></ngx-spinner>

