// IMPORTS
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreCollectionGroup,
  AngularFirestoreDocument,
  DocumentReference
} from '@angular/fire/firestore';
import { first, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { QueryFn } from '@angular/fire/firestore/interfaces';
import { AngularFireStorage } from '@angular/fire/storage';
import { AlertService } from './alert.service';

type  DocPredicate<T> = string | AngularFirestoreDocument<T>;
type  CollectionPredicate<T> = string | AngularFirestoreCollection<T>;

@Injectable({
  providedIn: 'root'
})
export class FirebaseDataService {

  constructor(public db: AngularFirestore,
    private storage: AngularFireStorage) {
  }

  col<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.db.collection<T>(ref, queryFn) : ref;
  }

  colGroup<T>(ref: string, queryFn?: any): AngularFirestoreCollectionGroup<T> {
    return this.db.collectionGroup<T>(ref, queryFn);
  }

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.db.doc<T>(ref) : ref;
  }

  doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(map(doc => {
      const data = doc.payload.data() as T;
      if (data == null) {
        return data;
      }
      data['key'] = doc.payload.id;
      return data;
    }));
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(
      map(docs => docs.map(a => a.payload.doc.data()) as T[])
    );
  }

  colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(
      map(docs => docs.map((a: any) => {
        const data = a.payload.doc.data();
        if (data == null) return data;
        data['key'] = a.payload.doc.id;
        return data;
      }) as T[])
    );
  }

  colStateWithIds$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<T[]> {
    return this.col(ref, queryFn).stateChanges(['modified', 'added']).pipe(
      map(docs => docs.map((a: any) => {
        const data = a.payload.doc.data();
        if (data == null) {
          return data;
        }
        data['key'] = a.payload.doc.id;
        return data;
      }) as T[])
    );
  }

  colGroupWithIds$<T>(ref: string, queryFn?: QueryFn): Observable<T[]> {
    return this.colGroup(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map(docs => docs.map((a: any) => {
          const data = a.payload.doc.data();
          data['key'] = a.payload.doc.id;
          data['path'] = a.payload.doc.ref.path;
          return data;
        }) as T[])
      );
  }

  docWithId$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(map(doc => {
      const data = doc.payload.data() as T;
      if (!data) return data;
      data['key'] = doc.payload.id;
      return data;
    }));
  }

  async getByPages<T>({ refCol, queryFn }: { refCol: CollectionPredicate<T>, queryFn?: QueryFn },
    orderBy: string,
    pageSize: number = 20,
    next?) {
    let items = [];

    if (!!next) {
      try {
        items = await next.pipe(first()).toPromise();
      } catch (e) {
        return {
          data: [],
          next,
          isLast: true
        };
      }
    } else {
      items = await this.col(refCol, ref => queryFn(ref)
        .orderBy(orderBy, 'asc')
        .limit(pageSize))
        .snapshotChanges().pipe(
          map(docs => docs.map((a: any) => {
            const data = a.payload.doc.data();
            if (data == null) return data;
            data['key'] = a.payload.doc.id;
            data['doc'] = a.payload.doc;
            return data;
          }) as T[])
        )
        .pipe(first())
        .toPromise();
    }

    const lastVisible = items[items.length - 1].doc;
    next = this.col('units', ref => queryFn(ref)
      .orderBy(orderBy, 'asc')
      .startAfter(lastVisible)
      .limit(pageSize))
      .snapshotChanges().pipe(
        map(docs => docs.map((a: any) => {
          const data = a.payload.doc.data();
          if (data == null) return data;
          data['key'] = a.payload.doc.id;
          data['doc'] = a.payload.doc;
          return data;
        }) as T[])
      );

    return {
      data: items,
      next,
      isLast: items.length < pageSize
    };
  }

  getReference(url: string): DocumentReference {
    return this.db.doc(url).ref;
  }

  createID() {
    return this.db.createId();
  }

  async uploadFile(file, key: string, nameFile: string, extension: string) {
    const uploadRef = this.getStorageRefFile(key, nameFile, extension);
    await uploadRef.put(file);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadFileStorage(file, key, nameFile, extension);

    return url;
  }

  private getStorageRefFile(id: string, nameFile: string, extension) {
    return this.storage.ref(`purchase-order/${id}/${nameFile}.${extension}`);
  }

  private uploadFileStorage(data, id, nameFile: string, extension) {
    return this.storage.upload(`medics/${id}/${nameFile}.${extension}`, data);
  }

  update(route: string, data: any) {
    for (let property in data) {
      if (data[property] === undefined) {
        console.error(`La propiedad "${property}" llegó undefined al intentar actualizar el documento "${route}"`);
        AlertService.error(`La propiedad "${property}" llegó undefined al intentar actualizar el documento "${route}"`);
      }
    }
    return this.db.doc(route).update(data);
  }

  async populateSubObjectField(items: any[], field: string, subField: string) {
    return await Promise.all(items.map(async item => {
      try {
        if (item[field][subField]) {
          item[field][subField] = await this.docWithId$(item[field][subField].path).pipe(first()).toPromise();
        }
      } catch (e) {
        console.error(`Hubo un error al obtener el documento del campo ${field.toUpperCase()}`, e);
      }
      return item;
    }));
  }

  async populate(items: any[], fields: string[], subFields: string[] = []) {
    return await Promise.all(items.map(async item => {
      for (let field of fields) {
        if (item[field] && item[field].path) {
          try {
            item[field] = await this.docWithId$(item[field].path).pipe(first()).toPromise();

            for (let subField of subFields) {
              if (item[field][subField]) {
                item[field][subField] = await this.docWithId$(item[field][subField].path).pipe(first()).toPromise();
              }
            }
          } catch (e) {
            console.error(`Hubo un error al obtener el documento del campo ${field.toUpperCase()}`, e);
          }
        }
      }
      return item;
    }));
  }

  async populateArrayField(items: any[], fields: string[]) {
    return await Promise.all(items.map(async item => {
      for (let field of fields) {
        if (item[field]) {
          try {
            item[field] = await Promise.all(item[field].map(async (itemField: any) => {
              return await this.docWithId$(itemField.path).pipe(first()).toPromise();
            }));
            item[field] = item[field].filter(item => !!item);
          } catch (e) {
            console.error(`Hubo un error al obtener el documento del campo ${field.toUpperCase()}`, e);
          }
        }
      }
      return item;
    }));
  }
}
