<div class="form-group">
  <label>{{label}}</label>
  <div class="custom-file">
    <input type="file" class="custom-file-input" #inputFile
           (change)="onFileChange($event)"
           [accept]="fileType"
           [ngClass]="{ 'is-invalid': submitted && !isInputValid }">
    <label class="custom-file-label" #labelImport>Elegir {{label}}</label>
    <small class="form-text text-muted danger" *ngIf="!isInputValid && submitted">
      <div>
        {{errorLabel}}
      </div>
    </small>
  </div>
</div>

<img [src]="path" alt="" class="w-100" *ngIf="showPreview && !!path">
