import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApplicantService} from '../../../shared/services/applicant.service';
import {AuthService} from '../../../shared/template-services/auth.service';
import {Applicant} from '../../interfaces/applicant';
import {UserService} from '../../../shared/services/user.service';
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-send-account',
  templateUrl: './send-account.component.html',
  styleUrls: ['./send-account.component.css']
})
export class SendAccountComponent implements OnInit {
  accountForm: FormGroup;
  submitted: boolean = false;
  applicantKey: string;
  emailUser: string;
  applicant: Applicant;

  constructor(public modal: BsModalRef,
              private formBuilder: FormBuilder,
              private _applicant: ApplicantService,
              private _auth: AuthService,
              private _user: UserService) {
    this.accountForm = formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get formControls() {
    return this.accountForm.controls;
  }

  ngOnInit(): void {
  }

  async submit() {
    this.submitted = true;
    if (this.accountForm.valid) {
      this._auth.addToWhitelist(this.accountForm.value.email);
      this._applicant.sendAccountEmail(this.accountForm.value.email, this.accountForm.value.password, this.emailUser);

      let user = {
        rut: this.applicant.rut,
        name: this.applicant.name,
        surnames: this.applicant.surnames,
        city: this.applicant.city,
        job: this.applicant.job,
        status: this.applicant.status,
        birthday: this.applicant.birthday,
        age: this.applicant.age,
        workExperience: this.applicant.workExperience,
        educationLevel: this.applicant.educationLevel,
        email: this.applicant.email,
        phone: this.applicant.phone,
        nationality: this.applicant.nationality,
        experience: this.applicant.experience,
        expectation: this.applicant.expectation,
        imageUrl: this.applicant.imageUrl,
        quiz: this.applicant.quiz,
        curriculum: this.applicant.curriculum,
        isApplicant: true,
        firstLogin: true,
        trash: false,
        isDisable: false,
        permissions: []
      }

      const resp: any = await this._user.createAccount(this.accountForm.value.email, this.accountForm.value.password);
      if (!resp.ok) return AlertService.error(AuthService.getMessageError(resp.error.code))

      this._user.addUserByKey(resp.uid, user);
      this.modal.hide();
    }
  }

}
