import { Injectable } from '@angular/core';
import { FirebaseDataService } from '../../../../shared/template-services/firebase-data.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ContractService extends FirebaseDataService {

  constructor(private _db: AngularFirestore) {
    super(_db, null);
  }

  getAll(applicantKey: string) {
    return this.colWithIds$(`applicants/${applicantKey}/contracts`, ref => ref.where('trash', '==', false));
  }

  add(applicantKey: string, contract) {
    return this.db.collection(`applicants/${applicantKey}/contracts`).add({
      ...contract,
      trash: false,
      createdAt: Date.now()
    });
  }

  updateContract(applicantKey: string, contractKey: string, contract) {
    return this.db.doc(`applicants/${applicantKey}/contracts/${contractKey}`).update({
      ...contract,
      updatedAt: Date.now()
    });
  }
}
