<ng-container *ngIf="!load">
  <div class="mt-2 ml-2">
    <div class="btn btn-danger" (click)="modal.hide()">Cerrar</div>
  </div>

  <app-datatable [rows$]="payments$"
                 [config]="config"
                 (rowSelected)="openPaymentEdit($event)">

    <app-datatable-column title="Categoría">
      <ng-template let-row #column>
        <label *ngIf="!!row.deliveryDateChanged && row.deliveryDateChanged"><i class="feather ft-alert-triangle"></i></label>
        {{getCategory(row?.category)}}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Pago">
      <ng-template let-row #column>
        {{row?.payment | clearCurrency | swapCommasAndDots}}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Fecha de pago">
      <ng-template let-row #column>
        {{row?.date | date : 'dd/MM/yyyy'}}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Fecha de creación">
      <ng-template let-row #column>
        {{row?.createdAt | date : 'dd/MM/yyyy HH:mm'}}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="">
      <ng-template let-row #column>
      <span class="badge badge-pill"
            [ngClass]="getPaidClass(row.paid)">{{row?.paid ? 'Pagado' : 'Sin pagar'}}</span>
      </ng-template>
    </app-datatable-column>

  </app-datatable>
</ng-container>
