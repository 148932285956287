<div #componentID *ngIf="warehouse">
  <section class="card border-0 shadow-none pt-5 pl-5 pr-5 pb-0">
    <div class="d-flex w-100 justify-content-center">
      <h2 class="text-center" [ngClass]="getStatusClass(ticket.status)">
        {{ ticketStatusLabel[ticket.status] }}
      </h2>
    </div>
    <div class="row">
      <div class="col-md-8 col-12">
        <div id="invoice-customer-details" class="row pt-2">
          <div class="col-md-6 col-sm-12 text-center text-md-left">
            <ul class="px-0 list-unstyled">
              <li class="text-bold-800">Bodega</li>
              <li class="text-bold-800">Código: {{ warehouse.code }}</li>
              <li>Ubicación: {{ warehouse.location }}</li>
              <li>Descripción: {{ warehouse.description }}</li>
              <li *ngIf="!!ticket.projects">
                Proyecto: {{ (ticket.projects | doc | async)?.name }}
              </li>
            </ul>
          </div>
          <div class="col-md-6 col-sm-12 text-center text-md-right">
            <p>
              <span class="text-muted">Fecha de creación:</span>
              {{ ticket.createdDate | date: 'dd/MM/yyyy HH:mm' }}
            </p>
            <p *ngIf="!!ticket.lastUpdate">
              <span class="text-muted">Última actualización:</span>
              {{ ticket.lastUpdate | date: 'dd/MM/yyyy HH:mm' }}
            </p>
            <p>
              <span class="text-muted" *ngIf="!!ticket.endDate"
                >Fecha de finalización : {{ ticket.endDate | date }}</span
              >
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-12 text-center text-md-right">
        <h2>TICKET</h2>
        <strong>
          <h2>
            <p class="pb-3">ID: {{ ticket?.ticketID | slice: 0:-3 }}<span class="text-muted font-size-xsmall">#{{ticket?.ticketID | slice:-3 }}</span></p>
          </h2>
          <p class="text-black-50">{{ ticket.key }}</p>
        </strong>
        <ul class="px-0 list-unstyled">
          <li>TOTAL</li>
          <li class="lead text-bold-800">
            {{ ticket.total | currency: 'USD':'symbol':'1.0-0' }}
          </li>
        </ul>
      </div>
    </div>

    <div class="row" *ngIf="ticket.status == TicketStatus.DRAFT">
      <div class="form-group col-4">
        <label>Proyecto *</label>
        <ng-select
          [items]="selectArrayProjects"
          [searchable]="true"
          bindLabel="name"
          [clearable]="false"
          [(ngModel)]="projectSelected"
          placeholder="Seleccionar proyecto"
          (change)="setProjectSelected($event)">
          <ng-template ng-option-tmp let-item="item">
            {{ item.name }}
          </ng-template>
        </ng-select>
      </div>

      <div class="form-group col-4">
        <label>Categoría *</label>
        <ng-select
          [items]="categories"
          [searchable]="true"
          bindLabel="name"
          [clearable]="false"
          placeholder="Seleccionar categoría"
          [(ngModel)]="projectCategorySelected"
          (change)="setCategorytSelected($event)">
          <ng-template ng-option-tmp let-item="item">
            <span *ngIf="!!item.name">{{ item.name }}</span>
            <span *ngIf="!item.name">{{
              (item.reference | doc | async)?.name
            }}</span>
          </ng-template>
        </ng-select>
      </div>

      <div class="form-group col-4">
        <label>Usuario *</label>
        <ng-select
          [items]="users"
          [searchable]="true"
          bindLabel="name"
          [clearable]="false"
          [(ngModel)]="userSelected"
          placeholder="Seleccionar un usuario"
          (change)="setUserSelected($event)">
          <ng-template ng-option-tmp let-item="item">
            {{ item.name }} {{ item.surnames }}
          </ng-template>

          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-label"
              >{{ item.name }} {{ item.surnames }}</span
            >
            <span class="ng-value-icon right" (click)="clear(item)">×</span>
          </ng-template>
        </ng-select>
      </div>
    </div>

    <div id="invoice-items-details" class="pt-2">
      <div class="row">
        <div class="table-responsive col-sm-12">
          <table class="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Artículo</th>
                <th>Ubicación</th>
                <th class="text-right">Cantidad</th>
                <th class="text-right">Precio</th>
                <th class="text-right">Total</th>
                <th
                  *ngIf="
                    ticket.status == TicketStatus.AUTHORIZED &&
                    (permission == permissionEnum.GROCER ||
                      permission == permissionEnum.ADMIN)
                  "
                  class="text-right">
                  Confirmar
                </th>
                <th
                  *ngIf="ticket.status == TicketStatus.DRAFT"
                  class="text-right">
                  Acción
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let article of ticket.articles; let index = index">
                <th scope="row">{{ index + 1 }}</th>
                <td>
                  <p>{{ article?.name }}</p>
                </td>
                <td>
                  <p>{{ article?.location }}</p>
                </td>
                <td
                  *ngIf="
                    ticket.status != TicketStatus.DRAFT &&
                    ticket.status != TicketStatus.COLLECTED &&
                    ticket.status != TicketStatus.GENERATED &&
                    ticket.status != TicketStatus.AUTHORIZED
                  "
                  class="text-right">
                  {{ article?.quantity }}
                  <span
                    *ngIf="
                      !!article.quantityOrdered &&
                      article.quantity != article.quantityOrdered
                    "
                    >(Solicitados {{ article.quantityOrdered }})</span
                  >
                  <span
                    *ngIf="
                      article?.returned >= 1 &&
                      ticket.status != TicketStatus.RETURNED
                    "
                    >(regresados {{ article?.returned }})</span
                  >
                </td>
                <td
                  *ngIf="
                    ticket.status == TicketStatus.DRAFT ||
                    ticket.status == TicketStatus.GENERATED ||
                    (ticket.status == TicketStatus.AUTHORIZED &&
                      (permission == permissionEnum.GROCER ||
                        permission == permissionEnum.ADMIN) &&
                      ticketAuthorizationReferences.length > 0 &&
                      ticket.status != TicketStatus.COLLECTED)
                  ">
                  <div class="form-inline float-right">
                    <div
                      class="form-group d-flex justify-content-end margin-right">
                      <input
                        #articleQuantity
                        type="number"
                        value="{{ articleQuantityEdit[index].quantity }}"
                        class="form-control input-quantity"
                        (change)="changeQuantityArray(index, articleQuantity)"
                        min="1" />
                    </div>
                    <div
                      class="btn btn-danger btn-sm"
                      (click)="messageArticles()"
                      *ngIf="ticket.status == TicketStatus.AUTHORIZED">
                      <i class="feather ft-alert-circle"></i>
                    </div>
                    <div
                      class="btn btn-danger btn-sm ml-1"
                      (click)="deleteArticle(article)"
                      *ngIf="ticket.status == TicketStatus.AUTHORIZED">
                      <i class="feather ft-trash"></i>
                    </div>
                  </div>
                </td>
                <td
                  *ngIf="
                    (ticket.status == TicketStatus.AUTHORIZED ||
                      ticket.status == TicketStatus.COLLECTED) &&
                    (permission == permissionEnum.GROCER ||
                      permission == permissionEnum.ADMIN) &&
                    !ticketAuthorizationReferences.length > 0
                  ">
                  <div class="form-inline float-right">
                    <div
                      class="form-group d-flex justify-content-end margin-right">
                      <label class="margin-right">{{
                        articleQuantityEdit[index].quantity
                      }}</label>
                      <span
                        *ngIf="
                          !!article.quantityOrdered &&
                          article.quantity != article.quantityOrdered
                        "
                        >(Solicitados {{ article.quantityOrdered }})</span
                      >
                      <span
                        *ngIf="
                          article?.returned >= 1 &&
                          ticket.status != TicketStatus.RETURNED
                        "
                        >(regresados {{ article?.returned }})</span
                      >
                    </div>
                    <div
                      class="btn btn-danger btn-sm ml-1"
                      (click)="messageArticles()"
                      *ngIf="ticket.status == TicketStatus.AUTHORIZED">
                      <i class="feather ft-alert-circle"></i>
                    </div>
                  </div>
                </td>
                <td
                  *ngIf="
                    ticket.status == TicketStatus.COLLECTED &&
                    ticketAuthorizationReferences.length > 0
                  ">
                  <div class="form-inline float-right">
                    <div
                      class="form-group d-flex justify-content-end margin-right">
                      <label class="margin-right">{{
                        articleQuantityEdit[index].quantity
                      }}</label>
                      <span
                        *ngIf="
                          !!article.quantityOrdered &&
                          article.quantity != article.quantityOrdered
                        "
                        >(Solicitados {{ article.quantityOrdered }})</span
                      >
                      <span
                        *ngIf="
                          article?.returned >= 1 &&
                          ticket.status != TicketStatus.RETURNED
                        "
                        >(regresados {{ article?.returned }})</span
                      >
                    </div>
                    <div
                      class="btn btn-danger btn-sm ml-1"
                      (click)="messageArticles()"
                      *ngIf="ticket.status == TicketStatus.AUTHORIZED">
                      <i class="feather ft-alert-circle"></i>
                    </div>
                  </div>
                </td>
                <td class="text-right">
                  {{ article?.price | currency: 'USD':'symbol':'1.0-0' }}
                </td>
                <td class="text-right">
                  {{ article['total'] | currency: 'USD':'symbol':'1.0-0' }}
                </td>
                <td
                  *ngIf="
                    ticket.status == TicketStatus.AUTHORIZED &&
                    (permission == permissionEnum.GROCER ||
                      permission == permissionEnum.ADMIN)
                  "
                  class="text-right">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="{{ index + 1 }}"
                      (change)="isConfirm(article, index)"
                      [(ngModel)]="ticket.articles[index].isConfirm" />
                    <label class="form-check-label" for="{{ index + 1 }}">
                      Confirmar
                    </label>
                  </div>
                </td>

                <td
                  class="text-right"
                  *ngIf="ticket.status == TicketStatus.DRAFT">
                  <div
                    class="btn btn-danger btn-sm"
                    (click)="dropArticle(index, article.key)">
                    <i class="feather ft-trash"></i>
                  </div>
                </td>
              </tr>
              <tr *ngIf="ticket.status == TicketStatus.DRAFT">
                <th scope="row"></th>
                <td>
                  <ng-select
                    [items]="articleReferences"
                    [searchable]="true"
                    bindLabel="name"
                    appendTo="body"
                    [(ngModel)]="article"
                    placeholder="Seleccionar artículo"
                    (change)="setArticleSelected($event)">
                    <ng-template ng-option-tmp let-item="item">
                      {{ item.name }}
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.name }}
                    </ng-template>
                  </ng-select>
                  <small class="form-text text-muted danger invalid-feedback">
                  </small>
                </td>
                <td class="text-right">
                  <div class="col-5 float-right">
                    <div class="form-group">
                      <input
                        #articleQuantity
                        type="number"
                        [(ngModel)]="quantity"
                        class="form-control"
                        placeholder="Escribe la cantidad"
                        (change)="totalPriceArticle(articleQuantity)" />
                    </div>
                  </div>
                </td>
                <td class="text-right">
                  {{ priceArticle | currency: 'USD':'symbol':'1.0-0' }}
                </td>
                <td class="text-right">
                  {{ totalArticle | currency: 'USD':'symbol':'1.0-0' }}
                </td>
                <td class="text-right">
                  <div
                    class="btn btn-danger btn-sm"
                    (click)="addArticle(articleQuantity)">
                    <i class="feather ft-plus"></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div
      class="row mt-2"
      *ngIf="!!ticket.comments && ticket.status != TicketStatus.DRAFT">
      <div class="col-md-12">
        <strong><label>Comentario</label></strong>
        <br />
        <label>{{ ticket.comments }}</label>
      </div>
    </div>

    <div class="row mt-2" *ngIf="ticket.status == TicketStatus.DRAFT">
      <div class="col-md-12">
        <div class="form-group">
          <label>Comentario</label>
          <textarea
            rows="3"
            [(ngModel)]="comment"
            (blur)="updateComment()"
            type="text"
            class="form-control"></textarea>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-2">
        <ngx-qrcode
          elementType="img"
          value="https://plataforma.colsan.cl/admin/warehouseDetails/{{
            warehouse.key
          }}/{{ ticket.key }}"
          errorCorrectionLevel="L">
        </ngx-qrcode>
      </div>

      <div class="col-8 d-flex justify-content-center">
        <div class="d-flex flex-column align-items-center">
          <div class="text-center" *ngIf="!!ticket.supervisor">
            <p class="mb-0">Autorizado por</p>
            <p class="text-muted mb-0">
              {{ (ticket.supervisor | doc | async)?.name }}
            </p>
            <p class="text-muted">
              {{ (ticket.supervisor | doc | async)?.email }}
            </p>
          </div>

          <div class="row">
            <div class="text-center mt-4 mr-2" *ngIf="!!ticket.createdBy">
              <p class="text-muted mb-0">Creado por</p>
              <p class="text-muted">
                {{ (ticket.createdBy | doc | async)?.name }}
                {{ (ticket.createdBy | doc | async)?.surnames }}
              </p>
            </div>

            <div class="text-center mt-4 mt-2" *ngIf="!!ticket.user">
              <p class="text-muted mb-0">Retirado por</p>
              <p class="text-muted">
                {{ (ticket.user | doc | async)?.name }}
                {{ (ticket.user | doc | async)?.surnames }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div class="modal-footer border-0">
  <button
    *ngIf="
      ticket.status == TicketStatus.AUTHORIZED &&
      (permission == permissionEnum.GROCER ||
        permission == permissionEnum.ADMIN)
    "
    [disabled]="requestsReference.length > 0"
    class="btn btn-success cursor-pointer"
    (click)="rectifyTicket()">
    Rectificar cantidades
  </button>

  <button
    *ngIf="
      (permission == permissionEnum.GROCER ||
        permission == permissionEnum.ADMIN) &&
      ticket.status == TicketStatus.AUTHORIZED
    "
    [disabled]="!areAllConfirm"
    class="btn btn-success cursor-pointer"
    (click)="changeQuantity()">
    Confirmar
  </button>

  <button
    *ngIf="ticket.status == TicketStatus.DRAFT"
    class="btn btn-success cursor-pointer"
    (click)="confirmTicket()">
    Generar
  </button>

  <button
    *ngIf="
      ((permission == permissionEnum.SUPERVISOR ||
        permission == permissionEnum.ADMIN) &&
        ticket.status == TicketStatus.GENERATED) ||
      (permission == permissionEnum.ADMIN &&
        ticket.status == TicketStatus.CANCELLED)
    "
    class="btn btn-danger cursor-pointer"
    (click)="changeToDraft()">
    Pasar a borrador
  </button>

  <button
    *ngIf="
      (permission == permissionEnum.SUPERVISOR ||
        permission == permissionEnum.ADMIN) &&
      ticket.status == TicketStatus.GENERATED
    "
    class="btn btn-success cursor-pointer"
    (click)="confirmTicket()">
    Autorizar
  </button>

  <button
    *ngIf="
      (permission == permissionEnum.SUPERVISOR ||
        permission == permissionEnum.ADMIN) &&
      ticket.status <= TicketStatus.AUTHORIZED
    "
    class="btn btn-dark cursor-pointer"
    (click)="cancelTicket()">
    Cancelar ticket
  </button>

  <button
    *ngIf="
      permission != permissionEnum.GROCER &&
      permission != permissionEnum.BUYER &&
      ticket.status == TicketStatus.DRAFT
    "
    class="btn btn-danger cursor-pointer"
    (click)="discardTicket()">
    Descartar
  </button>

  <div class="btn btn-warning cursor-pointer" (click)="print()">Imprimir</div>
  <div class="btn btn-danger cursor-pointer" (click)="modal.hide()">Cerrar</div>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
  ><p style="color: white">Actualizando ticket...</p></ngx-spinner
>
