import {Injectable} from '@angular/core';
import {UserType} from 'src/app/admin/enums/user-type.enum';
import {TabsPermission} from '../enums/tabs-permission.enum';
import {indexPermissions, isTabPermission} from '../permissions/permission';
import {TABS_PERMISSIONS} from '../permissions/tabs.permissions';
import {UserService} from '../../../../shared/services/user.service';
import {Permission} from '../../../enums/permissions.enum';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private userType: UserType[] = [];
  tabsPermissionsIndexed;

  constructor(private _user: UserService) {
    this.tabsPermissionsIndexed = indexPermissions(TABS_PERMISSIONS);
  }

  setUserType(userType: UserType): void {
    this.userType.push(userType);
  }

  resetUserType(): void {
    this.userType = [];
  }

  hasPermission(permission: TabsPermission): boolean {
    if (!this.userType.length) {
      this.setUserType(this._user.user.permissions
        .find((permission) => permission.section == 'BODEGAS')
        .permission);
    }

    if (isTabPermission(permission)) {
      for (const userType of this.userType) {
        if (!!this.tabsPermissionsIndexed[userType][permission]) {
          return true;
        }
      }
    }

    return false;
  }

  isSupervisor(permission, section) {
    return permission.section == section && (
      permission.permission == Permission.SUPERVISOR ||
      permission.permission == Permission.ADMIN
    );
  }

  isGrocer(permission, section) {
    return permission.section == section &&
      permission.permission == Permission.GROCER;
  }

  isBuyer(permission, section) {
    return permission.section == section
      && permission.permission == Permission.BUYER;
  }

  isUser(permission, section) {
    return permission.section == section
      && permission.permission == Permission.USER;
  }

  hasUserType(userType: UserType): boolean {
    return this.userType.includes(userType);
  }
}
