import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'categoryName'
})
export class CategoryNamePipe implements PipeTransform {

  transform(name: string, ): string {
    let lettersReplaced: string[] = [];
    for (const letter of name) {
      if (letter != ' ' && letter == letter.toUpperCase()) {

        let letterIndex = name.indexOf(letter);

        if (letterIndex > 0 && (lettersReplaced.length == 0 || !lettersReplaced.some(data => data == letter))) {
          if (name[letterIndex - 1] != ' ') {

            name = name.replaceAll(letter, ' ' + letter);
            lettersReplaced = [...lettersReplaced, letter];
          }
        }
      }
    }
    return name;
  }

}
