import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { StatusService } from '../../../shared/services/status.service';
import { UtilsService } from '../../../applicant/services/utils.service';
import { JobService } from '../../../shared/services/job.service';
import { formatDate } from '@angular/common';
import { AlertService } from '../../../shared/template-services/alert.service';
import { ApplicantService } from '../../../shared/services/applicant.service';
import { CountryListService } from '../../../shared/services/country-list.service';
import { States } from '../../../../assets/json/states';

@Component({
  selector: 'app-update-applicant',
  templateUrl: './update-applicant.component.html',
  styleUrls: ['./update-applicant.component.css']
})
export class UpdateApplicantComponent implements OnInit {
  applicant: any;
  applicantKey: string;
  editApplicantForm: FormGroup;
  submitted: boolean = false;
  statusSubscription: Subscription = new Subscription();
  jobsSubscription: Subscription = new Subscription();
  selectArrayStatus: any = [];
  states: any[] = [];
  cities: any[] = [];
  selectArrayJobs: any = [];
  educationLevelArray = [
    'Enseñanza Media Incompleta',
    'Enseñanza Media Completa',
    'Enseñanza Técnica Completa',
    'Enseñanza Técnica Incompleta',
    'Enseñanza Universitaria Completa',
    'Enseñanza Universitaria Incompleta'
  ];
  countries: string[] = [];

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _status: StatusService,
              private utilsService: UtilsService,
              private _job: JobService,
              private _applicant: ApplicantService) {
    this.editApplicantForm = this.formBuilder.group({
      rut: ['', this.ValidateRut],
      name: [''],
      region: [''],
      surnames: [''],
      city: [''],
      position: [''],
      birthday: [''],
      workExperience: [''],
      educationLevel: [''],
      email: [''],
      phone: [''],
      nationality: [''],
      experience: [''],
      expectation: ['']
    });
  }

  ValidateRut(control: AbstractControl): { [key: string]: any } | null {
    function checkRut(rut) {
      let valor = rut.value.replaceAll('.', '');
      valor = valor.replace('-', '');

      let cuerpo = valor.slice(0, -1);
      let dv = valor.slice(-1).toUpperCase();

      rut.value = cuerpo + '-' + dv;

      if (cuerpo.length < 7) {
        return false;
      }

      let suma = 0;
      let multiplo = 2;

      for (let i = 1; i <= cuerpo.length; i++) {
        let index = multiplo * valor.charAt(cuerpo.length - i);

        suma = suma + index;

        if (multiplo < 7) {
          multiplo = multiplo + 1;
        } else {
          multiplo = 2;
        }
      }
      let dvEsperado = 11 - (suma % 11);

      dv = (dv == 'K') ? 10 : dv;
      dv = (dv == 0) ? 11 : dv;

      if (dvEsperado != dv) return false;

      return true;
    }

    if (control.value) {
      const rutValid = checkRut(control);
      if (!rutValid) return { 'rutInvalid': true };
    }
    return null;
  }

  async ngOnInit() {
    this.countries = CountryListService.countries;

    let birthday: number = +this.applicant.birthday;
    const birthDateDate = new Date(birthday);
    const format = 'yyyy/MM/dd';
    const locale = 'en-US';
    const formattedDate = formatDate(birthDateDate, format, locale);

    this.applicant.birthday = formattedDate.replace(/\//g, '-');
    this.states = States;

    this.editApplicantForm.patchValue({
      ...this.applicant,
      region: this.states.find(state => state.id == this.applicant.region.id || state.previousId == this.applicant.region.id)
    });

    await this.getCategories();
    this.getJobs();
  }

  getJobs() {
    this.jobsSubscription = this._job.getAll()
      .pipe(
        map(job => job.map(job => ({
            name: job.name,
            key: job.key
          }
        )))
      )
      .subscribe(data => {
        this.selectArrayJobs = data;
        let indexJob = this.selectArrayJobs.findIndex(job => job.name == this.applicant.position.name || job.key == this.applicant.position?.reference?.id);

        console.log(this.applicant.position);
        console.log(data);

        this.editApplicantForm.patchValue({ position: this.selectArrayJobs[indexJob] });
      });
  }

  getCategories() {
    this.statusSubscription = this._status.getAll()
      .pipe(
        map(status => status.map(status => ({
            name: status.name,
            key: status.key
          }
        )))
      )
      .subscribe(data => {
        this.selectArrayStatus = data;
      });
  }

  get formControls() {
    return this.editApplicantForm.controls;
  }

  stateChange(event) {
    this.cities = [];
    for (let i = 0; i < this.utilsService.citylist.length; i++) {
      if (this.utilsService.citylist[i].state_id == event.id) {
        this.cities.push(this.utilsService.citylist[i]);
      }
    }
  }

  async submit() {
    this.submitted = true;
    if (!this.editApplicantForm.valid) {
      return AlertService.toastError('Verifique todos los campos');
    }
    this.editApplicantForm.patchValue({ birthday: new Date(this.editApplicantForm.value.birthday).getTime() });

    let jobSelect = this.editApplicantForm.value.position;
    let jobReference = this._job.getReference(this.editApplicantForm.value.position.key);

    this.editApplicantForm.patchValue({ position: { reference: jobReference } });

    await this._applicant.update(this.applicantKey, this.editApplicantForm.value);

    const applicants = this._applicant.applicantsQueue;
    const applicant = applicants.find(applicantItem => applicantItem.key == this.applicantKey);

    if (applicant) {
      applicant.rut = this.editApplicantForm.value.rut;
      applicant.name = this.editApplicantForm.value.name;
      applicant.surnames = this.editApplicantForm.value.surnames;
      applicant.city = this.editApplicantForm.value.city;
      applicant.position.name = jobSelect.name;
      applicant.birthday = this.editApplicantForm.value.birthday;
      applicant.workExperience = this.editApplicantForm.value.workExperience;
      applicant.educationLevel = this.editApplicantForm.value.educationLevel;
      applicant.email = this.editApplicantForm.value.email;
      applicant.phone = this.editApplicantForm.value.phone;
      applicant.nationality = this.editApplicantForm.value.nationality;
      applicant.experience = this.editApplicantForm.value.experience;
      applicant.expectation = this.editApplicantForm.value.expectation;

      this._applicant.applicantsQueue = applicants;
    }

    AlertService.toastSuccess('Se actualizó correctamente');
    this.modal.hide();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
