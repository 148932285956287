import {Component, Input, OnInit} from '@angular/core';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {Observable} from 'rxjs';
import {WarehouseDetailService} from '../../pages/warehouses/services/warehouse-detail.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {
  @Input() logs$: Observable<any>;
  config: DataTableConfig = {
    title: '',
    notFoundText: 'No se encontraron registros',
    hasSearch: false
  };

  constructor(private _warehouseDetail: WarehouseDetailService) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (!this.logs$) {
        this.logs$ = this._warehouseDetail.logs$;
      }
    }, 1000);
  }

}
