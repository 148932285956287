import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { QuizService } from '../../../shared/services/quiz.service';
import { Quiz } from '../../interfaces/quiz';
import { AlertService } from '../../../shared/template-services/alert.service';
import { map, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { JobService } from '../../../shared/services/job.service';
import { AddAnswerComponent } from '../add-answer/add-answer.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-quiz',
  templateUrl: './add-quiz.component.html',
  styleUrls: ['./add-quiz.component.css']
})
export class AddQuizComponent implements OnInit {
  quiz: Quiz;
  update: boolean;
  quizForm: FormGroup;
  submitted: boolean = false;
  questionss = [];
  modalRef: BsModalRef;
  answer = [];

  constructor(private formBuilder: FormBuilder,
              private _quiz: QuizService,
              private _job: JobService,
              private modal: BsModalService,
              private router: Router) {
    this.quizForm = new FormGroup({
      name: new FormControl('', Validators.required),
      job: new FormControl(''),
      questions: new FormControl(this.questionss)
    });
    this.addQuestion();
  }

  ngOnInit(): void {
    if (this.update) {
      this.quizForm.patchValue({ name: this.quiz.name, job: this.quiz.job });
    }
  }

  get formControls() {
    return this.quizForm.controls;
  }

  async submit() {
    for (let i = 0; i < this.questionss.length; i++) {
      for (let j = 0; j < this.questionss[i].answers.length; j++) {
        let questions = Object.values(this.questionss[i].answers[j]);

        this.questionss[i].answers[j] = questions.toString();
      }
    }

    if (this.quizForm.valid) {
      let quiz = {
        name: this.quizForm.value.name,
        questions: this.questionss,
        job: this.quizForm.value.job,
        trash: false
      };

      this._quiz.add(quiz);

      AlertService.success('Se guardó correctamente');

      this.router.navigateByUrl('admin/quiz');
    }
  }

  addQuestion() {
    this.modalRef = this.modal.show(AddAnswerComponent, {
      animated: true,
      backdrop: 'static',
      class: 'modal-lg'
    });

    this.modalRef.onHide.pipe(take(1)).subscribe(() => {
      if (!this.modalRef.content.isSubmitted) return;

      this.questionss.push(this.modalRef.content.question);
    });
  }

  dropQuestion(index: number) {
    this.questionss.splice(index, 1);
  }
}
