import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {PurchaseOrder} from '../../../../interfaces/purchase-order';
import {PurchaseStatus} from '../../../../../../enums/purchase-status.enum';
import {UserType} from '../../../../../../enums/user-type.enum';
import {Observable, Subscription} from 'rxjs';
import {PurchaseOrderService} from '../../../../../../../shared/services/purchase-order.service';
import {LogsService} from '../../../../../../services/logs.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {UserService} from '../../../../../../../shared/services/user.service';
import {FirebaseDataService} from '../../../../../../../shared/template-services/firebase-data.service';
import {AlertService} from '../../../../../../../shared/template-services/alert.service';
import {take, first} from 'rxjs/operators';
import {UpdloadPurchasePdfComponent} from '../../../../../../modals/updload-purchase-pdf/updload-purchase-pdf.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ProviderService} from '../../../../../../../shared/services/provider.service';
import {SearchItemsModalComponent} from '../../../../../../../shared/template-components/search-items/search-items-modal.component';
import {BillService} from '../../../../../../../shared/services/bill.service';
import {FieldDataTableColumnType} from '../../../../../../../shared/enums/field-data-table-column-type.enum';
import {Bill} from '../../../../../../interfaces/bill';
import {PrintPurchaseService} from '../../../../../../../shared/services/print-purchase.service';
import {UploadFileStorageComponent} from '../../../../../../../shared/template-components/upload-file-storage/upload-file-storage.component';
import {DomSanitizer} from '@angular/platform-browser';
import {User} from 'src/app/shared/interfaces/user';
import {NotificationService} from '../../../../../../services/notification.service';

@Component({
  selector: 'app-authorized-purchase-order',
  templateUrl: './authorized-purchase-order.component.html',
  styleUrls: ['./authorized-purchase-order.component.css'],
})
export class AuthorizedPurchaseOrderComponent implements OnInit {
  @ViewChild(UploadFileStorageComponent)
  filesStorage: UploadFileStorageComponent;
  @ViewChild('componentID') componentID: ElementRef;
  @Input() purchaseOrder: PurchaseOrder = {key: ''} as PurchaseOrder;
  @Output() purchaseOrderChanged: EventEmitter<PurchaseOrder> =
    new EventEmitter<PurchaseOrder>();
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  purchaseStatus = PurchaseStatus;
  provider: any;
  articlesPurchase: any;
  dates: any;
  permission: any;
  permissionEnum = UserType;
  logs$: Observable<any>;
  isPrint: boolean = false;
  isPdf: boolean = true;
  url: any;
  quotation = null;
  spinnerMessage: string = '';
  description: string = '';
  bussinessConditions: string = '';
  bills$: Observable<any[]>;
  isQuotationLoaded: boolean = false;
  usersSubscription: Subscription = new Subscription();
  users: User[] = [];

  constructor(
    private _purchase: PurchaseOrderService,
    private _log: LogsService,
    private SpinnerService: NgxSpinnerService,
    public _user: UserService,
    private db: FirebaseDataService,
    private modalService: BsModalService,
    private _provider: ProviderService,
    private _bill: BillService,
    private sanitizer: DomSanitizer,
    private _notification: NotificationService
  ) {
  }

  ngOnInit(): void {
    this.bills$ = this._bill.getAll();
    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'COMPRAS'
    ).permission;

    this.usersSubscription = this._user.getUsers().subscribe((users) => {
      users = users.filter((user) => {
        if (!user.permissions) return false;
        let section = user.permissions.find((per) => per.section == 'COMPRAS');
        return (
          !!section &&
          (section.permission == this.permissionEnum.SUPERVISOR ||
            section.permission == this.permissionEnum.ADMIN)
        );
      });
      this.users = users;
    });

    this.logs$ = this._log.getAllPurchaseOrder(this.purchaseOrder.key);
    this.provider = {
      provider: this.purchaseOrder.provider,
      project: this.purchaseOrder.project,
    };

    if (!!this.purchaseOrder.description) {
      this.description = this.purchaseOrder.description;
    }
    if (!!this.purchaseOrder.bussinessConditions) {
      this.bussinessConditions = this.purchaseOrder.bussinessConditions;
    }

    this.articlesPurchase = {
      articles: this.purchaseOrder.articles,
      subtotal: this.purchaseOrder.subtotal,
      discount: this.purchaseOrder.discount,
      net: this.purchaseOrder.net,
      iva: this.purchaseOrder.iva,
      total: this.purchaseOrder.total,
      paymentType: this.purchaseOrder.paymentType,
      currency: this.purchaseOrder.currency,
    };

    this.quotation = this.purchaseOrder.quotation;
    this.getUrlPdf(this.quotation);
  }

  printChangeTrue() {
    this.isPrint = true;
  }

  getUrlPdf(url: any) {
    this.isPdf = url.includes('pdf');
    if (!this.isPdf) {
      return (this.url = url);
    }
    this.url = this.sanitizer.bypassSecurityTrustHtml(
      `<iframe width="100%" height="915" src="${url}"></iframe>`
    );
  }

  print(componentID) {
    this.isPrint = true;
    setTimeout(() => {
      PrintPurchaseService.print(componentID);
      this.isPrint = false;
    }, 1000);
  }

  async sendPdf() {
    const provider: any = await this._provider
      .get(this.purchaseOrder.provider.id)
      .pipe(take(1))
      .toPromise();
    this.modalService.show(UpdloadPurchasePdfComponent, {
      initialState: {
        providerEmail: !!provider
          ? provider.email
          : 'ing.carlos.alatorre@gmail.com',
        purchaseID: this.purchaseOrder.purchaseID.toString().slice(0, -3),
      },
      id: 2,
    });
  }

  async changeToReceived() {
    if (await AlertService.confirm('¿Deseas confirmar de recibido?')) {
      await this._purchase.set(this.purchaseOrder.key, {
        received: true,
      } as PurchaseOrder);
      this.purchaseOrder.received = true;
      this._log.addPurchaseOrder(this.purchaseOrder.key, {
        description: `La orden de compra ${this.purchaseOrder.purchaseID} fue recibida`,
      });
      AlertService.toastSuccess('Se confirmó correctamente');
    }
  }

  addInvoice() {
    const modalRef: BsModalRef = this.modalService.show(
      SearchItemsModalComponent,
      {
        class: 'modal-xl',
        id: 100,
        initialState: {
          items$: this._bill.getUnused(this.purchaseOrder),
          fields: [
            {
              title: 'Folio',
              column: 'documentNumber',
            },
            {
              title: 'Proveedor',
              column: 'provider',
            },
            {
              title: 'Monto',
              type: FieldDataTableColumnType.CURRENCY,
              column: 'total',
            },
            {
              title: 'Fecha creación',
              type: FieldDataTableColumnType.DATE,
              column: 'startDate',
            },
          ],
        },
      }
    );

    modalRef.onHide.pipe(take(1)).subscribe(async () => {
      if (modalRef.content.itemSelected.total > +this.purchaseOrder.total) {
        AlertService.toastError('Ha excedido el monto total de la OC');
        return;
      }
      if (this.purchaseOrder.invoice.length > 0) {
        let bills = await this.bills$.pipe(first()).toPromise();
        let billsTotalAmount = this.purchaseOrder.invoice.reduce(
          (acc, el) =>
            acc + bills.some((bill) => bill.key == el.id)
              ? bills.find((bill) => bill.key == el.id).total
              : 0,
          0
        );
        billsTotalAmount =
          billsTotalAmount + +modalRef.content.itemSelected.total;
        if (billsTotalAmount > +this.purchaseOrder.total) {
          AlertService.toastError('Ha excedido el monto total de la OC');
          return;
        }
      }
      if (!this.purchaseOrder.invoice) {
        this.purchaseOrder.invoice = [];
      }
      this._bill.update(modalRef.content.itemSelected.key, {
        isUsed: true,
        category: this.purchaseOrder.budget,
      } as Bill);
      this.purchaseOrder.invoice.push(
        this.db.getReference(`bills/${modalRef.content.itemSelected.key}`)
      );
      this.purchaseOrder.invoiced = true;

      this._purchase.update(this.purchaseOrder.key, {
        invoice: this.purchaseOrder.invoice,
        invoiced: true,
      } as PurchaseOrder);

      this._bill.update(modalRef.content.itemSelected.key, {
        purchaseOrder: this.db.getReference(
          `purchaseOrders/${this.purchaseOrder.key}`
        ),
      } as Bill);
    });
  }

  quotationLoaded() {
    if (this.isQuotationLoaded == false) {
      this.isQuotationLoaded = true;
    }
  }

  async updateQuotation() {
    if (!this.isQuotationLoaded) {
      return AlertService.toastError(
        'Favor de agregar una cotización para actualizar el documento'
      );
    }
    this.SpinnerService.show();
    this.spinnerMessage = 'Actualizando cotización';

    if (this.filesStorage.isInputValid) {
      this.quotation = await this.filesStorage.uploadDocument(
        'purchaseOrders',
        this.purchaseOrder.purchaseID
      );
    } else {
      return;
    }

    this.getUrlPdf(this.quotation);
    await this._purchase.set(this.purchaseOrder.key, {
      quotation: this.quotation,
    } as PurchaseOrder);
    this.SpinnerService.hide();
  }

  close() {
    this.closeModal.emit();
  }

  async cancelPurchase() {
    if (
      await AlertService.confirm(
        '¿Estás seguro que deseas cancelar la orden de compra?',
        ''
      )
    ) {
      let cancelMessage = await AlertService.input(
        'Motivo de la cancelación',
        '',
        'Confirmar'
      );
      if (!cancelMessage) cancelMessage = 'No se asignó motivo';

      await this._purchase.update(this.purchaseOrder.key, {
        status: PurchaseStatus.CANCELLED,
        cancelMessage,
      } as PurchaseOrder);
      this._log.addPurchaseOrder(this.purchaseOrder.key, {
        description: `La orden de compra ${this.purchaseOrder.purchaseID.slice(
          0,
          -3
        )} fue cancelada`
      });
      if (
        !!this.purchaseOrder.status
      ) {
        let emails = [];
        if (this.users.length > 0) {
          emails = this.users.map((user) => user.email);
          await this._notification.sendPurchaseOrderNotificationEmail(
            `${
              this._user.user.name
            } canceló la orden de compra ${this.purchaseOrder.purchaseID.slice(0, -3)}`,
            this.purchaseOrder?.description,
            this._provider.providers.find(
              (provider) => provider.key == this.purchaseOrder.provider.id
            )?.name,
            `$${Math.floor(this.purchaseOrder?.total)}`,
            this.purchaseOrder.key,
            `Compras: Orden de compra ${this.purchaseOrder.purchaseID.slice(0, -3)} cancelada`,
            emails
          );
        }
      }
      this.close();
      AlertService.toastSuccess('La orden de compra fue cancelada', '');
    }
  }

  async setIncompleteOrder() {
    await this._purchase.update(this.purchaseOrder.key, {
      status: PurchaseStatus.INCOMPLETE,
    } as PurchaseOrder);
    this.close();
    AlertService.toastSuccess('La orden de compra cambió a incompleta', '');
  }

  async changeStatusToDraft() {
    if (await AlertService.confirm('¿Estás seguro que deseas regresar a borrador?')) {
      this._purchase.update(this.purchaseOrder.key, {
        status: PurchaseStatus.DRAFT,
      } as PurchaseOrder);

      AlertService.toastSuccess('La orden de compra cambió a borrador', '');
      this.close();
    }
  }
}
