import {Component, OnInit} from '@angular/core';
import {Quiz} from '../../interfaces/quiz';
import {QuizService} from '../../../shared/services/quiz.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {map, take} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {JobService} from '../../../shared/services/job.service';
import {AddAnswerComponent} from '../../modals/add-answer/add-answer.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-quiz-details',
  templateUrl: './quiz-details.component.html',
  styleUrls: ['./quiz-details.component.css']
})
export class QuizDetailsComponent implements OnInit {

  quizKey: string;
  modalRef: BsModalRef;
  quiz: Quiz;
  quizForm: FormGroup;
  submitted: boolean = false;
  questions = [];
  update: boolean;

  constructor(private _quiz: QuizService,
              private activatedRoute: ActivatedRoute,
              private _position: JobService,
              private fb: FormBuilder,
              private modal: BsModalService,
              private route: Router) {
    this.quizForm = this.fb.group({
      name: ['', Validators.required],
      questions: [this.quiz]
    });
  }

  ngOnInit(): void {
    this.quizKey = this.activatedRoute.snapshot.params['quizKey'];
    this.quiz = JSON.parse(sessionStorage.getItem(this.quizKey));
    this.questions = this.quiz.questions;
    if (this.update) {
      this.questions = this.quiz.questions;
    }
    this.quizForm.patchValue({name: this.quiz.name, position: this.quiz.job});
  }

  get formControls() {
    return this.quizForm.controls;
  }

  dropQuestion(index: number) {
    this.questions.splice(index, 1);
  }

  async submit() {
    let quiz = {
      name: this.quizForm.value.name,
      questions: this.questions,
      trash: false
    };
    this._quiz.update(this.quizKey, quiz);
    if (AlertService.success('Se guardo correctamente')) return this.route.navigateByUrl('admin/quiz')
  }

  addQuestion() {
      this.modalRef = this.modal.show(AddAnswerComponent, {
      animated: true,
      backdrop: 'static',
      class: 'modal-xl'
    });

    this.modalRef.onHide.pipe(take(1)).subscribe(() => {
      for (let i = 0; i < this.modalRef.content.question.answers.length; i++) {
        let questions = Object.values(this.modalRef.content.question.answers[i]);
        this.modalRef.content.question.answers[i] = questions.toString();
      }
      this.questions.push(this.modalRef.content.question);
    });
  }

  editQuiz(index: number) {
    const modalRef: BsModalRef =
    this.modal.show(AddAnswerComponent, {
      initialState: {
        answers: this.questions,
        index,
        update: true
      },
      animated: true,
      backdrop: 'static',
      class: 'modal-xl'
    });

    modalRef.content.eventUpdateQuestion.pipe(take(1)).toPromise().then((tab) => {
      for (let i = 0; i < tab.questions.answers.length; i++) {
        let questions = Object.values(tab.questions.answers[i]);
        tab.questions.answers[i] = questions.toString();
      }
      this.questions[tab.index] = tab.questions;
    });
  }
}
