<div class="p-2">
  <h2>Cambiar proyecto</h2>

  <form [formGroup]="projectForm" (ngSubmit)="submit()">
    <div class="row mt-2">
      <div class="col-md-12 col-12">
        <div class="form-group">
          <label>Proyecto</label>
          <ng-select formControlName="project"
                     [items]="selectArrayProjects"
                     bindLabel="name"
                     placeholder="">
          </ng-select>
        </div>
      </div>
    </div>

    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn">Actualizar</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide()">Cancelar</div>
    </div>
  </form>

</div>
