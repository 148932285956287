import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {EmployeePayment} from '../interfaces/employee-payment';
import {Article} from '../pages/warehouses/interfaces/article';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmployeePaymentService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  getAll(centerCostKey: string): Observable<EmployeePayment[]> {
    return this.afs.collectionGroup<EmployeePayment>(`employeePayments`, ref => ref
      .where('trash', '==', false)
      .where('centerCostKey', '==', centerCostKey))
      .snapshotChanges()
      .pipe(map(actions => actions.map((a:any) => {
        return {
          ...a.payload.doc.data(),
          key: a.payload.doc.id,
        }
      })))
  }

  set(costCenterKey: string, employeePaymentKey: string, employeePayment: EmployeePayment): void {
    this.afs.doc(`costCenter/${costCenterKey}/employeePayments/${employeePaymentKey}`).set(employeePayment, {merge: true});
  }

  delete(costCenterKey: string, employeePaymentKey: string): Promise<void> {
    return this.afs.doc<EmployeePayment>(`costCenter/${costCenterKey}/employeePayments/${employeePaymentKey}`).update({trash: true});
  }

  async update(costCenterKey: string, employeePaymentKey: string, employeePayment: EmployeePayment): Promise<void> {
    return await this.afs.doc<EmployeePayment>(`costCenter/${costCenterKey}/employeePayments/${employeePaymentKey}`).update(employeePayment);
  }
}
