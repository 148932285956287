<div class="modal-header">
  <h4 class="modal-title pull-left">Comprobante</h4>
  <button type="button" class="pull-right btn" (click)="modal.hide()">
    <i class="feather ft-x"></i>
  </button>
</div>
<div class="p-1">
  <form [formGroup]="receiptForm">
    <div class="mt-1">
      <label>Descripción</label>
      <textarea
        class="form-control"
        [ngClass]="{'is-invalid': submitted && formControls.description.errors}"
        formControlName="description"></textarea>
      <small class="invalid-feedback">Campo obligatorio</small>
    </div>

    <div class="mt-1">
      <label>Monto</label>
      <input
        type="number"
        class="form-control"
        [ngClass]="{'is-invalid': submitted && formControls.amount.errors}"
        formControlName="amount" />
      <small class="invalid-feedback">Campo obligatorio</small>
    </div>

    <div class="mt-1">
      <label>Categoría</label>
      <ng-select
        [items]="categories"
        [clearable]="false"
        bindLabel="name"
        formControlName="category"
        placeholder="Seleccione una categoría"
        notFoundText="No se encontraron categorías">
      </ng-select>
      <small
        class="text-danger"
        *ngIf="submitted && formControls.category.errors"
        >Campo obligatorio</small
      >
    </div>

    <div class="mt-1" *ngIf="isPosibleEditImage()">
      <app-upload-file-storage
        label="imagen del comprobante"
        extension="png"
        controlName="photoUrl"
        fileType="image/*"
        [showPreview]="true"
        (isImageLoaded)="setIsImageLoaded()">
      </app-upload-file-storage>
    </div>
    <img
      [src]="receipt.photoUrl"
      class="w-100"
      alt="Receipt image"
      *ngIf="isEdit && !isImageLoaded" />

    <div class="mt-1 d-flex justify-content-end">
      <button
        *ngIf="isPosibleEditImage()"
        class="btn btn-success mr-1"
        (click)="submit()">
        Guardar
      </button>

      <button class="btn btn-outline-danger" (click)="modal.hide()">
        Cerrar
      </button>
    </div>
  </form>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
  ><p style="color: white">Guardando datos...</p></ngx-spinner
>
