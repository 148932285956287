import { Component, OnInit } from '@angular/core';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {Observable} from 'rxjs';
import {Job} from '../../interfaces/job';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../shared/template-services/alert.service';
import {AddAfpComponent} from '../../modals/add-afp/add-afp.component';
import {AfpService} from '../../../shared/services/afp.service';
import {Afp} from '../../interfaces/afp';

@Component({
  selector: 'app-afp',
  templateUrl: './afp.component.html',
  styleUrls: ['./afp.component.css']
})
export class AfpComponent implements OnInit {
  config: DataTableConfig = {
    title: "",
    notFoundText: "No se encontraron afp",
    hasSearch: true
  };
  afp$: Observable<Afp[]>;

  constructor(private _afp: AfpService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.afp$ = this._afp.getAll();
  }

  openUpdateAfpModal(afp: any) {
    this.modal.show(AddAfpComponent, {
      initialState: {
        afp: afp,
        update: true
      }
    });
  }

  async deleteAfp(afpKey: string) {
    if (await AlertService.confirm('¿Estás seguro de que deseas eliminar este afp?', '')) {
      await this._afp.delete(afpKey);
      AlertService.toastSuccess('Se eliminó correctamente', '');
    }
  }

  openAddAfpModal() {
    this.modal.show(AddAfpComponent, {
      initialState: {
        update: false
      }
    });
  }
}
