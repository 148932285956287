import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Ticket} from '../pages/warehouses/interfaces/ticket';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {UserService} from '../../shared/services/user.service';
import {Observable} from 'rxjs';
import {UserNotification} from '../interfaces/userNotification';
import {AngularFirestore} from '@angular/fire/firestore';
import {AuthService} from '../../shared/template-services/auth.service';
import {take} from 'rxjs/operators';
import {TicketEntry} from '../pages/warehouses/interfaces/ticket-entry';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private http: HttpClient,
    private db: FirebaseDataService,
    private afs: AngularFirestore,
    private _user: UserService,
    private _auth: AuthService
  ) {}

  async sendTicketCollectedEmail(ticket: Ticket | TicketEntry) {
    delete ticket.withdrawn;
    delete ticket.projects;
    delete ticket.grocer;
    const ticketData = {
      ...ticket,
      supervisor: ticket.supervisor.id,
      user: ticket.user.id,
      warehouse: ticket.warehouse.id
    };

    const resp = await this.http
      .post(`${environment.apiBaseURL}/sendTicketCollectedEmail`, {
        ticket: ticketData
      })
      .pipe(take(1))
      .toPromise();

    console.log(resp);
  }

  async sendTicketAuthorizedEmail(ticket: Ticket) {
    delete ticket.withdrawn;
    delete ticket.projects;
    const ticketData = {
      ...ticket,
      supervisor: ticket.supervisor.id,
      user: ticket.user.id,
      warehouse: ticket.warehouse.id,
      grocer: !!ticket.grocer
        ? ticket.grocer.reference.id
        : 'zO12T1VGtHOE1hi3JE9jcnOdBnp2'
    };

    const resp = await this.http
      .post(`${environment.apiBaseURL}/sendTicketAuthorizedEmail`, {
        ticket: ticketData
      })
      .pipe(take(1))
      .toPromise();

    console.log(resp);
  }

  getAll(): Observable<UserNotification[]> {
    return this.db.colWithIds$<UserNotification>(
      `users/${this._user.user.key}/notifications`,
      (ref) => ref.where('trash', '==', false).orderBy('createdDate', 'desc')
    );
  }

  set(notification: UserNotification): void {
    this.afs
      .collection(`users/${this._auth.user.uid}/notifications`)
      .add(notification);
  }

  setUser(userKey, notification: UserNotification): void {
    this.afs.collection(`users/${userKey}/notifications`).add(notification);
  }

  update(userKey, notification: UserNotification, notificationKey: string) {
    this.afs
      .doc(`users/${userKey}/notifications/${notificationKey}`)
      .update(notification);
  }

  async sendNotificationEmail(
    text: string,
    subject: string,
    emails: string[]
  ): Promise<any> {
    return await this.http
      .post(`${environment.apiBaseURL}/sendNotificationEmail`, {
        text,
        subject,
        emails
      })
      .pipe(take(1))
      .toPromise();
  }

  async sendAuthorizedPurchaseOrderNotificationEmail(
    text: string,
    subject: string,
    purchaseOrderId: string,
    emails: string[]
  ): Promise<any> {
    return await this.http
      .post(
        `${environment.apiBaseURL}/sendAuthorizedPurchaseOrderNotification`,
        {
          text,
          subject,
          purchaseOrderId,
          emails
        }
      )
      .pipe(take(1))
      .toPromise();
  }

  async sendNotificationTicketEmail(
    text: string,
    subject: string,
    emails: string[],
    path: string
  ): Promise<any> {
    return await this.http
      .post(`${environment.apiBaseURL}/sendNotificationTicketEmail`, {
        text,
        subject,
        emails,
        path
      })
      .pipe(take(1))
      .toPromise();
  }

  async sendPurchaseOrderNotificationEmail(
    text: string,
    description: string,
    provider: string,
    amount: string,
    purchaseOrderId: string,
    subject: string,
    emails: string[]
  ): Promise<any> {
    return await this.http
      .post(`${environment.apiBaseURL}/sendPurchaseOrderNotification`, {
        text,
        description,
        provider,
        amount,
        purchaseOrderId,
        subject,
        emails
      })
      .pipe(take(1))
      .toPromise();
  }
}
