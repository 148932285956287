import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {AuthComponent} from './auth.component';
import {AuthRoutes} from "./auth-routing.module";
import {LoginComponent} from "./pages/login.component";
import {SharedModule} from '../shared/shared.module';

@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(AuthRoutes),
    SharedModule
  ]
})
export class AuthModule {
}
