<div class="pt-1">
  <app-datatable [rows$]="tasks$" [config]="dataTableConfig" (rowSelected)="onTaskClicked($event)">

    <app-datatable-column title="Título" field="title">
      <ng-template let-row #column>
        <p>{{row.title}}</p>
      </ng-template>
    </app-datatable-column>
    
    <app-datatable-column title="Prioridad" field="tags">
      <ng-template let-row #column>
        <div class="badge mr-1 mb-1" [style.backgroundColor]="tag?.color"
           *ngFor="let tag of row.tags">{{tag?.name | titlecase}}</div>
      </ng-template>
    </app-datatable-column>
    
    <app-datatable-column title="Asignados" field="members">
      <ng-template let-row #column>
        <ul class="list-unstyled users-list m-0" *ngIf="row?.members?.length > 0">
          <li class="avatar avatar-sm pull-up" *ngFor="let member of row.members">
            <img class="media-object rounded-circle avatar-img"
                 container="body"
                 ngbTooltip="{{member?.name}}"
                 src="{{!!member?.imageUrl ? member.imageUrl : '/assets/images/default.png'}}" alt="Responsable">
          </li>
        </ul>
      </ng-template>
    </app-datatable-column>
    
    <app-datatable-column title="Fecha de finalización" field="expiratedDate">
      <ng-template let-row #column>
        <p>{{row.expiratedDate | date : 'MMM, dd, yyyy'}}</p>
      </ng-template>
    </app-datatable-column>
    
    <app-datatable-column title="Estado" field="status">
      <ng-template let-row #column>
        {{row.status | taskStatus | titlecase}}
      </ng-template>
    </app-datatable-column>

  </app-datatable>
</div>