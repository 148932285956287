<div class="p-2">
  <div class="payment-item">
    <label>Estado</label>
    <div *ngIf="!_billData.updating">
      <div class="badge {{badgeType}}">{{_billData.bill.isPaid ? 'Pagada' : 'Pendiente'}}</div>
    </div>

    <div
      class="custom-control custom-switch"
      *ngIf="_billData.updating">
      <mat-slide-toggle
        color="warn"
        (change)="updateActualPaymentDate()"
        [(ngModel)]="_billData.billToEdit.isPaid">¿Está pagada?
      </mat-slide-toggle>
    </div>
  </div>

  <div class="payment-item">
    <label>Método de pago</label>
    <h5 *ngIf="!_billData.updating">{{paymentMethodLabel[_billData.bill.paymentMethod] || 'No asignado'}}</h5>
    <h5 *ngIf="!_billData.updating">{{_billData.bill?.otherPaymentMethod}}</h5>

    <div *ngIf="_billData.updating && _billData.billToEdit.isPaid">
      <div>
        <app-upload-file-storage
          label="Comprobante de pago"
          (change)="proofOfPaymentChange()"
          extension="pdf"
          controlName="voucher"
          [showPreview]="false"></app-upload-file-storage>
      </div>

      <select
        id="paymentMethod"
        class="form-control mb-1"
        [value]="paymentMethodEnum.ELECTRONIC_TRANSFER"
        [(ngModel)]="_billData.billToEdit.paymentMethod">
        <option [value]="paymentMethodEnum.CASH">
          {{ paymentMethodLabel[paymentMethodEnum.CASH] | titlecase }}
        </option>

        <option [value]="paymentMethodEnum.ELECTRONIC_TRANSFER">
          {{paymentMethodLabel[paymentMethodEnum.ELECTRONIC_TRANSFER] | titlecase}}
        </option>

        <option [value]="paymentMethodEnum.BANK">
          {{ paymentMethodLabel[paymentMethodEnum.BANK] | titlecase }}
        </option>

        <option [value]="paymentMethodEnum.PETTY_CASH">
          {{paymentMethodLabel[paymentMethodEnum.PETTY_CASH] | titlecase}}
        </option>

        <option [value]="paymentMethodEnum.OTHER">
          {{paymentMethodLabel[paymentMethodEnum.OTHER] | titlecase}}
        </option>
      </select>

      <div class="mb-1" *ngIf="_billData.billToEdit.paymentMethod == paymentMethodEnum.OTHER">
        <input
          class="form-control"
          placeholder="Especifique su método de pago"
          type="text"
          [(ngModel)]="_billData.billToEdit.otherPaymentMethod" />
      </div>
    </div>
  </div>

  <div class="payment-item">
    <label>Fecha real de pago</label>
    <h5 *ngIf="!_billData.updating && _billData.bill.isPaid">{{_billData.bill?.realPaymentDate | date : 'yyyy-MM-dd' || 'No asignado'}}</h5>

    <app-bill-date-picker
      *ngIf="_billData.updating && _billData.billToEdit.isPaid"
      [(date)]="_billData.billToEdit.realPaymentDate"></app-bill-date-picker>
  </div>
</div>
