import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalService} from 'ngx-bootstrap/modal';
import {map} from 'rxjs/internal/operators/map';
import {Subscription} from 'rxjs/internal/Subscription';
import {ProjectService} from 'src/app/shared/services/project.service';
import {UserService} from 'src/app/shared/services/user.service';
import {CostCenterType} from '../../../../enums/cost-center-type.enum';
import {ProjectStatus} from '../../../../enums/project-status.enum';
import {FundToRender} from '../../interfaces/fund-to-render';
import {FundToRenderStatusEnum} from 'src/app/admin/pages/travel-advance/enums/fund-to-render-status.enum';
import {FundToRenderService} from 'src/app/shared/services/fund-to-render.service';
import {User} from 'src/app/shared/interfaces/user';
import {UserType} from '../../../../enums/user-type.enum';
import {AlertService} from 'src/app/shared/template-services/alert.service';
import {LogsService} from '../../../../services/logs.service';
import {FundToRenderStatusLabel} from '../../labels/fund-to-render-status.label';
import {DomSanitizer} from '@angular/platform-browser';
import {BreakdownAmountCategory} from '../../interfaces/breakdown-amount-catengory';
import {BreakdownAmountCategoryService} from '../../services/breakdown-amount-category.service';
import {BreakdownAmount} from '../../interfaces/breakdown-amount';
import {DocumentReference} from '@angular/fire/firestore';
import {RequestNewFundToRender} from '../../interfaces/request-new-fund-to-render';
import {NewFundToRenderService} from 'src/app/shared/services/new-fund-to-render.service';
import {NotificationService} from '../../../../services/notification.service';
import {first} from 'rxjs/operators';
import {FundToRenderRequestStatusEnum} from '../../enums/fund-to-render-request-status.enum';

declare const $;

@Component({
  selector: 'app-fund-to-render-modal',
  templateUrl: './fund-to-render-modal.component.html',
  styleUrls: ['./fund-to-render-modal.component.css']
})
export class FundToRenderModalComponent implements OnInit {
  submitted: boolean;
  userKey: any;
  user: User;
  fundToRender: FundToRender = {key: ''} as FundToRender;
  fundToRenderRequest;
  fundToRenderForm: FormGroup;
  projectStatusEnum = ProjectStatus;
  projectSubscription: Subscription = new Subscription();
  userSubscription: Subscription = new Subscription();
  selectArray = [];
  projectExists: boolean = false;
  usersSubscription: Subscription = new Subscription();
  team: any;
  users: User[] = [];
  permission: any;
  permissionEnum = UserType;
  fundToRenderStatusEnum = FundToRenderStatusEnum;
  fundToRenderStatusLabel = FundToRenderStatusLabel;
  isEdit: boolean;
  fundsToRenderSubscription: Subscription = new Subscription();
  file: any;
  path: any;
  url: any;
  uploadFile: boolean;
  settings: any;
  breakdownAmountCategoriesSubscription: Subscription = new Subscription();
  breakdownAmountCategories: BreakdownAmountCategory[] = [];
  emailSupervisors: string[] = [];
  pendingAccountability: number;
  allAmounts: number[] = [];
  totalAmount: any;
  isImageLoaded: boolean;
  requiredAmount: number = 0;
  fundToRenderRequestStatusEnum = FundToRenderRequestStatusEnum;

  constructor(public modal: BsModalService,
              private formBuilder: FormBuilder,
              private _project: ProjectService,
              private _user: UserService,
              private sanitizer: DomSanitizer,
              private _logs: LogsService,
              private _fundToRender: FundToRenderService,
              private _requestNewFundToRender: NewFundToRenderService,
              private _breakdownAmountCategory: BreakdownAmountCategoryService,
              private _notification: NotificationService) {
    this.createForm();
  }

  async ngOnInit() {
    this.userSubscription = this._user.getAllByColsan().subscribe((data) => {
      this.emailSupervisors = data
        .filter((user) => {
          if (!!user.permissions) {
            return user.permissions.some(
              (permissions) =>
                permissions.section == 'FONDOS POR RENDIR' &&
                permissions.permission == this.permissionEnum.SUPERVISOR
            );
          }
        })
        .map((user) => user.email);
    });

    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'FONDOS POR RENDIR'
    ).permission;

    this.getProjects();
    await this.loadUsers();
    this.patchAssignedUser();
    this.listenForBreakdownAmountCategories();
    this.calculateRequiredAmount();

    if (this.permission == this.permissionEnum.USER) {
      this.user = this._user.user;
    }

    if (
      this.permission == this.permissionEnum.SUPERVISOR ||
      this.permission == this.permissionEnum.ADMIN ||
      (this.permission == this.permissionEnum.USER &&
        this.fundToRenderForm.value.status != this.fundToRenderStatusEnum.DRAFT)
    ) {
      this.fundToRenderForm.disable();
    }

    if (!!this.fundToRenderForm.value.pdf) {
      this.getUrlPdf(this.fundToRenderForm.value.pdf);
    }
    if (!!this.fundToRender.pdf) {
      this.getUrlPdf(this.fundToRender.pdf);
    }
  }

  ngOnDestroy() {
    this.fundsToRenderSubscription.unsubscribe();
    this.breakdownAmountCategoriesSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  listenForBreakdownAmountCategories() {
    this.breakdownAmountCategoriesSubscription = this._breakdownAmountCategory
      .getAll()
      .subscribe((data) => {
        this.breakdownAmountCategories = data;
        if (this.isEdit) {
          this.addBreakdownAmountControls();

          this.breakdownAmounts.setValue(
            this.fundToRender.breakdownAmounts.map((breakdownAmount) => ({
              ...breakdownAmount,
              category: this.breakdownAmountCategories.find(
                (category) =>
                  category.key == (<any>breakdownAmount.category).key
              )
            }))
          );
        }
      });
  }

  get formControls() {
    return this.fundToRenderForm.controls;
  }

  createForm() {
    this.fundToRenderForm = this.formBuilder.group({
      id: [],
      project: ['', Validators.required],
      reason: ['', Validators.required],
      title: ['', Validators.required],
      user: [''],
      breakdownAmounts: this.formBuilder.array([
        this.formBuilder.group({
          description: ['', Validators.required],
          amount: [0, Validators.required],
          category: ['', Validators.required]
        })
      ]),
      status: [this.fundToRenderStatusEnum.DRAFT],
      sendToSupervisor: [],
      pendingAccountability: [],
      receivedAmount: [],
      pdf: [],
      trash: [false],
      commentReturnedToDraft: [''],
    });
  }

  get breakdownAmounts() {
    return this.fundToRenderForm.get('breakdownAmounts') as FormArray;
  }

  addBreakdownAmountControl() {
    const control = this.formBuilder.group({
      description: ['', Validators.required],
      amount: [0, Validators.required],
      category: ['', Validators.required]
    });

    this.breakdownAmounts.push(control);
  }

  removeBreakdownAmountForm(index: number) {
    if (this.breakdownAmounts.length == 1) return;
    this.breakdownAmounts.removeAt(index);
  }

  private getProjects() {
    this.projectSubscription = this._project
      .getAll()
      .pipe(
        map((project) =>
          project.map((project) => ({
            ...project,
            type:
              project.type == CostCenterType.PROJECT
                ? 'Proyectos'
                : 'Centro de Costos'
          }))
        )
      )
      .subscribe(async (data) => {
        if (data.length > 0) {
          data = data.filter(
            (d) =>
              d.status == this.projectStatusEnum.ACTIVE ||
              (d.status == this.projectStatusEnum.INACTIVE &&
                this.convertToTime(d.endDate) >=
                new Date().getTime() - 2629800000)
          );
        }
        this.selectArray = data;

        if (this.fundToRender.project) {
          this.projectExists = true;
          let indexProject = this.selectArray.findIndex(
            (project) => project.key == this.fundToRender.project.id
          );

          if (!this.selectArray[indexProject]) {
            return;
          }

          this.fundToRenderForm.patchValue({
            project: this.selectArray[indexProject]
          });

          this.fundToRender.project = this.selectArray[indexProject];
        }
      });
  }

  private async loadUsers() {
    this.usersSubscription = this._user.getAllUndeleted().subscribe((users) => {
      this.team = users.filter((user) => {
        if (!user.permissions) return false;
        let section = user.permissions.find(
          (per) => per.section == 'FONDOS POR RENDIR'
        );
        return !!section;
      });
    });
  }

  patchAssignedUser() {
    if (this.isEdit) {
      this.fundToRenderForm.patchValue({
        ...this.fundToRender
      });
    }
  }

  addBreakdownAmountControls() {
    for (let i = 1; i < this.fundToRender.breakdownAmounts.length; i++) {
      this.addBreakdownAmountControl();

      if (
        this.permission == this.permissionEnum.ADMIN ||
        this.permission == this.permissionEnum.SUPERVISOR ||
        (this.permission == this.permissionEnum.USER &&
          this.fundToRender.status != this.fundToRenderStatusEnum.DRAFT)
      ) {
        this.breakdownAmounts.controls[i].disable();
      }
    }
  }

  calculateRequiredAmount() {
    if (!this.isEdit) return;
    this.requiredAmount = this.fundToRender.breakdownAmounts.reduce(
      (acc, {amount}) => acc + amount,
      0
    );
  }

  async submit() {
    this.breakdownAmounts.patchValue(
      this.formatBreakdownAmountsCategories(this.breakdownAmounts.value)
    );

    for (const breakdownAmount of this.breakdownAmounts.value) {
      this.allAmounts.push(breakdownAmount.amount);
    }

    let sumAmounts = (accumulator, curr) => accumulator + curr;
    this.totalAmount = this.allAmounts.reduce(sumAmounts);

    if (this.totalAmount < this.pendingAccountability) {
      return AlertService.toastError(
        'La suma total del valor de las categorías debe ser mayor a su adeudo pendiente'
      );
    }

    this.fundToRenderForm.patchValue({
      title: this.fundToRenderForm.value.title.trim(),
      reason: this.fundToRenderForm.value.reason.trim()
    });

    if (!this.fundToRenderForm.valid) {
      return AlertService.toastError('Verifique todos los campos');
    }

    this.fundToRenderForm.patchValue({
      project: this._project.getReference(
        this.fundToRenderForm.value.project.key
      )
    });

    this.isEdit ? await this.update() : await this.add();

    AlertService.toastSuccess('Los datos se guardaron correctamente');
    this.modal.hide();
  }

  private async add() {
    if (!!this.pendingAccountability) {
      this.fundToRenderForm.patchValue({
        pendingAccountability: this.pendingAccountability
      });
    }

    this.fundToRenderForm.patchValue({
      status: this.fundToRenderStatusEnum.DRAFT,
      user: this._user.getReference(this.user.key)
    });

    await this._fundToRender.add(this.fundToRenderForm.value);

    if (!!this.fundToRenderRequest) {
      await this._requestNewFundToRender.update(this.fundToRenderRequest.key, {
        status: this.fundToRenderRequestStatusEnum.COMPLETED
      } as RequestNewFundToRender);
    }
  }

  private async update() {
    this.fundToRenderForm.patchValue({
      user: this._user.getReference(this.fundToRenderForm.value.user.id)
    });
    await this._fundToRender.update(
      this.fundToRender.key,
      this.fundToRenderForm.value
    );
  }

  convertToTime(date) {
    if (date == null) return null;

    let newDeliveryDate = date.replaceAll('-', '/');
    return new Date(newDeliveryDate).getTime();
  }

  formatBreakdownAmountsCategories(breakdownAmounts: BreakdownAmount[]) {
    return breakdownAmounts.map((breakdownAmount) => ({
      ...breakdownAmount,
      category: !!(<DocumentReference>breakdownAmount.category).id
        ? breakdownAmount.category
        : this._breakdownAmountCategory.getReference(
          `breakdownAmountCategory/${(<any>breakdownAmount.category).key}`
        ),
      description: breakdownAmount.description.trim()
    }));
  }

  async changeFundToRenderStatus() {
    switch (this.fundToRender.status) {
      case FundToRenderStatusEnum.DRAFT:
        if (
          !(await AlertService.confirm(
            '¿Estás seguro de que deseas generar este fondo por rendir?'
          ))
        ) {
          return;
        }

        this.settings = await this._fundToRender.getSettings();
        if (!this.settings) {
          let startID = 0;
          this._fundToRender.setSettings({fundToRenderId: startID});
        }

        this.submit();

        await this._fundToRender.update(this.fundToRender.key, {
          status: this.fundToRenderStatusEnum.GENERATED,
          id: this.settings.fundToRenderId + 1
        } as FundToRender);

        this._fundToRender.updateSettings({
          fundToRenderId: this.settings.fundToRenderId + 1
        });

        if (!!this.emailSupervisors.length) {
          await this.sendNotificationEmail(
            `Se ha generado un nuevo fondo por rendir`,
            this.emailSupervisors,
            `Fondos por rendir: fondo por rendir ${this.fundToRender.title} generado`
          );
        }

        break;

      case FundToRenderStatusEnum.GENERATED:
        if (
          !(await AlertService.confirm(
            '¿Estás seguro de que deseas mandar a revisión este fondo por rendir?'
          ))
        ) {
          return;
        }

        await this._fundToRender.update(this.fundToRender.key, {
          status: this.fundToRenderStatusEnum.REVIEW
        } as FundToRender);
        this._logs.addFundsToRender(
          `Se cambió el status del fondo por rendir #${
            this.fundToRender.id
          } de ${this.fundToRenderStatusLabel[this.fundToRender.status]} a ${
            this.fundToRenderStatusLabel[this.fundToRenderStatusEnum.REVIEW]
          }`
        );
        break;

      case FundToRenderStatusEnum.REVIEW:
        if (
          !(await AlertService.confirm(
            '¿Estás seguro de que deseas autorizar este fondo por rendir?'
          ))
        ) {
          return;
        }

        await this._fundToRender.update(this.fundToRender.key, {
          status: this.fundToRenderStatusEnum.AUTHORIZED
        } as FundToRender);
        this._logs
          .addFundsToRender(`Se cambió el status del fondo por rendir #${
            this.fundToRender.id
          } de ${this.fundToRenderStatusLabel[this.fundToRender.status]}
           a ${
            this.fundToRenderStatusLabel[
              this.fundToRenderStatusEnum.AUTHORIZED
              ]
          }`);
        break;

      case FundToRenderStatusEnum.AUTHORIZED:

        let receivedAmount = 0;
        if (!!this.fundToRenderForm.value.pendingAccountability) {
          if (
            !(await AlertService.confirm(
              `¿Estás seguro de que deseas marcar como pagado este fondo por rendir?`,
              'El cliente tiene una deuda del fondo anterior, favor de tomar en consideración ya que al aceptar su deuda se saldará',
              'Pagar'
            ))
          ) {
            return;
          }
          receivedAmount = (this.requiredAmount - this.fundToRenderForm.value.pendingAccountability);

          await this._user.update((this.fundToRender.user as DocumentReference).id ?? (this.fundToRender.user as User).key, {
            pendingAccountability: 0
          } as User);
        }

        if (!this.fundToRenderForm.value.pendingAccountability) {
          if (
            !(await AlertService.confirm(
              '¿Estás seguro de que deseas marcar como pagado este fondo por rendir?'
            ))
          ) {
            return;
          }
          receivedAmount = this.requiredAmount;
        }

        await this._fundToRender.update(this.fundToRender.key, {
          status: this.fundToRenderStatusEnum.PAID,
          receivedAmount
        } as FundToRender);
        this._logs.addFundsToRender(
          `Se cambió el status del fondo por rendir #${this.fundToRender.id}
          de ${this.fundToRenderStatusLabel[this.fundToRender.status]}
          a ${this.fundToRenderStatusLabel[this.fundToRenderStatusEnum.PAID]}`
        );

        await this.sendFundToRenderPaidEmailToUser(
          this.fundToRender.user as DocumentReference
        );

        break;
    }

    this.modal.hide();
  }

  async sendFundToRenderPaidEmailToUser(userReference: DocumentReference) {
    const user = await this._user
      .getWithId$(userReference.id)
      .pipe(first())
      .toPromise();
    if (!user || !!user.isDisable || !!user.trash) return;
    await this.sendNotificationEmail('Su fondo por rendir ha sido pagado',
      [user.email],
      `Fondos por rendir: fondo por rendir ${this.fundToRender.title} pagado`
    );
  }

  async sendNotificationEmail(text: string, emails: string[], subject: string) {
    try {
      await this._notification.sendNotificationEmail(
        text,
        subject,
        emails
      );
    } catch (e) {
      console.log(e);
    }
  }

  async rejectFundToRender() {
    let [rejectReason] = await AlertService.withHtml(
      'Solicitud de fondo por rendir',
      `<label>Razón del rechazo:</label>
      <textarea id="swal-input1" rows="3" class="swal2-input"></textarea>`,
      function () {
        return new Promise(function (resolve) {
          resolve([$('#swal-input1').val()]);
        });
      }
    );
    await this._fundToRender.update(this.fundToRender.key, {
      status: this.fundToRenderStatusEnum.REJECTED,
      rejectReason: rejectReason
    } as FundToRender);

    this._logs.addFundsToRender(
      `Se cambió el status del fondo por rendir #${this.fundToRender.id}
       de ${this.fundToRenderStatusLabel[this.fundToRender.status]}
       a ${this.fundToRenderStatusLabel[this.fundToRenderStatusEnum.REJECTED]}`
    );
    this.modal.hide();
  }

  async chooseFile(event) {
    await this.getProjects();

    if (
      event.target.files[0].name.slice(event.target.files[0].name.length - 3) !=
      'pdf'
    ) {
      return AlertService.error('Solo se permiten archivos pdf');
    }

    this.file = event.target.files[0];

    if (event.target.files && this.file) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.path = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(this.file);
      this.uploadFile = true;
    }

    this.fundToRenderForm.patchValue({pdf: this.file});

    await this.uploadPdf();
    this.supervisorAttachVoucher();
  }

  private async uploadPdf() {
    if (this.uploadFile) {
      this.fundToRenderForm.patchValue({
        pdf: await this._fundToRender.uploadPdf(
          this.fundToRenderForm.value.pdf,
          this.fundToRender.key
        )
      });

      this.fundToRenderForm.patchValue({
        project: !!this.fundToRenderForm.value.project.key
          ? this._project.getReference(this.fundToRenderForm.value.project.key)
          : null
      });
      await this._fundToRender.update(
        this.fundToRender.key,
        this.fundToRenderForm.value as FundToRender
      );
    }

    if (!!this.fundToRenderForm.value.pdf) {
      this.getUrlPdf(this.fundToRenderForm.value.pdf);
    }
    if (!!this.fundToRender.pdf) {
      this.getUrlPdf(this.fundToRender.pdf);
    }
  }

  getUrlPdf(url: any) {
    this.url = this.sanitizer.bypassSecurityTrustHtml(
      `<iframe width="100%" height="400" src="${url}"></iframe>`
    );
  }

  userDraft() {
    return (
      this.permission == this.permissionEnum.USER &&
      this.fundToRender.status == this.fundToRenderStatusEnum.DRAFT &&
      !!this.isEdit
    );
  }

  sendToSupervision() {
    return (
      this.permission == this.permissionEnum.USER &&
      this.fundToRenderForm.value.status ==
      this.fundToRenderStatusEnum.GENERATED
    );
  }

  sendToReviewAdmin() {
    return (
      this.permission == this.permissionEnum.SUPERVISOR &&
      this.fundToRender.status == this.fundToRenderStatusEnum.GENERATED
    );
  }

  validateFundToRender() {
    return (
      this.permission == this.permissionEnum.ADMIN &&
      this.fundToRender.status == this.fundToRenderStatusEnum.REVIEW
    );
  }

  supervisorAttachVoucher() {
    return (
      this.permission == this.permissionEnum.SUPERVISOR &&
      this.fundToRender.status == this.fundToRenderStatusEnum.AUTHORIZED &&
      (!!this.fundToRenderForm.value.pdf || !!this.fundToRender.pdf)
    );
  }

  hideUserSaveButton() {
    return (
      this.permission == this.permissionEnum.USER &&
      this.fundToRenderForm.value.status == this.fundToRenderStatusEnum.DRAFT
    );
  }

  getStatusClass(status: number) {
    switch (status) {
      case FundToRenderStatusEnum.DRAFT:
        return 'badge-primary';

      case FundToRenderStatusEnum.GENERATED:
        return 'badge-success';

      case FundToRenderStatusEnum.REVIEW:
        return 'badge-warning';

      case FundToRenderStatusEnum.AUTHORIZED:
        return 'badge-info';

      case FundToRenderStatusEnum.PAID:
        return 'badge-success';

      case FundToRenderStatusEnum.RENDERED:
        return 'badge-warning';

      case FundToRenderStatusEnum.CLOSED:
        return 'badge-danger';

      case FundToRenderStatusEnum.CANCELLED:
        return 'badge-dark';

      case FundToRenderStatusEnum.REJECTED:
        return 'badge-dark';

      default:
        return 'badge-success';
    }
  }

  hasSupervisorPermission() {
    return this.permission == this.permissionEnum.SUPERVISOR || this.permission == this.permissionEnum.ADMIN;
  }

  async changeStatusToDraft() {
    if (await AlertService.confirm('¿Está seguro de cambiar el estado a borrador?')) {
      const comment = await AlertService.input('Comentario', 'Ingrese un comentario para el cambio a borrador');
      if (!comment) return AlertService.error('Debe ingresar un comentario');

      await this._fundToRender.update(this.fundToRender.key, {
        status: this.fundToRenderStatusEnum.DRAFT,
        commentReturnedToDraft: comment
      } as FundToRender);
    }
  }
}
