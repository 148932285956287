<app-datatable [rows$]="logs$" [config]="config">
  <app-datatable-column title="Descripción">
    <ng-template let-row #column>
      {{ row.description }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha">
    <ng-template let-row #column>
      {{ row.createdAt | date: 'dd/MM/yyyy h:mm a' }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Usuario">
    <ng-template let-row #column>
      {{ (row.user | doc | async)?.name }}
    </ng-template>
  </app-datatable-column>
</app-datatable>
