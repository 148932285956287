import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pagination'
})
export class PaginationPipe implements PipeTransform {

  transform(array: any[], page: number, pageLimit: number): any[] {
    if (!array) return [];

    let start = (page - 1) * pageLimit;
    let end = page * pageLimit;

    return array.slice(start, end);
  }

}
