<div class="p-2">
  <h2>Editar proyecto</h2>

  <form [formGroup]="editProjectForm">
    <div class="row mt-2">
      <div class="col-md-6">
        <div class="form-group">
          <label>Nombre *</label>
          <input type="text" class="form-control" formControlName="name"
                 placeholder="" [ngClass]="{'is-invalid': submitted && formControls.name.errors}"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.name.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Tipo *</label>
          <select class="form-control"
                  formControlName="status"
                  [ngClass]="{ 'is-invalid': submitted && formControls.status.errors }">
            <option [value]="type.key" *ngFor="let type of projectType | keyvalue">{{ type.value }}</option>
          </select>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.status.errors">
            <div *ngIf=" formControls.status.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Centro de costo *</label>
          <input type="text"
                 class="form-control"
                 formControlName="code"
                 disabled/>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <label>Cliente</label>
      <ng-select formControlName="client"
                 [items]="clients"
                 bindLabel="name"
                 appendTo="body">
      </ng-select>
    </div>
    <div class="row mt-2">
      <div class="col-md-6">
        <div class="form-group">
          <label>Fecha de inicio *</label>
          <input type="date" class="form-control" formControlName="startDate"
                 placeholder=""/>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Fecha de finalización *</label>
          <input type="date"
                 class="form-control"
                 formControlName="endDate"
                 placeholder="introduzca la fecha de finalización" [ngClass]="{'is-invalid': submitted && formControls.endDate.errors}"/>
          <small class="invalid-feedback">Campo obligatorio</small>
        </div>
      </div>

      <div class="col-md-6">
        <label>Valor de venta</label>
        <input type="number" class="form-control" formControlName="saleValue">
      </div>

      <div class="col-md-6" *ngIf="!!project.activeLateEndDate || (project.status == projectStatus.EXPIRATED && editProjectForm.value.status == projectStatus.ACTIVE)">
        <div class="form-group">
          <label>Nueva fecha de terminación *</label>
          <input type="date" class="form-control" formControlName="activeLateEndDate"
                 placeholder="Ingrese una nueva fecha de finalización"/>
        </div>
      </div>
    </div>
    <div class="modal-footer border-0">
      <button class="btn btn-danger cancel-btn" (click)="changeToInactive()" *ngIf="project.status == projectStatus.ACTIVE">Pasar a inactivo</button>
      <button class="btn btn-success add-btn" (click)="submit()">Agregar</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide()">Cancelar</div>
    </div>
  </form>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
             type="square-jelly-box"><p style="color: white"> Ejecutando proceso de inactividad del proyecto... </p></ngx-spinner>
