import { Injectable } from '@angular/core';
import { FirebaseDataService } from '../template-services/firebase-data.service';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Job } from '../../admin/interfaces/job';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(private db: FirebaseDataService,
    private afs: AngularFirestore) {
  }

  getAll(): Observable<Job[]> {
    return this.db.colWithIds$<Job>('positions', ref => ref
      .where('trash', '==', false)
      .orderBy('position', 'asc'));
  }

  update(positionKey: string, position: Job): Promise<void> {
    return this.afs.doc<Job>(`positions/${positionKey}`).update(position);
  }

  delete(positionKey: string): Promise<void> {
    return this.afs.doc<Job>(`positions/${positionKey}`).update({ trash: true });
  }

  add(position: Job) {
    this.afs.collection('positions').add(position);
  }

  getReference(positionKey: string): DocumentReference {
    return this.afs.doc(`positions/${positionKey}`).ref;
  }

  get(positionKey: string): Promise<Job> {
    return this.afs.doc<Job>(`positions/${positionKey}`).valueChanges().pipe(take(1)).toPromise();
  }
}
