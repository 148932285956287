import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Ticket} from '../../interfaces/ticket';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Warehouse} from '../../interfaces/warehouse';
import {Article} from '../../interfaces/article';
import {ArticleService} from '../../../../../shared/services/article.service';
import {TicketStatus} from '../../enums/ticket-status.enum';
import {TicketService} from '../../../../../shared/services/ticket.service';
import {AlertService} from '../../../../../shared/template-services/alert.service';
import {NotificationService} from '../../../../services/notification.service';
import {UserService} from '../../../../../shared/services/user.service';
import {NotificationType} from '../../../../enums/notification-type.enum';
import {WarehouseService} from '../../../../../shared/services/warehouse.service';
import {map, take} from 'rxjs/operators';
import {User as UserInterface, User} from '../../../../../shared/interfaces/user';
import {UserType} from '../../../../enums/user-type.enum';
import {ProjectService} from '../../../../../shared/services/project.service';
import {Subscription} from 'rxjs';
import {PrintService} from '../../../../../shared/services/print.service';
import {TicketStatusLabel} from '../../labels/ticket-status.label';
import {LogsService} from '../../../../services/logs.service';
import {TicketAuthorizationService} from '../../../../../shared/services/ticket-authorization.service';
import {ArticleType} from '../../enums/article-type.enum';
import {SelectSerialsNumberComponent} from '../../../../modals/select-serials-number/select-serials-number.component';
import {FormBuilder} from '@angular/forms';
import {AuthService} from '../../../../../shared/template-services/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Project} from '../../../../interfaces/project';
import {Permission} from '../../../../enums/permissions.enum';
import {PermissionService} from '../../services/permission.service';

type TicketEmail = {
  text: string,
  emails: string[],
  userKeys: string[],
  subject: string
}

@Component({
  selector: 'app-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.css']
})
export class TicketDetailComponent implements OnInit, OnDestroy {
  @ViewChild('componentID') componentID: ElementRef;
  ticket: Ticket;
  unknownWarehouse: boolean;
  ticketAuthorizationReferences: any = [];
  user: User;
  warehouse: Warehouse;
  articleReferences: Article[];
  TicketStatus = TicketStatus;
  option = [];
  eventChangeToTab: EventEmitter<string> = new EventEmitter<string>();
  priceArticle: number = 0;
  totalArticle: number = 0;
  articles = [];
  articleSelected: Article;
  total: number = 0;
  quantity: number = 0;
  article: string = '';
  ticketStatusLabel = TicketStatusLabel;
  areAllConfirm: boolean = false;
  articleQuantityEdit = [];
  requestsReference: any = [];
  permission: UserType;
  permissionEnum = UserType;
  articleWithSeriesNumbersSelected: { key: string; article: any }[] = [];
  quantityAvailable: boolean = true;
  currentUser: any;
  submitted: boolean = false;
  comment: string = '';
  selectArrayProjects: any = [];
  users: UserInterface[];
  projectSubscription: Subscription = new Subscription();
  userSubscription: Subscription = new Subscription();
  projectSelected: any;
  userSelected: any;
  categories = [];
  projectCategorySelected;

  constructor(public modal: BsModalRef,
              private _article: ArticleService,
              private _ticket: TicketService,
              private _user: UserService,
              private _project: ProjectService,
              private _warehouse: WarehouseService,
              private _notification: NotificationService,
              private _log: LogsService,
              private _auth: AuthService,
              private _ticketAuthorized: TicketAuthorizationService,
              private formBuilder: FormBuilder,
              private modalService: BsModalService,
              private SpinnerService: NgxSpinnerService,
              private _permission: PermissionService) {
  }

  async ngOnInit() {
    this.getUsers();
    if (this.ticket.status == TicketStatus.DRAFT) {
      this.getProjects();
      this.comment = this.ticket.comments;
    }

    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'BODEGAS'
    ).permission;

    if (this.permission != UserType.GROCER && this._permission.hasUserType(Permission.GROCER as any)) {
      this.permission = UserType.GROCER;
    }

    if (this.unknownWarehouse) {
      await this.loadWarehouse();
    }

    if (this.permission != this.permissionEnum.ADMIN) {
      let supervisor = false;
      let grocer = false;

      if (this.warehouse.grocer.reference.id == this._user.user.key) {
        grocer = true;
      }
      for (let warehouseManager of this.warehouse.warehouseManager) {
        if (warehouseManager.reference.id == this._user.user.key) {
          supervisor = true;
        }
      }

      if (!supervisor && !grocer) this.permission = this.permissionEnum.USER;
    }

    this.user = this._user.user;
    if (this.ticket.status == TicketStatus.AUTHORIZED) {
      this.ticket.articles = this.ticket.articles.map((article) => ({
        ...article,
        isConfirm: false
      }));
    }

    if (this.ticket.status == TicketStatus.COLLECTED) {
      this.ticket.articles = this.ticket.articles.map((article) => ({
        ...article,
        returned: 0,
        isComplete: false,
        error: false
      }));
    }

    if (this.ticket.status == TicketStatus.INCOMPLETE) {
      this.ticket.articles = this.ticket.articles.map((article) => ({
        ...article,
        returnedLabel: article.returned
      }));
    }

    for (let article of this.ticket.articles) {
      let articleQuantity = {
        key: article.key,
        quantity: article.quantity,
        quantityOrdered: article.quantityOrdered
      };
      this.articleQuantityEdit.push(articleQuantity);
    }

    this.requestsReference = this.requestsReference
      .filter(
        (ticketAuthorization) => ticketAuthorization.userKey == this.user.key
      )
      .filter(
        (ticketAuthorization) =>
          ticketAuthorization.ticketKey == this.ticket.key
      );
    this.ticketAuthorizationReferences = this.ticketAuthorizationReferences
      .filter(
        (ticketAuthorization) => ticketAuthorization.userKey == this.user.key
      )
      .filter(
        (ticketAuthorization) =>
          ticketAuthorization.ticketKey == this.ticket.key
      );
  }

  ngOnDestroy() {
    this.projectSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  getProjects() {
    this.projectSubscription = this._project
      .getAll()
      .pipe(
        map((project) =>
          project.map((project) => ({
            name: project.name,
            key: project.key,
            budgets: project.budgets
          }))
        )
      )
      .subscribe((data) => {
        this.selectArrayProjects = data;
        this.projectSelected = this.selectArrayProjects.find(
          (project) => project.key == this.ticket.projects.id
        );
        this.categories = this.projectSelected.budgets;
        this.projectCategorySelected = this.categories.find(
          (category) => category.reference.id == this.ticket.projectCategory.id
        );
      });
  }

  private getUsers() {
    this.userSubscription = this._user.getUsers().subscribe((data) => {
      this.users = data;
      this.userSelected = this.users.find(
        (user) => user.key == this.ticket.user.id
      );
    });
  }

  async loadWarehouse() {
    this.warehouse = await this._warehouse
      .get(this.ticket.warehouse.id)
      .pipe(take(1))
      .toPromise();
  }

  print(): void {
    PrintService.print(this.componentID.nativeElement);
    this._log.add(this.warehouse.key, {
      description: `Se imprimió el ticket ${this.ticket.ticketID.slice(0, -3)}`
    });
  }

  async confirmTicket() {
    if (this.ticket.articles.findIndex((article) => !article.error) == -1) {
      return;
    }

    switch (this.ticket.status) {
      case TicketStatus.DRAFT:
        this.generateDraft();
        break;

      case TicketStatus.GENERATED:
        this._log.add(this.warehouse.key, {
          description: `Ticket ${this.ticket.ticketID.slice(
            0,
            -3
          )} pasó a status AUTORIZADO`
        });

        this.ticket.lastUpdate = new Date().getTime();

        await this.authorizeTicket();

        for (let i = 0; i <= this.ticket.articles.length - 1; i++) {
          let surname = this.user.surnames ? this.user.surnames : '';
          let record = {
            action:
              this.ticket.articles[i].quantity == 1
                ? `Se solicitó un ${this.ticket.articles[i].name} (${this.ticket.articles[i].internalId}) por ${this.user.name} ${surname}`
                : `Se solicitaron ${this.ticket.articles[i].quantity} ${this.ticket.articles[i].name} por ${this.user.name} ${surname}`,
            date: new Date().getTime(),
            trash: false
          };

          await this._log.addRecord(
            this.warehouse.key,
            this.ticket.articles[i].key,
            record
          );
        }

        break;

      case TicketStatus.AUTHORIZED:
        await this.retireTicket();
        break;

      case TicketStatus.COLLECTED:
        let isComplete = this.ticket.articles.findIndex(
          (article) => article.isComplete
        );

        if (isComplete >= 0) {
          if (
            await AlertService.confirm(
              '¿Estás seguro que todos los artículos entregados están en buenas condiciones?',
              ''
            )
          ) {
            this._log.add(this.warehouse.key, {
              description: `Ticket ${this.ticket.ticketID.slice(
                0,
                -3
              )} pasó a status DEVUELTO`
            });
            this.ticket.lastUpdate = new Date().getTime();
            await this.returnTicket();
          }
        }

        if (isComplete == -1) {
          await this.returnTicket();
        }
        break;

      case TicketStatus.INCOMPLETE:
        this._log.add(this.warehouse.key, {
          description: `Ticket ${this.ticket.ticketID.slice(
            0,
            -3
          )} pasó a status INCOMPLETO`
        });
        this.ticket.lastUpdate = new Date().getTime();
        await this.returnTicketIncomplete();
        break;
    }
  }

  private async generateDraft() {
    await this.changeQuantity();

    if (!this.quantityAvailable) {
      return;
    }

    this._log.add(this.warehouse.key, {
      description: `Ticket ${this.ticket.ticketID.slice(
        0,
        -3
      )} pasó a status GENERADO`
    });
    this.ticket.status = TicketStatus.GENERATED;
    this.ticket.lastUpdate = new Date().getTime();
    AlertService.toastSuccess(`El ticket fue generado`, '');
    await this._ticket.update(this.ticket.key, this.ticket);
    for (let warehouseManager of this.warehouse.warehouseManager) {
      let user = await this._user.getWithId$(
        warehouseManager.reference.id
      ).pipe(take(1)).toPromise();
      if (!!user) {
        if (warehouseManager.reference.id != this._user.user.key) {
          await this.notificationTicketChanged(
            {
              text: `${
                this._user.user.name
              } ha generado el ticket ${this.ticket.ticketID.slice(0, -3)}`,
              emails: [user.email],
              userKeys: [warehouseManager.reference.id],
              subject: `Bodegas: Ticket ${this.ticket.ticketID.slice(0, -3)} generado`
            }
          );
        }
      }
    }
  }

  async notificationTicketChanged({text, emails, userKeys, subject}: TicketEmail) {
    for (let key of userKeys) {
      this._notification.setUser(key, {
        createdDate: new Date().getTime(),
        description: text,
        type: NotificationType.USER_MESSAGE,
        redirectUrl: `admin/warehouseDetails/${this.warehouse.key}`,
        readed: false,
        trash: false
      });
    }
    await this._notification.sendNotificationTicketEmail(
      text,
      subject,
      emails,
      `/admin/warehouseDetails/${this.warehouse.key}/tickets/${this.ticket.key}`
    );
  }

  private async authorizeTicket() {
    this.ticket.status = TicketStatus.AUTHORIZED;
    this.ticket.supervisor = await this._user.getReference(this._user.user.key);

    AlertService.toastSuccess(`El ticket fue autorizado`, '');

    this.ticket['grocer'] = !!this.warehouse.grocer
      ? this.warehouse.grocer
      : '';

    this.ticket.lastUpdate = new Date().getTime();

    await this._ticket.update(this.ticket.key, this.ticket);

    const resp = this.getEmailAndKeyFromUsersTicket();

    let [userKeys, emails] = resp;

    if (userKeys.length > 0 && emails.length > 0) {
      await this.notificationTicketChanged(
        {
          text: `${
            this._user.user.name
          } ha autorizado el ticket ${this.ticket.ticketID.slice(0, -3)}`,
          emails,
          userKeys,
          subject: `Bodegas: Ticket ${this.ticket.ticketID.slice(0, -3)} autorizado`
        }
      );
    }
  }

  removeDuplicateUsers(users: any[]): any {
    return users.filter(
      (user, index) => users.findIndex((data) => data.key == user.key) == index
    );
  }

  async returnedArticles() {
    for (const article of this.ticket.articles) {
      if (!article.isComplete) {
        const index = this.articleReferences.findIndex(
          (x) => x.name == article.name
        );
        await this._article.updateQuantity(
          this.warehouse.key,
          article.key,
          this.articleReferences[index].quantity + article.returned
        );
        article.returned = article.returned + article.returnedLabel;
      }
      if (article.returned == article.quantity) {
        article.isComplete = true;
      }
    }
  }

  getEmailAndKeyFromUsersTicket() {
    let users = this.users.filter(
      (user) =>
        (!!this.ticket.grocer && user.key == this.ticket.grocer.reference.id) ||
        (!!this.ticket.supervisor && user.key == this.ticket.supervisor.id) ||
        (!!this.ticket.user && user.key == this.ticket.user.id)
    );

    if (users.length > 0) {
      users = this.removeDuplicateUsers(users);
      let userKeys = users.map((user) => user.key);
      let emails = users.map((user) => user.email);

      return [userKeys, emails];
    } else {
      return [[], []];
    }
  }

  private async retireTicket() {
    if (
      !(await AlertService.confirm(
        `¿Estás seguro que deseas retirar todos los artículos del ticket #${this.ticket.ticketID.slice(
          0,
          -3
        )}?`
      ))
    ) {
      return;
    }
    if (
      this.permission == this.permissionEnum.USER ||
      this.permission == this.permissionEnum.SUPERVISOR
    ) {
      return AlertService.info(
        `Solo el bodeguero puede confirmar retirada y entrega de artículos`,
        ''
      );
    }
    if (this.ticket.articles.findIndex((article) => !article.isConfirm) != -1) {
      return AlertService.toastError(
        `Se deben de confirmar todos los artículos`,
        ''
      );
    }

    this.SpinnerService.show();

    this.ticket.status = TicketStatus.COLLECTED;
    this.ticket.lastUpdate = new Date().getTime();

    await this._ticket.update(this.ticket.key, this.ticket);

    let [userKeys, emails] = this.getEmailAndKeyFromUsersTicket();
    if (userKeys.length > 0 && emails.length > 0) {
      await this.notificationTicketChanged(
        {
          text: `${
            this._user.user.name
          } ha retirado el ticket ${this.ticket.ticketID.slice(0, -3)}`,
          emails,
          userKeys,
          subject: `Bodegas: Ticket ${this.ticket.ticketID.slice(0, -3)} retirado`
        }
      );
    }

    await this.changeBudgetLeftTotal();
    await this.saveLog();
    await this.subtractQuantityArticles();
    await this.sendNotifications();
    await this.updateSeriesNumbersSelected();
    this.calculateDecrementWarehouseTotal();

    this.SpinnerService.hide();

    AlertService.toastSuccess(`El ticket fue aceptado`, '');
    this.eventChangeToTab.emit('collected');
  }

  async changeBudgetLeftTotal() {
    let project = await this._project
      .get(this.ticket.projects.id)
      .pipe(take(1))
      .toPromise();
    let total = 0;

    for (const article of this.ticket.articles) {
      if (article.type == ArticleType.CONSUMABLE) {
        total += article.price * article.quantity;
      }
    }

    project.budgets = project.budgets.map((budget) => {
      if (budget.reference.id != this.ticket.projectCategory.id) return budget;
      return {
        ...budget,
        usedBudget: budget.usedBudget + total
      };
    });

    this._project.update(this.ticket.projects.id, {
      budgets: project.budgets
    } as Project);
  }

  private async sendNotifications() {
    this._notification.sendTicketCollectedEmail(this.ticket);

    const user = await this._user.getSpecificUser(this.ticket.user.id);
    const warehouse = await this._warehouse
      .get(this.ticket.warehouse.id)
      .pipe(take(1))
      .toPromise();

    this._notification.set({
      redirectUrl: `admin/warehouseDetails/${this.ticket.warehouse.id}`,
      description: `El usuario "${user.name}" ha retirado artículos de la bodega "${warehouse.name}"`,
      createdDate: new Date().getTime(),
      trash: false,
      readed: false,
      type: NotificationType.TICKET_COLLECTED
    });
  }

  private async saveLog() {
    this._log.add(this.warehouse.key, {
      description: `Ticket ${this.ticket.ticketID.slice(
        0,
        -3
      )} pasó a status RETIRADO`
    });
    this.ticket.lastUpdate = new Date().getTime();

    this._warehouse.update(this.warehouse.key, {
      lastUpdate: new Date().getTime()
    } as Warehouse);

    for (let i = 0; i <= this.ticket.articles.length - 1; i++) {
      let surname = this.user.surnames ? this.user.surnames : '';
      let record = {
        action:
          this.ticket.articles[i].quantity == 1
            ? `Se retiró un ${this.ticket.articles[i].name} (${this.ticket.articles[i].internalId}) por ${this.user.name} ${surname}`
            : `Se retiraron ${this.ticket.articles[i].quantity} ${this.ticket.articles[i].name} por ${this.user.name} ${surname}`,
        date: new Date().getTime(),
        trash: false
      };

      await this._log.addRecord(
        this.warehouse.key,
        this.ticket.articles[i].key,
        record
      );
    }
  }

  calculateDecrementWarehouseTotal() {
    this._warehouse.update(this.warehouse.key, {
      totalRetired: this.warehouse.totalRetired + this.ticket.total,
      totalStored: this.warehouse.totalStored - this.ticket.total
    } as Warehouse);
  }

  private async returnTicket() {
    this.ticket.status = TicketStatus.RETURNED;

    if (this.ticket.articles.findIndex((article) => !article.isComplete) >= 0) {
      this.ticket.status = TicketStatus.INCOMPLETE;
    }

    await this.addQuantityArticles();

    this.ticket.lastUpdate = new Date().getTime();
    this._ticket.update(this.ticket.key, this.ticket);
    AlertService.toastSuccess(`Ticket actualizado`, '');
  }

  private async returnTicketIncomplete() {
    if (this.permission != this.permissionEnum.GROCER) {
      return AlertService.info(
        `Solo el bodeguero puede confirmar retirada y entrega de artículos`,
        ''
      );
    }
    this.ticket.status = TicketStatus.INCOMPLETE;

    await this.returnedArticles();

    if (
      this.ticket.articles.findIndex((article) => !article.isComplete) == -1
    ) {
      this.ticket.status = TicketStatus.RETURNED;
    }

    AlertService.toastSuccess(`Ticket actualizado`, '');
    this.ticket.lastUpdate = new Date().getTime();
    this._ticket.update(this.ticket.key, this.ticket);
  }

  handleErrorInput(index: number) {
    if (this.ticket.status == TicketStatus.COLLECTED) {
      this.ticket.articles[index].error =
        this.ticket.articles[index].returned >
        this.ticket.articles[index].quantity ||
        this.ticket.articles[index].returned < 0;
    }
    if (this.ticket.status == TicketStatus.INCOMPLETE) {
      this.ticket.articles[index].error =
        this.ticket.articles[index].quantity >
        this.ticket.articles[index].returnedLabel ||
        this.ticket.articles[index].returned < 0;
    }
  }

  async isConfirm(article: Article, articleIndex: number) {
    const indexIsConfirm = this.ticket.articles.findIndex(
      (article) => !article.isConfirm
    );
    if (indexIsConfirm == -1) {
      this.areAllConfirm = true;
    }

    if (!this.ticket.articles[articleIndex].isConfirm) {
      return;
    }

    this.SpinnerService.show();
    const articleDb: any = await this._article
      .get(this.warehouse.key, article.key)
      .pipe(take(1))
      .toPromise();
    this.SpinnerService.hide();

    if (
      article.type == ArticleType.STORABLE &&
      !!articleDb.serialNumber &&
      articleDb.serialNumber.length > 0
    ) {
      const modal: BsModalRef = this.modalService.show(
        SelectSerialsNumberComponent,
        {
          initialState: {
            article: articleDb,
            quantity: this.articleQuantityEdit[articleIndex].quantity
          },
          id: 2,
          keyboard: false,
          backdrop: 'static'
        }
      );

      const serialNumbersSelected = await modal.content.serialNumbersEvent
        .pipe(take(1))
        .toPromise();
      this.ticket.articles[articleIndex]['serialNumbersSelected'] =
        serialNumbersSelected;

      if (!articleDb.serialNumberCollected) {
        articleDb['serialNumberCollected'] = serialNumbersSelected;
      } else {
        // articleDb.serialNumberCollected = _.union(articleDb.serialNumberCollected, serialNumbersSelected);
        for (let serial of serialNumbersSelected) {
          articleDb.serialNumberCollected.push(serial);
        }
      }

      this.articleWithSeriesNumbersSelected.push({
        key: article.key,
        article: articleDb
      });
    }
  }

  async subtractQuantityArticles() {
    for (const article of this.ticket.articles) {
      // const index = this.articleReferences.findIndex(x => x.key == article.key);

      let actualArticle: any = await this._article
        .get(this.warehouse.key, article.key)
        .pipe(take(1))
        .toPromise();

      await this._article.updateQuantity(
        this.warehouse.key,
        article.key,
        actualArticle.quantity - article.quantity
      );
    }
  }

  async addQuantityArticles() {
    for (const article of this.ticket.articles) {
      const index = this.articleReferences.findIndex(
        (x) => x.name == article.name
      );
      await this._article.updateQuantity(
        this.warehouse.key,
        article.key,
        this.articleReferences[index].quantity +
        (article.isComplete ? article.quantity : article.returned)
      );
    }
  }

  async changeQuantityArray(index: number, event) {
    this.articleQuantityEdit[index].quantity = +event.value;
    this.articleQuantityEdit[index].quantityOrdered = +event.value;
    this.ticket.articles[index].quantity = +event.value;
    await this._ticket.update(this.ticket.key, this.ticket);
    AlertService.toastSuccess('Se actualizó la cantidad correctamente');
  }

  async changeQuantity() {
    this.quantityAvailable = true;
    let messageWithoutStorage: string = '';

    for (let i = 0; i <= this.articleQuantityEdit.length - 1; i++) {
      if (
        this.ticket.status == TicketStatus.AUTHORIZED ||
        this.ticket.status == TicketStatus.DRAFT
      ) {
        let numberSubtractOrAdd: number;
        let article: any = await this._article
          .get(this.warehouse.key, this.articleQuantityEdit[i].key)
          .pipe(take(1))
          .toPromise();

        if (
          this.articleQuantityEdit[i].quantity >
          this.ticket.articles[i].quantity
        ) {
          numberSubtractOrAdd =
            this.articleQuantityEdit[i].quantity -
            this.ticket.articles[i].quantity;
          article.quantity = article.quantity - numberSubtractOrAdd;
        } else {
          numberSubtractOrAdd =
            this.ticket.articles[i].quantity -
            this.articleQuantityEdit[i].quantity;
          article.quantity = article.quantity + numberSubtractOrAdd;
        }

        let surname = this.user.surnames ? this.user.surnames : '';
        let record = {
          action: `Se cambió la cantidad a solicitar de ${this.ticket.articles[i].quantity} a ${this.articleQuantityEdit[i].quantity} del ${this.ticket.articles[i].name} (${this.ticket.articles[i].internalId}) por ${this.user.name} ${surname}`,
          date: new Date().getTime(),
          trash: false
        };

        if (
          article.quantity < 0 ||
          article.quantity < this.articleQuantityEdit[i].quantity
        ) {
          let article = this.ticket.articles.find(
            (article) => article.key == this.articleQuantityEdit[i].key
          );
          let name = `${article.name}, `;
          messageWithoutStorage = messageWithoutStorage.concat(name.toString());
          this.quantityAvailable = false;
          continue;
        }

        if (
          this.ticket.articles[i].quantity !=
          this.articleQuantityEdit[i].quantity
        ) {
          await this._log.addRecord(
            this.warehouse.key,
            this.articleQuantityEdit[i].key,
            record
          );
        }

        // await this._article.update(this.warehouse.key, this.articleQuantityEdit[i].key, article);
      }

      this.ticket.articles[i].quantity = this.articleQuantityEdit[i].quantity;
      this.ticket.articles[i].quantityOrdered =
        this.articleQuantityEdit[i].quantityOrdered;
      this.ticket.articles[i].total =
        this.articleQuantityEdit[i].quantity * this.ticket.articles[i].price;
      this.calculateTotal();
      this.ticket.lastUpdate = new Date().getTime();
      await this._ticket.update(this.ticket.key, this.ticket);
    }

    if (messageWithoutStorage != '') {
      AlertService.toastError(
        `No se encuentra cantidad disponible en los articulos:  ${messageWithoutStorage.slice(
          0,
          -2
        )}.`
      );
    }

    if (
      this.quantityAvailable &&
      this.ticket.status == TicketStatus.AUTHORIZED
    ) {
      await this.confirmTicket();
    }
  }

  setArticleSelected($event: any) {
    this.articleSelected = $event;
    this.priceArticle = this.articleSelected.price;

    if (this.quantity == 0) {
      return;
    }

    this.addArticle(this.quantity);
    this.quantity = 0;
    this.priceArticle = 0;
    this.article = '';
  }

  totalPriceArticle(quantity) {
    this.quantity = quantity;

    if (quantity.value <= 0) {
      this.quantity = 0;
      return AlertService.toastError('No se permiten números negativos');
    }

    if (this.articleSelected) {
      this.addArticle(quantity);
      this.quantity = 0;
      this.priceArticle = 0;
      this.article = '';
    }
  }

  dropArticle(index: number, articleKey) {
    const indexQuantityEdit = this.articleQuantityEdit.findIndex(
      (article) => article.key == articleKey
    );
    this.articleQuantityEdit.splice(indexQuantityEdit, 1);
    this.ticket.total = this.ticket.total - this.ticket.articles[index].total;
    this.ticket.articles.splice(index, 1);
    this.ticket.lastUpdate = new Date().getTime();
    return this._ticket.update(this.ticket.key, this.ticket);
  }

  async addArticle(quantity) {
    const articleQuantity: number = +quantity.value;
    const index = this.ticket.articles.findIndex(
      (article) => article.name == this.articleSelected.name
    );

    if (index >= 0) {
      return AlertService.toastError('Este artículo ya está en la lista', '');
    }
    if (articleQuantity <= 0) {
      return AlertService.toastError('No ha seleccionado cantidad', '');
    }

    if (this.articleSelected.quantity < articleQuantity) {
      return AlertService.info(
        `La cantidad disponible para este artículo es: ${this.articleSelected.quantity}`,
        ''
      );
    }

    let total = this.articleSelected.price * articleQuantity;
    this.articles = this.ticket.articles;
    this.articles.push({
      name: this.articleSelected.name,
      type: this.articleSelected.type,
      quantity: articleQuantity,
      price: this.articleSelected.price,
      total: total,
      key: this.articleSelected.key,
      imageUrl: this.articleSelected.imageUrl,
      returned: 0,
      isComplete: false,
      lost: false,
      message: '',
      quantityOrdered: articleQuantity
    });
    this.ticket.articles = this.articles;
    this.ticket.total = this.ticket.total + total;
    this.ticket.lastUpdate = new Date().getTime();
    this.articleQuantityEdit.push({
      key: this.articleSelected.key,
      quantity: articleQuantity
    });
    await this._ticket.update(this.ticket.key, this.ticket);
    this.calculateTotal();
  }

  async discardTicket() {
    if (
      await AlertService.confirm(
        '¿Estás seguro que quieres descartar este ticket?',
        ''
      )
    ) {
      await this._ticket.delete(this.ticket.key);
      AlertService.toastSuccess('Se descartó correctamente', '');
    }
  }

  async messageArticles() {
    let message = await AlertService.message('Escriba un mensaje');
    if (!message) {
      return;
    }
    this._notification.set({
      redirectUrl: `admin/warehouseDetails/${this.ticket.warehouse.id}`,
      description: `"${message}"`,
      createdDate: new Date().getTime(),
      trash: false,
      readed: false,
      type: NotificationType.ARTICLE_REPORT
    });
  }

  async deleteArticle(article) {
    if (
      await AlertService.confirm(
        '¿Estás seguro que deseas eliminar este artículo del ticket?'
      )
    ) {
      this.ticket.articles = this.ticket.articles.filter(
        (articleItem) => articleItem.key != article.key
      );
      const index = this.articleQuantityEdit.findIndex(
        (article) => article.key == article.key
      );
      this.articleQuantityEdit.splice(index, 1);
      this.calculateTotal();
      if (this.ticket.articles.length == 0) {
        this.ticket.trash = true;
        this._ticket.update(this.ticket.key, this.ticket);
      }
      this.ticket.lastUpdate = new Date().getTime();
      return this._ticket.update(this.ticket.key, this.ticket);
    }
  }

  calculateTotal(): void {
    this.ticket.total = 0;
    this.ticket.articles.forEach(
      (article) => (this.ticket.total += article.price * article.quantity)
    );
  }

  async rectifyTicket() {
    let request = {
      ticketID: this.ticket.ticketID,
      ticketKey: this.ticket.key,
      date: new Date().getTime(),
      user: this.user.name,
      userEmail: this.user.email,
      userKey: this.user.key,
      accept: false,
      trash: false
    };
    await this._ticketAuthorized.addRequest(this.warehouse.key, request);
    this.requestsReference.push({
      userKey: this.user.key,
      ticketKey: this.ticket.key
    });
    AlertService.toastSuccess('Se rectificó el ticket');
  }

  private async updateSeriesNumbersSelected() {
    for (const article of this.articleWithSeriesNumbersSelected) {
      await this._article.update(this.warehouse.key, article.key, {
        serialNumberCollected: article.article.serialNumberCollected
      });
    }
  }

  private getStatusClass(status: number) {
    switch (status) {
      case TicketStatus.DRAFT:
        return 'text-primary';

      case TicketStatus.GENERATED:
        return 'text-primary';

      case TicketStatus.AUTHORIZED:
        return 'text-info';

      case TicketStatus.COLLECTED:
        return 'text-warning';

      case TicketStatus.INCOMPLETE:
        return 'text-danger';

      case TicketStatus.RETURNED:
        return 'text-success';

      case TicketStatus.CANCELLED:
        return 'text-dark';

      default:
        return 'text-success';
    }
  }

  async updateComment() {
    this.ticket.comments = this.comment;
    await this._ticket.update(this.ticket.key, {
      comments: this.comment
    } as Ticket);
  }

  async setProjectSelected($event: any) {
    let reference = await this._project.getReference($event.key);
    await this._ticket.update(this.ticket.key, {projects: reference} as Ticket);
    this.ticket.projects = reference;
    this.categories = $event.budgets;
  }

  async setUserSelected($event: any) {
    let reference = await this._user.getReference($event.key);
    await this._ticket.update(this.ticket.key, {user: reference} as Ticket);
    this.ticket.user = reference;
  }

  async changeToDraft() {
    await this._ticket.update(this.ticket.key, {
      status: TicketStatus.DRAFT
    } as Ticket);
    this.ticket.status = TicketStatus.DRAFT;

    this._log.add(this.warehouse.key, {
      description: `El ticket ${this.ticket.ticketID.slice(
        0,
        -3
      )} pasó a borrador`
    });

    this.getProjects();
    this.getUsers();
    this.comment = this.ticket.comments;
  }

  async setCategorytSelected(category) {
    if (!category) return;
    await this._ticket.update(this.ticket.key, {
      projectCategory: category.reference
    } as Ticket);
  }

  async cancelTicket() {
    await this._ticket.update(this.ticket.key, {
      status: TicketStatus.CANCELLED
    } as Ticket);
    this.ticket.status = TicketStatus.CANCELLED;
  }
}
