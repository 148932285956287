import { CommonModule, CurrencyPipe, TitleCasePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { SharedModule } from '../shared/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CategoriesComponent } from './pages/categories/categories.component';
import { AddCategoryComponent } from './modals/add-category/add-category.component';
import { UsersListComponent } from './pages/users-list/users-list.component';
import { WarehousesComponent } from './pages/warehouses/warehouses.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdminRoutes } from './admin-routing.module';
import { UserDetailsComponent } from './pages/user-details/user-details.component';
import { AddWhitelistComponent } from './modals/add-whitelist/add-whitelist.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ChartistModule } from 'ng-chartist';
import { ChartsModule } from 'ng2-charts';
import { CardModule } from '../shared/template-components/partials/general/card/card.module';
import { HttpClientModule } from '@angular/common/http';
import { ShowImageComponent } from './modals/show-image/show-image.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { AddProjectComponent } from './modals/add-project/add-project.component';
import { UpdateProjectComponent } from './modals/update-project/update-project.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { PersonsComponent } from './pages/persons/persons.component';
import { AddPersonComponent } from './modals/add-person/add-person.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { PermissionsComponent } from './components/permissions/permissions.component';
import { PositionsComponent } from './pages/positions/positions.component';
import { AddJobComponent } from './modals/add-position/add-job.component';
import { QuizComponent } from './pages/quiz/quiz.component';
import { AddQuizComponent } from './modals/add-quiz/add-quiz.component';
import { ApplicantsComponent } from './pages/applicants/applicants.component';
import { ApplicantDetailComponent } from './pages/applicant-detail/applicant-detail.component';
import { ChangeStatusComponent } from './modals/change-status/change-status.component';
import { StatusComponent } from './pages/status/status.component';
import { AddStatusComponent } from './modals/add-status/add-status.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { QuizDetailsComponent } from './pages/quiz-details/quiz-details.component';
import { AddAnswerComponent } from './modals/add-answer/add-answer.component';
import { SendAccountComponent } from './modals/send-account/send-account.component';
import { LogsComponent } from './components/logs/logs.component';
import { SelectSerialsNumberComponent } from './modals/select-serials-number/select-serials-number.component';
import { AfpComponent } from './pages/afp/afp.component';
import { AddAfpComponent } from './modals/add-afp/add-afp.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DocumentsComponent } from './pages/documents/documents.component';
import { FilterApplicantsComponent } from './modals/filter-applicants/filter-applicants.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UpdateApplicantComponent } from './modals/update-applicant/update-applicant.component';
import { UpdateUserComponent } from './modals/update-user/update-user.component';
import { ProvidersComponent } from './pages/providers/providers.component';
import { ProviderModalComponent } from './modals/provider-modal/provider-modal.component';
import { PurchaseOrdersComponent } from './pages/purchase-orders/purchase-orders.component';
import { PurchaseOrderModalComponent } from './pages/purchase-orders/modals/purchase-order-modal/purchase-order-modal.component';
import { ProviderInfoComponent } from './components/provider-info/provider-info.component';
import {
  ArticlesPurchaseComponent
} from './pages/purchase-orders/modals/purchase-order-modal/components/articles-purchase/articles-purchase.component';
import {
  ArticlesPurchaseViewComponent
} from './pages/purchase-orders/modals/purchase-order-modal/components/articles-purchase-view/articles-purchase-view.component';
import { ProviderInfoViewComponent } from './components/provider-info-view/provider-info-view.component';
import { IdSettingsComponent } from './pages/id-settings/id-settings.component';
import {
  PurchaseCommentsComponent
} from './pages/purchase-orders/modals/purchase-order-modal/components/purchase-comments/purchase-comments.component';
import { UpdloadPurchasePdfComponent } from './modals/updload-purchase-pdf/updload-purchase-pdf.component';
import { BillsComponent } from './pages/bills/bills.component';
import { BillModalComponent } from './modals/bill-modal/bill-modal.component';
import {
  DraftPurchaseOrderComponent
} from './pages/purchase-orders/modals/purchase-order-modal/components/draft-purchase-order/draft-purchase-order.component';
import {
  GeneratedPurchaseOrderComponent
} from './pages/purchase-orders/modals/purchase-order-modal/components/generated-purchase-order/generated-purchase-order.component';
import {
  AuthorizedPurchaseOrderComponent
} from './pages/purchase-orders/modals/purchase-order-modal/components/authorized-purchase-order/authorized-purchase-order.component';
import {
  DonePurchaseOrderComponent
} from './pages/purchase-orders/modals/purchase-order-modal/components/done-purchase-order/done-purchase-order.component';
import { PrintPurchaseComponent } from './components/print-purchase/print-purchase.component';
import { ProvidersArticlesComponent } from './pages/providers-articles/providers-articles.component';
import { ProviderArticleComponent } from './modals/provider-article/provider-article.component';
import { BudgetComponent } from './components/budget/budget.component';
import { UnitsComponent } from './pages/units/units.component';
import { UnitModalComponent } from './modals/unit-modal/unit-modal.component';
import { AddBillsComponent } from './modals/add-bills/add-bills.component';
import { AddToExcelComponent } from './components/add-to-excel/add-to-excel.component';
import { ProjectCategoriesModalComponent } from './modals/project-categories-modal/project-categories-modal.component';
import { ProjectCategoriesComponent } from './pages/project-categories/project-categories.component';
import { PurcharseOrderBillsComponent } from './pages/purcharse-order-bills/purcharse-order-bills.component';
import { PurchaseOrderBillsComponent } from './pages/purchase-order-bills/purchase-order-bills.component';
import { AssignProjectComponent } from './modals/assign-project/assign-project.component';
import { AddToSubCategoryComponent } from './modals/add-to-sub-category/add-to-sub-category.component';
import { ProofPaymentsComponent } from './pages/proof-payments/proof-payments.component';
import { ProofOfPaymentModalComponent } from './modals/proof-of-payment-modal/proof-of-payment-modal.component';
import { CostCenterComponent } from './pages/cost-center/cost-center.component';
import { CostCenterModalComponent } from './modals/cost-center-modal/cost-center-modal.component';
import { CostCenterDetailsComponent } from './pages/cost-center-details/cost-center-details.component';
import { EmployeePaymentsComponent } from './pages/employee-payments/employee-payments.component';
import { EmployeePaymentModalComponent } from './modals/employee-payment-modal/employee-payment-modal.component';
import { WorkerSalariesComponent } from './pages/worker-salaries/worker-salaries.component';
import { WorkerSalaryModalComponent } from './modals/worker-salary-modal/worker-salary-modal.component';
import { TotalExpensesComponent } from './pages/total-expenses/total-expenses.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { PaymentsComponent } from './modals/payments/payments.component';
import { ChangePaydayComponent } from './modals/change-payday/change-payday.component';
import { PaymentCategoriesComponent } from './pages/payment-categories/payment-categories.component';
import { PaymentCategoryModalComponent } from './modals/payment-category-modal/payment-category-modal.component';
import { CostCenterPaymentsComponent } from './pages/cost-center-payments/cost-center-payments.component';
import { CostCenterPaymentsModalComponent } from './modals/cost-center-payments-modal/cost-center-payments-modal.component';
import { CostCenterPaymentsViewModalComponent } from './modals/cost-center-payments-view-modal/cost-center-payments-view-modal.component';
import { SendNotificationComponent } from './modals/send-notification/send-notification.component';
import {
  IncompletePurchaseOrderComponent
} from './pages/purchase-orders/modals/purchase-order-modal/components/incomplete-purchase-order/incomplete-purchase-order.component';
import { ChangeLogComponent } from './components/change-log/change-log.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { KanbanComponent } from './pages/kanban/kanban.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { KanbanItemComponent } from './pages/kanban/kanban-item/kanban-item.component';
import { KanbanModalComponent } from './pages/kanban/kanban-modal/kanban-modal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { KanbanNewItemComponent } from './pages/kanban/kanban-new-item/kanban-new-item.component';
import { SharedModule as IntechModule } from '@tintech/shared';
import { CostListComponent } from './modals/cost-list/cost-list.component';
import { BoardsComponent } from './pages/boards/boards.component';
import { BoardModalComponent } from './modals/board-modal/board-modal.component';
import { TasksListComponent } from './pages/tasks-list/tasks-list.component';
import { ChecklistComponent } from './pages/kanban/components/checklist/checklist.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { KanbanReportComponent } from './kanban/components/kanban-report/kanban-report.component';
import { ProjectBillsComponent } from './components/project-bills/project-bills.component';
import { MatRadioModule } from '@angular/material/radio';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { ClientModalComponent } from './modals/client-modal/client-modal.component';
import { ArchwizardModule } from 'angular-archwizard';
import { KanbanTasksListComponent } from './components/kanban-tasks-list/kanban-tasks-list.component';
import { TaskStatusPipe } from './pipes/task-status.pipe';
import { SalesBoardModalComponent } from './modals/sales-board-modal/sales-board-modal.component';
import { SalesBoardsComponent } from './pages/sales-boards/sales-boards.component';
import { SalesKanbanComponent } from './pages/sales-kanban/sales-kanban.component';
import { AddSaleModalComponent } from './modals/add-sale-modal/add-sale-modal.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SaleCardComponent } from './components/sale-card/sale-card.component';
import { SaleDetailsModalComponent } from './modals/sale-details-modal/sale-details-modal.component';
import { SalesReportComponent } from './pages/sales-report/sales-report.component';
import { PurchaseOrderArticlesComponent } from './pages/purchase-order-articles/purchase-order-articles.component';
import { FundsToRenderComponent } from './pages/travel-advance/pages/funds-to-render/funds-to-render.component';
import { FundToRenderModalComponent } from './pages/travel-advance/modals/fund-to-render-modal/fund-to-render-modal.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { RefundModalComponent } from './modals/refund-modal/refund-modal.component';
import { FundToRenderLogsComponent } from './pages/travel-advance/pages/fund-to-render-logs/fund-to-render-logs.component';
import { DomseguroPipe } from './pipes/domseguro.pipe';
import {
  RequestNewFundToRenderComponent
} from './pages/travel-advance/modals/request-new-fund-to-render/request-new-fund-to-render.component';
import {
  ManageFundToRenderRequestsComponent
} from './pages/travel-advance/pages/manage-fund-to-render-requests/manage-fund-to-render-requests.component';
import {
  BreakdownAmountCategoriesComponent
} from './pages/travel-advance/pages/breakdown-amount-categories/breakdown-amount-categories.component';
import { AccountabilitiesComponent } from './pages/travel-advance/pages/accountabilities/accountabilities.component';
import { AccountabilityModalComponent } from './pages/travel-advance/modals/accountability-modal/accountability-modal.component';
import { AccountabilityReceiptsComponent } from './pages/travel-advance/pages/accountability-receipts/accountability-receipts.component';
import {
  AccountabilityReceiptModalComponent
} from './pages/travel-advance/modals/accountability-receipt-modal/accountability-receipt-modal.component';
import { SummaryCategoryAmountsComponent } from './modals/summary-category-amounts/summary-category-amounts.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ReceiptsComponent } from './pages/receipts/receipts.component';
import { FundsToRenderSelectComponent } from './pages/travel-advance/components/funds-to-render-select/funds-to-render-select.component';
import { FundToRenderDetailsComponent } from './pages/travel-advance/pages/fund-to-render-details/fund-to-render-details.component';
import { ReceiptsLogsComponent } from './pages/travel-advance/components/receipts-logs/receipts-logs.component';
import { ProjectInvoiceReportsComponent } from './pages/project-invoice-reports/project-invoice-reports.component';
import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { AdminAccountabilitiesComponent } from './pages/travel-advance/pages/admin-accountabilities/admin-accountabilities.component';
import { WarehousesModule } from './pages/warehouses/warehouses.module';
import { ReceiptComponent } from './pages/travel-advance/pages/receipt/receipt.component';
import { NgxViewerModule } from 'ngx-viewer';
import { VehiclesComponent } from './pages/vehicles/vehicles.component';
import { Applicantsv2Component } from './pages/applicantsv2/applicantsv2.component';
import { BillModalV2Component } from './modals/bill-modal-v2/bill-modal-v2.component';
import { BillGeneralComponent } from './modals/bill-modal-v2/components/bill-general/bill-general.component';
import { BillDetailsComponent } from './modals/bill-modal-v2/components/bill-details/bill-details.component';
import { BillPaymentComponent } from './modals/bill-modal-v2/components/bill-payment/bill-payment.component';
import { BillPdfComponent } from './modals/bill-modal-v2/components/bill-pdf/bill-pdf.component';
import { BillProofOfPaymentComponent } from './modals/bill-modal-v2/components/bill-proof-of-payment/bill-proof-of-payment.component';
import { BillClassificationComponent } from './modals/bill-modal-v2/components/bill-clasification/bill-classification.component';
import { BillDatePickerComponent } from './modals/bill-modal-v2/components/bill-date-picker/bill-date-picker.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MyTasksKanbanComponent } from './pages/my-tasks-kanban/my-tasks-kanban.component';
import { InterviewComponent } from './pages/applicant-detail/components/interview/interview.component';
import { MetricsTablesComponent } from './pages/dashboard/components/metrics-tables/metrics-tables.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ContractsComponent } from './pages/applicant-detail/components/contracts/contracts.component';
import { AddContractComponent } from './pages/applicant-detail/modals/add-contract/add-contract.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RecruitmentSheetComponent } from './pages/recruitment-sheet/recruitment-sheet.component';
import { CopyApplicantDocsUrlComponent } from './pages/applicant-detail/modals/copy-applicant-docs-url/copy-applicant-docs-url.component';
import { FundToRenderV2Component } from './pages/fund-to-render-v2/fund-to-render-v2.component';
import { ApplicantCurriculumComponent } from './pages/applicant-detail/components/applicant-curriculum/applicant-curriculum.component';
import { ApplicantCommentsComponent } from './pages/applicant-detail/components/applicant-comments/applicant-comments.component';
import { CommentsModalComponent } from './pages/applicant-detail/components/comments-modal/comments-modal.component';
import { CurriculumModalComponent } from './pages/applicant-detail/components/curriculum-modal/curriculum-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { QRModalComponent } from './pages/applicant-detail/components/qrmodal/qrmodal.component';
import { SalaryRangeComponent } from './pages/dashboard/components/salary-range/salary-range.component';
import { PositionSalaryGraphComponent } from './pages/dashboard/components/position-salary-graph/position-salary-graph.component';
import { SalaryRangeFormComponent } from './pages/dashboard/components/salary-range-form/salary-range-form.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    HomeComponent,
    CategoriesComponent,
    AddCategoryComponent,
    UsersListComponent,
    NotificationsComponent,
    WarehousesComponent,
    UserDetailsComponent,
    AddWhitelistComponent,
    ShowImageComponent,
    ProjectsComponent,
    AddProjectComponent,
    UpdateProjectComponent,
    ProjectDetailsComponent,
    PersonsComponent,
    AddPersonComponent,
    PermissionsComponent,
    PositionsComponent,
    AddJobComponent,
    QuizComponent,
    AddQuizComponent,
    ApplicantsComponent,
    ApplicantDetailComponent,
    ChangeStatusComponent,
    StatusComponent,
    AddStatusComponent,
    QuizDetailsComponent,
    AddAnswerComponent,
    SendAccountComponent,
    LogsComponent,
    SelectSerialsNumberComponent,
    AfpComponent,
    AddAfpComponent,
    DocumentsComponent,
    FilterApplicantsComponent,
    DashboardComponent,
    UpdateApplicantComponent,
    UpdateUserComponent,
    ProvidersComponent,
    ProviderModalComponent,
    PurchaseOrdersComponent,
    PurchaseOrderModalComponent,
    ProviderInfoComponent,
    ArticlesPurchaseComponent,
    ArticlesPurchaseViewComponent,
    ProviderInfoViewComponent,
    IdSettingsComponent,
    PurchaseCommentsComponent,
    UpdloadPurchasePdfComponent,
    BillsComponent,
    BillModalComponent,
    DraftPurchaseOrderComponent,
    GeneratedPurchaseOrderComponent,
    AuthorizedPurchaseOrderComponent,
    DonePurchaseOrderComponent,
    PrintPurchaseComponent,
    ProvidersArticlesComponent,
    ProviderArticleComponent,
    BudgetComponent,
    UnitsComponent,
    UnitModalComponent,
    AddBillsComponent,
    AddToExcelComponent,
    ProjectCategoriesModalComponent,
    ProjectCategoriesComponent,
    PurcharseOrderBillsComponent,
    PurchaseOrderBillsComponent,
    AssignProjectComponent,
    AddToSubCategoryComponent,
    ProofPaymentsComponent,
    ProofOfPaymentModalComponent,
    CostCenterComponent,
    CostCenterModalComponent,
    CostCenterDetailsComponent,
    EmployeePaymentsComponent,
    EmployeePaymentModalComponent,
    WorkerSalariesComponent,
    WorkerSalaryModalComponent,
    TotalExpensesComponent,
    ReportsComponent,
    PaymentsComponent,
    ChangePaydayComponent,
    PaymentCategoriesComponent,
    PaymentCategoryModalComponent,
    CostCenterPaymentsComponent,
    CostCenterPaymentsModalComponent,
    CostCenterPaymentsViewModalComponent,
    SendNotificationComponent,
    IncompletePurchaseOrderComponent,
    ChangeLogComponent,
    KanbanComponent,
    KanbanItemComponent,
    KanbanModalComponent,
    KanbanNewItemComponent,
    CostListComponent,
    BoardsComponent,
    BoardModalComponent,
    TasksListComponent,
    ChecklistComponent,
    KanbanReportComponent,
    ProjectBillsComponent,
    ClientsComponent,
    ClientModalComponent,
    KanbanTasksListComponent,
    TaskStatusPipe,
    SalesBoardModalComponent,
    SalesBoardsComponent,
    SalesKanbanComponent,
    AddSaleModalComponent,
    SaleCardComponent,
    SaleDetailsModalComponent,
    SalesReportComponent,
    PurchaseOrderArticlesComponent,
    FundsToRenderComponent,
    FundToRenderModalComponent,
    RefundModalComponent,
    FundToRenderLogsComponent,
    BreakdownAmountCategoriesComponent,
    AccountabilitiesComponent,
    AccountabilityModalComponent,
    AccountabilityReceiptsComponent,
    AccountabilityReceiptModalComponent,
    DomseguroPipe,
    RequestNewFundToRenderComponent,
    ManageFundToRenderRequestsComponent,
    BreakdownAmountCategoriesComponent,
    SummaryCategoryAmountsComponent,
    ReceiptsComponent,
    ReceiptComponent,
    FundsToRenderSelectComponent,
    FundToRenderDetailsComponent,
    ReceiptsLogsComponent,
    ProjectInvoiceReportsComponent,
    AdminAccountabilitiesComponent,
    VehiclesComponent,
    Applicantsv2Component,
    BillModalV2Component,
    BillGeneralComponent,
    BillDetailsComponent,
    BillPaymentComponent,
    BillPdfComponent,
    BillProofOfPaymentComponent,
    BillClassificationComponent,
    BillDatePickerComponent,
    MyTasksKanbanComponent,
    InterviewComponent,
    MetricsTablesComponent,
    ContractsComponent,
    AddContractComponent,
    RecruitmentSheetComponent,
    CopyApplicantDocsUrlComponent,
    FundToRenderV2Component,
    ApplicantCurriculumComponent,
    ApplicantCommentsComponent,
    CommentsModalComponent,
    CurriculumModalComponent,
    QRModalComponent,
    FundToRenderV2Component,
    SalaryRangeComponent,
    PositionSalaryGraphComponent,
    SalaryRangeFormComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(AdminRoutes),
    SharedModule,
    NgxDatatableModule,
    NgSelectModule,
    MatTabsModule,
    NgxQRCodeModule,
    ChartistModule,
    CardModule,
    ChartsModule,
    HttpClientModule,
    UiSwitchModule,
    NgxChartsModule,
    NgxSpinnerModule,
    TooltipModule,
    ScrollingModule,
    DragDropModule,
    MatFormFieldModule,
    MatSelectModule,
    BsDatepickerModule,
    IntechModule,
    ProgressbarModule,
    MatRadioModule,
    ArchwizardModule,
    CKEditorModule,
    NgxMaskModule,
    CdkAccordionModule,
    MatInputModule,
    WarehousesModule,
    NgxViewerModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatButtonModule,
    MatDatepickerModule,
    PdfViewerModule
  ],
  providers: [
    TitleCasePipe,
    CurrencyPipe
  ]
})
export class AdminModule {}
