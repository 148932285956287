import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {CostCenter} from '../interfaces/cost-center';

@Injectable({
  providedIn: 'root'
})
export class CostCentersService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  getAll(): Observable<CostCenter[]> {
    return this.db.colWithIds$<CostCenter>('costCenters', ref => ref
      .where('trash', '==', false));
  }

  get(costCenterKey: string): Observable<CostCenter> {
    return this.db.docWithId$(`costCenters/${costCenterKey}`);
  }

  set(costCenterKey: string, costCenter: CostCenter): void {
    this.afs.doc(`costCenters/${costCenterKey}`).set(costCenter, {merge: true});
  }

  update(costCenterKey: string, costCenter: CostCenter): Promise<void> {
    return this.afs.doc<CostCenter>(`costCenters/${costCenterKey}`).update(costCenter);
  }
}
