<div class="p-2">
  <h2>{{isUpdate ? 'Actualizando categoría' : 'Agregar categoría'}}</h2>

  <form [formGroup]="projectCategoryForm" (ngSubmit)="submit()">
    <div class="row mt-2">

      <div class="col-md-12 col-12">
        <div class="form-group">
          <label>Categoría *</label>
          <input type="text" class="form-control" formControlName="name"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.name.errors }"/>
          <div *ngIf="submitted && formControls.name.errors">
            La categoría es obligatoria
          </div>
        </div>
      </div>

    </div>

    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn">Aceptar</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide()">Cancelar</div>
    </div>
  </form>
</div>
