import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../shared/template-services/alert.service';
import {Afp} from '../../interfaces/afp';
import {AfpService} from '../../../shared/services/afp.service';

@Component({
  selector: 'app-add-afp',
  templateUrl: './add-afp.component.html',
  styleUrls: ['./add-afp.component.css']
})
export class AddAfpComponent implements OnInit {
  update: boolean;
  afp: Afp;
  afpForm: FormGroup;
  submitted: boolean = false;

  constructor(public modal: BsModalRef,
              private formBuilder: FormBuilder,
              private _afp: AfpService) {
    this.afpForm = formBuilder.group({
      name: ['', Validators.required],
      trash: [false]
    });
  }

  ngOnInit(): void {
    if (this.update) this.afpForm.patchValue({name: this.afp.name})
  }

  get formControls() {
    return this.afpForm.controls;
  }

  async submit() {
    this.submitted = true;
    if (this.afpForm.valid) {
      this.update ? await this._afp.update(this.afp.key, this.afpForm.value as Afp) : await this._afp.add(this.afpForm.value as Afp);
      AlertService.toastSuccess(this.update ? 'Se actualizó el afp' : 'Se agregó el afp');
      this.modal.hide();
    }
  }
}
