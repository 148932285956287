import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {Observable, of, Subscription} from 'rxjs';
import {BillModalComponent} from '../../modals/bill-modal/bill-modal.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BillService} from '../../../shared/services/bill.service';
import {take} from 'rxjs/operators';
import {PurchaseOrderService} from 'src/app/shared/services/purchase-order.service';
import {ObjectService} from '../../../shared/template-services/object.service';
import {PaymentCategoryService} from '../../services/payment-category.service';
import {ProviderService} from '../../../shared/services/provider.service';
import {ProjectService} from '../../../shared/services/project.service';

@Component({
  selector: 'app-purchase-order-bills',
  templateUrl: './purchase-order-bills.component.html',
  styleUrls: ['./purchase-order-bills.component.css']
})
export class PurchaseOrderBillsComponent implements OnInit, OnDestroy {
  @Input() bills = [];
  @Input() purchaseOrder;
  bills$: Observable<any>;
  config: DataTableConfig = {hasSearch: true, notFoundText: 'No se encontraron facturas', title: ''};
  indexedBills = {};
  indexedProviders = {};
  categoriesIndexed = {};
  billSubscription: Subscription = new Subscription();
  paymentCategoriesSubscription: Subscription = new Subscription();
  proofOfPaymentSubscription: Subscription = new Subscription();
  categoriesSubscription: Subscription = new Subscription();
  paymentCategories: any[] = [];
  proofOfPayments: any[] = [];

  constructor(private modal: BsModalService,
              private _bill: BillService,
              private _purchase: PurchaseOrderService,
              private _paymentCategory: PaymentCategoryService,
              private _provider: ProviderService,
              private _project: ProjectService,) {
  }

  async ngOnInit(): Promise<void> {
    this._purchase.get(this.purchaseOrder.key).subscribe(async purchase => {
      this.bills = await Promise.all(purchase.invoice.map(async bill => {
        return await this._bill.get(bill.id).pipe(take(1)).toPromise();
      }));

      this.indexedBills = ObjectService.indexArray(this.bills, 'key');

      this.bills$ = of(this.bills);
    });

    this.listenPaymentCategories();
    this.loadProviders();
    this.listenProOfPayments();
    this.listenCategories();
  }

  ngOnDestroy(): void {
    this.billSubscription.unsubscribe();
    this.paymentCategoriesSubscription.unsubscribe();
    this.proofOfPaymentSubscription.unsubscribe();
    this.categoriesSubscription.unsubscribe();
  }

  listenPaymentCategories() {
    this.paymentCategoriesSubscription = this._paymentCategory
      .getAllWithoutCostCenter()
      .subscribe((data) => {
        this.paymentCategories = data;
      });
  }

  async loadProviders() {
    this.indexedProviders = ObjectService.indexArray(
      await this._provider.getAll().pipe(take(1)).toPromise(),
      'key'
    );
  }

  listenProOfPayments() {
    this.proofOfPaymentSubscription = this._bill.getAllProofPayments().subscribe((data) => {
      this.proofOfPayments = data.filter((proOfPayment) => proOfPayment.type == 1);
    });
  }

  listenCategories() {
    this.categoriesSubscription = this._project.getAllCategories().subscribe((data) => {
      this.categoriesIndexed = ObjectService.indexArray(data, 'key');
    });
  }

  goToOpenEditBill(bill) {
    this.modal.show(BillModalComponent, {
      initialState: {
        bill: {...bill},
        isUpdate: true,
        indexedBills: {...this.indexedBills},
        paymentCategories: this.paymentCategories,
        indexedProviders: this.indexedProviders,
        proofOfPayments: this.proofOfPayments,
        categoriesIndexed: this.categoriesIndexed,
      },
      class: 'modal-xl',
      backdrop: 'static',
      id: 8177
    });
  }
}
