import {Component, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {PurchaseOrderService} from '../../../shared/services/purchase-order.service';
import {formatDate} from '@angular/common';
import {PurchaseOrder} from '../../pages/purchase-orders/interfaces/purchase-order';
import {Bill} from '../../interfaces/bill';
import {BillService} from '../../../shared/services/bill.service';

@Component({
  selector: 'app-change-payday',
  templateUrl: './change-payday.component.html',
  styleUrls: ['./change-payday.component.css']
})
export class ChangePaydayComponent implements OnInit {
  move: string = '';
  key: string;
  date: any;
  itHasUpdated: boolean = false;

  constructor(public modal: BsModalService,
              private _purchase: PurchaseOrderService,
              private _bill: BillService) {
  }

  ngOnInit(): void {
    this.formatDate();
  }

  private formatDate() {
    const deliveryDateDate = new Date(this.date);
    const format = 'yyyy/MM/dd';
    const locale = 'en-US';
    const zone = 'UTC';
    const formattedDate = formatDate(deliveryDateDate, format, locale, zone);
    this.date = formattedDate.replace(/\//g, '-');
  }


  async submit() {
    let date = this.date.replaceAll('-', '/');
    let dateMilliseconds = new Date(date).getTime();

    if (this.move == 'purchase') {
      await this._purchase.update(this.key, {deliveryDate: dateMilliseconds, deliveryDateChanged: true} as PurchaseOrder);
    }

    if (this.move == 'bill') {
      await this._bill.update(this.key, {expectedPaymentDate: dateMilliseconds, deliveryDateChanged: true} as Bill);
    }

    this.itHasUpdated = true;
    this.modal.hide(33);
  }
}
