<div class="row p-4">
  <div class="col-12" *blockUI="'numberTabs'; message: 'Loading'">
    <m-card>
      <ng-container mCardHeaderTitle>
        <div class="w-100 d-flex justify-content-center">
          <img src="assets/images/logo_sm.png" alt="" style="height:110px">
        </div>
      </ng-container>
      <ng-container mCardBody>
        <div *ngIf="!complete" class="card-block">
          <div class="p-2">
            <form [formGroup]="curriculumForm">
              <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4 col-12">
                  <div class="form-group">
                    <label>Actualizar curriculum</label>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input"
                             (change)="chooseCurriculum($event)"
                             accept="application/pdf"
                             [ngClass]="{ 'is-invalid': submitted && formControls.curriculum.errors }">
                      <label class="custom-file-label">{{curriculumForm.value.curriculum ? curriculumForm.value.curriculum.name : 'Solo archivos pdf'}}</label>
                      <small class="form-text text-muted danger" *ngIf="!curriculumValidation">
                        <div>
                          El curriculum es obligatorio
                        </div>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="text-align-center mt-1">
              <button class="btn btn-success" (click)="updateCV()" [disabled]="formControls.curriculum.errors">Actualizar</button>
            </div>
          </div>
        </div>

        <div *ngIf="complete" class="card-block">
          <div class="text-center">
            <h1><strong>Se ha actualizado el cv exitosamente</strong></h1>
          </div>
        </div>
      </ng-container>
    </m-card>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
             type="square-jelly-box"><p style="color: white"> Actualizando... </p></ngx-spinner>

