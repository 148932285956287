import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, of, Subscription} from 'rxjs';
import {Receipt} from '../travel-advance/interfaces/receipt';
import {BreakdownAmountCategoryService} from '../travel-advance/services/breakdown-amount-category.service';
import {ReceiptService} from '../travel-advance/services/receipt.service';
import {take} from 'rxjs/internal/operators/take';
import {map, mergeMap} from 'rxjs/operators';
import {DocumentReference} from '@angular/fire/firestore';
import {UserService} from 'src/app/shared/services/user.service';
import {FundToRenderService} from '../../../shared/services/fund-to-render.service';
import {FundToRenderStatusEnum} from '../travel-advance/enums/fund-to-render-status.enum';
import {BsModalService} from 'ngx-bootstrap/modal';
import {FundsToRenderSelectComponent} from '../travel-advance/components/funds-to-render-select/funds-to-render-select.component';
import {FundToRender} from '../travel-advance/interfaces/fund-to-render';
import {ProjectService} from '../../../shared/services/project.service';
import {ReceiptComponent} from '../travel-advance/pages/receipt/receipt.component';
import {UserType} from '../../enums/user-type.enum';
import _ from 'lodash';

@Component({
  selector: 'app-receipts',
  templateUrl: './receipts.component.html',
  styleUrls: ['./receipts.component.css']
})
export class ReceiptsComponent implements OnInit, OnDestroy {
  receipts$: Observable<Receipt[]>;
  fundsToRenderSubscription: Subscription = new Subscription();
  fundToRenderStatusEnum = FundToRenderStatusEnum;
  fundsToRender: FundToRender[] = [];
  receiptsSelected: Receipt[] = [];
  receiptSelection: boolean = false;
  selectedReceiptsKey: any[] = [];
  userPermission: any;
  userTypeEnum = UserType;
  selectedCategory: any = null;
  receiptsSubscription: Subscription = new Subscription();
  receiptCategories: any[] = [];
  searchValue: string = '';

  constructor(private _receipt: ReceiptService,
              private modal: BsModalService,
              private _breakdownAmountCategory: BreakdownAmountCategoryService,
              private _fundToRender: FundToRenderService,
              private router: Router,
              private _user: UserService,
              private _project: ProjectService) {
  }

  ngOnInit(): void {
    this.userPermission = this._user.getPermission('FONDOS POR RENDIR');
    this.listenForFundsToRender();

    this.receiptsSubscription = this._receipt.getAll()
      .pipe(
        map((receipts) =>
          this.filterReceipts(receipts)
            .map((receipt) => ({
              ...receipt,
              isSelected: false
            }))
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        ),
        mergeMap(receipts => this._receipt.populate(receipts, ['category']))
      ).subscribe((receipts) => {
        this.receiptCategories = this.getCategoriesFromReceipts(receipts);
        this.receipts$ = of(receipts);
      });
  }

  ngOnDestroy(): void {
    this.fundsToRenderSubscription.unsubscribe();
    this.receiptsSubscription.unsubscribe();
  }

  filterReceipts(receipts: Receipt[]): Receipt[] {
    return receipts.filter((receipt) => (
      this.userPermission == this.userTypeEnum.ADMIN ||
      (
        this.userPermission == this.userTypeEnum.USER &&
        !receipt.isUsed &&
        ((<DocumentReference>receipt.user).id) == this._user.user.key
      )
    ));
  }

  listenForFundsToRender() {
    this.fundsToRenderSubscription = this._fundToRender
      .getAll()
      .pipe(
        map((funds) =>
          funds.filter(
            (fund) =>
              fund.status == this.fundToRenderStatusEnum.PAID &&
              (<DocumentReference>fund.user).id == this._user.user.key
          )
        )
      )
      .subscribe(async (fundsToRender) => {
        this.fundsToRender = await this.formatFundsToRenderProject(fundsToRender);
      });
  }

  async formatFundsToRenderProject(fundsToRender: FundToRender[]) {
    return await Promise.all(
      fundsToRender.map(async (fund) => ({
        ...fund,
        project: await this._project
          .get(fund.project.id)
          .pipe(take(1))
          .toPromise()
      }))
    );
  }

  goToNewReceipt() {
    this.router.navigateByUrl(`admin/new-receipt`);
  }

  goToEditReceiptsModal(receipt: Receipt) {
    this.modal.show(ReceiptComponent, {
      initialState: {
        receipt: {...receipt},
        isEdit: true,
        isModal: true
      },
      backdrop: 'static'
    });
  }

  enableReceiptsSelect() {
    this.receiptSelection = true;
    this.receipts$ = this.receipts$.pipe(
      map((receipts) =>
        receipts.map((receipt) => ({
          ...receipt,
          isSelected: false
        }))
      )
    );
  }

  disableReceiptsSelect() {
    this.receiptSelection = false;
    this.receiptsSelected = [];
  }

  selectReceipt(receipt: Receipt) {
    if (!this.receiptSelection) return this.goToEditReceiptsModal(receipt);
    receipt.isSelected = false;

    if (
      this.receiptsSelected.some(
        (receiptSelected) => receiptSelected.key == receipt.key
      )
    ) {
      receipt.isSelected = false;
      this.receiptsSelected = this.receiptsSelected.filter(
        (receiptSelected) => receiptSelected.key != receipt.key
      );
    } else {
      this.receiptsSelected = [...this.receiptsSelected, receipt];
      this.selectedReceiptsKey = this.receiptsSelected.map(
        (receipt) => receipt.key
      );
      receipt.isSelected = true;
    }
  }

  async sendReceipts() {
    const modalRef = this.modal.show(FundsToRenderSelectComponent, {
      initialState: {fundsToRender: this.fundsToRender}
    });

    modalRef.onHide.pipe(take(1)).subscribe(async () => {
      if (!modalRef.content.accepted) return;
      localStorage.setItem(
        'fundToRenderKey',
        modalRef.content.fundToRenderSelected.key
      );

      localStorage.setItem(
        'receiptKeys',
        JSON.stringify(this.selectedReceiptsKey)
      );
      this.router.navigateByUrl('admin/fund-to-render-details');
    });
  }

  getCategoriesFromReceipts(receipts: Receipt[]) {
    return _.uniqBy(receipts.map((receipt) => receipt.category), 'key');
  }
}
