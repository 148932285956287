import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-bill-date-picker',
  templateUrl: './bill-date-picker.component.html',
  styleUrls: ['./bill-date-picker.component.css']
})
export class BillDatePickerComponent implements OnInit {
  @Input() date;
  @Output() dateChange = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
    this.date = this.formatDate(this.date);
  }

  private formatDate(date) {
    const newDate = new Date(date);
    const format = 'yyyy/MM/dd';
    const locale = 'en-US';
    const zone = 'UTC';
    const formattedDate = formatDate(newDate, format, locale, zone);
    return formattedDate.replace(/\//g, '-');
  }

  onChange($event: any) {
    this.dateChange.emit($event.target.value);
  }
}
