<div class="p-2">
  <h2>Nuevo cuestionario</h2>

  <form [formGroup]="quizForm" (ngSubmit)="submit()">
    <div id="invoice-items-details" class="pt-2">
      <div class="row">
        <div class="table-responsive col-sm-12 padding-table-bottom">

          <div class="row">
            <div class="col-md-4 col-12">
              <div class="form-group">
                <label>Nombre del cuestionario *</label>
                <input type="text" class="form-control" formControlName="name"
                       placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.name.errors }"/>
                <div class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.name.errors">
                  El nombre es obligatorio
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="media d-flex">
              <h4>Nueva pregunta</h4>
              <div class="btn btn-warning btn-sm ml-2" (click)="addQuestion()">
                <i class="feather ft-plus"></i>
              </div>
            </div>
          </div>
          <table class="table">
            <thead>
            <tr>
              <th>Pregunta</th>
              <th class="text-right">
                Respuestas
              </th>
              <th class="text-right">Respuesta correcta</th>
              <th class="text-right">Acción</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let wiz of questionss; let index = index">
              <td><p>{{wiz.question}}</p></td>
              <td class="text-right">
                <span *ngIf="wiz.answers.length == 1">{{wiz.answers[0].answer}}</span>
                <button *ngIf="wiz.answers.length > 1" (mouseover)="wiz.isShow = true"
                        (mouseleave)="wiz.isShow = false" type="button" class="btn btn-success"></button>
                <div class="activate-tooltip d-flex justify-content-center align-items-center flex-column"
                     *ngIf="wiz.isShow">
                  <div class="d-flex justify-content-around" *ngFor="let answer of wiz.answers; let i = index">
                    <span class="mr-3">Respuesta&nbsp;{{i + 1}}:</span>
                    <div class="d-flex justify-content-center">
                      <span>{{answer.answer}}</span>
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-right">{{wiz.correct + 1}}</td>
              <td class="text-right">
                <div class="btn btn-danger btn-sm" (click)="dropQuestion(index)"><i
                  class="feather ft-trash"></i></div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="modal-footer border-0">
        <button class="btn btn-success add-btn">{{update ? 'Actualizar' : 'Guardar'}}</button>
      </div>
    </div>
  </form>
</div>
