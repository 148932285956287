<div class="modal-header">
  <h3 class="pt-1">
    Números seriales ({{ this.article?.serialNumber.length }} /
    {{ this.article?.quantity }})
  </h3>
</div>

<div class="modal-body">
  <div
    class="w-100 d-flex justify-content-between align-items-center mb-2"
    *ngFor="let serialNumber of article.serialNumber; let index = index">
    <label>{{ serialNumber }}</label>
    <div class="d-flex flex-row">
      <div
        class="btn-edit text-success d-flex align-items-center cursor-pointer mr-1"
        (click)="updateSerial(index)">
        <i class="feather ft-refresh-ccw"></i>
      </div>
      <div
        class="btn-edit text-danger d-flex align-items-center cursor-pointer"
        (click)="removeSerial(index)">
        <i class="feather ft-x"></i>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end w-100 p-1">
    <button
      class="btn btn-info mr-1"
      (click)="addSerials()"
      *ngIf="this.article?.serialNumber.length < this.article?.quantity">
      Agregar seriales
    </button>

    <button
      class="btn btn-success mr-1"
      (click)="completeAllSerials()"
      *ngIf="this.article?.serialNumber.length == this.article?.quantity">
      Aceptar
    </button>
    <button class="btn btn-danger" (click)="modal.hide()">Cerrar</button>
  </div>
</div>
