import {Injectable} from '@angular/core';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {Article} from "../../admin/pages/warehouses/interfaces/article";
import {AngularFireStorage} from "@angular/fire/storage";
import {map, take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  id: string;

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore,
              private storage: AngularFireStorage) {
  }

  getAll(): Observable<Article[]> {
    return this.afs.collectionGroup<Article>(`articles`, ref => ref
      .where('trash', '==', false))
      .snapshotChanges()
      .pipe(map(actions => actions.map((a:any) => {
        return {
          ...a.payload.doc.data(),
          key: a.payload.doc.id,
          warehouseKey: a.payload.doc.ref.parent.parent.id
        }
      })))
  }

  getAllByWarehouse(warehouseKey: string): Observable<Article[]> {
    return this.afs.collection<Article>(`warehouses/${warehouseKey}/articles`, ref => ref
      .where('trash', '==', false))
      .snapshotChanges()
      .pipe(map(actions => actions.map((a:any) => {
        return {
          ...a.payload.doc.data(),
          key: a.payload.doc.id,
          warehouseKey: a.payload.doc.ref.parent.parent.id
        }
      })))
  }

  async add(warehouseKey: any, article: any): Promise<any> {
    return await this.afs.collection<Article>(`warehouses/${warehouseKey}/articles`).add(article);
  }

  async delete(warehouseKey: string, articlesKey: string): Promise<void> {
    return await this.afs.doc<Article>(`warehouses/${warehouseKey}/articles/${articlesKey}`).update({trash: true});
  }

  async updateQuantity(warehouseKey: string, articlesKey: string, quantity: number): Promise<void> {
    return await this.afs.doc<Article>(`warehouses/${warehouseKey}/articles/${articlesKey}`).update({quantity: quantity >= 0 ? quantity : 0});
  }

  update(warehouseKey: any, articleKey: any, article: any) {
    this.afs.doc(`warehouses/${warehouseKey}/articles/${articleKey}`).update(article);
  }

  async uploadPicture(productPhoto, productKey) {
    if (productKey == null) {
      this.id = this.afs.createId();
    } else {
      this.id = productKey;
    }
    const uploadRef = this.getStorageRef(this.id);
    await uploadRef.put(productPhoto);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadImage(productPhoto, this.id);

    return url;
  }

  uploadImage(data, id) {
    return this.storage.upload(`article/${id}/articlePicture.jpeg`, data);
  }

  getStorageRef(id) {
    return this.storage.ref(`article/${id}/articlePicture.jpeg`);
  }

  get(warehouseKey: string, articleKey: string) {
    return this.afs.doc(`warehouses/${warehouseKey}/articles/${articleKey}`).valueChanges();
  }
}
