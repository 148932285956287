<div class="modal-header border-0">
  <button class="close pull-right" (click)="modal.hide(100)">
    <span class="">&times;</span>
  </button>
</div>

<app-datatable [rows$]="items$"
               [config]="config"
               (rowSelected)="selectItem($event)">

  <app-datatable-column [title]="field.title" *ngFor="let field of fields">
    <ng-template let-row #column>
      <ng-container [ngSwitch]="field.type">
        <ng-container *ngSwitchCase="fieldDataTableColumnTypeEnum.DATE">
          {{ row[field.column] | date : 'dd/MM/yyyy HH:mm' }}
        </ng-container>

        <ng-container *ngSwitchCase="fieldDataTableColumnTypeEnum.CURRENCY">
          {{ row[field.column] | currency }}
        </ng-container>

        <ng-container *ngSwitchCase="fieldDataTableColumnTypeEnum.ID">
          {{ row[field.column] | slice:0 : -3 }}
        </ng-container>

        <ng-container *ngSwitchCase="fieldDataTableColumnTypeEnum.ARRAY">
          <div *ngFor="let item of row[field.column]">{{item?.name}}</div>
        </ng-container>

        <ng-container *ngSwitchCase="fieldDataTableColumnTypeEnum.LABEL">
          <div class="badge badge-info">{{field.labels[row[field.column]]}}</div>
        </ng-container>

        <ng-container *ngSwitchDefault>
          {{ row[field.column] }}
        </ng-container>
      </ng-container>
    </ng-template>
  </app-datatable-column>

</app-datatable>
