<div class="modal-header">
  <h4 class="modal-title pull-left">Fondo por rendir</h4>
  <button
    type="button"
    class="pull-right btn"
    (click)="modal.hide()">
    <i class="feather ft-x"></i>
  </button>
</div>
<div
  *ngIf="!!pendingAccountability"
  class="row px-2 justify-content-center">
  <h4 class="modal-title pull-left">
    Pendiente por pagar de la rendición anterior:
    <span class="text-danger">${{ pendingAccountability }}</span>
  </h4>
</div>
<div
  *ngIf="!!fundToRenderForm.value.pendingAccountability"
  class="row px-2 justify-content-center">
  <h4 class="modal-title pull-left">
    Pendiente por pagar de la rendición anterior:
    <span
      class="text-danger"
    >${{ fundToRenderForm.value.pendingAccountability }}</span
    >
  </h4>
</div>
<div class="d-flex flex-column px-2 justify-content-center align-items-center  mt-1">
  <div
    class="badge badge-pill"
    [ngClass]="getStatusClass(fundToRenderForm.value.status)">
    {{ fundToRenderStatusLabel[fundToRenderForm.value.status] }}
  </div>

  <div
    class="alert alert-warning mt-1"
    role="alert"
    *ngIf="!!fundToRenderForm.value.commentReturnedToDraft && fundToRenderForm.value.status == fundToRenderStatusEnum.DRAFT">
    Fondo por rendir devuelto por: {{fundToRenderForm.value.commentReturnedToDraft}}
  </div>
</div>

<div class="px-1 pb-1">
  <form [formGroup]="fundToRenderForm">
    <div>
      <label>Título</label>
      <input
        type="text"
        class="form-control"
        formControlName="title"
        [ngClass]="{'is-invalid': !!submitted && formControls.title.errors}"/>
      <small class="invalid-feedback"> Campo obligatorio </small>
    </div>

    <div class="mt-1">
      <label>Motivo del fondo por rendir</label>
      <textarea
        formControlName="reason"
        type="text"
        rows="2"
        class="form-control"
        placeholder=""
        [ngClass]="{'is-invalid': submitted && formControls.reason.errors}">
      </textarea>
      <small class="invalid-feedback"> Campo obligatorio </small>
    </div>

    <div class="mt-1">
      <label class="mr-1 align-self-center">Proyecto o Centro de costos</label>
      <div>
        <ng-container>
          <ng-select
            [items]="selectArray"
            [searchable]="true"
            bindLabel="name"
            appendTo="body"
            placeholder="Selecciona un proyecto"
            formControlName="project"
            groupBy="type">
            <ng-template
              ng-option-tmp
              let-item="item">
              {{ item.name }}
            </ng-template>
          </ng-select>
        </ng-container>
        <small
          *ngIf="formControls.project.errors && submitted"
          class="text-muted danger"
        >Campo obligatorio</small
        >
      </div>
    </div>

    <div class="mt-1">
      <div *ngIf="permission != permissionEnum.USER && !!fundToRenderForm.value.pendingAccountability">
        <div class="gallery text-center">
          <div>
            <h5>
              Monto Requerido: {{ requiredAmount | currency : 'USD' : 'symbol' : '1.0-0' }}
            </h5>
          </div>

          <div>
            <h5>Deuda del usuario:
              {{fundToRenderForm.value.pendingAccountability | currency : 'USD' : 'symbol' : '1.0-0' }}
            </h5>
          </div>
        </div>

        <div class="row justify-content-center">
          <h4>
            Total del fondo: ${{ requiredAmount }} -
            {{ fundToRenderForm.value.pendingAccountability }} =
            <span class="text-danger">
              ${{
              requiredAmount - fundToRenderForm.value.pendingAccountability
              }}</span
            >
          </h4>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <label>Desglose del monto</label>
        <div
          *ngIf="permission == permissionEnum.USER"
          class="add-breakdownAmount-btn"
          (click)="addBreakdownAmountControl()"
          ngbTooltip="Agregar nuevo monto">
          <i class="la la-plus-circle text-success"></i>
        </div>
      </div>
      <div
        formArrayName="breakdownAmounts"
        class="ml-2">
        <div *ngFor="let breakdownAmountForm of breakdownAmounts.controls; let i = index">
          <div [formGroup]="breakdownAmountForm">
            <div class="mr-1">
              <div class="mt-1">
                <label
                  for="amount"
                >Monto <span class="text-danger">*</span></label
                >
                <input
                  class="form-control"
                  id="amount"
                  type="number"
                  formControlName="amount"/>
              </div>
              <div class="mt-1">
                <label
                  for="amount1"
                >Descripción <span class="text-danger">*</span></label
                >
                <input
                  class="form-control"
                  id="amount1"
                  type="text"
                  formControlName="description"/>
              </div>
              <div class="mt-1">
                <label>Categoría <span class="text-danger">*</span></label>
                <ng-select
                  formControlName="category"
                  [clearable]="false"
                  [items]="breakdownAmountCategories"
                  bindLabel="name">
                </ng-select>
              </div>
            </div>
            <button
              *ngIf="
                permission == permissionEnum.USER && breakdownAmounts.length > 1
              "
              class="btn btn-danger mt-1"
              (click)="removeBreakdownAmountForm(i)">
              <i class="la la-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="
        fundToRenderForm.value.status == fundToRenderStatusEnum.AUTHORIZED &&
        permission == permissionEnum.SUPERVISOR
      ">
      <label class="mt-1">PDF del comprobante</label>
      <div class="custom-file">
        <input
          type="file"
          class="custom-file-input"
          (change)="chooseFile($event)"
          accept="application/pdf"
          [ngClass]="{
            'is-invalid': submitted && formControls.pdf.errors
          }"/>
        <label class="custom-file-label">{{
          !!fundToRenderForm.value.pdf ? 'Archivo cargado' : 'Solo archivos pdf'
          }}</label>
        <small class="invalid-feedback"> La factura es obligatoria </small>
      </div>
    </div>

    <div
      *ngIf="!!fundToRenderForm.value.pdf || !!fundToRender.pdf"
      class="w-100 mt-1">
      <div class="card shadow-none">
        <div class="card-body p-0">
          <div class="write-post">
            <div class="col-sm-12 px-0">
              <div [innerHTML]="url"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 mb-2">
      <div class="d-flex justify-content-end">
        <button
          *ngIf="userDraft()"
          class="btn btn-info mr-1"
          (click)="changeFundToRenderStatus()">
          Enviar solicitud
        </button>

        <button
          *ngIf="sendToReviewAdmin()"
          class="btn btn-danger mr-1"
          (click)="rejectFundToRender()">
          Rechazar
        </button>

        <button
          *ngIf="sendToReviewAdmin()"
          class="btn btn-warning mr-1"
          (click)="changeFundToRenderStatus()">
          Enviar a aprobación
        </button>

        <button
          *ngIf="validateFundToRender()"
          class="btn btn-info mr-1"
          (click)="changeFundToRenderStatus()">
          Autorizar
        </button>

        <button
          *ngIf="validateFundToRender()"
          class="btn btn-danger mr-1"
          (click)="rejectFundToRender()">
          Rechazar
        </button>

        <button
          *ngIf="supervisorAttachVoucher()"
          class="btn btn-success mr-1"
          (click)="changeFundToRenderStatus()">
          Guardar como pagado
        </button>

        <button
          class="btn btn-success mr-1"
          *ngIf="hideUserSaveButton()"
          (click)="submit()">
          Guardar
        </button>

        <button
          class="btn btn-success mr-1"
          *ngIf="hasSupervisorPermission() && fundToRender.status == fundToRenderStatusEnum.GENERATED"
          (click)="changeStatusToDraft()">
          Pasar a borrador
        </button>

        <button
          class="btn btn-outline-danger"
          (click)="modal.hide()">
          Cerrar
        </button>
      </div>
    </div>
  </form>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
><p style="color: white">Subiendo...</p></ngx-spinner
>
