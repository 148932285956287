<div class="p-2">
  <h2>Nuevo proyecto</h2>

  <form [formGroup]="projectForm">
    <div class="row mt-2">
      <div class="col-md-6">
        <div class="form-group">
          <label>Nombre *</label>
          <input type="text" class="form-control" formControlName="name"
                 placeholder="" [ngClass]="{'is-invalid': submitted && formControls.name.errors}"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.name.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Tipo *</label>
          <select class="form-control"
                  formControlName="status"
                  [ngClass]="{ 'is-invalid': submitted && formControls.status.errors }">
            <option [value]="type.key" *ngFor="let type of projectType | keyvalue">{{ type.value }}</option>
          </select>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.status.errors">
            <div *ngIf=" formControls.status.errors.required">
              El tipo es obligatorio
            </div>
          </small>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Orden de servicio *</label>
          <input type="text" class="form-control" formControlName="code" disabled
                 placeholder="" [ngClass]="{'is-invalid': submitted && formControls.code.errors}"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.code.errors">
            <div *ngIf=" formControls.code.errors.required">
              El centro de costo es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label>Cliente *</label>
          <ng-select formControlName="client"
                     [items]="selectArray"
                     bindLabel="name"
                     appendTo="body">
          </ng-select>
          <small class="form-text text-muted danger" *ngIf="submitted && formControls.client.errors">
            <div>
              Necesita agregar mínimo una categoría
            </div>
          </small>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6">
        <div class="form-group">
          <label>Fecha de inicio *</label>
          <input type="date" class="form-control" formControlName="startDate" [ngClass]="{ 'is-invalid': submitted && formControls.startDate.errors }"
                 placeholder=""/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.startDate.errors">
            <div *ngIf=" formControls.startDate.errors.required">
              La fecha de inicio es obligatoria
            </div>
          </small>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Fecha de finalización *</label>
          <input type="date"
                 class="form-control"
                 formControlName="endDate"
                 placeholder="introduzca la fecha de finalización" [ngClass]="{'is-invalid': submitted && formControls.endDate.errors}"/>
          <small class="invalid-feedback">Campo obligatorio</small>
        </div>
      </div>

      <div class="mt-2 col-md-6">
        <label>
          Valor de venta
        </label>
        <input type="number" class="form-control" formControlName="saleValue">
      </div>

      <div class="col-md-12 mt-1">
        <div>
          <div class="d-inline-block custom-control custom-radio mr-1">
            <input type="radio" class="custom-control-input bg-default" formControlName="type"
                   [value]="costCenterType.PROJECT" name="type" id="radioProject">
            <label class="custom-control-label" for="radioProject">Proyecto</label>
          </div>
          <div class="d-inline-block custom-control custom-radio mr-1">
            <input type="radio" class="custom-control-input bg-default" formControlName="type"
                   [value]="costCenterType.CO" name="type" id="radioCO">
            <label class="custom-control-label" for="radioCO">Centro de costo</label>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-1">
        <h4><label>Presupuestos</label></h4>
      </div>

      <div class="col-md-10 col-10">
        <div class="form-group">
          <label>Categoría</label>
          <ng-select [items]="selectArrayCategory"
                     [searchable]="true"
                     bindLabel="name"
                     [multiple]="false"
                     placeholder="Seleccione una categoría"
                     formControlName="budget">
            <ng-template ng-option-tmp let-item="item">
              {{item.name}}
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div class="col-md-2 col-2">
          <button class="btn btn-success category-button" (click)="addBudget()"><i class="feather ft-plus-circle"></i></button>
      </div>

      <ng-container *ngFor="let category of arrayCategory; let index = index">
        <div class="col-md-6">
          <div class="form-group">
            <label>{{category.name}}</label>
            <input type="text" class="form-control" value="0" #budget (change)="changeBudget(budget, index)" placeholder="Presupuesto"/>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn" (click)="submit()">Agregar</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide()">Cancelar</div>
    </div>
  </form>
</div>
