<div class="p-2">
  <h2>Contacto</h2>

  <form [formGroup]="providerForm" (ngSubmit)="submit()">
    <div class="row mt-2">
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Nombre *</label>
          <input type="text" class="form-control" formControlName="nameContact"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.nameContact.errors }"/>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.nameContact.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>E-mail *</label>
          <input type="email" class="form-control" formControlName="emailContact"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.emailContact.errors }"/>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.emailContact.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Dirección *</label>
          <input type="text" class="form-control" formControlName="addressContact"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.addressContact.errors }"/>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.addressContact.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Ciudad *</label>
          <input type="text" class="form-control" formControlName="cityContact"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.cityContact.errors }"/>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.cityContact.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Comuna *</label>
          <input type="text" class="form-control" formControlName="communeContact"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.communeContact.errors }"/>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.communeContact.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>País *</label>
          <input type="text" class="form-control" formControlName="countryContact"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.countryContact.errors }"/>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.countryContact.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Región *</label>
          <input type="text" class="form-control" formControlName="regionContact"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.regionContact.errors }"/>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.regionContact.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Fax *</label>
          <input type="text" class="form-control" formControlName="faxContact"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.faxContact.errors }"/>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.faxContact.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Teléfono *</label>
          <input type="number" class="form-control" formControlName="phoneContact"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.phoneContact.errors }"/>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.phoneContact.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>
    </div>

    <h2>Ficha de proveedor</h2>

    <div class="row mt-2">
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>RUT *</label>
          <input type="text" class="form-control" formControlName="rut"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.rut.errors }"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.rut.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Nombre *</label>
          <input type="text" class="form-control" formControlName="name"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.name.errors }"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.name.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Giro *</label>
          <input type="text" class="form-control" formControlName="lineOfBusiness"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.lineOfBusiness.errors }"/>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.lineOfBusiness.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Dirección *</label>
          <input type="text" class="form-control" formControlName="address"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.address.errors }"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.address.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Comuna *</label>
          <input type="text" class="form-control" formControlName="commune"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.commune.errors }"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.commune.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Región *</label>
          <input type="text" class="form-control" formControlName="region"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.region.errors }"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.region.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Teléfono *</label>
          <input type="number0" class="form-control" formControlName="phone"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.phone.errors }"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.phone.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>E-mail *</label>
          <input type="text" class="form-control" formControlName="email"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.email.errors }"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.email.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Ciudad *</label>
          <input type="text" class="form-control" formControlName="city"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.city.errors }"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.city.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>País *</label>
          <input type="text" class="form-control" formControlName="country"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.country.errors }"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.country.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Sitio web *</label>
          <input type="text" class="form-control" formControlName="website"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.website.errors }"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.website.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>
    </div>

    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn">Agregar</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide()">Cancelar</div>
    </div>
  </form>

</div>
