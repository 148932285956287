<div *ngIf="validation || !update">
  <form [formGroup]="questionForm" (ngSubmit)="submit()">
    <div class="card mb-0">
      <div class="card-header">
        <h2>Nueva Pregunta</h2>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>Pregunta *</label>
              <input class="form-control" formControlName="question"
                     placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.question.errors }">
              <div class="form-text text-muted danger invalid-feedback"
                   *ngIf="submitted && formControls.question.errors">
                Es obligatorio
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-12">
            <div class="media d-flex">
              <h4>Nueva respuesta</h4>
              <div class="btn btn-warning btn-sm ml-2"
                   ngbTooltip="Agregar respuesta"
                   (click)="addAnswer()">
                <i class="feather ft-plus"></i>
              </div>
            </div>
          </div>
        </div>

        <div formArrayName="answers" class="row mt-2">
          <div class="col-12 mb-1" *ngFor="let answer of answer.controls; let i = index">
            <label>Respuesta {{i + 1}}</label>
            <div [formGroupName]="i" class="w-100 d-flex align-items-center">
                    <textarea formControlName="answer" rows="2" cols="10" class="w-100 form-control"
                              placeholder=""
                              [ngClass]="{ 'is-invalid': submitted && answer.status == 'INVALID' }">
                            </textarea>
              <div class="d-flex ml-1">
                <div class="btn btn-warning btn-sm"
                     ngbTooltip="Eliminar respuesta"
                     (click)="removeAnswer(i)">
                  <i class="feather ft-minus"></i>
                </div>

                <div class="btn btn-{{questionForm.value.correctAnswer == i ? 'success': 'primary'}} btn-sm ml-1"
                     ngbTooltip="Seleccionar como correcta" (click)="setCorrectAnswer(i)">
                  <i class="feather ft-check"></i>
                </div>
              </div>
            </div>

            <div class="form-text text-muted danger"
                 *ngIf="submitted && answer.status == 'INVALID'">
              Es obligatorio
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="btn btn-success float-right" (click)="submit()">
          <span>Agregar pregunta</span>
        </div>
        <div class="btn btn-primary float-right mr-2" (click)="modal.hide()">
          <span>Cerrar</span>
        </div>
      </div>
    </div>
  </form>
</div>
