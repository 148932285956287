import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {DataTableConfig} from 'src/app/shared/interfaces/data-table-config';
import {LogsService} from '../../../../services/logs.service';

@Component({
  selector: 'app-fund-to-render-logs',
  templateUrl: './fund-to-render-logs.component.html',
  styleUrls: ['./fund-to-render-logs.component.css']
})
export class FundToRenderLogsComponent implements OnInit {
  logs$: Observable<any>;
  config: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron resultados',
    title: ''
  };

  constructor(private _log: LogsService) {}

  ngOnInit(): void {
    this.logs$ = this._log.getLogsFundsToRender();
  }
}
