<form [formGroup]="providerForm">
  <div class="row">
    <div class="form-group col-sm-6">
      <label>PROVEEDOR *</label>
      <ng-select [items]="providers"
                 [searchable]="true"
                 bindLabel="name"
                 placeholder="Selecciona un proveedor"
                 formControlName="provider"
                 (change)="changeProviderForm(null)">
        <ng-template ng-option-tmp let-item="item">
          {{item.name}}
        </ng-template>
      </ng-select>
      <small class="form-text text-muted danger invalid-feedback"
             *ngIf="submitted && formControls.user.errors">
        <div *ngIf="formControls.user.errors.required">
          Debe de elegir un usuario
        </div>
      </small>
    </div>

    <div class="form-group col-sm-6">
      <label>Proyecto o Centro de costos *</label>
      <ng-select [items]="selectArray"
                 [searchable]="true"
                 bindLabel="name"
                 placeholder="Selecciona un proyecto"
                 formControlName="project"
                 groupBy="type"
                 (change)="changeProviderForm(true)">
        <ng-template ng-option-tmp let-item="item">
          {{item.name}}
        </ng-template>
      </ng-select>
      <small class="form-text text-muted danger invalid-feedback"
             *ngIf="submitted && formControls.project.errors">
        <div *ngIf="formControls.project.errors.required">
          Debe de elegir un proyecto
        </div>
      </small>
    </div>

    <div class="form-group col-sm-6">
      <label>CATEGORÍA DE PRESUPUESTO *</label>
      <ng-select [items]="budgets"
                 [searchable]="true"
                 bindLabel="name"
                 placeholder="Selecciona una categoría"
                 formControlName="budget"
                 (change)="changeProviderForm(null)">
        <ng-template ng-option-tmp let-item="item">
          {{item.name}}
        </ng-template>

        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          <span class="ng-value-label">{{item.name}}</span>
          <span class="ng-value-icon right" (click)="clear(item)">×</span>
        </ng-template>
      </ng-select>
      <small class="form-text text-muted danger invalid-feedback"
             *ngIf="submitted && formControls.budget.errors">
        <div *ngIf="formControls.budget.errors.required">
          Debe de elegir una categoría de presupuesto
        </div>
      </small>
    </div>
  </div>
</form>
