<div class="row" *ngIf="!!applicant">
  <button
    *ngIf="!isMobile"
    class="btn btn-facebook btn-float-left add-btn"
    tooltip="Regresar" (click)="goToApplicantList()"
  >
    <i class="feather ft-arrow-left"></i>
  </button>

  <div class="col-12">
    <div *ngIf="!isMobile; else mobileApplicantHeader" class="row">
      <div class="col-4 bg-white">
        <div class="flex-row mt-2 gallery">
          <button
            class="btn btn-dark" *ngIf="applicant.status.name != 'bloqueado'"
            (click)="changeStatus(applicant.key,'bloqueado', indexApplicant)"
          >
            <i class="la la-ban"></i> Bloqueado
          </button>
          <button
            class="btn btn-danger" *ngIf="applicant.status.name == 'postulante'"
            (click)="changeStatus(applicant.key,'no apto', indexApplicant)"
          >
            <i class="la la-close"></i> No apto
          </button>
          <button
            class="btn btn-info" *ngIf="applicant.status.name == 'postulante'"
            (click)="changeStatus(applicant.key,'apto', indexApplicant)"
          >
            <i class="la la-check-circle"></i> Apto
          </button>
          <button
            class="btn btn-warning" *ngIf="applicant.status.name == 'apto'"
            (click)="changeStatus(applicant.key,'revisión', indexApplicant)"
          >
            <i class="icon-book-open"></i> Revisión
          </button>
          <button
            class="btn btn-success"
            *ngIf="applicant.status.name == 'revisión' || applicant.status.name == 'calificado'"
            (click)="changeStatus(applicant.key,'contratado', indexApplicant)"
          >
            <img
              src="assets/images/handshake-regular.svg" width="20px" alt=""
            > Contratado
          </button>
          <button
            class="btn btn-success" *ngIf="applicant.status.name == 'contratado'"
            (click)="changeStatus(applicant.key,'calificado', indexApplicant)"
          >
            <i class="icon-badge"></i> Calificado
          </button>

          <button
            class="btn btn-warning" *ngIf="applicant.status.name == 'revisión'"
            (click)="assignProject()"
          >
            <i class="feather ft-edit"></i> Asignar a proyecto
          </button>

          <button
            class="btn btn-info btn-sm"
            *ngIf="applicant.project"
            (click)="copyLink()"
          >
            <i class="feather ft-copy"></i> Copiar link para subir datos
          </button>
        </div>
      </div>
      <div class="col-4 bg-white d-flex">
        <div class="user-data text-center bg-white rounded p-1">
          <h4 class="mb-0 font-weight-bold">
            {{applicant.name}} {{applicant.surnames}}
          </h4>
          <p class="m-0">{{!applicant.position.reference ? applicant.position.name : (applicant.position.reference | doc | async)?.name}}</p>
          <p class="m-0">{{applicant.email}}</p>
          <p class="m-0">{{applicant.city.name}}</p>
          <div
            class="badge mt-1"
            [ngClass]="getStatusClass(applicant.status.name)"
          >
            {{ applicant.status.name}}
          </div>
        </div>

        <div style="margin-top: 5px;">
          <img
            *ngIf="applicant.project"
            src="assets/images/ribbon_colsan.png"
            class=" text-warning"
            style="width: 55px; height: 70px;"
          >
        </div>
      </div>
      <div class="col-4 bg-white">
        <div class="media profil-cover-details w-100">
          <div class="media-body pt-1 px-2">
            <div class="row justify-content-end">
              <div class="text-right justify-content-end">
                <div class="btn-group float-right ml-2">
                  <div class="d-inline-block float-md-right" ngbDropdown>
                    <button *ngIf="indexApplicant != 0" class="btn btn-info ml-2" (click)="previousApplicant()"><
                      Anterior
                    </button>
                    <button *ngIf="indexApplicant == 0" class="btn btn-light ml-2">< Anterior</button>
                    <button
                      *ngIf="applicants.length != (indexApplicant + 1)" class="btn btn-info ml-1"
                      (click)="nextApplicant()"
                    >Siguiente >
                    </button>
                    <button *ngIf="applicants.length == (indexApplicant + 1)" class="btn btn-light ml-1">Siguiente >
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-end mt-1">
              <button
                class="btn btn-success mr-1" tooltip="Acceso"
                (click)="sendAccountModal()"
              >
                <i class="feather ft-check"></i>
              </button>
              <button
                class="btn btn-warning mr-1" tooltip="Status"
                (click)="changeStatusModal()"
              >
                <i class="feather ft-edit"></i>
              </button>
              <button
                class="btn btn-warning mr-1"
                (click)="printInfoUser()"
              >
                <i class="feather ft-printer"></i>
              </button>
              <button
                class="btn btn-info" tooltip="Código QR"
                (click)="openQRModal()"
              >
                <i class="la la-qrcode"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #mobileApplicantHeader>
      <div class="row d-flex flex-column bg-white">
        <div
          class="w-100 d-flex align-items-center justify-content-center"
        >
          <div class="w-100 p-2 text-center">
            <h4 class="mb-0 font-weight-bold">
              {{applicant.name}} {{applicant.surnames}}
            </h4>
            <p
              class="m-0"
            >{{!applicant.position.reference ? applicant.position.name : (applicant.position.reference | doc | async)?.name}}</p>
            <p class="m-0">{{applicant.email}}</p>
            <p class="m-0">{{applicant.city.name}}</p>
            <div
              class="badge mt-1"
              [ngClass]="getStatusClass(applicant.status.name)"
            >
              {{ applicant.status.name}}
            </div>
          </div>
          <div style="margin-top: 5px;">
            <img
              *ngIf="applicant.project"
              src="assets/images/ribbon_colsan.png"
              class=" text-warning"
              style="width: 55px; height: 70px;"
            >
          </div>
        </div>

        <div class="d-flex justify-content-between w-100 mb-2">
          <div class="ml-1">
            <button
              *ngIf="indexApplicant != 0"
              class="btn btn-info mobile-navigation-button"
              (click)="previousApplicant()"
            >
              <
            </button>
            <button *ngIf="indexApplicant == 0" class="btn btn-light mobile-navigation-button">
              <
            </button>
          </div>

          <div class="mobile-applicant-actions">
            <button
              class="btn btn-warning mobile-modal-button"
              *ngIf="applicant.status.name == 'postulante'"
              (click)="handleOpenModal('curriculum')"
            >
              <i class="la la-file"></i> Curriculum
            </button>
            <button
              class="btn btn-info mobile-modal-button"
              *ngIf="applicant.status.name == 'postulante'"
              (click)="handleOpenModal('comments')"
            >
              <i class="la la-comments"></i> Comentarios
            </button>
          </div>

          <div class="mr-1">
            <button
              *ngIf="applicants.length != (indexApplicant + 1)" class="btn btn-info mobile-navigation-button"
              (click)="nextApplicant()"
            >
              >
            </button>
            <button *ngIf="applicants.length == (indexApplicant + 1)" class="btn btn-light mobile-navigation-button ">
              >
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <button
      class="btn btn-primary mobile-menu"
      (click)="toggleMenu()"
    >
      <i class="fa fa-navicon"></i>
    </button>

    <div *ngIf="isMenuOpen" class="mobile-menu-container">
      <div *ngIf="applicant.status.name != 'bloqueado'" class="mobile-menu-button-row">
        <div class="mobile-button-label">Bloqueado</div>
        <button
          class="btn btn-dark mobile-button"
          (click)="toggleMenu(); changeStatus(applicant.key,'bloqueado', indexApplicant)"
        >
          <i class="la la-ban"></i>
        </button>
      </div>

      <div class="mobile-menu-button-row" *ngIf="applicant.status.name == 'postulante'">
        <div class="mobile-button-label">No apto</div>
        <button
          class="mobile-button btn btn-danger"
          (click)="toggleMenu(); changeStatus(applicant.key,'no apto', indexApplicant)"
        >
          <i class="la la-close"></i>
        </button>
      </div>

      <div class="mobile-menu-button-row" *ngIf="applicant.status.name == 'postulante'">
        <div class="mobile-button-label">Apto</div>
        <button
          class="mobile-button btn btn-info"
          (click)="toggleMenu(); changeStatus(applicant.key,'apto', indexApplicant)"
        >
          <i class="la la-check-circle"></i>
        </button>
      </div>

      <div class="mobile-menu-button-row" *ngIf="applicant.status.name == 'apto'">
        <div class="mobile-button-label">Revisión</div>
        <button
          class="mobile-button btn btn-warning"
          (click)="toggleMenu(); changeStatus(applicant.key,'revisión', indexApplicant)"
        >
          <i class="icon-book-open"></i>
        </button>
      </div>

      <div
        class="mobile-menu-button-row"
        *ngIf="applicant.status.name == 'revisión' || applicant.status.name == 'calificado'"
      >
        <div class="mobile-button-label">Contratado</div>
        <button
          class="mobile-button btn btn-success"
          (click)="toggleMenu(); changeStatus(applicant.key,'contratado', indexApplicant)"
        >
          <img src="assets/images/handshake-regular.svg" width="20px" alt="">
        </button>
      </div>

      <div class="mobile-menu-button-row" *ngIf="applicant.status.name == 'contratado'">
        <div class="mobile-button-label">Calificado</div>
        <button
          class="mobile-button btn btn-success"
          (click)="toggleMenu(); changeStatus(applicant.key,'calificado', indexApplicant)"
        >
          <i class="icon-badge"></i>
        </button>
      </div>

      <div class="mobile-menu-button-row" *ngIf="applicant.status.name == 'revisión'">
        <div class="mobile-button-label">Asignar a proyecto</div>
        <button
          class="mobile-button btn btn-warning"
          (click)="toggleMenu(); assignProject()"
        >
          <i class="feather ft-edit"></i>
        </button>
      </div>

      <div class="mobile-menu-button-row" *ngIf="applicant.project">
        <div class="mobile-button-label">Copiar link para subir datos</div>
        <button
          class="mobile-button btn btn-info btn-sm"
          (click)="toggleMenu(); copyLink()"
        >
          <i class="feather ft-copy"></i>
        </button>
      </div>

      <div class="mobile-menu-button-row">
        <div class="mobile-button-label">Acceso</div>
        <button
          class="mobile-button btn btn-success"
          (click)="toggleMenu(); sendAccountModal()"
        >
          <i class="feather ft-check"></i>
        </button>
      </div>

      <div class="mobile-menu-button-row">
        <div class="mobile-button-label">Status</div>
        <button
          class="mobile-button btn btn-warning"
          (click)="toggleMenu(); changeStatusModal()"
        >
          <i class="feather ft-edit"></i>
        </button>
      </div>

      <div class="mobile-menu-button-row">
        <div class="mobile-button-label">Imprimir</div>
        <button
          class="mobile-button btn btn-warning"
          (click)="toggleMenu(); printInfoUser()"
        >
          <i class="feather ft-printer"></i>
        </button>
      </div>

      <div class="mobile-menu-button-row">
        <div class="mobile-button-label">Código QR</div>
        <button
          class="mobile-button  btn btn-info"
          (click)="toggleMenu(); openQRModal()"
        >
          <i class="la la-qrcode"></i>
        </button>
      </div>
    </div>

    <mat-tab-group>
      <mat-tab label="Comentarios">
        <div class="d-flex mt-2">
          <div [ngClass]="{'px-0 static-width mr-1':!isMobile, 'w-100':isMobile}">
            <div class="card shadow-none mb-0">
              <div class="card-body p-0">
                <div class="mb-1">
                  <h5 class="card-title mb-0 font-weight-bold text-center mt-1">Información personal</h5>
                </div>

                <div class="d-flex justify-content-center">
                  <img
                    *ngIf="user && user.imageUrl"
                    [src]="user.imageUrl"
                    class="img-fluid rounded-circle width-100 height-100 shadow-lg border border-3 cursor-pointer"
                    style="object-fit: cover; object-position: top"
                    ngxViewer
                    alt="timeline image"
                  />
                </div>

                <div class="grid-container px-1 pt-1 pb-0">
                  <div>
                    <h6 class="mb-0 font-weight-bold">Fecha de postulación</h6>
                    <p>{{applicant.createdAt | date: 'dd/MM/yyyy'}}</p>
                  </div>

                  <div>
                    <h6 class="mb-0 font-weight-bold">Nombre</h6>
                    <p>{{applicant.name}} {{applicant.surnames}}</p>
                  </div>

                  <div>
                    <h6 class="mb-0 font-weight-bold">email</h6>
                    <p>{{applicant.email}}</p>
                  </div>

                  <div>
                    <h6 class="mb-0 font-weight-bold">RUT</h6>
                    <p>{{applicant.rut}}</p>
                  </div>

                  <div>
                    <h6 class="mb-0 font-weight-bold">Edad</h6>
                    <p>{{applicant.age}} años</p>
                  </div>

                  <div>
                    <h6 class="mb-0 font-weight-bold">Fecha de nacimiento</h6>
                    <p>{{applicant.birthday | date: 'dd/MM/yyyy' : '+0300'}}</p>
                  </div>

                  <div>
                    <h6 class="card-title mb-0 font-weight-bold">Teléfono</h6>
                    <p>{{applicant.phone}}</p>
                  </div>

                  <div>
                    <h6 class="mb-0 font-weight-bold">Región</h6>
                    <p>{{applicant?.region?.name}}</p>
                  </div>

                  <div>
                    <h6 class="mb-0 font-weight-bold">Ciudad</h6>
                    <p>{{applicant.city.name}}</p>
                  </div>

                  <div>
                    <h6 class="mb-0 font-weight-bold">Nacionalidad</h6>
                    <p>{{applicant.nationality}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card shadow-none">
              <div class="card-body px-0">
                <div>
                  <h5 class="card-title mb-1 font-weight-bold text-center">Información de la empresa</h5>
                </div>
                <div class="grid-container px-1 pt-1 pb-0">
                  <div>
                    <h5 class="mb-0 font-weight-bold">Nivel educacional</h5>
                    <p>{{applicant.educationLevel}}</p>
                  </div>

                  <div>
                    <h5 class="mb-0 font-weight-bold">Cargo</h5>
                    <p
                    >{{!applicant.position.reference ? applicant.position.name : (applicant.position.reference | doc | async)?.name}}</p>
                  </div>

                  <div>
                    <h5 class="mb-0 font-weight-bold">Experiencia laboral (años)</h5>
                    <p>{{applicant.workExperience}} años</p>
                  </div>

                  <div>
                    <h5 class="mb-0 font-weight-bold">Pretensiones de renta</h5>
                    <p>{{applicant.expectation}}</p>
                  </div>

                  <div>
                    <h5 class="mb-0 font-weight-bold">Experiencia</h5>
                    <p>{{applicant.experience}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!isMobile" class="col-6 px-0">
            <app-applicant-curriculum
              [permission]="this.permission"
              [submitted]="this.submitted"
              [file]="this.file"
              [applicant]="this.applicant"
              [isMobile]="this.isMobile"
              [path]="this.path"
              [uploadMessage]="this.uploadMessage"
              [applicants]="this.applicants"
              [indexApplicant]="this.indexApplicant"
              [applicantKey]="this.applicantKey"
            >
            </app-applicant-curriculum>
          </div>

          <div *ngIf="!isMobile" class="col-3">
            <app-applicant-comments
              [comments]="this.comments"
              [users]="this.users"
              [usersNotification]="this.usersNotification"
              [commentForm]="this.commentForm"
              [applicant]="this.applicant"
              [currentUser]="this.currentUser"
              [submitted]="this.submitted"
              [indexApplicant]="this.indexApplicant"
              [applicants]="this.applicants"
              [applicantKey]="this.applicant?.key"
            >
            </app-applicant-comments>
          </div>
          <div
            class="col-lg-9 col-md-6 col-sm-12"
            *ngIf="applicant.titleApplicant"
          >
            <div class="card shadow-none">
              <div class="card-body pt-2">
                <div class="write-post">
                  <div class="col-sm-12 px-2">
                    <img src="{{applicant.titleApplicant}}" alt="" class="col-md-12 mb-2">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Cuestionario">
        <div class="d-flex" *ngIf="!!applicant.quiz"><span
          class="sidebar-label mt-2"
        >Respuestas correctas: {{this.quizRatingCorrect}}
          /{{applicant.quiz.length}}</span></div>
        <div class="d-flex"><span
          class="sidebar-label mt-2"
        >Porcentaje de correctas: {{this.quizRatingPercentage | number : '1.2-2'}}
          %</span>
        </div>

        <ng-container *ngFor="let quiz of applicant.quiz">
          <div class="mt-2">
            <label>{{quiz.answers[quiz.selected] == quiz.answers[quiz.correct] ? 'Correcto' : 'Incorrecto'}}</label>
            <div
              class="card-footer pt-0"
              [ngClass]="quiz.answers[quiz.selected] == quiz.answers[quiz.correct] ? 'background-color-correct' : 'background-color-incorrect'"
            >
              <label class="sidebar-label mt-2">{{quiz.question}}</label> <br>
              <label class="sidebar-label mt-2">Respuesta: {{quiz.answers[quiz.selected]}}</label> <br>
              <label *ngIf="quiz.answers[quiz.selected] != quiz.answers[quiz.correct]" class="sidebar-label mt-2">La
                respuesta
                correcta era: {{quiz.answers[quiz.correct]}}</label> <br>
            </div>
          </div>
        </ng-container>
      </mat-tab>

      <mat-tab label="Resumen">
        <div class="col-lg-12 flex-row d-flex justify-content-center">
          <div class="card shadow-none">
            <h5 class="font-weight-bold mb-0 text-center p-1">Información general
              <button class="btn btn-facebook btn-sm ml-1" (click)="openModalUpdateApplicant()">Editar</button>
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="card shadow-none">
              <div class="card-body">
                <div>
                  <h5 class="card-title mb-0 font-weight-bold text-center">Información personal</h5>
                </div>
                <hr>

                <div class="mt-1">
                  <h5 class="card-title mb-0 font-weight-bold">Fecha de postulación</h5>
                  <p>{{applicant.createdAt | date: 'dd/MM/yyyy'}}</p>
                </div>

                <div class="mt-2">
                  <h5 class="card-title mb-0 font-weight-bold">Nombre</h5>
                  <p>{{applicant.name}} {{applicant.surnames}}</p>
                </div>

                <div class="mt-2">
                  <h5 class="card-title mb-0 font-weight-bold">RUT</h5>
                  <p>{{applicant.rut}}</p>
                </div>

                <div class="mt-2">
                  <h5 class="card-title mb-0 font-weight-bold">email</h5>
                  <p>{{applicant.email}}</p>
                </div>

                <div class="mt-2">
                  <h5 class="card-title mb-0 font-weight-bold">Edad</h5>
                  <p>{{applicant.age}} años</p>
                </div>

                <div class="mt-2">
                  <h5 class="card-title mb-0 font-weight-bold">Fecha de nacimiento</h5>
                  <p>{{applicant.birthday | date: 'dd/MM/yyyy'}}</p>
                </div>

                <div class="mt-2">
                  <h5 class="card-title mb-0 font-weight-bold">Teléfono</h5>
                  <p>{{applicant.phone}}</p>
                </div>

                <div class="mt-2">
                  <h5 class="card-title mb-0 font-weight-bold">Región</h5>
                  <p>{{applicant?.region?.name}}</p>
                </div>

                <div class="mt-2">
                  <h5 class="card-title mb-0 font-weight-bold">Ciudad</h5>
                  <p>{{applicant.city.name}}</p>
                </div>

                <div class="mt-2">
                  <h5 class="card-title mb-0 font-weight-bold">Nacionalidad</h5>
                  <p>{{applicant.nationality}}</p>
                </div>

              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="card shadow-none">
              <div class="card-body">
                <div>
                  <h5 class="card-title mb-0 font-weight-bold text-center">Información de la empresa</h5>
                </div>
                <hr>
                <div class="mt-1">
                  <h5 class="card-title mb-0 font-weight-bold">Nivel educacional</h5>
                  <p>{{applicant.educationLevel}}</p>
                </div>

                <div class="mt-2">
                  <h5 class="card-title mb-0 font-weight-bold">Cargo</h5>
                  <p
                  >{{!applicant.position.reference ? applicant.position.name : (applicant.position.reference | doc | async)?.name}}</p>
                </div>

                <div class="mt-2">
                  <h5 class="card-title mb-0 font-weight-bold">Experiencia laboral (años)</h5>
                  <p>{{applicant.workExperience}} años</p>
                </div>

                <div class="mt-2">
                  <h5 class="card-title mb-0 font-weight-bold">Pretensiones de renta</h5>
                  <p
                  >{{(applicant.expectation.toString()).replaceAll(',', '').replace('$', '') | clearCurrency | swapCommasAndDots}}</p>
                </div>

                <div class="mt-2">
                  <h5 class="card-title mb-0 font-weight-bold">Experiencia</h5>
                  <p>{{applicant.experience}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Entrevista">
        <app-interview
          *ngIf="applicant"
          [interviewUrl]="applicant.interviewUrl"
          [applicant]="applicant"
        ></app-interview>
      </mat-tab>

      <mat-tab label="Contratos">
        <app-contracts
          *ngIf="!!applicant"
          [applicant]="applicant"
        ></app-contracts>
      </mat-tab>

      <mat-tab label="Registros">
        <table class="table border_bottom table-hover">
          <thead>
          <tr>
            <th class="border-top-0">Descripción</th>
            <th class="border-top-0">Usuario</th>
            <th class="border-top-0">Fecha</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let log of logs">
            <td class="text-truncate text-column">{{log.description}}</td>
            <td class="text-truncate text-column">{{log.user ? (log.user | doc | async)?.name : 'No asignado'}}</td>
            <td class="text-truncate text-column">{{log.createdAt | date: 'dd/MM/yyyy HH:mm'}}</td>
          </tr>
          </tbody>
        </table>
      </mat-tab>

      <mat-tab label="Documentos" *ngIf="user">
        <app-documents [user]="user"></app-documents>
      </mat-tab>

      <mat-tab label="Ficha reclutamiento" *ngIf="user">
        <app-recruitment-sheet
          [applicant]="applicant"
          [user]="user"
        ></app-recruitment-sheet>
      </mat-tab>
      >
    </mat-tab-group>
  </div>
</div>

<div *ngIf="isPrinting" #componentID>
  <div>
    <strong><label>Aplicante</label></strong>
    <div class="row mt-2">
      <div class="col-2 border">
        <strong><label>Nombre completo</label></strong>
      </div>
      <div class="col-10 border">
        <label>{{applicant?.name}} {{applicant?.surnames}}</label>
      </div>

      <div class="col-2 border">
        <strong><label>R.U.T</label></strong>
      </div>
      <div class="col-4 border">
        <label>{{applicant?.rut}}</label>
      </div>

      <div class="col-2 border">
        <strong><label>Cargo</label></strong>
      </div>
      <div class="col-4 border">
        <label>{{applicant?.position.name}}</label>
      </div>

      <div class="col-2 border">
        <strong><label>Estatus</label></strong>
      </div>
      <div class="col-4 border">
        <label>{{applicant?.status.name}}</label>
      </div>

      <div class="col-2 border">
        <strong><label>Teléfono</label></strong>
      </div>
      <div class="col-4 border">
        <label>{{applicant?.phone}}</label>
      </div>

      <div class="col-2 border">
        <strong><label>Email</label></strong>
      </div>
      <div class="col-4 border">
        <label>{{applicant?.email}}</label>
      </div>

      <div class="col-2 border">
        <strong><label>Región</label></strong>
      </div>
      <div class="col-4 border">
        <label>{{applicant?.region?.name}}</label>
      </div>

      <div class="col-2 border">
        <strong><label>Comuna</label></strong>
      </div>
      <div class="col-4 border">
        <label>{{applicant?.city?.name}}</label>
      </div>

      <div class="col-2 border">
        <strong><label>Fecha de nacimiento</label></strong>
      </div>
      <div class="col-4 border">
        <label>{{applicant?.birthday}}</label>
      </div>

      <div class="col-2 border">
        <strong><label>Experiencia laboral(años)</label></strong>
      </div>
      <div class="col-4 border">
        <label>{{applicant?.workExperience}}</label>
      </div>

      <div class="col-2 border">
        <strong><label>Nivel educacional</label></strong>
      </div>
      <div class="col-4 border">
        <label>{{applicant?.educationLevel}}</label>
      </div>

      <div class="col-2 border">
        <strong><label>Nacionalidad</label></strong>
      </div>
      <div class="col-4 border">
        <label>{{applicant?.nationality}}</label>
      </div>

      <div class="col-2 border">
        <strong><label>Pretensiones de renta</label></strong>
      </div>
      <div class="col-4 border">
        <label>{{applicant.expectation | clearCurrency | swapCommasAndDots}}</label>
      </div>

      <div class="col-2 border">
        <strong><label>Experiencia</label></strong>
      </div>
      <div class="col-10 border">
        <label>{{applicant?.experience}}</label>
      </div>

      <strong *ngIf="!!applicant?.titleApplicant"><label class="mt-4">Título</label></strong>
      <div class="mt-4" *ngIf="!!applicant?.titleApplicant">
        <div>
          <img src="{{applicant?.titleApplicant}}" style="height: 900px; width: 750px;">
        </div>
      </div>
    </div>
  </div>

  <div class="social-border footer">
    <div class="d-flex flex-row justify-content-center align-items-center pt-2">
      <img src="/assets/images/logo.png" class="logoSize mr-4" alt="">
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
  type="square-jelly-box"
><p style="color: white"> {{uploadMessage}} </p></ngx-spinner>
