import {Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {compilePipeFromMetadata} from '@angular/compiler';
import {ValidationService} from '../../../shared/template-services/validation.service';
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-curriculum',
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.css']
})
export class CurriculumComponent implements OnInit {
  @Input() formCurriculumSubmitted: any;
  @Input() applicantForm: any;
  curriculumForm: FormGroup;
  submitted: boolean = false;
  path: any;
  @ViewChild('labelImport', {static: true}) labelImport: ElementRef;
  @Output() eventDocuments: EventEmitter<any> = new EventEmitter<any>();
  curriculumToUpload: File = null;
  file: any;
  curriculumValidation: boolean = true;

  constructor(private formBuilder: FormBuilder) {
    this.curriculumForm = formBuilder.group({
      curriculum: ['', Validators.required],
      //title: [''],
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    let changeEducationLevel: SimpleChange = changes['applicantForm'];
    if (!!changeEducationLevel) this.applicantForm = changeEducationLevel;

    let changeSubmitted: SimpleChange = changes['formCurriculumSubmitted'];
    if (!!changeSubmitted) this.formCurriculumSubmitted = changeSubmitted.currentValue;
  }

  get formControls() {
    return this.curriculumForm.controls;
  }

  isValidPath(): boolean {
    return !(this.path == '' || this.path == null);
  }

  chooseFile(event, document: string) {
    if (event.target.files[0].name.slice(event.target.files[0].name.length - 3) != 'pdf') return AlertService.error('Solo se permiten archivos pdf')

    if (!ValidationService.validateFileSize(event, 10000)) return AlertService.toastError('El archivo debe ser menor a 10MB')

    this.file = event.target.files[0];
    if (event.target.files && this.file) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.path = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(this.file);
      if (document == 'curriculum') this.curriculumForm.patchValue({curriculum: this.file});
      //if (document == 'title') this.curriculumForm.patchValue({title: this.file});
    }
    // if (this.applicantForm.currentValue.educationLevel == 'Enseñanza Universitaria Completa' && !this.curriculumForm.value.title) return;
    // if (this.applicantForm.currentValue.educationLevel == 'Enseñanza Universitaria Completa' && this.curriculumForm.value.title && this.curriculumForm.valid) return this.eventDocuments.emit(this.curriculumForm.value);
    if (this.curriculumForm.valid) return this.eventDocuments.emit(this.curriculumForm.value);

  }
}
