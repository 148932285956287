import { Component, Input, OnInit } from '@angular/core';
import { Applicant } from '../../../../interfaces/applicant';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-curriculum-modal',
  templateUrl: './curriculum-modal.component.html',
  styleUrls: ['./curriculum-modal.component.css']
})
export class CurriculumModalComponent implements OnInit {
  @Input() url: any;
  @Input() permission: any;
  @Input() submitted: boolean = false;
  @Input() file: any;
  @Input() applicant: Applicant;
  @Input() isMobile: boolean = false;
  @Input() path: any;
  @Input() uploadMessage: string = '';
  @Input() applicants: any = [];
  @Input() indexApplicant: number;
  @Input() applicantKey: string;

  constructor(private modal: BsModalService) {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.modal.hide();
  }
}
