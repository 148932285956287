<div class="details-container p-2">
  <div class="details-item">
    <label>Valor neto</label>
    <h5 *ngIf="!_billData.updating">{{_billData.bill?.netValue | clearCurrency : false | swapCommasAndDots}}</h5>

    <input
      *ngIf="_billData.updating"
      type="text"
      class="form-control"
      [(ngModel)]="_billData.billToEdit.netValue" />
  </div>
  <div class="details-item">
    <label>IVA</label>
    <h5 *ngIf="!_billData.updating">{{_billData.bill?.iva | clearCurrency : false | swapCommasAndDots}}</h5>

    <input
      *ngIf="_billData.updating"
      type="text"
      class="form-control"
      [(ngModel)]="_billData.billToEdit.iva" />
  </div>
  <div class="details-item">
    <label>Total *</label>
    <h5 *ngIf="!_billData.updating">{{_billData.bill?.total | clearCurrency : false | swapCommasAndDots}}</h5>

    <input
      *ngIf="_billData.updating"
      type="text"
      class="form-control"
      [(ngModel)]="_billData.billToEdit.total" />
  </div>

  <div class="form-group" *ngIf="_billData.updating">
    <label class="font-weight-bold mb-0">PDF de la Factura</label>
    <div class="custom-file">
      <input
        type="file"
        class="custom-file-input"
        (change)="onBillPDFChange($event)"
        accept="application/pdf"/>
      <label class="custom-file-label">{{
        !!_billData.billToEdit.billPdf
          ? 'Archivo cargado'
          : 'Solo archivos pdf'
        }}</label>
    </div>
  </div>
</div>
