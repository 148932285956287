import {Component, OnDestroy, OnInit} from '@angular/core';
import {Sale} from '../../interfaces/sale';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import moment from 'moment';
import {SaleStatusEnum} from '../../enums/sale-status.enum';
import {Subscription} from 'rxjs';
import {ClientService} from '../../services/client.service';
import {Client} from '../../interfaces/client';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {User} from '../../../shared/interfaces/user';
import {SaleService} from '../../services/sale.service';
import {UserService} from 'src/app/shared/services/user.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import _ from 'lodash';
import {UserType} from '../../enums/user-type.enum';

declare const $;

@Component({
  selector: 'app-sale-details-modal',
  templateUrl: './sale-details-modal.component.html',
  styleUrls: ['./sale-details-modal.component.css'],
})
export class SaleDetailsModalComponent implements OnInit, OnDestroy {
  sale: Sale;
  clientsSubscription: Subscription = new Subscription();
  clients: Client[] = [];
  editor = ClassicEditor;
  saleForm: FormGroup;
  users: User[] = [];
  indexedUsers;
  salesBoardKey: string;
  comments: any[] = [];
  comment: string = '';
  commentsSubscription: Subscription = new Subscription();
  userPermission;
  userTypeEnum = UserType;
  isEditingDescription: boolean;
  isEditingTitle: boolean;
  config = {
    toolbar: [
      'bold',
      'italic',
      'link',
      'undo',
      'redo',
      'numberedList',
      'bulletedList',
      '|',
      'insertTable',
    ],
  };

  openAddclientModal: () => void;
  validateUserPermission: (permissionExpected) => boolean;

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private _client: ClientService,
    private _sale: SaleService,
    private _user: UserService,
    private modal: BsModalService
  ) {
    this.createSaleForm();
  }

  ngOnInit() {
    this.saleForm.patchValue({
      ...this.sale,
      closeDate: moment(this.sale.closeDate).format('YYYY-MM-DD'),
    });
    this.clientsSubscription = this._client.getAll().subscribe((clients) => {
      this.clients = clients;
      this.saleForm.patchValue({
        client: this.clients.find(
          (client) => client.key == (<any>this.sale.client).key
        ),
      });
    });

    this.listenForComments();
  }

  ngOnDestroy() {
    this.clientsSubscription.unsubscribe();
    this.commentsSubscription.unsubscribe();
  }

  get formControls() {
    return this.saleForm.controls;
  }

  createSaleForm() {
    this.saleForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: [''],
      closeDate: [moment().format('YYYY-MM-DD')],
      saleValue: [1, [Validators.required, Validators.min(1)]],
      salesMarginPercentage: [1, [Validators.required, Validators.min(1)]],
      referenceCode: ['', Validators.required],
      users: [[], Validators.required],
      client: ['', Validators.required],
      links: [[]],
      status: [SaleStatusEnum.LEAFLET],
      trash: [false],
    });
  }

  listenForComments() {
    this.commentsSubscription = this._sale
      .getAllComments(this.salesBoardKey, this.sale.key)
      .subscribe((data) => {
        this.comments = data.map((item: any) => ({
          ...item,
          user: this.indexedUsers[item.user.id],
        }));
        this.comments = _.orderBy(this.comments, 'createdAt', 'desc');
      });
  }

  update(value, field: string) {
    if (!this.saleForm.valid)
      return AlertService.toastError('Revise los campos');
    switch (field) {
      case 'title':
        if (!this.saleForm.value.title.trim()) return;
        this.isEditingTitle = false;
        break;

      case 'description':
        if (!this.saleForm.value.description.trim()) return;
        this.isEditingDescription = false;
        break;

      case 'closeDate':
        value = moment(this.saleForm.value.closeDate).toDate().getTime();
        break;

      case 'client':
        value = this._client.getReference(value.key);
        break;

      case 'users':
        value = value.map((user) => this._user.getReference(user.key));
        break;

      case 'note':
        value = {
          updatedAt: new Date().getTime(),
          value,
        };
        break;
    }
    this._sale.updateSale(this.salesBoardKey, this.sale.key, {
      [field]: value,
    } as Sale);
  }

  compareUsers(user, currentUser) {
    return user.key == currentUser.key;
  }

  sendComment() {
    if (!this.comment) return;
    this._sale.addComment(this.salesBoardKey, this.sale.key, {
      user: this._sale.getReference(`users/${this._user.user.key}`),
      trash: false,
      createdAt: new Date().getTime(),
      comment: this.comment,
    });

    this.comment = '';
    AlertService.toastSuccess('Comentario agregado');
  }

  async deleteSale() {
    if (
      !(await AlertService.confirm(
        '¿Estás seguro de que deseas eliminar esta venta?'
      ))
    )
      return;
    this._sale.delete(this.salesBoardKey, this.sale.key);
    AlertService.toastSuccess('La venta se ha eliminado correctamente');
    this.modalRef.hide();
  }

  async addLink() {
    let [title, link] = await AlertService.withHtml(
      'Nuevo enlace',
      `
        <label>Título</label>
        <input type="text" id="swal-input1" class="swal2-input form-control">
        <label>Enlace</label>
        <input type="text" id="swal-input2" class="swal2-input form-control">`,
      function () {
        return new Promise(function (resolve) {
          resolve([$('#swal-input1').val(), $('#swal-input2').val()]);
        });
      }
    );
    if (!title.trim() || !link.trim()) return;
    this.saleForm.patchValue({
      links: [
        ...this.saleForm.value.links,
        {
          title,
          link,
        },
      ],
    });
    this.update(this.saleForm.value.links, 'links');
  }

  deleteLink(index: number) {
    let links = this.saleForm.value.links.filter((link, i) => i != index);
    this.saleForm.patchValue({links: links});
    this.update(links, 'links');
  }
}
