<ng-container *ngIf="receiptsLogs$ | async as logs">
  <ng-container *ngIf="!!logs.length; else notFound">
    <div class="table-responsive">
      <table class="table table-bordered table-hover">
        <thead class="header">
          <tr>
            <th>Fecha</th>
            <th>Descripción</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let log of logs">
            <td data-label="Fecha">
              {{ log.createdAt | date: 'medium' }}
            </td>
            <td data-label="Descripción">
              {{log.description}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</ng-container>

<ng-template #notFound>
  <app-not-found title="No se encontraron resultados" size="medium"></app-not-found>
</ng-template>