<div class="p-2">
  <h3>Nueva venta</h3>
  <form [formGroup]="saleForm">
    <div class="mt-1">
      <label>Nombre de la oportunidad</label>
      <input
        type="text"
        formControlName="title"
        class="form-control"
        [ngClass]="{'is-invalid': !!submitted && formControls.title.errors}" />
      <small class="invalid-feedback">Campo obligatorio</small>
    </div>

    <div class="mt-1">
      <span>Descripción</span>
      <ckeditor
        formControlName="description"
        [config]="config"
        [editor]="editor"></ckeditor>
    </div>

    <div class="mt-1">
      <label>Fecha de expiración de oferta</label>
      <input type="date" formControlName="closeDate" class="form-control" />
    </div>

    <div class="mt-1">
      <label>Valor de venta</label>
      <input
        type="number"
        formControlName="saleValue"
        class="form-control"
        [ngClass]="{
          'is-invalid': !!submitted && formControls.saleValue.errors
        }" />
      <small class="invalid-feedback">
        <span
          *ngIf="
            !!formControls.saleValue.errors &&
            !!formControls.saleValue.errors.min
          "
          class="fadeIn"
          >El valor debe ser mayor a cero</span
        >
        <span
          *ngIf="
            !!formControls.saleValue.errors &&
            !!formControls.saleValue.errors.required
          "
          class="fadeIn"
          >Campo obligatorio</span
        >
      </small>
    </div>

    <div class="mt-1">
      <label>Margen (%)</label>
      <input
        type="number"
        formControlName="salesMarginPercentage"
        class="form-control"
        [ngClass]="{
          'is-invalid': !!submitted && formControls.salesMarginPercentage.errors
        }" />
      <small class="invalid-feedback">
        <span
          *ngIf="
            !!formControls.salesMarginPercentage.errors &&
            !!formControls.salesMarginPercentage.errors.min
          "
          class="fadeIn"
          >El valor debe ser mayor a cero</span
        >
        <span
          *ngIf="
            !!formControls.salesMarginPercentage.errors &&
            !!formControls.salesMarginPercentage.errors.required
          "
          class="fadeIn"
          >Campo obligatorio</span
        >
      </small>
    </div>

    <div class="mt-1">
      <label>Código de referencia</label>
      <input
        type="text"
        formControlName="referenceCode"
        class="form-control"
        [ngClass]="{
          'is-invalid': !!submitted && formControls.referenceCode.errors
        }" />
      <small class="invalid-feedback">Campo obligatorio</small>
    </div>

    <div class="mt-1 d-flex align-items-center">
      <mat-form-field class="w-50 mr-1">
        <mat-label>Cliente</mat-label>
        <mat-select formControlName="client">
          <mat-option
            *ngFor="let client of clients; let i = index"
            [value]="client">
            {{ client.name }}
          </mat-option>
        </mat-select>
        <mat-error>Campo obligatorio</mat-error>
      </mat-form-field>
      <div
        class="ml-1"
        class="cursor-pointer"
        ngbTooltip="Crear cliente"
        (click)="openAddclientModal()">
        <i class="la la-plus-circle text-dark"></i>
      </div>
    </div>

    <div class="mt-1">
      <mat-form-field class="w-50">
        <mat-label>Vendedores a cargo</mat-label>
        <mat-select
          formControlName="users"
          multiple
          [compareWith]="compareUsers"
          [disabled]="validateUserPermission(userTypeEnum.USER)">
          <mat-select-trigger>
            <ul class="list-unstyled users-list m-0">
              <li
                class="avatar avatar-sm pull-up"
                *ngFor="let user of saleForm.value.users">
                <img
                  class="media-object rounded-circle avatar-img"
                  container="body"
                  [ngbTooltip]="user.name"
                  [src]="
                    !!user.imageUrl
                      ? user.imageUrl
                      : '/assets/images/default.png'
                  "
                  alt="Foto del vendedor a cargo" />
              </li>
            </ul>
          </mat-select-trigger>
          <mat-option *ngFor="let user of users; let i = index" [value]="user">
            <img
              src="{{
                !!user.imageUrl ? user.imageUrl : '/assets/images/default.png'
              }}"
              class="avatar-img rounded-circle"
              alt="Foto de vendedor" /><span class="ml-1">{{ user.name }}</span>
          </mat-option>
        </mat-select>
        <mat-error>Campo obligatorio</mat-error>
      </mat-form-field>
    </div>

    <div class="mt-1">
      <div class="d-flex justify-content-between">
        <span>Enlaces</span>
        <div
          class="ml-1"
          class="cursor-pointer"
          ngbTooltip="Agregar enlace"
          placement="left"
          (click)="addLink()">
          <i class="la la-plus-circle text-dark"></i>
        </div>
      </div>
      <div
        class="mt-1"
        *ngFor="let item of saleForm.value.links; let i = index">
        <div class="d-flex justify-content-between">
          <a [href]="item.link" target="_blank">{{ item.title }}</a>
          <i
            ngbTooltip="Eliminar enlace cursor-pointer"
            (click)="deleteLink(i)"
            class="la la-times text-dark"></i>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-end">
      <button class="btn btn-success mr-1" (click)="submit()">Guardar</button>
      <button class="btn btn-outline-danger" (click)="modalRef.hide()">
        Cerrar
      </button>
    </div>
  </form>
</div>
