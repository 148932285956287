<div #componentID>
  <div class="col-md-12 text-center text-sm-left border">
    <div class="media d-flex align-items-center flex-row">
      <img src="/assets/images/logo.png" class="logoSize" alt="company logo"/>
      <div class="media-body ml-4">
        <ul class="ml-2 px-0 list-unstyled">
          <li class="text-bold-800">Ingeniería y Servicios Colsan Ltda.</li>
          <li>Benito Rebolledo #2470</li>
          <li>San Joaquin, La Serena.</li>
          <li>8:30am – 6:30pm</li>
          <li>+56 51 2 494893</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="d-flex border-2">
    <div class="col-3 border">
      <label class="font-weight-bold">Orden de compra ª</label>
    </div>

    <div class="col-3 border">
      <label class="font-weight-bold">OC: {{purchaseOrder.purchaseID.slice(0, -3)}}</label>
    </div>

    <div class="col-3 border">
      <label class="font-weight-bold">FECHA</label>
    </div>

    <div class="col-3 border">
      <label>{{purchaseOrder.date | date : 'dd/MM/yyyy'}}</label>
    </div>
  </div>

  <div class="d-flex border-2">
    <div class="col-3 border">
      <label class="font-weight-bold">PROVEEDOR</label>
    </div>

    <div class="col-3 border">
      <label>{{(purchaseOrder.provider | doc | async)?.name}}</label>
    </div>

    <div class="col-3 border">
      <label class="font-weight-bold">R.U.T:</label>
    </div>

    <div class="col-3 border">
      <label>{{(purchaseOrder.provider | doc | async)?.rut}}</label>
    </div>
  </div>

  <div class="d-flex border-2">
    <div class="col-3 border">
      <label class="font-weight-bold">GIRO</label>
    </div>

    <div class="col-3 border">
      <label>{{(purchaseOrder.provider | doc | async)?.lineOfBusiness}}</label>
    </div>

    <div class="col-3 border">
      <label class="font-weight-bold">TELÉFONO:</label>
    </div>

    <div class="col-3 border">
      <label>{{(purchaseOrder.provider | doc | async)?.phone }}</label>
    </div>
  </div>

  <div class="d-flex border-2">
    <div class="col-6 border">
      <label class="font-weight-bold">DIRECCIÓN</label>
    </div>

    <div class="col-6 border">
      <label>{{(purchaseOrder.provider | doc | async)?.address}}</label>
    </div>
  </div>

  <div id="invoice-items-details" class="pt-2">
    <div class="row">
      <div class="table-responsive col-sm-12">
        <table class="table line-border border">
          <thead>
          <tr>
            <th class="border">CANTIDAD</th>
            <th class="border">UNIDAD</th>
            <th class="border">DESCRIPCIÓN</th>
            <th class="border">VALOR UNITARIO</th>
            <th class="border">% DESCUENTO</th>
            <th class="border">VALOR TOTAL</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let articles of purchaseOrder.articles; let index = index">
            <td class="border">{{articles?.quantity}}</td>
            <td class="border">{{(articles?.unit | doc | async)?.name}}</td>
            <td class="border">{{articles?.description}}</td>
            <td class="border">{{articles?.unitValue}}</td>
            <td class="border">{{articles?.discount}}</td>
            <td class="border">{{articles?.total}}</td>
          </tr>

          <tr>
            <td class="border"></td>
            <td class="border"></td>
            <td class="border"></td>
            <td class="border"></td>
            <td class="border">SUB TOTAL</td>
            <td class="border">{{purchaseOrder?.subtotal | clearCurrency | swapCommasAndDots}}</td>
          </tr>

          <tr>
            <td class="border"></td>
            <td class="border"></td>
            <td class="border"></td>
            <td class="border"></td>
            <td class="border">DESCUENTO</td>
            <td class="border">{{purchaseOrder?.discount | clearCurrency | swapCommasAndDots}}</td>
          </tr>

          <tr>
            <td class="border">Fecha de presentación OC:</td>
            <td class="border">{{purchaseOrder.date | date : 'dd/MM/yyyy HH:mm'}}</td>
            <td class="border"></td>
            <td class="border"></td>
            <td class="border">NETO</td>
            <td class="border">{{purchaseOrder?.net | clearCurrency | swapCommasAndDots}}</td>
          </tr>

          <tr>
            <td class="border">Fecha de aprobación OC:</td>
            <td class="border">{{purchaseOrder.approvalDate | date : 'dd/MM/yyyy HH:mm'}}</td>
            <td class="border">CONDICIÓN DE PAGO:</td>
            <td class="border">{{purchaseOrder?.paymentType}}</td>
            <td class="border">19% IVA</td>
            <td class="border">{{purchaseOrder.iva | clearCurrency | swapCommasAndDots}}</td>
          </tr>

          <tr>
            <td class="border">Fecha de entrega:</td>
            <td class="border">
              <span>{{purchaseOrder.deliveryDate | date : 'dd/MM/yyyy' : 'UTC'}}</span>
            </td>
            <td class="border">MONEDA Y TIPO DE CAMBIO:</td>
            <td class="border">{{purchaseOrder.currency}}</td>
            <td class="border">TOTAL</td>
            <td class="border">{{purchaseOrder.total | clearCurrency | swapCommasAndDots}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column align-items-center justify-content-center ">
    <img src="{{purchaseOrder.signature}}" class="signature">
    <div class="text-center">
      <p class="mb-0 font-weight-bold">________________________________________________________</p>
      <p class="mb-0 font-weight-bold">FIRMA AUTORIZADA
        POR: {{(purchaseOrder.supervisor | doc | async)?.name | uppercase}}</p>
    </div>
  </div>

  <div *ngIf="!!purchaseOrder.bussinessConditions" class="mt-4">
    <h5>Condiciones comerciales</h5>
    <p>{{purchaseOrder.bussinessConditions}}</p>
  </div>
</div>
