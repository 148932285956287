import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../shared/template-services/auth.service";

@Component({
  selector: 'app-add-whitelist',
  templateUrl: './add-whitelist.component.html',
  styleUrls: ['./add-whitelist.component.css']
})
export class AddWhitelistComponent implements OnInit {

  constructor(private _auth: AuthService) { }

  ngOnInit(): void {
  }

  submit(): void {

  }

}
