import {Component, OnInit} from '@angular/core';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {Observable, Subscription} from 'rxjs';
import {Job} from '../../interfaces/job';
import {JobService} from '../../../shared/services/job.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../shared/template-services/alert.service';
import {AddJobComponent} from '../../modals/add-position/add-job.component';

@Component({
  selector: 'app-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.css']
})
export class PositionsComponent implements OnInit {
  config: DataTableConfig = {
    title: "",
    notFoundText: "No se encontraron posiciones",
    hasSearch: true
  };
  jobs$: Observable<Job[]>;
  jobs: any
  positionsSubscription: Subscription = new Subscription();

  constructor(private _position: JobService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.jobs$ = this._position.getAll();

    this.positionsSubscription = this._position.getAll().subscribe(data => {
      this.jobs = data;
    });
  }

  openUpdatePositionModal(position: any, positionKey: string) {
    this.modal.show(AddJobComponent, {
      initialState: {
        job: position,
        update: true
      }
    });
  }

  async deletePosition(positionKey: string, position: number) {
    if (await AlertService.confirm('¿Estás seguro de que deseas eliminar este cargo?', '')) {
      await this._position.update(this.jobs[position - 1].key, {position: - 1} as Job);
      for (let i = position; i < this.jobs.length; i ++){
        await this._position.update(this.jobs[i].key, {position: this.jobs[i].position - 1} as Job);
      }

      await this._position.delete(positionKey);
      AlertService.toastSuccess('Se eliminó correctamente', '');
    }
  }

  openAddPositionModal() {
    this.modal.show(AddJobComponent, {
      initialState: {
        update: false,
        lastPosition: this.jobs.length
      }
    });
  }

  async raisePosition(position: number, key: string) {
    if (position == 1) return;
    let raiseIndex = this.jobs.findIndex(job => job.position == position - 1);
    await this._position.update(this.jobs[raiseIndex].key, {position: this.jobs[raiseIndex].position + 1} as Job);
    await this._position.update(key, {position: position - 1} as Job);
  }

  async lowerPosition(position: number, key: string) {
    if (this.jobs.length == position) return;
    let raiseIndex = this.jobs.findIndex(job => job.position == position + 1);
    await this._position.update(this.jobs[raiseIndex].key, {position: this.jobs[raiseIndex].position - 1} as Job);
    await this._position.update(key, {position: position + 1} as Job);
  }

  async changeToInvisible(position: number, key: string, name: string) {
    await this._position.update(key, {isVisible: false} as Job);
    await AlertService.toastSuccess(`Ahora la posición ${name} no será visible`)
  }

  async changeToVisible(position: number, key: string, name: string) {
    await this._position.update(key, {isVisible: true} as Job);
    await AlertService.toastSuccess(`Ahora la posición ${name} es visible`)
  }
}
