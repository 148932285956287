<app-datatable [rows$]="categoriesFiltered$"
               [config]="config"
               (rowSelected)="selectCategory($event)">
  <app-datatable-column title="Nombre" field="name">
    <ng-template let-row #column>
      {{ row.name }}
    </ng-template>
  </app-datatable-column>
  <app-datatable-column title="" field="name" [isClickable]="false">
    <ng-template let-row #column>
      <button class="btn btn-facebook btn-sm" ngbTooltip="Editar categoría" (click)="openUpdateCategoryModal(row.key)">
        <i class="feather ft-edit"></i>
      </button>

      <button class="btn btn-primary btn-sm margin-left-button-status" ngbTooltip="Eliminar categoría" (click)="delete(row.key)">
        <i class="feather ft-x"></i>
      </button>

      <button *ngIf="row.subCategory == undefined" class="btn btn-warning btn-sm margin-left-button-status" ngbTooltip="Pasar a una sub categoría" (click)="addToSubCategory(row.key)">
        <i class="feather ft-arrow-right"></i>
      </button>
    </ng-template>
  </app-datatable-column>
</app-datatable>

<ng-container *ngIf="selectedCategory">
  <app-datatable [rows$]="subCategoriesFiltered$"
                 [config]="configFilter"
                 (rowSelected)="openUpdateSubCategoryModal($event)">
    <app-datatable-column title="Nombre" field="name">
      <ng-template let-row #column>
        {{ row.name }}
      </ng-template>
    </app-datatable-column>
    <app-datatable-column title="" field="name" [isClickable]="false">
      <ng-template let-row #column>
        <button class="btn btn-primary" (click)="delete(row.key)">Eliminar</button>
      </ng-template>
    </app-datatable-column>

  </app-datatable>
</ng-container>

<app-add-float-button (clickEmmiter)="openAddCategoryModal()"></app-add-float-button>

<button class="btn btn-warning btn-float-right add-subCategory-btn"
        app-tooltip="Agregar sub categoría"
        (click)="openAddSubCategoryModal()"
        *ngIf="selectedCategory">
  <i class="feather ft-plus"></i>
</button>
