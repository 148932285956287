import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../../../shared/template-services/alert.service';

@Component({
  selector: 'app-serials-numbers',
  templateUrl: './serials-numbers.component.html',
  styleUrls: ['./serials-numbers.component.css']
})
export class SerialsNumbersComponent implements OnInit {
  article: any;

  constructor(public modal: BsModalRef) {
  }

  ngOnInit(): void {
    if (!('serialNumber' in this.article)) this.article['serialNumber'] = [];
  }

  async removeSerial(index: number) {
    if (await AlertService.confirm('Estás seguro de que quieres eliminar este numero de serie?')) {
      this.article.serialNumber.splice(index, 1);
      AlertService.toastSuccess('Se ha removido exitosamente');
    }
  }

  async updateSerial(index: number) {
    if (await AlertService.confirm('Estás seguro de que quieres actualizar este número de serie?')) {
      const resp = await AlertService.input('Ingresa el nuevo número de serie');
      if (!resp) return;

      this.article.serialNumber[index] = resp;
      AlertService.toastSuccess('Se ha actualizado exitosamente');
    }
  }

  async addSerials() {
    const {quantity} = this.article;
    if (!!quantity && +quantity > 0) {
      if (this.article.serialNumber.length < this.article.quantity) await this.addSerialNumber();
    }
  }

  async addSerialNumber() {
    const {quantity, serialNumber} = this.article;

    const times = quantity - serialNumber.length;
    for (let i = 0; i < times; i++) {
      const serialNumber = await AlertService.inputValidator(`Número de serie ${this.article.serialNumber.length + 1} de ${quantity}`);
      if (!!serialNumber) this.article.serialNumber.push(serialNumber);
    }
  }

  completeAllSerials() {
    AlertService.toastSuccess('Se han agregado todos los números seriales correctamente');
    this.modal.hide();
  }
}
