import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap/modal";
import {CategoryService} from "../../../shared/services/category.service";
import {Category} from "../../../shared/interfaces/category";

@Component({
  selector: 'app-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {
  categoryForm: FormGroup;
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private _category: CategoryService,
              public modal: BsModalRef) {
  }

  private createForm() {
    this.categoryForm = this.formBuilder.group({
      name: ['', Validators.required],
      trash: [false],
    })
  }

  ngOnInit(): void {
    this.createForm();
  }

  get formControls() {
    return this.categoryForm.controls;
  }

  submit() {
    this.submitted = true;
    if (this.categoryForm.valid) {
      this._category.add(this.categoryForm.value as Category);
      this.modal.hide();
    }
  }
}
