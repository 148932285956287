import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NotificationType} from '../../enums/notification-type.enum';
import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.css']
})
export class SendNotificationComponent implements OnInit, OnDestroy {
  notificationForm: FormGroup;
  submitted: boolean = false;
  users$: any;
  users = [];

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _notification: NotificationService) {
    this.notificationForm = formBuilder.group({
      users: [[], Validators.required],
      message: ['', Validators.required],
    });
  }

  get formControls() {
    return this.notificationForm.controls;
  }

  ngOnInit(): void {
    this.users$.subscribe(data => {
      this.users = data;
    })
  }

  ngOnDestroy() {
    this.users$.unsubscribe;
  }

  submit() {
    this.submitted = true;
    if (this.notificationForm.valid) {

      for (let user of this.notificationForm.value.users) {
        this._notification.setUser(user.key, {
          redirectUrl: null,
          description: `${this.notificationForm.value.message}`,
          createdDate: new Date().getTime(),
          trash: false,
          readed: false,
          type: NotificationType.USER_MESSAGE
        });
      }

      this.modal.hide();
    }
  }
}
