import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit, OnChanges {
  @Input() rowsPerPage: number;
  @Input() totalRows: number;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  pages: number[] = [];
  currentPage: number = 1;
  pagesToShow: number = 10;
  totalPages: number;

  constructor() {
  }

  ngOnInit(): void {
    this.calculatePages();
  }

  ngOnChanges(): void {
    this.calculatePages();
    this.pageChange.emit(1);
  }

  calculatePages() {
    this.pages = [];
    this.totalPages = Math.ceil(this.totalRows / this.rowsPerPage);
    this.drawPages();
    this.currentPage = 1;
  }

  drawPages() {
    for (let i = 1; i <= this.totalPages; i++) {
      if (i > this.pagesToShow) {
        this.pages.push(-1);
        continue;
      }
      this.pages.push(i);
    }
  }

  handlePageChange(page: number) {
    if (page == -1) return;

    this.currentPage = page;
    this.pageChange.emit(page);
    this.setScrollTop();
    this.reDrawPages();
  }

  reDrawPages() {
    this.pages = this.pages.map((page, index) => {
      const position = index + 1;

      if ((position + (this.pagesToShow / 2)) >= this.currentPage && position < this.currentPage + (this.pagesToShow / 2)) return position;

      return -1;
    });
  }

  setScrollTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
}
