import {Component, Input, OnInit} from '@angular/core';
import {Warehouse} from '../../../interfaces/warehouse';
import {Article} from '../../../interfaces/article';
import {TicketAuthorization} from '../../../interfaces/ticket-authorization';
import {Observable, Subscription} from 'rxjs';
import {TicketEntryStatus} from '../../../enums/ticket-entry-status.enum';
import {DataTableConfig} from '../../../../../../shared/interfaces/data-table-config';
import {UserType} from '../../../../../enums/user-type.enum';
import {WarehouseService} from '../../../../../../shared/services/warehouse.service';
import {ActivatedRoute} from '@angular/router';
import {ProjectService} from '../../../../../../shared/services/project.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {UserService} from '../../../../../../shared/services/user.service';
import {WarehouseDetailService} from '../../../services/warehouse-detail.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {map, take} from 'rxjs/operators';
import {ObjectService} from '../../../../../../shared/template-services/object.service';
import {TicketEntryService} from '../../../services/ticket-entry.service';
import {TicketEntryDetailsComponent} from '../../../modals/ticket-entry-details/ticket-entry-details.component';
import {TicketEntryStatusLabel} from '../../../labels/ticket-entry-status.label';
import {TicketEntry} from '../../../interfaces/ticket-entry';
import {Permission} from '../../../../../enums/permissions.enum';
import {PermissionService} from '../../../services/permission.service';

@Component({
  selector: 'app-tickets-article-entry',
  templateUrl: './tickets-article-entry.component.html',
  styleUrls: ['./tickets-article-entry.component.css'],
})
export class TicketsArticleEntryComponent implements OnInit {
  @Input() warehouse: Warehouse;
  @Input() articleReferences: Article[];
  @Input() ticketAuthorizationReferences: TicketAuthorization[] = [];
  @Input() requestsReference: TicketAuthorization[] = [];
  @Input() tickets$: Observable<TicketEntry[]>;
  @Input() ticketsFiltered$: Observable<TicketEntry[]> = new Observable<TicketEntry[]>();
  @Input() isAll: boolean = false;
  @Input() isProject: boolean = false;
  @Input() projectKey: string;
  @Input() statusSelected: any;
  @Input() unknownWarehouse: boolean = false;
  @Input() ticket: any;
  @Input() submitExcel: boolean = false;
  EntryTicketsStatus = TicketEntryStatus;
  ticketsSubscription: Subscription = new Subscription();
  warehouseReference: any;
  config: DataTableConfig = {
    title: '',
    notFoundText: 'No se encontraron tickets',
    hasSearch: true,
  };
  EntryTicketsStatusLabel = TicketEntryStatusLabel;
  myRadio: number;
  permission: UserType;
  warehouses: Warehouse[] = [];
  usersIndexed: any = {};
  projectsIndexed: any = {};
  private usersSubscription: Subscription = new Subscription();
  private projectsSubscription: Subscription = new Subscription();
  messageLoad: string;

  constructor(private _ticket: TicketEntryService,
              private _warehouse: WarehouseService,
              private activatedRoute: ActivatedRoute,
              private _project: ProjectService,
              private modal: BsModalService,
              private _user: UserService,
              private _warehouseDetail: WarehouseDetailService,
              private SpinnerService: NgxSpinnerService,
              private _permission: PermissionService) {
  }

  async ngOnInit() {
    this.messageLoad = 'Cargando...';
    this.SpinnerService.show();

    await this.loadUsers();
    await this.loadProjects();

    if (!this.warehouse) {
      this.warehouse = this._warehouseDetail.warehouse;
      this.articleReferences = this._warehouseDetail.articleReferences;
      this.ticketAuthorizationReferences =
        this._warehouseDetail.ticketAuthorizationReferences;
      this.requestsReference = this._warehouseDetail.requestsReference;
      this.ticket = this._warehouseDetail.ticket;
    }

    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'BODEGAS'
    ).permission;

    if (this.permission != UserType.BUYER && this._permission.hasUserType(Permission.BUYER as any)) {
      this.permission = UserType.BUYER;
    }

    if (this.isProject) {
      await this.loadTickets();
    }

    if (!this.isAll && !this.isProject) {
      await this.getWarehouseReference();
      await this.loadTickets();
    }

    if (this.isAll) {
      this.filterTickets();
    }

    const ticketKey = this.activatedRoute.snapshot.params['ticketKey'];
    if (!!ticketKey) {
      const ticket = await this._ticket
        .get(ticketKey)
        .pipe(take(1))
        .toPromise();
      this.openTicketDetail(ticket);
    }

    if (this.permission == UserType.GROCER) {
      this.myRadio = TicketEntryStatus.AUTHORIZED;
      this.changeEntryTicketsStatus();
    }

    this.SpinnerService.hide();
  }

  ngOnDestroy() {
    this.ticketsSubscription.unsubscribe();
    this.usersSubscription.unsubscribe();
    this.projectsSubscription.unsubscribe();
  }

  async loadTickets() {
    this.tickets$ = null;
    if (this.isProject) {
      return (this.ticketsFiltered$ = this._ticket.getAllByProject(
        this._project.getReference(this.projectKey)
      ));
    }

    this.tickets$ = this._ticket.getAll(this.warehouseReference).pipe(
      map((tickets) => {
        return tickets.sort(this.compareFn);
      })
    );
    this.ticketsFiltered$ = this.tickets$;
  }

  compareFn(a, b) {
    if (parseInt(a.ticketID) < parseInt(b.ticketID)) {
      return 1;
    }
    if (parseInt(a.ticketID) > parseInt(b.ticketID)) {
      return -1;
    }
    return 0;
  }

  getWarehouseReference() {
    this.warehouseReference = this._warehouse.getReference(this.warehouse.key);
  }

  openTicketDetail(ticket: any) {
    this.modal.show(TicketEntryDetailsComponent, {
      initialState: {
        ticket: {...ticket},
        warehouse: this.warehouse,
        articleReferences: this.articleReferences,
        unknownWarehouse: this.unknownWarehouse,
      },
      class: 'modal-xl',
      backdrop: 'static',
    });
  }

  changeEntryTicketsStatus() {
    if (this.myRadio == 99) return (this.ticketsFiltered$ = this.tickets$);

    switch (this.myRadio) {
      case TicketEntryStatus.DRAFT:
        this.statusSelected = {
          name: 'BORRADORES',
          status: TicketEntryStatus.DRAFT,
        };
        break;

      case TicketEntryStatus.GENERATED:
        this.statusSelected = {
          name: 'GENERADOS',
          status: TicketEntryStatus.GENERATED,
        };
        break;

      case TicketEntryStatus.AUTHORIZED:
        this.statusSelected = {
          name: 'AUTORIZADOS',
          status: TicketEntryStatus.AUTHORIZED,
        };
        break;

      case TicketEntryStatus.ADDED:
        this.statusSelected = {
          name: 'INGRESADOS',
          status: TicketEntryStatus.ADDED,
        };
        break;
    }

    this.filterTickets();
  }

  getStatusClass(status: number) {
    switch (status) {
      case TicketEntryStatus.DRAFT:
        return 'btn-outline-primary';

      case TicketEntryStatus.GENERATED:
        return 'btn-outline-primary';

      case TicketEntryStatus.AUTHORIZED:
        return 'btn-outline-info';

      case TicketEntryStatus.CANCELLED:
        return 'btn-outline-dark';

      case TicketEntryStatus.ADDED:
        return 'btn-outline-info';

      default:
        return 'btn-outline-success';
    }
  }

  filterTickets() {
    this.config.notFoundText = `No se encontraron tickets de entrada ${this.statusSelected.name}`;
    this.ticketsFiltered$ = this.tickets$.pipe(
      map((tickets) =>
        tickets.filter((ticket) => {
          return ticket.status == this.statusSelected.status;
        })
      )
    );
  }

  private async loadUsers() {
    this.usersSubscription = this._user.getAllByColsan().subscribe((users) => {
      this.usersIndexed = ObjectService.indexArray(users, 'key');
    });
  }

  private async loadProjects() {
    this.projectsSubscription = this._project.getAll().subscribe((projects) => {
      this.projectsIndexed = ObjectService.indexArray(projects, 'key');
    });
  }
}
