import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../shared/interfaces/user';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit, OnChanges {
  @Input() user: User;
  @Input() generalData: any;
  documentsForm: FormGroup;
  @Output() eventDocuments: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder) {
    this.documentsForm = formBuilder.group({
      indentificationCardFront: ['', Validators.required],
      indentificationCardBack: ['', Validators.required],
      backgroundPaper: ['', Validators.required],
      birthCertificate: ['', Validators.required],
      afpAffiliationCertificate: [''],
      healthAffiliationCertificate: [''],
      driverLicensePhoto: [null],
      driverResume: [null],
      titlePhoto: [''],
      finiquitoUrl: [''],
      imageUrl: [''],
    });
  }

  ngOnInit(): void {
    this.documentsForm.valueChanges.subscribe(() => {
      this.eventDocuments.emit(this.documentsForm.value);
    });
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    this.generalData = changes['generalData'];

    if (changes.user && changes.user.currentValue) {
      this.documentsForm.patchValue(changes.user.currentValue);
    }
  }

  get formControls() {
    return this.documentsForm.controls;
  }
}
