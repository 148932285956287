<div class="p-4">
  <h4>Seleccione {{quantity}} números de serie a retirar del artículo {{article.name}}</h4>
  <div class="form-group">
    <ng-select [items]="serialNumbersAvailable"
               [multiple]="true"
               [(ngModel)]="serialNumbersSelected"
               notFoundText="">
    </ng-select>
  </div>

  <button class="btn btn-primary btn-lg mt-3" (click)="finish()">Finalizar</button>
</div>
