import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {Quiz} from '../../admin/interfaces/quiz';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class QuizService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  getAll(): Observable<Quiz[]> {
    return this.db.colWithIds$<Quiz>('quiz', ref => ref
      .where('trash', '==', false));
  }

  update(quizKey: string, quiz: Quiz): Promise<void> {
    return this.afs.doc<Quiz>(`quiz/${quizKey}`).update(quiz);
  }

  delete(quizKey: string): Promise<void> {
    return this.afs.doc<Quiz>(`quiz/${quizKey}`).update({trash: true});
  }

  add(quiz: Quiz) {
    this.afs.collection('quiz').add(quiz);
  }

  getReference(quizKey: string): DocumentReference {
    return this.afs.doc(`quiz/${quizKey}`).ref;
  }
}
