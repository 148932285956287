import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subscription} from 'rxjs';
import {PurchaseOrder} from '../purchase-orders/interfaces/purchase-order';
import {PurchaseOrderService} from '../../../shared/services/purchase-order.service';
import {WorkerSalariesService} from '../../services/worker-salaries.service';
import {WorkerSalaries} from '../../interfaces/worker-salaries';
import {PurchaseStatus} from '../../enums/purchase-status.enum';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {PurchaseOrderModalComponent} from '../purchase-orders/modals/purchase-order-modal/purchase-order-modal.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {Provider} from '../../interfaces/provider';
import {ProviderService} from '../../../shared/services/provider.service';
import {PurchaseStatusLabel} from '../../labels/purchase-status.label';

@Component({
  selector: 'app-total-expenses',
  templateUrl: './total-expenses.component.html',
  styleUrls: ['./total-expenses.component.css']
})
export class TotalExpensesComponent implements OnInit, OnDestroy {
  purchaseOrdersSubscription: Subscription = new Subscription();
  purchaseOrders: PurchaseOrder[];
  workerSalariesSubscription: Subscription = new Subscription();
  workersSalaries: WorkerSalaries[];
  totalExpensesForTheWeek: number;
  pendingExpenses: number;
  hitRateOptions = {
    bodyClass: ['bg-hexagons', 'pt-0'],
    headerClass: ['bg-hexagons'],
    cardClass: [''],
    close: false,
    expand: false,
    minimize: true
  };
  total: number;
  purchases$: any = [];
  config: DataTableConfig = {hasSearch: true, notFoundText: 'No se encontraron costos vencidos', title: ''};
  providersSubscription: Subscription;
  providers: Provider[];
  purchaseStatusLabel = PurchaseStatusLabel;
  overdueCostsLoad: boolean = false;

  constructor(private _purchase: PurchaseOrderService,
              private _workerSalaries: WorkerSalariesService,
              private modal: BsModalService,
              private _provider: ProviderService) {
  }

  ngOnInit(): void {
    this.getPurchases();
    this.getProviders();
  }

  ngOnDestroy() {
    this.purchaseOrdersSubscription.unsubscribe();
    this.workerSalariesSubscription.unsubscribe();
    this.providersSubscription.unsubscribe();
  }

  getProviders() {
    this.providersSubscription = this._provider.getAll()
      .subscribe(data => {
        this.providers = data;
      });
  }

  getPurchases() {
    this.purchaseOrdersSubscription = this._purchase.getAll().subscribe(data => {
      this.purchaseOrders = data.filter(purchase => purchase.status >= PurchaseStatus.AUTHORIZED);
      this.getWorkersSalaries();
    });
  }

  private getWorkersSalaries() {
    this.workerSalariesSubscription = this._workerSalaries.getAll().subscribe(data => {
      this.workersSalaries = data;
      this.getTotals();
    });
  }

  getTotals() {
    this.totalExpensesForTheWeek = 0;
    this.pendingExpenses = 0;
    this.total = 0;

    let curr = new Date; // get current date
    let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    let last = first + 6; // last day is the first day + 6

    let firstDay: any = new Date(curr.setDate(first)).toUTCString();
    let lastDay: any = new Date(curr.setDate(last)).toUTCString();
    lastDay = new Date(lastDay);
    lastDay = lastDay.setMonth(lastDay.getMonth() + 1)

    let hours = new Date().getHours() * 3600000;

    firstDay = new Date(firstDay).getTime() - hours;
    lastDay = new Date(lastDay).getTime();

    for (let purchaseOrders of this.purchaseOrders) {
      if (purchaseOrders.approvalDate >= firstDay && purchaseOrders.approvalDate <= lastDay && purchaseOrders.invoiced) this.totalExpensesForTheWeek = this.totalExpensesForTheWeek + purchaseOrders.total;
      if (!purchaseOrders.invoiced) this.pendingExpenses = this.pendingExpenses + purchaseOrders.total;
      this.total = this.total + purchaseOrders.total;

      if (purchaseOrders.approvalDate <= firstDay && !purchaseOrders.invoiced) this.purchases$.push(purchaseOrders);
    }

    this.purchases$ = of(this.purchases$);
    this.overdueCostsLoad = true;

    for (let salaries of this.workersSalaries) {
      this.total = this.total + salaries.lawExpenses + salaries.workerSalary;
      this.totalExpensesForTheWeek = this.totalExpensesForTheWeek + salaries.lawExpenses + salaries.workerSalary;
    }
  }

  gotoOpenEditPurchaseOrder(purchaseOrder) {
    this.modal.show(PurchaseOrderModalComponent, {
      initialState: {
        purchaseOrder: {...purchaseOrder}
      },
      class: 'modal-xxl',
      backdrop: 'static'
    });
  }

  getProvider(providerReference: any): any {
    let providerIndex = this.providers.findIndex(provider => provider.key == providerReference.id);
    if (providerIndex == -1) {
      return '';
    }
    return this.providers[providerIndex];
  }

  getStatusClass(status: number) {
    switch (status) {
      case PurchaseStatus.DRAFT:
        return 'badge-dark';

      case PurchaseStatus.GENERATED:
        return 'badge-primary';

      case PurchaseStatus.AUTHORIZED:
        return 'badge-info';

      case PurchaseStatus.NOT_AUTHORIZED:
        return 'badge-warning';

      case PurchaseStatus.FINALIZED:
        return 'badge-success';

      default:
        return 'badge-success';
    }
  }
}
