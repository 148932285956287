import { Component, OnInit } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { Applicant } from '../../interfaces/applicant';
import { ApplicantService } from '../../../shared/services/applicant.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableConfig } from '../../../shared/interfaces/data-table-config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, take } from 'rxjs/operators';
import { StatusService } from '../../../shared/services/status.service';
import { JobService } from '../../../shared/services/job.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { AlertService } from '../../../shared/template-services/alert.service';
import { id } from '@swimlane/ngx-charts';
import { ProjectService } from '../../../shared/services/project.service';
import { Project } from '../../interfaces/project';
import * as moment from 'moment';
import { UserService } from 'src/app/shared/services/user.service';
import { UserType } from '../../enums/user-type.enum';
import { Cities } from '../../../../assets/json/cities';

@Component({
  selector: 'app-applicants',
  templateUrl: './applicants.component.html',
  styleUrls: ['./applicants.component.css']
})
export class ApplicantsComponent implements OnInit {
  filterForm: FormGroup;
  applicants: Applicant[];
  applicantsNoRepeat: Applicant[];
  duplicatedApplicants: Applicant[];
  projects: Project[];
  applicantsFiltered$: Observable<Applicant[]>;
  applicantsFiltered: Applicant[] = [];
  applicantsConfig: DataTableConfig = {
    title: 'Postulantes registrados',
    notFoundText: 'No se encontraron postulantes',
    hasSearch: true,
    removeInfiniteScroll: true,
    pagination: true,
    paginationLimit: 25,
    filtersFields: ['fullname'.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''), 'fullname', 'name', 'surnames', 'rut']
  };
  statusSubscription: Subscription = new Subscription();
  projectSubscription: Subscription = new Subscription();
  selectArrayStatus: any = [];
  selectArrayProjects: any = [];
  positionSubscription: Subscription = new Subscription();
  selectArrayPosition: any = [];
  selectMultipleArray: any = [];
  selectedMultipleArray: any = [];
  status: any = { name: '' };
  position: any = { name: '' };
  city: any = { name: '' };
  minExpectation: number;
  maxExpectation: number;
  minYears: number = null;
  maxYears: number = null;
  cities = [];
  applicantCities = [];
  noRepeat = [];
  userPermission;
  userTypeEnum = UserType;

  constructor(private _applicant: ApplicantService,
              private formBuilder: FormBuilder,
              private router: Router,
              private _status: StatusService,
              private _position: JobService,
              private activatedRoute: ActivatedRoute,
              private _project: ProjectService,
              private _user: UserService) {
    this.filterForm = this.formBuilder.group({
      status: ['', Validators.required],
      position: ['', Validators.required],
      city: ['', Validators.required]
    });
  }

  async ngOnInit() {
    this.userPermission = this._user.user.permissions.find(permission => permission.section == 'POSTULANTES').permission;
    await this.asignFullname();
    this.cities = Cities;

    await this.getStatus();

    if (window.location.pathname.includes('revision-applicants') || window.location.pathname.includes('contratado-applicants') || window.location.pathname.includes('calificado-applicants') || window.location.pathname.includes('postulante-applicants') || window.location.pathname.includes('apto-applicants')) {
      await this.getProjects();
    }

    await this.getPositions();
    await this.getApplicants().then(async () => {
      let status = this.activatedRoute.snapshot.params['status'];

      if (status == 'revision') {
        status = 'revisión';
      }

      if (status) {
        this.selectedMultipleArray.push({ name: status, type: 'Estatus' });
      }

      this.getLocalStorageItems();

      await this.getApplicants();
      await this.getApplicantsFilter();

      for (const applicant of this.applicants) {
        applicant.fullname = applicant.fullname.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '');
      }
    });

    this.activatedRoute.params.subscribe(async params => {
      let status = params['status'];

      if (status == 'revision') {
        status = 'revisión';
      }

      if (status) {
        this.selectedMultipleArray.push({ name: status, type: 'Estatus' });
      }

      await this.getApplicants();
      await this.getApplicantsFilter();
    });

    this.applicantsNoRepeat = await this._applicant.getAll().pipe(take(1)).toPromise();
    this.duplicatedApplicants = await this._applicant.getAll().pipe(take(1)).toPromise();

    this.applicantsNoRepeat = this.applicantsNoRepeat.reduce((accumulator, current) => {
      if (!accumulator.some(item => (item.rut == current.rut))) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);

    for (var i = this.duplicatedApplicants.length - 1; i >= 0; i--) {
      for (var j = 0; j < this.applicantsNoRepeat.length; j++) {
        if (this.duplicatedApplicants[i] && (this.duplicatedApplicants[i].name == this.applicantsNoRepeat[j].name)) {
          this.duplicatedApplicants.splice(i, 1);
        }
      }
    }

    if (this.duplicatedApplicants.length > 0) {
      for (const applicant of this.duplicatedApplicants) {
        this._applicant.update(applicant.key, { duplicated: true } as Applicant);
      }
    }

    if (this.duplicatedApplicants.length == 0) {
      for (const applicant of this.duplicatedApplicants) {
        if (!!applicant.duplicated) {
          this._applicant.update(applicant.key, { duplicated: false } as Applicant);
        }
      }
    }
  }

  async removeDuplicatedApplicants() {
    if (this.duplicatedApplicants.length == 0) {
      AlertService.toastError('No hay postulantes duplicados');
      return;
    }
    if (await AlertService.confirm(`¿Estás seguro que deseas eliminar los postulantes duplicados?`)) {
      for (let applicant of this.duplicatedApplicants) {
        await this._applicant.update(applicant.key, { trash: true } as Applicant);
      }
      AlertService.toastSuccess('Se borraron los duplicados');
    }
  }

  async deleteDuplicatedApplicant(applicant: Applicant) {
    if (await AlertService.confirm(`¿Estás seguro que deseas eliminar este postulante duplicado?`)) {
      this._applicant.update(applicant.key, { trash: true } as Applicant);
      AlertService.toastSuccess('Se eliminó el postulante duplicado');
    }
  }

  asignFullname() {
    this._applicant.getAll().subscribe(applicants => {
      applicants.forEach(applicant => {
        if (!applicant.fullname && applicant.name && applicant.surnames) {
          applicant.fullname = (applicant.name + ' ' + applicant.surnames).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '');
          this._applicant.update(applicant.key, { fullname: `${applicant.fullname}` } as Applicant);
        }
        if (!applicant.fullname && applicant.name && !applicant.surnames) {
          applicant.fullname = (applicant.name).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '');
          this._applicant.update(applicant.key, { fullname: `${applicant.fullname}` } as Applicant);
        }
      });
    });
  }

  async getApplicants() {
    this.applicantsFiltered$ = this._applicant.getAll().pipe(
      map(applicants => _.orderBy(applicants, ['createdAt'], ['desc']))
    );
  }

  async onlyRegisteredCities() {
    this.applicants = await this._applicant.getAll().pipe(take(1)).toPromise();
    this.applicantCities = this.applicants.filter(aplicants => aplicants.city.name);

    for (let applicant of this.applicantCities) {
      this.noRepeat.push({ name: applicant.city.name });
    }

    this.noRepeat = this.noRepeat.reduce((accumulator, current) => {
      if (!accumulator.some(item => item.name == current.name)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
  }

  getLocalStorageItems() {
    let storagedSelectFilter = localStorage.getItem('selectFilter');
    let storagedMinExpectation = localStorage.getItem('minExpectation');
    let storagedMaxExpectation = localStorage.getItem('maxExpectation');
    let storagedMinYears = localStorage.getItem('minYears');
    let storagedMaxYears = localStorage.getItem('maxYears');

    if (!!storagedSelectFilter) {
      this.selectedMultipleArray = JSON.parse(storagedSelectFilter);
      localStorage.removeItem('selectFilter');
    }
    if (!!storagedMinExpectation) {
      this.minExpectation = JSON.parse(storagedMinExpectation);
      localStorage.removeItem('minExpectation');
    }
    if (!!storagedMaxExpectation) {
      this.maxExpectation = JSON.parse(storagedMaxExpectation);
      localStorage.removeItem('maxExpectation');
    }
    if (!!storagedMinYears) {
      this.minYears = JSON.parse(storagedMinYears);
      localStorage.removeItem('minYears');
    }
    if (!!storagedMaxYears) {
      this.maxYears = JSON.parse(storagedMaxYears);
      localStorage.removeItem('maxYears');
    }
  }

  async getApplicantsFilter() {
    this.selectedMultipleArray = this.deleteDuplicateElements(this.selectedMultipleArray);
    let arrayStatusSelected = this.selectedMultipleArray.filter(item => item.type == 'Estatus').map(status => status.name);
    let arrayPositionsSelected = this.selectedMultipleArray.filter(item => item.type == 'Cargo').map(position => position.key);
    let arrayPositionsSelectedWithName = this.selectedMultipleArray.filter(item => item.type == 'Cargo').map(position => position.name);
    let arrayProjectsSelected = this.selectedMultipleArray.filter(item => item.type == 'Proyecto').map(project => project.key);
    let arrayCitiesSelected = this.selectedMultipleArray.filter(item => item.type == 'Ciudad').map(city => city.name);

    if (!arrayStatusSelected[0] && !arrayPositionsSelected[0] && !arrayCitiesSelected[0] && !arrayProjectsSelected[0] && !this.minYears && !this.maxYears && !this.maxExpectation && !this.minExpectation) {
      this.getApplicants();
      this.applicantsFiltered = await this.applicantsFiltered$.pipe(take(1)).toPromise();
      return;
    }

    this.applicants = await this._applicant.getAll().pipe(take(1)).toPromise();

    const infiniteExpectation = 9999999999999999999999999999999999999999999999;
    if (!this.minExpectation) {
      this.minExpectation = 0;
    }
    if (!this.maxExpectation) {
      this.maxExpectation = infiniteExpectation;
    }

    let arrayFilter = [];

    const applicantsFilter = this.applicants.filter(applicant => arrayStatusSelected.includes(applicant.status.name));

    const projectFilter = (this.applicants.filter(applicant => {
      if (!!applicant.project) {
        return arrayProjectsSelected.includes(applicant.project.id);
      }

      return false;
    }));

    const positionFilter = (this.applicants.filter(applicant => {
      if (applicant.position.reference) {
        return arrayPositionsSelected.includes(applicant.position.reference.id);
      }

      return arrayPositionsSelectedWithName.includes(applicant.position.name);
    }));

    const citiesFilter = (this.applicants.filter(applicant => arrayCitiesSelected.includes(applicant.city.name)));

    if (arrayStatusSelected.length > 0 && arrayPositionsSelected.length == 0 && arrayCitiesSelected.length == 0 && arrayProjectsSelected.length == 0) {
      arrayFilter = applicantsFilter;
    }

    if (arrayStatusSelected.length == 0 && arrayPositionsSelected.length > 0 && arrayCitiesSelected.length == 0 && arrayProjectsSelected.length == 0) {
      arrayFilter = positionFilter;
    }

    if (arrayStatusSelected.length == 0 && arrayPositionsSelected.length == 0 && arrayCitiesSelected.length > 0 && arrayProjectsSelected.length == 0) {
      arrayFilter = citiesFilter;
    }

    if (arrayStatusSelected.length == 0 && arrayPositionsSelected.length == 0 && arrayCitiesSelected.length == 0 && arrayProjectsSelected.length > 0) {
      arrayFilter = projectFilter;
    }

    if (arrayStatusSelected.length > 0 && arrayPositionsSelected.length > 0 && arrayCitiesSelected.length > 0 && arrayProjectsSelected.length > 0) {
      arrayFilter = _.intersectionBy(applicantsFilter, positionFilter, projectFilter, citiesFilter, 'key');
    }

    if (arrayStatusSelected.length > 0 && arrayPositionsSelected.length > 0 && arrayCitiesSelected.length > 0 && arrayProjectsSelected.length == 0) {
      arrayFilter = _.intersectionBy(applicantsFilter, positionFilter, citiesFilter, 'key');
    }

    if (arrayStatusSelected.length > 0 && arrayPositionsSelected.length > 0 && arrayCitiesSelected.length == 0 && arrayProjectsSelected.length > 0) {
      arrayFilter = _.intersectionBy(applicantsFilter, positionFilter, projectFilter, 'key');
    }

    if (arrayStatusSelected.length > 0 && arrayPositionsSelected.length == 0 && arrayCitiesSelected.length > 0 && arrayProjectsSelected.length > 0) {
      arrayFilter = _.intersectionBy(applicantsFilter, citiesFilter, projectFilter, 'key');
    }

    if (arrayStatusSelected.length == 0 && arrayPositionsSelected.length > 0 && arrayCitiesSelected.length > 0 && arrayProjectsSelected.length > 0) {
      arrayFilter = _.intersectionBy(positionFilter, citiesFilter, projectFilter, 'key');
    }

    if (arrayStatusSelected.length > 0 && arrayPositionsSelected.length > 0 && arrayCitiesSelected.length == 0 && arrayProjectsSelected.length == 0) {
      arrayFilter = _.intersectionBy(applicantsFilter, positionFilter, 'key');
    }

    if (arrayStatusSelected.length > 0 && arrayPositionsSelected.length == 0 && arrayCitiesSelected.length > 0 && arrayProjectsSelected.length == 0) {
      arrayFilter = _.intersectionBy(applicantsFilter, citiesFilter, 'key');
    }

    if (arrayStatusSelected.length == 0 && arrayPositionsSelected.length > 0 && arrayCitiesSelected.length > 0 && arrayProjectsSelected.length == 0) {
      arrayFilter = _.intersectionBy(positionFilter, citiesFilter, 'key');
    }

    if (arrayStatusSelected.length == 0 && arrayPositionsSelected.length > 0 && arrayCitiesSelected.length == 0 && arrayProjectsSelected.length > 0) {
      arrayFilter = _.intersectionBy(positionFilter, projectFilter, 'key');
    }

    if (arrayStatusSelected.length > 0 && arrayPositionsSelected.length == 0 && arrayCitiesSelected.length == 0 && arrayProjectsSelected.length > 0) {
      arrayFilter = _.intersectionBy(applicantsFilter, projectFilter, 'key');
    }

    if (!!this.minYears && !this.maxYears) {
      arrayFilter = arrayFilter.filter(item => {
        let minAgeDate = moment(new Date()).subtract(this.minYears, 'years').toDate();
        return moment(+item.birthday).isBefore(minAgeDate);
      });
    }

    if (!!this.maxYears && !this.minYears) {
      arrayFilter = arrayFilter.filter(item => {
        let maxAgeDate = moment(new Date()).subtract(this.maxYears, 'years').toDate();
        return moment(+item.birthday).isAfter(maxAgeDate);
      });
    }

    if (!!this.maxYears && !!this.minYears) {
      arrayFilter = arrayFilter.filter(item => {
        let minAgeDate = moment(new Date()).subtract(this.minYears, 'years').toDate();
        let maxAgeDate = moment(new Date()).subtract(this.maxYears, 'years').toDate();
        return moment(+item.birthday).isAfter(maxAgeDate) && moment(+item.birthday).isBefore(minAgeDate);
      });
    }


    if (!(this.minExpectation == 0 && this.maxExpectation == infiniteExpectation)) {
      const expectationFilter = (arrayFilter.filter(applicant => applicant.expectation >= this.minExpectation && applicant.expectation <= this.maxExpectation));
      arrayFilter = expectationFilter.length == 0 ? arrayFilter : expectationFilter;
    }

    if (this.minExpectation == 0) {
      this.minExpectation = null;
    }
    if (this.maxExpectation == infiniteExpectation) {
      this.maxExpectation = null;
    }

    this.applicantsFiltered = _.orderBy(arrayFilter, 'createdAt', 'desc');
    this.applicantsFiltered$ = of(this.applicantsFiltered);
  }

  async goToApplicantDetails(applicantKey: string): Promise<void> {
    const applicants = await this.applicantsFiltered$.pipe(take(1)).toPromise();

    for (let i = 0; i < applicants.length; i++) {
      for (let e = 0; e < this.selectArrayPosition.length; e++) {
        if (!!applicants[i].position.reference && applicants[i].position.reference.id == this.selectArrayPosition[e].key) {
          applicants[i].position.name = this.selectArrayPosition[e].name;
        }
      }
      delete applicants[i].position.reference;
    }

    for (let i = 0; i < applicants.length; i++) {
      if (!!applicants[i].project) applicants[i].project = applicants[i].project.id;
    }

    this._applicant.applicantsQueue = applicants;

    this.setLocalStorageItems();
    this.router.navigateByUrl(`admin/applicant-details/${applicantKey}`);
  }

  setLocalStorageItems() {
    if (this.selectedMultipleArray.length > 0) {
      localStorage.setItem('selectFilter', JSON.stringify(this.selectedMultipleArray));
    }
    if (!!this.minExpectation) {
      localStorage.setItem('minExpectation', JSON.stringify(this.minExpectation));
    }
    if (!!this.maxExpectation) {
      localStorage.setItem('maxExpectation', JSON.stringify(this.maxExpectation));
    }
    if (!!this.minYears) {
      localStorage.setItem('minYears', JSON.stringify(this.minYears));
    }
    if (!!this.maxYears) {
      localStorage.setItem('maxYears', JSON.stringify(this.maxYears));
    }
  }

  getStatus() {
    this.statusSubscription = this._status.getAll()
      .pipe(
        map(status => status.map(status => ({
            name: status.name,
            key: status.key,
            type: 'Estatus'
          }
        )))
      )
      .subscribe(data => {
        this.selectArrayStatus = data;
      });
  }

  async getProjects() {
    this.projectSubscription = this._project.getAll()
      .pipe(
        map(project => project.map(project => ({
            name: project.name,
            key: project.key,
            status: project.status,
            type: 'Proyecto'
          }
        )))
      )
      .subscribe(data => {
        this.selectArrayProjects = data;
        data = data.filter(project => project.status == 0);
        let arr = this.selectArrayStatus;

        for (let i = 0; i < data.length; i++) {
          arr.push(data[i]);
        }
      });
  }

  async getPositions() {
    await this.onlyRegisteredCities();

    this.positionSubscription = this._position.getAll()
      .pipe(
        map(position => position.map(position => ({
            name: position.name,
            key: position.key,
            type: 'Cargo'
          }
        )))
      )
      .subscribe(data => {
        this.selectArrayPosition = data;
        let arr = this.selectArrayStatus;
        for (let i = 0; i < data.length; i++) {
          arr.push(data[i]);
        }

        for (let i = 0; i < this.noRepeat.length; i++) {
          let city = { name: this.noRepeat[i].name, type: 'Ciudad' };
          arr.push(city);
        }
        this.selectMultipleArray = arr;
      });
  }

  async setStatus(applicant: any, status: string) {
    if (await AlertService.confirm(`¿Estás seguro que deseas pasar este postulante a ${status}?`, '')) {
      this._applicant.update(applicant.key, { status: { name: status } } as Applicant);
      AlertService.toastSuccess('Se actualizó correctamente', '');
    }
  }

  getStatusClass(name) {
    switch (name) {
      case 'apto':
        return 'badge-info';
      case 'incompleto':
        return 'badge-danger';
      case 'calificado':
        return 'badge-success';
      case 'postulante':
        return 'badge-primary';
      case 'no apto':
        return 'badge-danger';
      case 'bloqueado':
        return 'badge-dark';
      case 'revisión':
        return 'badge-warning';
      case 'inactivo':
        return 'badge-light';
      case 'contratado':
        return 'badge-success';
      default:
        return 'warning';
    }
  }

  selectFilter() {
    const statusFilter = this.selectedMultipleArray.filter((filter => filter.type == 'Estatus'));
    if (statusFilter.length == 2) {
      this.selectedMultipleArray.splice(0, 1);
    }
    this.selectedMultipleArray = this.selectedMultipleArray.filter(s => s != id);
  }

  getPositionName(reference: any) {
    let index = this.selectArrayPosition.findIndex(position => position.key == reference.id);
    return index != -1 ? this.selectArrayPosition[index].name : '';
  }

  deleteDuplicateElements(elements: any[]) {
    return elements.filter((data, index: number) => elements.findIndex(d => d.name == data.name) == index);
  }

  async deleteApplicant(applicantKey: string) {
    if (!await AlertService.confirm('¿Estás seguro de que deseas eliminar este postulante?')) return;

    await this._applicant.delete(applicantKey);

    AlertService.toastSuccess('El postulante se ha eliminado correctamente');
    this.getApplicantsFilter();
  }
}
