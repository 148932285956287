import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AlertService} from 'src/app/shared/template-services/alert.service';
import { FundToRender } from '../../interfaces/fund-to-render';
import { AccountabilityService } from '../../services/accountability.service';
import { FundToRenderService } from '../../../../../shared/services/fund-to-render.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-funds-to-render-select',
  templateUrl: './funds-to-render-select.component.html',
  styleUrls: ['./funds-to-render-select.component.css']
})
export class FundsToRenderSelectComponent implements OnInit {
  fundsToRender: FundToRender[] = [];
  fundToRender: FundToRender;
  fundToRenderSelected: FundToRender;
  accepted: boolean;

  constructor(private modal: BsModalRef,
              private _accountability: AccountabilityService,
              private _fundToRender: FundToRenderService) {}

  async ngOnInit() {
    await this.setFundsToRenderWithNoAccountability(this.fundsToRender);
  }

  async setFundsToRenderWithNoAccountability(fundsToRender: FundToRender[]) {
    for (const fund of fundsToRender) {
      const fundReference = this._fundToRender.getReference(`fundToRender/${fund.key}`);

      const accountabilities = await this._accountability
        .getByFundToRender(fundReference)
        .pipe(first())
        .toPromise();

      if (accountabilities.length) {
        fundsToRender = this.dropFundToRender(fund, fundsToRender);
      }
    }
    this.fundsToRender = fundsToRender;
  }

  dropFundToRender(fundToRender: FundToRender, fundsToRender: FundToRender[]) {
    return fundsToRender.filter(fund => fund.key != fundToRender.key);
  }

  async selectFundToRender() {
    if (
      !(await AlertService.confirm(
        'Confirmar selección de fondo a rendir',
        '¿Está seguro de que desea seleccionar el fondo a rendir?'
      ))
    )
      return;
    this.accepted = true;
    this.modal.hide();
  }

  cancelOperation() {
    this.accepted = false;
    this.modal.hide();
  }
}
