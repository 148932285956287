import {Component, OnInit} from '@angular/core';
import {Warehouse} from '../../interfaces/warehouse';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WarehouseService} from '../../../../../shared/services/warehouse.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {map} from 'rxjs/operators';
import {UserService} from '../../../../../shared/services/user.service';
import {LogsService} from '../../../../services/logs.service';
import {UserType} from '../../../../enums/user-type.enum';
import {AlertService} from '../../../../../shared/template-services/alert.service';
import {PermissionService} from '../../services/permission.service';
import {simpleLineIcons} from '../../data/simple-line';
import {bgColors} from '../../data/bg-colors';

@Component({
  selector: 'app-update-warehouse',
  templateUrl: './update-warehouse.component.html',
  styleUrls: ['./update-warehouse.component.css'],
})
export class UpdateWarehouseComponent implements OnInit {
  warehouseKey: string;
  warehouse: Warehouse;
  editWarehouseForm: FormGroup;
  submitted: boolean = false;
  public supervisors = [];
  grocersAndBuyers: any = [];
  users: any = [];
  permissionEnum = UserType;
  photo: any;
  path: any;
  permission;
  icons: string[] = [...simpleLineIcons];
  bgColors: string[] = [...bgColors];

  constructor(private formBuilder: FormBuilder,
              private _warehouse: WarehouseService,
              public modal: BsModalRef,
              private _log: LogsService,
              private _user: UserService,
              private _permission: PermissionService) {
    this.editWarehouseForm = formBuilder.group({
      code: ['', Validators.required],
      name: ['', Validators.required],
      description: ['', Validators.required],
      location: ['', Validators.required],
      warehouseManager: ['', Validators.required],
      grocer: ['', Validators.required],
      buyer: ['', Validators.required],
      users: [[]],
      imageUrl: [''],
      icon: [''],
      bgColor: [''],
      trash: [false],
    });
  }

  ngOnInit(): void {
    this.permission = this._user.getPermission('BODEGAS');
    this.editWarehouseForm.patchValue(this.warehouse);

    if (this.permission != this.permissionEnum.ADMIN) this.editWarehouseForm.disable()

    this._user
      .getUsers()
      .pipe(
        map((users) =>
          users.map((user) => ({
            imageUrl: !!user.imageUrl ? user.imageUrl : null,
            name: user.name,
            permissions: user.permissions,
            reference: this._user.getReference(user.key),
          }))
        )
      )
      .subscribe((data) => {
        let supervisors = [];
        let grocers = [];
        let users = [];

        this.setBuyer(data);

        for (let i = 0; i < data.length; i++) {
          if (!!data[i].permissions) {
            let permissionsLength = data[i].permissions.length;
            for (let iPermission = 0; iPermission < permissionsLength; iPermission++) {
              if (this._permission.isSupervisor(data[i].permissions[iPermission], 'BODEGAS')) {
                supervisors.push(data[i]);
                users.push(data[i]);
              }

              if (this._permission.isGrocer(data[i].permissions[iPermission], 'BODEGAS') ||
                this._permission.isBuyer(data[i].permissions[iPermission], 'BODEGAS')) {
                grocers.push(data[i]);
                users.push(data[i]);
              }

              if(this._permission.isUser(data[i].permissions[iPermission], 'BODEGAS')){
                users.push(data[i]);
              }
            }
          }
        }

        this.supervisors = supervisors;
        this.grocersAndBuyers = grocers;
        this.users = users;
      });
  }

  isValidPath(): boolean {
    return !(this.path == '' || this.path == null);
  }

  get formControls() {
    return this.editWarehouseForm.controls;
  }

  async submit() {
    this.submitted = true;
    if (!this.editWarehouseForm.valid) return;
    if (!(await AlertService.confirm('¿Está seguro de que desea guardar los datos?'))) return;

    this.editWarehouseForm.patchValue({
      buyer: this.editWarehouseForm.value.buyer.reference,
    });

    if (!!this.path) {
      this.editWarehouseForm.patchValue({
        imageUrl: await this._warehouse.uploadPicture(
          this.photo,
          this.warehouseKey
        ),
      });
    }

    this._warehouse.update(this.warehouseKey, this.editWarehouseForm.value as Warehouse);

    this._log.add(this.warehouseKey, {
      description: `Se actualizó la bodega ${
        this.editWarehouseForm.get('name').value
      }`,
    });

    AlertService.toastSuccess('Datos guardados correctamente');
    this.modal.hide();
  }

  setBuyer(users: any[]) {
    if (!('buyer' in this.warehouse)) return;
    if (!users.some((user) => user.reference.id == (<any>this.warehouse.buyer).id)) return;

    this.editWarehouseForm.patchValue({
      buyer: users.find(
        (user) => user.reference.id == (<any>this.warehouse.buyer).id
      ),
    });
  }
}
