import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Applicant } from '../../../../interfaces/applicant';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-comments-modal',
  templateUrl: './comments-modal.component.html',
  styleUrls: ['./comments-modal.component.css']
})
export class CommentsModalComponent implements OnInit {
  comments: any = [];
  users = [];
  usersNotification = [];
  commentForm: FormGroup;
  applicant: Applicant;
  currentUser: any;
  submitted = false;
  indexApplicant: number;
  applicants: any = [];
  applicantKey: string;

  constructor(private modal: BsModalService) {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.modal.hide();
  }
}
