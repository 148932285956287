<app-datatable [rows$]="units$"
               [config]="config"
               (rowSelected)="gotToUnitEdit($event)">

  <app-datatable-column title="Nombre">
    <ng-template let-row #column>
      {{row?.name}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false" [isVisiblePrint]="false">
    <ng-template let-row #column>
      <button class="btn btn-danger btn-sm" (click)="deleteUnit(row.key)">Eliminar</button>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="addUnit()"></app-add-float-button>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
             type="square-jelly-box"><p style="color: white"> Subiendo... </p></ngx-spinner>
