<div class="p-2">
  <h2>Enviar cuenta</h2>

  <form [formGroup]="accountForm" (ngSubmit)="submit()">
    <div class="row mt-2">
      <div class="col-md-12 col-12">
        <div class="form-group">
          <label>Email *</label>
          <input type="text" class="form-control" formControlName="email"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.email.errors }"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.email.errors">
            <div *ngIf=" formControls.email.errors.required">
              El email es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-12 col-12">
        <div class="form-group">
          <label>Contraseña *</label>
          <input type="password" class="form-control" formControlName="password"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.password.errors }"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.password.errors">
            <div *ngIf=" formControls.password.errors.required">
              La contraseña es obligatorio
            </div>
          </small>
        </div>
      </div>
    </div>

    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn">Enviar</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide()">Cancelar</div>
    </div>
  </form>

</div>
