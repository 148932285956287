import {FundToRenderStatusEnum} from '../enums/fund-to-render-status.enum';

export const FundToRenderStatusLabel = {
  [FundToRenderStatusEnum.DRAFT]: 'Borrador',
  [FundToRenderStatusEnum.GENERATED]: 'En revisión',
  [FundToRenderStatusEnum.REVIEW]: 'En espera de autorización',
  [FundToRenderStatusEnum.AUTHORIZED]: 'Autorizado',
  [FundToRenderStatusEnum.RENDERED]: 'Rendido',
  [FundToRenderStatusEnum.CLOSED]: 'Cerrado',
  [FundToRenderStatusEnum.CANCELLED]: 'Cancelado',
  [FundToRenderStatusEnum.REJECTED]: 'Rechazado',
  [FundToRenderStatusEnum.PAID]: 'Pagado',
  [FundToRenderStatusEnum.CLOSED_COLSAN]: 'Cerrado a favor de Colsan',
  [FundToRenderStatusEnum.CLOSED_USER]: 'Cerrado a favor del usuario',
  [FundToRenderStatusEnum.PAID_COLSAN]: 'Cerrado a favor de Colsan',
  [FundToRenderStatusEnum.PAID_USER]: 'Cerrado a favor del usuario'
};
