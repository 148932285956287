import { Component, OnInit } from '@angular/core';
import {EmployeePayment} from '../../interfaces/employee-payment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {EmployeePaymentService} from '../../services/employee-payment.service';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-employee-payment-modal',
  templateUrl: './employee-payment-modal.component.html',
  styleUrls: ['./employee-payment-modal.component.css']
})
export class EmployeePaymentModalComponent implements OnInit {
  employeePayment: EmployeePayment = {key: ''} as EmployeePayment;
  employeePaymentForm: FormGroup;
  submitted: boolean = false;
  isUpdate: boolean = false;
  costCenterKey: string;

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _employeePaymentService: EmployeePaymentService,
              private _firebase: FirebaseDataService) {
    this.employeePaymentForm = formBuilder.group({
      payments: ['', Validators.required],
      date: [this.formatDate(new Date().getTime()), Validators.required],
      createdAt: [''],
      trash: [false],
      centerCostKey: ['']
    });
  }

  ngOnInit(): void {
    this.employeePaymentForm.patchValue({centerCostKey: this.costCenterKey});
    if (this.isUpdate) {
      this.employeePayment.date = this.formatDate(this.employeePayment.date);
      this.employeePaymentForm.patchValue(this.employeePayment);
    }
  }

  get formControls() {
    return this.employeePaymentForm.controls;
  }

  async submit() {
    this.submitted = true;
    if (this.employeePaymentForm.valid) {

      if (!this.isUpdate) {
        this.employeePayment.key = this._firebase.createID();
        this.employeePaymentForm.patchValue({createdAt: new Date().getTime()});
      }

      await this.employeePaymentForm.patchValue({date: await this.convertToTime(this.employeePaymentForm.value.date)});
      await this._employeePaymentService.set(this.costCenterKey, this.employeePayment.key, this.employeePaymentForm.value);

      this.modal.hide();
    }
  }

  private formatDate(date) {
    const deliveryDateDate = new Date(date);
    const format = 'yyyy/MM/dd';
    const locale = 'en-US';
    const zone = 'UTC';
    const formattedDate = formatDate(deliveryDateDate, format, locale, zone);
    date = formattedDate.replace(/\//g, '-');
    return date;
  }

  convertToTime(date) {
    let newDeliveryDate = date.replaceAll('-', '/');
    let deliveryDateMilliseconds = new Date(newDeliveryDate).getTime();
    return deliveryDateMilliseconds;
  }
}

