<div
  id="invoice-items-details"
  class="pt-2">
  <div class="row">
    <div class="table-responsive col-sm-12 pl-0 pr-0">
      <table class="table">
        <thead>
        <tr>
          <th>CANTIDAD</th>
          <th>UNIDAD</th>
          <th>CÓDIGO</th>
          <th>CÓDIGO PROVEEDOR</th>
          <th>DESCRIPCIÓN</th>
          <th>VALOR UNITARIO</th>
          <th>% DESCUENTO</th>
          <th>VALOR TOTAL</th>
          <th>EXENTO</th>
          <th class="text-right">AGREGAR</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let articles of articlesPurchase; let index = index">
          <td>
            <input
              type="number"
              class="form-control"
              [(ngModel)]="articlesPurchase[index].quantity"
              (ngModelChange)="setTotalInRow(articles, index)"
              placeholder=""/>
          </td>
          <td *ngIf="isReference(articles?.unit)">{{(articles?.unit | doc | async)?.name}}</td>
          <td *ngIf="!isReference(articles?.unit)">{{articles?.unit}}</td>
          <td>
            <span *ngIf="!isReference(articles?.code)">{{articles?.code}}</span>
          </td>
          <td>
            <span *ngIf="!isReference(articles?.providerCode)">{{articles?.providerCode}}</span>
          </td>
          <td>{{articles?.description}}</td>
          <td>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="articlesPurchase[index].unitValue"
              (ngModelChange)="setTotalInRow(articles, index)"
              placeholder=""/>
          </td>
          <td>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="articlesPurchase[index].discount"
              (ngModelChange)="setTotalInRow(articles, index)"
              placeholder=""/>
          </td>
          <td>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="articlesPurchase[index].total"
              (ngModelChange)="getTotals(false)"
              placeholder=""/>
          </td>
          <td>{{articles?.exempt ? 'Si' : 'No'}}</td>
          <td>
            <button
              class="btn btn-sm btn-warning"
              (click)="dropArticle(index)"><i class="la la-close"></i></button>
          </td>
        </tr>

        <tr>
          <td>
            <input
              type="number"
              class="form-control"
              [(ngModel)]="quantity"
              (ngModelChange)="setTotal()"
              placeholder=""/>
          </td>

          <td>
            <ng-select
              [(ngModel)]="unit"
              (change)="setProviderCode()"
              [items]="unitsArray"
              appendTo="body"
              [clearable]="false"
              bindLabel="name"
              placeholder="">
              <ng-template
                ng-option-tmp
                let-item="item">
                {{item.name}}
              </ng-template>
            </ng-select>
          </td>
          <td>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="unitCode"
              placeholder=""/>
          </td>

          <td>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="providerCode"
              placeholder=""/>
          </td>

          <td>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="description"
              placeholder=""/>
          </td>

          <td>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="unitValue"
              (ngModelChange)="setTotal()"
              placeholder=""/>
          </td>

          <td>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="discount"
              (ngModelChange)="setTotal()"
              placeholder=""/>
          </td>

          <td>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="total"
              (ngModelChange)="getTotals(false)"
              placeholder=""/>
          </td>

          <td>
            <input
              type="checkbox"
              data-toggle="toggle"
              [(ngModel)]="exempt"
              data-size="xs">
          </td>

          <td>
            <button
              class="btn btn-sm btn-success"
              (click)="addToArticles()"><i class="la la-check"></i></button>
          </td>
        </tr>

        <tr>
          <td
            class="cursor-pointer text-center"
            ngbTooltip="Búsqueda avanzada"
            container="body"
            (click)="openSearchArticle()"><i
            class="fa fa-search fa-2x"></i></td>
          <td>
            <button
              class="btn btn-success"
              (click)="addUnit()">Agregar unidad
            </button>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td>SUB TOTAL</td>
          <td>{{subtotal | clearCurrency | swapCommasAndDots}}</td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>DESCUENTO</td>
          <td>{{discountTotal | clearCurrency | swapCommasAndDots}}</td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>Fecha de presentación OC:</td>
          <td>{{currentDate | date : 'dd/MM/yyyy HH:mm'}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td>NETO</td>
          <td>{{net | clearCurrency | swapCommasAndDots}}</td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>Fecha de aprobación OC:</td>
          <td></td>
          <td>CONDICIÓN DE PAGO:</td>
          <td>
            <ng-select
              [(ngModel)]="paymentType"
              (change)="setPaymentType()"
              [items]="paymentTypeArray"
              appendTo="body"
              placeholder="">
            </ng-select>
          </td>
          <td></td>
          <td>19% IVA</td>
          <td>{{iva | clearCurrency | swapCommasAndDots}}</td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>Fecha de entrega:</td>
          <td>
            <input
              class="form-control"
              type="date"
              [(ngModel)]="deliveryDate"
              #event
              (change)="convertToTime(event)">
          </td>
          <td>MONEDA Y TIPO DE CAMBIO:</td>
          <td>
            <ng-select
              [(ngModel)]="currency"
              (change)="setCurrency()"
              [items]="currencyArray"
              appendTo="body"
              placeholder="">
            </ng-select>
          </td>
          <td></td>
          <td>TOTAL</td>
          <td>{{totalValue | clearCurrency | swapCommasAndDots}}</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
