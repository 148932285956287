<div class="modal-header">
  <h4 class="modal-title pull-left">Solicitud para nuevo fondo por rendir</h4>
  <button type="button" class="pull-right btn" (click)="modal.hide()">
    <i class="feather ft-x"></i>
  </button>
</div>

<div class="p-1">
  <form [formGroup]="requestFundToRenderForm">
    <div class="mt-1">
      <label>Motivo</label>
      <input type="text" class="form-control" formControlName="reason" />
    </div>

    <div class="mt-2 mb-2">
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-success mr-1"
          *ngIf="permission == permissionEnum.USER"
          (click)="submit()">
          Guardar
        </button>
        <button class="btn btn-outline-danger" (click)="modal.hide()">
          Cerrar
        </button>
      </div>
    </div>
  </form>
</div>
