<app-datatable [rows$]="fundToRenderRequests$" [config]="config">
  <app-datatable-column title="Razón">
    <ng-template let-row #column>
      {{ row.reason }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Usuario">
    <ng-template let-row #column>
      {{ !!row.user ? (row.user | doc | async)?.name : '-' }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha">
    <ng-template let-row #column>
      {{ row.createdAt | date: 'dd/MM/yyyy HH:mm aa' }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Estatus">
    <ng-template let-row #column>
      {{ fundToRenderRequestStatusLabel[row.status]}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column [isClickable]="false">
    <ng-template let-row #column>
      <div class="d-flex justify-content-end">
        <button
          *ngIf="acceptableSupervisorRequests(row)"
          class="btn btn-success mr-1"
          ngbTooltip="Aceptar y enviar al administrador"
          (click)="acceptFundToRenderRequestSupervisor(row)">
          <i class="la la-check"></i>
        </button>

        <button
          *ngIf="acceptableSupervisorRequests(row)"
          class="btn btn-danger"
          ngbTooltip="Rechazar"
          (click)="rejectFundToRenderRequestSupervisor(row)">
          <i class="la la-times"></i>
        </button>

        <button
          *ngIf="acceptableAdminRequests(row)"
          class="btn btn-success mr-1"
          ngbTooltip="Aceptar"
          (click)="acceptFundToRenderRequest(row)">
          <i class="la la-check"></i>
        </button>

        <button
          *ngIf="acceptableAdminRequests(row)"
          class="btn btn-danger"
          ngbTooltip="Rechazar"
          (click)="rejectFundToRenderRequest(row)">
          <i class="la la-times"></i>
        </button>
      </div>
    </ng-template>
  </app-datatable-column>
</app-datatable>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
  ><p style="color: white">Guardando datos...</p></ngx-spinner
>
