<div class="d-flex flex-row justify-content-between">
  <h2 class="mr-4 d-flex align-items-center">{{salesBoard?.name}}</h2>

  <div class="d-flex flex-row">
    <div class="mr-1">
      <label>Desde</label>
      <input
        class="form-control p-sm"
        type="date"
        [(ngModel)]="startDate"
        (ngModelChange)="filterAll()" />
    </div>

    <div>
      <label>Hasta</label>
      <input
        class="form-control p-sm"
        type="date"
        [(ngModel)]="finalDate"
        (ngModelChange)="filterAll()" />
    </div>

    <div class="ml-1 align-self-center">
      <mat-form-field class="w-100 mb-n3 align-middle">
        <mat-label>Asignados</mat-label>
        <mat-select [(ngModel)]="userFilter" (ngModelChange)="filterAll()">
          <mat-select-trigger>
            <ul class="list-unstyled users-list mb-0" *ngIf="!!userFilter">
              <li class="avatar avatar-sm pull-up">
                <img
                  class="media-object rounded-circle avatar-img"
                  container="body"
                  [ngbTooltip]="userFilter.name"
                  [src]="
                  !!userFilter.imageUrl
                    ? userFilter.imageUrl
                    : '/assets/images/default.png'
                " />
              </li>
            </ul>
          </mat-select-trigger>
          <mat-option [value]="userFilter == null">
            <div class="ml-1">TODOS</div>
          </mat-option>
          <mat-option *ngFor="let user of users; let i = index" [value]="user">
            <img
              src="{{ user.imageUrl }}"
              class="avatar-img rounded-circle"
              alt="" /><span class="ml-1">{{ user.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="ml-1 align-self-center">
      <mat-form-field class="w-100 mb-n3 align-middle">
        <mat-label>Cliente</mat-label>
        <mat-select [(ngModel)]="clientSelected" (ngModelChange)="filterAll()">
          <mat-select-trigger>
            <p class="mb-0" *ngIf="clientSelected">{{ clientSelected.name }}</p>
          </mat-select-trigger>
          <mat-option *ngFor="let client of allClients; let i = index" [value]="client">
            <span class="ml-1">{{ client.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>

<div
  cdkDropListGroup
  class="d-flex mt-2 animated fadeIn faster kanban-container"
  [style.zoom]="zoom">
  <div class="example-container">
    <div class="flex-row d-flex justify-content-between mb-1">
      <h3>PROSPECTO</h3>
      <h5 class="mr-1 place-items-center">{{ leaflet.length }}</h5>
    </div>

    <perfect-scrollbar>
      <div
        cdkDropList
        [id]="saleStatusEnum.LEAFLET"
        [cdkDropListData]="leaflet"
        class="example-list"
        (cdkDropListDropped)="drop($event)">
        <div
          class="example-box"
          *ngFor="let item of leaflet"
          cdkDrag
          [cdkDragDisabled]="validateCdkDragDisabled(item)">
          <app-sale-card
            [item]="item"
            (click)="openSaleDetails(item)"></app-sale-card>
        </div>
      </div>
    </perfect-scrollbar>
    <div class="bg-white text-center total-list">
      <h5>
        {{ 'Total: ' + (leafletTotal | currency: 'CL$' | swapCommasAndDots) }}
      </h5>
    </div>
  </div>

  <div class="example-container">
    <div class="flex-row d-flex justify-content-between mb-1">
      <h3>OPORTUNIDAD</h3>
      <h5 class="mr-1 place-items-center">{{ opportunity.length }}</h5>
    </div>
    <perfect-scrollbar>
      <div
        cdkDropList
        [id]="saleStatusEnum.OPPORTUNITY"
        [cdkDropListData]="opportunity"
        class="example-list"
        (cdkDropListDropped)="drop($event)">
        <div
          class="example-box"
          *ngFor="let item of opportunity"
          cdkDrag
          [cdkDragDisabled]="validateCdkDragDisabled(item)">
          <app-sale-card
            [item]="item"
            (click)="openSaleDetails(item)"></app-sale-card>
        </div>
      </div>
    </perfect-scrollbar>

    <div class="bg-white text-center total-list">
      <h5>
        {{
        'Total: ' + (opportunityTotal | currency: 'CL$' | swapCommasAndDots)
        }}
      </h5>
    </div>
  </div>

  <div class="example-container">
    <div class="flex-row d-flex justify-content-between mb-1">
      <h3>PROPUESTA</h3>
      <h5 class="mr-1 place-items-center">{{ proposal.length }}</h5>
    </div>

    <perfect-scrollbar>
      <div
        cdkDropList
        [id]="saleStatusEnum.PROPOSAL"
        [cdkDropListData]="proposal"
        class="example-list"
        (cdkDropListDropped)="drop($event)">
        <div
          class="example-box"
          *ngFor="let item of proposal"
          cdkDrag
          [cdkDragDisabled]="validateCdkDragDisabled(item)">
          <app-sale-card
            [item]="item"
            (click)="openSaleDetails(item)"></app-sale-card>
        </div>
      </div>
    </perfect-scrollbar>

    <div class="bg-white text-center total-list">
      <h5>
        {{ 'Total: ' + (proposalTotal | currency: 'CL$' | swapCommasAndDots) }}
      </h5>
    </div>
  </div>

  <div class="example-container">
    <div class="flex-row d-flex justify-content-between mb-1">
      <h3>HOT</h3>
      <h5 class="mr-1 place-items-center">{{ hot.length }}</h5>
    </div>

    <perfect-scrollbar>
      <div
        cdkDropList
        [id]="saleStatusEnum.HOT"
        [cdkDropListData]="hot"
        class="example-list"
        (cdkDropListDropped)="drop($event)">
        <div
          class="example-box"
          *ngFor="let item of hot"
          cdkDrag
          [cdkDragDisabled]="validateCdkDragDisabled(item)">
          <app-sale-card
            [item]="item"
            (click)="openSaleDetails(item)"></app-sale-card>
        </div>
      </div>
    </perfect-scrollbar>

    <div class="bg-white text-center total-list">
      <h5>
        {{ 'Total: ' + (hotTotal | currency: 'CL$' | swapCommasAndDots) }}
      </h5>
    </div>
  </div>

  <div class="example-container">
    <div class="flex-row d-flex justify-content-between mb-1">
      <h3>CIERRES GANADOS</h3>
      <h5 class="mr-1 place-items-center">{{ earnedClosures.length }}</h5>
    </div>

    <perfect-scrollbar>
      <div
        cdkDropList
        [id]="saleStatusEnum.EARNED_CLOSURE"
        [cdkDropListData]="earnedClosures"
        class="example-list"
        (cdkDropListDropped)="drop($event)">
        <div
          class="example-box"
          *ngFor="let item of earnedClosures"
          cdkDrag
          [cdkDragDisabled]="validateCdkDragDisabled(item)">
          <app-sale-card
            [item]="item"
            (click)="openSaleDetails(item)"></app-sale-card>
        </div>
      </div>
    </perfect-scrollbar>

    <div class="bg-white text-center total-list">
      <h5>
        {{
        'Total: ' +
        (earnedClosuresTotal | currency: 'CL$' | swapCommasAndDots)
        }}
      </h5>
    </div>
  </div>

  <div class="example-container">
    <div class="flex-row d-flex justify-content-between mb-1">
      <h3>CIERRES PERDIDOS</h3>
      <h5 class="mr-1 place-items-center">{{ missedClosures.length }}</h5>
    </div>

    <perfect-scrollbar>
      <div
        cdkDropList
        [id]="saleStatusEnum.MISSED_CLOSURE"
        [cdkDropListData]="missedClosures"
        class="example-list"
        (cdkDropListDropped)="drop($event)">
        <div
          class="example-box"
          *ngFor="let item of missedClosures"
          cdkDrag
          [cdkDragDisabled]="validateCdkDragDisabled(item)">
          <app-sale-card
            [item]="item"
            (click)="openSaleDetails(item)"></app-sale-card>
        </div>
      </div>
    </perfect-scrollbar>

    <div class="bg-white text-center total-list">
      <h5>
        {{
        'Total: ' +
        (missedClosuresTotal |  currency:'CLP')
        }}
      </h5>
    </div>
  </div>
</div>

<button
  class="btn btn-success add-zoom"
  app-tooltip="Añadir zoom"
  (click)="addZoom()">
  <i class="la la-plus"></i>
</button>

<button
  class="btn btn-warning substract-zoom"
  app-tooltip="Restar zoom"
  (click)="substractZoom()">
  <i class="la la-minus"></i>
</button>

<button
  class="btn btn-dark config"
  app-tooltip="Configurar"
  *ngIf="userPermission == userTypeEnum.ADMIN"
  (click)="openConfig()">
  <i class="la la-gear"></i>
</button>

<app-add-float-button (clickEmmiter)="openAddSale()"></app-add-float-button>
