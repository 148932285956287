<form [formGroup]="saleForm">
  <div class="row p-2">
    <div class="col-md-6">
      <div class="mt-1">
        <h4 class="font-weight-bold">Nombre de la oportunidad</h4>
        <h5 *ngIf="!isEditingTitle" (click)="isEditingTitle = true">
          {{ saleForm.value.title }}
        </h5>
        <textarea
          *ngIf="isEditingTitle"
          type="text"
          formControlName="title"
          class="form-control"
          [ngClass]="{'is-invalid': formControls.title.errors}"
          (blur)="update(saleForm.value.title, 'title')"></textarea>
        <small class="invalid-feedback">Campo obligatorio</small>
      </div>

      <div class="mt-2">
        <h4 class="font-weight-bold">Descripción</h4>
        <div
          *ngIf="!isEditingDescription"
          (click)="isEditingDescription = true"
          [innerHTML]="saleForm.value.description"></div>
        <ckeditor
          *ngIf="isEditingDescription"
          [editor]="editor"
          [config]="config"
          formControlName="description"></ckeditor>
        <button
          (click)="update(saleForm.value.description, 'description')"
          class="btn btn-success mt-1"
          *ngIf="isEditingDescription">
          Aceptar
        </button>
      </div>

      <div class="mt-2">
        <h4 class="font-weight-bold">Comentarios</h4>
        <div class="mb-3">
          <input
            type="text"
            class="form-control comment-input"
            [(ngModel)]="comment"
            [ngModelOptions]="{standalone: true}"
            placeholder="Escriba su comentario..."
            (keyup.enter)="sendComment()" />
        </div>
        <div *ngFor="let comment of comments">
          <div class="d-flex flex-row">
            <img
              src="{{
                !!comment.user?.imageUrl
                  ? comment.user.imageUrl
                  : '/assets/images/default.png'
              }}"
              class="avatar-img-comment rounded-circle"
              alt="" />
            <div class="d-flex flex-column">
              <div class="ml-1">
                <span class="font-weight-bold text-dark">{{
                  comment.user?.name
                }}</span>
                <span class="ml-1 text-muted text-small font-size-xsmall">{{
                  comment.createdAt | date: 'dd/MM/yyyy HH:mm'
                }}</span>
              </div>

              <p class="text-dark ml-1">{{ comment.comment }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 border-1">
      <div class="card shadow-none border border-gray">
        <div class="card-header border-bottom border-gray pt-1 pb-1">
          <span>Detalles</span>
        </div>
        <div class="card-body p-2">
          <div class="d-flex align-items-center px-0">
            <div class="font-weight-bold w-50">Vendedores a cargo</div>
            <mat-form-field class="w-50">
              <mat-select
                formControlName="users"
                multiple
                [compareWith]="compareUsers"
                (selectionChange)="update(saleForm.value.users, 'users')"
                [disabled]="validateUserPermission(userTypeEnum.USER)">
                <mat-select-trigger>
                  <ul class="list-unstyled users-list m-0">
                    <li
                      class="avatar avatar-sm pull-up"
                      *ngFor="let user of saleForm.value.users">
                      <img
                        class="media-object rounded-circle avatar-img"
                        container="body"
                        [ngbTooltip]="user.name"
                        [src]="
                          !!user.imageUrl
                            ? user.imageUrl
                            : '/assets/images/default.png'
                        "
                        alt="Foto del vendedor a cargo" />
                    </li>
                  </ul>
                </mat-select-trigger>
                <mat-option
                  *ngFor="let user of users; let i = index"
                  [value]="user">
                  <img
                    src="{{
                      !!user.imageUrl
                        ? user.imageUrl
                        : '/assets/images/default.png'
                    }}"
                    class="avatar-img rounded-circle"
                    alt="Foto de vendedor" /><span class="ml-1">{{
                    user.name
                  }}</span>
                </mat-option>
              </mat-select>
              <mat-error>Campo obligatorio</mat-error>
            </mat-form-field>
          </div>

          <div class="d-flex align-items-center px-0 mt-1">
            <div class="font-weight-bold w-50">Código de referencia</div>
            <div class="w-50">
              <input
                type="text"
                formControlName="referenceCode"
                class="form-control"
                [ngClass]="{'is-invalid': formControls.referenceCode.errors}"
                (blur)="
                  update(saleForm.value.referenceCode, 'referenceCode')
                " />
              <small class="invalid-feedback">Campo obligatorio</small>
            </div>
          </div>

          <div class="d-flex align-items-center px-0 mt-1">
            <div class="font-weight-bold w-50">Valor de venta</div>
            <div class="w-50">
              <input
                type="number"
                formControlName="saleValue"
                class="form-control"
                [ngClass]="{'is-invalid': formControls.saleValue.errors}"
                (blur)="update(saleForm.value.saleValue, 'saleValue')" />
              <small class="invalid-feedback">
                <span
                  *ngIf="
                    !!formControls.saleValue.errors &&
                    !!formControls.saleValue.errors.min
                  "
                  class="fadeIn"
                  >El valor debe ser mayor a cero</span
                >
                <span
                  *ngIf="
                    !!formControls.saleValue.errors &&
                    !!formControls.saleValue.errors.required
                  "
                  class="fadeIn"
                  >Campo obligatorio</span
                >
              </small>
            </div>
          </div>

          <div class="d-flex align-items-center px-0 mt-1">
            <div class="font-weight-bold w-50">Margen (%)</div>
            <div class="w-50">
              <input
                type="number"
                formControlName="salesMarginPercentage"
                class="form-control"
                [ngClass]="{
                  'is-invalid': formControls.salesMarginPercentage.errors
                }"
                (blur)="
                  update(
                    saleForm.value.salesMarginPercentage,
                    'salesMarginPercentage'
                  )
                " />
              <small class="invalid-feedback">
                <span
                  *ngIf="
                    !!formControls.salesMarginPercentage.errors &&
                    !!formControls.salesMarginPercentage.errors.min
                  "
                  class="fadeIn"
                  >El valor debe ser mayor a cero</span
                >
                <span
                  *ngIf="
                    !!formControls.salesMarginPercentage.errors &&
                    !!formControls.salesMarginPercentage.errors.required
                  "
                  class="fadeIn"
                  >Campo obligatorio</span
                >
              </small>
            </div>
          </div>

          <div class="d-flex align-items-center px-0 mt-1">
            <div class="font-weight-bold w-50">
              Fecha de expiración de oferta
            </div>
            <input
              type="date"
              formControlName="closeDate"
              class="form-control w-50"
              (change)="update(saleForm.value.closeDate, 'closeDate')" />
          </div>

          <div class="d-flex align-items-center px-0 mt-1">
            <div class="font-weight-bold w-50">Cliente</div>
            <mat-form-field class="w-50">
              <mat-select
                formControlName="client"
                (selectionChange)="update(saleForm.value.client, 'client')">
                <mat-option (click)="openAddclientModal()" [value]="null">
                  Crear nuevo
                </mat-option>
                <mat-option
                  *ngFor="let client of clients; let i = index"
                  [value]="client">
                  {{ client.name }}
                </mat-option>
              </mat-select>
              <mat-error>Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <span class="text-muted">Fecha de creación: {{sale.createdAt | date : 'dd/MM/yyyy HH:mm'}}</span>

      <div class="mt-1">
        <div class="d-flex justify-content-between">
          <h3 class="font-weight-bold">Enlaces</h3>
          <div
            class="ml-1 cursor-pointer"
            ngbTooltip="Agregar enlace"
            placement="left"
            (click)="addLink()">
            <i class="la la-plus-circle text-dark"></i>
          </div>
        </div>
        <div
          class="mt-1 d-flex justify-content-between"
          *ngFor="let item of saleForm.value.links; let i = index">
          <a class="font-medium-1" target="_blank" [href]="item.link">{{
            item.title
          }}</a>
          <i
            ngbTooltip="Eliminar enlace cursor-pointer"
            (click)="deleteLink(i)"
            class="la la-times text-dark"></i>
        </div>
      </div>
    </div>
  </div>
</form>
<button
  class="btn btn-primary ml-1 mr-1 mb-1 float-right"
  (click)="deleteSale()">
  Eliminar
</button>
<button
  class="btn btn-outline-primary mb-1 float-right"
  (click)="modalRef.hide()">
  Cerrar
</button>
