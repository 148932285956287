import { Injectable } from '@angular/core';
import { IShortcut, IUserShortcut } from '../interfaces/shortcut';
import { FirebaseDataService } from '../template-services/firebase-data.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';

interface IControl {
  adding: boolean;
  editing: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ShortcutsService {
  shortcuts: IShortcut[] = [];
  path: string;
  control$: BehaviorSubject<IControl> = new BehaviorSubject<IControl>({ adding: false, editing: false });

  constructor(private db: FirebaseDataService,
    private afs: AngularFirestore,
    private _user: UserService) {
  }

  get(pathToShortcuts: string): Observable<IShortcut[]> {
    return this.db.doc<IUserShortcut>(pathToShortcuts)
      .valueChanges()
      .pipe(
        map((userShortcut) => {
          this.path = pathToShortcuts;
          if (!!userShortcut && userShortcut.shortcuts) {
            this.shortcuts = userShortcut.shortcuts;
            return userShortcut.shortcuts;
          } else {
            this.shortcuts = [];
            return null;
          }
        })
      );
  }

  async newUserShortcuts() {
    const newId = this.db.createID();
    await this.afs.doc(`shortcuts/${newId}`).set({ shortcuts: [], createdAt: Date.now() });

    this.path = `shortcuts/${newId}`;
    const shortcutRef = this.db.getReference(this.path);

    await this.db.update(`users/${this._user.user.key}`, { shortcuts: shortcutRef });
    return newId;
  }

  add(newShortcut: IShortcut) {
    const nextId = Math.max(...this.shortcuts.map(objeto => objeto.id), 0) + 1;
    this.shortcuts.push({ ...newShortcut, id: nextId });
    return this.db.update(this.path, { shortcuts: this.shortcuts });
  }

  delete(shortcut: IShortcut) {
    this.shortcuts = this.shortcuts.filter(s => s.id !== shortcut.id);
    return this.db.update(this.path, { shortcuts: this.shortcuts });
  }


  startAdding() {
    this.control$.next({ ...this.control$.value, adding: true });
  }

  stopAdding() {
    this.control$.next({ ...this.control$.value, adding: false });
  }

  updatePosition(shortcuts: IShortcut[]) {
    return this.db.update(this.path, { shortcuts });
  }

  update(shortcut: IShortcut) {
    const index = this.shortcuts.findIndex(s => s.id === shortcut.id);
    this.shortcuts[index] = shortcut;
    return this.db.update(this.path, { shortcuts: this.shortcuts });
  }
}
