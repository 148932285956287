<div
  #componentID
  *ngIf="warehouse">
  <section class="card border-0 shadow-none pt-5 pl-5 pr-5 pb-0">
    <div class="d-flex w-100 justify-content-center">
      <h2
        class="text-center"
        [ngClass]="getStatusClass(ticket.status)">
        {{ EntryTicketStatusLabel[ticket.status] }}
      </h2>
    </div>
    <div class="row">
      <div class="col-md-8 col-12">
        <div
          id="invoice-customer-details"
          class="row pt-2">
          <div class="col-md-6 col-sm-12 text-center text-md-left">
            <ul class="px-0 list-unstyled">
              <li class="text-bold-800">Bodega</li>
              <li class="text-bold-800">Código: {{ warehouse.code }}</li>
              <li>Ubicación: {{ warehouse.location }}</li>
              <li>Descripción: {{ warehouse.description }}</li>
            </ul>
          </div>
          <div class="col-md-6 col-sm-12 text-center text-md-right">
            <p>
              <span class="text-muted">Fecha de creación:</span>
              {{ ticket.createdDate | date: 'dd/MM/yyyy HH:mm' }}
            </p>
            <p *ngIf="!!ticket.lastUpdate">
              <span class="text-muted">Última actualización:</span>
              {{ ticket.lastUpdate | date: 'dd/MM/yyyy HH:mm' }}
            </p>
            <p>
              <span
                class="text-muted"
                *ngIf="!!ticket.endDate"
              >Fecha de finalización : {{ ticket.endDate | date }}</span
              >
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-12 text-center text-md-right">
        <h2>TICKET DE ENTRADA</h2>
        <strong>
          <h2>
            <p class="pb-3">ID: {{ ticket?.ticketID | slice: 0:-3 }}
              <span class="text-muted font-size-xsmall">#{{ticket?.ticketID | slice:-3 }}</span></p>
          </h2>
          <p class="text-black-50">{{ ticket.key }}</p>
        </strong>
        <ul class="px-0 list-unstyled">
          <li>TOTAL</li>
          <li class="lead text-bold-800">
            {{ ticket.total | currency: 'USD':'symbol':'1.0-0' }}
          </li>
        </ul>
      </div>
    </div>

    <div
      class="row"
      *ngIf="ticket.status == EntryTicketStatus.DRAFT">
      <div class="form-group col-4">
        <label>Usuario a ingresar *</label>
        <ng-select
          [items]="users"
          [searchable]="true"
          bindLabel="name"
          [clearable]="false"
          [(ngModel)]="userSelected"
          placeholder="Seleccionar un usuario"
          (change)="setUserSelected($event)">
          <ng-template
            ng-option-tmp
            let-item="item">
            {{ item.name }} {{ item.surnames }}
          </ng-template>

          <ng-template
            ng-label-tmp
            let-item="item"
            let-clear="clear">
            <span
              class="ng-value-label"
            >{{ item.name }} {{ item.surnames }}</span
            >
            <span
              class="ng-value-icon right"
              (click)="clear(item)">×</span>
          </ng-template>
        </ng-select>
      </div>
    </div>

    <div
      id="invoice-items-details"
      class="pt-2">
      <div class="row">
        <div class="table-responsive col-sm-12">
          <table class="table">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Artículo</th>
              <th scope="col">Cantidad</th>
              <th scope="col">Precio</th>
              <th scope="col">Total</th>
              <th
                scope="col"
                *ngIf="hasPermission('confirmar')">
                Confirmar
              </th>
              <th *ngIf="ticket.status == EntryTicketStatus.DRAFT">Acción</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let article of ticket.articles; let index = index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ article?.name }}</td>
              <td>{{ article?.quantity }}</td>
              <td>{{ article?.price | currency: 'USD':'symbol':'1.0-0' }}</td>
              <td>
                {{ article['total'] | currency: 'USD':'symbol':'1.0-0' }}
              </td>
              <td *ngIf="hasPermission('confirm')">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="{{ index + 1 }}"
                    (change)="isConfirm(article, index)"
                    [(ngModel)]="ticket.articles[index].isConfirm"/>
                  <label
                    class="form-check-label"
                    for="{{ index + 1 }}">
                    Confirmar
                  </label>
                </div>
              </td>

              <td *ngIf="ticket.status == EntryTicketStatus.DRAFT">
                <div
                  class="btn btn-danger btn-sm"
                  (click)="dropArticle(index, article.key)">
                  <i class="feather ft-trash"></i>
                </div>

                <div
                  class="btn btn-success btn-sm cursor-pointer ml-1"
                  *ngIf="article.type == articleTypeEnum.STORABLE"
                  (click)="showSerialsNumbersArticles(article)"
                  ngbTooltip="{{
                      'Agregar seriales' +
                        ' ' +
                        '(' +
                        (!!article?.serialNumber && article?.quantity
                          ? article?.serialNumber.length
                          : '0') +
                        '/' +
                        article?.quantity +
                        ')'
                    }}">
                  <i class="feather ft-plus"></i>
                </div>
              </td>
            </tr>
            <tr *ngIf="ticket.status == EntryTicketStatus.DRAFT">
              <th scope="row"></th>
              <td>
                <ng-select
                  [items]="articleReferences"
                  [searchable]="true"
                  bindLabel="name"
                  appendTo="body"
                  [(ngModel)]="article"
                  placeholder="Seleccionar artículo"
                  (change)="setArticleSelected($event)"
                  class="select-article">
                  <ng-template
                    ng-option-tmp
                    let-item="item">
                    {{ item.name }}
                  </ng-template>
                  <ng-template
                    ng-label-tmp
                    let-item="item">
                    {{ item.name }}
                  </ng-template>
                </ng-select>
                <small class="form-text text-muted danger invalid-feedback">
                </small>
              </td>
              <td>
                <div class="col-5 p-0 m-0">
                  <div class="form-group">
                    <input
                      #articleQuantity
                      class="quantity"
                      type="number"
                      [(ngModel)]="quantity"
                      class="form-control"
                      placeholder="Escribe la cantidad"
                      (change)="totalPriceArticle(articleQuantity)"/>
                  </div>
                </div>
              </td>
              <td>{{ priceArticle | currency: 'USD':'symbol':'1.0-0' }}</td>
              <td>{{ totalArticle | currency: 'USD':'symbol':'1.0-0' }}</td>
              <td>
                <div
                  class="btn btn-danger btn-sm"
                  (click)="addArticle(articleQuantity)">
                  <i class="feather ft-plus"></i>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-2">
        <ngx-qrcode
          elementType="img"
          value="https://plataforma.colsan.cl/admin/warehouseDetails/{{
            warehouse.key
          }}/{{ ticket.key }}"
          errorCorrectionLevel="L">
        </ngx-qrcode>
      </div>

      <div class="col-8 d-flex justify-content-center">
        <div class="d-flex flex-column align-items-center">
          <div
            class="text-center"
            *ngIf="
              !!ticket.supervisor &&
              (ticket.supervisor | doc | async) as supervisor
            ">
            <p class="mb-0">Autorizado por</p>
            <p class="text-muted mb-0">{{ supervisor?.name }}</p>
            <p class="text-muted">{{ supervisor?.email }}</p>
          </div>

          <div class="row">
            <div
              class="text-center mt-4 mr-2"
              *ngIf="
                !!ticket.createdBy &&
                (ticket.createdBy | doc | async) as createdBy
              ">
              <p class="text-muted mb-0">Creado por</p>
              <p class="text-muted">
                {{ createdBy?.name }} {{ createdBy?.surnames }}
              </p>
            </div>

            <div
              class="text-center mt-4 mt-2"
              *ngIf="!!ticket.user && (ticket.user | doc | async) as user">
              <p class="text-muted mb-0">Ingresado por</p>
              <p class="text-muted">{{ user?.name }} {{ user?.surnames }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div class="modal-footer border-0">
  <button
    *ngIf="hasPermission('confirm')"
    [disabled]="!areAllConfirm"
    class="btn btn-success cursor-pointer"
    (click)="confirmTicket()">
    Confirmar
  </button>

  <button
    *ngIf="ticket.status == EntryTicketStatus.DRAFT"
    class="btn btn-success cursor-pointer"
    (click)="confirmTicket()">
    Generar
  </button>

  <button
    *ngIf="
      ((permission == permissionEnum.SUPERVISOR ||
        permission == permissionEnum.ADMIN) &&
        ticket.status == EntryTicketStatus.GENERATED) ||
      (permission == permissionEnum.ADMIN &&
        ticket.status == EntryTicketStatus.CANCELLED)
    "
    class="btn btn-danger cursor-pointer"
    (click)="changeToDraft()">
    Pasar a borrador
  </button>

  <button
    *ngIf="
      (permission == permissionEnum.SUPERVISOR ||
        permission == permissionEnum.ADMIN) &&
      ticket.status == EntryTicketStatus.GENERATED
    "
    class="btn btn-success cursor-pointer"
    (click)="confirmTicket()">
    Autorizar
  </button>

  <button
    *ngIf="
      (permission == permissionEnum.SUPERVISOR ||
        permission == permissionEnum.ADMIN) &&
      ticket.status == EntryTicketStatus.AUTHORIZED
    "
    class="btn btn-dark cursor-pointer"
    (click)="cancelTicket()">
    Cancelar ticket
  </button>

  <button
    *ngIf="
      permission != permissionEnum.GROCER &&
      permission != permissionEnum.BUYER &&
      ticket.status == EntryTicketStatus.DRAFT
    "
    class="btn btn-danger cursor-pointer"
    (click)="discardTicket()">
    Descartar
  </button>

  <div
    class="btn btn-warning cursor-pointer"
    (click)="print()">Imprimir
  </div>
  <div
    class="btn btn-danger cursor-pointer"
    (click)="modal.hide()">Cerrar
  </div>
</div>
