<div class="gallery mb-1">
  <div>
    <label>Desde</label>
    <input class="form-control p-sm" type="date" [(ngModel)]="startDate" />
  </div>

  <div>
    <label>Hasta</label>
    <input class="form-control p-sm" type="date" [(ngModel)]="finalDate" />
  </div>

  <div class="card mb-0 align-self-end">
    <div class="card-content">
      <div class="card-body justify-content-center p-sm">
        <div class="checkbox">
          <label class="mb-0 text-center">
            <input type="checkbox" [(ngModel)]="statusNotPayed" />
            NO PAGADA
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-0 align-self-end">
    <div class="card-content">
      <div class="card-body justify-content-center p-sm">
        <div class="checkbox">
          <label class="mb-0 text-center">
            <input type="checkbox" [(ngModel)]="statusPayed" />
            PAGADO
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-0 align-self-end">
    <div class="card-content">
      <div class="card-body justify-content-center p-sm">
        <div class="checkbox">
          <label class="mb-0 text-center">
            <input type="checkbox" [(ngModel)]="isnClassified" />
            SIN CLASIFICAR
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-0 align-self-end">
    <div class="card-content">
      <div class="card-body justify-content-center p-sm">
        <div class="checkbox">
          <label class="mb-0 text-center">
            <input type="checkbox" [(ngModel)]="isClassified" />
            CLASIFICADAS
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-0 align-self-end">
    <div class="card-content">
      <div class="card-body justify-content-center p-sm">
        <div class="checkbox">
          <label class="mb-0 text-center">
            <input type="checkbox" [(ngModel)]="isExpirated" />
            VENCIDAS
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-0 align-self-end">
    <div class="card-content">
      <div class="card-body justify-content-center p-sm">
        <div class="checkbox">
          <label class="mb-0 text-center">
            <input type="checkbox" [(ngModel)]="isnExpirated" />
            NO VENCIDA
          </label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex mb-1">
  <ng-select
    [items]="providers"
    [searchable]="true"
    class="width-80-per align-self-end"
    bindLabel="provider"
    [(ngModel)]="providerSelected"
    placeholder="Buscar por proveedor...">
  </ng-select>

  <div class="d-flex align-items-center">
    <div>
      <button class="btn btn-danger ml-1" (click)="filterBills()">Buscar</button>
    </div>
    <div>
      <button class="btn btn-warning ml-1" (click)="resetBills()">
        Mostrar todos
      </button>
    </div>
  </div>
</div>

<app-datatable-pagination
  [rows]="billsFiltered"
  [config]="config"
  *ngIf="!isUploading"
  (rowSelected)="gotoOpenEditBill($event)">
  <app-datatable-column title="N. factura">
    <ng-template let-row #column>
      {{ row.documentNumber }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha de emisión">
    <ng-template let-row #column>
      {{ row.startDate | date: 'dd/MM/yyyy' }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha de pago">
    <ng-template let-row #column>
      <span
        [ngClass]="{
          'text-danger':
            (!row.isPaid && today > row.expectedPaymentDate) ||
            (!row.isPaid && todayMs > row.expectedPaymentDate),
          'text-info': row.isPaid
        }"
      >{{ row.expectedPaymentDate | date: 'dd/MM/yyyy' }}</span
      >
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="RUT">
    <ng-template let-row #column>
      {{ getRut(row.provider) }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Proveedor">
    <ng-template let-row #column>
      {{ getProvider(row.provider) }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Total">
    <ng-template let-row #column> {{ row.total  | clearCurrency : false |  swapCommasAndDots }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Centro de costo">
    <ng-template let-row #column>
      {{ showBillCostCenter(row) }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Estatus">
    <ng-template let-row #column>
      <div class="d-flex">
        <span
          class="badge badge-pill mr-2"
          [ngClass]="{
            'badge-success': row.isUsed,
            'badge-primary': !row.isUsed
          }"
          ngbTooltip="{{
            (row.isUsed && 'Clasificada') || (!row.isUsed && 'No clasificada')
          }}">
          <i
            [ngClass]="{
              'la-check': row.isUsed,
              'la-close': !row.isUsed
            }"
            class="la"></i>
        </span>
        <span
          class="badge badge-pill"
          [ngClass]="{
            'badge-info': row.isPaid,
            'badge-primary': !row.isPaid
          }"
          ngbTooltip="{{ row.isPaid ? 'Pagada' : 'No pagada' }}">
          <i class="la la-money"></i>
        </span>
      </div>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column
    title=""
    [isClickable]="false"
    [isVisiblePrint]="false"
    *ngIf="permission == permissionEnum.ADMIN">
    <ng-template let-row #column>
      <button
        class="btn btn-outline-danger"
        ngbTooltip="Abrir factura V1"
        (click)="gotoOpenEditBill(row, true)">
        <i class="la la-eye"></i>
      </button>
      <button
        class="btn btn-outline-danger"
        ngbTooltip="Eliminar"
        (click)="deleteBill(row.key)">
        <i class="la la-trash"></i>
      </button>
    </ng-template>
  </app-datatable-column>
</app-datatable-pagination>

<button
  class="btn btn-success btn-float-right add-articles-btn"
  app-tooltip="Agregar por excel"
  (click)="addToExcel()">
  <i class="la la-file-excel-o xls-icon"></i>
</button>

<app-add-float-button
  (clickEmmiter)="openAddBillModal()"></app-add-float-button>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
><p style="color: white">
  Cargando facturas...
</p></ngx-spinner
>
