import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { AlertService } from '../../../../../shared/template-services/alert.service';
import { JobService } from '../../../../../shared/services/job.service';
import { Job } from '../../../../interfaces/job';

@Component({
  selector: 'app-salary-range-form',
  templateUrl: './salary-range-form.component.html',
  styleUrls: ['./salary-range-form.component.css']
})
export class SalaryRangeFormComponent implements OnInit {
  rangesForm: FormGroup;
  positionKey: string;
  salaryRanges: any[];
  loading: boolean = false;

  constructor(private modal: BsModalService,
    private formBuilder: FormBuilder,
    private _position: JobService) {
    this.rangesForm = this.formBuilder.group({
      ranges: this.formBuilder.array([])
    });
  }

  ngOnInit(): void {
    this.salaryRanges.forEach(range => {
      this.setRange(range);
    });
  }


  get ranges(): FormArray {
    return this.rangesForm.get('ranges') as FormArray;
  }

  newRange(): FormGroup {
    return this.formBuilder.group({
      min: '',
      max: ''
    });
  }

  setRange(range: any) {
    this.ranges.push(this.formBuilder.group({
      min: range.min,
      max: range.max
    }));
  }

  addRange() {
    const lastInput = this.ranges.at(this.ranges.length - 1).value;
    if (lastInput.max) {
      this.setRange({
        min: lastInput.max,
        max: ''
      });
    } else {
      this.ranges.push(this.newRange());
    }
  }

  removeRange(index: number) {
    this.ranges.removeAt(index);
  }

  checkInvalidValues(formArray: FormArray): boolean {
    const length = formArray.length;

    if (length <= 2) {
      AlertService.toastError('Debe haber al menos 2 rangos de salario');
      return false;
    }
    if (!formArray.at(0).get('max').value) {
      AlertService.toastError('El limite superior del rango inicial debe estar definido');
      return false;
    }
    if (!formArray.at(length - 1).get('min').value) {
      AlertService.toastError('El limite inferior del rango final debe estar definido');
      return false;
    }

    for (let i = 1; i < length - 1; i++) {
      const rangeGroup = formArray.at(i) as FormGroup;
      const min = rangeGroup.get('min').value;
      const max = rangeGroup.get('max').value;
      if (!min || !max) {
        AlertService.toastError('No se puede ingresar un valor de 0 en los rangos intermedios de salario');
        return false;
      }
      if (min > max) {
        AlertService.toastError('El limite inferior no puede ser mayor al limite superior');
        return false;
      }
    }

    return true;
  }

  currencyFormat(value: number): string {
    return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(value);
  }

  async handleSubmit() {
    if (!this.checkInvalidValues(this.ranges)) return;

    const newRanges = this.ranges.value.map(range => ({
      ...range,
      label: `${
        range.min && range.max
          ? this.currencyFormat(range.min) + ' - ' + this.currencyFormat(range.max)
          : !range.min
            ? this.currencyFormat(0) + ' - ' + this.currencyFormat(range.max)
            : this.currencyFormat(range.min) + ' +'
      }`
    }));

    if (!await AlertService.confirm(`¿Está seguro que desea actualizar los rangos salariales ?`)) return;

    this.loading = true;
    await this._position.update(this.positionKey, { salaryRanges: newRanges } as Job);

    AlertService.toastSuccess('Rangos salariales actualizados');

    this.closeModal();
    this.loading = false;
  }

  closeModal() {
    this.modal.hide(919293);
  }
}
