<div class="font-weight-bold" *ngIf="providerInfo.provider | doc | async as provider">
  <div>PROVEEDOR: <span>{{provider?.name}}</span></div>

  <div>PROYECTO: <span>{{(providerInfo.project | doc | async)?.name}}</span></div>

  <div>R.U.T: <span>{{provider?.rut}}</span></div>

  <div>GIRO: <span>{{provider?.lineOfBusiness}}</span></div>

  <div>TELÉFONO: <span>{{provider?.phone}}</span></div>

  <div>DIRECCIÓN: <span>{{provider?.address}}</span></div>
</div>
