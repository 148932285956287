import { Component, Input, OnInit } from '@angular/core';
import { Applicant } from '../../../../interfaces/applicant';
import { DataTableConfig } from '../../../../../shared/interfaces/data-table-config';
import { ContractService } from '../../services/contract.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddContractComponent } from '../../modals/add-contract/add-contract.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.css']
})
export class ContractsComponent implements OnInit {
  @Input() applicant: Applicant;
  contracts$: Observable<any[]> = new Observable<any[]>();
  config: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron contratos',
    title: '',
    removeInfiniteScroll: true
  };

  constructor(private _contract: ContractService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.contracts$ = this._contract.getAll(this.applicant.key);
  }

  openAddContractModal() {
    this.modal.show(AddContractComponent, {
      initialState: {
        applicant: this.applicant
      },
      class: 'modal-lg'
    });
  }

}
