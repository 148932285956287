<div class="p-2">
  <h2>Actualizar bodega</h2>
  <img
    class="size"
    [ngClass]="editWarehouseForm.value.imageUrl ? 'sizeIfHavePhoto' : 'size'"
    src="{{
      !isValidPath()
        ? !!warehouse.imageUrl
          ? warehouse?.imageUrl
          : 'assets/images/img_default.png'
        : path
    }}"/>

  <form [formGroup]="editWarehouseForm" (ngSubmit)="submit()">
    <div class="row mt-2">
      <div class="col-md-6">
        <div class="form-group">
          <label>Nombre *</label>
          <input
            type="text"
            class="form-control"
            formControlName="name"
            placeholder=""
            [ngClass]="{'is-invalid': submitted && formControls.name.errors}"/>
          <small
            class="form-text text-muted danger invalid-feedback"
            *ngIf="submitted && formControls.name.errors">
            <div *ngIf="formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Descripción *</label>
          <input
            type="text"
            class="form-control"
            formControlName="description"
            placeholder=""
            [ngClass]="{
              'is-invalid': submitted && formControls.description.errors
            }"/>
          <small
            class="form-text text-muted danger invalid-feedback"
            *ngIf="submitted && formControls.description.errors">
            <div *ngIf="formControls.description.errors.required">
              La descripción es obligatorio
            </div>
          </small>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-6">
        <div class="form-group">
          <label>Ubicación *</label>
          <input
            type="text"
            class="form-control"
            formControlName="location"
            placeholder=""
            [ngClass]="{
              'is-invalid': submitted && formControls.location.errors
            }"/>
          <small
            class="form-text text-muted danger invalid-feedback"
            *ngIf="submitted && formControls.location.errors">
            <div *ngIf="formControls.location.errors.required">
              La ubicación es obligatoria
            </div>
          </small>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div class="custom-file">
            <ng-select
              formControlName="warehouseManager"
              [items]="supervisors"
              [multiple]="true"
              bindLabel="name"
              placeholder="Seleccione al encargado *">
              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <img height="15" width="15" [src]="item.imageUrl"/>
                {{ item.name }}
                <span class="ng-value-icon right" (click)="clear(item)">×</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <img height="15" width="15" [src]="item.imageUrl"/>
                {{ item.name }}
              </ng-template>
            </ng-select>
            <small
              class="text-danger"
              *ngIf="submitted && formControls.warehouseManager.errors">
              El campo es obligatorio
            </small>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-6">
        <div class="form-group">
          <label>Codigo *</label>
          <input
            type="text"
            class="form-control"
            formControlName="code"
            placeholder=""
            [ngClass]="{'is-invalid': submitted && formControls.code.errors}"/>
          <small
            class="form-text text-muted danger invalid-feedback"
            *ngIf="submitted && formControls.code.errors">
            <div *ngIf="formControls.code.errors.required">
              El codigo es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <div class="custom-file">
            <ng-select formControlName="icon"
                       [items]="icons"
                       placeholder="Seleccione un icono *">
              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <div class="icon-select">
                  <i class="{{item}} font-medium-5"></i>
                </div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <i class="{{item}} font-medium-5"></i>
              </ng-template>
            </ng-select>
            <small
              class="text-danger"
              *ngIf="submitted && formControls.icon.errors">
              El campo es obligatorio
            </small>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <div class="custom-file">
            <ng-select formControlName="bgColor"
                       [items]="bgColors"
                       placeholder="Seleccione color de bodega *">
              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <div [style.background-color]="item" class="bg-color-select"></div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div [style.background-color]="item" class="bg-color-select"></div>
              </ng-template>
            </ng-select>
            <small
              class="text-danger"
              *ngIf="submitted && formControls.bgColor.errors">
              El campo es obligatorio
            </small>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <div class="custom-file">
            <ng-select
              *ngIf="!!grocersAndBuyers"
              formControlName="grocer"
              [items]="grocersAndBuyers"
              bindLabel="name"
              placeholder="Seleccione al bodeguero *">
            </ng-select>
            <small
              class="text-danger"
              *ngIf="submitted && formControls.grocer.errors">
              El campo es obligatorio
            </small>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <div class="custom-file">
            <ng-select
              formControlName="buyer"
              [items]="grocersAndBuyers"
              bindLabel="name"
              placeholder="Seleccione al comprador *"
              required>
            </ng-select>
            <small
              class="text-danger"
              *ngIf="submitted && formControls.buyer.errors">
              El campo es obligatorio
            </small>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <div class="custom-file">
            <ng-select
              formControlName="users"
              [items]="users"
              bindLabel="name"
              placeholder="Lista blanca de usuarios"
              multiple="true"
              required>
            </ng-select>
            <small
              class="text-danger"
              *ngIf="submitted && formControls.buyer.errors">
              El campo es obligatorio
            </small>
          </div>
        </div>
      </div>

    </div>
    <div class="modal-footer border-0">
      <button class="btn btn-primary add-btn">Guardar</button>
      <div
        class="btn btn-danger cancel-btn cursor-pointer"
        (click)="modal.hide()">
        Cancelar
      </div>
    </div>
  </form>
</div>
