<div class="p-3 d-flex flex-column justify-content-center align-items-center w-100" #componentID>
  <h2>Reporte de tareas: {{user.name}}</h2>

  <div class="mt-4 d-flex justify-content-around w-100">
    <div>
      <h3 class="text-info">{{('0' + totalDone) | slice : -2}}</h3>
      <span>Tareas realizadas</span>
    </div>

    <div>
      <h3 class="text-danger">{{('0' + totalPending) | slice : -2}}</h3>
      <span>Tareas faltantes</span>
    </div>

    <div>
      <h3 [ngClass]="{
      'text-success': totalPercent >= 80,
      'text-danger': totalPercent < 80}">{{totalPercent.toFixed(0)}}%</h3>
      <span>Porcentaje de cumplimiento</span>
    </div>
  </div>

  <table class="table mt-5">
    <thead>
    <tr>
      <th>Tarea</th>
      <th>Progreso</th>
      <th>Expiración</th>
      <th>Finalización</th>
      <th>Puntos</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let task of tasks">
      <td><div class="badge badge-sm"
      [ngClass]="{
      'badge-secondary': task.status == 0,
      'badge-info': task.status == 1,
      'badge-success': task.status == 2
      }">{{taskStatus[task.status]}}</div> {{task.title}}</td>
      <td>
        <progressbar type="info" [value]="task.totalProgress">
          <span class="text-nowrap" style="font-size: 10px">{{task.totalProgress.toFixed(0)}}%</span>
        </progressbar>
      </td>
      <td>{{task.expiratedDate | date: 'dd/MM/yyyy'}}</td>
      <td>{{task.finishDate | date: 'dd/MM/yyyy'}}</td>
      <td>{{task.points}}</td>
    </tr>
    </tbody>
  </table>
</div>
