import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PurchaseOrder } from '../../interfaces/purchase-order';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PurchaseOrderService } from '../../../../../shared/services/purchase-order.service';
import { ProviderService } from '../../../../../shared/services/provider.service';
import { PurchaseStatus } from '../../../../enums/purchase-status.enum';
import { UserService } from '../../../../../shared/services/user.service';
import { LogsService } from '../../../../services/logs.service';
import { Observable, Subscription } from 'rxjs';
import { User } from '../../../../../shared/interfaces/user';
import { PurchaseStatusLabel } from '../../../../labels/purchase-status.label';
import { UploadFileStorageComponent } from '../../../../../shared/template-components/upload-file-storage/upload-file-storage.component';
import { UserType } from '../../../../enums/user-type.enum';
import { NotificationService } from '../../../../services/notification.service';

@Component({
  selector: 'app-purchase-order-modal',
  templateUrl: './purchase-order-modal.component.html',
  styleUrls: ['./purchase-order-modal.component.css']
})
export class PurchaseOrderModalComponent implements OnInit, OnDestroy {
  @ViewChild('labelImport', {static: false}) labelImport: ElementRef;
  @ViewChild('labelSignature', {static: false}) labelSignature: ElementRef;
  @ViewChild(UploadFileStorageComponent) filesStorage;
  submitted: boolean = false;
  purchaseOrder: PurchaseOrder = {key: ''} as PurchaseOrder;
  currentDate = new Date().getTime();
  providerForm: any;
  articlesPurchaseForm: any;
  deliveryDate: any;
  purchaseStatus = PurchaseStatus;
  permission: UserType;
  permissionEnum = UserType;
  logs$: Observable<any>;
  purchaseStatusLabel = PurchaseStatusLabel;
  isPrint: boolean = false;
  quotation = null;
  usersSubscription: Subscription = new Subscription();
  users: User[] = [];
  userTypeEnum = UserType;

  constructor(public modal: BsModalRef,
              public modalService: BsModalService,
              public _purchase: PurchaseOrderService,
              private _provider: ProviderService,
              public _user: UserService,
              private _log: LogsService,
              private _notification: NotificationService) {
  }

  ngOnInit(): void {
    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'COMPRAS'
    ).permission;
    this.usersSubscription = this._user.getUsers().subscribe((users) => {
      users = users.filter((user) => {
        if (!user.permissions) return false;
        let section = user.permissions.find((per) => per.section == 'COMPRAS');
        return (
          !!section &&
          (section.permission == this.userTypeEnum.SUPERVISOR ||
            section.permission == this.userTypeEnum.ADMIN)
        );
      });
      this.users = users;
    });
    if (!!this.purchaseOrder.key) this.logs$ = this._log.getAllPurchaseOrder(this.purchaseOrder.key);
    if (!!this.purchaseOrder.provider) {
      this.quotation = this.purchaseOrder.quotation;
      this.deliveryDate = this.purchaseOrder.deliveryDate;

      this.providerForm = {
        provider: this.purchaseOrder.provider,
        project: this.purchaseOrder.project
      };

      this.articlesPurchaseForm = {
        articles: this.purchaseOrder.articles,
        subtotal: this.purchaseOrder.subtotal,
        discount: this.purchaseOrder.discount,
        net: this.purchaseOrder.net,
        iva: this.purchaseOrder.iva,
        total: this.purchaseOrder.total,
        paymentType: this.purchaseOrder.paymentType,
        currency: this.purchaseOrder.currency
      };
    }
  }

  ngOnDestroy() {
    this.usersSubscription.unsubscribe();
  }

  print() {
    this.isPrint = true;
  }

  getStatusClass(status: number) {
    switch (status) {
      case PurchaseStatus.DRAFT:
        return 'text-dark';

      case PurchaseStatus.GENERATED:
        return 'text-primary';

      case PurchaseStatus.AUTHORIZED:
        return 'text-info';

      case PurchaseStatus.NOT_AUTHORIZED:
        return 'text-warning';

      case PurchaseStatus.INCOMPLETE:
        return 'text-warning';

      default:
        return 'text-success';
    }
  }

  async changePurchaseOrder(purchaseOrder) {
    this.purchaseOrder = purchaseOrder;
    if (
      !!purchaseOrder.status &&
      purchaseOrder.status == this.purchaseStatus.AUTHORIZED &&
      'createdBy' in purchaseOrder
    ) {
      let user = await this._user.getSpecificUser(purchaseOrder.createdBy.id);
      await this._notification.sendAuthorizedPurchaseOrderNotificationEmail(
        `${
          this._user.user.name
        } autorizó la orden de compra ${purchaseOrder.purchaseID.slice(0, -3)}`,
        `Compras: Orden de compra ${purchaseOrder.purchaseID.slice(0, -3)} autorizada`,
        purchaseOrder.key,
        [user.email]
      );
    }
    if (
      !!purchaseOrder.status &&
      purchaseOrder.status == this.purchaseStatus.GENERATED
    ) {
      let emails = [];
      if (this.users.length > 0) {
        emails = this.users.map((user) => user.email);
        await this._notification.sendPurchaseOrderNotificationEmail(
          `${
            this._user.user.name
          } generó la orden de compra ${purchaseOrder.purchaseID.slice(0, -3)}`,
          purchaseOrder?.description,
          this._provider.providers.find(
            (provider) => provider.key == purchaseOrder.provider.id
          )?.name,
          `$${Math.floor(purchaseOrder?.total)}`,
          purchaseOrder.key,
          `Compras: Orden de compra ${this.purchaseOrder.purchaseID.slice(0, -3)} generada`,
          emails
        );
      }
    }
  }
}
