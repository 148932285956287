<div class="p-4">
  <div class="form-group">
    <div class="text-bold-600 font-medium-2">
      Destinatarios
    </div>
    <ng-select [items]="[]"
               [addTag]="true"
               [(ngModel)]="recipients"
               [multiple]="true"
               placeholder="ejemplo: usuario@dominio.com">
    </ng-select>
  </div>

  <div class="form-group">
    <label>Comentario <span class="required">*</span></label>
    <textarea cols="30" rows="10" class="form-control" [(ngModel)]="comment"></textarea>
  </div>

  <div class="form-group">
    <label>Archivo a enviar *</label>
    <div class="custom-file">
      <input type="file" class="custom-file-input cursor-pointer"
             (change)="onFileChange($event.target.files)"
             accept="*">
      <label class="custom-file-label" #labelImport>Subir archivo</label>
    </div>
  </div>

  <button class="btn btn-success" (click)="submit()">Enviar</button>
</div>
