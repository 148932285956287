<div class="row mb-4">
  <div class="cart-main-wrapper pt-60 col-6">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2>Contratados</h2>
          <ngx-charts-advanced-pie-chart
            *ngIf="loadGraph"
            [scheme]="colorScheme"
            [results]="graphicProject"
            [gradient]="gradient"
          >
          </ngx-charts-advanced-pie-chart>
        </div>
      </div>
    </div>
  </div>

  <div class="col-6">
    <h2>Estados de los postulantes</h2>
    <div class="row">
      <div
        class="col-4"
        *ngFor="let status of graphicStatus; let index  = index"
        (click)="redirectToApplicants(status.name)"
      >
        <div class="card cursor-pointer pull-up" style="background-color: {{colorScheme.domain[index]}}">
          <div class="card-content">
            <div class="card-body">
              <div class="media d-flex">
                <div class="media-body text-left">
                  <h6 class="text-center text-white text-capitalize">{{status.name}} </h6>
                  <h3 class="text-white text-center">{{status.value}}
                    ({{(status.value * 100 / applicants.length).toFixed(1)}}%)</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-metrics-tables
  [graphicCity]="graphicCity"
  [lastFiveApplicants]="lastFiveApplicants"
></app-metrics-tables>

<h3 class="mt-4">Filtrar por estado del postulante</h3>
<div
  class="d-flex gap-2"
  style="gap: 10px"
>
  <div
    (click)="resetGraphics()"
    class="badge badge-primary badge-lg text-capitalize cursor-pointer"
  >
    Todo
  </div>
  <div
    (click)="filterByWeek()"
    class="badge badge-warning badge-lg text-capitalize cursor-pointer"
  >
    Esta semana
  </div>
  <div
    (click)="filterByMonth()"
    class="badge badge-info badge-lg text-capitalize cursor-pointer"
  >
    Este mes
  </div>
  <div
    (click)="filterByYear()"
    class="badge badge-danger badge-lg text-capitalize cursor-pointer"
  >
    Este año
  </div>
  <div
    *ngFor="let status of applicantStatus; let index = index"
    (click)="filterByStatus(status)"
    [style.backgroundColor]="colorScheme.domain[index]"
    class="badge badge-lg text-capitalize cursor-pointer"
  >{{status}}</div>
</div>

<div class="row mt-4">
  <div class="col-12 mt-3" style="height: 250px; margin-bottom: 0px">
    <h2>Postulantes por Cargos</h2>

    <ngx-charts-advanced-pie-chart
      *ngIf="loadGraph"
      [scheme]="colorScheme"
      [results]="graphicPosition"
      [gradient]="gradient"
    >
    </ngx-charts-advanced-pie-chart>
  </div>

  <div class="col-12 mt-3" style="height: 250px; margin-bottom: 0px">
    <h2>Postulantes por Comunas</h2>
    <ngx-charts-advanced-pie-chart
      *ngIf="loadGraph"
      [scheme]="colorScheme"
      [results]="graphicCity"
      [gradient]="gradient"
    >
    </ngx-charts-advanced-pie-chart>
  </div>

  <div class="col-12 mt-3" style="height: 250px; margin-bottom: 0px">
    <h2>Postulantes por Regiones</h2>
    <ngx-charts-advanced-pie-chart
      *ngIf="loadGraph"
      [scheme]="colorScheme"
      [results]="graphicState"
      [gradient]="gradient"
    >
    </ngx-charts-advanced-pie-chart>
  </div>

  <div class="col-12 mt-3">
    <h2>Lista de cargos</h2>
    <app-salary-range></app-salary-range>
  </div>
</div>
