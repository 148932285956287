import {Component, OnInit} from '@angular/core';
import {ProjectService} from '../../../shared/services/project.service';
import {BillService} from '../../../shared/services/bill.service';
import {Bill} from '../../interfaces/bill';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {Observable, of} from 'rxjs';
import {BillsComponent} from '../../pages/bills/bills.component';
import {take} from 'rxjs/operators';
import {PurchaseOrderService} from '../../../shared/services/purchase-order.service';
import {PurchaseOrder} from '../../pages/purchase-orders/interfaces/purchase-order';
import _ from 'lodash';

@Component({
  selector: 'app-cost-list',
  templateUrl: './cost-list.component.html',
  styleUrls: ['./cost-list.component.css']
})
export class CostListComponent implements OnInit {
  category: any;
  projectKey: string;
  bills$: Observable<Bill[]>;
  bills: Bill[] = [];
  purchaseOrders: PurchaseOrder[] = [];
  dataTableConfig: DataTableConfig = {
    title: 'Facturas',
    notFoundText: 'No se encontraron facturas relacionadas a esta categoría',
    hasSearch: false,
    propTotal: 'total'
  };

  constructor(private _project: ProjectService,
              public _bill: BillService,
              private _purchaseOrder: PurchaseOrderService) {
  }

  async ngOnInit(): Promise<void> {
    this.bills = _.unionBy(await this._bill.getByProjectCategory(this.category.key, this.projectKey), 'documentNumber')
    this.bills$ = of(this.bills);
  }
}
