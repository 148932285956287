<app-datatable [rows$]="accountabilities$" [config]="config">
  <app-datatable-column title="Título">
    <ng-template let-row #column>
      {{ row.title }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Estatus">
    <ng-template let-row #column>
      <span
        class="badge mx-1"
        [ngClass]="accountabilityStatusClasses[row?.status]">
        {{ accountabilityStatusLabel[row?.status] }}
      </span>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Monto recibido">
    <ng-template let-row #column>
      {{ row?.fundToRender.receivedAmount }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Total de gastos">
    <ng-template let-row #column>
      {{ row?.receiptsTotalAmount }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Saldo final">
    <ng-template let-row #column>
      {{ row?.fundToRender.receivedAmount - row?.receiptsTotalAmount }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Opciones">
    <ng-template let-row #column>
      <div class="d-flex">
        <button
          class="btn btn-success mr-1"
          (click)="authorize(row)"
          ngbTooltip="Autorizar">
          <i class="la la-check"></i>
        </button>
        <button
          class="btn btn-danger mr-1"
          (click)="reject(row)"
          ngbTooltip="Rechazar">
          <i class="la la-times"></i>
        </button>
      </div>
    </ng-template>
  </app-datatable-column>
</app-datatable>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
  ><p style="color: white">Guardando...</p></ngx-spinner
>
