<div class="p-2">
  <h2>{{isUpdate ? 'Actualizando unidad' : 'Importar unidad'}}</h2>

  <form [formGroup]="unitForm" (ngSubmit)="submit()">
    <div class="row mt-2">

      <div class="col-md-12 col-12">
        <div class="form-group">
          <label>Nombre *</label>
          <input type="text" class="form-control" formControlName="name"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.name.errors }"/>
          <div *ngIf="submitted && formControls.name.errors">
            El nombre es obligatorio
          </div>
        </div>
      </div>

<!--      <div class="col-md-12 col-12">-->
<!--        <div class="form-group">-->
<!--          <label>Código</label>-->
<!--          <input type="text" class="form-control" formControlName="code"-->
<!--                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.code.errors }"/>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn">Aceptar</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide(50)">Cancelar</div>
    </div>
  </form>
</div>
