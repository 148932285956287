<div class="row mt-2">
  <div class="col-xl-12 col-lg-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div (change)="changeStatusFilter()">
            <div class="d-inline-block custom-control custom-radio mr-1">
              <input type="radio" class="custom-control-input bg-success" [(ngModel)]="myRadio"
                     [value]="purchaseStatus.ACTIVE" name="colorRadio" id="colorRadio1">
              <label class="custom-control-label" for="colorRadio1">ACTIVO</label>
            </div>
            <div class="d-inline-block custom-control custom-radio mr-1">
              <input type="radio" class="custom-control-input bg-primary" [(ngModel)]="myRadio"
                     [value]="purchaseStatus.INACTIVE" name="colorRadio" id="colorRadio2">
              <label class="custom-control-label" for="colorRadio2">INACTIVO</label>
            </div>
            <div class="d-inline-block custom-control custom-radio mr-1">
              <input type="radio" class="custom-control-input bg-warning" [(ngModel)]="myRadio"
                     [value]="99" name="colorRadio" id="all">
              <label class="custom-control-label" for="all">TODOS</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-datatable [rows$]="projectsFiltered$"
               [config]="config"
               (rowSelected)="goToCostCenterDetails($event.key)">

  <app-datatable-column title="Nombre">
    <ng-template let-row #column>
      {{row?.name}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha inicio">
    <ng-template let-row #column>
      {{row?.startDate}}
    </ng-template>
  </app-datatable-column>


  <app-datatable-column title="Fecha Término">
    <ng-template let-row #column>
      {{row?.endDate ? row.endDate : 'No hay fecha'}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Estado">
    <ng-template let-row #column>
      <div class="badge" [ngClass]="{'badge-success': row.status == 0, 'badge-danger' : row.status == 1}">{{statusLabel[row?.status]}}</div>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Creador" [isVisiblePrint]="false">
    <ng-template let-row #column>
      <ul class="list-unstyled users-list m-0">
        <li class="avatar avatar-sm pull-up">
          <img class="media-object rounded-circle"
               container="body"
               [ngbTooltip]="(row?.createdBy | doc | async)?.name"
               [src]="!!(row?.createdBy | doc | async)?.imageUrl ? (row?.createdBy | doc | async)?.imageUrl : '/assets/images/default.png'" alt="Supervisor">
        </li>
      </ul>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Hora creación">
    <ng-template let-row #column>
      {{row?.creationTime | date: ' HH:mm'}}
    </ng-template>
  </app-datatable-column>

</app-datatable>

<app-add-float-button (clickEmmiter)="openAddCostCenterModal()"></app-add-float-button>

