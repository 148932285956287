import {Component, OnInit} from '@angular/core';
import {Category} from '../../../shared/interfaces/category';
import {Observable} from 'rxjs';
import {BsModalService} from 'ngx-bootstrap/modal';
import {CategoryService} from '../../../shared/services/category.service';
import {AddCategoryComponent} from '../../modals/add-category/add-category.component';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {AlertService} from '../../../shared/template-services/alert.service';
import {ObjectService} from '../../../shared/template-services/object.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  categories$: Observable<Category[]>;
  config: DataTableConfig = {
    notFoundText: 'No se encontraron categorías',
    title: 'Lista de categorías',
    exportCallback: this.decoratedCategoriesToExport.bind(this),
    excelFileName: 'Categorías',
    hasSearch: false
  };

  constructor(private _category: CategoryService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.loadCategories();
  }

  loadCategories() {
    this.categories$ = this._category.getAll();
  }

  openAddCategoryModal() {
    this.modal.show(AddCategoryComponent);
  }

  async delete(key: string) {
    if (await AlertService.confirm('¿Estás seguro que deseas eliminar esta categoría?')) {
      this._category.delete(key);
      AlertService.toastSuccess('Se ha eliminado con éxito');
    }
  }

  decoratedCategoriesToExport(categories: Category[]) {
    return ObjectService.replaceUndefined(categories.map(category => ({
      name: category.name,
    })));
  }
}
