import {Component, OnInit, ViewChild} from '@angular/core';
import {DocumentReference} from '@angular/fire/firestore';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {UserService} from 'src/app/shared/services/user.service';
import {UploadFileStorageComponent} from 'src/app/shared/template-components/upload-file-storage/upload-file-storage.component';
import {AlertService} from 'src/app/shared/template-services/alert.service';
import {AccountabilityReceiptStatusEnum} from '../../enums/accountability-receipt-status.enum';
import {UserType} from '../../../../enums/user-type.enum';
import {AccountabilityReceipt} from '../../interfaces/accountability-receipt';
import {BreakdownAmountCategory} from '../../interfaces/breakdown-amount-catengory';
import {Receipt} from '../../interfaces/receipt';
import {BreakdownAmountCategoryService} from '../../services/breakdown-amount-category.service';
import {ReceiptService} from '../../services/receipt.service';
import {take} from 'rxjs/internal/operators/take';
import {Router} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-new-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit {
  @ViewChild(UploadFileStorageComponent) filesStorage: UploadFileStorageComponent;
  isEdit: boolean;
  receipt: AccountabilityReceipt;
  receiptForm: FormGroup;
  submitted: boolean;
  categories: BreakdownAmountCategory[] = [];
  isImageLoaded: boolean;
  receiptKey: string;
  permission: any;
  permissionEnum = UserType;
  accountabilityReceiptStatus = AccountabilityReceiptStatusEnum;
  isModal: boolean;
  newReceipt: any;
  path: string = '';

  constructor(public modal: BsModalService,
              private _receipt: ReceiptService,
              private formBuilder: FormBuilder,
              private spinner: NgxSpinnerService,
              private _user: UserService,
              private _breakdownAmountCategory: BreakdownAmountCategoryService,
              private router: Router) {
    this.createReceiptForm();
  }

  async ngOnInit() {
    await this.loadBreakdownAmountsCategories();
    this.permission = this._user.getPermission('FONDOS POR RENDIR');
    if (this.isEdit) {
      this.receiptKey = this.receipt.key;
      delete this.receipt['key'];
      this.path = this.receipt.photoUrl;
      this.receiptForm.patchValue(this.receipt);

      this.receiptForm.patchValue({
        category: this.categories.find(
          (category) =>
            category.key ==
            ((<DocumentReference>this.receipt.category).id ??
              (<any>this.receipt.category).key)
        )
      });
    }
    if (
      this.permission == this.permissionEnum.SUPERVISOR ||
      this.permission == this.permissionEnum.ADMIN
    ) {
      this.receiptForm.disable();
    }
  }

  get formControls() {
    return this.receiptForm.controls;
  }

  async loadBreakdownAmountsCategories() {
    this.categories = await this._breakdownAmountCategory
      .getAll()
      .pipe(take(1))
      .toPromise();
  }

  createReceiptForm() {
    this.receiptForm = this.formBuilder.group({
      amount: [0, Validators.required],
      category: [null, Validators.required],
      description: ['', Validators.required],
      photoUrl: [''],
      status: [this.accountabilityReceiptStatus.PENDING]
    });
  }

  async submit() {
    this.submitted = true;

    if (!this.receiptForm.valid) {
      return;
    }

    if (!this.isEdit && !this.isImageLoaded) {
      return AlertService.toastError(
        'Es necesario subir una foto del comprobante'
      );
    }

    if (
      !(await AlertService.confirm(
        `Guardar datos`,
        '¿Está seguro de que desea guardar los datos?',
        'Guardar'
      ))
    ) {
      return;
    }

    this.spinner.show();

    this.receiptForm.patchValue({
      category: this.getCategoryReference(this.receiptForm.value.category)
    });

    if (this.isImageLoaded) {
      await this.uploadImage();
    }

    if (this.isEdit) {
      const status =
        this.receipt.status == this.accountabilityReceiptStatus.CORRECTION
          ? this.accountabilityReceiptStatus.PENDING
          : this.receipt.status;

      this._receipt.updateReceipt(this.receiptKey, {
        ...this.receiptForm.value,
        status
      });
    } else {
      const userRef = this._user.getReference(this._user.user.key);

      const newReceipt = await this._receipt.add({
        ...this.receiptForm.value,
        user: userRef
      });

      if (this.isModal) {
        this.newReceipt = newReceipt;
      }
    }

    this.spinner.hide();
    AlertService.toastSuccess(`Los datos se han guardado correctamente`);
    if (this.isEdit || this.isModal) {
      return this.modal.hide();
    } else {
      this.router.navigateByUrl('admin/receipts');
    }
  }

  setIsImageLoaded() {
    this.isImageLoaded = true;
  }

  getCategoryReference(category) {
    return this._receipt.getReference(
      `breakdownAmountCategories/${category.key}`
    );
  }

  async uploadImage() {
    const url = await this.filesStorage.uploadDocument(
      'accountability-receipts',
      new Date().getTime()
    );
    if (!!url) {
      this.receiptForm.patchValue({[this.filesStorage.controlName]: url});
    }
  }

  async acceptReceipt() {
    if (this.receiptForm.invalid) {
      return AlertService.toastError('Revise los campos');
    }
    if (
      !(await AlertService.confirm(
        'Aceptar comprobante',
        `¿Estás seguro de aceptar este comprobante?`
      ))
    ) {
      return;
    }
    this._receipt.updateReceipt(this.receiptKey, {
      status: AccountabilityReceiptStatusEnum.ACCEPTED
    } as Receipt);
    AlertService.toastSuccess(`El comprobante ha sido aceptado correctamente`);
  }

  async rejectReceipt() {
    if (
      !(await AlertService.confirm(
        'Rechazar comprobante',
        `¿Estás seguro de rechazar este comprobante?`
      ))
    ) {
      return;
    }
    this._receipt.updateReceipt(this.receiptKey, {
      status: AccountabilityReceiptStatusEnum.REJECTED
    } as Receipt);
    AlertService.toastSuccess(`El comprobante ha sido rechazado correctamente`);
  }

  cancel() {
    if (this.isEdit || this.isModal) return this.modal.hide();
    this.router.navigateByUrl('admin/receipts');
  }

  clickButtonInChild() {
    this.filesStorage.inputFile.nativeElement.click();
  }

  setPath(path: string) {
    this.path = path;
  }

  validateAttachImageButton() {
    return (
      !this.isEdit ||
      (!!this.isEdit &&
        (this.receipt.status == this.accountabilityReceiptStatus.PENDING ||
          this.receipt.status == this.accountabilityReceiptStatus.CORRECTION))
    );
  }

  async deleteReceipt() {
    if (await AlertService.confirm('Eliminar comprobante', '¿Estás seguro de eliminar este comprobante?', 'Eliminar')) {
      this._receipt.update(`receipts/${this.receiptKey}`, {
        updatedAt: new Date().getTime(),
        trash: true
      });
      AlertService.toastSuccess(`El comprobante ha sido eliminado correctamente`);
      this.modal.hide();
    }
  }
}
