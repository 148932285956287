import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {PrintPurchaseService} from '../../../../shared/services/print-purchase.service';

@Component({
  selector: 'app-kanban-report',
  templateUrl: './kanban-report.component.html',
  styleUrls: ['./kanban-report.component.css']
})
export class KanbanReportComponent implements OnInit, AfterViewInit {
  @ViewChild('componentID') componentID: ElementRef;
  tasks: any;
  user: any;
  totalPercent: number = 0;
  totalDone: number = 0;
  totalPending: number = 0;
  taskStatus = {
    0: 'POR HACER',
    1: 'EN PROCESO',
    2: 'HECHO'
  }

  constructor(private modal: BsModalRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.modal.hide();
    }, 1000);
    this.print();
  }

  print() {
    PrintPurchaseService.print(this.componentID.nativeElement);
  }

}
