import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { FirebaseDataService } from 'src/app/shared/template-services/firebase-data.service';
import { Sale } from '../interfaces/sale';

@Injectable({
  providedIn: 'root'
})
export class SaleService extends FirebaseDataService {

  constructor(public _db: AngularFirestore,
              private _storage: AngularFireStorage) {
    super(_db, _storage);
  }

  getAll(salesBoardKey: string): Observable<Sale[]> {
    return this.colWithIds$(`salesBoards/${salesBoardKey}/sales`, ref => ref.where('trash', '==', false));
  }

  async add(salesBoardKey: string, sale: Sale) {
    sale['createdAt'] = new Date().getTime();
    return await this.db.collection(`salesBoards/${salesBoardKey}/sales`).add(sale);
  }

  updateSale(salesBoardKey: string, saleKey: string, sale: Sale) {
    sale['updatedAt'] = new Date().getTime();
    this.update(`salesBoards/${salesBoardKey}/sales/${saleKey}`, sale);
  }

  delete(salesBoardKey: string, saleKey: string) {
    this.update(`salesBoards/${salesBoardKey}/sales/${saleKey}`, {trash: true});
  }

  getAllComments(salesBoardKey: string, saleKey: string) {
    return this.colWithIds$(`salesBoards/${salesBoardKey}/sales/${saleKey}/comments`)
  }

  addComment(boardKey: string, saleKey: string, comment) {
    return this._db.collection(`salesBoards/${boardKey}/sales/${saleKey}/comments`).add(comment);
  }

  setLog(salesBoardKey: string, saleKey: string, log) {
    log['createdAt'] = new Date().getTime();
    log['trash'] = false;
    return this.db.collection(`salesBoards/${salesBoardKey}/sales/${saleKey}/logs`).add(log);
  }
}
