import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ProviderArticle} from '../interfaces/provider-article';
import {ReplaySubject} from 'rxjs';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {AngularFireStorage} from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class ProviderArticleService extends FirebaseDataService {
  public providersArticles: any[];
  providersArticles$: ReplaySubject<any[]> = new ReplaySubject<any[]>();

  constructor(private _db: AngularFirestore) {
    super(_db, null);
  }

  set(providerArticleKey: string, providerArticle: ProviderArticle): void {
    this.doc(`providersArticles/${providerArticleKey}`).set(providerArticle, {merge: true});
  }

  getAll() {
    if (!this.providersArticles) {
      this.providersArticles = [];
      this.colWithIds$('providersArticles', ref => ref
        .where('trash', '==', false)).subscribe(data => {
        this.providersArticles$.next(data);
        this.providersArticles = data;
      });
    }

    return this.providersArticles$;
  }

  async update(providerArticleKey: string, providerArticle: ProviderArticle): Promise<void> {
    return await this.doc<ProviderArticle>(`providersArticles/${providerArticleKey}`).update(providerArticle);
  }
}
