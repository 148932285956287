import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ProjectService} from '../../../shared/services/project.service';
import {Router} from '@angular/router';
import {Observable, of, Subscription} from 'rxjs';
import {Project} from '../../interfaces/project';
import {AddProjectComponent} from '../../modals/add-project/add-project.component';
import {ProjectStatusLabel} from '../../labels/projectStatusLabel';
import {map, take} from 'rxjs/operators';
import {ProjectStatus} from '../../enums/project-status.enum';
import {CostCenterType} from '../../enums/cost-center-type.enum';
import * as _ from 'lodash';
import * as moment from 'moment';
import {UserType} from '../../enums/user-type.enum';
import {UserService} from '../../../shared/services/user.service';
import {Client} from '../../interfaces/client';
import {ClientService} from '../../services/client.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit, OnDestroy {
  projects$: Observable<Project[]>;
  projectsSubscription: Subscription = new Subscription();
  config: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron proyectos',
    title: 'Proyectos'
  };
  statusLabel = ProjectStatusLabel;
  status: any = ProjectStatus.ACTIVE;
  filter: any;
  projectStatusEnum = ProjectStatus;
  projectsFiltered$: Observable<Project[]>;
  purchaseStatus = ProjectStatus;
  userPermission: any;
  userTypeEnum = UserType;
  clients: Client[] = [];
  totalSaleValue: number = 0;

  constructor(private modal: BsModalService,
              private _project: ProjectService,
              private router: Router,
              private _user: UserService,
              private _client: ClientService,
              private db: FirebaseDataService) {
  }

  ngOnInit(): void {
    this.userPermission = this._user.user.permissions.find(permission => permission.section == 'PROYECTOS').permission;
    this.loadClients();
    this.loadProjects();
  }

  ngOnDestroy() {
    this.projectsSubscription.unsubscribe();
  }

  async loadClients() {
    this.clients = await this._client.getAll().pipe(take(1)).toPromise();
  }

  loadProjects() {
    this.projectsSubscription = this._project.getAll().subscribe(projects => {
      projects = projects.filter(project => project.type == CostCenterType.PROJECT);
      projects = projects.map((project: any) => {
        let client;
        if (!!project.client && !!project.client.id) {
          client = this.clients.find(client => client.key == project.client.id);
        } else if (!!project.client && !!project.client.key) {
          client = this.clients.find(client => client.key == project.client.key);
        }
        return ({
          ...project,
          client
        });
      });
      this.calculateTotalSaleValue(projects);
      projects = _.orderBy(projects, data => data.code.toLowerCase(), 'desc');
      this.projectsFiltered$ = this.projects$ = of(projects);
      this.changeStatusFilter();
    });
  }

  gotoProjectDetails(key: string) {
    this.router.navigateByUrl(`admin/projectDetails/${key}`);
  }

  openAddProjectModal() {
    this.modal.show(AddProjectComponent);
  }

  changeStatusFilter() {
    if (this.status == 99) return this.projectsFiltered$ = this.projects$;
    this.filter = null;

    switch (this.status) {
      case ProjectStatus.ACTIVE:
        this.filter = {name: 'ACTIVOS', status: this.projectStatusEnum.ACTIVE};
        break;

      case ProjectStatus.INACTIVE:
        this.filter = {name: 'INACTIVOS', status: this.projectStatusEnum.INACTIVE};
        break;

      case ProjectStatus.EXPIRATED:
        this.filter = {name: this.statusLabel[ProjectStatus.EXPIRATED], status: this.projectStatusEnum.EXPIRATED};
        break;
    }

    this.filterPurchases();
  }

  private filterPurchases() {
    this.config.notFoundText = `No se encontraron proyectos ${this.filter.name}`;

    this.projectsFiltered$ = this.projects$.pipe(map(project => project.filter(project => {
      return project.status == this.filter.status && project.type == CostCenterType.PROJECT;
    })));
  }

  calculateElapsedDays(project: Project, isExcecutionDaysField: boolean) {
    let today = new Date().getTime();
    if (project.status == this.projectStatusEnum.ACTIVE) {
      if (!project.activeLateEndDate && isExcecutionDaysField) {
        return moment(new Date(today)).diff(project.startDate, 'days');
      }
      if (!!project.activeLateEndDate && !isExcecutionDaysField) {
        return moment(new Date(today)).diff(project.activeLateEndDate, 'days');
      }
    } else if (project.status == this.projectStatusEnum.EXPIRATED) {
      return 'Ha expirado';
    } else return '';
  }

  validateEndDate(project) {
    if (!!project.endDate && !project.activeLateEndDate) {
      return project.endDate;
    } else if (!!project.activeLateEndDate) {
      return project.activeLateEndDate;
    } else return 'No hay fecha';
  }

  calculateTotalSaleValue(projects: Project[]) {
    let total
      if (!!projects && projects.length > 0) {
        total = projects.filter(project => !!project.saleValue).reduce((acc, el) => el.saleValue + acc, 0);
      }
    if (!!total) this.totalSaleValue = total;
  }

  async changeProjectStatus(project: Project) {
    if (!await AlertService.confirm(`¿Estás seguro de que deseas pasar el proyecto a ${project.status == this.projectStatusEnum.ACTIVE ? 'inactivo' : 'activo'}`)) return;
    if (project.status == this.projectStatusEnum.ACTIVE) {
      await this.db.update(`projects/${project.key}`, {status: this.projectStatusEnum.INACTIVE});
    } else if (project.status == this.projectStatusEnum.INACTIVE){
      await this.db.update(`projects/${project.key}`, {status: this.projectStatusEnum.ACTIVE});
    }
    AlertService.toastSuccess('El estatus del proyecto ha sido cambiado correctamente');
  }

  async delete(project: Project) {
    if (!await AlertService.confirm('¿Estás seguro de que deseas eliminar este proyecto?')) return;
    await this._project.delete(project.key);
    AlertService.toastSuccess('El proyecto ha sido eliminado correctamente');
  }
}
