import { Pipe, PipeTransform } from '@angular/core';
import {KanbanStatusLabel} from '../pages/kanban/labels/kanban-status.label'

@Pipe({
  name: 'taskStatus'
})
export class TaskStatusPipe implements PipeTransform {
  taskStatusLabel = KanbanStatusLabel

  transform(value: number): unknown {
    return this.taskStatusLabel[value];
  }
}
