import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {Warehouse} from '../../admin/pages/warehouses/interfaces/warehouse';
import {Observable} from 'rxjs';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {AngularFireStorage} from '@angular/fire/storage';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {
  id: string;

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore,
              private storage: AngularFireStorage) {
  }

  getAll(): Observable<Warehouse[]> {
    return this.db.colWithIds$<Warehouse>('warehouses', ref => ref
      .where('trash', '==', false));
  }

  get(warehouseKey: string): Observable<Warehouse> {
    return this.db.docWithId$(`warehouses/${warehouseKey}`);
  }

  add(warehouse: Warehouse) {
    this.afs.collection('warehouses').add(warehouse);
  }

  getReference(warehouseKey: string): DocumentReference {
    return this.afs.doc(`warehouses/${warehouseKey}`).ref;
  }

  update(warehouseKey: string, warehouse: Warehouse): Promise<void> {
    return this.afs.doc<Warehouse>(`warehouses/${warehouseKey}`).update(warehouse);
  }

  delete(warehouseKey: string): Promise<void> {
    return this.afs.doc<Warehouse>(`warehouses/${warehouseKey}`).update({trash: true});
  }

  async uploadPicture(warehousePhoto, warehouseKey) {
    if (warehouseKey == null) {
      this.id = this.afs.createId();
    } else {
      this.id = warehouseKey;
    }
    const uploadRef = this.getStorageRef(this.id);
    await uploadRef.put(warehousePhoto);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadImage(warehousePhoto, this.id);

    return url;
  }

  getStorageRef(id: string) {
    return this.storage.ref(`warehouse/${id}/warehousePicture.jpeg`);
  }

  uploadImage(data, id) {
    return this.storage.upload(`warehouse/${id}/warehousePicture.jpeg`, data);
  }
}
