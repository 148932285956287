import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) {
  }

  sendReport(report: any) {
    return this.http.post(`${environment.apiReportUrlV1}/reports/${environment.projectKey}`, report);
  }
}
