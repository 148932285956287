<div class="modal-header">
  <h4 class="modal-title pull-left">Nuevo cliente</h4>
  <button type="button" class="pull-right btn" (click)="modal.hide()">
    <i class="feather ft-x"></i>
  </button>
</div>
<div class="container">
  <aw-wizard #wizard navBarLayout="large-filled-symbols">
    <aw-wizard-step [stepTitle]="'Contacto'" [navigationSymbol]="{ symbol: '1'}">
      <form [formGroup]="contactDataForm">
        <div class="mt-2">
          <div class="form-group">
            <label>Nombre *</label>
            <input type="text" class="form-control" formControlName="nameContact"
                   placeholder="Introduzca nombre"
                   [ngClass]="{ 'is-invalid': contactDataFormControls.nameContact.touched && contactDataFormControls.nameContact.errors }"/>
            <small class="invalid-feedback">
              Campo obligatorio
            </small>
          </div>
        </div>
        <div class="mt-2">
          <div class="form-group">
            <label>Cargo *</label>
            <input type="text" class="form-control" formControlName="positionContact"
                   placeholder="Introduzca el cargo"
                   [ngClass]="{ 'is-invalid': contactDataFormControls.positionContact.touched && contactDataFormControls.positionContact.errors }"/>
            <small class="invalid-feedback">
              Campo obligatorio
            </small>
          </div>
        </div>
        <div class="mt-2">
          <div class="form-group">
            <label>Teléfono *</label>
            <input type="number" class="form-control" formControlName="phoneContact"
                   placeholder="Ejemplo: 2345678901"
                   [ngClass]="{ 'is-invalid': contactDataFormControls.phoneContact.touched && contactDataFormControls.phoneContact.errors }"/>
            <small class="invalid-feedback">
              Campo obligatorio
            </small>
          </div>
        </div>
        <div class="mt-2">
          <div class="form-group">
            <label>Correo *</label>
            <input type="text" class="form-control" formControlName="emailContact"
                   placeholder="Ejemplo: ejemplo@ej.com"
                   [ngClass]="{ 'is-invalid': contactDataFormControls.emailContact.touched && contactDataFormControls.emailContact.errors }"/>
            <small class="invalid-feedback">
              Campo obligatorio
            </small>
          </div>
        </div>
        <div class="mt-2">
          <div class="form-group">
            <label>Comuna *</label>
            <input type="text" class="form-control" formControlName="communeContact"
                   placeholder="Introduzca la comuna"
                   [ngClass]="{ 'is-invalid': contactDataFormControls.communeContact.touched && contactDataFormControls.communeContact.errors }"/>
            <small class="invalid-feedback">
              Campo obligatorio
            </small>
          </div>
        </div>
        <div class="form-group text-center">
          <button type="button" class="btn btn-info btn-raised"
                  [disabled]="!contactDataForm.valid" awNextStep>
            Siguiente
            <span style="margin-left:10px;">
              <i class="feather ft-chevron-right"></i>
            </span>
          </button>
        </div>
      </form>
    </aw-wizard-step>
    <aw-wizard-step [stepTitle]="'Ficha'" [navigationSymbol]="{ symbol: '1'}">
      <form [formGroup]="fileDataForm">
        <div class="mt-2">
          <div class="form-group">
            <label>RUT *</label>
            <input type="text" class="form-control" formControlName="rut"
                   placeholder=""
                   [ngClass]="{ 'is-invalid': fileDataFormControls.rut.touched  && fileDataFormControls.rut.errors }"/>
            <small class="invalid-feedback">
              Ingrese un rut válido
            </small>
          </div>
        </div>
        <div class="mt-2">
          <div class="form-group">
            <label>Nombre *</label>
            <input type="text" class="form-control" formControlName="name"
                   placeholder="Introduzca el nombre"
                   [ngClass]="{ 'is-invalid': fileDataFormControls.name.touched && fileDataFormControls.name.errors }"/>
            <small class="invalid-feedback">
              Campo obligatorio
            </small>
          </div>
        </div>
        <div class="mt-2">
          <div class="form-group">
            <label>Dirección *</label>
            <input type="text" class="form-control" formControlName="address"
                   placeholder="Introduzca la dirección"
                   [ngClass]="{ 'is-invalid': fileDataFormControls.address.touched && fileDataFormControls.address.errors }"/>
            <small class="invalid-feedback">
              Campo obligatorio
            </small>
          </div>
        </div>
        <div class="mt-2">
          <div class="form-group">
            <label>Región *</label>
            <input type="text" class="form-control" formControlName="region"
                   placeholder="Introduzca la región"
                   [ngClass]="{ 'is-invalid': fileDataFormControls.region.touched && fileDataFormControls.region.errors }"/>
            <small class="invalid-feedback">
              Campo obligatorio
            </small>
          </div>
        </div>
        <div class="mt-2">
          <app-upload-file-storage label="Logo del cliente"
                                   extension="png"
                                   controlName="logoUrl"
                                   fileType="image/*"
                                   [showPreview]="true"
                                   (isImageLoaded)="isImageLoaded = $event">
          </app-upload-file-storage>
        </div>
        <div class="mt-2" *ngIf="!!client.logoUrl && !isImageLoaded">
          <img class="w-100" src="{{client?.logoUrl}}" alt="Logo del cliente" *ngIf="!!client.logoUrl">
        </div>
        <div class="form-group text-center space-20">
          <button type="button" class="btn btn-raised btn-secondary mr-1"
                  awPreviousStep>
            <span style="margin-right:10px;">
              <i class="feather ft-chevron-left"></i>
            </span> Anterior
          </button>
          <button type="button" class="btn btn-raised btn-info"
                  [disabled]="!fileDataForm.valid" (click)="submit()">
            Guardar
          </button>
        </div>
      </form>
    </aw-wizard-step>
  </aw-wizard>
  <div class="mb-1 d-flex justify-content-end">
    <button class="btn btn-danger" (click)="modal.hide()">Cerrar</button>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
             type="square-jelly-box"><p style="color: white"> Guardando... </p></ngx-spinner>
