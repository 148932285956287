import {Component, OnInit} from '@angular/core';
import {QuizService} from '../../../shared/services/quiz.service';
import {Observable} from 'rxjs';
import {Quiz} from '../../interfaces/quiz';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {AlertService} from '../../../shared/template-services/alert.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css']
})
export class QuizComponent implements OnInit {
  quizzes$: Observable<Quiz[]>;
  config: DataTableConfig = {
    title: '',
    notFoundText: 'No se encontraron cuestionarios',
    hasSearch: true
  };

  constructor(private _quiz: QuizService,
              private modal: BsModalService,
              private router: Router) {}

  ngOnInit(): void {
    this.quizzes$ = this._quiz.getAll();
  }

  async deleteQuiz(quizKey: string) {
    if (await AlertService.confirm('¿Estás seguro de que deseas eliminar este cuestionario?', '')) {
      await this._quiz.delete(quizKey);
      AlertService.toastSuccess('Se eliminó correctamente', '');
    }
  }

  gotToAddQuiz() {
    this.router.navigateByUrl(`admin/addQuiz`);
  }

  gotToQuizDetails($event: any,quizKey: string) {
    this.router.navigateByUrl(`admin/quizDetails/${quizKey}`);
    let quiz = JSON.stringify($event)
    sessionStorage.setItem(quizKey, quiz)
  }
}
