import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {UnitService} from '../../services/unit.service';
import {Unit} from '../../interfaces/unit';
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-unit-modal',
  templateUrl: './unit-modal.component.html',
  styleUrls: ['./unit-modal.component.css']
})
export class UnitModalComponent implements OnInit {
  isUpdate: boolean = false;
  unitForm: FormGroup;
  submitted: boolean = false;
  unit: any;

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalService,
              private _unit: UnitService) {
    this.unitForm = formBuilder.group({
      name: ['', Validators.required],
      code: [''],
      trash: [false]
    });
  }

  ngOnInit(): void {
    if (this.isUpdate){
      this.unitForm.patchValue(this.unit);
    }
  }

  get formControls() {
    return this.unitForm.controls;
  }

  async submit() {
    this.submitted = true;
    if (this.unitForm.valid) {
      this.isUpdate ? await this._unit.update(this.unit.key, this.unitForm.value as Unit) : await this._unit.add(this.unitForm.value as Unit);
      AlertService.toastSuccess(this.isUpdate ? 'Se actualizó la unidad' : 'Se agregó la unidad');
      this.modal.hide(50);
    }
  }
}
