import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {BillService} from '../../../shared/services/bill.service';
import {ProofOfPayment} from '../../interfaces/proof-of-payment';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ProofOfPaymentModalComponent} from '../../modals/proof-of-payment-modal/proof-of-payment-modal.component';
import {AlertService} from '../../../shared/template-services/alert.service';
import {map, take} from 'rxjs/operators';
import {ValidationService} from '../../../shared/template-services/validation.service';
import {BillModalComponent} from '../../modals/bill-modal/bill-modal.component';
import {PurchaseStatus} from '../../enums/purchase-status.enum';
import {PurchaseOrder} from '../purchase-orders/interfaces/purchase-order';
import {PurchaseOrderService} from '../../../shared/services/purchase-order.service';
import {PaymentCategoryService} from '../../services/payment-category.service';
import {ProviderService} from '../../../shared/services/provider.service';
import {ProjectService} from '../../../shared/services/project.service';
import {ObjectService} from '../../../shared/template-services/object.service';

@Component({
  selector: 'app-proof-of-payments',
  templateUrl: './proof-payments.component.html',
  styleUrls: ['./proof-payments.component.css']
})
export class ProofPaymentsComponent implements OnInit, OnDestroy {
  proofPayments$: Observable<ProofOfPayment[]>;
  config: DataTableConfig = {
    notFoundText: 'No se encontraron Comprobantes de pago',
    title: 'Lista de Comprobantes de pago',
    hasSearch: true
  };
  months = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December'
  };
  purchaseOrdersSubscription: Subscription = new Subscription();
  purchaseOrders: PurchaseOrder[];
  indexedBills = {};
  indexedProviders = {};
  categoriesIndexed = {};
  billSubscription: Subscription = new Subscription();
  paymentCategoriesSubscription: Subscription = new Subscription();
  proofOfPaymentSubscription: Subscription = new Subscription();
  categoriesSubscription: Subscription = new Subscription();
  paymentCategories: any[] = [];
  proofOfPayments: any[] = [];

  constructor(private _bill: BillService,
              private modal: BsModalService,
              private _validation: ValidationService,
              private _purchase: PurchaseOrderService,
              private _paymentCategory: PaymentCategoryService,
              private _provider: ProviderService,
              private _project: ProjectService,) {
  }

  ngOnInit(): void {
    this.proofPayments$ = this._bill.getAllProofPayments()
      .pipe(
        map(proofpayments => proofpayments.filter(proofpayment => !proofpayment.trashPermanent))
      );

    this.billSubscription = this._bill.getAll().subscribe((data) => {
      this.indexedBills = ObjectService.indexArray(data, 'key');
    })

    this.purchaseOrdersSubscription = this._purchase.getAll()
        .subscribe(data => {
          this.purchaseOrders = data.filter(purchaseOrder => purchaseOrder.status >= PurchaseStatus.AUTHORIZED);
        });

    this.listenPaymentCategories();
    this.loadProviders();
    this.listenProOfPayments();
    this.listenCategories();
  }

  ngOnDestroy(): void {
    this.billSubscription.unsubscribe();
    this.paymentCategoriesSubscription.unsubscribe();
    this.proofOfPaymentSubscription.unsubscribe();
    this.categoriesSubscription.unsubscribe();
  }

  listenPaymentCategories() {
    this.paymentCategoriesSubscription = this._paymentCategory
      .getAllWithoutCostCenter()
      .subscribe((data) => {
        this.paymentCategories = data;
      });
  }

  async loadProviders() {
    this.indexedProviders = ObjectService.indexArray(
      await this._provider.getAll().pipe(take(1)).toPromise(),
      'key'
    );
  }

  listenProOfPayments() {
    this.proofOfPaymentSubscription = this._bill.getAllProofPayments().subscribe((data) => {
      this.proofOfPayments = data.filter((proOfPayment) => proOfPayment.type == 1);
    });
  }

  listenCategories() {
    this.categoriesSubscription = this._project.getAllCategories().subscribe((data) => {
      this.categoriesIndexed = ObjectService.indexArray(data, 'key');
    });
  }

  async addToEmail() {
    let date = await AlertService.inputDate('Obtener comprobantes desde');
    await this._bill.getPayments(`${this.months[date[0].slice(5, 7)]} ${date[0].slice(-2)}, ${date[0].slice(0, 4)}`);
    AlertService.toastSuccess('Obtención de correos en curso');
  }

  async openProofOfPaymentModal(proofOfPayment) {
    proofOfPayment.name = proofOfPayment.name.toString();
    let includes = proofOfPayment.name.includes('Pago');
    if (!includes) proofOfPayment.name = await this.getRut(proofOfPayment.content);

    this.modal.show(ProofOfPaymentModalComponent, {
      initialState: {
        proofOfPayment: proofOfPayment
      },
      class: 'modal-xl'
    });
  }

  async deleteProofPayment(proofOfPaymentKey) {
    if (await AlertService.confirm('¿Estás seguro que deseas eliminar este comprobante de pago?')) {
      this._bill.deletePermanentProofPayment(proofOfPaymentKey);
    }
  }

  getRut(content) {
    if (!content) return;
    let contentSeparate = content.split(/[ >]+/);
    let rut: any = null;
    let quantity: any = null;

    for (let i = 0; i < contentSeparate.length; i++) {
      let content = contentSeparate[i].replace(/ /g,'');
      content = content.substr(0, content.indexOf('<'));

      if (contentSeparate[i] == '$') quantity = contentSeparate[i + 1].substr(0, contentSeparate[i + 1].indexOf('<'));
      if (content != '76.318.613-K' && this.validateRut({value: content})) rut = content;
    }

    if (rut && quantity) return `${rut} - $${quantity}`;
    if (rut) return `${rut}`;
    if (quantity) return `$${quantity}`;

    return '';
  }

  validateRut(rut) {
    let valor = rut.value.replaceAll('.', '');
    valor = valor.replace('-', '');

    let cuerpo = valor.slice(0, -1);
    let dv = valor.slice(-1).toUpperCase();

    rut.value = cuerpo + '-' + dv
    if (cuerpo.length < 7) {
      return false;
    }

    let suma = 0;
    let multiplo = 2;

    for (let i = 1; i <= cuerpo.length; i++) {
      let index = multiplo * valor.charAt(cuerpo.length - i);

      suma = suma + index;

      if (multiplo < 7) {
        multiplo = multiplo + 1;
      } else {
        multiplo = 2;
      }
    }
    let dvEsperado = 11 - (suma % 11);

    dv = (dv == 'K') ? 10 : dv;
    dv = (dv == 0) ? 11 : dv;

    if (dvEsperado != dv) return false

    rut.value = rut.value.slice(0, 2) + '.' + rut.value.slice(2, 5) + '.' + rut.value.slice(5);
    return true
  }

  includesName(name) {
    name = name.toString();
    let includes = name.indexOf('Pago -');
    return includes != -1;
  }

  async gotoOpenEditBill(billReference) {
    let bill = await this._bill.get(billReference.id).pipe(take(1)).toPromise();

    this.modal.show(BillModalComponent, {
      initialState: {
        bill: {...bill},
        isUpdate: true,
        purchaseOrders: this.purchaseOrders,
        indexedBills: {...this.indexedBills},
        paymentCategories: this.paymentCategories,
        indexedProviders: this.indexedProviders,
        proofOfPayments: this.proofOfPayments,
        categoriesIndexed: this.categoriesIndexed,
      },
      class: 'modal-xl',
      backdrop: 'static',
      id: 8177
    });
  }
}
