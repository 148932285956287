import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs/internal/Observable';
import {RequestNewFundToRender} from 'src/app/admin/pages/travel-advance/interfaces/request-new-fund-to-render';
import {FirebaseDataService} from '../template-services/firebase-data.service';

@Injectable({
  providedIn: 'root'
})
export class NewFundToRenderService {
  constructor(private db: FirebaseDataService, private afs: AngularFirestore) {}

  getAll(): Observable<RequestNewFundToRender[]> {
    return this.db.colWithIds$('fundToRenderRequests', (ref) =>
      ref.where('trash', '==', false)
    );
  }

  getAllByStatus(status: number[]): Observable<RequestNewFundToRender[]> {
    return this.db.colWithIds$('fundToRenderRequests', (ref) =>
      ref.where('trash', '==', false)
      .where('status', 'in', status)
    );
  }

  get(newFundToRenderKey: string): Observable<RequestNewFundToRender> {
    return this.db.docWithId$(`fundToRenderRequests/${newFundToRenderKey}`);
  }

  add(newFundToRender: RequestNewFundToRender) {
    newFundToRender['createdAt'] = new Date().getTime();
    return this.afs.collection('fundToRenderRequests').add(newFundToRender);
  }

  update(newFundToRenderKey: string, newFundToRender: RequestNewFundToRender): Promise<void>{
    return this.afs
      .doc(`fundToRenderRequests/${newFundToRenderKey}`)
      .update(newFundToRender);
  }

  delete(newFundToRenderKey: string): Promise<void> {
    return this.afs
      .doc(`fundToRenderRequests/${newFundToRenderKey}`)
      .update({trash: true});
  }
}
