import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Project} from '../../interfaces/project';
import {ProjectService} from '../../../shared/services/project.service';
import {ActivatedRoute} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap/modal';
import {UpdateProjectComponent} from '../../modals/update-project/update-project.component';
import {Article} from '../warehouses/interfaces/article';
import {ArticleService} from '../../../shared/services/article.service';
import {AddPersonComponent} from '../../modals/add-person/add-person.component';
import {UserType} from '../../enums/user-type.enum';
import {UserService} from '../../../shared/services/user.service';
import {LogsService} from '../../services/logs.service';
import {User} from '../../../shared/interfaces/user';
import {map} from 'rxjs/operators';
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnInit, OnDestroy {
  @Input() projectKey: string;
  project$: Observable<Project>;
  articleSubscription: Subscription = new Subscription();
  articleReferences: Article[];
  tabIndex: number = 2;
  permission: UserType;
  permissionEnum = UserType;
  logs$: Observable<any>;
  users: User[] = [];
  usersSubscription: Subscription = new Subscription();

  constructor(
    private activatedRoute: ActivatedRoute,
    private _project: ProjectService,
    private _article: ArticleService,
    private modal: BsModalService,
    private _user: UserService,
    private _log: LogsService
  ) {}

  ngOnInit(): void {
    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'PROYECTOS'
    ).permission;
    this.projectKey = this.activatedRoute.snapshot.params['projectKey'];
    this.project$ = this._project.get(this.projectKey);
    this.logs$ = this._log.getAllBudgets(this.projectKey);
    this.loadArticles();
    this.loadProjectUsers();
  }

  ngOnDestroy() {
    this.usersSubscription.unsubscribe();
  }

  loadProjectUsers() {
    this.usersSubscription = this._user
      .getUsers()
      .pipe(
        map((users) =>
          users.filter(
            (data) =>
              'projects' in data &&
              data.projects.length > 0 &&
              data.isApplicant == true &&
              data.projects.some((project) => project.id == this.projectKey)
          )
        )
      )
      .subscribe((users) => (this.users = users));
  }

  loadArticles() {
    this.articleSubscription = this._article.getAll().subscribe((data) => {
      this.articleReferences = data;
    });
  }

  modifyProject(project: Project) {
    this.modal.show(UpdateProjectComponent, {
      initialState: {
        projectKey: project.key,
        project,
        users: this.users,
      },
    });
  }

  async deleteProject(project: Project) {
    if (
      !(await AlertService.confirm(
        '¿Estás seguro de que deseas eliminar este proyecto?'
      ))
    )
      return;
    await this._project.delete(project.key);
    AlertService.toastSuccess('El proyecto ha sido eliminado correctamente');
  }

  openModalAddPerson() {
    this.modal.show(AddPersonComponent, {
      initialState: {
        projectKey: this.projectKey,
      },
    });
  }
}
