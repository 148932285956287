import {Component, OnInit} from '@angular/core';
import {DocumentReference} from '@angular/fire/firestore';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observable} from 'rxjs/internal/Observable';
import {first, map} from 'rxjs/operators';
import {DataTableConfig} from 'src/app/shared/interfaces/data-table-config';
import {NewFundToRenderService} from 'src/app/shared/services/new-fund-to-render.service';
import {UserService} from 'src/app/shared/services/user.service';
import {AlertService} from 'src/app/shared/template-services/alert.service';
import { FundToRenderRequestStatusEnum } from '../../enums/fund-to-render-request-status.enum';
import {UserType} from '../../../../enums/user-type.enum';
import {RequestNewFundToRender} from '../../interfaces/request-new-fund-to-render';
import { LogsService } from '../../../../services/logs.service';
import {NotificationService} from '../../../../services/notification.service';
import { FundToRenderRequestStatusLabel } from '../../labels/fund-to-render-request-status.label';
import _ from 'lodash';

declare const $;

@Component({
  selector: 'app-manage-fund-to-render-requests',
  templateUrl: './manage-fund-to-render-requests.component.html',
  styleUrls: ['./manage-fund-to-render-requests.component.css']
})
export class ManageFundToRenderRequestsComponent implements OnInit {
  fundToRenderRequests$: Observable<RequestNewFundToRender[]>;
  fundToRenderRequest: RequestNewFundToRender = {
    key: ''
  } as RequestNewFundToRender;
  permission: any;
  permissionEnum = UserType;
  config: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron solicitudes de fondos por rendir',
    title: ''
  };
  fundToRenderRequestStatusEnum = FundToRenderRequestStatusEnum;
  fundToRenderRequestStatusLabel = FundToRenderRequestStatusLabel;

  constructor(
    private _requestNewFundToRender: NewFundToRenderService,
    private _user: UserService,
    private _notification: NotificationService,
    private spinner: NgxSpinnerService,
    private _logs: LogsService
  ) {}

  ngOnInit(): void {
    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'FONDOS POR RENDIR'
    ).permission;
    this.fundToRenderRequests$ = this._requestNewFundToRender
      .getAllByStatus([
        this.fundToRenderRequestStatusEnum.PENDING_SUPERVISOR_APROVAL,
        this.fundToRenderRequestStatusEnum.PENDING_ADMIN_APROVAL,
        this.fundToRenderRequestStatusEnum.ACCEPTED,
        this.fundToRenderRequestStatusEnum.COMPLETED
      ]).pipe(map(requests => _.orderBy(requests, 'status', 'asc')));
  }

  async acceptFundToRenderRequestSupervisor(fundToRenderRequest) {
    if (
      !(await AlertService.confirm(
        '¿Estás seguro de que deseas enviar a aprobación esta solicitud de fondo por rendir?'
      ))
    )
      return;
    this.spinner.show();

    await this._requestNewFundToRender.update(fundToRenderRequest.key, {
      status: this.fundToRenderRequestStatusEnum.PENDING_ADMIN_APROVAL
    } as RequestNewFundToRender);

    this._logs.addFundsToRender(`El supervisor aceptó y envió a revisión su solicitud para un nuevo fondo por rendir`);

    this.spinner.hide();
  }

  async rejectFundToRenderRequestSupervisor(fundToRenderRequest) {
    if (
      !(await AlertService.confirm(
        '¿Estás seguro de que deseas rechazar esta solicitud de fondo por rendir?'
      ))
    )
      return;

    const rejectReason = await this.getRejectReason();

    if (!rejectReason) {
      AlertService.toastError('Debe ingresar una razón para rechazar');
      return;
    }

    this.spinner.show();
    await this.sendNotificationEmailToApplicant(
      fundToRenderRequest.user,
      false,
      rejectReason
    );

    await this._requestNewFundToRender.update(fundToRenderRequest.key, {
      status: this.fundToRenderRequestStatusEnum.REJECTED,
      rejectReason: rejectReason
    } as RequestNewFundToRender);
    this._logs.addFundsToRender(`El supervisor ha rechazado su solicitud para un nuevo fondo por rendir`);
    this.spinner.hide();
  }

  async acceptFundToRenderRequest(fundToRenderRequest) {
    if (
      !(await AlertService.confirm(
        '¿Estás seguro de que deseas aceptar esta solicitud de fondo por rendir?'
      ))
    )
      return;

    this.spinner.show();
    await this.sendNotificationEmailToApplicant(fundToRenderRequest.user, true);

    this._requestNewFundToRender.update(fundToRenderRequest.key, {
      status: this.fundToRenderRequestStatusEnum.ACCEPTED
    } as RequestNewFundToRender);
    this._logs.addFundsToRender(`El administrador aceptó su solicitud para un nuevo fondo por rendir.
     Ya puede crear un fondo por rendir nuevo`);
    this.spinner.hide();
  }

  async getRejectReason() {
    let rejectReason = await AlertService.withHtml(
      'Solicitud de fondo por rendir',
      `<label>Razón del rechazo:</label>
      <textarea id="swal-input1" rows="3" class="swal2-input"></textarea>`,
      function () {
        return new Promise(function (resolve) {
          resolve($('#swal-input1').val());
        });
      }
    );

    return rejectReason;
  }

  async rejectFundToRenderRequest(fundToRenderRequest) {
    const rejectReason = await this.getRejectReason();

    if (!rejectReason) {
      AlertService.toastError('Debe ingresar una razón para rechazar');
      return;
    }

    this.spinner.show();
    await this.sendNotificationEmailToApplicant(
      fundToRenderRequest.user,
      false,
      rejectReason
    );

    this._requestNewFundToRender.update(fundToRenderRequest.key, {
      status: this.fundToRenderRequestStatusEnum.REJECTED,
      rejectReason: rejectReason
    } as RequestNewFundToRender);

    this._logs.addFundsToRender(`Motivo del rechazo del administrador: ${rejectReason}`);

    this.spinner.hide();
  }

  async sendNotificationEmailToApplicant(
    userReference: DocumentReference,
    isAccepted: boolean,
    rejectReason?: string
  ) {
    const user = await this._user
      .getWithId$(userReference.id)
      .pipe(first())
      .toPromise();
    if (!user) return;

    try {
      await this._notification.sendNotificationEmail(
        `Solicitud para un nuevo fondo por rendir fue ${
          isAccepted
            ? 'aceptada'
            : 'rechazada con la siguiente razón: ' + rejectReason
        }`,
        'Fondos por rendir: Solicitud aceptada',
        [user.email]
      );
    } catch (error) {
      console.log(error);
    }
  }

  acceptableSupervisorRequests(fundToRenderRequest) {
    return (
      this.permission == this.permissionEnum.SUPERVISOR &&
      fundToRenderRequest.status == this.fundToRenderRequestStatusEnum.PENDING_SUPERVISOR_APROVAL
    );
  }

  acceptableAdminRequests(fundToRenderRequest) {
    return (
      this.permission == this.permissionEnum.ADMIN &&
      fundToRenderRequest.status == this.fundToRenderRequestStatusEnum.PENDING_ADMIN_APROVAL
    );
  }
}
