import { Component, OnInit, ViewChild } from '@angular/core';
import { BillDataService } from '../../services/bill-data.service';
import { PaymentMethodLabel } from '../../../../labels/payment-method.label';
import { UploadFileStorageComponent } from '../../../../../shared/template-components/upload-file-storage/upload-file-storage.component';
import { BillService } from '../../../../../shared/services/bill.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { PaymentMethodEnum } from '../../../../enums/payment-method.enum';

@Component({
  selector: 'app-bill-payment',
  templateUrl: './bill-payment.component.html',
  styleUrls: ['./bill-payment.component.scss']
})
export class BillPaymentComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild(UploadFileStorageComponent) filesStorage: UploadFileStorageComponent;
  paymentMethodLabel = PaymentMethodLabel;
  paymentMethodEnum = PaymentMethodEnum;

  constructor(public _billData: BillDataService,
              private _bill: BillService) {
  }

  ngOnInit(): void {
  }

  get badgeType() {
    return this._billData.bill.isPaid
      ? 'badge-info'
      : 'badge-warning';
  }

  updateActualPaymentDate() {
    if (this._billData.billToEdit.isPaid) {
      this._billData.billToEdit.realPaymentDate = new Date().getTime();
    } else {
      this._billData.billToEdit.realPaymentDate = null;
      this._billData.billToEdit.proofOfPayment = null;
      this._billData.billToEdit.paymentMethod = null;
      this._billData.billToEdit.otherPaymentMethod = null;
    }
  }

  async proofOfPaymentChange() {
    if (this.filesStorage.isInputValid) {
      this.blockUI.start('Procesando comprobante, favor de esperar...');

      const url = await this.filesStorage.uploadDocument(
        'vouchers',
        this._billData.bill.key
      );

      this._billData.bill.proofOfPayment = url;
      this._billData.billToEdit.proofOfPayment = url;

      this.blockUI.stop();
    }
  }
}
