<div class="modal-header">
  <h4 class="modal-title pull-left">Tabla de gastos por categoría</h4>
  <button type="button" class="pull-right btn" (click)="modal.hide()">
    <i class="feather ft-x"></i>
  </button>
</div>

<cdk-accordion class="example-accordion p-1">
  <cdk-accordion-item
    *ngFor="let category of receiptsPerCategory; let index = index"
    #accordionItem="cdkAccordionItem"
    class="example-accordion-item"
    role="button"
    tabindex="0"
    [attr.id]="'accordion-header-' + index"
    [attr.aria-expanded]="accordionItem.expanded"
    [attr.aria-controls]="'accordion-body-' + index">
    <div
      class="example-accordion-item-header align-items-center"
      (click)="accordionItem.toggle()">
      {{ category.categoryName }}
      <span class="example-accordion-item-header">
        Monto pedido: {{ category.categories[0].firstAmount }}
      </span>
      <span class="example-accordion-item-header">
        Total:
        <span
          [ngClass]="{
            'text-danger':
              0 > category.categories[0].firstAmount - category.totalAmount,
            'text-success':
              category.categories[0].firstAmount - category.totalAmount > 0
          }">
          {{ category.categories[0].firstAmount - category.totalAmount }}</span
        >
      </span>
    </div>
    <div
      class="example-accordion-item-body"
      role="region"
      [style.display]="accordionItem.expanded ? '' : 'none'"
      [attr.id]="'accordion-body-' + index"
      [attr.aria-labelledby]="'accordion-header-' + index">
      <table class="table">
        <thead>
          <tr>
            <th>Descripción</th>
            <th>Monto gastado</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let receipt of category.categories">
            <td>{{ receipt.description }}</td>
            <td>{{ receipt.amount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </cdk-accordion-item>
</cdk-accordion>

<div
  *ngIf="!!fundToRenderUser?.pendingAccountability"
  class="d-flex flex-row p-1 align-items-center">
  <p class="mb-0">
    Adeudo pendiente del usuario: ${{ fundToRenderUser?.pendingAccountability }}
  </p>

  <button (click)="paidUserDebt()" class="btn btn-success ml-1">
    Saldar deuda
  </button>
</div>

<div class="mt-1 p-1 d-flex justify-content-end">
  <button class="btn btn-outline-danger" (click)="modal.hide()">Cerrar</button>
</div>
