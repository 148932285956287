import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ReceiptLog } from '../../interfaces/receipt-log';
import { LogsService } from '../../../../services/logs.service';
import { map } from 'rxjs/operators';
import _ from 'lodash';
import { UserService } from '../../../../../shared/services/user.service';
import { DocumentReference } from '@angular/fire/firestore';

@Component({
  selector: 'app-receipts-logs',
  templateUrl: './receipts-logs.component.html',
  styleUrls: ['./receipts-logs.component.css']
})
export class ReceiptsLogsComponent implements OnInit {
  receiptsLogs$: Observable<ReceiptLog[]>;

  constructor(private _log: LogsService,
              private _user: UserService) { }

  ngOnInit() {
    this.receiptsLogs$ = this._log.getReceiptLogs().pipe(
      map((logs) =>
        _.orderBy(
          logs.filter(
            (log) => (log.user as DocumentReference).id == this._user.user.key
          ),
          'createdAt',
          'desc'
        )
      )
    );
  }
}
