<div class="row mb-1">
  <h2 class="ml-2">Fecha desde:</h2>
  <input class="form-control ml-1" type="date" style="width: 200px; margin-top: -4px;"
         [(ngModel)]="startDate">

  <h2 class="ml-1">a</h2>

  <input class="form-control ml-1" type="date" style="width: 200px; margin-top: -4px;"
         [(ngModel)]="finalDate">

  <button class="btn btn-danger ml-1" style="margin-top: -4px;" (click)="filterDate()" >Buscar</button>
</div>
<mat-tab-group (selectedTabChange)="onChange($event)">
  <mat-tab label="No leídas">
    <app-datatable [rows$]="filteredUnreadNotifications$"
                  [config]="notificationsConfig"
                  (rowSelected)="markAsReaded($event)">
                 
      <app-datatable-column title="Descripción">
        <ng-template let-row #column>
          {{row.description}}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Fecha de creación">
        <ng-template let-row #column>
          {{row.createdDate |  date : 'dd/MM/yyyy HH:mm'}}   
        </ng-template>
      </app-datatable-column>         
    </app-datatable>
  </mat-tab>

  <mat-tab label="Leídas">
    <app-datatable [rows$]="filteredReadNotifications$"
                  [config]="notificationsConfig">

      <app-datatable-column title="Descripción">
        <ng-template let-row #column>
          {{row.description}}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Fecha de creacion">
        <ng-template let-row #column>
          {{row.createdDate |  date : 'dd/MM/yyyy HH:mm'}}   
        </ng-template>
      </app-datatable-column>         
    </app-datatable>
  </mat-tab>
</mat-tab-group>    