<div class="d-flex mb-1 flex-wrap">
  <div class="max-width">
    <label>Desde</label>
    <input
      class="form-control p-sm"
      type="date"
      [(ngModel)]="startDate">
  </div>

  <div class="max-width ml-1">
    <label>Hasta</label>
    <input
      class="form-control p-sm"
      type="date"
      [(ngModel)]="finalDate">
  </div>

  <div class="align-self-end mt-1 d-flex">
    <button
      class="btn btn-danger ml-1"
      (click)="filterPurchases()">Buscar
    </button>
    <button
      class="btn btn-warning ml-1"
      (click)="resetBills()">Mostrar todos
    </button>
  </div>

  <div class="ml-2 d-flex align-items-end">
    <ng-select
      [items]="selectArray"
      [searchable]="true"
      bindLabel="name"
      appendTo="body"
      placeholder="Selecciona un proyecto o centro de costo"
      class="pb-0"
      [(ngModel)]="selectedProject"
      (change)="filterPurchases()"
      groupBy="type">
      <ng-template ng-option-tmp let-item="item">
        {{ item.name }}
      </ng-template>
    </ng-select>
  </div>
</div>


<div class="col-xl-12 col-lg-12 mt-2">
  <div class="card">
    <div class="card-content">
      <div class="card-body p-sm">
        <div
          (change)="changePurchaseStatus()"
          class="flex-wrap">
          <div class="d-inline-block custom-control custom-radio mr-1">
            <input
              type="radio"
              class="custom-control-input bg-light"
              [(ngModel)]="myRadio"
              [value]="purchaseStatus.DRAFT"
              name="colorRadio"
              id="colorRadio1">
            <label
              class="custom-control-label"
              for="colorRadio1">BORRADORES</label>
          </div>
          <div class="d-inline-block custom-control custom-radio mr-1">
            <input
              type="radio"
              class="custom-control-input bg-primary"
              [(ngModel)]="myRadio"
              [value]="purchaseStatus.GENERATED"
              name="colorRadio"
              id="colorRadio2">
            <label
              class="custom-control-label"
              for="colorRadio2">GENERADOS</label>
          </div>
          <div class="d-inline-block custom-control custom-radio mr-1">
            <input
              type="radio"
              class="custom-control-input bg-info"
              [(ngModel)]="myRadio"
              [value]="purchaseStatus.AUTHORIZED"
              name="colorRadio"
              id="colorRadio3">
            <label
              class="custom-control-label"
              for="colorRadio3">AUTORIZADOS</label>
          </div>
          <div class="d-inline-block custom-control custom-radio mr-1">
            <input
              type="radio"
              class="custom-control-input bg-dark"
              [(ngModel)]="myRadio"
              [value]="purchaseStatus.CANCELLED"
              name="colorRadio"
              id="colorRadio4">
            <label
              class="custom-control-label"
              for="colorRadio4">CANCELADOS</label>
          </div>
          <div class="d-inline-block custom-control custom-radio mr-1">
            <input
              type="radio"
              class="custom-control-input bg-warning"
              [(ngModel)]="myRadio"
              [value]="999"
              name="colorRadio"
              id="all">
            <label
              class="custom-control-label"
              for="all">TODOS</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-datatable-pagination
  [rows]="purchasesFiltered"
  [config]="config"
  (rowSelected)="gotoOpenEditPurchaseOrder($event)">

  <app-datatable-column title="Estatus">
    <ng-template
      let-row
      #column>
      <div
        class="status-circle"
        [ngClass]="getStatusClass(row.status)"
        ngbTooltip="{{purchaseStatusLabel[row.status]}}"></div>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="OC">
    <ng-template
      let-row
      #column>
      {{ row.purchaseID | slice:0 : -3 }}
      <span class="text-muted font-size-xsmall">#{{row?.purchaseID | slice:-3 }}</span>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Proveedor">
    <ng-template
      let-row
      #column>
      {{row.providerName}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="RUT">
    <ng-template
      let-row
      #column>
      {{row.providerRut}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Descripción">
    <ng-template
      let-row
      #column>
      {{row.description}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Monto">
    <ng-template
      let-row
      #column>
      {{ row.total | clearCurrency | swapCommasAndDots}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha de entrega">
    <ng-template
      let-row
      #column>
      {{ row?.deliveryDate | date : 'dd/MM/yyyy' }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="">
    <ng-template
      let-row
      #column>
      <div class="d-flex flex-wrap">
        <span
          class="badge badge-pill badge-success mr-1"
          [ngClass]="{
                'badge-success': !!row.received,
                'badge-danger': !row.received}"
          ngbTooltip="{{!!row.received ? 'Entregado' : 'No entregado'}}">
          <i class="la la-truck"></i></span>

        <span
          class="badge badge-pill"
          [ngClass]="{
                'badge-success': !!row.invoiced,
                'badge-danger': !row.invoiced}"
          ngbTooltip="{{!!row.invoiced ? 'Facturado' : 'No facturado'}}">
          <i class="la la-file-pdf-o"></i>
        </span>
      </div>

    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Acciones"
                        [isClickable]="false">
    <ng-template
      let-row
      #column>
      <button class="btn btn-primary"
              *ngIf="row.status === purchaseStatus.DRAFT"
              (click)="deletePurchase(row)">
        <i class="la la-trash"></i>
      </button>
    </ng-template>
  </app-datatable-column>

</app-datatable-pagination>

<app-add-float-button (clickEmmiter)="openAddPurchaseOrderModal()"></app-add-float-button>
