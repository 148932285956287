import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-metrics-tables',
  templateUrl: './metrics-tables.component.html',
  styleUrls: ['./metrics-tables.component.css']
})
export class MetricsTablesComponent implements OnInit {
  @Input() lastFiveApplicants = []
  @Input() graphicCity = []

  constructor() { }

  ngOnInit(): void {
  }

}
