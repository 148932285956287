<div (window:resize)="onResize($event)"></div>
<app-navigation></app-navigation>
<div class="app-content content">
  <div class="{{isFleet ? '' : 'content-wrapper'}}">
    <div class="content-body">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<div class="sidenav-overlay d-none" id="sidenav-overlay" (click)="rightbar($event)"></div>
<app-footer></app-footer>
