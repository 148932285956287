import {TabsPermission} from '../enums/tabs-permission.enum';
import {ObjectService} from '../../../../shared/template-services/object.service';

export const isTabPermission = (permission) => {
  return Object.values(TabsPermission).includes(permission);
};

export const indexPermissions = (permissions): any => {
  const tabsPermissions = {};

  for (const tab of Object.keys(permissions)) {
    tabsPermissions[tab] = ObjectService.indexArray(permissions[tab]);
  }

  return tabsPermissions;
};
