<div class="p-2">
  <h2>{{isUpdate ? 'Actualizar categoría de pago' : 'Agregar categoría de pago'}}</h2>

  <form [formGroup]="paymentCategoryForm" (ngSubmit)="submit()">
    <div class="row mt-2">
      <div class="col-12">
        <div class="form-group">
          <label>Nombre de la categoría *</label>
          <input type="text" class="form-control" formControlName="name"
                 placeholder="" [ngClass]="{'is-invalid': submitted && formControls.name.errors}"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.name.errors">
            <div *ngIf=" formControls.name.errors.required">
              El total de pagos es obligatorio
            </div>
          </small>
        </div>
      </div>
    </div>
    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn">{{isUpdate ? 'Actualizar' : 'Agregar'}}</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide()">Cancelar</div>
    </div>
  </form>
</div>

