import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../../shared/services/user.service';
import {ActivatedRoute} from '@angular/router';
import {User} from '../../../shared/interfaces/user';
import {AlertService} from '../../../shared/template-services/alert.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription, Observable} from 'rxjs';
import {CommentService} from '../../../shared/services/comment.service';
import {AuthService} from '../../../shared/template-services/auth.service';
import {UserType} from '../../enums/user-type.enum';
import {PrintService} from '../../../shared/services/print.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {UpdateUserComponent} from '../../modals/update-user/update-user.component';
import {take} from 'rxjs/operators';
import {ValidationService} from '../../../shared/template-services/validation.service';
import {LogsService} from '../../services/logs.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {FirebaseError} from 'firebase-admin';

declare const $;

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  @ViewChild('componentID') componentID: ElementRef;
  commentsSubscription: Subscription = new Subscription();
  permissionsForm: FormGroup;
  comments: any = [];
  commentForm: FormGroup;
  submitted: boolean = false;
  user: User;
  currentUser: any;
  userKey: string;
  tabIndex: number = 0;
  permission: UserType;
  permissionEnum = UserType;
  isPrinting: boolean = false;
  logs$: Observable<any[]>;

  constructor(
    private _user: UserService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private _comment: CommentService,
    private _auth: AuthService,
    private modal: BsModalService,
    private _log: LogsService,
    private SpinnerService: NgxSpinnerService
  ) {
    this.permissionsForm = formBuilder.group({
      admin: [0, Validators.required],
      supervisor: [0, Validators.required],
      grocer: [0, Validators.required],
      reader: [0, Validators.required],
      blind: [0, Validators.required]
    });

    this.commentForm = formBuilder.group({
      comment: ['', Validators.required],
      user: ['', Validators.required],
      date: [new Date().getTime()],
      trash: [false]
    });
  }

  async ngOnInit(): Promise<void> {
    const permission = this._user.user.permissions.find(
      (permission) => permission.section == 'USUARIOS'
    );
    this.permission = !!permission
      ? permission.permission
      : this.permissionEnum.USER;
    this.userKey = this.activatedRoute.snapshot.params['userKey'];
    this.userKey =
      this.userKey == 'default' ? this._auth.user.uid : this.userKey;
    this.user = await this._user.getSpecificUser(this.userKey);
    this.getComments(this.userKey);
    this.logs$ = this._log.getLogsUser(this.user.key);

    this._user.update(this.userKey, {lastAccess: new Date().getTime()} as User);
  }

  getComments(userKey) {
    this.commentsSubscription = this._user
      .getComments(userKey)
      .subscribe((data) => {
        this.comments = data;
      });
  }

  async disabledAccount(): Promise<void> {
    if (
      await AlertService.confirm(
        '¿Estás seguro que deseas deshabilitar esta cuenta?'
      )
    ) {
      this._user.update(this.userKey, {isDisable: true} as User);
      this.user.isDisable = true;
    }
  }

  async enableAccount(): Promise<void> {
    if (
      await AlertService.confirm(
        '¿Estás seguro que deseas habilitar esta cuenta?'
      )
    ) {
      this._user.update(this.userKey, {isDisable: false} as User);
      this.user.isDisable = false;
    }
  }

  async submitComment() {
    this.currentUser = await this._auth.loadFirebaseUser();
    this.commentForm.patchValue({
      user: this._user.getReference(this.currentUser.uid)
    });
    this.submitted = true;
    if (this.commentForm.valid) {
      await this._comment.addCommentUser(
        this.userKey,
        this.commentForm.value as Comment
      );
      AlertService.toastSuccess('Se agregó el comentario');
      await this._log.addUser(this.user.key, `Se agregó un comentario`);
      this.commentForm.patchValue({comment: ''});
    }
  }

  printInfoUser() {
    this.isPrinting = true;
    setTimeout(() => {
      PrintService.print(this.componentID.nativeElement);
      this.isPrinting = false;
    }, 500);
  }

  async askToResetPassword() {
    if (
      await AlertService.confirm(
        '¿Estás seguro que deseas restablecer la contraseña de este usuario?'
      )
    ) {
      this.SpinnerService.show();
      await this._user.resetPassword(this.userKey, this.user.email);
      this.SpinnerService.hide();
      AlertService.success(
        'Contraseña restablecida',
        `Nueva contraseña: ${this.user.email}`
      );
    }
  }

  async choosePicture(event) {
    if (!ValidationService.validateFileSize(event, 10000))
      return AlertService.toastError('El archivo debe ser menor a 10MB');

    const imageUrl = await this._user.uploadPicture(
      event.target.files[0],
      this.user.key
    );
    this._user.update(this.user.key, {imageUrl} as User);
    this.user.imageUrl = imageUrl;
  }

  openUpdateUserModal() {
    const modalRef = this.modal.show(UpdateUserComponent, {
      initialState: {
        user: this.user
      },
      class: 'modal-lg'
    });

    modalRef.onHidden.pipe(take(1)).subscribe(async () => {
      if (modalRef.content.wasEdited) {
        this.user = await this._user.getSpecificUser(this.userKey);
        await this._log.addUser(this.userKey, 'Se modificó el usuario');
      }
    });
  }

  async changePassword() {
    let password = await this.showPasswordAlertInput('Ingrese su contraseña');

    if (!!password) {
      const userCredential = await this._auth
        .reAuth(this.user.email, password)
        .catch((err: FirebaseError) => {
          AlertService.error(
            'Error al insertar su contraseña actual',
            AuthService.getMessageError(err.code)
          );
        });
      if (userCredential == undefined) {
        return;
      }
      if (userCredential) {
        let newPassword = await this.showPasswordAlertInput(
          'Ingrese su nueva contraseña'
        );
        if (!newPassword) return;
        userCredential.user
          .updatePassword(newPassword)
          .then(() => {
            AlertService.success('Se ha cambiado la contraseña con éxito');
            this.modal.hide();
          })
          .catch((err: FirebaseError) => {
            AlertService.error(
              'Error al insertar su nueva contraseña',
              AuthService.getMessageError(err.code)
            );
          });
      }
    }
  }

  async showPasswordAlertInput(text: string) {
    return await AlertService.withHtml(
      text,
      `
      <div class="d-flex justify-content-center">
        <input class="form-control" type="password" id="swal-input1">
      </div>
    `,
      function () {
        return new Promise(function (resolve) {
          resolve($('#swal-input1').val());
        });
      }
    );
  }
}
