import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-purcharse-order-bills',
  templateUrl: './purcharse-order-bills.component.html',
  styleUrls: ['./purcharse-order-bills.component.css']
})
export class PurcharseOrderBillsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
