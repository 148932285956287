import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {Observable} from 'rxjs';
import {Receipt} from '../interfaces/receipt';
import {FirebaseDataService} from '../../../../shared/template-services/firebase-data.service';

@Injectable({
  providedIn: 'root'
})
export class ReceiptService extends FirebaseDataService {
  constructor(
    private _db: AngularFirestore,
    private _storage: AngularFireStorage
  ) {
    super(_db, _storage);
  }

  getAll(): Observable<Receipt[]> {
    return this.colWithIds$(`receipts`, (ref) =>
      ref.where('trash', '==', false)
    );
  }

  get(receiptKey: string): Observable<Receipt> {
    return this.docWithId$(`receipts/${receiptKey}`);
  }

  add(receipt: Receipt): Promise<DocumentReference> {
    return this._db.collection(`receipts`).add({
      ...receipt,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      trash: false
    });
  }

  updateReceipt(receiptKey: string, receipt: Receipt): Promise<void> {
    receipt['updatedAt'] = new Date().getTime();
    return this._db.doc(`receipts/${receiptKey}`).update(receipt);
  }

  delete(receiptKey: string): Promise<void> {
    return this._db.doc(`receipts/${receiptKey}`).update({trash: true});
  }
}
