import {Component, OnInit} from '@angular/core';
import {DataTableConfig} from '../../../../../shared/interfaces/data-table-config';
import {BreakdownAmountCategoryService} from '../../services/breakdown-amount-category.service';
import {Observable} from 'rxjs';
import {BreakdownAmountCategory} from '../../interfaces/breakdown-amount-catengory';
import {AlertService} from '../../../../../shared/template-services/alert.service';
import {UserService} from '../../../../../shared/services/user.service';
import {UserType} from '../../../../enums/user-type.enum';

@Component({
  selector: 'app-breakdown-amount-categories',
  templateUrl: './breakdown-amount-categories.component.html',
  styleUrls: ['./breakdown-amount-categories.component.css']
})
export class BreakdownAmountCategoriesComponent implements OnInit {
  breakdownAmountCategories$: Observable<BreakdownAmountCategory[]>;
  userPermission;
  userTypeEnum = UserType;

  dataTableConfig: DataTableConfig = {
    hasSearch: false,
    title: 'Lista de categorías',
    notFoundText: 'No se encontraron resultados'
  };

  constructor(
    private _breakdownAmountCategory: BreakdownAmountCategoryService,
    private _user: UserService
  ) {}

  ngOnInit() {
    this.breakdownAmountCategories$ = this._breakdownAmountCategory.getAll();
    this.setUserPermission();
  }

  async addCategory() {
    const name = await AlertService.input(
      'Agregar categoría',
      'Ingrese el nombre de la categoría',
      'Guardar'
    );
    if (!name.trim()) return;

    if (
      !(await AlertService.confirm(
        'Agregar categoría',
        '¿Está seguro de que deseas agregar esta categoría?'
      ))
    ) {
      return;
    }

    await this._breakdownAmountCategory.add(name.trim());
    AlertService.toastSuccess('Categoría agregada');
  }

  async updateCategory({key, name: categoryName}: BreakdownAmountCategory) {
    const name = await AlertService.input(
      'Editar categoría',
      'Ingrese el nombre de la categoría',
      'Guardar',
      categoryName
    );
    if (!name.trim() || name.trim() == categoryName) return;

    if (
      !(await AlertService.confirm(
        'Editar categoría',
        `¿Está seguro de que deseas editar la categoría ${categoryName}?`
      ))
    ) {
      return;
    }

    await this._breakdownAmountCategory.update(key, name.trim());
    AlertService.toastSuccess('Categoría actualizada');
  }

  async deleteCategory({key}: BreakdownAmountCategory) {
    if (
      !(await AlertService.confirm(
        'Eliminar categoría',
        '¿Está seguro de que deseas eliminar esta categoría?'
      ))
    ) {
      return;
    }

    await this._breakdownAmountCategory.delete(key);
    AlertService.toastSuccess('Categoría eliminada');
  }

  private setUserPermission() {
    this.userPermission = this._user.getPermission('FONDOS POR RENDIR');
  }

  validateUserPermission(permissionExpected) {
    return permissionExpected == this.userPermission;
  }
}
