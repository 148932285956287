import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'clearCurrency'
})
export class ClearCurrencyPipe implements PipeTransform {

  transform(value: any, round: boolean = true): string {
    if (!value) value = 0;

    if (round) value = (Math.round(value)).toString();
    return value.toString().replaceAll('.', '').replaceAll('$', '');
  }

}
