<app-datatable [config]="dataTableConfig" [rows$]="breakdownAmountCategories$">
  <app-datatable-column title="Nombre" field="name">
    <ng-template let-row #column>
      <h4>{{ row.name }}</h4>
      <p>{{ row.description }}</p>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column
    [isClickable]="false"
    *ngIf="validateUserPermission(userTypeEnum.ADMIN)">
    <ng-template let-row #column>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-success mr-1"
          ngbTooltip="Editar"
          (click)="updateCategory(row)">
          <i class="la la-edit"></i>
        </button>

        <button
          class="btn btn-danger"
          ngbTooltip="Eliminar"
          (click)="deleteCategory(row)">
          <i class="la la-trash"></i>
        </button>
      </div>
    </ng-template>
  </app-datatable-column>
</app-datatable>

<app-add-float-button
  *ngIf="validateUserPermission(userTypeEnum.ADMIN)"
  (click)="addCategory()"></app-add-float-button>
