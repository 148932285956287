<div class="p-2">
  <h2>{{isUpdate ? 'Actualizar pago' : 'Agregar pago'}}</h2>

  <form [formGroup]="paymentForm" (ngSubmit)="submit()">
    <div class="row mt-2">
      <div class="col-12">
        <div class="form-group">
          <label>Categoría de pago *</label>
          <ng-select [items]="paymentCategories"
                     [searchable]="true"
                     bindLabel="name"
                     placeholder="Seleccione una categoría de pago"
                     formControlName="category"
                     [ngClass]="{ 'is-invalid': submitted && formControls.category.errors }">
            <ng-template ng-option-tmp let-item="item">
              {{item.name}}
            </ng-template>
          </ng-select>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.category.errors">
            <div *ngIf="formControls.category.errors.required">
              La categoría de pago es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-12">
        <div class="form-group">
          <label>Fecha del pago *</label>
          <input class="form-control" type="date" formControlName="date"
                 [ngClass]="{ 'is-invalid': submitted && formControls.date.errors }">
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.date.errors">
            <div *ngIf=" formControls.date.errors.required">
              La fecha real de pago es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-12">
        <div class="form-group">
          <label>Total del pago *</label>
          <input type="number" class="form-control" formControlName="payment"
                 placeholder="" [ngClass]="{'is-invalid': submitted && formControls.payment.errors}"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.payment.errors">
            <div *ngIf=" formControls.payment.errors.required">
              El total de pagos es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="custom-control custom-switch ml-1" (click)="updateActualPaymentDate()">
        <input type="checkbox" class="custom-control-input" id="customSwitch1" formControlName="paid">
        <label class="custom-control-label" for="customSwitch1">¿Está pagada?</label>
      </div>

    </div>
    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn">{{isUpdate ? 'Actualizar' : 'Agregar'}}</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide()">Cancelar</div>
    </div>
  </form>
</div>

