import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotificationType } from '../../../../enums/notification-type.enum';
import { AlertService } from '../../../../../shared/template-services/alert.service';
import { Applicant } from '../../../../interfaces/applicant';
import { AuthService } from '../../../../../shared/template-services/auth.service';
import { UserService } from '../../../../../shared/services/user.service';
import { NotificationService } from '../../../../services/notification.service';
import { LogsService } from '../../../../services/logs.service';
import { CommentService } from '../../../../../shared/services/comment.service';
import { Subscription } from 'rxjs';
import { ApplicantService } from '../../../../../shared/services/applicant.service';

@Component({
  selector: 'app-applicant-comments',
  templateUrl: './applicant-comments.component.html',
  styleUrls: ['./applicant-comments.component.css']
})
export class ApplicantCommentsComponent implements OnInit {
  @Input() comments: any = [];
  @Input() users = [];
  @Input() usersNotification = [];
  @Input() commentForm: FormGroup;
  @Input() applicant: Applicant;
  @Input() currentUser: any;
  @Input() submitted = false;
  @Input() indexApplicant: number;
  @Input() applicants: any = [];
  @Input() applicantKey: string;
  commentsSubscription: Subscription = new Subscription();

  constructor(private _auth: AuthService,
    private _user: UserService,
    private _notification: NotificationService,
    private _log: LogsService,
    private _comment: CommentService,
    private _applicant: ApplicantService) {
  }

  ngOnInit(): void {
    this.getComments(this.applicantKey);
  }

  getComments(applicantKey: string) {
    this.commentsSubscription = this._applicant
      .getComments(applicantKey)
      .subscribe((data) => {
        this.comments = data;
      });
  }

  async submitComment(applicantKey: string, event: MouseEvent) {
    this.currentUser = await this._auth.loadFirebaseUser();
    this.commentForm.patchValue({
      user: this._user.getReference(this.currentUser.uid),
      date: new Date().getTime()
    });

    this.submitted = true;

    if (this.commentForm.valid) {
      let usersNotification = [];
      if (this.usersNotification.length > 0) {
        for (let user of this.usersNotification) {
          this._notification.setUser(user.key, {
            redirectUrl: `admin/applicant-details/${
              this.applicants[this.indexApplicant].key
            }`,
            description: `${this.commentForm.value.comment}. Aplicante ${
              this.applicants[this.indexApplicant].name
            } ${this.applicants[this.indexApplicant].surnames}`,
            createdDate: new Date().getTime(),
            trash: false,
            readed: false,
            type: NotificationType.APPLICANT_COMMENT
          });

          usersNotification.push({
            name: user.name,
            surnames: !user.surnames ? '' : user.surnames,
            reference: await this._user.getReference(user.key)
          });
        }
      }

      this.commentForm.patchValue({ usersNotification: usersNotification });

      await this._comment.add(applicantKey, this.commentForm.value as Comment);
      AlertService.toastSuccess('Se agregó el comentario');
      this.commentForm.patchValue({ comment: '' });
    }
    this._log.addApplicant(this.applicantKey, {
      description: `Se agregó un comentario`
    });
  }

  ngOnDestroy() {
    this.commentsSubscription.unsubscribe();
  }
}
