import {Component, Input, OnInit} from '@angular/core';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {Observable, of, Subscription} from 'rxjs';
import {CostCenterPayments} from '../../interfaces/cost-center-payments';
import {PaymentCategory} from '../../interfaces/payment-category';
import {CostCenterPaymentsService} from '../../services/cost-center-payments.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PaymentCategoryService} from '../../services/payment-category.service';
import {CostCenterPaymentsModalComponent} from '../cost-center-payments-modal/cost-center-payments-modal.component';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-cost-center-payments-view-modal',
  templateUrl: './cost-center-payments-view-modal.component.html',
  styleUrls: ['./cost-center-payments-view-modal.component.css']
})
export class CostCenterPaymentsViewModalComponent implements OnInit {
  costCenterKey: string;
  config: DataTableConfig = {hasSearch: true, notFoundText: 'No se encontraron pagos', title: 'Pagos de centro de costo'};
  payments$: Observable<CostCenterPayments[]>;
  payments: any;
  paymentCategories: PaymentCategory[];
  paymentCategoriesSubscription: Subscription = new Subscription();
  firstDay: number;
  lastDay: number;
  month: number;
  load: boolean = true;
  thereWasChange: boolean = false;

  constructor(private _payments: CostCenterPaymentsService,
              private modal: BsModalService,
              private _paymentCategory: PaymentCategoryService) {
  }

  async ngOnInit(): Promise<void> {
    this.paymentCategoriesSubscription = await this._paymentCategory.getAll(this.costCenterKey).subscribe(data => {
      this.paymentCategories = data;
    });

    this.payments$ = of(this.payments);
    this.load = false;
  }

  ngOnDestroy() {
    this.paymentCategoriesSubscription.unsubscribe();
  }

  openPaymentEdit(payment) {
    const modalRef: BsModalRef =
    this.modal.show(CostCenterPaymentsModalComponent, {
      initialState: {
        payment: payment,
        costCenterKey: this.costCenterKey,
        isUpdate: true
      },
      id: 34
    });

    modalRef.onHide.pipe(take(1)).subscribe(() => {
      if (modalRef.content.itHasUpdated) {
        let date = modalRef.content.date.slice(0, -3)
        let month: number = +date.substring(5);
        let day: number = +modalRef.content.date.substring(8);

        if (month == this.month && day > this.firstDay && day <= this.lastDay) return;

        let index = this.payments.findIndex(payment => payment.key == modalRef.content.payment.key);
        this.payments.splice(index, 1);
        this.thereWasChange = true;
      }
    })
  }

  getCategory(categoryReference) {
    if (this.paymentCategories){
      let category = this.paymentCategories.find(category => category.key == categoryReference.id);
      return category.name;
    }
  }

  getPaidClass(paid) {
    switch (paid) {
      case true:
        return 'badge-success';

      case false:
        return 'badge-primary';
    }
  }
}
