import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ProviderService} from '../../../shared/services/provider.service';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ProviderModalComponent} from '../../modals/provider-modal/provider-modal.component';
import {Provider} from '../../interfaces/provider';
import {ObjectService} from 'src/app/shared/template-services/object.service';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.css']
})
export class ProvidersComponent implements OnInit {
  providers$: Observable<Provider[]>;
  config: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron proveedores',
    title: '',
    exportCallback: this.decoratedProvidersToExport.bind(this),
    excelFileName: 'Proveedores'
  };

  constructor(private _provider: ProviderService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.getProviders();
  }

  getProviders() {
    this.providers$ = this._provider.getAll();
  }

  gotoOpenEditProvider(provider: Provider) {
    this.modal.show(ProviderModalComponent, {
      initialState: {
        provider: {...provider}
      },
      class: 'modal-xl',
      backdrop: 'static'
    });
  }

  openAddProviderModal() {
    this.modal.show(ProviderModalComponent, {
      class: 'modal-xl',
      backdrop: 'static'
    });
  }

  decoratedProvidersToExport(providers: Provider[]) {
    return ObjectService.replaceUndefined(providers.map(provider => ({
      name: provider.name,
      lineOfBusiness: provider.lineOfBusiness,
      city: provider.city,
      country: provider.country,
      contact: provider.website
    })));
  }
}
