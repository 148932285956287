import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Observable} from "rxjs";
import {Article} from "../../interfaces/article";
import {DataTableConfig} from "../../../../../shared/interfaces/data-table-config";
import {ArticleService} from "../../../../../shared/services/article.service";
import {BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-print-articles',
  templateUrl: './print-articles.component.html',
  styleUrls: ['./print-articles.component.css']
})
export class PrintArticlesComponent implements OnInit {
  @Input() warehouseKey: string;
  articles$: Observable<Article[]>;
  config: DataTableConfig = {
    title: "",
    notFoundText: "No se encontraron artículos",
    hasSearch: false
  };
  @ViewChild('componentID') componentID: ElementRef;

  constructor(private _article: ArticleService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.articles$ = this._article.getAllByWarehouse(this.warehouseKey);
  }

  print(): void {
    const printContent = this.componentID.nativeElement;

    const WindowPrt = window.open('', '', 'left=0,top=0,width=1200,height=800,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(`
    <html>
      <head>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" integrity="sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z" crossorigin="anonymous">
        <link rel="stylesheet" href="src/assets/sass/main.scss">
        <script src="https://kit.fontawesome.com/cef61353db.js" crossorigin="anonymous"></script>
        <style>

        .imageSize {
            object-fit: cover;
            height: 50px;
            width: 50px;
        }

        </style>
      </head>
      <body>
        ${printContent.innerHTML}
      </body>
    </html>`);
    WindowPrt.document.close();
    WindowPrt.focus();
    setTimeout(() => {
      WindowPrt.print();
    }, 2000)
  }

}
