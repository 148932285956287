<div class="p-1" [ngClass]="{'card': !isModal}">
  <div>
    <h4 class="mb-1" [ngClass]="{'modal-title': isModal}">Comprobante</h4>
  </div>
  <form [formGroup]="receiptForm">
    <div class="mt-1">
      <img
        [src]="!!path ? path : ''"
        class="w-100"
        alt="Receipt image"
        *ngIf="!!path"/>
    </div>
    <div class="mt-1 d-flex justify-content-center" *ngIf="permission == permissionEnum.USER">
      <app-upload-file-storage class="d-none"
                               label="imagen del comprobante"
                               extension="png"
                               controlName="photoUrl"
                               fileType="image/*"
                               [showPreview]="true"
                               (isImageLoaded)="setIsImageLoaded()"
                               (pathEmitter)="setPath($event)">
      </app-upload-file-storage>

      <div class="col-md-6 p-0">
        <button *ngIf="validateAttachImageButton()" class="btn btn-info w-100" (click)="clickButtonInChild()">
          <span><i class="fa fa-camera"></i> Adjuntar imagen</span>
        </button>
      </div>

    </div>

    <div class="mt-1 d-flex flex-md-column justify-content-sm-between">
      <div class="col-sm-4 p-0">
        <label>Categoría</label>
      </div>
      <div>
        <ng-select
          [items]="categories"
          [clearable]="false"
          [searchable]="false"
          bindLabel="name"
          formControlName="category"
          placeholder="Seleccione una categoría"
          notFoundText="No se encontraron categorías"
          style="width: 200px">
        </ng-select>
        <small
          class="text-danger"
          *ngIf="submitted && formControls.category.errors"
        >Campo obligatorio</small
        >
      </div>
    </div>

    <div class="mt-1 d-flex flex-md-column justify-content-sm-between">
      <div class="col-sm-4 p-0">
        <label>Monto</label>
      </div>
      <div class="col-sm-6 p-0">
        <input
          type="number"
          class="form-control"
          [ngClass]="{'is-invalid': submitted && formControls.amount.errors}"
          formControlName="amount"/>
        <small class="invalid-feedback">Campo obligatorio</small>
      </div>
    </div>

    <div class="mt-1">
      <label>Descripción</label>
      <textarea
        class="form-control"
        [ngClass]="{'is-invalid': submitted && formControls.description.errors}"
        formControlName="description"></textarea>
      <small class="invalid-feedback">Campo obligatorio</small>
    </div>

    <div class="mt-1 d-flex justify-content-end">
      <button
        *ngIf="permission == permissionEnum.SUPERVISOR"
        class="btn btn-info mr-1"
        (click)="acceptReceipt()">
        Aceptar
      </button>

      <button
        *ngIf="permission == permissionEnum.SUPERVISOR"
        class="btn btn-danger mr-1"
        (click)="rejectReceipt()">
        Rechazar
      </button>

      <button
        *ngIf="permission == permissionEnum.USER"
        class="btn btn-success mr-1"
        (click)="submit()">
        Guardar
      </button>

      <button
        *ngIf="permission == permissionEnum.USER"
        class="btn btn-danger mr-1"
        (click)="deleteReceipt()">
        Eliminar
      </button>

      <button
        *ngIf="permission == permissionEnum.USER"
        class="btn btn-danger mr-1"
        (click)="cancel()">
        Cancelar
      </button>
    </div>
  </form>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
><p style="color: white">Guardando datos...</p></ngx-spinner
>
