<div class="col-lg-9 col-md-6 col-sm-12 center-layout center">
  <div class="card shadow-none">
    <div class="card-body pt-2">
      <div class="form-group d-flex flex-row">
        <input type="text" class="form-control form-control-line" [(ngModel)]="name">
        <button class="btn btn-info ml-2" (click)="update()">Guardar</button>
        <button class="btn btn-primary ml-2" (click)="modal.hide()">Cerrar</button>
      </div>

      <div class="write-post">
        <div class="col-sm-12 px-2" *ngIf="proofOfPayment.type == 0">
          <div *ngIf="isPdf" [innerHTML]="url" ></div>
        </div>

        <div class="col-sm-12 px-2" *ngIf="proofOfPayment.type == 1">
          <button class="btn btn-primary" (click)="viewProof()">Ver comprobante</button>
        </div>
      </div>
    </div>
  </div>
</div>
