<mat-tab-group [(selectedIndex)]="tabIndex">
  <mat-tab label="Bodegas">
    <div class="d-flex justify-content-start mt-2 flex-wrap">
      <div *ngFor="let warehouse of warehouses$ | async"
           (click)="gotoWarehouseDetails(warehouse.key)"
           class="pr-1 cursor-pointer warehouse-card">
        <div class="card" [style.background-color]="!!warehouse.bgColor ? warehouse.bgColor : '#3498db'">
          <div class="card-body row">
            <div class="col-9">
              <h4 class="card-title text-white mb-0">{{ warehouse.name }}</h4>
              <ul class="list-unstyled users-list m-0">
                <li class="avatar avatar-xs pull-up"
                    *ngFor="let manager of warehouse.warehouseManager">
                  <img class="media-object rounded-circle"
                       container="body"
                       (click)="goToUserDetails(manager.reference.id)"
                       [ngbTooltip]="manager.name"
                       [src]="!!manager.imageUrl ? manager.imageUrl : '/assets/images/default.png'"
                       alt="Supervisor"/>
                </li>
              </ul>
              <p class="card-text text-white mt-1">{{ warehouse.description }}</p>
            </div>

            <div class="col-3 d-flex align-items-center">
              <i class="{{!!warehouse.icon ? warehouse.icon : 'icon-notebook'}} text-white font-large-2"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab
    label="Tickets autorizados"
    *ngIf="
      (permission == permissionEnum.GROCER ||
        permission == permissionEnum.ADMIN ||
        permission == permissionEnum.SUPERVISOR) &&
      !!ticketsAuthorized
    ">
    <app-tickets
      [isAll]="true"
      [unknownWarehouse]="true"
      [tickets$]="ticketsAuthorized"
      [statusSelected]="{
        name: 'AUTORIZADOS',
        status: ticketStatus.AUTHORIZED
      }"></app-tickets>
  </mat-tab>

  <mat-tab
    label="Tickets generados"
    *ngIf="
      (permission >= permissionEnum.SUPERVISOR ||
        permission == permissionEnum.ADMIN) &&
      !!ticketsGenerated
    ">
    <app-tickets
      [isAll]="true"
      [unknownWarehouse]="true"
      [tickets$]="ticketsGenerated"
      [statusSelected]="{
        name: 'GENERADOS',
        status: ticketStatus.GENERATED
      }"></app-tickets>
  </mat-tab>
</mat-tab-group>

<app-add-float-button
  *ngIf="
    tabIndex == 0 &&
    (permission >= permissionEnum.SUPERVISOR ||
      permission == permissionEnum.ADMIN)
  "
  (clickEmmiter)="openAddWarehousesModal()"></app-add-float-button>
