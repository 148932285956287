import {Component, OnDestroy, OnInit} from '@angular/core';
import {FundToRenderService} from '../../../../../shared/services/fund-to-render.service';
import {Subscription} from 'rxjs';
import {FundToRenderStatusEnum} from '../../enums/fund-to-render-status.enum';
import {UserService} from '../../../../../shared/services/user.service';
import {Accountability} from '../../interfaces/accountability';
import {AccountabilityService} from '../../services/accountability.service';
import {Router} from '@angular/router';
import {UserType} from '../../../../enums/user-type.enum';
import {AccountabilityStatusEnum} from '../../enums/accountability-status.enum';
import {AccountabilityStatusLabel} from '../../labels/accountability-status.label';
import {ReceiptService} from '../../services/receipt.service';
import {AccountabilityReceiptStatusEnum} from '../../enums/accountability-receipt-status.enum';
import {AccountabilityStatusClasses, AccountabilityStatusColor} from '../../../../data/accountability-status-classes';
import {ObjectService} from '../../../../../shared/template-services/object.service';
import {NgxSpinnerService} from 'ngx-spinner';
import _ from 'lodash';

@Component({
  selector: 'app-accountabilities',
  templateUrl: './accountabilities.component.html',
  styleUrls: ['./accountabilities.component.scss']
})
export class AccountabilitiesComponent implements OnInit, OnDestroy {
  accountabilities: Accountability[] = [];
  filteredAccountabilities: Accountability[] = [];
  accountabilitiesSubscription: Subscription = new Subscription();
  permission: any;
  accountabilityStatusLabel = AccountabilityStatusLabel;
  statusColor = AccountabilityStatusColor;
  filterStatus: AccountabilityStatusEnum[] = [
    AccountabilityStatusEnum.SUPERVISION,
    AccountabilityStatusEnum.REVIEW,
    AccountabilityStatusEnum.AUTHORIZED,
    AccountabilityStatusEnum.CLOSED,
    AccountabilityStatusEnum.CANCELLED,
    AccountabilityStatusEnum.REJECTED,
    AccountabilityStatusEnum.CLOSED_COLSAN,
    AccountabilityStatusEnum.CLOSED_USER,
    AccountabilityStatusEnum.PAID_COLSAN,
    AccountabilityStatusEnum.PAID_USER
  ];
  loading: boolean = true;
  accountabilityStatus = AccountabilityStatusEnum;

  constructor(private _accountability: AccountabilityService,
              private _fundToRender: FundToRenderService,
              private _user: UserService,
              private router: Router,
              private _receipt: ReceiptService,
              private spinner: NgxSpinnerService) {
  }

  async ngOnInit() {
    this.permission = this._user.getPermissionBySection('FONDOS POR RENDIR');

    this.spinner.show();

    this.accountabilitiesSubscription = this._accountability.getAll()
      .subscribe(async accountabilities => {
        this.accountabilities = this.filteredAccountabilities = await ObjectService.resolveChainPromise([
          this.populateAccountabilitiesWithFundToRender.bind(this),
          this.populateAccountabilitiesWithReceipts.bind(this),
          this.decorateAccountabilities.bind(this),
          this.filterAccountabilitiesByTravelAdvancePaid.bind(this),
          this.filterAccountabilitiesByUserType.bind(this),
          this.orderByFolio.bind(this),
        ], accountabilities);

        this.spinner.hide();
        this.loading = false;
      });
  }

  orderByFolio(accountabilities) {
    return _.orderBy(accountabilities, 'fundToRender.id', 'desc');
  }

  filterAccountabilitiesByUserType(accountabilities) {
    return this.permission == UserType.USER
      ? accountabilities.filter(accountability => accountability.fundToRender.user.key == this._user.user.key)
      : accountabilities;
  }

  filterAccountabilitiesByTravelAdvancePaid(accountabilities) {
    return accountabilities.filter(accountability => accountability.fundToRender.status >= FundToRenderStatusEnum.PAID)
  }

  decorateAccountabilities(accountabilities) {
    return accountabilities.map(accountability => ({
      ...accountability,
      totalAccepted: accountability.receiptsWithData.filter(receipt => receipt.status == AccountabilityReceiptStatusEnum.ACCEPTED).length,
      totalReceipts: accountability.receiptsWithData.length,
      totalAmount: accountability.receiptsWithData.reduce((acc, receipt) => acc + receipt.amount, 0),
      subtotal: accountability.receiptsWithData
        .filter(receipt => receipt.status == AccountabilityReceiptStatusEnum.ACCEPTED)
        .reduce((acc, receipt) => acc + receipt.amount, 0)
    }));
  }

  async populateAccountabilitiesWithReceipts(accountabilities) {
    return await this._accountability.populateArrayField(accountabilities.map(accountability => ({
        ...accountability,
        receiptsWithData: Object.entries(accountability.receipts).map(([key, value]) => this._accountability.getReference(`receipts/${key}`))
      })
    ), ['receiptsWithData']);
  }

  async populateAccountabilitiesWithFundToRender(accountabilities) {
    return await this._accountability.populate(accountabilities, ['fundToRender'], ['project', 'user']);
  }

  ngOnDestroy() {
    this.accountabilitiesSubscription.unsubscribe();
  }

  goToAccountabilitiesDetails({key}: Accountability) {
    return this.router.navigate(['admin/accountabilities-details', key]);
  }

  filterAccountabilitiesByStatus(statusSelected: string | number) {
    this.filteredAccountabilities = this.accountabilities
      .filter(accountability => statusSelected == 'all' || accountability.status == statusSelected);
  }

  handleMissingImage($event: ErrorEvent) {
    ($event.target as HTMLImageElement).src = 'assets/images/default.png';
  }
}
