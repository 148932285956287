<mat-tab-group>
  <mat-tab label="Editar">
    <div class="p-2" *ngIf="!!article">
      <h2>Editar artículo</h2>
      <p class="text-black-50">ID: {{article.key}}</p>
      <img class="size"
           src="{{!isValidPath() ? !!article.imageUrl ? article?.imageUrl : 'assets/images/article.svg' : path}}">

      <form [formGroup]="editArticleForm" (ngSubmit)="submit()">
        <div class="row mt-2">
          <div class="col-md-4 col-12">
            <div class="form-group">
              <label>Nombre *</label>
              <input type="text" class="form-control" formControlName="name"
                     placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.name.errors }"/>
              <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.name.errors">
                <div *ngIf=" formControls.name.errors.required">
                  El nombre es obligatorio
                </div>
              </small>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <div class="form-group">
              <label>Referencia interna *</label>
              <input type="text" class="form-control" formControlName="internalId"
                     placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.internalId.errors }"/>
              <small class="form-text text-muted danger invalid-feedback"
                     *ngIf="submitted && formControls.internalId.errors">
                <div *ngIf="formControls.internalId.errors.required">
                  Referencia interna es obligatorio
                </div>
              </small>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <div class="form-group">
              <label>Precio *</label>
              <input type="text" class="form-control" formControlName="price"
                     placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.price.errors }"/>
              <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.price.errors">
                <div *ngIf="formControls.price.errors.required">
                  El precio es obligatorio
                </div>
              </small>
            </div>
          </div>

          <div class="col-md-4 col-12" *ngIf="article.type == articleTypeEnum.CONSUMABLE">
            <div class="form-group">
              <label>Stock mínimo *</label>
              <input type="text" class="form-control" formControlName="minStock"
                     placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.minStock.errors }"/>
              <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.minStock.errors">
                <div *ngIf=" formControls.minStock.errors.required">
                  El stock mínimo es obligatorio
                </div>
              </small>
            </div>
          </div>
          <div class="col-md-4 col-12" *ngIf="article.type == articleTypeEnum.CONSUMABLE">
            <div class="form-group">
              <label>Stock máximo</label>
              <input type="text" class="form-control" formControlName="maxStock"
                     placeholder=""/>
            </div>
          </div>

          <div class="col-md-4 col-12">
            <div class="form-group">
              <label>Ubicación *</label>
              <input type="text" class="form-control" formControlName="location"
                     placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.location.errors }"/>
              <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.location.errors">
                <div *ngIf=" formControls.location.errors.required">
                  La ubicación es obligatoria
                </div>
              </small>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <div class="form-group">
              <label>Imagen *</label>
              <div class="custom-file">
                <input type="file" class="custom-file-input"
                       (change)="choosePicture($event)"
                       accept="image/*"
                       [ngClass]="{ 'is-invalid': submitted && formControls.imageUrl.errors }">
                <label class="custom-file-label" #labelImport>Elegir imagen</label>
                <small class="form-text text-muted danger" *ngIf="!photoValidation">
                  <div>
                    La imagen es obligatoria
                  </div>
                </small>
              </div>
            </div>
          </div>

          <div class="col-md-4 col-12">
            <div class="form-group">
              <label>Tipo *</label>
              <select class="form-control"
                      formControlName="type"
                      [ngClass]="{ 'is-invalid': submitted && formControls.type.errors }">
                <option [value]="type.key" *ngFor="let type of articleType | keyvalue">{{ type.value }}</option>
              </select>
              <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.type.errors">
                <div *ngIf=" formControls.type.errors.required">
                  El tipo es obligatorio
                </div>
              </small>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <div class="form-group">
              <label>Proveedor</label>
              <ng-select formControlName="provider" [items]="selectArrayProviders" [multiple]="false" bindLabel="name" appendTo="body"
                         placeholder="">
              </ng-select>
              <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.provider.errors">
                <div *ngIf=" formControls.provider.errors.required">
                  El tipo es obligatorio
                </div>
              </small>
            </div>
          </div>

          <div class="col-md-4 col-12">
            <div class="form-group">
              <label>Categorías *
                <div class="btn btn-success btn-sm" (click)="addCategory()">Agregar nueva</div>
              </label>
              <ng-select formControlName="categories" [items]="multipleSelectArray" [multiple]="true" bindLabel="name" appendTo="body"
                         placeholder="">
              </ng-select>
              <small class="form-text text-muted danger invalid-feedback"
                     *ngIf="submitted && formControls.categories.errors">
                <div *ngIf=" formControls.categories.errors.required">
                  El tipo es obligatorio
                </div>
              </small>
            </div>
          </div>

          <div class="col-md-4 col-12">
            <div class="form-group">
              <label>Unidades de medida *</label>
              <ng-select formControlName="unitMeasure" [items]="selectArray" bindLabel="name" appendTo="body"
                         [ngClass]="{ 'is-invalid': submitted && formControls.unitMeasure.errors }">
              </ng-select>
              <div *ngIf="submitted && formControls.unitMeasure.errors" class="invalid-feedback">
                Es obligatorio
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer border-0">
          <button class="btn btn-success add-btn">Actualizar</button>
          <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide()">Cancelar</div>
        </div>
      </form>

    </div>
  </mat-tab>

  <mat-tab label="Números seriales">
    <ng-container *ngFor="let record of article.serialNumber; let index = index">
      <div class="card-content mt-1">
        <div class="card-footer pt-0">
          <label class="sidebar-label mt-1">Serial n.º {{index + 1}}: {{record}}</label> <p></p>
        </div>
      </div>
    </ng-container>

    <div *ngIf="article.serialNumber && article.serialNumber.length > 0">
      <div class="mt-4">
        <app-not-found size="medium" [title]="config.notFoundText"></app-not-found>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Registros">
    <ng-container *ngFor="let record of records">
      <div class="card-content mt-1">
        <div class="card-footer pt-0">
          <label class="sidebar-label mt-1">{{record.action}}</label> <p></p>
          <i><label class="sidebar-label float-right">{{record.date | date: 'd/MM/yyyy'}} {{record.date | date: 'shortTime'}}</label></i>
        </div>
      </div>
    </ng-container>

    <div *ngIf="!records[0]">
      <div class="mt-4">
        <app-not-found size="medium" [title]="config.notFoundText"></app-not-found>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
