import {Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {Observable} from 'rxjs/internal/Observable';
import {FundToRenderStatusLabel} from '../../labels/fund-to-render-status.label';
import {DataTableConfig} from 'src/app/shared/interfaces/data-table-config';
import {FundToRenderService} from 'src/app/shared/services/fund-to-render.service';
import {AlertService} from 'src/app/shared/template-services/alert.service';
import {FundToRenderStatusEnum} from '../../enums/fund-to-render-status.enum';
import {UserType} from '../../../../enums/user-type.enum';
import {FundToRender} from '../../interfaces/fund-to-render';
import {FundToRenderModalComponent} from '../../modals/fund-to-render-modal/fund-to-render-modal.component';
import {UserService} from 'src/app/shared/services/user.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {of} from 'rxjs';
import {BreakdownAmountCategory} from '../../interfaces/breakdown-amount-catengory';
import {BreakdownAmountCategoryService} from '../../services/breakdown-amount-category.service';
import {first, map} from 'rxjs/operators';
import {DocumentReference} from '@angular/fire/firestore';
import {RequestNewFundToRenderComponent} from '../../modals/request-new-fund-to-render/request-new-fund-to-render.component';
import {RequestNewFundToRender} from '../../interfaces/request-new-fund-to-render';
import {NewFundToRenderService} from 'src/app/shared/services/new-fund-to-render.service';
import {FundToRenderRequestStatusEnum} from '../../enums/fund-to-render-request-status.enum';
import {AccountabilityService} from '../../services/accountability.service';
import {Router} from '@angular/router';
import _ from 'lodash';
import {ProjectService} from '../../../../../shared/services/project.service';

declare const $;

@Component({
  selector: 'app-funds-to-render',
  templateUrl: './funds-to-render.component.html',
  styleUrls: ['./funds-to-render.component.css']
})
export class FundsToRenderComponent implements OnInit, OnDestroy {
  config: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron resultados',
    title: '',
  };
  fundsToRender$: Observable<FundToRender[]>;
  filteredFundsToRender$: Observable<FundToRender[]>;
  travelAdvances: FundToRender[] = [];
  fundsToRenderSubscription: Subscription = new Subscription();
  breakdownAmountsCategories: BreakdownAmountCategory[] = [];
  breakdownAmountsCategoriesSubscription: Subscription = new Subscription();
  permissionEnum = UserType;
  permission: UserType;
  fundToRenderStatusEnum = FundToRenderStatusEnum;
  fundToRenderStatusLabel = FundToRenderStatusLabel;
  user: any;
  validTravelAdvance: any[] = [];
  pendingFundsToRender: any[] = [];
  requestsFundToRenderSubscription: Subscription = new Subscription();
  requestsNewFundToRender: RequestNewFundToRender[] = [];
  hasTravelAdvanceRejected: boolean;
  filterStatusSelected: string | number = 'all';
  filterStatus: any[] = [];
  fundToRenderRequestStatusEnum = FundToRenderRequestStatusEnum;
  accountabilities: any[] = [];

  constructor(private modal: BsModalService,
              private _fundsToRender: FundToRenderService,
              private _user: UserService,
              private _fundToRender: FundToRenderService,
              private _breakdownAmountCategory: BreakdownAmountCategoryService,
              private _requestNewFundToRender: NewFundToRenderService,
              private _accountability: AccountabilityService,
              private router: Router,
              private _project: ProjectService) {
  }

  async ngOnInit() {
    this.accountabilities = await this._accountability.getAll().pipe(first()).toPromise();
    this.createFilterStatus();
    this.listenForFundsToRender();
    this.listenForbreakdownAmountCategories();

    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'FONDOS POR RENDIR'
    ).permission;

    if (this.permission == this.permissionEnum.USER) {
      this.user = this._user.user;
    }

    this.listenForRequestsFundToRender();
  }

  ngOnDestroy(): void {
    this.breakdownAmountsCategoriesSubscription.unsubscribe();
    this.fundsToRenderSubscription.unsubscribe();
    this.requestsFundToRenderSubscription.unsubscribe();
  }

  createFilterStatus() {
    this.filterStatus = [
      {
        id: this.fundToRenderStatusEnum.DRAFT,
        label: this.fundToRenderStatusLabel[this.fundToRenderStatusEnum.DRAFT]
      },
      {
        id: this.fundToRenderStatusEnum.GENERATED,
        label:
          this.fundToRenderStatusLabel[this.fundToRenderStatusEnum.GENERATED]
      },
      {
        id: this.fundToRenderStatusEnum.REVIEW,
        label: this.fundToRenderStatusLabel[this.fundToRenderStatusEnum.REVIEW]
      },
      {
        id: this.fundToRenderStatusEnum.AUTHORIZED,
        label:
          this.fundToRenderStatusLabel[this.fundToRenderStatusEnum.AUTHORIZED]
      },
      {
        id: this.fundToRenderStatusEnum.CANCELLED,
        label:
          this.fundToRenderStatusLabel[this.fundToRenderStatusEnum.CANCELLED]
      },
      {
        id: this.fundToRenderStatusEnum.REJECTED,
        label:
          this.fundToRenderStatusLabel[this.fundToRenderStatusEnum.REJECTED]
      },
      {
        id: this.fundToRenderStatusEnum.PAID,
        label: this.fundToRenderStatusLabel[this.fundToRenderStatusEnum.PAID]
      },
      {
        id: this.fundToRenderStatusEnum.CLOSED,
        label: this.fundToRenderStatusLabel[this.fundToRenderStatusEnum.CLOSED]
      },
      {
        id: this.fundToRenderStatusEnum.CLOSED_COLSAN,
        label: this.fundToRenderStatusLabel[this.fundToRenderStatusEnum.CLOSED_COLSAN]
      },
      {
        id: this.fundToRenderStatusEnum.CLOSED_USER,
        label: this.fundToRenderStatusLabel[this.fundToRenderStatusEnum.CLOSED_USER]
      },
      {
        id: this.fundToRenderStatusEnum.PAID_COLSAN,
        label: this.fundToRenderStatusLabel[this.fundToRenderStatusEnum.PAID_COLSAN]
      },
      {
        id: this.fundToRenderStatusEnum.PAID_USER,
        label: this.fundToRenderStatusLabel[this.fundToRenderStatusEnum.PAID_USER]
      }
    ];
  }

  listenForRequestsFundToRender() {
    this.requestsFundToRenderSubscription = this._requestNewFundToRender
      .getAllByStatus([
        this.fundToRenderRequestStatusEnum.PENDING_SUPERVISOR_APROVAL,
        this.fundToRenderRequestStatusEnum.PENDING_ADMIN_APROVAL,
        this.fundToRenderRequestStatusEnum.ACCEPTED
      ])
      .subscribe((data) => {
        this.requestsNewFundToRender = data;
      });
  }

  listenForbreakdownAmountCategories() {
    this.breakdownAmountsCategoriesSubscription = this._breakdownAmountCategory
      .getAll()
      .subscribe((breakdownAmountsCategories) => {
        this.breakdownAmountsCategories = breakdownAmountsCategories;
        this.formatFundsToRender();
      });
  }

  listenForFundsToRender() {
    this.fundsToRenderSubscription = this._fundsToRender
      .getAll()
      .pipe(map((fundsToRender) => this.orderByCreateDate(this.filterFundsToRender(fundsToRender))))
      .subscribe((travelAdvances) => {
        this.travelAdvances = _.orderBy(travelAdvances.filter(travelAdvance => travelAdvance.createdAt), 'createdAt', 'desc');

        if (!travelAdvances.length) this.fundsToRender$ = of([]);

        this.formatFundsToRender();

        this.pendingFundsToRender = this.getPendingTravelAdvances();
        this.validTravelAdvance = this.getValidTravelAdvances();
        this.hasTravelAdvanceRejected = this.hasSomeTravelAdvanceRejected();
      });
  }

  getPendingTravelAdvances() {
    return this.travelAdvances.filter(travelAdvance =>
      travelAdvance.status < this.fundToRenderStatusEnum.CLOSED
    );
  }

  getValidTravelAdvances() {
    return this.travelAdvances.filter((travelAdvance) =>
      travelAdvance.status != this.fundToRenderStatusEnum.CANCELLED &&
      travelAdvance.status != this.fundToRenderStatusEnum.REJECTED &&
      travelAdvance.status != this.fundToRenderStatusEnum.CLOSED
    );
  }

  hasSomeTravelAdvanceRejected() {
    return this.travelAdvances.some((travelAdvance) =>
      travelAdvance.status == this.fundToRenderStatusEnum.REJECTED ||
      travelAdvance.status == this.fundToRenderStatusEnum.CANCELLED
    );
  }

  orderByCreateDate(fundsToRender: FundToRender[]) {
    return _.orderBy(fundsToRender, 'createdAt', 'desc');
  }

  formatFundsToRender() {
    if (!this.travelAdvances.length || !this.breakdownAmountsCategories.length) return;

    const fundsToRender = this.travelAdvances.map((fundToRender: any) => {
      if (!fundToRender.breakdownAmounts) return fundToRender;

      return this.formatFundToRenderBreakdownAmounts(fundToRender);
    });

    this.fundsToRender$ = of(fundsToRender);
    this.filteredFundsToRender$ = of(fundsToRender);
  }

  formatFundToRenderBreakdownAmounts(fundToRender) {
    return {
      ...fundToRender,
      breakdownAmounts: fundToRender.breakdownAmounts.map(
        (breakdownAmount: any) =>
          this.formatBreakdownAmountCategory(breakdownAmount)
      ),
      requiredAmount: fundToRender.breakdownAmounts.map(
        (breakdownAmount: any) =>
          this.formatBreakdownAmountCategory(breakdownAmount)).reduce(
        (acc, {amount}) => acc + amount,
        0
      ),
      accountability: this.accountabilities.find(accountability => accountability.fundToRender.id == fundToRender.key)
    };
  }

  formatBreakdownAmountCategory(breakdownAmount) {
    return {
      ...breakdownAmount,
      category: !!breakdownAmount.category.key
        ? breakdownAmount.category
        : this.breakdownAmountsCategories.find(
          (category: any) => category.key == breakdownAmount.category.id
        )
    };
  }

  filterFundsToRender(fundsToRender: FundToRender[]) {
    return fundsToRender.filter((fundToRender) => {
      return this.validateFundToRenderFilter(fundToRender);
    });
  }

  async userCancelFundToRender(fundToRender: FundToRender) {
    let rejectReason = await AlertService.withHtml(
      'Cancelar fondo por rendir',
      `<label>Razón de la cancelación:</label>
      <textarea id="swal-input1" rows="3" class="swal2-input"></textarea>`,
      function () {
        return new Promise(function (resolve) {
          resolve($('#swal-input1').val());
        });
      }
    );

    if (!rejectReason) return;

    await this._fundToRender.update(fundToRender.key, {
      status: this.fundToRenderStatusEnum.CANCELLED,
      rejectReason: rejectReason
    } as FundToRender);
  }

  viableCancelStatus(fundToRender: FundToRender) {
    return (
      fundToRender.status == this.fundToRenderStatusEnum.GENERATED ||
      fundToRender.status == this.fundToRenderStatusEnum.REVIEW
    );
  }

  validateFundToRenderRequestAccepted() {
    return this.requestsNewFundToRender.some(
      (request) =>
        request.user.id == this._user.user.key &&
        request.status == this.fundToRenderRequestStatusEnum.ACCEPTED
    );
  }

  getMyRequests() {
    return this.requestsNewFundToRender.filter((request: RequestNewFundToRender) => request.user.id == this._user.user.key);
  }

  hasPendingRequest() {
    const requests = this.getMyRequests();

    return requests.length || requests.some(request => request.status < this.fundToRenderRequestStatusEnum.REJECTED);
  }

  validateFundToRenderFilter(fundToRender: FundToRender): boolean {
    return (
      (this.permission == this.permissionEnum.USER &&
        (<DocumentReference>fundToRender.user).id == this._user.user.key) ||
      (this.permission == this.permissionEnum.ADMIN &&
        fundToRender.status != this.fundToRenderStatusEnum.DRAFT) ||
      (this.permission == this.permissionEnum.SUPERVISOR &&
        fundToRender.status != this.fundToRenderStatusEnum.DRAFT)
    );
  }

  async openAddFundToRenderModal() {
    this.modal.show(FundToRenderModalComponent, {
      initialState: {
        pendingAccountability: this._user.user.pendingAccountability
      }
    });
  }

  goToOpenEditFundToRender(fundToRender) {
    if (
      this.permission == this.permissionEnum.USER &&
      this.user.key != fundToRender.user.id
    ) {
      return AlertService.toastError(
        'Solo puedes modificar tus propios fondos por rendir'
      );
    }
    this.modal.show(FundToRenderModalComponent, {
      initialState: {
        fundToRender: {...fundToRender},
        isEdit: true
      },
      backdrop: 'static'
    });
  }

  async deleteFundToRender({key: fundToRenderKey, user}: FundToRender) {
    if (
      this.permission == this.permissionEnum.USER &&
      this.user.key != (<DocumentReference>user).id
    ) {
      return AlertService.toastError(
        'Solo puedes eliminar tus propios fondos por rendir'
      );
    }
    if (
      await AlertService.confirm(
        '¿Estás seguro que deseas eliminar este fondo por rendir?'
      )
    ) {
      await this._fundsToRender.delete(fundToRenderKey);
    }
    this.filterFundsToRenderByStatus();
  }

  getStatusClass(status: number) {
    switch (status) {
      case FundToRenderStatusEnum.DRAFT:
        return 'badge-primary';

      case FundToRenderStatusEnum.GENERATED:
        return 'badge-success';

      case FundToRenderStatusEnum.REVIEW:
        return 'badge-warning';

      case FundToRenderStatusEnum.AUTHORIZED:
        return 'badge-info';

      case FundToRenderStatusEnum.PAID:
        return 'badge-danger';

      case FundToRenderStatusEnum.RENDERED:
        return 'badge-warning';

      case FundToRenderStatusEnum.CLOSED:
        return 'badge-danger';

      case FundToRenderStatusEnum.CANCELLED:
        return 'badge-dark';

      case FundToRenderStatusEnum.REJECTED:
        return 'badge-dark';

      case FundToRenderStatusEnum.CLOSED_COLSAN:
        return 'badge-danger';

      case FundToRenderStatusEnum.CLOSED_USER:
        return 'badge-danger';

      case FundToRenderStatusEnum.PAID_USER:
        return 'badge-danger';

      case FundToRenderStatusEnum.PAID_COLSAN:
        return 'badge-danger';

      default:
        return 'badge-success';
    }
  }

  async sendRequestNewFundToRender() {
    if (
      this.hasPendingRequest() ||
      this.validateFundToRenderRequestAccepted()
    ) {
      return AlertService.toastError(
        'Ya no puede solicitar otro fondo por rendir'
      );
    }
    this.modal.show(RequestNewFundToRenderComponent, {
      initialState: {
        user: this.user
      }
    });
  }

  filterFundsToRenderByStatus() {
    if (!this.filterStatusSelected && !typeof 'number') return;
    if (this.filterStatusSelected == 'all') {
      this.filteredFundsToRender$ = this.fundsToRender$;
      return;
    }
    this.filteredFundsToRender$ = this.fundsToRender$.pipe(
      map((fundsToRender) =>
        fundsToRender.filter((fund) => fund.status == this.filterStatusSelected)
      )
    );
  }

  hasAccountability(fundToRender: FundToRender) {
    return this.accountabilities.some(accountability => accountability.fundToRender.id == fundToRender.key);
  }

  goToAccountability(fundToRender: FundToRender) {
    let accountability = this.accountabilities.find(accountability => accountability.fundToRender.id == fundToRender.key);

    this.router.navigateByUrl(`/admin/accountabilities-details/${accountability.key}`);
  }
}
