import { Component, OnInit } from '@angular/core';
import { BillDataService } from '../../services/bill-data.service';

@Component({
  selector: 'app-bill-proof-of-payment',
  templateUrl: './bill-proof-of-payment.component.html',
  styleUrls: ['./bill-proof-of-payment.component.css']
})
export class BillProofOfPaymentComponent implements OnInit {

  constructor(public _billData: BillDataService) {
  }

  ngOnInit(): void {
  }

}
