<div class="p-2">
  <h2>{{isUpdate ? 'Actualizar pago' : 'Agregar pago'}}</h2>

  <form [formGroup]="employeePaymentForm" (ngSubmit)="submit()">
    <div class="row mt-2">
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Fecha del pago *</label>
          <input class="form-control" type="date" formControlName="date"
                 [ngClass]="{ 'is-invalid': submitted && formControls.date.errors }">
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.date.errors">
            <div *ngIf=" formControls.date.errors.required">
              La fecha real de pago es obligatorio
            </div>
          </small>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Total de pagos *</label>
          <input type="number" class="form-control" formControlName="payments"
                 placeholder="" [ngClass]="{'is-invalid': submitted && formControls.payments.errors}"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.payments.errors">
            <div *ngIf=" formControls.payments.errors.required">
              El total de pagos es obligatorio
            </div>
          </small>
        </div>
      </div>
    </div>
    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn">{{isUpdate ? 'Actualizar' : 'Agregar'}}</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide()">Cancelar</div>
    </div>
  </form>
</div>

