<div class="p-2">
  <h2>Asignar categoría</h2>

  <form [formGroup]="categoryForm" (ngSubmit)="submit()">
    <div class="row mt-2">

      <div class="col-md-12 col-12">
        <div class="form-group">
          <label>Categoría *</label>
          <ng-select [items]="categories"
                     [searchable]="true"
                     bindLabel="name"
                     placeholder="Seleccione una categoría"
                     formControlName="category">
            <ng-template ng-option-tmp let-item="item">
              {{item.name}}
            </ng-template>
          </ng-select>
        </div>
      </div>

    </div>

    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn">Aceptar</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide()">Cancelar</div>
    </div>
  </form>
</div>
