<div class="p-1">
  <div class="modal-header p-1">
    <h4 class="pt-1">
      Agregar artículos
    </h4>
    <div>
      <a href="../../../../../../assets/templates/article_template.xlsx" class="btn btn-success mr-2">Descargar template</a>
      <div class="btn btn-danger" (click)="modal.hide()">Cerrar</div>
    </div>
  </div>
  <div class="modal-body">
    <div class="row mt-4">
      <div class="offset-3 col-6">
        <div class="custom-file">
          <input type="file"
                 class="upload-drop-zone upload-drop-zone.drop"
                 id="customFile"
                 accept=".csv, .xlsx, .xls"
                 [(ngModel)]="file"
                 (change)="extractDataFromExcelFile($event)">
        </div>
      </div>
    </div>
    <h1 class="text-center">Haz clic para agregar o arrastra el archivo en el recuadro</h1>
    <div *ngIf="articles.length > 0">
      <h4 class="mt-4">Lista de artículos cargados (total: {{articles.length}})</h4>
      <table class="table table-hover table-striped table-responsive mt-4 overflow-auto">
        <thead>
        <tr>
          <th>Nombre</th>
          <th>Tipo</th>
          <th>Identificador Interno</th>
          <th>Cantidad</th>
          <th>Precio</th>
          <th>Categorías</th>
          <th>Stock mínimo</th>
          <th>Stock máximo</th>
          <th>Ubicación</th>
          <th>Imagen URL</th>
          <th>Proveedor</th>
          <th>Números seriales</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let article of articles">
          <td>{{article.name}}</td>
          <td>{{article.type}}</td>
          <td>{{article.internalId}}</td>
          <td>{{article.quantity}}</td>
          <td>{{article.price}}</td>
          <td>{{article.categories}}</td>
          <td>{{article.minStock}}</td>
          <td>{{article.maxStock}}</td>
          <td>{{article.location}}</td>
          <td>{{article.imageUrl}}</td>
          <td>{{article.provider}}</td>
          <td>{{article.serialNumber}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="row" *ngIf="articles.length > 0">
      <div class="col d-flex justify-content-end">
        <button class="btn btn-primary" (click)="saveArticles()">Registrar artículos</button>
      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
             type="square-jelly-box"><p style="color: white"> {{messageLoad}} </p></ngx-spinner>
