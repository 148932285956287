import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import * as XLSX from 'xlsx';
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-add-bills',
  templateUrl: './add-bills.component.html',
  styleUrls: ['./add-bills.component.css']
})
export class AddBillsComponent implements OnInit {
  data: any[];
  file: any;
  headers: string[] = [];
  excelExtension = '.xlsx';
  items: any[] = [];
  count = [];
  register: boolean = false;
  name: string = '';
  topic: string = '';
  description: string = '';

  constructor(public modal: BsModalRef) {
  }

  async ngOnInit(): Promise<void> {

  }

  extractDataFromExcelFile(event, file): void {
    const target: DataTransfer = <DataTransfer>(event.target);

    if (target.files.length !== 1) {
      AlertService.error('No se pueden cargar multiples archivos', '');
      return;
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;

      let numberCharacterAfterPoint = file.value.indexOf('.') + 1;
      if (file.value.substring(numberCharacterAfterPoint) == 'csv') {
        let rows = bstr.split('\n');
        for (let row of rows) {
          this.data.push(row.split(';'));
        }
      } else {
        const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        this.data = (XLSX.utils.sheet_to_json(ws, {header: 1}));
      }
      this.formatXmlRows();
    };
    reader.readAsBinaryString(target.files[0]);
  }

  async formatXmlRows(): Promise<void> {
    this.items = [];
    let alreadyCounted = false;

    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].length == 0) continue;
      let item = {} as any;

      if (!alreadyCounted) {
        for (let i in this.data[0]) {
          await this.headers.push(`h${i}`);
          await this.count.push({count: `h${i}`});
        }
        alreadyCounted = true;
      }

      for (let j = 0; j < this.data[i].length; j++) {
        if (this.data[i][j] == undefined) {
          this.data[i][j] = '';
        }
        item[this.headers[j]] = this.data[i][j];
      }

      await this.items.push(item);
    }
  }

  async sendData() {
    this.register = true;
    this.modal.hide();
  }
}
