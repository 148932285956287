<div
  *ngIf="hasPendingRequest()"
  class="fit-content alert alert-info mb-2"
  role="alert">
  Actualmente tiene 1 solicitud siendo procesada
</div>

<div class="card mb-0">
  <div class="p-1 d-flex">
    <ng-select
      [clearable]="false"
      style="width: 300px"
      [(ngModel)]="filterStatusSelected"
      (ngModelChange)="filterFundsToRenderByStatus()">
      <ng-option [value]="'all'"> Todos</ng-option>
      <ng-option
        [value]="item.id"
        *ngFor="let item of filterStatus">
        {{ item.label }}
      </ng-option>
    </ng-select>
  </div>
</div>
<ng-container *ngIf="travelAdvances.length > 0; else notFound">
  <app-datatable
    [rows$]="filteredFundsToRender$"
    [config]="config">

    <app-datatable-column title="ID">
      <ng-template
        let-row
        #column>
        {{ row?.id }}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Título">
      <ng-template
        let-row
        #column>
        {{ row.title }}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Proyecto">
      <ng-template
        let-row
        #column>
        {{ !!row.project ? (row.project | doc | async)?.name : '-' }}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Usuario">
      <ng-template
        let-row
        #column>
        {{ !!row.user ? (row.user | doc | async)?.name : '-' }}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Monto solicitado">
      <ng-template
        let-row
        #column>
        {{ row.requiredAmount | currency : 'USD' : 'symbol' : '1.0-0'}}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Estatus">
      <ng-template
        let-row
        #column>
        <div
          class="badge badge-pill"
          [ngClass]="getStatusClass(row.status)">
          {{ fundToRenderStatusLabel[row.status] }}
        </div>
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Fecha">
      <ng-template
        let-row
        #column>
        {{ row?.createdAt | date : 'dd/MM/yyyy' }}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column
      *ngIf="permission == permissionEnum.USER && !!hasTravelAdvanceRejected"
      title="Motivo del rechazo">
      <ng-template
        let-row
        #column>
        {{ !!row.rejectReason ? row.rejectReason : '-' }}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column [isClickable]="false">
      <ng-template
        let-row
        #column>
        <div class="d-flex justify-content-end">

          <button
            *ngIf="!row.accountability?.trash && hasAccountability(row)"
            class="btn btn-info mr-1"
            ngbTooltip="Ir a la rendición"
            (click)="goToAccountability(row)">
            <i class="la la-money"></i>
          </button>

          <button
            class="btn btn-success mr-1"
            [id]="'details-' + row.key"
            ngbTooltip="Ver detalles"
            (click)="goToOpenEditFundToRender(row)">
            <i class="la la-edit"></i>
          </button>

          <button
            *ngIf="permission == permissionEnum.USER && row.status == fundToRenderStatusEnum.DRAFT"
            [id]="'delete-' + row.key"
            class="btn btn-danger"
            ngbTooltip="Eliminar"
            (click)="deleteFundToRender(row)">
            <i class="la la-trash"></i>
          </button>

          <button
            *ngIf="viableCancelStatus(row)"
            [id]="'cancel-' + row.key"
            class="btn btn-danger"
            ngbTooltip="Cancelar"
            (click)="userCancelFundToRender(row)">
            <i class="la la-times"></i>
          </button>
        </div>
      </ng-template>
    </app-datatable-column>
  </app-datatable>
</ng-container>

<button
  *ngIf="pendingFundsToRender.length && !hasPendingRequest()"
  (click)="sendRequestNewFundToRender()"
  class="btn btn-info select-float-button"
  tooltip="Solicitar otro fondo por rendir">
  <i class="la la-file-text icon-size"></i>
</button>

<app-add-float-button
  *ngIf="!pendingFundsToRender.length && !hasPendingRequest()"
  (clickEmmiter)="openAddFundToRenderModal()">
</app-add-float-button>

<ng-template #notFound>
  <app-not-found
    size="medium"
    title="No se encontraron resultados"></app-not-found>
</ng-template>
