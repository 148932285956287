import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../shared/template-services/alert.service';
import {Status} from '../../interfaces/status';
import {StatusService} from '../../../shared/services/status.service';

@Component({
  selector: 'app-add-status',
  templateUrl: './add-status.component.html',
  styleUrls: ['./add-status.component.css']
})
export class AddStatusComponent implements OnInit {
  update: boolean;
  status: Status;
  statusForm: FormGroup;
  submitted: boolean = false;

  constructor(public modal: BsModalRef,
              private formBuilder: FormBuilder,
              private _status: StatusService) {
    this.statusForm = formBuilder.group({
      name: ['', Validators.required],
      trash: [false]
    });
  }

  ngOnInit(): void {
    if (this.update) this.statusForm.patchValue({name: this.status.name})
  }

  get formControls() {
    return this.statusForm.controls;
  }

  async submit() {
    this.submitted = true;
    if (this.statusForm.valid) {
      this.update ? await this._status.update(this.status.key, this.statusForm.value as Status) : await this._status.add(this.statusForm.value as Status);
      AlertService.toastSuccess(this.update ? 'Se actualizó el estatus' : 'Se agrego el estatus');
      this.modal.hide();
    }
  }
}
