import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Board} from '../interfaces/board';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {KanbanStatus} from '../pages/kanban/enums/kanban-status.enum';
import {TaskOverhaul} from '../pages/kanban/enums/task-overhaul';
import {Task} from '../pages/kanban/interfaces/task';

@Injectable({
  providedIn: 'root'
})
export class BoardService {
  constructor(private db: FirebaseDataService, private afs: AngularFirestore) {}

  getAll(): Observable<Board[]> {
    return this.db.colWithIds$('boards', (ref) =>
      ref.where('trash', '==', false)
    );
  }

  async set(board: Board, boardKey: string) {
    return this.afs.doc(`boards/${boardKey}`).set(board, {merge: true});
  }

  delete(boardKey: string) {
    return this.afs.doc(`boards/${boardKey}`).update({trash: true});
  }

  getTasksCompleted(boardKey: string): Observable<Task[]> {
    return this.db.colWithIds$(`boards/${boardKey}/tasks`, (ref) =>
      ref
        .where('trash', '==', false)
        .where('status', '==', KanbanStatus.DONE)
        .where('overhaul', '==', TaskOverhaul.COMPLETED)
    );
  }

  get(boardKey: string): Observable<Board> {
    return this.db.docWithId$(`boards/${boardKey}`);
  }
}
