<div class="p-2">
  <h2>Notificación</h2>

  <form [formGroup]="notificationForm">
    <div class="row mt-2">
      <div class="form-group col-12">
        <label>Usuarios *</label>
        <ng-select [items]="users"
                   [searchable]="true"
                   [multiple]="true"
                   bindLabel="name"
                   formControlName="users"
                   [ngClass]="{ 'is-invalid': submitted && formControls.users.errors }"
                   placeholder="Seleccionar un usuario">
          <ng-template ng-option-tmp let-item="item">
            {{item.name}} {{item.surnames}}
          </ng-template>

          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-label">{{item.name}} {{item.surnames}}</span>
            <span class="ng-value-icon right" (click)="clear(item)">×</span>
          </ng-template>
        </ng-select>
        <small class="form-text text-muted danger invalid-feedback"
               *ngIf="submitted && formControls.users.errors">
          <div *ngIf="formControls.users.errors.required">
            Debe de elegir un usuario
          </div>
        </small>
      </div>

      <div class="col-md-12">
        <div class="form-group">
          <label>Mensaje</label>
            <textarea rows="8"
                      formControlName="message"
                      type="text"
                      [ngClass]="{ 'is-invalid': submitted && formControls.message.errors }"
                      class="form-control"></textarea>
        </div>
      </div>

    </div>
    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn" (click)="submit()">Enviar</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide()">Cancelar</div>
    </div>
  </form>
</div>

