import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {Observable} from 'rxjs/internal/Observable';
import {FirebaseDataService} from '../../../../shared/template-services/firebase-data.service';
import {BreakdownAmountCategory} from '../interfaces/breakdown-amount-catengory';

@Injectable({
  providedIn: 'root'
})
export class BreakdownAmountCategoryService extends FirebaseDataService {
  constructor(
    private _db: AngularFirestore,
    private _storage: AngularFireStorage
  ) {
    super(_db, _storage);
  }

  getAll(): Observable<BreakdownAmountCategory[]> {
    return this.colWithIds$('breakdownAmountCategories', (ref) =>
      ref.where('trash', '==', false)
    );
  }

  add(name: string): Promise<DocumentReference> {
    return this._db.collection(`breakdownAmountCategories`).add({
      name,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      trash: false
    });
  }

  update(key: string, name: string): Promise<void> {
    return this._db.doc(`breakdownAmountCategories/${key}`).update({
      name,
      updatedAt: new Date().getTime()
    });
  }

  delete(key: string): Promise<void> {
    return this._db
      .doc(`breakdownAmountCategories/${key}`)
      .update({trash: true});
  }
}
