import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {Observable} from 'rxjs/internal/Observable';
import {take} from 'rxjs/internal/operators/take';
import {FundToRender} from 'src/app/admin/pages/travel-advance/interfaces/fund-to-render';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {FundToRenderStatusEnum} from '../../admin/pages/travel-advance/enums/fund-to-render-status.enum';

@Injectable({
  providedIn: 'root'
})
export class FundToRenderService {
  constructor(
    private db: FirebaseDataService,
    private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) {}

  getAll(): Observable<FundToRender[]> {
    return this.db.colWithIds$('fundToRender', (ref) =>
      ref.where('trash', '==', false)
    );
  }

  getByStatus(status: FundToRenderStatusEnum): Observable<FundToRender[]> {
    return this.db.colWithIds$('fundToRender', (ref) =>
      ref.where('trash', '==', false).where('status', '==', status)
    );
  }

  get(fundToRenderKey: string): Observable<FundToRender> {
    return this.db.docWithId$(`fundToRender/${fundToRenderKey}`);
  }

  add(fundToRender: FundToRender) {
    fundToRender['createdAt'] = new Date().getTime();
    fundToRender['updatedAt'] = new Date().getTime();
    return this.afs.collection('fundToRender').add(fundToRender);
  }

  update(fundToRenderKey: string, fundToRender: FundToRender): Promise<void> {
    fundToRender['updatedAt'] = new Date().getTime();
    return this.afs.doc(`fundToRender/${fundToRenderKey}`).update(fundToRender);
  }

  delete(fundToRenderKey: string): Promise<void> {
    return this.afs
      .doc(`fundToRender/${fundToRenderKey}`)
      .update({trash: true});
  }

  getSettings() {
    return this.db
      .docWithId$(`settings/fundsToRender`)
      .pipe(take(1))
      .toPromise();
  }

  setSettings(setting) {
    return this.db.doc(`settings/fundsToRender`).set(setting);
  }

  updateSettings(setting) {
    return this.db.doc(`settings/fundsToRender`).update(setting);
  }

  getReference(path: string) {
    return this.db.getReference(path);
  }

  async uploadPdf(title, fundToRenderKey) {
    const uploadRef = this.getStorageRefFile(fundToRenderKey);
    await uploadRef.put(title);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadFileStorage(title, fundToRenderKey);

    return url;
  }

  uploadFileStorage(data, id) {
    return this.storage.upload(`fundToRender/${id}/fundToRenderPdf.pdf`, data);
  }

  getStorageRefFile(id) {
    return this.storage.ref(`fundToRender/${id}/fundToRenderPdf.pdf`);
  }
}
