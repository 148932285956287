import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { DataTableConfig } from '../../../shared/interfaces/data-table-config';
import { BillService } from '../../../shared/services/bill.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BillModalComponent } from '../../modals/bill-modal/bill-modal.component';
import { first, map, take } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddToExcelComponent } from '../../components/add-to-excel/add-to-excel.component';
import { Bill } from '../../interfaces/bill';
import { PurchaseStatus } from '../../enums/purchase-status.enum';
import { PurchaseOrderService } from '../../../shared/services/purchase-order.service';
import { PurchaseOrder } from '../purchase-orders/interfaces/purchase-order';
import { UserService } from '../../../shared/services/user.service';
import { UserType } from '../../enums/user-type.enum';
import { AlertService } from '../../../shared/template-services/alert.service';
import { formatDate } from '@angular/common';
import * as _ from 'lodash';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ObjectService } from 'src/app/shared/template-services/object.service';
import moment from 'moment';
import { PaymentCategoryService } from '../../services/payment-category.service';
import { ProviderService } from '../../../shared/services/provider.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BillModalV2Component } from '../../modals/bill-modal-v2/bill-modal-v2.component';
import { CostCenterType } from '../../enums/cost-center-type.enum';

@Component({
  selector: 'app-bills',
  templateUrl: './bills.component.html',
  styleUrls: ['./bills.component.css']
})
export class BillsComponent implements OnInit, OnDestroy {
  bills$: Observable<Bill[]>;
  billsFiltered: Bill[] = [];
  config: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron facturas',
    title: '',
    propTotal: 'total',
    pagination: true,
    paginationLimit: 25
  };
  startDate: any = null;
  finalDate: any = null;
  status: any;
  purchaseOrdersSubscription: Subscription = new Subscription();
  purchaseOrders: PurchaseOrder[] = [];
  permission: UserType;
  permissionEnum = UserType;
  isClassified: boolean;
  isExpirated: boolean = null;
  providers: any[] = [];
  providerSelected = null;
  statusNotPayed: boolean;
  statusPayed: boolean;
  isnClassified: boolean;
  isnExpirated: boolean;
  indexedPurchaseOrders = {};
  projectsSubscription: Subscription = new Subscription();
  indexedProjects;
  projects;
  today;
  todayMs;
  isOpenModal: boolean;
  indexedBills = {};
  indexedProviders = {};
  categoriesIndexed = {};
  billSubscription: Subscription = new Subscription();
  paymentCategoriesSubscription: Subscription = new Subscription();
  proofOfPaymentSubscription: Subscription = new Subscription();
  categoriesSubscription: Subscription = new Subscription();
  paymentCategories: any[] = [];
  proofOfPayments: any[] = [];
  isUploading: boolean = false;
  bills;

  constructor(private _bill: BillService,
              private modal: BsModalService,
              private SpinnerService: NgxSpinnerService,
              private _purchase: PurchaseOrderService,
              private _user: UserService,
              private _project: ProjectService,
              private _paymentCategory: PaymentCategoryService,
              private http: HttpClient,
              private _provider: ProviderService) {
  }

  ngOnInit() {
    this.loadProjects();
    this.getBills();
    this.listenPaymentCategories();
    this.loadProviders();
    this.listenProOfPayments();
    this.listenCategories();

    this.today = this.formatDate(new Date().getTime());
    this.todayMs = new Date().getTime();
    const currentDate: Date = new Date();
    const date = new Date(
      `${new Date().getMonth() + 1}/01/${new Date().getFullYear()}`
    );
    this.startDate = this.formatDate(
      moment(date).subtract(3, 'month').format('YYYY-MM-DD')
    );
    this.finalDate = this.formatDate(currentDate);
    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'TESORERÍA'
    ).permission;

    this.purchaseOrdersSubscription = this._purchase
      .getAll()
      .subscribe(async (data) => {
        this.indexedPurchaseOrders = ObjectService.indexArray(data, 'key');

        this.purchaseOrders = (await this._bill.db.populate(data.filter(
          (purchaseOrder) => purchaseOrder.status >= PurchaseStatus.AUTHORIZED
        ), ['project', 'budget'])) as any;
      });
  }

  ngOnDestroy() {
    this.purchaseOrdersSubscription.unsubscribe();
    this.projectsSubscription.unsubscribe();
    this.billSubscription.unsubscribe();
    this.paymentCategoriesSubscription.unsubscribe();
    this.proofOfPaymentSubscription.unsubscribe();
    this.categoriesSubscription.unsubscribe();
  }

  listenPaymentCategories() {
    this.paymentCategoriesSubscription = this._paymentCategory
      .getAllWithoutCostCenter()
      .subscribe((data) => {
        this.paymentCategories = data;
      });
  }

  async loadProviders() {
    this.indexedProviders = ObjectService.indexArray(
      await this._provider.getAll().pipe(take(1)).toPromise(),
      'key'
    );
  }

  listenProOfPayments() {
    this.proofOfPaymentSubscription = this._bill.getAllProofPayments().subscribe((data) => {
      this.proofOfPayments = data.filter((proOfPayment) => proOfPayment.type == 1);
    });
  }

  listenCategories() {
    this.categoriesSubscription = this._project.getAllCategories().subscribe((data) => {
      this.categoriesIndexed = ObjectService.indexArray(data, 'key');
    });
  }

  private formatDate(date) {
    const deliveryDateDate = new Date(date);
    const format = 'yyyy/MM/dd';
    const locale = 'en-US';
    const zone = 'UTC';
    const formattedDate = formatDate(deliveryDateDate, format, locale, zone);
    return formattedDate.replace(/\//g, '-');
  }

  async getBills() {
    this.billSubscription = this._bill.getAll().pipe(
      map((categories) =>
        categories.map((category) => {
          let isPaid = category.isPaid == undefined ? false : category.isPaid;
          return {
            ...category,
            isPaid,
            isExpirated:
              moment(category.expectedPaymentDate).toDate().getTime() <
              moment().startOf('day').toDate().getTime() && !isPaid
          };
        })
      )
    ).subscribe((bills) => {
      this.billsFiltered = bills;
      this.bills$ = of(bills);
      this.bills = bills.map(bill => ({
        ...bill,
        provider: bill.provider.toUpperCase()
      }));
      this.filterBills();

      this.providers = _.uniqBy(this.bills, 'provider');

      this.indexedBills = ObjectService.indexArray(this.bills, 'key');
    });
  }

  loadProjects() {
    this.projectsSubscription = this._project.getAll().subscribe((projects) => {
      this.indexedProjects = ObjectService.indexArray(projects, 'key');
      this.projects = projects.map(project => ({
        ...project,
        type:
          project.type == CostCenterType.PROJECT
            ? 'Proyectos'
            : 'Centro de Costos'
      }));
    });
  }

  async gotoOpenEditBill(bill, openOldVersion = false) {
    this.isOpenModal = true;
    let modalRef: BsModalRef;

    if (openOldVersion) {
      modalRef = this.modal.show(BillModalComponent, {
        initialState: {
          bill: _.cloneDeep(bill),
          indexedBills: { ...this.indexedBills },
          paymentCategories: this.paymentCategories,
          indexedProviders: this.indexedProviders,
          proofOfPayments: this.proofOfPayments,
          categoriesIndexed: this.categoriesIndexed,
          isUpdate: true,
          permission: this.permission,
          purchaseOrders: this.purchaseOrders
        },
        class: 'modal-xl',
        backdrop: 'static',
        id: 8177
      });
    } else {
      modalRef = this.modal.show(BillModalV2Component, {
        initialState: {
          bill: _.cloneDeep(bill),
          indexedBills: { ...this.indexedBills },
          paymentCategories: this.paymentCategories,
          indexedProviders: this.indexedProviders,
          proofOfPayments: this.proofOfPayments,
          categoriesIndexed: this.categoriesIndexed,
          isUpdate: true,
          permission: this.permission,
          purchaseOrders: this.purchaseOrders,
          projects: this.projects,
          indexedPurchaseOrders: this.indexedPurchaseOrders,
          indexedProjects: this.indexedProjects
        },
        class: 'modal-xl',
        backdrop: 'static',
        id: 8177
      });
    }

    await modalRef.onHidden.pipe(first()).toPromise();
    this.isOpenModal = false;
  }

  openAddBillModal() {
    this.modal.show(BillModalComponent, {
      initialState: {
        isUpdate: false,
        indexedBills: { ...this.indexedBills },
        paymentCategories: this.paymentCategories,
        indexedProviders: this.indexedProviders,
        proofOfPayments: this.proofOfPayments,
        purchaseOrders: this.purchaseOrders,
        categoriesIndexed: this.categoriesIndexed,
        permission: this.permission
      },
      class: 'modal-lg',
      backdrop: 'static',
      id: 8177
    });
  }

  addToExcel() {
    const modalRef = this.modal.show(AddToExcelComponent, {
      class: 'modal-md'
    });

    modalRef.onHide.pipe(take(1)).subscribe(async () => {
      if (modalRef.content.send) {
        this.billSubscription.unsubscribe();
        this.isUploading = true;
        this.SpinnerService.show();

        const formData = new FormData();
        formData.append('file', modalRef.content.file);

        try {
          const resp: any = await this.http.post(`${environment.apiBaseURLV1}/bills/massive`, formData).pipe(first()).toPromise();
          this.SpinnerService.hide();

          resp.ok
            ? AlertService.toastSuccess('Se han agregado las facturas correctamente')
            : AlertService.toastError('Ha ocurrido un error al agregar las facturas');
        } catch (error) {
          this.SpinnerService.hide();
          AlertService.toastError('Ha ocurrido un error al agregar las facturas');
        }

        setTimeout(() => {
          this.isUploading = false;
          this.getBills();
        }, 3000);
      }
    });
  }

  async resetBills() {
    return this.billsFiltered = await this.bills$.pipe(take(1)).toPromise();
  }

  async filterBills() {
    if (this.status == 99) {
      return this.resetBills();
    }

    if (
      !this.startDate &&
      !this.finalDate &&
      this.status == undefined &&
      this.isClassified == undefined
    ) {
      return;
    }

    let startDate;
    let startDateMilliseconds;
    let finalDate;
    let finalDateMilliseconds;

    if (this.startDate) {
      startDate = this.startDate.replaceAll('-', '/');
      startDateMilliseconds = new Date(startDate).getTime();
    } else {
      startDateMilliseconds = 0;
    }

    if (this.finalDate) {
      finalDate = this.finalDate.replaceAll('-', '/');
      finalDateMilliseconds = new Date(finalDate).getTime();
    } else {
      finalDateMilliseconds = Infinity;
    }

    this.billsFiltered = await this.bills$.pipe(
      map((project) =>
        project
          .filter(
            (bill) =>
              new Date(!this.isExpirated ? bill.startDate : bill.expectedPaymentDate).getTime() >= startDateMilliseconds &&
              new Date(!this.isExpirated ? bill.startDate : bill.expectedPaymentDate).getTime() <= finalDateMilliseconds
          )
          .filter((bill) => {
            if (this.statusNotPayed && this.statusPayed) {
              return true;
            } else if (this.statusPayed) {
              return bill.isPaid;
            } else if (this.statusNotPayed) {
              return !bill.isPaid;
            }

            return true;
          })
          .filter((bill) => {
            if (this.isClassified && this.isnClassified) {
              return true;
            } else if (this.isClassified) {
              return bill.isUsed;
            } else if (this.isnClassified) {
              return !bill.isUsed;
            }

            return true;
          })
          .filter((bill) => {
            if (this.isExpirated && this.isnExpirated) {
              return true;
            } else if (this.isExpirated) {
              return bill.isExpirated;
            } else if (this.isnExpirated) {
              return !bill.isExpirated;
            }

            return true;
          })
          .filter((bill) => {
            if (!this.providerSelected) return true;

            return this.providerSelected.provider.toUpperCase().trim() == bill.provider.toUpperCase().trim();
          })
      )
    ).pipe(take(1)).toPromise();
  }

  public getRut(provider) {
    if (!provider) {
      return '-';
    }
    return provider.substr(0, provider.indexOf(' '));
  }

  public getProvider(provider) {
    if (!provider) {
      return '-';
    }
    return provider.substr(provider.indexOf(' ') + 1);
  }

  async deleteBill(billKey: string) {
    if (
      await AlertService.confirm(
        '¿Estás seguro que deseas eliminar esta factura?'
      )
    ) {
      this._bill.delete(billKey);
    }
  }

  showBillCostCenter(bill) {
    if (bill.costCenter == 'SIN CC' || !!bill.costCenter) {
      return bill.costCenter;
    } else if (!!bill.purchaseOrder) {
      return `OC ${this.indexedPurchaseOrders[
        this.getBillPurchaseOrderKey(bill.purchaseOrder)
        ]?.purchaseID.slice(0, -3)}`;
    } else if (!!bill.project) {
      return this.indexedProjects[this.getBillProjectKey(bill.project)]?.name;
    } else {
      return '-';
    }
  }

  getBillProjectKey(project) {
    return !!project.id ? project.id : project.key;
  }

  getBillPurchaseOrderKey(purchaseOrder) {
    return !!purchaseOrder.id ? purchaseOrder.id : purchaseOrder.key;
  }
}
