import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dotsCurrency'
})
export class DotsCurrencyPipe implements PipeTransform {

  transform(value: string): unknown {
    return value.replaceAll(',', '.');
  }

}
