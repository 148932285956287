<ng-container *ngIf="receipts$ | async as receipts">
  <div class="d-flex justify-content-between">
    <div class="w-25">
      <ng-select
        [items]="receiptCategories"
        placeholder="Filtrar por categoría"
        bindLabel="name"
        bindValue="key"
        appendTo="body"
        [(ngModel)]="selectedCategory">
      </ng-select>
    </div>

    <ti-input
      placeholder="Buscar..."
      [(model)]="searchValue"></ti-input>
  </div>

  <ng-container *ngIf="receipts.length > 0; else notFound">
    <div
      *ngIf="!!receiptSelection"
      class="fit-content alert alert-info"
      role="alert">
      Favor de seleccionar los comprobantes que desea enviar
    </div>
    <div class="row gallery">
      <div
        class="card mb-0 col-md-4 mw-100"
        *ngFor="let receipt of receipts | filter : searchValue | filter : selectedCategory : ['category.key']">
        <div
          class="row"
          (click)="selectReceipt(receipt)"
          [ngClass]="{
            'card-selection-color': !!receipt.isSelected && !!receiptSelection
          }">
          <div class="col-4 pl-0">
            <img
              class=""
              [src]="receipt.photoUrl"
              alt="Imagen del comprobante"/>
          </div>
          <div class="col-8 pl-0">
            <div class="p-1 h-100 d-flex flex-column justify-content-between">
              <div>
                <h5 class="m-0">
                  <strong>{{ receipt.description }}</strong>
                </h5>
                <small>{{ receipt.createdAt | date: 'dd/MM/yyyy' }}</small>
              </div>

              <div class="d-flex justify-content-between">
                <span class="badge badge-info">{{
                  receipt?.category.name
                  }}</span>
                <span class="text-success">{{
                  receipt?.amount | currency
                  }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<button
  class="add-float-button .btn btn-primary"
  (click)="goToNewReceipt()"
  *ngIf="userPermission == userTypeEnum.USER">
  <i class="la la-plus"></i>
</button>

<div *ngIf="!receiptSelection && userPermission == userTypeEnum.USER">
  <button
    class="select-float-button .btn btn-info"
    (click)="enableReceiptsSelect()"
    tooltip="Seleccionar comprobantes">
    <i class="la la-square"></i>
  </button>
</div>

<div *ngIf="receiptSelection && userPermission == userTypeEnum.USER">
  <button
    class="select-float-button .btn btn-info"
    (click)="disableReceiptsSelect()"
    tooltip="Seleccionar comprobantes">
    <i class="la la-square"></i>
  </button>
</div>

<button
  *ngIf="!!receiptsSelected.length && userPermission == userTypeEnum.USER"
  class="send-float-button .btn btn-success"
  (click)="sendReceipts()">
  <i class="la la-send"></i>
</button>

<ng-template #notFound>
  <app-not-found
    size="medium"
    title="No se encontraron resultados"></app-not-found>
</ng-template>
