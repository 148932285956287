<div class="p-3">
  <h3>Categoría: <b>{{category?.name}}</b></h3>

  <app-datatable
    [rows$]="bills$"
    [config]="dataTableConfig" class="example-viewport-budget">
    <app-datatable-column title="Tipo">
      <ng-template let-row #column>
        {{ row.documentType }}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="N. factura">
      <ng-template let-row #column>
        {{ row.documentNumber }}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Fecha de emision">
      <ng-template let-row #column>
        {{ row.startDate | date : 'dd/MM/yyyy'}}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Fecha de pago">
      <ng-template let-row #column>
      <span [ngClass]="{
      'text-danger': row.isExpirated && !row.isPaid,
      'text-success': !row.isExpirated,
      'text-info': row.isPaid
      }">{{ row.expectedPaymentDate | date : 'dd/MM/yyyy'}}</span>
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="RUT">
      <ng-template let-row #column>
        {{ _bill.getRut(row.provider) }}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Proveedor">
      <ng-template let-row #column>
        {{ _bill.getProvider(row.provider) }}
      </ng-template>
    </app-datatable-column>

    <app-datatable-column title="Total">
      <ng-template let-row #column>
        {{ row.total | clearCurrency : false | swapCommasAndDots }}
      </ng-template>
    </app-datatable-column>
  </app-datatable>
</div>
