<app-datatable [rows$]="paymentCategories$"
               [config]="config"
               (rowSelected)="openPaymentCategoryEdit($event)">

  <app-datatable-column title="Categoría">
    <ng-template let-row #column>
      {{row?.name}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Hora creación">
    <ng-template let-row #column>
      {{row?.createdAt | date : 'dd/MM/yyyy HH:mm'}}
    </ng-template>
  </app-datatable-column>

</app-datatable>
