<div class="ml-2 mr-2">
  <h3 class="mt-2">Antecedentes personales</h3>

  <div class="row mt-2">
    <div class="col-2 border">
      <strong><label>Nombre completo</label></strong>
    </div>
    <div class="col-10 border">
      <label>{{ user?.name }} {{ user?.surnames }}</label>
    </div>

    <div class="col-2 border">
      <strong><label>RUT</label></strong>
    </div>
    <div class="col-4 border">
      <label>{{ user?.rut }}</label>
    </div>

    <div class="col-2 border">
      <strong><label>Fecha nacimiento</label></strong>
    </div>
    <div class="col-4 border">
      <label>{{ user?.birthday | date : 'dd/MM/yyyy' }}</label>
    </div>

    <div class="col-2 border">
      <strong><label>Dirección</label></strong>
    </div>
    <div class="col-4 border">
      <label>{{ user?.address }}</label>
    </div>

    <div class="col-2 border">
      <strong><label>Comuna</label></strong>
    </div>
    <div class="col-4 border">
      <label>{{ user?.city?.name }}</label>
    </div>

    <div class="col-2 border">
      <strong><label>Nº Celular</label></strong>
    </div>
    <div class="col-4 border">
      <label>{{ applicant?.phone }}</label>
    </div>

    <div class="col-2 border">
      <strong><label>AFP</label></strong>
    </div>
    <div class="col-4 border">
      <label>{{ user?.afp?.name }}</label>
    </div>

    <div class="col-2 border">
      <strong><label>Teléfono fijo</label></strong>
    </div>
    <div class="col-4 border">
      <label>{{ user?.landline }}</label>
    </div>

    <div class="col-2 border">
      <strong><label>Nº. Cuenta</label></strong>
    </div>
    <div class="col-4 border">
      <label>{{ user?.accountNumber }}</label>
    </div>

    <div class="col-2 border">
      <strong><label>Banco</label></strong>
    </div>
    <div class="col-4 border">
      <label>{{ user?.bank }}</label>
    </div>

    <div class="col-2 border">
      <strong><label>Estado civil</label></strong>
    </div>
    <div class="col-4 border">
      <label>{{ user?.maritalStatus }}</label>
    </div>

    <div class="col-2 border">
      <strong><label>Fonasa o ISAPRE</label></strong>
    </div>
    <div class="col-4 border">
      <label>{{ user?.healthcareSystem }}</label>
    </div>

    <div class="col-2 border">
      <strong><label>Aviso en caso de emergencia</label></strong>
    </div>
    <div class="col-4 border">
      <label>{{ user?.emergencyPerson }}</label>
    </div>

    <div class="col-2 border">
      <strong><label>Nº de emergencia</label></strong>
    </div>
    <div class="col-4 border">
      <label>{{ user?.emergencyNumber }}</label>
    </div>

    <div class="col-2 border">
      <strong><label>Cargo al que postula</label></strong>
    </div>
    <div class="col-4 border">
      <label>{{ user?.position?.name }}</label>
    </div>

    <div class="col-2 border">
      <strong><label>Licencia de conducir</label></strong>
    </div>
    <div class="col-4 border">
      <label>{{ user?.driverLicense }}</label>
    </div>

    <div class="col-2 border">
      <strong><label>Nacionalidad</label></strong>
    </div>
    <div class="col-4 border">
      <label>{{ user?.nationality }}</label>
    </div>

    <div class="col-2 border">
      <strong><label>Correo electrónico</label></strong>
    </div>
    <div class="col-10 border">
      <label>{{ user?.email }}</label>
    </div>
  </div>

  <h3 class="mt-3">Elementos de protección personal</h3>
  <div class="row mt-2">
    <div class="col-4 border">
      <strong><label>Elemento</label></strong>
    </div>

    <div class="col-4 border">
      <strong><label>Talla</label></strong>
    </div>

    <div class="col-4 border">
      <strong><label>Aplica / No aplica</label></strong>
    </div>

    <div class="col-4 border">
      <strong><label>Chaqueta geólga y polar</label></strong>
    </div>
    <div class="col-4 border">
      <label>{{ user?.shirtSize }}</label>
    </div>
    <div class="col-4 border">
      <label>Aplica</label>
    </div>

    <div class="col-4 border">
      <strong><label>Buzo piloto</label></strong>
    </div>
    <div class="col-4 border">
      <label>{{ user?.pilotDriverSize }}</label>
    </div>
    <div class="col-4 border">
      <label>Aplica</label>
    </div>

    <div class="col-4 border">
      <strong><label>Zapatos</label></strong>
    </div>
    <div class="col-4 border">
      <label>{{ user?.numberOfShoes }}</label>
    </div>
    <div class="col-4 border">
      <label>Aplica</label>
    </div>

    <div class="col-4 border">
      <strong><label>Lentes ópticos</label></strong>
    </div>
    <div class="col-4 border">
      <label></label>
    </div>
    <div class="col-4 border">
      <label>{{ user?.opticalLenses }}</label>
    </div>
  </div>
</div>
