import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../shared/services/user.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from '../../../shared/interfaces/user';
import { take } from 'rxjs/operators';
import { ApplicantService } from '../../../shared/services/applicant.service';
import { AlertService } from '../../../shared/template-services/alert.service';
import { Applicant } from '../../../admin/interfaces/applicant';

@Component({
  selector: 'app-register-data',
  templateUrl: './register-data.component.html',
  styleUrls: ['./register-data.component.css']
})
export class RegisterDataComponent implements OnInit {
  complete: boolean = false;
  user: any;
  userKey: string;
  generalData: any;
  documents: any;
  driverLicense: any;
  messageLoading: string = 'Guardando...';
  authenticated: boolean = false;
  email: string;
  code: string;
  applicant: Applicant;

  constructor(private _user: UserService,
              private router: Router,
              private _applicant: ApplicantService,
              private SpinnerService: NgxSpinnerService) {
  }

  async ngOnInit(): Promise<any> {
    const applicantRut = this.router.url.split('/')[3];
    let resp = await this._applicant.getByRut(applicantRut).pipe(take(1)).toPromise();

    if (!resp.length) {
      resp = await this._applicant.getByRut(applicantRut.replaceAll('.', '')).pipe(take(1)).toPromise();

      if (!resp.length) {
        const applicantResp = await this._applicant.get(applicantRut).pipe(take(1)).toPromise();
        resp = applicantResp ? [applicantResp] : [];
      }
    }

    this.applicant = resp.length ? resp[0] : null;

    if (!this.applicant.code) return AlertService.error('Aún no se ha generado un código para poder acceder, por favor contacte a su administrador');

    if (!this.applicant) {
      AlertService.error('No se encontró su usuario, por favor contacte a su administrador');
    } else {
      const users = await this._user.getAll().pipe(take(1)).toPromise();

      this.user = users.find((user: User) => (user.rut === this.applicant.rut || user.rut == this.applicant.rut.replaceAll('.', '')) && user.email === this.applicant.email);
    }
  }

  getGeneralDataForm(generalData: any) {
    this.generalData = generalData;
  }

  getDocuments(documents: any) {
    this.documents = documents;
  }

  getDriverLicense(driverLicense: any) {
    this.driverLicense = driverLicense;
  }

  async submitGeneralData() {
    this.SpinnerService.show();

    let generalData = {
      phone: null,
      imageUrl: !!this.user.imageUrl ? this.user.imageUrl : null,
      isDisable: false,
      permissions: [],
      isApplicant: this.user.isApplicant ? this.user.isApplicant : null,
      firstLogin: false,
      ...this.generalData
    };

    await this._user.update(this.user.key, generalData as User);
    this.SpinnerService.hide();

    await AlertService.success('Datos guardados correctamente', 'Ya puedes cerrar esta ventana');
  }

  async skipDocuments() {
    let generalData = {
      phone: null,
      imageUrl: !!this.user.imageUrl ? this.user.imageUrl : null,
      isDisable: false,
      permissions: [],
      isApplicant: this.user.isApplicant ? this.user.isApplicant : null,
      firstLogin: false,
      ...this.generalData
    };

    this.SpinnerService.show();

    await this._user.update(this.user.key, generalData as User);

    this.SpinnerService.hide();

    await AlertService.success('Datos guardados correctamente', 'Ya puedes cerrar esta ventana');
  }

  submitAuth() {
    if (!(this.code.trim().toLowerCase() == this.applicant.code.trim().toLowerCase()
      && this.email.trim().toLowerCase() == this.applicant.email.trim().toLowerCase())) return AlertService.error('Código o correo inválido');

    this.authenticated = true;
    AlertService.toastSuccess('Código validado correctamente');
  }
}
