<div class="p-2" [formGroup]="kanbanForm" *ngIf="!!kanbanForm">
  <div class="form-group">
    <label>Título</label>
    <input
      type="text"
      class="form-control"
      formControlName="title"
      [ngClass]="{'is-invalid': submitted && formControls.title.errors}" />
    <small
      class="form-text text-muted danger invalid-feedback"
      *ngIf="submitted && formControls.title.errors">
      <div *ngIf="formControls.title.errors.required">
        El título es obligatorio
      </div>
    </small>
  </div>

  <div *ngIf="boardType == boardTypeEnum.TEAM">
    <div
      class="form-group"
      *ngIf="
        userPermission == userTypeEnum.ADMIN ||
        userPermission == userTypeEnum.SUPERVISOR
      ">
      <label class="form-label">Fecha de finalización sugerida</label>
      <input
        type="date"
        placeholder="Seleccione una fecha"
        class="form-control"
        [(ngModel)]="supervisorFinishDate"
        [ngModelOptions]="{standalone: true}" />
    </div>

    <div class="form-group" *ngIf="userPermission == userTypeEnum.USER">
      <label class="form-label">Fecha de finalización sugerida</label>
      <input
        type="date"
        placeholder="Seleccione una fecha"
        class="form-control"
        [(ngModel)]="userFinishDate"
        [ngModelOptions]="{standalone: true}" />
    </div>
  </div>

  <div class="form-group" *ngIf="boardType == boardTypeEnum.PERSONAL">
    <label class="form-label">Fecha de finalización</label>
    <input
      type="date"
      placeholder="Seleccione una fecha"
      class="form-control" />
  </div>

  <div class="form-group">
    <label>Descripción</label>
    <textarea
      type="text"
      class="form-control"
      formControlName="description"
      [ngClass]="{
        'is-invalid': submitted && formControls.description.errors
      }"></textarea>
    <small
      class="form-text text-muted danger invalid-feedback"
      *ngIf="submitted && formControls.description.errors">
      <div *ngIf="formControls.description.errors.required">
        La descripción es obligatoria
      </div>
    </small>
  </div>

  <div>
    <div>
      <mat-form-field class="w-100">
        <mat-label>Prioridad</mat-label>
        <mat-select
          formControlName="tags"
          multiple
          [compareWith]="compareMembers"
          (selectionChange)="changed()">
          <mat-select-trigger>
            <ul class="list-unstyled users-list m-0">
              <li
                class="d-inline-block pull-up mr-1"
                *ngFor="let tag of kanbanForm.value.tags">
                <div
                  class="badge mr-1 text-white"
                  [style.backgroundColor]="tag.color">
                  {{ tag.name }}
                </div>
              </li>
            </ul>
          </mat-select-trigger>
          <mat-option *ngFor="let tag of tags; let i = index" [value]="tag">
            <div class="badge mr-1" [style.backgroundColor]="tag.color">
              {{ tag.name }}
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div>
    <mat-form-field class="w-100" *ngIf="validateIsTeamBoard()">
      <mat-label>Seleccione el tablero</mat-label>
      <mat-select
        [(ngModel)]="boardSelected"
        [ngModelOptions]="{standalone: true}"
        (selectionChange)="boardSelectionChanged()">
        <mat-option [value]="board" *ngFor="let board of boards">
          {{ board.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="boardType == boardTypeEnum.TEAM">
    <mat-form-field class="w-100">
      <mat-label>Asignados</mat-label>
      <mat-select
        formControlName="members"
        multiple
        [compareWith]="compareMembers">
        <mat-select-trigger>
          <ul class="list-unstyled users-list m-0">
            <li
              class="avatar avatar-sm pull-up"
              *ngFor="let member of kanbanForm.value.members">
              <img
                class="media-object rounded-circle avatar-img"
                container="body"
                [ngbTooltip]="member.name"
                [src]="
                  !!member.imageUrl
                    ? member.imageUrl
                    : '/assets/images/default.png'
                "
                alt="Responsable" />
            </li>
          </ul>
          <small
            class="form-text text-muted danger invalid-feedback"
            *ngIf="submitted && kanbanForm.value.members == null">
            <div *ngIf="formControls.members.errors.required">
              Los miembros son obligatorios
            </div>
          </small>
        </mat-select-trigger>
        <mat-option *ngFor="let member of team; let i = index" [value]="member">
          <img
            src="{{ member.imageUrl }}"
            class="avatar-img rounded-circle"
            alt="" /><span class="ml-1">{{ member.name }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="mt-1">
    <label class="mr-1 align-self-center">Proyecto o Centro de costos</label>
    <div>
      <ng-container>
        <ng-select
          [items]="selectArray"
          [searchable]="true"
          bindLabel="name"
          appendTo="body"
          placeholder="Selecciona un proyecto"
          formControlName="project"
          groupBy="type">
          <ng-template ng-option-tmp let-item="item">
            {{ item.name }}
          </ng-template>
        </ng-select>
      </ng-container>
      <small
        *ngIf="formControls.project.errors && submitted"
        class="text-muted danger"
      >Campo obligatorio</small
      >
    </div>
  </div>

  <div *ngIf="boardType == boardTypeEnum.TEAM">
    <div class="custom-control custom-switch unselect">
      <input
        type="checkbox"
        class="custom-control-input"
        id="customSwitch1"
        formControlName="isRecurrent" />
      <label class="custom-control-label" for="customSwitch1"
        >¿Tarea periódica?</label
      >
    </div>

    <div class="mt-2" *ngIf="kanbanForm.value.isRecurrent">
      <div class="d-flex flex-row">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            id="exampleRadios1"
            [value]="frequencyType.MONTHLY"
            formControlName="frequencyType" />
          <label class="form-check-label" for="exampleRadios1">
            Mensualmente
          </label>
        </div>
        <div class="form-check ml-2">
          <input
            class="form-check-input"
            type="radio"
            id="exampleRadios2"
            [value]="frequencyType.WEEKLY"
            formControlName="frequencyType" />
          <label class="form-check-label" for="exampleRadios2">
            Semanalmente
          </label>
        </div>
      </div>

      <ng-select
        [items]="
          kanbanForm.value.frequencyType == frequencyType.WEEKLY
            ? weekDays
            : monthDays
        "
        [searchable]="true"
        placeholder="Seleccionar día"
        formControlName="creationDay"
        class="mt-2">
      </ng-select>
    </div>
  </div>

  <div class="mb-2 mt-2">
    <div *ngFor="let routeFile of files; index as index">
      <app-upload-file-storage
        [routeFile]="routeFile"
        [showPreview]="true"
        (imageLoaded)="uploadFile()"
        fileType="all"
        label="Imagen de la tarea"
        controlName="photoUrl"
        extension="png">
      </app-upload-file-storage>
    </div>
  </div>

  <div class="mt-1">
    <div class="d-flex justify-content-between">
      <span>Enlaces</span>
      <div
        class="ml-1 cursor-pointer"
        ngbTooltip="Agregar enlace"
        placement="left"
        (click)="addLink()">
        <i class="la la-plus-circle text-dark"></i>
      </div>
    </div>
    <div
      class="mt-1"
      *ngFor="let item of kanbanForm.value.links; let i = index">
      <div class="d-flex justify-content-between">
        <a [href]="item.link" target="_blank">{{ item.title }}</a>
        <i
          ngbTooltip="Eliminar enlace cursor-pointer"
          (click)="deleteLink(i)"
          class="la la-times text-dark"></i>
      </div>
    </div>
  </div>

  <div class="mr-2 mt-2">
    <button class="btn btn-primary float-right mb-2" (click)="submit()">
      Guardar
    </button>
    <button
      class="btn btn-outline-danger float-right mb-2 mr-2"
      (click)="modal.hide()">
      Cerrar
    </button>
  </div>
</div>
