import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../../shared/services/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {User} from '../../../shared/interfaces/user';
import {Subscription} from 'rxjs';
import {ProjectService} from '../../../shared/services/project.service';
import {DocumentReference} from '@angular/fire/firestore';

@Component({
  selector: 'app-add-person',
  templateUrl: './add-person.component.html',
  styleUrls: ['./add-person.component.css']
})
export class AddPersonComponent implements OnInit, OnDestroy {
  personForm: FormGroup;
  submitted: boolean = false;
  persons: User[];
  personsSubscription: Subscription = new Subscription();
  projectKey: string;
  projectReference: DocumentReference;

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _project: ProjectService,
              private _user: UserService) {
    this.personForm = formBuilder.group({
      persons: [[], Validators.required]
    });
  }

  ngOnInit(): void {
    this.projectReference = this._project.getReference(this.projectKey);
    this.personsSubscription = this._user.getAllOtherThanProject(this.projectReference).subscribe(data => {
      this.persons = data;
    });
  }

  ngOnDestroy(): void {
    this.personsSubscription.unsubscribe();
  }

  submit() {
    this.submitted = true;
    if (this.personForm.valid) {
      for(let person of this.personForm.value.persons){
        if(!person.projects){
          person = {
            ...person,
            projects: []
          }
        }
        person.projects.push(this.projectReference);
        this._user.update(person.key, person)
      }
      this.modal.hide();
    }
  }
}
