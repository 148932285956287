import {Pipe, PipeTransform} from '@angular/core';
import {TabsPermission} from '../enums/tabs-permission.enum';
import {PermissionService} from '../services/permission.service';

@Pipe({
  name: 'permission'
})
export class PermissionPipe implements PipeTransform {

  constructor(private _permission: PermissionService) {
  }

  transform(permission: TabsPermission): unknown {
    return this._permission.hasPermission(permission);
  }

}
