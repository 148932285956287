<app-datatable
  [rows$]="articlesToCome$"
  [config]="config"
  (rowSelected)="openArticleDetails($event)">
  <app-datatable-column title="Artículo">
    <ng-template let-row #column>
      {{ row?.description }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Cantidad">
    <ng-template let-row #column>
      {{ row?.quantity }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Orden de compra">
    <ng-template let-row #column>
      OC-{{ row?.purchaseOrder?.purchaseID }}
    </ng-template>
  </app-datatable-column>
</app-datatable>
