import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../shared/services/user.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-fund-to-render-v2',
  templateUrl: './fund-to-render-v2.component.html',
  styleUrls: ['./fund-to-render-v2.component.css']
})
export class FundToRenderV2Component implements OnInit {
  fundToRenderUrl;

  constructor(public _user: UserService) {
  }

  ngOnInit(): void {
    let dataParam = window.location.href.includes('data=') ? window.location.href.split('data=')[1] : '';
    dataParam = dataParam ? `&data=${dataParam}` : '';
    this.fundToRenderUrl = `${environment.embeddedUrl}/#/fund-to-render?uid=${this._user.user.key}${dataParam}`;
  }
}
