<div class="p-2">
  <h2>Editar aplicante</h2>
  <form [formGroup]="editApplicantForm" (ngSubmit)="submit()">
    <div class="row mt-2">
      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Nombre *</label>
          <input type="text" class="form-control" formControlName="name"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.name.errors }"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.name.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Apellidos *</label>
          <input type="text" class="form-control" formControlName="surnames"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.surnames.errors }"/>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.surnames.errors">
            <div *ngIf="formControls.surnames.errors.required">
              Los apellidos son obligatorios
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>RUT *</label>
          <input type="text" class="form-control" formControlName="rut"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.rut.errors }"/>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.rut.errors">
            <div *ngIf="formControls.rut.errors">
              El rut es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Región</label>
          <ng-select
            formControlName="region"
            [items]="states"
            (change)="stateChange($event)"
            bindLabel="name">
          </ng-select>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.city.errors">
            <div *ngIf="formControls.city.errors.required">
              La ciudad es obligatoria
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Comuna *</label>
          <ng-select formControlName="city"
                     [items]="cities"
                     bindLabel="name">
          </ng-select>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.city.errors">
            <div *ngIf="formControls.city.errors.required">
              La ciudad es obligatoria
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Cargo *</label>
          <ng-select formControlName="position"
                     [searchable]="true"
                     [items]="selectArrayJobs"
                     bindLabel="name"
                     placeholder="">
          </ng-select>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.position.errors">
            <div *ngIf="formControls.position.errors.required">
              El cargo es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Fecha de nacimiento *</label>
          <input type="date" class="form-control" formControlName="birthday"
                 placeholder=""/>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.birthday.errors">
            <div *ngIf="formControls.birthday.errors.required">
              La fecha de nacimiento es obligatoria
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Experiencia laboral (años) *</label>
          <input type="number" class="form-control" formControlName="workExperience"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.workExperience.errors }"/>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.workExperience.errors">
            <div *ngIf="formControls.workExperience.errors.required">
              La experiencia laboral es obligatoria
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Nivel Educacional *</label>
          <ng-select formControlName="educationLevel"
                     [items]="educationLevelArray"
                     placeholder="">
          </ng-select>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.educationLevel.errors">
            <div *ngIf="formControls.educationLevel.errors.required">
              El nivel educacional es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Email *</label>
          <input type="email" class="form-control" formControlName="email"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.email.errors }"/>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.email.errors">
            <div *ngIf="formControls.email.errors.required">
              El email es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Teléfono *</label>
          <input type="number" class="form-control" formControlName="phone"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.phone.errors }"/>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.phone.errors">
            <div *ngIf="formControls.phone.errors.required">
              El teléfono es obligatorio
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Nacionalidad *</label>
          <ng-select formControlName="nationality"
                     [items]="countries"
                     [ngClass]="{ 'is-invalid': submitted && formControls.nationality.errors }">
          </ng-select>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.nationality.errors">
            <div *ngIf="formControls.nationality.errors.required">
              Es obligatorio la nacionalidad
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="form-group">
          <label>Pretensiones de renta</label>
          <input type="text" class="form-control" formControlName="expectation" (keypress)="numberOnly($event)"
                 maxlength="8"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.expectation.errors }"/>
        </div>
      </div>

      <div class="col-md-12 col-12">
        <div class="form-group">
          <label>Cuéntanos sobre tu experiencia *</label>
          <textarea class="form-control" formControlName="experience" rows="8" cols="50"
                    placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.experience.errors?.minlength }">
            </textarea>
          <small class="form-text text-muted danger invalid-feedback"
                 *ngIf="submitted && formControls.experience.errors?.minlength">
            <div *ngIf="formControls.experience.errors?.minlength">
              Es obligatorio "caracteres mínimos 40"
            </div>
          </small>
        </div>
      </div>
    </div>

    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn">Actualizar</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide()">Cancelar</div>
    </div>
  </form>
</div>
