<div class="p-3">
  <div class="d-flex flex-row justify-content-center font-weight-bold mb-1">
    <p>Información del artículo</p>
  </div>

    <div class="grid-container">
      <div>
        <label class="font-weight-bold mb-0">Nombre</label>
        <p>
          {{article.name}}
        </p>
      </div>
    
      <div>
        <label class="font-weight-bold mb-0">Proveedor</label>
        <p>
          {{article.provider ? article.provider.name : 'No registrado'}}
        </p>
      </div>
    
      <div>
        <label class="font-weight-bold mb-0">Precio</label>
        <p>
          {{article.unitPrice}}
        </p>
      </div>
    
      <div>
        <label class="font-weight-bold mb-0">Unidad </label>
        <p>
          {{article.unitType ? article.unitType.name : ''}}

        </p>
      </div> 
    
      <div>
        <label class="font-weight-bold mb-0">Exento</label>
        <p>
          {{article.exempt ? 'Si' : 'No'}}
        </p>
      </div>
    
      <div>
        <label class="font-weight-bold mb-0">Última actualización</label>
        <p>
          {{article.createdAt | date : 'dd/MM/yyyy HH:mm'}}
        </p>
      </div>

      <div>
        <label class="font-weight-bold mb-0">Orden de compra</label>
        <p>
          {{article?.purchaseOrder ? ('OC' + ' ' + article?.purchaseOrder?.purchaseID | slice : 0: -3) : ''}}
        </p>
      </div>
    </div>

</div>
