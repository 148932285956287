import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {Observable} from 'rxjs';
import {FirebaseDataService} from '../../../../shared/template-services/firebase-data.service';
import {AccountabilityReceipt} from '../interfaces/accountability-receipt';

@Injectable({
  providedIn: 'root'
})
export class AccountabilityReceiptService extends FirebaseDataService {
  constructor(
    private _db: AngularFirestore,
    private _storage: AngularFireStorage
  ) {
    super(_db, _storage);
  }

  getAll(accountabilityKey: string): Observable<AccountabilityReceipt[]> {
    return this.colWithIds$(
      `accountabilities/${accountabilityKey}/receipts`,
      (ref) => ref.where('trash', '==', false)
    );
  }

  add(
    accountabilityKey: string,
    receipt: AccountabilityReceipt
  ): Promise<DocumentReference> {
    return this._db
      .collection(`accountabilities/${accountabilityKey}/receipts`)
      .add({
        ...receipt,
        createdAt: new Date().getTime(),
        updatedAt: new Date().getTime(),
        trash: false
      });
  }

  updateReceipt(
    accountabilityKey: string,
    receiptKey: string,
    receipt: AccountabilityReceipt
  ): Promise<void> {
    receipt['updatedAt'] = new Date().getTime();
    return this._db
      .doc(`accountabilities/${accountabilityKey}/receipts/${receiptKey}`)
      .update(receipt);
  }

  delete(accountabilityKey: string, receiptKey: string): Promise<void> {
    return this._db
      .doc(`accountabilities/${accountabilityKey}/receipts/${receiptKey}`)
      .update({trash: true});
  }
}
