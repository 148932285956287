<div class="p-2">
  <div class="row">
    <div class="col-4">
      <button class="btn btn-primary mb-2"
              (click)="downloadFile()">
        <i class="fa fa-download"></i>

        Descargar todo
      </button>

      <img [src]="user.imageUrl || 'assets/images/default.png'"
           style="width: 100%; height: auto; margin-bottom: 20px; border-radius: 5px">

      <ti-file
        label="Foto tipo carnet"
        [fileUrl]="user.imageUrl"
        name="imageUrl"
        accept="image/x-png,image/gif,image/jpeg"
        [user]="user"
      ></ti-file>
    </div>

    <div class="col-8"
         style="display: grid; grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); grid-column-gap: 10px;">
      <div>
        <ti-file
          label="CV"
          [fileUrl]="user.cv"
          name="cv"
          accept="application/pdf"
          [user]="user"
        ></ti-file>
      </div>

      <div>
        <ti-file
          label="Cédula de identidad Parte frontal"
          [fileUrl]="user.indentificationCardFront"
          name="indentificationCardFront"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>

      <div>
        <ti-file
          label="Cédula de identidad Parte trasera"
          [fileUrl]="user.indentificationCardBack"
          name="indentificationCardBack"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>

      <div>
        <ti-file
          label="Papel de Antecedentes"
          [fileUrl]="user.backgroundPaper"
          name="backgroundPaper"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>

      <div>
        <ti-file
          label="Certificado de Nacimiento"
          [fileUrl]="user.birthCertificate"
          name="birthCertificate"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>

      <div>
        <ti-file
          label="Certificado de Afiliación AFP"
          [fileUrl]="user.afpAffiliationCertificate"
          name="afpAffiliationCertificate"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>

      <div>
        <ti-file
          label="Certificado de Afiliación Salud"
          [fileUrl]="user.healthAffiliationCertificate"
          name="healthAffiliationCertificate"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>

      <div>
        <ti-file
          label="Licencia de conducir"
          [fileUrl]="user.driverLicensePhoto"
          name="driverLicensePhoto"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>

      <div>
        <ti-file
          label="Hoja de vida de conductor"
          [fileUrl]="user.driverResume"
          name="driverResume"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>

      <div>
        <ti-file
          label="Título"
          [fileUrl]="user.title"
          name="title"
          accept="*"
          [user]="user"
        ></ti-file>
      </div>

      <div>
        <ti-file
          label="Firma"
          [fileUrl]="user.signature"
          name="signature"
          accept="image/x-png,image/gif,image/jpeg"
          [user]="user"
        ></ti-file>
      </div>

      <div>
        <ti-file
          label="Último finiquito"
          [fileUrl]="user.finiquitoUrl"
          name="finiquitoUrl"
          accept="image/x-png,image/gif,image/jpeg"
          [user]="user"
        ></ti-file>
      </div>
    </div>
  </div>
</div>
