import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalService} from 'ngx-bootstrap/modal';
import {UserService} from 'src/app/shared/services/user.service';
import {NewFundToRenderService} from 'src/app/shared/services/new-fund-to-render.service';
import {AlertService} from 'src/app/shared/template-services/alert.service';
import {UserType} from '../../../../enums/user-type.enum';
import { FundToRenderRequestStatusEnum } from '../../enums/fund-to-render-request-status.enum';

@Component({
  selector: 'app-request-new-fund-to-render',
  templateUrl: './request-new-fund-to-render.component.html',
  styleUrls: ['./request-new-fund-to-render.component.css']
})
export class RequestNewFundToRenderComponent implements OnInit {
  permission: any;
  permissionEnum = UserType;
  requestFundToRenderForm: FormGroup;
  user: any;

  constructor(
    private formBuilder: FormBuilder,
    public modal: BsModalService,
    private _user: UserService,
    private _requestNewFundToRender: NewFundToRenderService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    if (this.permission == this.permissionEnum.USER) {
      this.user = this._user.user;
    }

    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'FONDOS POR RENDIR'
    ).permission;
  }

  createForm() {
    this.requestFundToRenderForm = this.formBuilder.group({
      reason: ['', Validators.required],
      user: ['', Validators.required],
      status: [FundToRenderRequestStatusEnum.PENDING_SUPERVISOR_APROVAL],
      trash: [false]
    });
  }

  get formControls() {
    return this.requestFundToRenderForm.controls;
  }

  async submit() {
    if (
      !(await AlertService.confirm(
        '¿Estás seguro de que deseas solicitar un nuevo fondo por rendir?',
        'Este tipo de solicitudes son excepcionales y muy especiales, favor de considerar en caso de ser rechazada ',
        'Solicitar'
      ))
    )
      return;

    this.requestFundToRenderForm.patchValue({
      user: this._user.getReference(this.user.key),
    });

    this._requestNewFundToRender.add(this.requestFundToRenderForm.value);
    this.modal.hide();
  }
}
