<block-ui>
  <div
    class="report-panel bg-white p-2"
    [ngClass]="{'report-panel__active': visiblePanel}"
  >
    <i class="fa fa-times close-button color-danger" (click)="toggleMenu()"></i>
    <h2><i class="fa fa-bug text-danger"></i> Reportar</h2>

    <p class="text-muted font-size-base">¿Encontraste un error? ¿Tienes una sugerencia? ¡Envíanos un reporte!</p>

    <ti-input
      [multiline]="true"
      [rows]="10"
      [(model)]="message"
    ></ti-input>

    <p class="text-muted text-small mt-1">Si quieres, puedes grabar tu pantalla y adjuntarla al reporte (El
      audio no será grabado)</p>

    <div class="mt-1 d-flex">
      <button
        class="btn btn-info"
        [ngClass]="{'btn-dark': recording}"
        [disabled]="recording"
        (click)="startRecord()"
      >Grabar
      </button>

      <button
        class="btn btn-primary ml-1"
        [ngClass]="{'btn-dark': !recording}"
        [disabled]="!recording"
        (click)="stopRecord()"
      >Detener
      </button>

      <button
        class="btn btn-primary ml-1"
        *ngIf="videoStreamUrl"
        (click)="deleteRecord()"
      >Eliminar
      </button>
    </div>

    <video
      *ngIf="videoStreamUrl"
      [src]="videoStreamUrl"
      controls
      class="w-100 mt-1"
    ></video>

    <button
      class="btn btn-primary btn-block mt-4"
      (click)="sendReport()"
    >Enviar
    </button>

    <aj-screen-recorder #screenRecorder></aj-screen-recorder>
  </div>
</block-ui>
