<div class="content-wrapper">
  <div class="content-body">
    <section class="card">
      <div id="invoice-template" class="card-body">
        <div id="invoice-company-details" class="row">
          <div class="col-md-6 col-sm-12 text-center text-sm-left">
            <div class="media row">
              <div class="col-12 col-sm-3 col-xl-3">
                <img
                  src="../../../../../../assets/images/logoWeb.ico"
                  class="logo"
                  alt="company logo" />
              </div>
              <div class="col-12 col-sm-9 col-xl-9">
                <div class="media-body">
                  <ul class="ml-2 px-0 list-unstyled">
                    <li class="text-bold-800">
                      Ingeniería y Servicios Colsan Ltda.
                    </li>
                    <li>Benito Rebolledo #2470</li>
                    <li>San Joaquin, La Serena.</li>
                    <li>8:30am – 6:30pm</li>
                    <li>+56 51 2 494893</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 text-center text-md-right">
            <h2>TICKET DE ENTRADA</h2>

            <ul class="px-0 list-unstyled">
              <li>TOTAL</li>
              <li class="lead text-bold-800">
                {{ total | currency: 'USD':'symbol':'1.0-0' }}
              </li>
            </ul>
          </div>
        </div>
        <!-- Invoice Customer Details -->
        <div id="invoice-customer-details" class="row pt-2">
          <div class="col-md-6 col-sm-12 text-center text-md-left">
            <ul class="px-0 list-unstyled">
              <li class="text-bold-800">Bodega</li>
              <li class="text-bold-800">Código: {{ warehouse.code }}</li>
              <li>Locación: {{ warehouse.location }}</li>
              <li>Descripción: {{ warehouse.description }}</li>
            </ul>
          </div>
          <div class="col-md-6 col-sm-12 text-center text-md-right">
            <p>
              <span class="text-muted">Fecha de creación:</span>
              {{ currentDate | date: 'dd/MM/yyyy HH:mm' }}
            </p>
          </div>
        </div>
        <!--/ Invoice Customer Details -->
        <div class="row">
          <div class="form-group col-4">
            <label>Ingresado por *</label>
            <ng-select
              [items]="users"
              [searchable]="true"
              bindLabel="name"
              placeholder="Seleccionar un usuario"
              (change)="setUserSelected($event)">
              <ng-template ng-option-tmp let-item="item">
                {{ item.name }} {{ item.surnames }}
              </ng-template>

              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <span class="ng-value-label"
                  >{{ item.name }} {{ item.surnames }}</span
                >
                <span class="ng-value-icon right" (click)="clear(item)">×</span>
              </ng-template>
            </ng-select>
            <small
              class="form-text text-muted danger invalid-feedback"
              *ngIf="submitted && formControls.user.errors">
              <div *ngIf="formControls.user.errors.required">
                Debe de elegir un usuario
              </div>
            </small>
          </div>
        </div>

        <!-- Add Ticket -->
        <form [formGroup]="ticketForm" (ngSubmit)="submit()">
          <div id="invoice-items-details" class="pt-2">
            <div class="row">
              <div class="table-responsive col-sm-12 padding-table-bottom">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th class="text-right">Cantidad</th>
                      <th class="text-right">Precio</th>
                      <th class="text-right">Total</th>
                      <th class="text-right">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let article of articles; let index = index">
                      <td>
                        <p>{{ article?.name }} ({{ article?.internalId }})</p>
                      </td>
                      <td class="text-right">{{ article.quantity }}</td>
                      <td class="text-right">
                        {{ article.price | currency: 'USD':'symbol':'1.0-0' }}
                      </td>
                      <td class="text-right">
                        {{ article.total | currency: 'USD':'symbol':'1.0-0' }}
                      </td>
                      <td class="text-right">
                        <div
                          class="btn btn-danger btn-sm cursor-pointer"
                          (click)="dropArticle(index)">
                          <i class="feather ft-trash"></i>
                        </div>

                        <div
                          class="btn btn-success btn-sm cursor-pointer ml-1"
                          *ngIf="article.type == articleTypeEnum.STORABLE"
                          (click)="showSerialsNumbersArticles(article)"
                          ngbTooltip="{{
                            'Agregar seriales' +
                              ' ' +
                              '(' +
                              (!!article?.serialNumber && article?.quantity
                                ? article?.serialNumber.length
                                : '0') +
                              '/' +
                              article?.quantity +
                              ')'
                          }}">
                          <i class="feather ft-plus"></i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="w-100">
                        <ng-select
                          [items]="articleReferences"
                          [searchable]="true"
                          formControlName="article"
                          bindLabel="searchLabel"
                          class="select-article w-auto"
                          placeholder="Seleccionar artículo"
                          appendTo="body"
                          (change)="setArticleSelected($event)">
                          <ng-template ng-label-tmp let-item="item">
                            <img height="15" width="15" [src]="item.imageUrl" />
                            {{ item.name }}
                          </ng-template>

                          <ng-template ng-option-tmp let-item="item">
                            <img height="15" width="15" [src]="item.imageUrl" />
                            {{ item.internalId }} - {{ item.name }}
                          </ng-template>
                        </ng-select>
                        <small
                          class="form-text text-muted danger invalid-feedback"
                          *ngIf="submitted && formControls.articles.errors">
                          <div *ngIf="formControls.articles.errors">
                            Debe de elegir por lo menos un artículo
                          </div>
                        </small>
                      </td>
                      <td>
                        <div class="form-group d-flex justify-content-end">
                          <input
                            #articleQuantity
                            type="number"
                            class="form-control input-quantity"
                            (change)="totalPriceArticle(articleQuantity)"
                            formControlName="quantity"
                            min="1"
                            [ngClass]="{
                              'is-invalid':
                                submitted && formControls.articles.errors
                            }" />
                        </div>
                      </td>
                      <td class="text-right">
                        {{ priceArticle | currency: 'USD':'symbol':'1.0-0' }}
                      </td>
                      <td class="text-right">
                        {{ totalArticle | currency: 'USD':'symbol':'1.0-0' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0">
            <button class="btn btn-success add-btn">Guardar</button>
            <div
              class="btn btn-danger cancel-btn cursor-pointer"
              (click)="modal.hide()">
              Cancelar
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</div>
