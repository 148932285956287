import { Component, OnInit } from '@angular/core';
import { FundToRenderService } from '../../../../../shared/services/fund-to-render.service';
import { AccountabilityService } from '../../services/accountability.service';
import { Accountability } from '../../interfaces/accountability';
import { Observable } from 'rxjs';
import { DataTableConfig } from '../../../../../shared/interfaces/data-table-config';
import { map, switchMap  } from 'rxjs/operators';
import { ReceiptService } from '../../services/receipt.service';
import { Receipt } from '../../interfaces/receipt';
import { AccountabilityStatusEnum } from '../../enums/accountability-status.enum';
import { AccountabilityStatusLabel } from '../../labels/accountability-status.label';
import { AlertService } from '../../../../../shared/template-services/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../../../../../shared/services/user.service';
import { FundToRender } from '../../interfaces/fund-to-render';
import { DocumentReference } from '@angular/fire/firestore';
import { User } from '../../../../../shared/interfaces/user';
import { FundToRenderStatusEnum } from '../../enums/fund-to-render-status.enum';
import { AccountabilityStatusClasses } from '../../../../data/accountability-status-classes';

declare const $;

@Component({
  selector: 'app-admin-accountabilities',
  templateUrl: './admin-accountabilities.component.html',
  styleUrls: ['./admin-accountabilities.component.css']
})
export class AdminAccountabilitiesComponent implements OnInit {
  accountabilities$: Observable<Accountability[]>;
  accountabilityStatusEnum = AccountabilityStatusEnum;
  accountabilityStatusClasses = AccountabilityStatusClasses;
  accountabilityStatusLabel = AccountabilityStatusLabel;

  config: DataTableConfig = {
    title: 'Rendiciones',
    notFoundText: 'No se encontraron resultados',
    hasSearch: true
  };

  constructor(private _fundToRender: FundToRenderService,
              private _accountability: AccountabilityService,
              private _receipt: ReceiptService,
              private spinner: NgxSpinnerService,
              private _user: UserService) {}

  ngOnInit() {
    this.setAccountabilities$();
  }

  setAccountabilities$() {
    this.accountabilities$ = this._fundToRender.getAll().pipe(
      switchMap((funds) =>
        this._receipt.getAll().pipe(
          switchMap((receipts) => {
            return this.getFormattedAccountabilities$(receipts, funds);
          })
        )
      )
    );
  }

  getFormattedAccountabilities$(receipts: Receipt[], fundsToRender: FundToRender[]) {
    return this._accountability.getAll().pipe(
      map((accountabilities) =>
        this.filterAccountabilityWithExistedFund(accountabilities, fundsToRender
        )
      ),
      map((accountabilities) =>
        this.formatAccountabilities(accountabilities, fundsToRender, receipts)
      )
    );
  }

  filterAccountabilityWithExistedFund(accountabilities: Accountability[], fundsToRender: FundToRender[]) {
    return accountabilities.filter((accountability) =>
      fundsToRender.some(
        (fundToRender) => fundToRender.key == accountability.fundToRender.id
      )
    );
  }

  formatAccountabilities(accountabilities: Accountability[],
                         fundsToRender: FundToRender[],
                         receipts: Receipt[]) {
    return this.filterAccountabilitiesInReview(accountabilities).map(
      (accountability) => ({
        ...accountability,
        fundToRender: fundsToRender.find(
          (fund) => fund.key == accountability.fundToRender.id
        ),
        receiptsTotalAmount: this.calculateReceiptsTotalAmount(
          receipts,
          accountability.receipts
        )
      })
    );
  }

  filterAccountabilitiesInReview(accountabilities: Accountability[]) {
    return accountabilities.filter(
      (accountability) =>
        accountability.status == AccountabilityStatusEnum.REVIEW
    );
  }

  calculateReceiptsTotalAmount(receipts: Receipt[], receiptKeysRequired: any) {
    return receipts
      .filter((receipt) => receiptKeysRequired[receipt.key])
      .reduce((acc, el) => acc + el.amount, 0);
  }

  async authorize({key, fundToRender, receiptsTotalAmount}: any) {
    if (
      !(await AlertService.confirm(
        'Autorizar rendición',
        '¿Está seguro de autorizar esta rendición?'
      ))
    ) return;

    this.spinner.show();

    const finalBalance = (fundToRender.receivedAmount - receiptsTotalAmount);

    const userReference = this._user.getReference(this._user.user.key);

    let status;
    if (finalBalance < 0) status = AccountabilityStatusEnum.CLOSED_USER;

    if (finalBalance > 0) {
      status = AccountabilityStatusEnum.CLOSED_COLSAN;

      if (!(fundToRender.user as DocumentReference).id && !(fundToRender.user as User).key) {
        this.spinner.hide();
        return AlertService.toastError('No se encontró el usuario para la rendición');
      }

      const userKey = (fundToRender.user as DocumentReference).id ?? (fundToRender.user as User).key;

      await this._user.update(userKey, {
        pendingAccountability: Math.abs(finalBalance)
      } as User);

      await this._fundToRender.update(fundToRender.key, {
        status: FundToRenderStatusEnum.CLOSED
      } as FundToRender);
    }

    if (finalBalance == 0) {
      status = AccountabilityStatusEnum.CLOSED;

      await this._fundToRender.update(fundToRender.key, {
        status: FundToRenderStatusEnum.CLOSED
      } as FundToRender);
    }

    await this._accountability.update(key, {
      status: status,
      closedBy: userReference
    } as Accountability);

    AlertService.toastSuccess('Rendición autorizada');
    this.spinner.hide();
  }

  async reject(accountability: any) {
    let rejectReason = await AlertService.withHtml(
      'Rechazo de rendición',
      `<label>Razón del rechazo:</label>
      <textarea id="swal-input1" rows="3" class="swal2-input"></textarea>`,
      function () {
        return new Promise(function (resolve) {
          resolve($('#swal-input1').val());
        });
      }
    );

    if (!rejectReason.trim()) {
      return AlertService.toastError(
        'Debe ingresar una razón para rechazar la rendición'
      );
    }

    this._accountability.update(accountability.key, {
      status: AccountabilityStatusEnum.REJECTED,
      rejectReason: rejectReason.trim()
    } as Accountability);

    this.spinner.hide();
    AlertService.toastSuccess('Rendición rechazada');
  }
}
