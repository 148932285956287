export enum FundToRenderStatusEnum {
  DRAFT,
  GENERATED,
  REVIEW,
  AUTHORIZED,
  RENDERED,
  CLOSED,
  CANCELLED,
  REJECTED,
  PAID,
  CLOSED_COLSAN,
  CLOSED_USER,
  PAID_COLSAN,
  PAID_USER
}
