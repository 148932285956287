<div class="card mb-0 w-100" (click)="onTaskClicked()">
  <div
    class="card-body p-1 position-relative"
    [ngClass]="{unavailable: !item.isAvailable}">
    <div class="d-flex flex-row flex-wrap">
      <div
        class="badge mr-1"
        [style.backgroundColor]="tag?.color"
        *ngFor="let tag of item.tags">
        {{ tag?.name }}
      </div>

      <div
        class="badge d-flex align-items-center mr-1"
        [ngClass]="{
          'badge-danger': isExpirated(item)
        }"
        *ngIf="unfinishedAndExpiredTask(item)">
        <i class="feather ft-bell"></i
        >{{'Tarea atrasada'}}
      </div>
      <div
        class="badge d-flex align-items-center mr-1"
        [ngClass]="{
          'badge-warning': doneAfterExpiratedDate(item)
        }"
        *ngIf="doneAfterExpiratedDate(item) && finishedAndExpiredTask(item)">
        <i class="feather ft-bell"></i
        >{{'Terminada con atraso'}}
      </div>
    </div>

    <h5 class="normal-text mt-1 mb-2">{{ item.title }}</h5>

    <div class="d-flex justify-content-between">
      <div class="d-flex flex-row justify-content-start">
        <div
          class="badge d-flex align-items-center mr-1"
          [ngClass]="{
            'badge-info': item.status == kanbanStatus.DONE,
            'badge-success': !isExpirated(item) && item.status != kanbanStatus.DONE,
            'badge-danger': isExpirated(item) && item.status != kanbanStatus.DONE
          }"
          *ngIf="!!item.expiratedDate">
          <i class="feather ft-bell"></i
          >{{ (item.status == kanbanStatus.DONE ? item.finishDate : item.expiratedDate) | date: 'MMM, dd' }}
        </div>

        <div class="badge badge-secondary d-flex align-items-center mr-1">
          <i class="feather ft-paperclip"></i>{{ item?.attachments?.length }}
        </div>

        <ul
          class="list-unstyled users-list m-0"
          *ngIf="item?.members?.length > 0">
          <li
            class="avatar avatar-sm pull-up"
            *ngFor="let member of item.members">
            <img
              class="media-object rounded-circle avatar-img"
              container="body"
              ngbTooltip="{{ member?.name }}"
              src="{{
                !!member?.imageUrl
                  ? member.imageUrl
                  : '/assets/images/default.png'
              }}"
              alt="Responsable" />
          </li>
        </ul>
      </div>

      <div class="d-flex justify-content-center">
        <div
          class="badge badge-warning d-flex align-items-center ml-1"
          ngbTooltip="{{ taskOverhaulLabel[item.overhaul] }}"
          *ngIf="item.overhaul == taskOverhaulEnum.ACTIVE && item.status == 2">
          <i class="la la-wrench"></i>
        </div>
        <div
          class="d-flex align-items-center ml-1"
          ngbTooltip="{{ taskOverhaulLabel[item.overhaul] }}"
          *ngIf="
            item.overhaul == taskOverhaulEnum.COMPLETED && item.status == 2
          ">
          <i class="la la-check-circle"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="card-footer p-0 m-0" *ngIf="hasChecklist">
    <progressbar type="info" [value]="totalProgress">
      <span class="text-nowrap">{{ totalProgress.toFixed(0) }}%</span>
    </progressbar>
  </div>
</div>
