import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApplicantService } from '../../../shared/services/applicant.service';
import { map, take } from 'rxjs/operators';
import { AlertService } from '../../../shared/template-services/alert.service';
import { FirebaseDataService } from '../../../shared/template-services/firebase-data.service';
import { Applicant } from '../../interfaces/applicant';
import { ProjectService } from '../../../shared/services/project.service';
import { UserService } from '../../../shared/services/user.service';
import { AuthService } from '../../../shared/template-services/auth.service';
import moment from 'moment';

@Component({
  selector: 'app-assign-project',
  templateUrl: './assign-project.component.html',
  styleUrls: ['./assign-project.component.css']
})
export class AssignProjectComponent implements OnInit, OnDestroy {
  projectForm: FormGroup;
  applicantKey: string;
  applicant: Applicant;
  projectSubscription: Subscription = new Subscription();
  selectArrayProjects: any = [];
  users: any[] = [];

  constructor(public modal: BsModalRef,
              private formBuilder: FormBuilder,
              private _applicant: ApplicantService,
              private _project: ProjectService,
              private _user: UserService,
              private _auth: AuthService,
              private _firebase: FirebaseDataService) {
    this.projectForm = this.formBuilder.group({
      project: ['', Validators.required],
    });
  }

  get formControls() {
    return this.projectForm.controls;
  }

  async ngOnInit(): Promise<void> {
    this.getCategories();
    this.users = await this._user.getAll().pipe(take(1)).toPromise();
  }

  ngOnDestroy() {
    this.projectSubscription.unsubscribe();
  }

  getCategories() {
    this.projectSubscription = this._project.getAll()
      .pipe(
        map(projects => projects.map(project => ({
            name: project.name,
            key: project.key
          }
        )))
      )
      .subscribe(data => {
        this.selectArrayProjects = data;

        if (!!this.applicant.project) {
          let project = this.selectArrayProjects.find(project => project.key == this.applicant.project)
          this.projectForm.patchValue({project: project});
        }
      });
  }

  async submit() {
    if (this.projectForm.valid) {
      let reference = this._firebase.getReference(`projects/${this.projectForm.value.project.key}`)

      await this._applicant.updateProject(this.applicantKey, reference, this.applicant);

      if (!this.users.find(item => item.email === this.applicant.email)) {
        await this.sendAccountModal();
      }

      // const applicants = JSON.parse(localStorage.getItem('applicants'))
      //
      // this.applicant.project = {
      //   ...this.projectForm.value.project,
      //   id: this.projectForm.value.project.key
      // };
      //
      // this.applicant.initialAccessDate = this.applicant.initialAccessDate ? this.applicant.initialAccessDate : moment().add(1, 'year').toDate().getTime()
      // this.applicant.finalAccessDate = this.applicant.finalAccessDate ? this.applicant.finalAccessDate : moment().add(1, 'year').add(1, 'day').toDate().getTime()
      //
      // delete this.applicant.position.reference;
      //
      // localStorage.setItem('applicants', JSON.stringify(applicants.map(item => item.key === this.applicantKey ? this.applicant : item)))

      AlertService.toastSuccess('Se ha cambiado el proyecto');
      this.modal.hide();
    }
  }

  async sendAccountModal() {
    this._auth.addToWhitelist(this.applicant.email);

    let user = {
      rut: this.applicant.rut || '',
      name: this.applicant.name || '',
      surnames: this.applicant.surnames || '',
      city: this.applicant.city || '',
      position: this.applicant.position || '',
      status: this.applicant.status || '',
      birthday: this.applicant.birthday || '',
      workExperience: this.applicant.workExperience || '',
      educationLevel: this.applicant.educationLevel || '',
      email: this.applicant.email || '',
      phone: this.applicant.phone || '',
      nationality: this.applicant.nationality || '',
      experience: this.applicant.experience || '',
      expectation: this.applicant.expectation || '',
      quiz: this.applicant.quiz || '',
      curriculum: this.applicant.curriculum || '',
      isApplicant: true,
      firstLogin: true,
      trash: false,
      isDisable: false,
      permissions: []
    };

    const resp: any = await this._user.createAccount(
      this.applicant.email,
      this.applicant.rut
        .replaceAll('.', '')
        .replaceAll('-', '')
        .slice(0, -1)
    );

    if (!resp.ok) {
      return console.log(AuthService.getMessageError(resp.error.code));
    }

    await this._user.addUserByKey(resp.uid, user);
  }
}

