import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {JobService} from '../../../shared/services/job.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {Job} from '../../interfaces/job';
import {map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {QuizService} from '../../../shared/services/quiz.service';

@Component({
  selector: 'app-add-job',
  templateUrl: './add-job.component.html',
  styleUrls: ['./add-job.component.css']
})
export class AddJobComponent implements OnInit {
  update: boolean;
  job: Job;
  lastPosition: number;
  jobForm: FormGroup;
  submitted: boolean = false;
  quizSubscription: Subscription = new Subscription();
  selectArrayQuiz: any = [];

  constructor(public modal: BsModalRef,
              private formBuilder: FormBuilder,
              private _job: JobService,
              private _quiz: QuizService) {
    this.jobForm = formBuilder.group({
      name: ['', Validators.required],
      quiz: [null],
      position: [''],
      isVisible: [true],
      trash: [false]
    });
  }

  async ngOnInit() {
    this.quizSubscription = await this._quiz.getAll()
      .pipe(
        map(quiz => quiz.map(quiz => ({
            name: quiz.name,
            key: quiz.key
          }
        )))
      )
      .subscribe(data => {
        this.selectArrayQuiz = data;
        if (this.update) {
          this.jobForm.patchValue({
            name: this.job.name,
            position: this.job.position,
            quiz: !!this.job.quiz ? this.selectArrayQuiz[this.selectArrayQuiz.findIndex(quiz => quiz.key == this.job.quiz.id)] : null,
            isVisible: this.job.isVisible
          });
        }
      });
  }

  get formControls() {
    return this.jobForm.controls;
  }

  async submit() {
    this.submitted = true;
    if (this.jobForm.valid) {
      if (!!this.jobForm.value.quiz) await this.jobForm.patchValue({quiz: this._quiz.getReference(this.jobForm.value.quiz.key)});

      if (this.update) {
        await this._job.update(this.job.key, this.jobForm.value as Job)
      } else {
        this.jobForm.patchValue({position: this.lastPosition + 1})
        await this._job.add(this.jobForm.value as Job);
      }

      AlertService.toastSuccess('Se guardó el cargo')
      this.modal.hide();
    }
  }
}
