<app-datatable [rows$]="articlesFiltered$" [config]="config">
  <app-datatable-column title="Código">
    <ng-template let-row #column>
      {{ row?.internalId }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Artículo">
    <ng-template let-row #column>
      {{ row.name }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Categoría">
    <ng-template let-row #column>
      {{ row.categories[0].name }}
    </ng-template>
  </app-datatable-column>

  <ng-template #title></ng-template>

  <app-datatable-column title="Precio">
    <ng-template let-row #column>
      {{ row.price | currency: 'USD':'symbol':'1.0-0' }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Locación">
    <ng-template let-row #column>
      {{ row?.location }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Cantidad">
    <ng-template let-row #column>
      {{ row?.quantity }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Stock mínimo">
    <ng-template let-row #column>
      {{ row?.minStock }}
    </ng-template>
  </app-datatable-column>
</app-datatable>

<button
  class="btn btn-info btn-float-right export-btn"
  app-tooltip="Exportar"
  (click)="exportToCSV()">
  <i class="la la-download"></i>
</button>
