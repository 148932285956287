import {Injectable} from '@angular/core';
import {ChecklistTask} from '../interfaces/checklist-task';
import {FirebaseDataService} from '../../../../shared/template-services/firebase-data.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChecklistService {
  constructor(private db: FirebaseDataService, private afs: AngularFirestore) {}

  getAll(boardKey: string, kanbanTaskKey: string): Observable<ChecklistTask[]> {
    return this.db.colWithIds$<ChecklistTask>(
      `boards/${boardKey}/tasks/${kanbanTaskKey}/checklists`,
      (ref) => ref.where('trash', '==', false).orderBy('createdAt', 'asc')
    );
  }

  addTask(
    boardKey: string,
    kanbanTaskKey: string,
    checklistTask: ChecklistTask
  ) {
    this.afs
      .collection(`boards/${boardKey}/tasks/${kanbanTaskKey}/checklists`)
      .add(checklistTask);
  }

  updateTask(
    boardKey: string,
    kanbanTaskKey: string,
    checklistKey: string,
    checklistTask: ChecklistTask
  ) {
    this.afs
      .doc(
        `boards/${boardKey}/tasks/${kanbanTaskKey}/checklists/${checklistKey}`
      )
      .update(checklistTask);
  }
}
