import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../shared/template-services/alert.service';
import {ProjectCategory} from '../../interfaces/project-category';
import {ProjectService} from '../../../shared/services/project.service';
import {take} from 'rxjs/operators';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';

@Component({
  selector: 'app-project-categories-modal',
  templateUrl: './project-categories-modal.component.html',
  styleUrls: ['./project-categories-modal.component.css']
})
export class ProjectCategoriesModalComponent implements OnInit {
  isUpdate: boolean = false;
  isSubCategory: boolean = false;
  projectCategoryForm: FormGroup;
  submitted: boolean = false;
  projectCategory: any;
  categoryKey: string;
  subCategoryKey: string;

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _project: ProjectService,
              private _firebase: FirebaseDataService) {
    this.projectCategoryForm = formBuilder.group({
      name: ['', Validators.required],
      reference: [null],
      subCategory: [false],
      trash: [false]
    });
  }

  async ngOnInit() {
    if (this.isUpdate) {
      this.isSubCategory ? this.projectCategory = await this._project.getCategory(this.subCategoryKey).pipe(take(1)).toPromise() : this.projectCategory = await this._project.getCategory(this.categoryKey).pipe(take(1)).toPromise();
      this.projectCategoryForm.patchValue(this.projectCategory);
    }

    if (this.isSubCategory) this.projectCategoryForm.patchValue({subCategory: true});
  }

  get formControls() {
    return this.projectCategoryForm.controls;
  }

  async submit() {
    this.submitted = true;
    if (this.projectCategoryForm.valid) {
      if (!this.isUpdate && this.isSubCategory) this.projectCategoryForm.patchValue({reference: this._firebase.getReference(`projectCategories/${this.categoryKey}`)})
      this.isUpdate ? await this._project.updateCategory(this.projectCategory.key, this.projectCategoryForm.value as ProjectCategory) : await this._project.addCategory(this.projectCategoryForm.value as ProjectCategory);
      AlertService.toastSuccess(this.isUpdate ? 'Se actualizó la categoría' : 'Se agregó la categoría');
      this.modal.hide();
    }
  }
}
