import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-refund-modal',
  templateUrl: './refund-modal.component.html',
  styleUrls: ['./refund-modal.component.css']
})
export class RefundModalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
