<div class="d-flex flex-row w-100">
  <ng-select
    class="w-25"
    placeholder="Filtrar por proveedor"
    [items]="providers"
    [(ngModel)]="providerSelected"
    bindValue="value"
    (ngModelChange)="filterArticles()
    "></ng-select>

  <ng-select
    class="w-25 ml-2"
    placeholder="Filtrar por orden de compra"
    [items]="purchaseOrders"
    [(ngModel)]="purchaseOrderSelected"
    bindValue="value"
    (ngModelChange)="filterArticles()"></ng-select>
</div>

<app-datatable [rows$]="articlesFiltered$"
               [config]="config"
               (rowSelected)="openArticle($event)">

  <app-datatable-column title="Artículo">
    <ng-template let-row #column>
      {{row.name}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Código de proveedor">
    <ng-template let-row #column>
      {{row?.providerCode}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Proveedor">
    <ng-template let-row #column>
      {{row.provider ? row.provider.name : 'No registrado'}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Unidad">
    <ng-template let-row #column>
      {{row.unitType ? row.unitType.name : ''}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Precio unitario Neto">
    <ng-template let-row #column>
      {{row.unitPrice}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Exento">
    <ng-template let-row #column>
      {{row.exempt ? 'Si' : 'No'}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Orden de compra">
    <ng-template let-row #column>
      {{row?.purchaseOrder ? ('OC' + ' ' + row?.purchaseOrder?.purchaseID | slice : 0: -3) : ''}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Última actualización">
    <ng-template let-row #column>
      {{row.createdAt | date : 'dd/MM/yyyy HH:mm'}}
    </ng-template>
  </app-datatable-column>

</app-datatable>
