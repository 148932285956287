import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.css']
})
export class ChangeLogComponent implements OnInit {

  public breadcrumb: any;
  options = {
    close: true,
    expand: true,
    minimize: true,
    reload: true
  };

  updates = [
    {
      date: '20/01/2022',
      version: '1.4.21',
      sections: [
        {
          name: 'Menú lateral',
          changes: [
            'Se solucionó un problema al cargar el menú lateral',
            'Se redujo el ancho de el menú lateral para optimizar el espacio de las pantallas'
          ]
        },
        {
          name: 'Facturas',
          changes: [
            'Se agregaron los iconos de estatus en la ventana emergente',
            'Se agregó una pantalla de carga cuando se clasifica la factura',
            'Cambió el la fecha inicial y final en el filtro por fecha',
            'Se agregó el campo método de pago',
            'El botón de cancelar solo se muestra cuando se está editando una factura',
            'Ahora es posible agregar múltiples facturas a una orden de compra hasta que el total de esta sea cubierto',
            'Se modificó el diseño de la ventana de creación y edición de facturas',
            'Cuando se edita una factura, se cambiaron algunos campos no editables a etiquetas de texto',
            'Se aumentó el tamaño de la ventada de crear y editar factura',
            'Cambió la estructura de la ventana de crear y editar factura',
            'Se recolocaron algunos elementos, se redujeron espacios entre las columnas de las tablas',
            'Se agregaron validaciones para crear o editar facturas correctamente',
            'Se agregaron gráficos para las facturas pagadas y no pagadas',
            'Se agregó la pantalla de cargando cuando se está obteniendo la información',
            'Se agregaron mensajes de error que ayudan al usuario a rellenar correctamente los campos',
            'Se resolvieron errores por los cuales no se podía agregar correctamente las facturas',
            'Se agregó el botón de cerrar y se cambió el tamaño del PDF',
            'La fecha de pago ahora se muestra cuando la factura ha sido pagada',
            'Los campos de fecha ahora tienen la fecha actual  como valor predeterminado'
          ]
        },
        {
          name: 'Notificaciones',
          changes: [
            'Se arregló un problema con el filtro de notificaciones',
            'Se agregó un botón para marcar como leídas todas la notificaciones no leídas',
            'Se agregó el filtro de fechas a las notificaciones no leídas',
            'Se agregó filtro de fechas',
            'Se creó la sección'
          ]
        },
        {
          name: 'Usuarios',
          changes: [
            'Algunos campos de fecha del centro de costos tienen como valor predeterminado la fecha actual',
            'Ahora se pueden filtrar usuarios con nombres con acento y sin acento',
            'Ahora se pueden filtrar los usuarios entre postulantes o internos',
            'Se agregó una nueva pestaña para mostrar los usuarios admitidos',
            'Se agregaron nuevas opciones: eliminar usuario, cambiar de postulante a interno, cambiar la contraseña',
            'Se fusionaron la lista de internos y postulantes en la lista de usuarios'
          ]
        },
        {
          name: 'Proyectos',
          changes: [
            'Se corrigió un error para que las ventanas del centro de costos cierren correctamente',
            'Se agregó como valor predeterminado, la fecha de hoy, a todos los campos de fecha',
            'Se removió la columna de hora de creación y se agregó la columna de valor de venta',
            'La configuración del proyecto sólo puede ser modificada por administradores y supervisores',
            'En detalles del proyecto, ahora funciona correctamente el botón para eliminar el proyecto',
            'Se agregó el botón para eliminar un proyecto',
            'Se agregaron botones de acceso rápido, para cambiar el proyecto de activo a inactivo y viceversa',
            'Se agregó la columna de dias transcurridos, donde se muestran los días de ejecución y los días de retraso',
            'Se agregó la columna valor de venta',
            'Cuando la fecha de finalización de un proyecto ha pasado, el estatus cambia a expirado,',
            'Cuando se edita un proyecto, si la fecha de finalización ya ha pasado, se cambia su estatus a expirado',
            'Cuando pasa la fecha de finalización de un proyecto, su estatus se cambia a expirado y el estatus cambia a calificado de forma automáticamente'
          ]
        },
        {
          name: 'Postulantes',
          changes: [
            'El espacio con información de los postulantes al lado izquierdo, se le aplicó un tamaño estático',
            'Se agregó un botón para eliminar los registros duplicados',
            'Ya no es posible registrar postulantes con la misma RUT',
            'Se removió el título obligatorio',
            'Se validó para que el PDF no se descargue automáticamente',
            'Se alinearon los botones de currículum y descargar archivo',
            'Ahora se pueden filtrar postulantes con nombres con acento y sin acento',
            'El botón flotante se movió hacia la parte de arriba',
            'Se cambió el tamaño del PDF',
            'Se modificó la estructura del registro general, ahora la información se acomoda mejor',
            'Se removió la imagen de usuario para mejorar la visibilidad',
            'Se guardan los filtros, cuando vuelves a la sección se aplica el último',
            'Se corrigió un error en el cual los postulantes se duplicaban',
            'Se agregó un filtro para que solo se muestren los proyectos activos en los que se encuentra el postulante',
            'Ahora el paso 2 del registro puede ser saltado, se aplicó un nuevo  filtro para que solo muestre las ciudades registradas',
            'En el registro se removieron algunos elementos',
            'Se cambió la estructura del registro de postulante, se agregó una imagen de cabecera',
            'Se modificó la estructura de la información personal',
            'Se removió la imagen de portada para mejorar la visibilidad de la página',
            'Se eliminó la imagen al inicio de la página',
            'Se cambió el diseño de la información general del postulante',
            'La sección de comentarios se muestra a la izquierda del cv',
            'En el registro de postulante, el servicio médico se muestra en una lista',
            'En el registro de postulante, en el campo tamaño de zapato solo se muestran valores enteros',
            'En el registro de postulante el salario se muestra con formato de divisa',
            'En el registro de postulante la nacionalidad por defecto es Chile',
            'Se cambió el tamaño de los campos de registro de los postulantes',
            'Se agregó la ciudad en la información del postulante'
          ]
        },
        {
          name: 'Compras',
          changes: [
            'Se corrigió un error que sucedía cuando pasabas una tarea a borrador y cambiabas el centro de costo o proyecto, generabas la orden de compra pero ya no era posible autorizarla',
            'Ahora es posible agregar múltiples facturas a una orden de compra hasta que el total de esta sea cubierto',
            'Ahora esta sección es responsiva',
            'Se agregó validación para impedir que se actualice una orden si no se selecciona un documento de cotización',
            'Cuando seleccionas un artículo se muestra una ventana con la información este',
            'Se notifica cuando la orden de compra cambia de estado',
            'Se eliminaron algunos filtros, se mantuvieron los esenciales',
            'En la lista se agregaron valores por defecto al filtro por fecha'
          ]
        },
        {
          name: 'Bodega',
          changes: [
            'En el resúmen, se cambió el artículo más retirado filtrado por fecha a un top 10 artículos más retirados filtrados por fecha',
            'Se corrigió un error que hacía que las categorías cuyo nombre estaba compuesto por dos o más palabras, se mostraba todo junto',
            'Ahora esta sección es responsiva',
            'Se agregó la opción de seleccionar múltiples artículos para eliminarlos',
            'Se solucionó un error que ocasionaba que la información no cargara correctamente la primera vez que se accedía a esta sección',
            'Cuando un artículo es regresado se muestran los seriales',
            'En el resumen se agregó un sección con el artículo más retirado',
            'Ahora se muestran en el resumen, los artículos consumibles almacenados',
            'Se agregó el nombre de la bodega en el ticket',
            'En el ticket ahora es obligatorio el campo comentario, se cambió la etiqueta usuario a retirado por',
            'Se removió la imagen de portada para mejorar la visibilidad de la página',
            'Se notifica cuando los ticket cambian de estado',
            'La pestaña de tickets generados/autorizados fue separada en dos diferentes, una muestra los tickets generados y la otra los tickets autorizados',
            'Ahora se muestran los tickets autorizados y generados en la lista de bodegas',
            'Se habilitó la pestaña de tickets authorizados',
            'El campo poara editar la cantidad de los artículos es mostrado cuando el estado del ticket es generado',
            'Ahora se puede modificar la cantidad de artículos en los tickets',
            'Se agregó a los tickets el estado cancelado'
          ]
        },
        {
          name: 'Tareas',
          changes: [
            'Cuando se crea una tarea, puedes seleccionar el tablero de destino, solo aparecen disponibles los tableros en los que el usuario está asignado',
            'Ahora el supervisor puede aceptar la fecha de finalización',
            'Cuando se está creando una nueva tarea se agregó una alerta cuando no se asigna un usuario',
            'Se cambió la estructura de la tarea, se alinearon elementos, se modificaron espacios y márgenes',
            'Ahora es obligatorio rellenar el campo asignados',
            'En la tarea, el botón comenzar se renombró a en proceso',
            'Se cambió el diseño de las tareas para asimilarse a las de Intech-crm',
            'Se cambió el tamaño de la ventana de tareas, se cambió el tamaño de la sección de comentarios',
            'Se agregaron los tableros personales',
            'Se notifica cuando se completa una tarea',
            'Cuando una tarea es pasada a HECHO Se registra la fecha actual como fecha de finalización'
          ]
        }
      ]
    },
    {
      date: '01/09/2021',
      version: '1.4.15',
      sections: [
        {
          name: 'Proyectos',
          changes: [
            'En presupuesto, si se selecciona una categoría, se muestran las facturas asociadas al proyecto seleccionado y con esta categoría',
            'El presupuesto restante, gastado y total ahora es calculado correctamente',
            'Cuando se va a agregar un nuevo proyecto el campo nombre se inicializa con el código os',
            'Se agregó la columna orden de servicio en la tabla de proyectos',
            'Los proyectos se ordenan por orden de servicio',
            'Se agregó la pestaña de facturas en los detalles del proyecto',
            'Sólo se muestran las facturas asociadas al proyecto seleccionado',
            'El campo Centro de costo no se puede cambiar cuando se edita un proyecto',
            'Se agregó la pestaña de compras en los detalles del proyecto',
            'Sólo se muestran las ordenes de compra asociadas al proyecto seleccionado'
          ]
        },
        {
          name: 'Facturas',
          changes: [
            'Algunos elementos solo son visibles cuando se edita la factura',
            'Se corrigió un error en el que no permitía crear una factura sin agregar un comprobante',
            'Se modificó el diseño del menú para agregar o modificar una nueva factura',
            'En el menú para agregar o editar una factura, el pdf de la factura y comprobante son obligatorios',
            'Cuando carga por primera vez, las facturas se filtran con un periodo de 3 meses atrás hasta la fecha actual',
            'Se cambió el diseño de los estatus de la factura y del botón de eliminar'
          ]
        },
        {
          name: 'Compras',
          changes: [
            'Se agregó a la tabla las columnas RUT y descripción',
            'En el menú para agregar y editar orden de compra, se cambió el campo comentarios a descripción',
            'En los menús para agregar y editar orden de compra se movió de lugar el campo descripción hacia la parte superior',
            'Se cambió el diseño del estatus, si está pagada y la entrega'
          ]
        }
      ]
    },
    {
      date: '18/08/2021',
      version: '1.4.12',
      sections: [
        {
          name: 'Facturas',
          changes: [
            'Se corrigió el error al importar excel de facturas, no agregaba fecha de inicio.'
          ]
        }
      ]
    },
    {
      date: '05/08/2021',
      version: '1.4.8',
      sections: [
        {
          name: 'Kanban',
          changes: [
            'Se agregó la fecha de finalización de la tarea',
            'Cuando un supervisor o administrador pasa una tarea a "HECHO" se establece como finalizada - aprobada',
            'Ahora se pueden arrastrar las tareas entre columnas libremente',
            'El botón "Comenzar" solo aparece con el status "POR HACER"',
            'El botón "Completar" solo aparece con el status "EN PROCESO"'
          ]
        }
      ]
    },
    {
      date: '05/08/2021',
      version: '1.4.7',
      sections: [
        {
          name: 'Kanban',
          changes: [
            'Agregar tareas periodicas',
            'En reportes si están en proceso que aparezca el porcentaje actual y agregar una calificación global',
            'En el filtro de rango de fechas mejor elegir meses',
            'En adjuntos que se puedan agregar cualquier tipo de archivos',
            'Reporte de tareas realizadas, y todas las tareas que no estén realizadas pero estén bajo la fecha "hasta"',
            'Agregar validación de peso de archivo, 10MB',
            'Agregar a la derecha un panel de comentarios',
            'Agregar logs de cambios de la tarea',
            'Poder desligar una tarea',
            'Agregar solo Alta media y baja en producción',
            'Cuando sea un checklist de cero porcierto que diga 0%',
            'Agregar filtro de personas y que solo se vean las tareas vigentes no en DONE',
            'Cuando pasa a hecho un usuario no sale el icono amarillo',
            'Cuando llegan nuevos datos al tablero no se aplican los filtros',
            'Cuando la tarea está acordada quitar las fechas sugeridas',
            'Agregar sistema de penalizaciones cuando no cumple con una tarea del mes pasado (que el peso de la tarea se calcule con un porcentaje dado por el administrador en settings)',
            'Agregar botón para seleccionar una persona y rango de fechas para generar un reporte de las tareas finalizadas en ese periodo de tiempo, se imprimirá ese reporte',
            'Notificar por email cuando cambian la fecha de finalización',
            'Ahora se puede editar el título de la tarea'
          ]
        },
        {
          name: 'General',
          changes: [
            'Se quitó el prefijo USD',
            'Los totales ya se calculan correctamente'
          ]
        }
      ]
    },
    {
      date: '27/07/2021',
      version: '1.4.6',
      sections: [
        {
          name: 'Kanban',
          changes: [
            'Se oculta la fecha de finalización hasta que la tarea llegue a un acuerdo',
            'Se cambió el nombre del menú Kanban por Tareas',
            'Si una tarea está marcada como lista, sólo el supervisor podrá moverla',
            'Se agregó filtro por fecha y filtro por usuarios asignados',
            'Las tareas se filtran por defecto con el mes actual',
            'Ahora se pueden ligar tareas creadas en el checklist',
            'Se cambió la barra de progreso a pocertual',
            'Ahora se pueden asignar adminsitradores como supervisor de un tablero',
            'Se cambió etiquetas por priorida y se dejaron tres opciones fijas "Alta, Media, Baja"',
            'La etiqueta de vencimiento mantiene el estado correcto cuando está en "List"'
          ]
        },
        {
          name: 'General',
          changes: [
            'El menú se mostrará expandido por defecto'
          ]
        },
        {
          name: 'Facturas',
          changes: [
            'Se quitó la palabra OC después del número',
            'Se quitó el número duplicado Número de documento'
          ]
        }
      ]
    },
    {
      date: '15/07/2021',
      version: '1.4.4',
      sections: [
        {
          name: 'Facturas',
          changes: [
            'Se corrigió que no cargaran las categorías al seleccionar un proyecto o centro de costos',
            'Se corrigió el que no se pudiera actualizar una factura'
          ]
        }
      ]
    },
    {
      date: '13/07/2021',
      version: '1.4.3',
      sections: [
        {
          name: 'Facturas',
          changes: [
            'Poder seleccionar una orden de compra al generar la factura'
          ]
        }
      ]
    },
    {
      date: '12/07/2021',
      version: '1.4.2',
      sections: [
        {
          name: 'Facturas',
          changes: [
            'Que el proyecto no se pueda modificar si ha seleccionado la orden compra',
            'Agregar facturas sin orden de compra',
            'Proyecto y categoría no se vean, cuando seleccionan sin orden de compra que aparezcan y si selecciona la orden de compra que aparezcan, pero no se pueda modificar',
            'Cuando seleccione la orden de compra que salga el proyecto y la categoría',
            'Poder quitar orden de compra de una factura. Para que pueda volverla a clasificar'
          ]
        },
        {
          name: 'Ordenes de compra',
          changes: [
            'El centro de costo actualmente está mostrando el proveedor.',
            'Centro de costo que salga',
            'Poder ver la categoría seleccionada'
          ]
        },
        {
          name: 'Kanban',
          changes: [
            'Que todos los usuarios puedan agregar tareas, pero en status pendiente',
            'Que el usuario pueda ver las demás tareas, pero no editar en la que no haya sido asignado (Solo puede usar el checklist y comentarios)',
            'Que el usuario pueda hacer comentarios',
            'Agregar notificaciones cuando hagan comentario o de checklist',
            'Las tareas no acordadas saldrán en gris y no se podrán mover',
          ]
        }
      ]
    }
  ];

  constructor() {
  }

  ngOnInit() {
    this.breadcrumb = {
      'mainlabel': 'ChangeLog',
      'links': [
        {
          'name': 'Home',
          'isLink': true,
          'link': '/dashboard/sales'
        },
        {
          'name': 'ChangeLog',
          'isLink': false,
          'link': '#'
        },
      ]
    };
  }
}


