import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTableConfig } from 'src/app/shared/interfaces/data-table-config';
import { Task } from '../../pages/kanban/interfaces/task';

@Component({
  selector: 'app-kanban-tasks-list',
  templateUrl: './kanban-tasks-list.component.html',
  styleUrls: ['./kanban-tasks-list.component.css']
})
export class KanbanTasksListComponent implements OnInit {
  @Input() tasks$: Observable<Task[]>;
  @Output() taskClicked: EventEmitter<Task> = new EventEmitter<Task>()
  dataTableConfig: DataTableConfig = {
    title: 'Tareas',
    notFoundText: 'No se encontraron tareas',
    hasSearch: true
  }

  constructor() { }

  ngOnInit() {
  }

  onTaskClicked(task: Task) {
    this.taskClicked.emit(task);
  }
}
