import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Warehouse } from '../../interfaces/warehouse';
import { Observable, Subscription } from 'rxjs';
import { WarehouseService } from '../../../../../shared/services/warehouse.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddTicketComponent } from '../../modals/add-ticket/add-ticket.component';
import { AddArticleComponent } from '../../modals/add-article/add-article.component';
import { AlertService } from '../../../../../shared/template-services/alert.service';
import { ArticleService } from '../../../../../shared/services/article.service';
import { Article } from '../../interfaces/article';
import { UpdateWarehouseComponent } from '../../modals/update-warehouse/update-warehouse.component';
import { WarehouseTab } from '../../enums/warehouse-tab.enum';
import { AddArticlesComponent } from '../../modals/add-articles/add-articles.component';
import { UserService } from '../../../../../shared/services/user.service';
import { UserType } from '../../../../enums/user-type.enum';
import { LogsService } from '../../../../services/logs.service';
import { TicketAuthorizationService } from '../../../../../shared/services/ticket-authorization.service';
import { TicketAuthorization } from '../../interfaces/ticket-authorization';
import { take } from 'rxjs/operators';
import { WarehouseDetailService } from '../../services/warehouse-detail.service';
import { AddTicketEntryArticlesComponent } from '../../modals/add-ticket-entry-articles/add-ticket-entry-articles.component';
import { TabsPermission } from '../../enums/tabs-permission.enum';
import { PermissionService } from '../../services/permission.service';

@Component({
  selector: 'app-warehouse-details',
  templateUrl: './warehouse-details.component.html',
  styleUrls: ['./warehouse-details.component.scss']
})
export class WarehouseDetailsComponent implements OnInit, OnDestroy {
  end: any;
  warehouseKey: string;
  warehouse$: Observable<Warehouse>;
  articleSubscription: Subscription = new Subscription();
  requestsSubscription: Subscription = new Subscription();
  ticketAuthorizationSubscription: Subscription = new Subscription();
  articleReferences: Article[] = [];
  ticketAuthorizationReferences: TicketAuthorization[] = [];
  requestsReference: TicketAuthorization[];
  tabEnum = WarehouseTab;
  tabIndex: number = 0;
  logs$: Observable<any>;
  permission: UserType;
  permissionEnum = UserType;
  ticket: any;
  warehouseSubscription: Subscription = new Subscription();
  warehouse: Warehouse;
  articles: Article[] = [];
  articlesConsumable: Article[] = [];
  tabsPermissions = TabsPermission;
  hasPermissionsLoaded: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
              private _warehouse: WarehouseService,
              private _article: ArticleService,
              private modal: BsModalService,
              private router: Router,
              public _user: UserService,
              private _ticketAuthorized: TicketAuthorizationService,
              private _log: LogsService,
              private _warehouseDetail: WarehouseDetailService,
              private _permission: PermissionService) {
  }

  async ngOnInit(): Promise<void> {
    this.hasPermissionsLoaded = false;
    this._permission.resetUserType();

    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'BODEGAS'
    ).permission;

    this.loadWarehouseKey();
    this.loadArticles();

    this.warehouseSubscription = this._warehouse
      .get(this.warehouseKey)
      .subscribe((data) => {
        this.warehouse = data;

        if (this.permission != this.permissionEnum.ADMIN) {
          let supervisor = false;
          let grocer = false;
          let buyer = false;

          if (this.warehouse.grocer.reference.id == this._user.user.key) {
            this._permission.setUserType(this.permissionEnum.GROCER);
            grocer = true;
          }

          if ('buyer' in this.warehouse && !!this.warehouse.buyer && (<any>this.warehouse.buyer).id == this._user.user.key) {
            this._permission.setUserType(this.permissionEnum.BUYER);
            buyer = true;
          }

          for (let warehouseManager of this.warehouse.warehouseManager) {
            if (warehouseManager.reference.id == this._user.user.key) {
              this._permission.setUserType(this.permissionEnum.SUPERVISOR);
              supervisor = true;
            }
          }

          for (let user of this.warehouse.users) {
            if (user.reference.id == this._user.user.key) {
              this._permission.setUserType(this.permissionEnum.USER);
            }
          }

          if (!supervisor && !grocer && !buyer) {
            this._permission.setUserType(this.permissionEnum.USER);
            this.permission = this.permissionEnum.USER;
          }
        }
        this._warehouseDetail.warehouse = this.warehouse;
        this.hasPermissionsLoaded = true;
      });

    this.logs$ = this._log.getAll(this.warehouseKey);
    this._warehouseDetail.logs$ = this.logs$;

    this.ticketAuthorizationSubscription = this._ticketAuthorized
      .getAll()
      .subscribe((data) => {
        this.ticketAuthorizationReferences = data;
        this._warehouseDetail.ticketAuthorizationReferences =
          this.ticketAuthorizationReferences;
      });

    this.requestsSubscription = this._ticketAuthorized
      .getAllRequests(this.warehouseKey)
      .subscribe((data) => {
        this.requestsReference = data;
        this._warehouseDetail.requestsReference = this.requestsReference;
      });
  }

  ngOnDestroy() {
    this.warehouseSubscription.unsubscribe();
    this.hasPermissionsLoaded = false;
  }

  loadArticles() {
    this.articleSubscription = this._article
      .getAllByWarehouse(this.warehouseKey)
      .subscribe((data) => {
        this.articles = data.filter((article) => article.type == 0);
        this.articlesConsumable = data.filter((article) => article.type == 1);

        this._warehouseDetail.articles = this.articles;
        this._warehouseDetail.articlesConsumable = this.articlesConsumable;
        this._warehouseDetail.articleReferences = this.articleReferences = data;
      });
  }

  loadWarehouseKey() {
    const warehouse = this.activatedRoute.snapshot.params['warehouseKey'];
    if (!!warehouse) {
      this.warehouseKey = warehouse;
    }

    this._warehouseDetail.warehouseKey = this.warehouseKey;
  }

  openModalAddTicket() {
    this.tabIndex = WarehouseTab.TICKETS;
    this.selectTab(this.tabIndex, 'tickets');

    const modalRef = this.modal.show(AddTicketComponent, {
      initialState: {
        warehouseKey: this.warehouseKey,
        warehouse: this.warehouse,
        articleReferences: this.articleReferences
      },
      class: 'modal-xl',
      backdrop: 'static'
    });

    modalRef.onHide.pipe(take(1)).subscribe(() => {
      this.ticket = modalRef.content.ticket;
      this._warehouseDetail.ticket = this.ticket;
    });
  }

  openAddEntryArticlesTicketModal() {
    this.tabIndex = WarehouseTab.TICKETS_ENTRY_ARTICLES;
    this.selectTab(this.tabIndex, 'tickets-article-entry');

    this.modal.show(AddTicketEntryArticlesComponent, {
      initialState: {
        warehouseKey: this.warehouseKey,
        warehouse: this.warehouse,
        articleReferences: this.articleReferences
      } as any,
      class: 'modal-xl',
      backdrop: 'static'
    });
  }

  openModalAddArticle() {
    this.tabIndex = WarehouseTab.ARTICLES;
    this.modal.show(AddArticleComponent, {
      initialState: {
        warehouseKey: this.warehouseKey
      },
      backdrop: 'static',
      class: 'modal-lg'
    });
  }

  async deleteWarehouse(warehouse) {
    if (
      (await AlertService.input(
        '¿Quieres borrar esta bodega?',
        `Para confirmar que deseas borrar esta bodega, escribe su código: ${warehouse.code}`,
        'Aceptar'
      )) == warehouse.code
    ) {
      await this._warehouse.delete(warehouse.key);
      this._log.add(this.warehouseKey, {
        description: `Se eliminó la bodega ${warehouse.name}`
      });
      AlertService.toastSuccess('Se eliminó correctamente', '');
      this.router.navigateByUrl('admin/warehouse');
    }
  }

  openUpdateWarehouseModal(warehouse: Warehouse) {
    this.modal.show(UpdateWarehouseComponent, {
      initialState: {
        warehouseKey: warehouse.key,
        warehouse
      }
    });
  }

  openAddArticlesModal() {
    this._warehouseDetail.submitExcel.next(true);
    const modalRef = this.modal.show(AddArticlesComponent, {
      initialState: {
        warehouseKey: this.warehouseKey
      },
      class: 'modal-lg'
    });

    modalRef.onHide.pipe(take(1)).subscribe(() => {
      this._warehouseDetail.submitExcel.next(false);
    });
  }

  selectTab(tabIndex: number, tab) {
    this.tabIndex = tabIndex;
    this.router.navigateByUrl(
      `admin/warehouseDetails/${this.warehouseKey}/${tab}`
    );
  }

  validateAddEntryArticleTicketButton() {
    return (
      ((this.tabIndex == this.tabEnum.ARTICLES ||
          this.tabIndex == this.tabEnum.TICKETS_ENTRY_ARTICLES) &&
        (this.permission == this.permissionEnum.GROCER ||
          this.permission == this.permissionEnum.ADMIN ||
          this.permission == this.permissionEnum.SUPERVISOR)) ||
      (this.tabIndex == this.tabEnum.TICKETS_ENTRY_ARTICLES &&
        this.permission == this.permissionEnum.BUYER)
    );
  }

  validateUserPermission(permissionExpected) {
    return this.permission == permissionExpected;
  }

  validateAddArticlesButtonPermission() {
    return (
      this.tabIndex == this.tabEnum.ARTICLES &&
      (
        this.validateUserPermission(this.permissionEnum.ADMIN) ||
        this.validateUserPermission(this.permissionEnum.SUPERVISOR) ||
        this.validateUserPermission(this.permissionEnum.BUYER)
      )
    );
  }
}
