import {Component, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {Observable} from 'rxjs';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {UnitService} from '../../services/unit.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {UnitModalComponent} from '../../modals/unit-modal/unit-modal.component';
import {ObjectService} from '../../../shared/template-services/object.service';

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.css']
})
export class UnitsComponent implements OnInit {
  units$: Observable<any>;
  config: DataTableConfig = {
    title: '',
    notFoundText: 'No se encontraron unidades',
    hasSearch: true,
    exportCallback: this.decoratedUnitsToExport.bind(this),
    excelFileName: 'Unidades'
  };

  constructor(private modal: BsModalService,
              private _unit: UnitService) {
  }

  ngOnInit(): void {
    this.units$ = this._unit.getAll();
  }

  async deleteUnit(key) {
    if (await AlertService.confirm('¿Estás seguro de que deseas eliminar esta unidad?', '')) {
      await this._unit.delete(key);
      AlertService.toastSuccess('Se eliminó correctamente', '');
    }
  }

  gotToUnitEdit(unit) {
    this.modal.show(UnitModalComponent, {
      initialState: {
        unit: {...unit},
        isUpdate: true
      },
      id: 50
    });
  }

  addUnit() {
    this.modal.show(UnitModalComponent, {
      id: 50
    });
  }

  decoratedUnitsToExport(units: any[]) {
    return ObjectService.replaceUndefined(units.map(unit => ({
      name: unit.name,
    })));
  }
}
