import {Injectable} from '@angular/core';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {UnitMeasure} from '../interfaces/unit-measure';
import {Category} from "../interfaces/category";

@Injectable({
  providedIn: 'root'
})
export class UnitsMeasureService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  getAll(): Observable<UnitMeasure[]> {
    return this.db.colWithIds$<UnitMeasure>('unitsMeasure', ref => ref
      .where('trash', '==', false));
  }

  add(units: UnitMeasure): Promise<DocumentReference> {
    return this.afs.collection('unitsMeasure').add(units);
  }

  delete(unitsKey: string): Promise<void> {
    return this.afs.doc<UnitMeasure>(`unitsMeasure/${unitsKey}`).update({trash: true});
  }

  addByID(units: UnitMeasure, unitsKey: string) {
    this.afs.doc(`unitsMeasure/${unitsKey}`).set(units);
  }
}

