<div class="p-4 d-flex flex-column justify-content-center align-items-center">

  <h1>Compartir enlace</h1>

  <p class="text-center">Comparte el siguiente código para que el postulante pueda acceder al enlace y subir sus documentos.</p>

  <div *ngIf="applicant.code; else loading"
       class="code mt-2 text-center mb-1">
    {{applicant?.code}}
  </div>

  <p class="text-muted text-small text-center mb-3">{{applicantUrl}}</p>

  <ng-template #loading>
    <i class="fa fa-spinner fa-pulse fa-fw fa-3x mb-1"></i>
  </ng-template>

<div class="d-flex justify-content-center" style="gap: 10px">
  <button
    (click)="copyCode()"
    class="btn btn-success btn-lg">
    <i class="fa fa-copy"></i> Copiar código
  </button>

  <button
    (click)="copyLink()"
    class="btn btn-info btn-lg">
    <i class="fa fa-copy"></i> Copiar enlace
  </button>
</div>
</div>
