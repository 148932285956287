<ng-container>
  <div class="mt-2 ml-2">
    <div class="btn btn-danger" (click)="modal.hide()">Cerrar</div>
  </div>

  <!-- <ng-container>
    <app-datatable [rows$]="purchases$"
                   [config]="configPurchase"
                   (rowSelected)="gotoOpenEditPurchaseOrder($event)">

      <app-datatable-column title="OC">
        <ng-template let-row #column>
          <label *ngIf="!!row.deliveryDateChanged && row.deliveryDateChanged"><i class="feather ft-alert-triangle"></i></label>
          {{ row.purchaseID | slice:0 : -3 }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Proveedor">
        <ng-template let-row #column>
          {{ getProvider(row.provider)?.name }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="RUT">
        <ng-template let-row #column>
          {{ getProvider(row.provider)?.rut }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Monto">
        <ng-template let-row #column>
          {{ row.total | clearCurrency | swapCommasAndDots}}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Fecha de entrega">
        <ng-template let-row #column>
          {{ row?.deliveryDate | date : 'dd/MM/yyyy' }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="">
        <ng-template let-row #column>
      <span class="badge badge-pill"
            [ngClass]="getStatusClass(row.status)">{{purchaseStatusLabel[row.status]}}</span>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="">
        <ng-template let-row #column>
        <span class="badge badge-pill"
              [ngClass]="getPaymentClass(row.paymentStatus)">{{paymentStatusLabel[row.paymentStatus]}}</span>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Mover fecha" [isClickable]="false">
        <ng-template let-row #column>
          <button (click)="changePayday(row.key, row.deliveryDate, 'purchase')" class="btn btn-info"><i
            class="feather ft-corner-up-right"></i></button>
        </ng-template>
      </app-datatable-column>
    </app-datatable>
  </ng-container> -->

  <ng-container *ngIf="bills.length != 0">
    <app-datatable [rows$]="bills$"
                   [config]="configPurchase"
                   (rowSelected)="gotoOpenEditBill($event)">

      <app-datatable-column title="Tipo">
        <ng-template let-row #column>
          <label *ngIf="!!row.deliveryDateChanged && row.deliveryDateChanged"><i class="feather ft-alert-triangle"></i></label>
          {{ row.documentType }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="N. factura">
        <ng-template let-row #column>
          {{ row.documentNumber }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Fecha de emision">
        <ng-template let-row #column>
          {{ row.startDate | date : 'dd/MM/yyyy'}}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="RUT">
        <ng-template let-row #column>
          {{ getRut(row.provider) }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Proveedor">
        <ng-template let-row #column>
          {{ getProvider(row.provider) }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Total">
        <ng-template let-row #column>
          {{ row.total | clearCurrency | swapCommasAndDots }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Centro de costo">
        <ng-template let-row #column>
          {{ row.costCenter == 'SIN CC' || !row.costCenter ? '-' : row.costCenter}}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="">
        <ng-template let-row #column>
          <span class="badge badge-success badge-pill" *ngIf="row.isUsed || row.project">Clasificada</span>
          <span class="badge badge-primary badge-pill" *ngIf="!row.isUsed && !row.project">No clasificada</span>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="">
        <ng-template let-row #column>
          <span class="badge badge-info badge-pill" *ngIf="row.isPaid">Pagada</span>
          <span class="badge badge-primary badge-pill" *ngIf="!row.isPaid">No Pagada</span>
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Mover fecha" [isClickable]="false">
        <ng-template let-row #column>
          <button (click)="changePayday(row.key, row.expectedPaymentDate, 'bill')" class="btn btn-info"><i
                  class="feather ft-corner-up-right"></i></button>
        </ng-template>
      </app-datatable-column>
    </app-datatable>
  </ng-container>
</ng-container>
