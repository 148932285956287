<div class="card mb-0 w-100">
  <div class="card-body p-1">
    <div class="d-flex justify-content-between">
      <span># {{ item.referenceCode }} </span>

      <span ngbTooltip="Margen" class="badge badge-pill badge-info">{{
        (item.salesMarginPercentage | number: '1.0-2') + '%'
      }}</span>
    </div>

    <h5 class="mt-1">{{ item.title }}</h5>

    <div class="mt-1 d-flex align-items-end justify-content-between sale-value">
      <div></div>
      <span> {{ item.saleValue | clearCurrency | swapCommasAndDots }}</span>
    </div>

    <div class="d-flex justify-content-between">
      <ul class="list-unstyled users-list m-0">
        <li class="avatar avatar-sm pull-up" *ngFor="let user of item.users">
          <img
            class="media-object rounded-circle avatar-img"
            container="body"
            ngbTooltip="{{ user?.name }}"
            src="{{
              !!user?.imageUrl ? user.imageUrl : '/assets/images/default.png'
            }}" />
        </li>
      </ul>

      <div class="text-muted d-flex align-items-center">
        <span class="fa fa-calendar"></span>
        <span class="ml-1">{{ item.closeDate | date: 'dd/MM/yyyy' }}</span>
      </div>
    </div>
  </div>

  <div *ngIf="finalInactiveDays > 0" class="card-footer text-center bg-warning">
    <span class="text-small"
      >Expirado hace {{ finalInactiveDays }}
      {{ finalInactiveDays > 1 ? 'días' : 'día' }}</span
    >
  </div>
</div>
