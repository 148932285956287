<div class="p-2">
  <h2>{{update ? 'Actualizar cargo' : 'Nuevo cargo'}}</h2>

  <form [formGroup]="jobForm" (ngSubmit)="submit()">
    <div class="row mt-2">

      <div class="col-md-12 col-12">
        <div class="form-group">
          <label>Cargo *</label>
          <input type="text" class="form-control" formControlName="name"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.name.errors }"/>
          <div *ngIf="submitted && formControls.name.errors">
            El cargo es obligatorio
          </div>
        </div>
      </div>

      <div class="col-md-12 col-12">
        <div class="form-group">
          <label>Cuestionario</label>
          <ng-select formControlName="quiz"
                     [searchable]="true"
                     [items]="selectArrayQuiz"
                     bindLabel="name"
                     appendTo="body">
          </ng-select>
          <div *ngIf="submitted && formControls.quiz.errors">
            El cuestionario es obligatorio
          </div>
        </div>
      </div>

    </div>

    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn">{{update ? 'Actualizar' : 'Agregar'}}</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide()">Cancelar</div>
    </div>
  </form>

</div>
