import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SalesBoardModalComponent } from '../../modals/sales-board-modal/sales-board-modal.component';
import { SalesBoardService } from '../../services/sales-board.service';
import { Observable, Subscription } from 'rxjs';
import { DataTableConfig } from 'src/app/shared/interfaces/data-table-config';
import { UserService } from 'src/app/shared/services/user.service';
import { User } from '../../../shared/interfaces/user';
import { map } from 'rxjs/operators';
import { AlertService } from '../../../shared/template-services/alert.service';
import { UserType } from '../../enums/user-type.enum';
import { SalesBoard } from '../../interfaces/sales-board';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sales-boards',
  templateUrl: './sales-boards.component.html',
  styleUrls: ['./sales-boards.component.css']
})
export class SalesBoardsComponent implements OnInit, OnDestroy {
  salesBoards$: Observable<any>;
  usersSubscription: Subscription = new Subscription();
  users: User[];
  userTypeEnum = UserType;
  dataTableConfig: DataTableConfig = {
    hasSearch: false,
    title: 'Lista de tableros de venta',
    notFoundText: 'No se encontraron tableros de venta'
  };
  userPermission;

  constructor(
    private modal: BsModalService,
    private _salesBoard: SalesBoardService,
    private router: Router,
    private _user: UserService) {
  }

  ngOnInit() {
    this.userPermission = this._user.user.permissions.find(
      (permission) => permission.section == 'VENTAS'
    ).permission;
    this.loadUsers();
  }

  ngOnDestroy() {
    this.usersSubscription.unsubscribe();
  }

  loadUsers() {
    this.usersSubscription = this._user.getUsers().subscribe((users) => {
      this.salesBoards$ = this._salesBoard.getAll().pipe(
        map((salesBoards) =>
          salesBoards
            .filter((salesBoard) => this.validateSalesBoardFilter(salesBoard))
            .map((salesBoard) => ({
              ...salesBoard,
              users: this.getUserDataFormated(salesBoard.users, users),
              supervisors: this.getUserDataFormated(
                salesBoard.supervisors,
                users
              )
            }))
        )
      );
    });
  }

  validateSalesBoardFilter(salesBoard) {
    return (
      this.userPermission == UserType.ADMIN ||
      salesBoard.supervisors.some(
        (supervisor) => supervisor.id == this._user.user.key
      ) ||
      salesBoard.users.some((user) => user.id == this._user.user.key)
    );
  }

  getUserDataFormated(usersSelected, users) {
    return usersSelected.map((supervisor: any) =>
      users.find((data) => data.key == supervisor.id)
    );
  }

  showAddSalesBoardModal() {
    this.modal.show(SalesBoardModalComponent, {
      initialState: {
        userPermission: this.userPermission,
        validateUserPermission: this.validateUserPermission
      }
    });
  }

  showEditBoardModal(salesBoard, event?) {
    if (event) event.preventDefault();

    this.modal.show(SalesBoardModalComponent, {
      initialState: {
        userPermission: this.userPermission,
        salesBoard: { ...salesBoard },
        isEdit: true,
        validateUserPermission: this.validateUserPermission
      }
    });
  }

  validateUserPermission(permissionExpected): boolean {
    return this.userPermission == permissionExpected;
  }

  async deleteBoard({ key: salesBoardKey }, event?) {
    if (event) event.preventDefault();

    if (!(await AlertService.confirm('¿Estás seguro de que deseas eliminar este tablero de ventas?'))) return;

    this._salesBoard.delete(salesBoardKey);
    AlertService.toastSuccess('El tablero se ha eliminado correctamente');
  }

  goToSelectedBoard({ key: salesBoardKey }: SalesBoard) {
    this.router.navigate(['admin/sales-boards-sales-kanban', salesBoardKey]);
  }

  goToUserDetails(userKey: string): void {
    this.router.navigateByUrl(`admin/user-details/${userKey}`);
  }
}
