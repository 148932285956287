import { AccountabilityStatusEnum } from '../pages/travel-advance/enums/accountability-status.enum';

export const AccountabilityStatusClasses = {
  [AccountabilityStatusEnum.GENERATED]: 'badge-primary',
  [AccountabilityStatusEnum.SUPERVISION]: 'badge-warning',
  [AccountabilityStatusEnum.REVIEW]: 'badge-warning',
  [AccountabilityStatusEnum.AUTHORIZED]: 'badge-info',
  [AccountabilityStatusEnum.CLOSED]: 'badge-success',
  [AccountabilityStatusEnum.CANCELLED]: 'badge-danger',
  [AccountabilityStatusEnum.REJECTED]: 'badge-danger',
  [AccountabilityStatusEnum.CLOSED_COLSAN]: 'badge-success',
  [AccountabilityStatusEnum.CLOSED_USER]: 'badge-success',
  [AccountabilityStatusEnum.PAID_COLSAN]: 'badge-success',
  [AccountabilityStatusEnum.PAID_USER]: 'badge-success'
};

export const AccountabilityStatusColor = {
  [AccountabilityStatusEnum.GENERATED]: 'primary',
  [AccountabilityStatusEnum.SUPERVISION]: 'warning',
  [AccountabilityStatusEnum.REVIEW]: 'warning',
  [AccountabilityStatusEnum.AUTHORIZED]: 'info',
  [AccountabilityStatusEnum.CLOSED]: 'success',
  [AccountabilityStatusEnum.CANCELLED]: 'danger',
  [AccountabilityStatusEnum.REJECTED]: 'danger',
  [AccountabilityStatusEnum.CLOSED_COLSAN]: 'success',
  [AccountabilityStatusEnum.CLOSED_USER]: 'success',
  [AccountabilityStatusEnum.PAID_COLSAN]: 'success',
  [AccountabilityStatusEnum.PAID_USER]: 'success'
}
