<div class="p-2">
  <h2>{{isUpdate ? 'Actualizar centro de costo' : 'Nuevo centro de costo'}}</h2>

  <form [formGroup]="costCenterForm" (ngSubmit)="submit()">
    <div class="row mt-2">
      <div class="col-md-6">
        <div class="form-group">
          <label>Nombre *</label>
          <input type="text" class="form-control" formControlName="name"
                 placeholder="" [ngClass]="{'is-invalid': submitted && formControls.name.errors}"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.name.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Estatus *</label>
          <select class="form-control"
                  formControlName="status"
                  [ngClass]="{ 'is-invalid': submitted && formControls.status.errors }">
            <option [value]="type.key" *ngFor="let type of CostCenterType | keyvalue">{{ type.value }}</option>
          </select>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.status.errors">
            <div *ngIf=" formControls.status.errors.required">
              El estatus es obligatorio
            </div>
          </small>
        </div>
      </div>
    </div>
    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn">{{isUpdate ? 'Actualizar' : 'Agregar'}}</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide()">Cancelar</div>
    </div>
  </form>
</div>

