<div class="modal-wrapper">
  <div class="modal-header mobile-modal-header">
    <h1>Curriculum</h1>
    <i (click)="closeModal()" class="la la-close"></i>
  </div>
  <app-applicant-curriculum
    [permission]="this.permission"
    [submitted]="this.submitted"
    [file]="this.file"
    [applicant]="this.applicant"
    [isMobile]="this.isMobile"
    [path]="this.path"
    [uploadMessage]="this.uploadMessage"
    [applicants]="this.applicants"
    [indexApplicant]="this.indexApplicant"
    [applicantKey]="this.applicantKey"
  >
  </app-applicant-curriculum>
</div>
