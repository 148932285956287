import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {Ticket} from '../../admin/pages/warehouses/interfaces/ticket';
import {TicketStatus} from '../../admin/pages/warehouses/enums/ticket-status.enum';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  public tickets: Ticket[];
  tickets$: ReplaySubject<Ticket[]> = new ReplaySubject<Ticket[]>();

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  getAllAuthorized(warehouseReference): Observable<Ticket[]> {
    return this.db.colWithIds$<Ticket>('tickets', ref => ref
      .where('trash', '==', false)
      .where('warehouse', '==', warehouseReference)
      .where('status', 'in', [TicketStatus.AUTHORIZED, TicketStatus.GENERATED, TicketStatus.DRAFT])
      .orderBy('createdDate', 'asc'));
  }

  getAllReturned(warehouseReference): Observable<Ticket[]> {
    return this.db.colWithIds$<Ticket>('tickets', ref => ref
      .where('trash', '==', false)
      .where('warehouse', '==', warehouseReference)
      .where('status', 'in', [TicketStatus.RETURNED])
      .orderBy('createdDate', 'asc'));
  }

  getAll(warehouseReference): Observable<Ticket[]> {
      this.tickets = [];
      this.db.colStateWithIds$<Ticket>('tickets', ref => ref
        .where('trash', '==', false)
        .where('warehouse', '==', warehouseReference)
        .orderBy('ticketID', 'desc'))
        .subscribe(data => {
          data.forEach(ticket => {
            const ticketIndex = this.tickets.findIndex(ticketItem => ticketItem.key == ticket.key)
            if (ticketIndex >= 0) {
              this.tickets[ticketIndex] = ticket;
            } else {
              if (!this.tickets) this.tickets = [];
              this.tickets.push(ticket);
            }
          })
          this.tickets$.next(this.tickets);
        });

    return this.tickets$;
  }

  getAllByProject(projectReference): Observable<Ticket[]> {
    return this.db.colWithIds$<Ticket>('tickets', ref => ref
      .where('trash', '==', false)
      .where('projects', '==', projectReference)
      .orderBy('createdDate', 'asc'));
  }

  getAllNotReturned(warehouseReference): Observable<Ticket[]> {
    return this.db.colWithIds$<Ticket>('tickets', ref => ref
      .where('trash', '==', false)
      .where('warehouse', '==', warehouseReference)
      .where('status', 'in', [TicketStatus.COLLECTED, TicketStatus.INCOMPLETE])
      .orderBy('createdDate', 'asc'));
  }

  async add(ticket: Ticket, ticketID: string): Promise<DocumentReference> {
    return this.afs.collection('tickets').add({
      ...ticket,
      ticketID
    });
  }

  get(ticketKey: string): Observable<Ticket> {
    return this.db.docWithId$(`tickets/${ticketKey}`);
  }

  async getId(): Promise<number> {
    // @ts-ignore
    return (await this.db.doc('settings/setting').valueChanges().pipe(take(1)).toPromise()).ticketID
  }

  updateTicketID(ticketID: number) {
    this.afs.doc('settings/setting').update({ticketID})
  }

  async update(ticketKey: string, article: Ticket): Promise<void> {
    return await this.afs.doc<Ticket>(`tickets/${ticketKey}`).update(article);
  }

  delete(ticketKey: string): Promise<void> {
    return this.afs.doc<Ticket>(`tickets/${ticketKey}`).update({trash: true});
  }

  changeToCollected(ticketKey: string): Promise<void> {
    return this.afs.doc<Ticket>(`tickets/${ticketKey}`).update({status: TicketStatus.COLLECTED});
  }

  changeToAuthorized(ticketKey: string): Promise<void> {
    return this.afs.doc<Ticket>(`tickets/${ticketKey}`).update({status: TicketStatus.AUTHORIZED});
  }

  changeToIncomplete(ticketKey: string): Promise<void> {
    return this.afs.doc<Ticket>(`tickets/${ticketKey}`).update({status: TicketStatus.INCOMPLETE});
  }

  changeToReturned(ticketKey: string): Promise<void> {
    return this.afs.doc<Ticket>(`tickets/${ticketKey}`).update({status: TicketStatus.RETURNED});
  }

  getAllByWarehouses(): Observable<Ticket[]> {
    return this.db.colWithIds$<Ticket>('tickets', ref => ref
      .where('trash', '==', false));
  }
}
