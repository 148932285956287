import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FirebaseDataService} from "../../../shared/template-services/firebase-data.service";
import {PurchaseOrderService} from "../../../shared/services/purchase-order.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {AlertService} from "../../../shared/template-services/alert.service";
import {UserService} from "../../../shared/services/user.service";

@Component({
  selector: 'app-updload-purchase-pdf',
  templateUrl: './updload-purchase-pdf.component.html',
  styleUrls: ['./updload-purchase-pdf.component.css']
})
export class UpdloadPurchasePdfComponent implements OnInit {
  @ViewChild('labelImport', {static: false}) labelImport: ElementRef;
  comment: string = '';
  recipients: any[] = [];
  fileValidation: boolean = true;
  fileToUpload: File = null;
  providerEmail: string = '';
  purchaseID: any;

  constructor(private db: FirebaseDataService,
              private _purchase: PurchaseOrderService,
              private modal: BsModalService,
              private _user: UserService) {
  }

  ngOnInit(): void {
    this.recipients.push(this.providerEmail);
    if (this.providerEmail != this._user.user.email) this.recipients.push(this._user.user.email);
  }

  onFileChange(files: FileList) {
    this.fileValidation = true;
    this.labelImport.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
    this.fileToUpload = files.item(0);
  }

  async submit() {
    if (this.comment !== '' && this.fileValidation) {
      const fileUrl = await this.db.uploadFile(this.fileToUpload, this.db.createID(), 'orden_de_compra.pdf', 'pdf');
      this._purchase.sendPdfToProvider(fileUrl, this.recipients, this.comment, this.purchaseID);
      AlertService.toastSuccess('Orden de compra enviada');
      this.modal.hide(2)
    }
  }

}
