import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../shared/interfaces/user';
import { AfpService } from '../../../shared/services/afp.service';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AccountTypeArray, BankArray, MedicServices, SizeArray, SizeShoesArray } from './types';

@Component({
  selector: 'app-general-data',
  templateUrl: './general-data.component.html',
  styleUrls: ['./general-data.component.css']
})
export class GeneralDataComponent implements OnInit, OnChanges, OnDestroy {
  @Output() eventChangeGeneralDataForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventChangeDriverLicense: EventEmitter<any> = new EventEmitter<any>();
  @Input() user: User;
  generalForm: FormGroup;
  submitted: boolean = false;
  selectArrayAfp: any = [];
  medicServices = MedicServices;
  sizeShoesArray = SizeShoesArray;
  sizeArray = SizeArray;
  accountTypeArray = AccountTypeArray;
  bankArray = BankArray;
  afpSubscription: Subscription = new Subscription();
  generalFormSubscription: Subscription = new Subscription();
  driverLicenseSubscription: Subscription = new Subscription();

  constructor(private formBuilder: FormBuilder,
              private _afp: AfpService) {
    this.generalForm = this.formBuilder.group({
      address: ['', Validators.required],
      afp: ['', Validators.required],
      healthcareSystem: [''],
      driverLicense: ['', Validators.required],
      title: [''],
      bank: ['', Validators.required],
      accountType: ['', Validators.required],
      accountNumber: ['', Validators.required],
      emergencyPerson: ['', Validators.required],
      emergencyNumber: ['', Validators.required],
      numberOfShoes: ['', Validators.required],
      pilotDriverSize: ['', Validators.required],
      shirtSize: ['', Validators.required],
      opticalLenses: ['', Validators.required]
    });
  }

  get formControls() {
    return this.generalForm.controls;
  }

  ngOnInit(): void {
    this.afpSubscription = this._afp.getAll()
      .pipe(
        map(status => status.map(status => ({
            name: status.name,
            key: status.key
          }
        )))
      )
      .subscribe(data => {
        this.selectArrayAfp = data;
      });

    this.generalFormSubscription = this.generalForm.valueChanges.subscribe(() => {
      this.changeApplicantForm();
    });

    this.driverLicenseSubscription = this.generalForm.controls['driverLicense'].valueChanges.subscribe(() => {
      this.eventChangeDriverLicense.emit(this.generalForm.value.driverLicense);
    });
  }

  ngOnChanges(simplesChanges: any) {
    if (simplesChanges.user.currentValue) {
      this.generalForm.patchValue(simplesChanges.user.currentValue);
      this.driverLicenseEmit();
      this.changeApplicantForm();
    }
  }

  ngOnDestroy() {
    this.afpSubscription.unsubscribe();
    this.generalFormSubscription.unsubscribe();
    this.driverLicenseSubscription.unsubscribe();
  }

  driverLicenseEmit() {
    this.eventChangeDriverLicense.emit(this.generalForm.value.driverLicense);
  }

  changeApplicantForm() {
    this.eventChangeGeneralDataForm.emit(this.generalForm.valid ? this.generalForm.value : null);
  }
}
