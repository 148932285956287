import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { JobService } from '../../../shared/services/job.service';
import { map, take } from 'rxjs/operators';
import { User } from '../../../shared/interfaces/user';
import { UserService } from '../../../shared/services/user.service';
import { AlertService } from '../../../shared/template-services/alert.service';
import { States } from '../../../../assets/json/states';
import { UtilsService } from '../../../applicant/services/utils.service';
import { ApplicantService } from '../../../shared/services/applicant.service';
import { Applicant } from '../../interfaces/applicant';
import moment from 'moment';
import { CountryListService } from '../../../shared/services/country-list.service';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.css']
})
export class UpdateUserComponent implements OnInit {
  userForm: FormGroup;
  formSubmitted: boolean = false;
  educationLevelArray = [
    'Enseñanza Media Incompleta',
    'Enseñanza Media Completa',
    'Enseñanza Técnica Completa',
    'Enseñanza Técnica Incompleta',
    'Enseñanza Universitaria Completa',
    'Enseñanza Universitaria Incompleta'
  ];
  selectArrayJobs: any = [];
  user: User;
  wasEdited: boolean = false;
  states: any[] = States;
  cities: any[] = [];
  countries: string[] = CountryListService.countries;
  applicant: Applicant;

  constructor(private formBuilder: FormBuilder,
              private _job: JobService,
              public modal: BsModalRef,
              private utilsService: UtilsService,
              private _applicant: ApplicantService,
              private _user: UserService) {
    this.userForm = formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      rut: ['', [Validators.required, Validators.pattern('^(\\d{1,3}(?:\\.\\d{1,3}){2}-[\\dkK])$')]],
      position: ['', Validators.required],
      phone: ['', Validators.required],
      region: ['', Validators.required],
      city: ['', Validators.required],
      age: [{
        value: '',
        disabled: true
      }, Validators.required],
      birthday: ['', Validators.required],
      workExperience: ['', Validators.required],
      educationLevel: ['', Validators.required],
      nationality: ['', Validators.required]
    });
  }

  async ngOnInit(): Promise<void> {
    await this.getJobs();

    if(this.user.rut) this.applicant = (await this._applicant.getByRut(this.user.rut).pipe(take(1)).toPromise())[0];

    this.stateChange(this.user?.region || this.applicant?.region);

    this.user = {
      ...this.user,
      position: this.selectArrayJobs.find(job => job.key === (this.user?.position?.reference as any)?.id),
      region: this.states.find(state => state.id === this.user?.region?.id || state.id == this.applicant?.region?.id),
      city: this.cities.find(city => city.id === this.user?.city?.id || city.id == this.applicant?.city?.id),
      birthday: moment(this.user.birthday).format('YYYY-MM-DD'),
      age: moment().diff(this.user.birthday, 'years'),
      phone: this.user?.phone || this.applicant?.phone,
    };

    this.userForm.patchValue(this.user);

    this.userForm.controls['region'].valueChanges.subscribe(value => {
      this.stateChange(value);
    });
  }

  get formControls() {
    return this.userForm.controls;
  }

  async getJobs() {
    this.selectArrayJobs = await this._job.getAll()
      .pipe(
        map(categories => categories.map(provider => ({
            name: provider.name,
            key: provider.key
          }
        ))),
        take(1)
      )
      .toPromise();
  }

  submit() {
    this.formSubmitted = true;

    if (this.userForm.valid) {
      this._user.update(this.user.key, this.userForm.value);
      this.wasEdited = true;

      AlertService.toastSuccess('Actualizado con exitosamente');
      this.modal.hide();
    }
  }

  stateChange(event) {
    this.cities = this.utilsService.citylist.filter(city => city.state_id == event || city.state_id == event?.id);
  }
}
