<app-datatable [rows$]="persons$"
               [config]="config">

  <app-datatable-column title="Nombre">
    <ng-template let-row #column>
      {{row?.name}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="email">
    <ng-template let-row #column>
      {{row.email}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Imagen" [isClickable]="false">
    <ng-template let-row #column>
      <img (click)="openModalImage(row?.imageUrl)" src="{{row?.imageUrl}}" class="imageSize">
    </ng-template>
  </app-datatable-column>

  <ng-template #title></ng-template>

  <app-datatable-column title="Teléfono">
    <ng-template let-row #column>
      {{row.phone}}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="" [isClickable]="false" [isVisiblePrint]="false">
    <ng-template let-row #column>
      <button class="btn btn-danger btn-sm" (click)="dropPerson(row)">Eliminar</button>
    </ng-template>
  </app-datatable-column>

</app-datatable>
