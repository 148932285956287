import {Component, Input, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap/modal';
import {EmployeePaymentModalComponent} from '../../modals/employee-payment-modal/employee-payment-modal.component';
import {PaymentCategoryModalComponent} from '../../modals/payment-category-modal/payment-category-modal.component';
import {CostCenterPaymentsModalComponent} from '../../modals/cost-center-payments-modal/cost-center-payments-modal.component';
import {AddPersonComponent} from '../../modals/add-person/add-person.component';
import {Article} from '../warehouses/interfaces/article';
import {ArticleService} from '../../../shared/services/article.service';
import {UserType} from '../../enums/user-type.enum';
import {LogsService} from '../../services/logs.service';
import {Project} from '../../interfaces/project';
import {ProjectService} from '../../../shared/services/project.service';
import {UpdateProjectComponent} from '../../modals/update-project/update-project.component';
import {UserService} from '../../../shared/services/user.service';

@Component({
  selector: 'app-cost-center-details',
  templateUrl: './cost-center-details.component.html',
  styleUrls: ['./cost-center-details.component.scss'],
})
export class CostCenterDetailsComponent implements OnInit {
  @Input() costCenter: Project;
  costCenterKey: string;
  costCenter$: Observable<Project>;
  tabIndex: number = 0;
  articleSubscription: Subscription = new Subscription();
  articleReferences: Article[];
  permission: UserType;
  permissionEnum = UserType;
  logs$: Observable<any>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private modal: BsModalService,
    private _article: ArticleService,
    private _log: LogsService,
    private _project: ProjectService,
    private _user: UserService
  ) {}

  ngOnInit(): void {
    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'PROYECTOS'
    ).permission;
    this.costCenterKey = this.activatedRoute.snapshot.params['costCenterKey'];
    this.costCenter$ = this._project.get(this.costCenterKey);
    this.logs$ = this._log.getAllBudgets(this.costCenterKey);
    this.loadArticles();
  }

  deleteCostCenter(costCenter) {}

  loadArticles() {
    this.articleSubscription = this._article.getAll().subscribe((data) => {
      this.articleReferences = data;
    });
  }

  modifyCostCenter(costCenter) {
    this.modal.show(UpdateProjectComponent, {
      initialState: {
        projectKey: costCenter.key,
        project: costCenter,
      },
    });
  }

  openEditEmployeePaymentModal() {
    this.modal.show(EmployeePaymentModalComponent, {
      initialState: {
        costCenterKey: this.costCenterKey,
      },
    });
  }

  openEditPaymentCategoryModal() {
    this.modal.show(PaymentCategoryModalComponent, {
      initialState: {
        costCenterKey: this.costCenterKey,
      },
    });
  }

  openEditPaymentModal() {
    this.modal.show(CostCenterPaymentsModalComponent, {
      initialState: {
        costCenterKey: this.costCenterKey,
      },
    });
  }

  openModalAddPerson() {
    this.modal.show(AddPersonComponent, {
      initialState: {
        projectKey: this.costCenterKey,
      },
    });
  }
}
