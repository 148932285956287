import { Component, OnInit } from '@angular/core';
import { FirebaseDataService } from '../../../../../shared/template-services/firebase-data.service';
import { Applicant } from '../../../../interfaces/applicant';
import { ApplicantService } from '../../../../../shared/services/applicant.service';
import _ from 'lodash';
import { JobService } from '../../../../../shared/services/job.service';
import { combineLatest } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PositionSalaryGraphComponent } from '../position-salary-graph/position-salary-graph.component';
import { first } from 'rxjs/operators';
import { SettingService } from '../../../../services/setting.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AlertService } from '../../../../../shared/template-services/alert.service';

@Component({
  selector: 'app-salary-range',
  templateUrl: './salary-range.component.html',
  styleUrls: ['./salary-range.component.scss']
})
export class SalaryRangeComponent implements OnInit {
  applicantsByPosition = {};
  salaryByPosition = {};
  positions = [];
  lowerLimit: number = 0;
  applicants: Applicant[] = [];

  constructor(private _applicant: ApplicantService,
    private db: FirebaseDataService,
    private _position: JobService,
    private modal: BsModalService,
    private settings: SettingService,
    private loader: LoadingBarService) {
  }

  ngOnInit(): void {
    this.getSalaryByPosition();
  }

  getSalaryByPosition() {
    combineLatest([this._applicant.getAll(), this._position.getAll(), this.settings.getApplicants()]).subscribe(async ([applicants, positions, applicantsSettings]) => {
      const applicantsWithPosition = await this.db.populateSubObjectField(_.cloneDeep(applicants), 'position', 'reference');
      const rawSalaryByPosition = {};

      if (!applicantsSettings) {
        await this.settings.createApplicantsSettings();
      }

      this.lowerLimit = applicantsSettings?.salaryRangeLowerLimit || 0;


      positions.forEach(position => {
        if (position.position > 0)
          rawSalaryByPosition[position.position] = [];
      });

      applicantsWithPosition.forEach(applicant => {
        if (applicant.position?.reference?.position) {
          if (!!applicant.expectation && applicant.position.reference.position > 0) {
            if (this.normalizeCurrency(applicant.expectation) >= this.lowerLimit) rawSalaryByPosition[applicant.position.reference.position]?.push(this.normalizeCurrency(applicant.expectation));
          }
        }
      });

      this.applicantsByPosition = { ...rawSalaryByPosition };

      Object.keys(rawSalaryByPosition).forEach(key => {
        const hasData = rawSalaryByPosition[key].length && rawSalaryByPosition[key].every(value => value > 0);

        rawSalaryByPosition[key] = {
          min: hasData ? _.min([...rawSalaryByPosition[key]]) : '-',
          max: hasData ? _.max([...rawSalaryByPosition[key]]) : '-',
          avg: hasData ? _.mean([...rawSalaryByPosition[key]]) : '-'
        };
      });

      this.salaryByPosition = rawSalaryByPosition;
      this.positions = positions;
    });
  }

  normalizeCurrency(number: string | number) {
    return Number(number.toString().replace(/[.$,]/g, ''));
  }

  async togglePositionSalaryModal(position: number) {
    const modalRef = this.modal.show(PositionSalaryGraphComponent, {
      initialState: {
        applicantsByPosition: this.applicantsByPosition,
        selectedOption: position
      },
      class: 'modal-xl shadow-lg'
    });

    await modalRef.onHide.pipe(first()).toPromise();
  }

  async handleNewLimit() {
    this.loader.start();
    await this.settings.updateApplicantsSettings({ salaryRangeLowerLimit: this.lowerLimit });

    this.loader.stop();
    AlertService.toastSuccess('Límite actualizado');
  };
}
