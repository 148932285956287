<div class="row mb-1">
  <h2 class="ml-2">Fecha desde:</h2>
  <input class="form-control ml-1" type="date" style="width: 200px; margin-top: -4px;"
         [(ngModel)]="startDate">

  <h2 class="ml-1">a</h2>

  <input class="form-control ml-1" type="date" style="width: 200px; margin-top: -4px;"
         [(ngModel)]="finalDate">

  <button class="btn btn-danger ml-1" style="margin-top: -4px;" (click)="filterTasks()">Buscar</button>
  <button class="btn btn-warning ml-1" style="margin-top: -4px;" (click)="resetTasks()">Mostrar todos</button>
</div>

<app-datatable [rows$]="tasksFiltered$" [config]="config" (rowSelected)="openTask($event)">

  <app-datatable-column title="Título" field="title">
    <ng-template let-row #column>
      <h4>{{ row.title }}</h4>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Fecha real de finalización" field="finishDate">
    <ng-template let-row #column>
      {{ row.finishDate | date: 'dd/MM/yyyy hh:mm a' }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Usuarios" field="members" [isVisiblePrint]="false">
    <ng-template let-row #column>
      <div *ngIf="row?.members.length > 0; else userDefault">
        <ul class="list-unstyled users-list m-0">
          <li class="avatar avatar-sm pull-up" *ngFor="let member of row?.members">
            <img class="media-object rounded-circle avatar-img"
                 container="body"
                 ngbTooltip="{{member?.name}}"
                 src="{{!!member?.imageUrl ? member?.imageUrl : '/assets/images/default.png'}}" alt="Usuario">
          </li>
        </ul>
      </div>
    </ng-template>
  </app-datatable-column>

</app-datatable>

<ng-template #userDefault>
  <ul class="list-unstyled users-list m-0">
    <li class="avatar avatar-sm pull-up">
      <img class="media-object rounded-circle avatar-img"
           container="body"
           ngbTooltip="Sin asignar"
           src="/assets/images/default.png" alt="Sin asignar">
    </li>
  </ul>
</ng-template>

<!--<app-add-float-button *ngIf="permission == userTypeEnum.ADMIN" (clickEmmiter)="showAddBoardModal()"></app-add-float-button>-->
