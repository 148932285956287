export const States = [
  { 'name': 'De Tarapacá', id: '01', previousId: 2837 },
  { 'name': 'De Antofagasta', id: '02', previousId: 2832 },
  { 'name': 'De Atacama', id: '03', previousId: 2823 },
  { 'name': 'De Coquimbo', id: '04', previousId: 2825 },
  { 'name': 'De Valparaíso', id: '05', previousId: 2830 },
  { 'name': 'Del Libertador B. O\'Higgins', id: '06', previousId: 2838 },
  { 'name': 'Del Maule', id: '07', previousId: 2833 },
  { 'name': 'Del Bíobío', id: '08', previousId: 2827 },
  { 'name': 'De La Araucanía', id: '09', previousId: 2826 },
  { 'name': 'De Los Lagos', id: '10', previousId: 2835 },
  { 'name': 'De Aisén del Gral. C. Ibáñez del Campo', id: '11', previousId: 2828 },
  { 'name': 'De Magallanes y de La Antártica Chilena', id: '12', previousId: 2836 },
  { 'name': 'Metropolitana de Santiago', id: '13', previousId: 2824 },
  { 'name': 'De Los Ríos', id: '14', previousId: 2834 },
  { 'name': 'De Arica y Parinacota', id: '15', previousId: 2829 },
  { 'name': 'De Ñuble', id: '16', previousId: 2831 },
];
