<h4 class="text-center my-2">{{title}}</h4>
<div class="icon-group">
  <div
    (click)="handleIconSelection(icon)"
    class="icon"
    *ngFor="let icon of icons "
    [ngClass]="{'icon--active':selectedIcon==icon}"
  >
    <i [class]="'fa fa-'+icon"></i>
  </div>
</div>
