import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApplicantService} from '../../../shared/services/applicant.service';
import {Applicant} from '../../../admin/interfaces/applicant';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidationService} from '../../../shared/template-services/validation.service';
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-update-cv',
  templateUrl: './update-cv.component.html',
  styleUrls: ['./update-cv.component.css']
})
export class UpdateCvComponent implements OnInit {
  applicantKey: string;
  curriculumForm: FormGroup;
  path: any;
  @ViewChild('labelImport', {static: true}) labelImport: ElementRef;
  curriculumToUpload: File = null;
  curriculumFile: any;
  submitted: boolean = false;
  curriculumValidation: boolean = true;
  applicant: Applicant;
  complete: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
              private formBuilder: FormBuilder,
              private _applicant: ApplicantService,
              private SpinnerService: NgxSpinnerService) {
    this.curriculumForm = formBuilder.group({
      curriculum: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.applicantKey = this.activatedRoute.snapshot.params['applicantKey'];
  }

  get formControls() {
    return this.curriculumForm.controls;
  }

  isValidPath(): boolean {
    return !(this.path == '' || this.path == null);
  }

  chooseCurriculum(event) {
    if (!ValidationService.validateFileSize(event, 10000)) return AlertService.toastError('El archivo debe ser menor a 10MB')

    this.curriculumFile = event.target.files[0];
    if (event.target.files && this.curriculumFile) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.path = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(this.curriculumFile);
      this.curriculumForm.patchValue({curriculum: this.curriculumFile});
    }
  }

  async updateCV() {
    this.submitted = true;
    if (this.curriculumForm.valid) {
      this.SpinnerService.show();
      this.applicant = await this._applicant.getSpecificApplicant(this.applicantKey);
      this.applicant.curriculum = await this._applicant.uploadCurriculum(this.curriculumFile, this.applicantKey);
      await this._applicant.updateQuiz(this.applicantKey, this.applicant);
      this.complete = true;
      this.SpinnerService.hide();
    }
  }
}
