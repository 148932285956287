<div class="d-flex">
  <div
    class="d-flex align-items-center">
    <div class="d-flex flex-column ml-2" style="padding-top: 28px">
      <mat-form-field class="example-full-width" appearance="standard">
        <mat-label>Seleccionar mes:</mat-label>
        <input matInput
               type="month"
               style="width: 200px; margin-top: -4px"
               [(ngModel)]="monthSelected"
               (ngModelChange)="setDateRange()">
      </mat-form-field>
    </div>
  </div>
  <div class="ml-2" style="padding-top: 42px">
    <mat-form-field>
      <mat-label>Validez</mat-label>
      <mat-select
        [(ngModel)]="validitySelected"
        (ngModelChange)="filterTasks()">
        <mat-option [value]="expirationFilterEnum.ALL"> TODAS</mat-option>
        <mat-option [value]="expirationFilterEnum.EXPIRED">
          Vencidas
        </mat-option>
        <mat-option [value]="expirationFilterEnum.NOT_EXPIRED">
          Vigentes
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="ml-2" style="padding-top: 30px; min-width: 200px">
    <ng-select
      appearance="material"
      [(ngModel)]="project"
      (ngModelChange)="filterTasks()"
      [items]="selectArray"
      [searchable]="true"
      bindLabel="name"
      appendTo="body"
      placeholder="Selecciona un proyecto"
      groupBy="type">
      <ng-template ng-option-tmp let-item="item">
        {{ item.name }}
      </ng-template>
    </ng-select>
  </div>

  <div class="ml-2 d-flex flex-column justify-content-end">
    <h5>Modo {{ listMode ? 'lista' : 'tablero' }}</h5>
    <ui-switch [(ngModel)]="listMode">
      <i
        class="la"
        [ngClass]="{'la-list': listMode, 'la-table': !listMode}"></i>
    </ui-switch>
  </div>
</div>

<div *ngIf="listMode" class="animated fadeIn faster">
  <app-kanban-tasks-list
    [tasks$]="filteredTasks$"
    (taskClicked)="openTask($event)"></app-kanban-tasks-list>
</div>

<div
  cdkDropListGroup
  class="d-flex mt-2 animated fadeIn faster"
  [style.zoom]="zoom"
  *ngIf="!listMode">
  <div class="example-container">
    <div class="flex-row d-flex justify-content-between mb-1">
      <h3>POR HACER</h3>
      <h5 class="mr-1 place-items-center">Tareas: {{ todo.length }}</h5>
    </div>

    <div
      cdkDropList
      [id]="status.TODO"
      [cdkDropListData]="todo"
      class="example-list"
      (cdkDropListDropped)="drop($event)">
      <div
        class="example-box"
        *ngFor="let item of todo"
        cdkDrag
        [cdkDragDisabled]="!item.isAvailable">
        <app-kanban-item
          [item]="item"
          [boardKey]="item.boardKey"
          [userPermission]="userPermission"
          [tasks]="tasks"
          (taskClicked)="openTask($event)"></app-kanban-item>
      </div>
    </div>
  </div>

  <div class="example-container">
    <div class="flex-row d-flex justify-content-between mb-1">
      <h3>EN PROCESO</h3>
      <h5 class="mr-1 place-items-center">Tareas: {{ inProgress.length }}</h5>
    </div>

    <div
      cdkDropList
      [id]="status.IN_PROGRESS"
      [cdkDropListData]="inProgress"
      class="example-list"
      (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let item of inProgress" cdkDrag>
        <app-kanban-item
          [item]="item"
          [boardKey]="item.boardKey"
          [userPermission]="userPermission"
          [tasks]="tasks"
          (taskClicked)="openTask($event)"></app-kanban-item>
      </div>
    </div>
  </div>

  <div class="example-container">
    <div class="flex-row d-flex justify-content-between mb-1">
      <h3>HECHO</h3>
      <h5 class="mr-1 place-items-center">Tareas: {{ done.length }}</h5>
    </div>

    <div
      cdkDropList
      [id]="status.DONE"
      [cdkDropListData]="done"
      class="example-list"
      (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let item of done" cdkDrag>
        <app-kanban-item
          [item]="item"
          [boardKey]="item.boardKey"
          [userPermission]="userPermission"
          [tasks]="tasks"
          (taskClicked)="openTask($event)"></app-kanban-item>
      </div>
    </div>
  </div>
</div>

<button
  class="btn btn-success add-zoom"
  app-tooltip="Añadir zoom"
  (click)="addZoom()">
  <i class="la la-plus"></i>
</button>

<button
  class="btn btn-warning substract-zoom"
  app-tooltip="Restar zoom"
  (click)="substractZoom()">
  <i class="la la-minus"></i>
</button>

<button
  class="btn btn-warning btn-report"
  app-tooltip="Reporte"
  (click)="handleReports()">
  <i class="la la-bar-chart"></i>
</button>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  fullscreen="true"
  size="medium"
  color="#fff"
  type="square-jelly-box">
  <p style="color: #ecf0f1">Cargando...</p>
</ngx-spinner>
