import { Injectable } from '@angular/core';
import { FirebaseDataService } from '../../../shared/template-services/firebase-data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Task } from './interfaces/task';
import { Tag } from './interfaces/tag';
import { debounceTime, first, take } from 'rxjs/operators';
import { UserService } from '../../../shared/services/user.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KanbanService {
  pendingTasks: Task[] = [];

  constructor(
    private db: FirebaseDataService,
    private afs: AngularFirestore,
    private _user: UserService
  ) {
  }

  getAllTags() {
    return this.db.colWithIds$<Tag>('tags', (ref) =>
      ref.where('trash', '==', false)
    );
  }

  getAllTasks(boardKey: string) {
    return this.db.colWithIds$<Task>(`boards/${boardKey}/tasks`, (ref) =>
      ref.where('trash', '==', false)
    );
  }

  getRecurrentTasks(boardKey: string) {
    return this.db.colWithIds$<Task>(`boards/${boardKey}/tasks`, (ref) => ref
      .where('trash', '==', false)
      .where('isRecurrent', '==', true)
    );
  }

  addTask(task: Task, boardKey: string) {
    return this.afs.collection(`boards/${boardKey}/tasks`).add(task);
  }

  updateTask(task: Task, taskKey: string, boardKey: string) {
    delete task['key'];
    return this.db.update(`boards/${boardKey}/tasks/${taskKey}`, task);
  }

  deleteTask(taskKey: string, boardKey: string) {
    return this.afs
      .doc(`boards/${boardKey}/tasks/${taskKey}`)
      .update({ trash: true });
  }

  getAllComments(taskKey: string, boardKey: string) {
    return this.db.colWithIds$(
      `boards/${boardKey}/tasks/${taskKey}/comments`,
      (ref) => ref.orderBy('createdAt', 'asc')
    );
  }

  addComment(taskKey, comment, boardKey: string) {
    return this.afs
      .collection(`boards/${boardKey}/tasks/${taskKey}/comments`)
      .add(comment);
  }

  addLink(taskKey, url, boardKey: string) {
    return this.afs
      .collection(`boards/${boardKey}/tasks/${taskKey}/urls`)
      .add(url);
  }

  get(boardKey: string, taskKey: string): any {
    return this.db
      .docWithId$(`boards/${boardKey}/tasks/${taskKey}`)
      .pipe(take(1))
      .toPromise();
  }

  getSettings() {
    return this.db.docWithId$(`settings/kanban`).pipe(take(1)).toPromise();
  }

  setSettings(setting) {
    return this.db.doc(`settings/kanban`).update(setting);
  }

  getLogs(boardKey: string, taskKey: string) {
    return this.db.colWithIds$(
      `boards/${boardKey}/tasks/${taskKey}/logs`,
      (ref) => ref.orderBy('createdAt', 'desc')
    );
  }

  setLog(boardKey: string, taskKey: string, log) {
    return this.afs
      .collection(`boards/${boardKey}/tasks/${taskKey}/logs`)
      .add(log);
  }

  getAllMyTasks(userKey?: string): Observable<any> {
    return this.db
      .colGroupWithIds$(`tasks`, ref => ref
        .where('trash', '==', false)
        .where('members', 'array-contains', this.db.getReference(`users/${userKey || this._user.user.key}`))
      );
  }

  async getAllGroupTasks(userKey?: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.db
        .colGroupWithIds$(`tasks`, ref => ref
          .where('trash', '==', false)
          .where('members', 'array-contains', this.db.getReference(`users/${userKey}`))
        )
        .pipe(
          debounceTime(3000),
          first()
        )
        .subscribe((tasks) => {
          resolve(tasks);
        });
    });
  }
}
