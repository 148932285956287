<script src="utils/icon-list.ts"></script>
<div>
  <div class="p-1 d-flex align-items-center justify-content-between">
    <h2>Agregar nuevo atajo</h2>
    <div class="close-button">
      <i class="fa fa-times" (click)="handleCloseModal()"></i>
    </div>
  </div>
  <div class="card-body">
    <div class="text-center">
      <p class="mb-0">Atajo hasta la ruta:</p>
      <p class="text-info">{{currentUrl}}</p>
    </div>
    <span class=" mt-2">Seleccionar icono</span>
    <div class="icons-container">
      <app-icon-group
        [title]="'Vehículos'"
        [icons]="iconList.transportation"
        (handleSelectedIcon)="handleIconSelection($event)"
        [selectedIcon]="selectedIcon"
      ></app-icon-group>
      <app-icon-group
        [title]="'Usuarios'"
        [icons]="iconList.users"
        (handleSelectedIcon)="handleIconSelection($event)"
        [selectedIcon]="selectedIcon"
      ></app-icon-group>
      <app-icon-group
        [title]="'Gráficas'"
        [icons]="iconList.charts"
        (handleSelectedIcon)="handleIconSelection($event)"
        [selectedIcon]="selectedIcon"
      ></app-icon-group>
      <app-icon-group
        [title]="'Archivos'"
        [icons]="iconList.files"
        (handleSelectedIcon)="handleIconSelection($event)"
        [selectedIcon]="selectedIcon"
      ></app-icon-group>
      <app-icon-group
        [title]="'Otros'"
        [icons]="iconList.others"
        (handleSelectedIcon)="handleIconSelection($event)"
        [selectedIcon]="selectedIcon"
      ></app-icon-group>
    </div>
    <span class="">Mensaje de la etiqueta</span>
    <div class="icon-label-input">
      <input class="form-control p-sm" type="text" [(ngModel)]="shortcutLabel"/>
    </div>
  </div>
  <div class="card-footer d-flex justify-content-end">
    <button class="btn btn-primary ml-auto" (click)="saveShortcut()">Guardar</button>
  </div>
</div>
