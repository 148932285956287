import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { NotificationType } from '../../enums/notification-type.enum';
import { UserNotification } from '../../interfaces/userNotification';
import { NotificationTypeLabel } from '../../labels/notification-type.label';
import { NotificationService } from '../../services/notification.service';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import { map, take } from 'rxjs/operators';
import { User } from 'src/app/shared/interfaces/user';
import { UserService } from 'src/app/shared/services/user.service';
import { AlertService } from 'src/app/shared/template-services/alert.service';
import {formatDate} from '@angular/common';
import { MatTabChangeEvent } from '@angular/material/tabs';
import moment from 'moment';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})

export class NotificationsComponent implements OnInit {
  @Input() user: User;
  @Input() userKey: string;
  notificationKey: string;
  startDate = null;
  finalDate = null;
  tabName;
  filteredUnreadNotifications$: Observable<UserNotification[]>
  filteredReadNotifications$: Observable<UserNotification[]>
  unreadedNotifications$: Observable<any[]>
  unreadedNotifications: UserNotification[];
  readedNotifications$: Observable<any[]>
  notificationTypeLabel = NotificationTypeLabel;  
  notificationTypeEnum = NotificationType;
  notificationsConfig: DataTableConfig = {
    title: 'Notificaciones',
    notFoundText: 'No se encontraron notificaciones',
    hasSearch: true
  }; 

  constructor(private _notification: NotificationService,
              private _user: UserService) {
  }

  async ngOnInit() {
    const date = new Date(`${new Date().getMonth() + 1}/01/${new Date().getFullYear()}`);
    this.startDate = this.formatDate(date);
    this.finalDate = this.formatDate(moment(date).add(1, 'month').toDate());  

    this.unreadedNotifications = (await this._notification.getAll().pipe(take(1)).toPromise())
    .filter(unreadedNotifications => !unreadedNotifications.readed);

    this.assignReaded();
    this.unreadedNotifications$ = this._notification.getAll().pipe(map
      (unreadedNotifications => unreadedNotifications.filter(unreadedNotification => unreadedNotification.readed == false)));

    this.readedNotifications$ = this._notification.getAll().pipe(map
      (readedNotifications => readedNotifications.filter(readedNotification => readedNotification.readed == true)));  

    for (let notification of this.unreadedNotifications) {
      this._notification.update(this._user.user.key, {
        redirectUrl: notification.redirectUrl,
        description: notification.description,
        createdDate: notification.createdDate,
        trash: notification.trash,
        readed: false,
        type: notification.type,
        key: notification.key
      }, notification.key);
    }
    this.filterDate();
  }

  private formatDate(date) {
    const deliveryDateDate = new Date(date);
    const format = 'yyyy/MM/dd';
    const locale = 'en-US';
    const zone = 'UTC';
    const formattedDate = formatDate(deliveryDateDate, format, locale, zone);
    return formattedDate.replace(/\//g, '-');
  }

  filterDate () {
    let startDate;
    let startDateMilliseconds;
    let finalDate;
    let finalDateMilliseconds;

    if (this.startDate) {
      startDate = this.startDate.replaceAll('-', '/');
      startDateMilliseconds = new Date(startDate).getTime();
    } else {
      startDateMilliseconds = 0;
    }

    if (this.finalDate) {
      finalDate = this.finalDate.replaceAll('-', '/');
      finalDateMilliseconds = new Date(finalDate).getTime();
    } else {
      finalDateMilliseconds = Infinity;
    }
      this.filteredUnreadNotifications$ = this.unreadedNotifications$.pipe(map(
        unreadedNotifications => unreadedNotifications.filter(
          notification => notification.createdDate >= startDateMilliseconds && 
          notification.createdDate <= finalDateMilliseconds)));  
          
       if(this.tabName = 'Leídas') {
        this.filteredReadNotifications$ = this.readedNotifications$.pipe(map(
          readedNotifications => readedNotifications.filter(
            notification => notification.createdDate >= startDateMilliseconds && 
            notification.createdDate <= finalDateMilliseconds)));  
       }
  }

  assignReaded() {
    this.unreadedNotifications$ = this._notification.getAll()
        .pipe(map(unreadedNotifications => unreadedNotifications.map(unreadedNotification => ({        
          readed : 'readed' in unreadedNotification ? unreadedNotification.readed : false,
          createdDate : unreadedNotification.createdDate,
          redirectUrl : unreadedNotification.redirectUrl,
          trash : unreadedNotification.trash,
          type: unreadedNotification.type,
          description : unreadedNotification.description,
          key: unreadedNotification.key,
        })
      ))
    );
  }

  onChange(event: MatTabChangeEvent) { 
    if(event.tab.textLabel == 'Leídas') {
      this.tabName = event.tab.textLabel;
    }      
  }

  async markAsReaded(notification: UserNotification) {  
    if (await AlertService.confirm('¿Estás seguro que marcar esta notificación como leída?')) {
      this._notification.update(this._user.user.key, {readed:true} as UserNotification, notification.key )
      AlertService.toastSuccess('Se marcó como leída!');
    }
  }
}
