import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-filter-applicants',
  templateUrl: './filter-applicants.component.html',
  styleUrls: ['./filter-applicants.component.css']
})
export class FilterApplicantsComponent implements OnInit {
  filterForm: FormGroup;
  selectArrayStatus: any;
  selectArrayJob: any;
  cities: any;
  @Output() eventFilterApplicants: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalService) {
    this.filterForm = formBuilder.group({
      status: [''],
      job:[''],
      city: ['']
    });
  }

  ngOnInit(): void {
  }

  async submit() {
    let filter = {
      status: this.filterForm.value.status.name ? this.filterForm.value.status.name : '',
      job: this.filterForm.value.job.name ? this.filterForm.value.job.name : '',
      city: this.filterForm.value.city.name ? this.filterForm.value.city.name : ''
    }
    await this.eventFilterApplicants.emit(filter);
    this.modal.hide();
  }
}

