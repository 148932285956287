import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SalesBoardService } from '../../services/sales-board.service';
import { User } from 'src/app/shared/interfaces/user';
import { SalesBoard } from '../../interfaces/sales-board';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { UserType } from '../../enums/user-type.enum';
import { AlertService } from 'src/app/shared/template-services/alert.service';
import { bgColors } from '../../pages/warehouses/data/bg-colors';
import { simpleLineIcons } from '../../pages/warehouses/data/simple-line';

@Component({
  selector: 'app-sales-board-modal',
  templateUrl: './sales-board-modal.component.html',
  styleUrls: ['./sales-board-modal.component.css']
})
export class SalesBoardModalComponent implements OnInit, OnDestroy {
  salesBoardForm: FormGroup;
  salesBoard: SalesBoard = { key: '' } as SalesBoard;
  userPermission;
  usersSubscription: Subscription = new Subscription();
  users: User[];
  supervisors: User[];
  isEdit: boolean;
  submitted: boolean;
  userTypeEnum = UserType;
  validateUserPermission: (permissionExpected) => boolean;
  icons: string[] = [...simpleLineIcons];
  bgColors: string[] = [...bgColors];

  constructor(public modal: BsModalRef,
              private formBuilder: FormBuilder,
              private _salesBoard: SalesBoardService,
              private _user: UserService) {
    this.createSalesBoardForm();
  }

  ngOnInit() {
    this.usersSubscription = this._user.getUsers().subscribe(users => {
      this.users = this.getFilteredUsers(users, UserType.USER);
      this.supervisors = this.getFilteredUsers(users, UserType.SUPERVISOR);
    });

    if (this.isEdit) {
      this.salesBoardForm.patchValue(this.salesBoard);
      if (this.validateUserPermission(UserType.USER)) {
        this.salesBoardForm.get('name').disable();
      }
    }
  }

  ngOnDestroy() {
    this.usersSubscription.unsubscribe();
  }

  get formControls() {
    return this.salesBoardForm.controls;
  }

  createSalesBoardForm() {
    this.salesBoardForm = this.formBuilder.group({
      name: ['', Validators.required],
      users: [[], Validators.required],
      supervisors: [[], Validators.required],
      trash: [false],
      icon: [''],
      bgColor: ['']
    });
  }

  getFilteredUsers(users: User[], userType: UserType) {
    return users.filter(user => {
      if (!user.permissions) return false;
      let section = user.permissions.find(permission => permission.section == 'VENTAS');
      return (
        !!section
        && section.permission == userType
      );
    });
  }

  async submit() {
    this.submitted = true;
    if (!this.salesBoardForm.valid) return;
    if (!await AlertService.confirm('¿Estás seguro de que deseas guardar estos datos?')) return;

    this.salesBoardForm.patchValue({
      users: this.getUsersReference(this.salesBoardForm.value.users),
      supervisors: this.getUsersReference(this.salesBoardForm.value.supervisors)
    });

    if (this.isEdit) {
      this._salesBoard.updateSalesBoard(this.salesBoard.key, this.salesBoardForm.value);
    } else {
      await this._salesBoard.add({
        ...this.salesBoardForm.value,
        createdAt: new Date().getTime()
      });
    }
    AlertService.toastSuccess('Los datos se guardaron correctamente');
    this.modal.hide();
  }

  compareUsers(user, currentUser) {
    return user.key == currentUser.key;
  }

  getUsersReference(users: User[]) {
    return users.map(user => (this._salesBoard.getReference(`users/${user.key}`)));
  }
}
