export const IconList = {
  transportation: [
    'ambulance',
    'car',
    'bicycle',
    'bus',
    'taxi',
    'car',
    'fighter-jet',
    'motorcycle',
    'plane',
    'rocket',
    'ship',
    'space-shuttle',
    'subway',
    'taxi',
    'train',
    'truck',
    'wheelchair',
    'wheelchair-alt'
  ],
  charts: [
    'area-chart',
    'bar-chart',
    'line-chart',
    'pie-chart'
  ],
  users: [
    'address-book',
    'address-book-o',
    'address-card',
    'address-card-o',
    'id-card',
    'handshake-o'
  ],
  files: [
    'file',
    'file-archive-o',
    'file-audio-o',
    'file-code-o',
    'file-excel-o',
    'file-image-o',
    'file-movie-o',
    'file-o',
    'file-pdf-o',
    'file-photo-o',
    'file-picture-o',
    'file-powerpoint-o',
    'file-sound-o',
    'file-text',
    'file-text-o',
    'file-video-o',
    'file-word-o',
    'file-zip-o',
    'archive'
  ],
  others: [
    'balance-scale',
    'bell',
    'bell-o',
    'barcode',
    'book',
    'braille',
    'bed',
    'beer',
    'briefcase',
    'building-o',
    'building',
    'bullhorn',
    'calculator',
    'calendar',
    'calendar-check-o',
    'calendar-minus-o',
    'calendar-o',
    'calendar-plus-o',
    'calendar-times-o',
    'camera',
    'camera-retro',
    'cart-arrow-down',
    'cart-plus',
    'certificate',
    'check',
    'check-circle',
    'check-circle-o',
    'check-square',
    'clock-o',
    'cloud',
    'coffee',
    'cog',
    'cogs',
    'comment',
    'comment-o',
    'comments',
    'comments-o',
    'compass',
    'cube',
    'database',
    'desktop',
    'envelope',
    'envelope-o',
    'envelope-open',
    'envelope-open-o',
    'flash',
    'flask',
    'folder',
    'folder-o',
    'folder-open',
    'folder-open-o',
    'gavel',
    'gift',
    'globe',
    'graduation-cap',
    'heart',
    'heart-o',
    'heartbeat',
    'history',
    'home',
    'institution',
    'map',
    'map-marker',
    'map-o',
    'map-pin',
    'map-signs',
    'microchip'
  ]
};
