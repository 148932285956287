import { Component, OnInit } from '@angular/core';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {Observable} from 'rxjs';
import {Job} from '../../interfaces/job';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../shared/template-services/alert.service';
import {StatusService} from '../../../shared/services/status.service';
import {AddStatusComponent} from '../../modals/add-status/add-status.component';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit {
  config: DataTableConfig = {
    title: "",
    notFoundText: "No se encontraron estatus",
    hasSearch: true
  };
  status$: Observable<any[]>;

  constructor(private _status: StatusService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.status$ = this._status.getAll();
  }

  openUpdateStatusModal(status: any, statusKey: string) {
    this.modal.show(AddStatusComponent, {
      initialState: {
        status: status,
        update: true
      }
    });
  }

  async deleteStatus(statusKey: string) {
    if (await AlertService.confirm('¿Estás seguro de que deseas eliminar este estatus?', '')) {
      await this._status.delete(statusKey);
      AlertService.toastSuccess('Se eliminó correctamente', '');
    }
  }

  openAddStatusModal() {
    this.modal.show(AddStatusComponent, {
      initialState: {
        update: false
      }
    });
  }
}
