import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-link',
  template: `
    <span *ngFor="let item of splitTextByLinks(text)"
          [ngClass]="{'text-info cursor-pointer' : item.isLink}"
          (click)="openLink(item)">{{item.text}}</span>
  `
})
export class TextLinkComponent implements OnInit {
  @Input() text: string;

  constructor() {
  }

  ngOnInit(): void {
  }

  splitTextByLinks(text: string) {
    const regex = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm;

    const matches = text.match(regex);
    const splitText = text.split(regex);
    const result = [];

    for (let i = 0; i < splitText.length; i++) {
      if (splitText[i]) {
        result.push({
          text: splitText[i],
          isLink: false
        });
      }

      if (matches && matches[i]) {
        result.push({
          text: matches[i],
          isLink: true
        });
      }
    }

    return result;
  }

  extractLink(text: string) {
    const regex = /(http|https|www)\S+/g;
    const matches = text.match(regex);
    return matches[0];
  }

  openLink(item) {
    if (item.isLink) {
      window.open(this.extractLink(item.text.trim()), '_blank');
    }
  }
}
