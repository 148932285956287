import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/internal/operators/first';
import { take } from 'rxjs/internal/operators/take';
import { Sale } from '../../interfaces/sale';
import { SalesBoardService } from '../../services/sales-board.service';

@Component({
  selector: 'app-sale-card',
  templateUrl: './sale-card.component.html',
  styleUrls: ['./sale-card.component.css']
})
export class SaleCardComponent implements OnInit {
  @Input() item: Sale;
  @Input() salesBoardKey: string;
  inactive;
  settingsTime;
  finalDate;
  today;
  finalInactiveDays;

  constructor(private _salesBoard: SalesBoardService,
              private activatedRoute: ActivatedRoute) {
    this.salesBoardKey = this.activatedRoute.snapshot.params['salesBoardKey'];
  }

  async ngOnInit() {
    this.today = new Date().getTime();
    this.settingsTime = await this._salesBoard.getSettings(this.salesBoardKey).pipe(first()).toPromise();    
    this.finalDate = this.item.closeDate + (this.settingsTime[0].inactiveTime * 86400000);   
    this.finalInactiveDays = Math.floor((this.today - this.finalDate) / 86400000);
  }
}
