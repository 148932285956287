import {Component, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ProofOfPayment} from '../../interfaces/proof-of-payment';
import {DomSanitizer} from '@angular/platform-browser';
import {BillService} from '../../../shared/services/bill.service';
import {PrintService} from '../../../shared/services/print.service';
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-proof-of-payment-modal',
  templateUrl: './proof-of-payment-modal.component.html',
  styleUrls: ['./proof-of-payment-modal.component.css']
})
export class ProofOfPaymentModalComponent implements OnInit {
  proofOfPayment: ProofOfPayment;
  isPdf: boolean = true;
  url: any;
  name: any;

  constructor(public modal: BsModalService,
              private sanitizer: DomSanitizer,
              private _bill: BillService) {
  }

  ngOnInit(): void {
    if (this.proofOfPayment.type == 0) this.getUrlPdf(this.proofOfPayment.proofOfPayment);
    this.name = this.proofOfPayment.name;
  }

  update() {
    this._bill.updateProofPayment(this.proofOfPayment.key, {
      name: this.name
    } as ProofOfPayment);
    AlertService.toastSuccess('Guardado exitosamente');
    this.modal.hide();
  }

  getUrlPdf(url: any) {
    this.isPdf = url.includes('pdf');
    if (!this.isPdf) {
      return this.url = url;
    }
    this.url = this.sanitizer.bypassSecurityTrustHtml(`<iframe width="100%" height="915" src="${url}"></iframe>`);
  }

  viewProof() {
    PrintService.printFromHtml(this.proofOfPayment.content);
  }
}
