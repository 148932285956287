import { Component, OnInit } from '@angular/core';
import { IconList } from './utils/icon-list';
import { AlertService } from '../../template-services/alert.service';
import { ShortcutsService } from '../../services/shortcuts.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  selector: 'app-add-shortcut',
  templateUrl: './add-shortcut.component.html',
  styleUrls: ['./add-shortcut.component.css']
})
export class AddShortcutComponent implements OnInit {
  currentUrl: string = '';
  iconList = IconList;
  shortcutLabel: string = '';
  selectedIcon: string = '';
  isEdit: boolean = false;
  id: number;
  modalId: number;

  constructor(private _shortcuts: ShortcutsService,
    private modal: BsModalService,
    private loader: LoadingBarService) {
  }

  ngOnInit(): void {
    this.currentUrl = window.location.href;
  }

  handleIconSelection(icon: string) {
    this.selectedIcon = icon;
  }

  async saveShortcut() {
    if (!this.selectedIcon) {
      AlertService.toastError('Se debe seleccionar un icono');
      return;
    }

    if (!await AlertService.confirm(`¿Está seguro de ${this.isEdit ? 'modificar' : 'agregar'} el acceso directo?`)) return;

    this.loader.start();

    if (this.isEdit) {
      await this._shortcuts.update({
        icon: this.selectedIcon,
        color: 'info',
        label: this.shortcutLabel,
        to: this.currentUrl,
        id: this.id,
        updatedAt: Date.now()
      });
    } else {
      await this._shortcuts.add({
        icon: this.selectedIcon,
        color: 'info',
        label: this.shortcutLabel,
        to: this.currentUrl,
        createdAt: Date.now()
      });
    }

    this._shortcuts.stopAdding();
    this.handleCloseModal();
    this.loader.complete();
    this.loader.stop();
    AlertService.toastSuccess(`Acceso directo ${this.isEdit ? 'modificado' : 'agregado'}`);
  }

  handleCloseModal() {
    console.log(this.modalId)
    this.modal.hide(this.modalId);
  }
}
