import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ProjectService} from '../../../shared/services/project.service';
import {Project} from '../../interfaces/project';
import {UserService} from '../../../shared/services/user.service';
import {ProjectStatusLabel} from '../../labels/projectStatusLabel';
import {Subscription} from 'rxjs';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {ClientService} from '../../services/client.service';
import {CostCenterType} from '../../enums/cost-center-type.enum';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.css']
})
export class AddProjectComponent implements OnInit, OnDestroy {
  projectForm: FormGroup;
  submitted: boolean = false;
  projectType = ProjectStatusLabel;
  categorySubscription: Subscription = new Subscription();
  selectArrayCategory: any = [];
  arrayCategory: any = [];
  projectID: number;
  selectArray: any = [];
  costCenterType = CostCenterType;
  categories: any[] = [];
  clientsSubscription: Subscription = new Subscription();

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _project: ProjectService,
              private _client: ClientService,
              private _user: UserService,
              private _firebase: FirebaseDataService,
              private db: FirebaseDataService) {
    this.projectForm = formBuilder.group({
      name: ['', Validators.required],
      startDate: [this.formatDate(new Date().getTime()), Validators.required],
      endDate: [this.formatDate(new Date().getTime()), Validators.required],
      code: ['', Validators.required],
      status: ['', Validators.required],
      client: ['', Validators.required],
      createdBy: [''],
      creationTime: [new Date().getTime()],
      budgets: [[]],
      trash: [false],
      budget: [''],
      saleValue: [null],
      type: ['', Validators.required]
    });  
  }

  async ngOnInit(): Promise<void> {
    this.setSupervisorReferences();
    this.clientsSubscription = this._client.getAll().subscribe(data => this.selectArray = data);
    this.categorySubscription = this._project.getAllCategories().subscribe(data => {
      this.selectArrayCategory = data.filter(data => !data.subCategory);
      this.categories = data;
    });

    this.projectID = (await this._project.getID()).projectID + 1;
    this.projectForm.patchValue({
      code: `OS${this.projectID}`,
      name: `OS${this.projectID} `
    });

  }

  ngOnDestroy() {
    this.categorySubscription.unsubscribe();
    this.clientsSubscription.unsubscribe();
  }

  private formatDate(date) {
    const deliveryDateDate = new Date(date);
    const format = 'yyyy/MM/dd';
    const locale = 'en-US';
    const zone = 'UTC';
    const formattedDate = formatDate(deliveryDateDate, format, locale, zone);
    return formattedDate.replace(/\//g, '-');
  }

  setSupervisorReferences() {
    this.projectForm.patchValue({createdBy: this._user.getReference(this._user.user.key)});
  }

  get formControls() {
    return this.projectForm.controls;
  }

  async submit() {
    this.submitted = true;
    if (this.projectForm.valid) {
      this.projectForm.removeControl('budget');
      this.setSupervisorReferences();
      if (!!this.projectForm.value.client) {
        let reference = this.db.getReference(`clients/${this.projectForm.value.client.key}`);
        this.projectForm.patchValue({client: reference});
      }
      this._project.add(this.projectForm.value as Project);
      await this._project.updateProjectID(this.projectID);
      this.modal.hide();
    }
  }

  changeBudget(budget, index) {
    let budgetNumber: number = +budget.value;
    this.projectForm.value.budgets[index].totalBudget = budgetNumber;
  }

  addBudget() {
    let index = this.projectForm.value.budgets.findIndex(budget => budget.name == this.projectForm.value.budget.name);
    if (index != -1 || this.projectForm.value.budget == '') return AlertService.toastError('Esta categoría ya fue seleccionada');

    const subCategories = this.categories.filter(category => !!category.subCategory && category.reference.id == this.projectForm.value.budget.key)
      .map(category => ({
        name: category.name,
        reference: this._firebase.getReference(`projectCategories/${category.key}`),
        usedBudget: 0,
        totalBudget: 0
      }));

    this.projectForm.value.budgets.push(...subCategories);

    this.arrayCategory.push(...subCategories);

    this.projectForm.patchValue({budget: ''});
  }
}
