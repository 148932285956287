<mat-tab-group>
  <mat-tab label="Detalles">
    <div class="row p-2">
      <div
        [ngClass]="{
          'col-12': board?.type == boardTypeEnum.PERSONAL,
          'col-7 pr-4': board?.type == boardTypeEnum.TEAM
        }">
        <div class="header">
          <h2 *ngIf="!editingTitle" (click)="editingTitle = true" class="mb-3">
            {{ item.title }}
          </h2>
          <div class="col-md-12" *ngIf="editingTitle || !item.title">
            <input
              type="text"
              class="form-control mb-3"
              [(ngModel)]="item.title"
              (blur)="update('title', item.title)"/>
          </div>
          <div class="d-flex flex-row align-items-end">
            <div class="w-50 mr-2" *ngIf="board?.type == boardTypeEnum.TEAM">
              <mat-form-field class="w-100">
                <mat-label>Asignados</mat-label>
                <mat-select
                  [(ngModel)]="item.members"
                  required
                  (ngModelChange)="update('members', item.members)"
                  multiple
                  [compareWith]="compareMembers"
                  [disabled]="userPermission == userTypeEnum.USER">
                  <mat-select-trigger>
                    <ul class="list-unstyled users-list m-0">
                      <li
                        class="avatar avatar-sm pull-up"
                        *ngFor="let member of item.members">
                        <img
                          class="media-object rounded-circle avatar-img"
                          container="body"
                          [ngbTooltip]="!!member ? member.name : ''"
                          [src]="
                            !!member && !!member.imageUrl
                              ? member.imageUrl
                              : '/assets/images/default.png'
                          "
                          alt="Responsable"/>
                      </li>
                    </ul>
                  </mat-select-trigger>
                  <mat-option
                    *ngFor="let member of team; let i = index"
                    [value]="member">
                    <img
                      src="{{ member.imageUrl }}"
                      class="avatar-img rounded-circle"
                      alt=""/><span class="ml-1">{{ member.name }}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="w-50">
              <div
                class="d-flex flex-row justify-content-center align-items-center">
                <mat-form-field class="w-100">
                  <mat-label>Prioridad</mat-label>
                  <mat-select
                    [(ngModel)]="item.tags"
                    (selectionChange)="changed()"
                    multiple
                    [compareWith]="compareMembers"
                    [disabled]="userPermission == userTypeEnum.USER">
                    <mat-select-trigger>
                      <ul class="list-unstyled users-list m-0">
                        <li
                          class="d-inline-block pull-up mr-1"
                          *ngFor="let tag of item.tags">
                          <div
                            class="badge mr-1 text-white"
                            [style.backgroundColor]="tag.color">
                            {{ tag.name }}
                          </div>
                        </li>
                      </ul>
                    </mat-select-trigger>
                    <mat-option
                      *ngFor="let tag of tags; let i = index"
                      [value]="tag">
                      <div
                        class="badge mr-1"
                        [style.backgroundColor]="tag.color">
                        {{ tag.name }}
                      </div>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="mb-2 d-flex flex-row" *ngIf="!item.isAvailable">
            <div class="justify-content-between">
              <label class="form-label no-wrap"
              >Fecha sugerida del supervisor</label
              >
              <div class="d-flex flex-row">
                <input
                  type="date"
                  placeholder="Seleccione una fecha"
                  class="form-control mr-1"
                  role="button"
                  [disabled]="
                    userPermission == userTypeEnum.USER ||
                    !!item.expiratedDate ||
                    !isdaysLimitValid
                  "
                  [(ngModel)]="supervisorFinishDate"
                  (ngModelChange)="
                    update('supervisorFinishDate', supervisorFinishDate)
                  "/>
                <button
                  class="btn btn-info mr-1"
                  [disabled]="!supervisorFinishDate"
                  (click)="acceptExpiratedDate()"
                  *ngIf="
                    userPermission == userTypeEnum.USER &&
                    !item.expiratedDate &&
                    isdaysLimitValid
                  "
                  ngbTooltip="Aceptar fecha y habilitar tarea">
                  <i class="la la-check-square"></i>
                </button>
              </div>
            </div>
            <div class="align-self-end ml-1">
              <label class="form-label">Fecha sugerida por el usuario</label>
              <div class="d-flex flex-row">
                <input
                  type="date"
                  placeholder="Seleccione una fecha"
                  class="form-control mr-1"
                  role="button"
                  [disabled]="
                    userPermission != userTypeEnum.USER ||
                    !!item.expiratedDate ||
                    !isdaysLimitValid
                  "
                  [(ngModel)]="userFinishDate"
                  (ngModelChange)="update('userFinishDate', userFinishDate)"/>
                <button
                  class="btn btn-info"
                  [disabled]="!userFinishDate"
                  (click)="acceptExpiratedDate()"
                  *ngIf="
                    (userPermission == userTypeEnum.SUPERVISOR ||
                      userPermission == userTypeEnum.ADMIN) &&
                    !item.expiratedDate &&
                    isdaysLimitValid
                  "
                  ngbTooltip="Aceptar fecha y habilitar tarea">
                  <i class="la la-check-square"></i>
                </button>
              </div>
            </div>
          </div>

          <div
            class="alert alert-warning"
            *ngIf="
                    (userPermission == userTypeEnum.SUPERVISOR ||
                      userPermission == userTypeEnum.ADMIN) &&
                    !item.expiratedDate &&
                    !isdaysLimitValid
                  ">
            La fecha límite para aceptar la tarea fue superada, notificar a un administrador para que la habilite.
          </div>

          <div class="mb-2 w-50"
            *ngIf="item.isAvailable || userPermission == userTypeEnum.ADMIN">
            <div class="d-flex place-center d-row mb-1">
              <label class="form-label mr-1">Fecha de expiración</label>
              <div
                class="badge place-center"
                *ngIf="
                  (!!item.agreementType ||
                    (!item.agreementType && item.agreementType == 0)) &&
                  board?.type == boardTypeEnum.TEAM
                "
                [ngClass]="{
                  'agreement-admin':
                    item.agreementType == agreementTypeEnum.ADMIN,
                  'agreement-deal':
                    item.agreementType == agreementTypeEnum.DEAL,
                  'agreement-time': item.agreementType == agreementTypeEnum.TIME
                }">
                {{
                'Establecida por ' +
                taskAgreementTypeLabel[item.agreementType]
                }}
              </div>
            </div>
            <div class="d-flex flex-row mb-2">
              <input
                type="date"
                placeholder="Seleccione una fecha"
                class="form-control mr-1"
                [(ngModel)]="expiratedDate"
                [disabled]="
                  (userPermission == userTypeEnum.USER ||
                    (isdaysLimitValid &&
                      userPermission == userTypeEnum.SUPERVISOR)) &&
                  board?.type == boardTypeEnum.TEAM
                "/>
              <button
                class="btn btn-info"
                (click)="addminAssignExpiratedDate(expiratedDate)"
                *ngIf="
                  userPermission == userTypeEnum.ADMIN ||
                  (!isdaysLimitValid && userPermission != userTypeEnum.USER) ||
                  board?.type == boardTypeEnum.PERSONAL
                "
                [disabled]="!expiratedDate"
                ngbTooltip="Establecer fecha de finalización y habilitar tarea">
                <i class="la la-check-square"></i>
              </button>
            </div>
            <div
              *ngIf="!!item.finishDate"
              class="d-flex flex-row align-items-center max-width">
              <label class="mr-1">La tarea finalizó el:</label>
              <h5>{{ item.finishDate | date: 'dd/MM/yyyy' }}</h5>
              <button
                class="btn btn-primary btn-sm ml-2"
                *ngIf="userPermission >= userTypeEnum.SUPERVISOR"
                (click)="changeFinalDate()">
                Cambiar
              </button>
            </div>
          </div>

          <div class="mt-1 w-50">
            <div>
              <ng-container>
                <ng-select
                  [(ngModel)]="item.project"
                  (ngModelChange)="updateProject()"
                  [items]="selectArray"
                  [searchable]="true"
                  bindLabel="name"
                  appendTo="body"
                  placeholder="Selecciona un proyecto"
                  groupBy="type">
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.name }}
                  </ng-template>
                </ng-select>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="contentItem mt-1">
          <div class="mb-2" *ngIf="item.finalDescription">
            <h4>Observaciones finales</h4>
            <p>{{ item.finalDescription }}</p>
          </div>
          <div>
            <h4>Descripción</h4>
            <textarea
              class="form-control"
              rows="5"
              [(ngModel)]="item.description"
              (blur)="update('description', item.description)"
              [disabled]="
                userPermission == userTypeEnum.USER &&
                board?.type == this.boardTypeEnum.TEAM
              "></textarea>
          </div>

          <h4 class="mt-2" *ngIf="board?.type == boardTypeEnum.TEAM">
            Checklist:
            <button
              class="btn btn-success btn-sm"
              (click)="checklist.addTask()">
              Agregar checklist
            </button>
            <button
              class="btn btn-info btn-sm ml-1"
              (click)="checklist.addExistenTask()">
              Ligar tarea existente
            </button>
          </h4>
          <app-checklist
            [boardKey]="boardKey"
            [task]="item"
            (checkedTask)="notifyCheckedTask()"
            *ngIf="board?.type == boardTypeEnum.TEAM">
          </app-checklist>

          <h4 *ngIf="item.attachments.length > 0">Adjuntos</h4>

          <div class="gallery mb-1">
            <div *ngFor="let url of item.attachments; let i = index">
              <img
                [src]="url.url"
                *ngIf="isImage(url)"
                class="file cursor-pointer"/>

              <video
                width="200px"
                height="200px"
                controls
                class="cursor-pointer file"
                *ngIf="isVideo(url)">
                <source [src]="url.url" type="video/mp4"/>
              </video>

              <div>
                <iframe
                  [src]="url.url | domseguro"
                  height="200px"
                  width="200px"
                  class="file"
                  *ngIf="isPdf(url)"></iframe>
              </div>

              <img
                src="https://firebasestorage.googleapis.com/v0/b/tintech-crm.appspot.com/o/images%2FFile.png?alt=media&token=1c4a7057-2fd9-4e74-a0d7-583083bfb7e7"
                class="w-25 cursor-pointer"
                *ngIf="!isImage(url) && !isVideo(url) && !isPdf(url)"/>

              <div class="flex-row d-flex justify-content-center">
                <a [href]="!!url.url ? url.url : url" target="_blank">
                  <p class="text-break w-100">
                    {{ !!url.fileName ? url.fileName : 'Abrir adjunto' }}
                  </p>
                </a>

                <button
                  type="button"
                  (click)="deleteAttachment(i)"
                  *ngIf="!!url.url"
                  class="btn flex-shrink-1">
                  <i
                    class="feather ft-x"
                    *ngIf="userPermission != userTypeEnum.USER"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="mt-1" *ngFor="let routeFile of files">
            <app-upload-file-storage
              *ngIf="userPermission != userTypeEnum.USER"
              [routeFile]="routeFile"
              [showPreview]="true"
              (imageLoaded)="updateAttachments()"
              fileType="all"
              label="Adjuntar archivo"
              controlName="photoUrl"
              extension="png">
            </app-upload-file-storage>
          </div>
        </div>

        <div class="mr-1">
          <button
            class="btn btn-success float-right mb-2 mt-2"
            *ngIf="
              (item.status == kanbanStatusEnum.IN_PROGRESS ||
                item.status == kanbanStatusEnum.DONE) &&
              (userPermission == userTypeEnum.ADMIN ||
                userPermission == userTypeEnum.SUPERVISOR ||
                board?.type == boardTypeEnum.PERSONAL)
            "
            (click)="completeTask()">
            Completar
          </button>
          <button
            class="btn btn-primary float-right mb-2 mt-2 mr-2"
            *ngIf="
              item.status == kanbanStatusEnum.TODO &&
              (userPermission == userTypeEnum.SUPERVISOR ||
                userPermission == userTypeEnum.ADMIN ||
                board?.type == boardTypeEnum.PERSONAL)
            "
            [disabled]="!item.expiratedDate"
            (click)="startsTask()">
            En proceso
          </button>
          <button
            class="btn btn-primary float-right mb-2 mt-2 mr-2"
            *ngIf="
              userPermission == userTypeEnum.SUPERVISOR ||
              userPermission == userTypeEnum.ADMIN ||
              board?.type == boardTypeEnum.PERSONAL
            "
            (click)="deleteTask()">
            Eliminar
          </button>
          <button
            class="btn btn-outline-primary float-right mr-2 mb-2 mt-2"
            (click)="modal.hide()">
            Cerrar
          </button>
        </div>
      </div>

      <div class="col-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="font-weight-bold">Enlaces</h3>
          <div
            class="cursor-pointer ml-1"
            ngbTooltip="Agregar enlace"
            placement="left"
            (click)="addLink()">
            <i class="la la-plus-circle text-dark"></i>
          </div>
        </div>
        <div
          class="mt-1 d-flex justify-content-between"
          *ngFor="let item of item.links; let i = index">
          <a target="_blank" [href]="item.link">{{ item.title }}</a>
          <i
            ngbTooltip="Eliminar enlace cursor-pointer"
            (click)="deleteLink(i)"
            class="la la-times text-dark"></i>
        </div>

        <div *ngIf="board?.type == boardTypeEnum.TEAM">
          <div class="comments mt-2">
            <h4 class="mb-2">Comentarios</h4>

            <div *ngFor="let comment of comments">
              <div class="d-flex flex-row">
                <img
                  src="{{
                    !!comment.user.imageUrl
                      ? comment.user.imageUrl
                      : '/assets/images/default.png'
                  }}"
                  class="avatar-img rounded-circle"
                  alt=""/>
                <div class="d-flex flex-column">
                  <span class="ml-1 font-weight-bold text-dark">{{
                    comment.user.name
                    }}</span>
                  <span class="ml-1 text-muted text-small font-size-xsmall">{{
                    comment.createdAt | date: 'dd/MM/yyyy HH:mm'
                    }}</span>
                </div>
              </div>
              <a
                *ngIf="
                  comment.comment.includes('www') ||
                  comment.comment.includes('http') ||
                  comment.comment.includes('.com') ||
                  (isRegexMatch == true && !comment.comment.includes(' '))
                "
                href="{{ comment.comment }}"
                target="_blank">
                {{ comment.comment }}
              </a>
              <p
                class="text-dark"
                *ngIf="
                  !comment.comment.includes('www') &&
                  !comment.comment.includes('http') &&
                  !comment.comment.includes('.com')
                ">
                {{ comment.comment }}
              </p>
            </div>
          </div>

          <app-not-found
            title="No hay comentarios"
            size="medium"
            *ngIf="comments.length == 0"></app-not-found>

          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="comment"
              placeholder="Escriba su comentario..."/>
            <div class="input-group-append">
              <button class="btn btn-outline-primary" (click)="sendComment()">
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      size="medium"
      color="#fff"
      type="square-jelly-box"
    ><p style="color: white">Cargando imagen...</p></ngx-spinner
    >
  </mat-tab>

  <mat-tab label="Registros">
    <app-logs [logs$]="logs$"></app-logs>
  </mat-tab>
</mat-tab-group>
