<ng-container *ngFor="let quiz of quizArray; index as indexPosition">
  <ng-container *ngIf="quiz.key == quizPosition?.currentValue">
    <div *ngFor="let question of quiz.questions; index as indexAnswer" class="w-100 d-flex justify-content-center mt-¿3">
      <div class="mt-2 col-md-6" style="width: 500px">
        <h2 class="mb-2">{{question.question}}</h2>

        <ng-container *ngFor="let answer of question.answers; index as indexQuestion">
          <div (click)="selectedIndex(indexPosition, indexAnswer, indexQuestion)" class="option d-flex mb-1 flex-row cursor-pointer"
               [ngClass]="{'selected': answer == question.answers[question.selected]}">
            <div class="option-letter p-1 bg-light font-weight-bold">
              {{indexQuestion + 1}}
            </div>

            <div class="option-text p-1 ml-1 font-weight-bold">{{answer}}</div>
          </div>
        </ng-container>

      </div>
    </div>
  </ng-container>
</ng-container>
