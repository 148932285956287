<div class="modal-wrapper">
  <div class="modal-header mobile-modal-header">
    <h1>Código QR</h1>
    <i (click)="closeModal()" class="la la-close"></i>
  </div>
  <div class="qr-container">
    <ngx-qrcode
      [elementType]="elementType"
      [errorCorrectionLevel]="correctionLevel"
      [value]="value"
      [width]="250"
      cssClass="qr-code"
    ></ngx-qrcode>
  </div>
</div>
