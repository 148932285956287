import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ti-url-viewer',
  templateUrl: './ti-url-viewer.component.html',
  styleUrls: ['./ti-url-viewer.component.css']
})
export class TiUrlViewerComponent implements OnInit {
  url: string;
  fileType: string;
  fileIcon: string;

  constructor() { }

  ngOnInit(): void {
  }

  isImage() {
    return this.fileIcon.includes('png.png') || this.fileIcon.includes('jpg') || this.fileIcon.includes('picture')
  }
}
