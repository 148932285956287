<div class="p-2">
  <h2>Agregar usuarios al proyecto</h2>

  <form [formGroup]="personForm" (ngSubmit)="submit()">
    <div class="row mt-2">
      <div class="col-md-12 col-12">
        <div class="form-group">
          <label>Usuario</label>
          <ng-select formControlName="persons" [items]="persons" [multiple]="true" bindLabel="name"
                     placeholder="">
          </ng-select>
        </div>
      </div>
    </div>

    <div class="modal-footer border-0">
      <button class="btn btn-success add-btn">Agregar</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide()">Cancelar</div>
    </div>
  </form>

</div>
