import {Injectable} from '@angular/core';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PaymentCategory} from '../interfaces/payment-category';
import {Applicant} from '../interfaces/applicant';

@Injectable({
  providedIn: 'root'
})
export class PaymentCategoryService {

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {
  }

  getAll(centerCostKey: string): Observable<PaymentCategory[]> {
    return this.afs.collectionGroup<PaymentCategory>(`paymentCategories`, ref => ref
      .where('trash', '==', false)
      .where('centerCostKey', '==', centerCostKey))
      .snapshotChanges()
      .pipe(map(actions => actions.map((a:any) => {
        return {
          ...a.payload.doc.data(),
          key: a.payload.doc.id,
        }
      })))
  }

  getAllWithoutCostCenter(): Observable<PaymentCategory[]> {
    return this.afs.collectionGroup<PaymentCategory>(`paymentCategories`, ref => ref
      .where('trash', '==', false))
      .snapshotChanges()
      .pipe(map(actions => actions.map((a:any) => {
        return {
          ...a.payload.doc.data(),
          key: a.payload.doc.id,
        }
      })))
  }

  set(costCenterKey: string, paymentCategoryKey: string, paymentCategory: PaymentCategory): void {
    this.afs.doc(`projects/${costCenterKey}/paymentCategories/${paymentCategoryKey}`).set(paymentCategory, {merge: true});
  }

  delete(costCenterKey: string, paymentCategoryKey: string): Promise<void> {
    return this.afs.doc<PaymentCategory>(`projects/${costCenterKey}/paymentCategories/${paymentCategoryKey}`).update({trash: true});
  }

  async update(costCenterKey: string, paymentCategoryKey: string, paymentCategory: PaymentCategory): Promise<void> {
    return await this.afs.doc<PaymentCategory>(`projects/${costCenterKey}/paymentCategories/${paymentCategoryKey}`).update(paymentCategory);
  }
}
