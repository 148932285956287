<m-card [options]="cardOptions">
  <ng-container mCardHeaderTitle> Filtros </ng-container>
  <ng-container mCardBody>
    <div class="row mb-1 mr-md-1">
      <div class="col-md-3 d-flex flex-column justify-content-end">
        <h5>Tablero</h5>
        <ng-select
          [items]="salesBoards"
          bindLabel="name"
          [(ngModel)]="salesBoardSelected">
        </ng-select>
      </div>

      <div class="col-md-3 d-flex flex-column justify-content-end mt-1 mt-md-0">
        <h5>Estatus</h5>
        <ng-select
          [searchable]="false"
          [(ngModel)]="saleStatusSelected"
          [clearable]="false">
          <ng-option [value]="saleStatusEnum.LEAFLET">
            {{ saleStatusLabel[saleStatusEnum.LEAFLET] | titlecase }}
          </ng-option>
          <ng-option [value]="saleStatusEnum.OPPORTUNITY">
            {{ saleStatusLabel[saleStatusEnum.OPPORTUNITY] | titlecase }}
          </ng-option>
          <ng-option [value]="saleStatusEnum.PROPOSAL">
            {{ saleStatusLabel[saleStatusEnum.PROPOSAL] | titlecase }}
          </ng-option>
          <ng-option [value]="saleStatusEnum.HOT">
            {{ saleStatusLabel[saleStatusEnum.HOT] | titlecase }}
          </ng-option>
          <ng-option [value]="saleStatusEnum.EARNED_CLOSURE">
            {{ saleStatusLabel[saleStatusEnum.EARNED_CLOSURE] | titlecase }}
          </ng-option>
          <ng-option [value]="saleStatusEnum.MISSED_CLOSURE">
            {{ saleStatusLabel[saleStatusEnum.MISSED_CLOSURE] | titlecase }}
          </ng-option>
        </ng-select>
      </div>

      <div class="col-xl-6 mt-1 mt-xl-0">
        <div class="d-flex align-items-end">
          <div class="mr-1">
            <h5>Rango</h5>
            <ng-select
              [searchable]="false"
              [(ngModel)]="dateRangeTypeSelected"
              (ngModelChange)="handleDateRangeTypeSelected()"
              [clearable]="false">
              <ng-option [value]="dateRangeType.WEEKLY"> Semanal </ng-option>
              <ng-option [value]="dateRangeType.MONTHLY"> Mensual </ng-option>
              <ng-option [value]="dateRangeType.YEARLY"> Anual </ng-option>
            </ng-select>
          </div>

          <div
            class="animated fadeIn"
            *ngIf="dateRangeTypeSelected == dateRangeType.WEEKLY">
            <h5>Seleccione la semana</h5>
            <input
              type="week"
              class="form-control"
              [(ngModel)]="dateSelected"
              (ngModelChange)="assignStartDateAndFinalDate()" />
          </div>
          <div
            class="animated fadeIn"
            *ngIf="dateRangeTypeSelected == dateRangeType.MONTHLY">
            <h5>Seleccione el mes</h5>
            <input
              type="month"
              class="form-control"
              [(ngModel)]="dateSelected"
              (ngModelChange)="assignStartDateAndFinalDate()" />
          </div>

          <div *ngIf="dateRangeTypeSelected == dateRangeType.YEARLY">
            <h5>Seleccione el año</h5>
            <ng-select
              [searchable]="false"
              [(ngModel)]="dateSelected"
              (ngModelChange)="assignStartDateAndFinalDate()"
              [clearable]="false">
              <ng-option [value]="year" *ngFor="let year of years">
                {{ year }}
              </ng-option>
            </ng-select>
          </div>

          <div class="ml-1">
            <button class="btn btn-danger ml-1" (click)="onSendClick()">
              Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</m-card>

<div class="col-md-12 card" *ngIf="filteredSales.length > 0; else notFound">
  <div class="col-xl-12">
    <div *blockUI="'revenue'; message: 'Cargando'">
      <m-card [options]="chartOptions" (reloadFunction)="reloadSales()">
        <ng-container mCardHeaderTitle> Valor de venta </ng-container>
        <ng-container mCardBody>
          <div class="row mb-1">
            <div class="col-6 col-md-4">
              <h5>
                {{ 'Actual ' + dateRangeTypeLabel[dateRangeTypeSelected] }}
              </h5>
              <h2 class="danger">${{ currentTotalSaleValue | number: '.2' | swapCommasAndDots }}</h2>
            </div>
            <div class="col-6 col-md-4">
              <h5>
                {{ 'Anterior ' + dateRangeTypeLabel[dateRangeTypeSelected] }}
              </h5>
              <h2 class="text-muted">${{ previousTotalSaleValue | number: '.2' | swapCommasAndDots }}</h2>
            </div>
          </div>
          <div class="chartist">
            <x-chartist
              *ngIf="lineArea"
              [data]="lineArea.data"
              [type]="lineArea.type"
              [options]="lineArea.options"
              [responsiveOptions]="lineArea.responsiveOptions"
              [events]="lineArea.events">
            </x-chartist>
          </div>
        </ng-container>
      </m-card>
    </div>
  </div>
</div>

<ng-template #notFound>
  <app-not-found
    size="medium"
    title="No se encotraron ventas registradas"></app-not-found>
</ng-template>
