import {Component, OnInit} from '@angular/core';
import {PurchaseOrderService} from '../../../shared/services/purchase-order.service';
import {PurchaseOrder} from '../purchase-orders/interfaces/purchase-order';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {Observable, of} from 'rxjs';

@Component({
  selector: 'app-purchase-order-articles',
  templateUrl: './purchase-order-articles.component.html',
  styleUrls: ['./purchase-order-articles.component.css'],
})
export class PurchaseOrderArticlesComponent implements OnInit {
  articlesToCome: any[] = [];
  articlesToCome$: Observable<any[]> = new Observable<any[]>();
  config: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No hay artículos para llegar',
    title: '',
    filtersFields: ['description'],
  };

  constructor(private _purchaseOrder: PurchaseOrderService) {}

  ngOnInit(): void {
    this._purchaseOrder.getAll().subscribe((data) => {
      const purchases = this.getPurchasesWithArticlesToCome(data);

      for (let purchase of purchases) {
        this.articlesToCome.push(
          ...purchase.articles.map((article) => ({
            ...article,
            purchaseOrder: purchase,
          }))
        );
      }

      console.log(this.articlesToCome);

      this.articlesToCome$ = of(this.articlesToCome);
    });
  }

  getPurchasesWithArticlesToCome(data: PurchaseOrder[]) {
    return data.filter((purchase) =>
      purchase.articles.some((article) => 'received' in article)
    );
  }

  openArticleDetails($event: any) {
    console.log($event);
  }
}
