<div class="p-2">
  <h2>Nueva categoría</h2>

  <form [formGroup]="categoryForm" (ngSubmit)="submit()">
    <div class="row mt-2">
      <div class="col-md-6">
        <div class="form-group">
          <label>Nombre *</label>
          <input type="text" class="form-control" formControlName="name"
                 placeholder="" [ngClass]="{ 'is-invalid': submitted && formControls.name.errors }"/>
          <small class="form-text text-muted danger invalid-feedback" *ngIf="submitted && formControls.name.errors">
            <div *ngIf=" formControls.name.errors.required">
              El nombre es obligatorio
            </div>
          </small>
        </div>
      </div>
    </div>

    <div class="modal-footer border-0">
      <button class="btn btn-primary add-btn">Agregar</button>
      <div class="btn btn-danger cancel-btn cursor-pointer" (click)="modal.hide()">Cancelar</div>
    </div>
  </form>

</div>
