import {Component, Input, OnInit} from '@angular/core';
import {Warehouse} from '../../../interfaces/warehouse';
import {Article} from '../../../interfaces/article';
import {Observable, Subscription} from 'rxjs';
import {Ticket} from '../../../interfaces/ticket';
import {TicketService} from '../../../../../../shared/services/ticket.service';
import {WarehouseService} from '../../../../../../shared/services/warehouse.service';
import {DataTableConfig} from '../../../../../../shared/interfaces/data-table-config';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {TicketStatus} from '../../../enums/ticket-status.enum';
import {ShowImageComponent} from '../../../../../modals/show-image/show-image.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../../../../shared/template-services/alert.service';
import {ArticleService} from '../../../../../../shared/services/article.service';
import {flatMap, map, take} from 'rxjs/operators';
import {ArticleType} from '../../../enums/article-type.enum';
import {ProjectService} from '../../../../../../shared/services/project.service';
import {UserService} from '../../../../../../shared/services/user.service';
import {LogsService} from '../../../../../services/logs.service';
import {ArticleReturnerQuantityComponent} from '../../../modals/article-returner-quantity/article-returner-quantity.component';
import {User} from '../../../../../../shared/interfaces/user';
import {ArticleTypeLabel} from '../../../labels/article-type.label';
import {Project} from '../../../../../interfaces/project';
import {WarehouseDetailService} from '../../../services/warehouse-detail.service';
import {ExportToCsv} from 'export-to-csv';
import moment from 'moment';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import { UserType } from '../../../../../enums/user-type.enum';

@Component({
  selector: 'app-articles-returned',
  templateUrl: './articles-returned.component.html',
  styleUrls: ['./articles-returned.component.css']
})
export class ArticlesReturnedComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @Input() warehouse: Warehouse;
  @Input() articleReferences: Article[];
  projectSubscription: Subscription = new Subscription();
  userSubscription;
  tickets$: Observable<Ticket[]>;
  tickets: Ticket[];
  ticketSubscription: Subscription;
  warehouseReference: any;
  user: User;
  selectArrayProjects = [];
  selectArrayUsers = [];
  config: DataTableConfig = {
    title: '',
    notFoundText: 'No se encontraron artículos retirados',
    hasSearch: true
  };
  public perfectScrollbarConfig: PerfectScrollbarConfigInterface = {
    suppressScrollY: true
  };
  search: string;
  articles = [];
  articleTypeEnum = ArticleType;
  articleTypeLabel = ArticleTypeLabel;
  articleStatus: any[] = [
    {
      name: 'POR REGRESAR',
      lost: false,
      damaged: false
    },
    {
      name: 'POR REGRESAR CONSUMIBLE',
      lost: false,
      damaged: false,
      consumable: true
    },
    {
      name: 'PERDIDOS',
      lost: true,
      damaged: false
    },
    {
      name: 'DAÑADOS',
      lost: false,
      damaged: true
    }
  ];

  statusSelected: any = {
    name: 'POR REGRESAR',
    lost: false,
    damaged: false
  };

  projectSelected: any = {
    name: '',
    key: ''
  };

  userSelected: any = {
    name: '',
    key: ''
  };

  constructor(
    private _ticket: TicketService,
    private _warehouse: WarehouseService,
    private _article: ArticleService,
    private _project: ProjectService,
    private _user: UserService,
    private _log: LogsService,
    private modal: BsModalService,
    private _warehouseDetail: WarehouseDetailService
  ) {
  }

  async ngOnInit() {
    if (!this.warehouse) {
      this.warehouse = this._warehouseDetail.warehouse;
      this.articleReferences = this._warehouseDetail.articleReferences;
    }

    this.user = this._user.user;
    await this.getWarehouseReference();
    this.getTickets();
    this.getUsers();
    this.getProjects();
  }

  getWarehouseReference() {
    this.warehouseReference = this._warehouse.getReference(this.warehouse.key);
  }

  async getTickets() {
    this.tickets$ = this._ticket
      .getAllNotReturned(this.warehouseReference)
      .pipe(
        flatMap(
          async (tickets) =>
            await Promise.all(
              tickets.map(async (ticket) => {
                const user = await this._user
                  .getSpecificUser2(ticket.user.id)
                  .pipe(take(1))
                  .toPromise();
                let projectName = '';
                if (!!ticket.projects) {
                  projectName = (
                    await this._project
                      .get(ticket.projects.id)
                      .pipe(take(1))
                      .toPromise()
                  ).name;
                }
                return {
                  ...ticket,
                  userName: !user ? '' : user.name,
                  projectName
                };
              })
            )
        )
      );
  }

  private getUsers() {
    this.userSubscription = this._user
      .getAll()
      .pipe(
        map((users) => users.filter((user) => user.type != UserType.ADMIN)),
        map((users) => users.filter((user) => !user.isApplicant)),
        map((users) => users.filter((user) => !user.trash))
      )
      .pipe(
        map((user) =>
          user.map((user) => ({
            name: user.name,
            key: user.key
          }))
        )
      )
      .subscribe((data) => {
        this.selectArrayUsers = data;
      });
  }

  private getProjects() {
    this.projectSubscription = this._project
      .getAll()
      .pipe(
        map((project) =>
          project.map((project) => ({
            name: project.name,
            key: project.key
          }))
        )
      )
      .subscribe((data) => {
        this.selectArrayProjects = data;
      });
  }

  openModalImage(imageUrl: any) {
    this.modal.show(ShowImageComponent, {
      initialState: {
        image: imageUrl
      }
    });
  }

  async addArticleQuantity(
    article,
    ticketKey: string,
    tickets,
    complete = false
  ) {
    const articleDb: any = await this._article
      .get(this.warehouse.key, article.key)
      .pipe(take(1))
      .toPromise();
    let quantity, comment, serialNumberReturned;

    if (!complete) {
      const modalRef = this.modal.show(ArticleReturnerQuantityComponent, {
        initialState: {
          serialNumberCollected: articleDb.serialNumber,
          article: articleDb,
          selectedSerialNumbers: article.serialNumbersSelected
        }
      });

      await this.modal.onHide.pipe(take(1)).toPromise();

      quantity = modalRef.content.quantity;
      comment = modalRef.content.comments;
      serialNumberReturned = modalRef.content.serialNumberReturned;

      if (!!articleDb.serialNumberCollected) {
        serialNumberReturned.forEach((serial) => {
          let indexSerial = articleDb.serialNumberCollected.findIndex(
            (serialNumber) => serialNumber == serial
          );
          articleDb.serialNumberCollected.splice(indexSerial, 1);
        });
      }

      if (!!article.serialNumbersSelected) {
        serialNumberReturned.forEach((serial) => {
          let indexSerial = article.serialNumbersSelected.findIndex(
            (serialNumber) => serialNumber == serial
          );
          article.serialNumbersSelected.splice(indexSerial, 1);
        });
      }
    } else {
      quantity = 0;
      comment = '';

      if (
        !(await AlertService.confirm(
          '¿Estás seguro que deseas marcar este artículo como "No se regresó nada"?'
        ))
      ) {
        return;
      }
    }

    this._article.update(this.warehouse.key, article.key, articleDb);

    if (!(quantity >= 0 && quantity <= article.quantity - article.returned)) {
      return AlertService.error(
        'No puede ingresar una cantidad mayor a la que se puede devolver'
      );
    }
    let ticket = tickets.find((ticket) => ticket.key == ticketKey);

    const indexArticle = ticket.articles.findIndex(
      (articleItem) => articleItem.name == article.name
    );

    ticket.articles[indexArticle].returned = article.returned + quantity;
    ticket.articles[indexArticle].comment = comment;
    if (ticket.articles[indexArticle].type == this.articleTypeEnum.STORABLE) {
      ticket.articles[indexArticle].isComplete =
        ticket.articles[indexArticle].returned ==
        ticket.articles[indexArticle].quantity;
    } else {
      ticket.articles[indexArticle].isComplete = true;
    }

    const indexArticleInComplete = ticket.articles.findIndex(
      (articleItem) =>
        articleItem.type == ArticleType.STORABLE && !articleItem.isComplete
    );

    if (indexArticleInComplete == -1) {
      ticket.status = TicketStatus.RETURNED;
      this._log.add(this.warehouse.key, {
        description: `Ticket ${ticket.ticketID.slice(
          0,
          -3
        )} pasó a status DEVUELTO`
      });
    } else {
      ticket.status = TicketStatus.INCOMPLETE;
      this._log.add(this.warehouse.key, {
        description: `Ticket ${ticket.ticketID.slice(
          0,
          -3
        )} pasó a status INCOMPLETO`
      });
    }

    const index = this.articleReferences.findIndex(
      (articleItem) => articleItem.name == article.name
    );
    await this._article.updateQuantity(
      this.warehouse.key,
      this.articleReferences[index].key,
      this.articleReferences[index].quantity + quantity
    );
    await this._ticket.update(ticket.key, ticket);

    this._warehouse.update(this.warehouse.key, {
      lastUpdate: new Date().getTime()
    } as Warehouse);

    this._log.add(this.warehouse.key, {
      description: `Artículo ${article.name}(${articleDb.internalId}) fue devuelto al almacén`
    });

    if (article.lost || !!article.damaged) {
      this._article.update(this.warehouse.key, article.key, {
        totalQuantity: articleDb.totalQuantity + quantity
      });

      if (article.lost) {
        this._log.add(this.warehouse.key, {
          description: `Artículo ${article.name}(${articleDb.internalId}) fue encontrado y devuelto al almacén`
        });
      } else if (!!article.damaged) {
        this._log.add(this.warehouse.key, {
          description: `Artículo ${article.name}(${articleDb.internalId}) fue reparado y devuelto al almacén`
        });
      }
    }

    let surname = this.user.surnames ? this.user.surnames : '';
    let record = {
      action:
        quantity == 1
          ? `Se regresó un ${article.name} (${article.internalId}) por ${this.user.name} ${surname}`
          : `Se regresaron ${quantity} ${article.name} (${article.internalId}) por ${this.user.name} ${surname}`,
      date: new Date().getTime(),
      trash: false
    };

    if (article.type == ArticleType.CONSUMABLE) {
      let project = await this._project
        .get(ticket.projects.id)
        .pipe(take(1))
        .toPromise();
      project.budgets = project.budgets.map((budget) => {
        if (budget.reference.id != ticket.projectCategory.id) return budget;
        return {
          ...budget,
          usedBudget: budget.usedBudget - article.price * quantity
        };
      });

      this._project.update(ticket.projects.id, {
        budgets: project.budgets
      } as Project);
    }

    await this._log.addRecord(this.warehouse.key, article.key, record);
  }

  async lostArticle(article, ticketKey: string, tickets) {
    let message = await AlertService.message('Escriba un mensaje');
    if (!message) return;

    const indexTicket = tickets.findIndex((x) => x.key == ticketKey);
    let ticket = tickets[indexTicket];

    const indexArticle = ticket.articles.findIndex(
      (x) => x.name == article.name
    );

    ticket.articles[indexArticle].lost = true;
    ticket.articles[indexArticle].message = message;

    await this._ticket.update(ticketKey, ticket);
    const articleDb: any = await this._article
      .get(this.warehouse.key, article.key)
      .pipe(take(1))
      .toPromise();
    this._article.update(this.warehouse.key, article.key, {
      totalQuantity:
        articleDb.totalQuantity - (article.quantity - article.returned)
    });

    this._log.add(this.warehouse.key, {
      description: `Artículo ${article.name}(${article.internalId}) fue tomado como perdido`
    });

    let surname = this.user.surnames ? this.user.surnames : '';
    let record = {
      action:
        article.quantity - article.returned == 1
          ? `Se perdió un ${article.name} (${article.internalId}) por ${this.user.name} ${surname}`
          : `Se perdieron ${article.quantity - article.returned} ${
            article.name
          } (${article.internalId}) por ${this.user.name} ${surname}`,
      date: new Date().getTime(),
      trash: false
    };

    await this._log.addRecord(this.warehouse.key, article.key, record);
  }

  async damageArticle(article, ticketKey: string, tickets) {
    let message = await AlertService.message('Descripción de como está dañado');
    if (!message) return;

    const indexTicket = tickets.findIndex((x) => x.key == ticketKey);
    let ticket = tickets[indexTicket];

    const indexArticle = ticket.articles.findIndex(
      (x) => x.name == article.name
    );

    ticket.articles[indexArticle].damaged = true;
    ticket.articles[indexArticle].message = message;

    await this._ticket.update(ticketKey, ticket);
    const articleDb: any = await this._article
      .get(this.warehouse.key, article.key)
      .pipe(take(1))
      .toPromise();
    this._article.update(this.warehouse.key, article.key, {
      totalQuantity:
        articleDb.totalQuantity - (article.quantity - article.returned)
    });

    this._log.add(this.warehouse.key, {
      description: `Artículo ${article.name}(${article.internalId}) fue tomado como dañado`
    });

    let surname = this.user.surnames ? this.user.surnames : '';
    let record = {
      action:
        article.quantity - article.returned == 1
          ? `Se reportó como dañado un ${article.name} (${article.internalId}) por ${this.user.name} ${surname}`
          : `Se reportaron como dañados ${
            article.quantity - article.returned
          } ${article.name} (${article.internalId}) por ${
            this.user.name
          } ${surname}`,
      date: new Date().getTime(),
      trash: false
    };

    await this._log.addRecord(this.warehouse.key, article.key, record);
  }

  exportArticles() {
    this.blockUI.start('Exportando artículos, podría demorar unos minutos...');
    this.tickets$.pipe(take(1))
      .pipe(
        map((tickets: any) => tickets
          .filter(ticket => !this.userSelected.name || this.userSelected.name == ticket.userName)
          .filter(ticket => !this.projectSelected.name || this.projectSelected.name == ticket.projectName)
        )
      )
      .subscribe((tickets: any) => {
        const options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          filename: `Lista de artículos retirados - ${this.warehouse.name}`,
          title: 'Artículos retirados',
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: false,
          headers: [
            'Ticket',
            'Proyecto',
            'Solicitante',
            'Artículo',
            'Cantidad',
            'Fecha',
          ]
        };

        let articleReport = [];

        for (const ticket of tickets) {
          for (const article of ticket.articles) {
            if ((article.lost == this.statusSelected.lost &&
              !!article.damaged == this.statusSelected.damaged &&
              !article.isComplete &&
              !this.statusSelected.consumable &&
              article.type == this.articleTypeEnum.STORABLE)) {
              articleReport.push({
                ticket: ticket?.ticketID.slice(0, -3),
                project: ticket.projectName,
                user: ticket.userName,
                article: `${article.name} ${this.articleTypeLabel[article.type]}`,
                quantity: article.quantity - article.returned,
                date: moment(ticket?.createdDate).format('dd/MM/yyyy HH:mm'),
              });
            }

            if (article.lost == this.statusSelected.lost &&
              !!article.damaged == this.statusSelected.damaged &&
              !article.isComplete && !!this.statusSelected.consumable &&
              article.type == this.articleTypeEnum.CONSUMABLE) {
              articleReport.push({
                ticket: ticket?.ticketID.slice(0, -3),
                project: ticket.projectName,
                user: ticket.userName,
                article: `${article.name} ${this.articleTypeLabel[article.type]}`,
                quantity: article.quantity - article.returned,
                date: moment(ticket?.createdDate).format('dd/MM/yyyy HH:mm'),
              });
            }
          }
        }

        if (articleReport.length > 0) {
          const csvExporter = new ExportToCsv(options);
          csvExporter.generateCsv(articleReport);
        } else {
          AlertService.toastError('No hay artículos para exportar');
        }

        this.blockUI.stop();
      });
  }

}
