<div class="p-2" *ngIf="article">
  <div class="p-1 row">
    <h3 class="pt-1">Números seriales</h3>
    <div class="ml-auto">
      <div class="btn btn-success mr-1" (click)="addSerial()">Agregar</div>
      <div class="btn btn-danger" (click)="modal.hide()">Cerrar</div>
    </div>
  </div>

  <div class="modal-body">
    <div
      class="col-12 mt-1"
      *ngFor="let serialNumber of article.serialNumber; let index = index">
      <div class="d-flex align-items-center">
        <input
          type="text"
          class="form-control mr-1"
          placeholder=""
          value="{{ serialNumber }}"
          #serial />
        <div
          class="btn-edit d-flex text-success d-flex align-items-center mr-1 cursor-pointer"
          (click)="updateSerial(serial, index)">
          <i class="feather ft-refresh-ccw"></i>
        </div>
        <div
          class="btn-edit text-danger d-flex align-items-center cursor-pointer"
          (click)="removeSerial(serial, index)">
          <i class="feather ft-x"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
  ><p style="color: white">Actualizando número serial...</p></ngx-spinner
>
