<div class="modal-header">
  <h4 class="modal-title pull-left">Rendición</h4>
  <button type="button" class="pull-right btn" (click)="modal.hide()">
    <i class="feather ft-x"></i>
  </button>
</div>
<div class="p-1">
  <form [formGroup]="accountabilityForm">
    <div class="mt-1">
      <label>Nombre</label>
      <input
        type="text"
        class="form-control"
        formControlName="title"
        [ngClass]="{'is-invalid': !!submitted && formControls.title.errors}" />
      <small class="invalid-feedback"> Campo obligatorio </small>
    </div>

    <div class="mt-1">
      <label for="amount">Monto</label>
      <input
        type="number"
        id="amount"
        class="form-control"
        formControlName="amount"
        [ngClass]="{'is-invalid': !!submitted && formControls.amount.errors}" />
      <small class="invalid-feedback"> Campo obligatorio </small>
    </div>

    <div class="mt-1">
      <label>Fondo por rendir</label>
      <ng-select
        formControlName="fundToRender"
        [clearable]="false"
        placeholder="Seleccione un fondo por rendir"
        [items]="fundsToRender"
        bindLabel="title"
        notFoundText="No se encontraron fondos por rendir activos">
      </ng-select>
    </div>

    <div *ngIf="!!fundsToRender[0].pdf" class="w-100 mt-1">
      <div class="card shadow-none">
        <div class="card-body p-0">
          <div class="write-post">
            <div class="col-sm-12 px-0">
              <label>Comprobante del monto entregado</label>
              <div [innerHTML]="url"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-1 d-flex justify-content-end">
      <button
        class="btn btn-success mr-1"
        *ngIf="permission == permissionEnum.USER"
        (click)="submit()">
        Guardar
      </button>
      <button class="btn btn-outline-danger" (click)="modal.hide()">
        Cerrar
      </button>
    </div>
  </form>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box"
  ><p style="color: white">Guardando datos...</p></ngx-spinner
>
