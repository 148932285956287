import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ProjectService} from '../../../shared/services/project.service';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';
import {ProjectCategory} from '../../interfaces/project-category';
import {take} from 'rxjs/operators';
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-add-to-sub-category',
  templateUrl: './add-to-sub-category.component.html',
  styleUrls: ['./add-to-sub-category.component.css']
})
export class AddToSubCategoryComponent implements OnInit {
  categoryForm: FormGroup;
  categories: ProjectCategory[];
  categoryKey: string;
  submitted: boolean = false;
  category: ProjectCategory;

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _project: ProjectService,
              private _firebase: FirebaseDataService) {
    this.categoryForm = formBuilder.group({
      category: ['', Validators.required],
    });
  }

  async ngOnInit() {
    this.categories = await this._project.getAllCategoriesThatAreNotSubCategories().pipe(take(1)).toPromise();
    this.category = await this._project.getCategory(this.categoryKey).pipe(take(1)).toPromise();
  }

  async submit() {
    this.submitted = true;
    if (this.categoryForm.valid) {
      this.category.reference = await this._firebase.getReference(`projectCategories/${this.categoryForm.value.category.key}`);
      this.category.subCategory = true;
      await this._project.updateCategory(this.categoryKey, this.category);
      AlertService.toastSuccess('Se le asignó una categoría con éxito');
      this.modal.hide();
    }
  }
}
