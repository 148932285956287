<div class="content-body" *ngIf="costCenter$ | async as costCenter">
  <div id="user-profile">
    <div class="row">
      <div class="col-12">
        <div class="card profile-with-cover">
          <div class="card-img-top img-fluid bg-cover height-200">
            <img [src]="'assets/images/login-register.jpg'" alt="Sin imagen"
                 class="img-warehouse">
          </div>
          <div class="media profil-cover-details w-100">
            <div class="media-body pt-3 px-2">
              <div class="row">
                <div class="col-3">
                  <h3 class="card-title">Nombre: {{costCenter?.name}}</h3>
                </div>
                <div class="col-9 text-right d-flex justify-content-end">
                  <div class="btn-group float-right ml-2">
                    <div ngbDropdown class="d-inline-block">
                      <button class="btn btn-warning dropdown-toggle"
                              ngbDropdownToggle>Configuración
                      </button>
                      <div ngbDropdownMenu class="open-left">
                        <button class="dropdown-item" (click)="modifyCostCenter(costCenter)">Editar</button>
                        <button class="dropdown-item" (click)="deleteCostCenter(costCenter)">Eliminar proyecto</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <mat-tab-group [(selectedIndex)]="tabIndex">
    <mat-tab label="Pago de empleados">
      <app-employee-payments [costCenterKey]="costCenterKey"></app-employee-payments>
    </mat-tab>

    <mat-tab label="Categorías de pagos">
      <app-payment-categories [costCenterKey]="costCenterKey"></app-payment-categories>
    </mat-tab>

    <mat-tab label="Pagos">
      <app-cost-center-payments [costCenterKey]="costCenterKey"></app-cost-center-payments>
    </mat-tab>

    <mat-tab label="Usuarios">
      <app-persons [projectKey]="costCenterKey"></app-persons>
    </mat-tab>

    <mat-tab label="Tickets">
      <app-tickets [projectKey]="costCenterKey"
                   [isProject]="true"
                   [unknownWarehouse]="true"
                   [articleReferences]="articleReferences"></app-tickets>
    </mat-tab>

    <ng-container *ngIf="permission == permissionEnum.SUPERVISOR || permission == permissionEnum.ADMIN">
      <mat-tab label="Presupuestos">
        <app-budget [project]="costCenter"></app-budget>
      </mat-tab>

      <mat-tab label="Registros">
        <app-logs [logs$]="logs$"></app-logs>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</div>

<app-add-float-button (clickEmmiter)="openEditEmployeePaymentModal()" *ngIf="tabIndex == 0"></app-add-float-button>

<app-add-float-button (clickEmmiter)="openEditPaymentCategoryModal()" *ngIf="tabIndex == 1"></app-add-float-button>

<app-add-float-button (clickEmmiter)="openEditPaymentModal()" *ngIf="tabIndex == 2"></app-add-float-button>

<app-add-float-button (clickEmmiter)="openModalAddPerson()"
                      [@zoomInOnEnter]
                      [@zoomOutOnLeave]
                      *ngIf="tabIndex == 3"></app-add-float-button>


