<div class="p-2">
  <h3 class="mb-2">Agregar contrato para {{applicant.name}} {{applicant.surnames}}</h3>

  <ti-select
    [options]="contractTypes"
    label="Tipo de contrato *"
    [control]="formControls.contractType"
  ></ti-select>

  <ti-select
    [control]="formControls.project"
    label="Proyecto *"
    bindLabel="name"
    [options]="projects"
  ></ti-select>

  <div>
    <label>Fecha de inicio *</label>
    <input
      type="date"
      class="form-control"
      [formControl]="$any(formControls.startDate)"
    >
    <span class="text-danger" *ngIf="submitted && formControls.startDate.errors">Este campo es obligatorio</span>
  </div>

  <div>
    <label class="mt-2">Fecha de final *</label>
    <input
      type="date"
      class="form-control"
      [formControl]="$any(formControls.endDate)"
    >
    <span class="text-danger" *ngIf="submitted && formControls.endDate.errors">Este campo es obligatorio</span>
  </div>

  <div class="d-flex justify-content-end mt-4">
    <button
      class="btn btn-primary mr-2"
      (click)="modal.hide()"
    >
      Cerrar
    </button>
    <button
      class="btn btn-primary"
      (click)="submit()"
    >
      Guardar
    </button>
  </div>
</div>
