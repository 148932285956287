import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ArticlesComponent} from './components/warehouse-details/articles/articles.component';
import {AddArticleComponent} from './modals/add-article/add-article.component';
import {UpdateArticleComponent} from './modals/update-article/update-article.component';
import {AddWarehouseComponent} from './modals/add-warehouse/add-warehouse.component';
import {WarehouseDetailsComponent} from './components/warehouse-details/warehouse-details.component';
import {AddTicketComponent} from './modals/add-ticket/add-ticket.component';
import {TicketsComponent} from './components/warehouse-details/tickets/tickets.component';
import {TicketDetailComponent} from './modals/ticket-detail/ticket-detail.component';
import {UpdateWarehouseComponent} from './modals/update-warehouse/update-warehouse.component';
import {WarehouseSummaryComponent} from './components/warehouse-details/warehouse-summary/warehouse-summary.component';
import {ArticlesReturnedComponent} from './components/warehouse-details/articles-returned/articles-returned.component';
import {PrintArticlesComponent} from './modals/print-articles/print-articles.component';
import {AddArticlesComponent} from './modals/add-articles/add-articles.component';
import {TicketAuthorizationComponent} from './components/warehouse-details/ticket-authorization/ticket-authorization.component';
import {ArticleReturnerQuantityComponent} from './modals/article-returner-quantity/article-returner-quantity.component';
import {SerialsNumberComponent} from './modals/serials-number/serials-number.component';
import {TicketsArticleEntryComponent} from './components/warehouse-details/tickets-article-entry/tickets-article-entry.component';
import {AddTicketEntryArticlesComponent} from './modals/add-ticket-entry-articles/add-ticket-entry-articles.component';
import {TicketEntryDetailsComponent} from './modals/ticket-entry-details/ticket-entry-details.component';
import {SerialsNumbersComponent} from './modals/serials-numbers/serials-numbers.component';
import {MinimumStockComponent} from './components/warehouse-details/minimum-stock/minimum-stock.component';
import {AddSerialNumberComponent} from './modals/add-serial-number/add-serial-number.component';
import {RouterModule} from '@angular/router';
import {WarehousesRoutes} from './warehouses-routing.module';
import {SharedModule} from '../../../shared/shared.module';
import {CategoryNamePipe} from './pipes/category-name.pipe';
import { PermissionPipe } from './pipes/permission.pipe';
import {NgxQRCodeModule} from '@techiediaries/ngx-qrcode';
import {ChartistModule} from 'ng-chartist';
import {ChartsModule} from 'ng2-charts';
import {SwapCommasAndDotsPipe} from '../../pipes/swap-commas-and-dots.pipe';


@NgModule({
  declarations: [
    ArticlesComponent,
    AddArticleComponent,
    UpdateArticleComponent,
    AddWarehouseComponent,
    WarehouseDetailsComponent,
    AddTicketComponent,
    TicketsComponent,
    TicketDetailComponent,
    UpdateWarehouseComponent,
    WarehouseSummaryComponent,
    ArticlesReturnedComponent,
    PrintArticlesComponent,
    AddArticlesComponent,
    TicketAuthorizationComponent,
    ArticleReturnerQuantityComponent,
    SerialsNumberComponent,
    TicketsArticleEntryComponent,
    AddTicketEntryArticlesComponent,
    TicketEntryDetailsComponent,
    SerialsNumbersComponent,
    MinimumStockComponent,
    AddSerialNumberComponent,
    CategoryNamePipe,
    PermissionPipe
  ],
  exports: [
    TicketsComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(WarehousesRoutes),
    SharedModule,
    NgxQRCodeModule,
    ChartistModule,
    ChartsModule
  ]
})
export class WarehousesModule { }
