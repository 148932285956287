import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChange} from '@angular/core';
import {Subscription} from 'rxjs';
import {QuizService} from '../../../shared/services/quiz.service';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit, OnDestroy {
  @Input() quizPosition: any;
  quizSubscription: Subscription = new Subscription();
  @Output() eventQuiz: EventEmitter<any> = new EventEmitter<any>();
  quizArray: any = [];
  quiz: [];

  constructor(private _quiz: QuizService) {
  }

  ngOnInit(): void {
    this.quizSubscription = this._quiz.getAll().subscribe(data => {
      this.quizArray = data;
    });
  }

  ngOnDestroy(): void {
    this.quizSubscription.unsubscribe();
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    let change: SimpleChange = changes['quizPosition'];
    this.quizPosition = change;
  }

  selectedIndex(indexPosition: number, indexAnswer: number, indexQuestion: number) {
    this.quizArray[indexPosition].questions[indexAnswer].selected = indexQuestion;
    this.quiz = this.quizArray[indexPosition].questions;
    this.eventQuiz.emit(this.quiz);
  }
}
