import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {EmployeePaymentService} from '../../services/employee-payment.service';
import {FirebaseDataService} from '../../../shared/template-services/firebase-data.service';
import {PaymentCategory} from '../../interfaces/payment-category';
import {PaymentCategoryService} from '../../services/payment-category.service';

@Component({
  selector: 'app-payment-category-modal',
  templateUrl: './payment-category-modal.component.html',
  styleUrls: ['./payment-category-modal.component.css']
})
export class PaymentCategoryModalComponent implements OnInit {
  paymentCategory: PaymentCategory = {key: ''} as PaymentCategory;
  paymentCategoryForm: FormGroup;
  submitted: boolean = false;
  isUpdate: boolean = false;
  costCenterKey: string;

  constructor(private formBuilder: FormBuilder,
              public modal: BsModalRef,
              private _paymentCategory: PaymentCategoryService,
              private _firebase: FirebaseDataService) {
    this.paymentCategoryForm = formBuilder.group({
      name: ['', Validators.required],
      createdAt: [''],
      trash: [false],
      centerCostKey: ['']
    });
  }

  ngOnInit(): void {
    this.paymentCategoryForm.patchValue({centerCostKey: this.costCenterKey});
    if (this.isUpdate) {
      this.paymentCategoryForm.patchValue(this.paymentCategory);
    }
  }

  get formControls() {
    return this.paymentCategoryForm.controls;
  }

  async submit() {
    this.submitted = true;
    if (this.paymentCategoryForm.valid) {

      if (!this.isUpdate) {
        this.paymentCategory.key = this._firebase.createID();
        this.paymentCategoryForm.patchValue({createdAt: new Date().getTime()});
      }

      await this._paymentCategory.set(this.costCenterKey, this.paymentCategory.key, this.paymentCategoryForm.value);

      this.modal.hide();
    }
  }
}
