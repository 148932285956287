import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ArticleService } from '../../../../../../shared/services/article.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Article } from '../../../interfaces/article';
import { AlertService } from '../../../../../../shared/template-services/alert.service';
import { UpdateArticleComponent } from '../../../modals/update-article/update-article.component';
import { DataTableConfig } from '../../../../../../shared/interfaces/data-table-config';
import { ShowImageComponent } from '../../../../../modals/show-image/show-image.component';
import { ArticleTypeLabel } from '../../../labels/article-type.label';
import { Warehouse } from '../../../interfaces/warehouse';
import { WarehouseService } from '../../../../../../shared/services/warehouse.service';
import { DatePipe } from '@angular/common';
import { first, map, take } from 'rxjs/operators';
import { UserType } from '../../../../../enums/user-type.enum';
import { UserService } from '../../../../../../shared/services/user.service';
import { SerialsNumberComponent } from '../../../modals/serials-number/serials-number.component';
import { WarehouseDetailService } from '../../../services/warehouse-detail.service';
import _ from 'lodash';
import { ObjectService } from '../../../../../../shared/template-services/object.service';
import { PermissionService } from '../../../services/permission.service';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit, OnDestroy {
  @Input() warehouseKey: string;
  @Input() warehouse: Warehouse;
  submitExcel: boolean;
  articles$: Observable<Article[]>;
  articlesSubscription: Subscription;
  articlesFiltered$: Observable<Article[]>;
  config: DataTableConfig = {
    title: '',
    notFoundText: 'No se encontraron artículos',
    hasSearch: true,
    exportCallback: this.decorateArticlesExport.bind(this),
    excelFileName: 'Artículos',
    columnsToDelete: ['Imagen'],
    removeInfiniteScroll: true
  };
  articleTypeLabel = ArticleTypeLabel;
  categories: any[] = [];
  serialsNumber: any[] = [];
  permission: UserType;
  permissionEnum = UserType;
  articlesSelected: Article[] = [];
  submitExcelSubscription: Subscription = new Subscription();
  locations: string[] = [];
  categorySelected: any;
  locationSelected: string;
  userType = UserType;

  constructor(
    private _article: ArticleService,
    private modal: BsModalService,
    public _user: UserService,
    private _warehouse: WarehouseService,
    private datePipe: DatePipe,
    private _warehouseDetail: WarehouseDetailService,
    public _permission: PermissionService
  ) {
  }

  ngOnInit(): void {
    if (!this.warehouseKey) {
      this.warehouseKey = this._warehouseDetail.warehouseKey;
      this.warehouse = this._warehouseDetail.warehouse;
    }

    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'BODEGAS'
    ).permission;
    this.articles$ = this._article.getAllByWarehouse(this.warehouseKey)
      .pipe(
        map((articles) => _.orderBy(articles, ['name'], ['asc']))
      );

    this.listenForArticles(this.articles$);

    if (!!this.warehouse.lastUpdate) {
      this.config.title = `Ultima actualización ${this.datePipe.transform(
        this.warehouse.lastUpdate,
        'dd/MM/yyyy HH:mm'
      )}`;
    }

    this.articlesFiltered$ = this.articles$;
    this.submitExcelSubscription = this._warehouseDetail.submitExcel.subscribe(
      (data) => (this.submitExcel = data)
    );
  }

  ngOnDestroy() {
    this.submitExcelSubscription.unsubscribe();
    this.articlesSubscription.unsubscribe();
  }

  listenForArticles(articles$: Observable<Article[]>) {
    this.articlesSubscription = articles$.subscribe(articles => {
      this.loadCategoriesFromArticles(articles);
      this.loadLocationsFromArticles(articles);
    });
  }

  openUpdateArticleModal(article, articleKey) {
    if (!(this._permission.hasUserType(UserType.SUPERVISOR) || this._permission.hasUserType(UserType.ADMIN))) return;

    this.modal.show(UpdateArticleComponent, {
      initialState: {
        article: { ...article },
        articleKey: articleKey,
        warehouseKey: this.warehouseKey
      },
      class: 'modal-lg'
    });
  }

  async deleteArticle(articleKey: any) {
    if (
      await AlertService.confirm(
        '¿Estás seguro de que deseas eliminar este articulo?',
        ''
      )
    ) {
      await this._article.delete(this.warehouseKey, articleKey);
      AlertService.toastSuccess('Se eliminó correctamente');
      if (this.articlesSelected.length > 0) {
        this.articlesSelected = this.articlesSelected.filter(
          (articleSelected) => articleSelected.key != articleKey
        );
      }
    }
  }

  openModalImage(imageUrl: any) {
    this.modal.show(ShowImageComponent, {
      initialState: {
        image: imageUrl
      }
    });
  }

  async addSerialNumber(quantity: number) {
    for (let i = 0; i < quantity; i++) {
      const serialNumber = await AlertService.inputValidator(
        `Número de serie ${i + 1} de ${quantity}`
      );
      if (!!serialNumber) {
        this.serialsNumber.push({ serialNumber: serialNumber });
      }
    }
  }

  private loadCategoriesFromArticles(articles: Article[]) {
    const categories = this.extractArticlesPropertyValue(articles, 'categories');
    this.categories = _.orderBy(_.uniqBy(categories, category => category.key), 'name');
  }

  private loadLocationsFromArticles(articles: Article[]) {
    const locations = this.extractArticlesPropertyValue(articles, 'location');
    this.locations = _.uniq(locations);
  }

  extractArticlesPropertyValue(articles: Article[], property: string) {
    return articles.map(article => article[property]).reduce<any[]>((acc, el) => acc.concat(el), []);
  }

  filterArticles() {
    if (!this.categorySelected && !this.locationSelected) {
      this.articlesFiltered$ = this.articles$;
      return;
    }

    this.articlesFiltered$ = this.articles$.pipe(map(articles => articles.filter(article => (
      (
        !this.categorySelected || article.categories.some(category => category.key == this.categorySelected.key)
      ) &&
      (
        !this.locationSelected || article.location == this.locationSelected
      )
    ))));
  }

  clearFilter() {
    this.categorySelected = null;
    this.locationSelected = null;
    this.articlesFiltered$ = this.articles$;
  }

  async addSerials(article: Article) {
    if (
      await AlertService.confirm(
        '¿Estás seguro de que deseas habilitar números de serie en este artículo?',
        'Verifica que no tengas tickets activos con este artículo'
      )
    ) {
      this.serialsNumber = [];
      await this.addSerialNumber(article.quantity);
      let serials = [];

      for (let serial of this.serialsNumber) {
        serials.push(serial.serialNumber);
      }

      this._article.update(this.warehouseKey, article.key, {
        serialNumber: serials
      });
    }
  }

  async deleteSerials(article: Article) {
    if (
      await AlertService.confirm(
        '¿Estás seguro que deseas eliminar los números de serie de este artículo?',
        'Verifica que no tengas tickets activos con este artículo'
      )
    ) {
      this._article.update(this.warehouseKey, article.key, { serialNumber: [] });
      AlertService.toastSuccess('Se han eliminados todos los números de serie');
    }
  }

  editSerials(article: any) {
    const modalRef = this.modal.show(SerialsNumberComponent, {
      initialState: {
        articleKey: article.key,
        warehouseKey: this.warehouseKey
      },
      animated: true,
      backdrop: 'static'
    });
    modalRef.onHide.pipe(take(1)).subscribe(() => {
    });
  }

  async dropInventory(article: Article) {
    let articlesQuantityPermit = 0;

    !!article.serialNumber
      ? (articlesQuantityPermit =
        article.quantity - article.serialNumber.length)
      : (articlesQuantityPermit = article.quantity);

    if (article.quantity == 0) {
      return AlertService.toastError(
        'No hay artículos disponibles para poder eliminar.'
      );
    }
    if (articlesQuantityPermit <= 0) {
      return AlertService.toastError('Se tiene que eliminar números seriales.');
    }

    const quantity = await AlertService.input(
      `Eliminar inventario del artículo ${article.name}`
    );

    if (+quantity > articlesQuantityPermit) {
      return AlertService.toastError(
        `La cantidad disponible a eliminar es ${articlesQuantityPermit}`
      );
    }

    this._article.update(article.warehouseKey, article.key, {
      quantity: article.quantity - +quantity,
      totalQuantity: article.totalQuantity - +quantity
    });

    await this._warehouse.update(this.warehouseKey, {
      lastUpdate: new Date().getTime()
    } as Warehouse);

    AlertService.toastSuccess(`Se actualizó el inventario con éxito`);
  }

  selectArticle(article: Article) {
    if (
      this.articlesSelected.some(
        (articleSelected) => articleSelected.key == article.key
      )
    ) {
      this.articlesSelected = this.articlesSelected.filter(
        (articleSelected) => articleSelected.key != article.key
      );
    } else {
      this.articlesSelected = [...this.articlesSelected, article];
    }
  }

  validateArticleSelected(article: Article) {
    if (this.articlesSelected.length == 0) return false;
    return this.articlesSelected.some(
      (articleSelected) => articleSelected.key == article.key
    );
  }

  async deleteSelecteds() {
    if (
      !(await AlertService.confirm(
        '¿Estás seguro de que deseas eliminar los artículos seleccionados?'
      ))
    ) {
      return;
    }
    for (let article of this.articlesSelected) {
      await this._article.delete(this.warehouseKey, article.key);
    }
    this.articlesSelected = [];
    AlertService.toastSuccess(
      'Los artículos seleccionados se eliminaron correctamente'
    );
  }

  async selectAll() {
    if (this.articlesSelected.length > 0) this.articlesSelected = [];
    this.articlesSelected = await this.articlesFiltered$
      .pipe(first())
      .toPromise();
  }

  decorateArticlesExport(rows: any[]) {
    return ObjectService.replaceUndefined(rows.map(row => ({
      internalId: row.internalId,
      name: row.name,
      categories: row.categories[0].name,
      price: row.price,
      location: row.location,
      type: this.articleTypeLabel[row?.type],
      quantity: row.quantity
    })));
  }

}
