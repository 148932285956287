import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {TaskOverhaul} from '../enums/task-overhaul';
import {TaskOverhaulLabel} from '../labels/task-overhaul.label';
import {Subscription} from 'rxjs';
import {ChecklistService} from '../services/checklist.service';
import {KanbanStatus} from '../enums/kanban-status.enum';
import {BoardTypeEnum} from '../../../enums/board-type.enum';
import {Task} from '../interfaces/task';
import moment from 'moment';

@Component({
  selector: 'app-kanban-item',
  templateUrl: './kanban-item.component.html',
  styleUrls: ['./kanban-item.component.css']
})
export class KanbanItemComponent implements OnInit, OnDestroy {
  @Input() item: Task;
  @Input() boardKey: string;
  @Input() tasks;
  @Input() userPermission: number;
  @Input() boardType: number = BoardTypeEnum.TEAM;
  @Output() taskClicked: EventEmitter<Task> = new EventEmitter<Task>();
  taskOverhaulEnum = TaskOverhaul;
  taskOverhaulLabel = TaskOverhaulLabel;
  checklistSubscription: Subscription = new Subscription();
  totalProgress = 0;
  checkedTasks: number = 0;
  totalTasks: number = 0;
  hasChecklist: boolean = true;
  boardTypeEnum = BoardTypeEnum;
  kanbanStatus = KanbanStatus;

  constructor(private _checklist: ChecklistService) {}

  ngOnInit(): void {
    this.checklistSubscription = this._checklist
      .getAll(this.boardKey, this.item.key)
      .subscribe(async (data) => {
        if (data.length == 0) return (this.hasChecklist = false);

        this.checkedTasks = data.filter(
          (item) =>
            item.checked ||
            (!!item.task &&
              this.tasks.find((task) => task.key == item.task.id).status ==
                KanbanStatus.DONE)
        ).length;

        this.totalTasks = data.length;

        if (this.checkedTasks == 0) return (this.totalProgress = 0);

        this.totalProgress = (this.checkedTasks / this.totalTasks) * 100;
      });
  }

  onTaskClicked() {
    this.taskClicked.emit(this.item);
  }

  ngOnDestroy() {
    this.checklistSubscription.unsubscribe();
  }

  isExpirated(item) {
    return (
      item.isExpirated ||
      moment(item.expiratedDate).isBefore(moment().startOf('day'))
    );
  }

  doneAfterExpiratedDate(item) {    
    return moment(item.expiratedDate) < moment(item.finishDate);
  }

  unfinishedAndExpiredTask(item) {
    return item.isExpirated && (item.status == this.kanbanStatus.TODO ||
       item.status == this.kanbanStatus.IN_PROGRESS)
  }

  finishedAndExpiredTask(item) {
    return !!item.finishDate && item.status == this.kanbanStatus.DONE
  }
}
