<div class="col-xl-12 col-lg-12 mt-2" *ngIf="!isAll">
  <div class="card">
    <div class="card-content">
      <div class="card-body">
        <div (change)="changeEntryTicketsStatus()">
          <div class="d-inline-block custom-control custom-radio mr-1">
            <input
              type="radio"
              class="custom-control-input bg-primary"
              [(ngModel)]="myRadio"
              [value]="EntryTicketsStatus.DRAFT"
              name="colorRadio"
              id="colorRadio1" />
            <label class="custom-control-label" for="colorRadio1"
              >BORRADORES</label
            >
          </div>
          <div class="d-inline-block custom-control custom-radio mr-1">
            <input
              type="radio"
              class="custom-control-input bg-primary"
              [(ngModel)]="myRadio"
              [value]="EntryTicketsStatus.GENERATED"
              name="colorRadio"
              id="colorRadio2" />
            <label class="custom-control-label" for="colorRadio2"
              >GENERADOS</label
            >
          </div>
          <div class="d-inline-block custom-control custom-radio mr-1">
            <input
              type="radio"
              class="custom-control-input bg-info"
              [(ngModel)]="myRadio"
              [value]="EntryTicketsStatus.AUTHORIZED"
              name="colorRadio"
              id="colorRadio3" />
            <label class="custom-control-label" for="colorRadio3"
              >AUTORIZADOS</label
            >
          </div>
          <div class="d-inline-block custom-control custom-radio mr-1">
            <input
              type="radio"
              class="custom-control-input bg-info"
              [(ngModel)]="myRadio"
              [value]="EntryTicketsStatus.ADDED"
              name="colorRadio"
              id="colorRadio7" />
            <label class="custom-control-label" for="colorRadio7"
              >INGRESADOS</label
            >
          </div>
          <div class="d-inline-block custom-control custom-radio mr-1">
            <input
              type="radio"
              class="custom-control-input bg-warning"
              [(ngModel)]="myRadio"
              [value]="99"
              name="colorRadio"
              id="all" />
            <label class="custom-control-label" for="all">TODOS</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-datatable
  [rows$]="ticketsFiltered$"
  [config]="config"
  (rowSelected)="openTicketDetail($event)">
  <app-datatable-column title="ID">
    <ng-template let-row #column>
      {{ row?.ticketID | slice: 0:-3 }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Bodega" *ngIf="isProject">
    <ng-template let-row #column>
      <span>{{ (row?.warehouse | doc | async)?.name }}</span>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Valor de ticket">
    <ng-template let-row #column>
      {{ row?.total | currency: 'USD':'symbol':'1.0-0' }}
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Solicitante">
    <ng-template let-row #column>
      <span>{{ usersIndexed[row?.user.id]?.name }}</span>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Supervisor">
    <ng-template let-row #column>
      <span>{{
        !!row?.supervisor
          ? usersIndexed[row?.supervisor.id]?.name
          : 'No registrado'
      }}</span>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="Ultima actualización">
    <ng-template let-row #column>
      <ng-container *ngIf="row?.lastUpdate">
        {{ row?.lastUpdate | date: 'dd/MM/yyyy HH:mm' }}
      </ng-container>
      <ng-container *ngIf="!row?.lastUpdate">
        {{ row?.createdDate | date: 'dd/MM/yyyy HH:mm' }}
      </ng-container>
    </ng-template>
  </app-datatable-column>

  <app-datatable-column title="">
    <ng-template let-row #column>
      <button class="btn btn-sm round" [ngClass]="getStatusClass(row.status)">
        {{ EntryTicketsStatusLabel[row.status] }}
      </button>
    </ng-template>
  </app-datatable-column>
</app-datatable>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="square-jelly-box">
  <p style="color: white">{{ messageLoad }}</p>
</ngx-spinner>
