import {AccountabilityStatusEnum} from '../enums/accountability-status.enum';

export const AccountabilityStatusLabel = {
  [AccountabilityStatusEnum.GENERATED]: 'En revisión por finanzas',
  [AccountabilityStatusEnum.SUPERVISION]: 'En aprobación gerencia',
  [AccountabilityStatusEnum.REVIEW]: 'En Revisión',
  [AccountabilityStatusEnum.AUTHORIZED]: 'Autorizado',
  [AccountabilityStatusEnum.CLOSED]: 'Cerrado',
  [AccountabilityStatusEnum.CANCELLED]: 'Cancelado',
  [AccountabilityStatusEnum.REJECTED]: 'Rechazado',
  [AccountabilityStatusEnum.CLOSED_COLSAN]: 'Favor a colsan',
  [AccountabilityStatusEnum.CLOSED_USER]: 'Favor a usuario',
  [AccountabilityStatusEnum.PAID_COLSAN]: 'Pagado',
  [AccountabilityStatusEnum.PAID_USER]: 'Pagado'
};
