<div class="d-flex justify-content-start mt-2 flex-wrap">
  <div *ngFor="let saleBoard of salesBoards$ | async"
       (click)="goToSelectedBoard(saleBoard)"
       class="pr-1 cursor-pointer sale-board-card">
    <div class="card position-relative"
         [style.background-color]="!!saleBoard.bgColor ? saleBoard.bgColor : '#3498db'">
      <div class="config">
        <button mat-icon-button
                (click)="$event.stopPropagation()"
                [matMenuTriggerFor]="menu">
          <i class="la la-ellipsis-v text-white"></i>
        </button>
      </div>

      <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
        <button mat-menu-item
                class="normal-text mt-0"
                (click)="showEditBoardModal(saleBoard, $event)">
          <span>Editar</span>
        </button>
        <button mat-menu-item
                class="normal-text"
                (click)="deleteBoard(saleBoard, $event)">
          <span>Eliminar</span>
        </button>
      </mat-menu>

      <div class="card-body row">
        <div class="col-9">
          <h4 class="text-white mb-0">{{ saleBoard.name }}</h4>

          <h6 class="text-white text-bold mt-1">Usuarios</h6>
          <ul class="list-unstyled users-list m-0">
            <li class="avatar avatar-xs pull-up"
                *ngFor="let user of saleBoard.users">
              <img class="media-object rounded-circle"
                   container="body"
                   (click)="goToUserDetails(user.reference.id)"
                   [ngbTooltip]="user.name"
                   (error)="$event.target.src = '/assets/images/default.png'"
                   [src]="!!user.imageUrl ? user.imageUrl : '/assets/images/default.png'"
                   alt="Supervisor" />
            </li>
          </ul>

          <h6 class="text-white text-bold mt-1">Supervisores</h6>
          <ul class="list-unstyled users-list m-0">
            <li class="avatar avatar-xs pull-up"
                *ngFor="let user of saleBoard.supervisors">
              <img class="media-object rounded-circle"
                   container="body"
                   (click)="goToUserDetails(user.reference.id)"
                   [ngbTooltip]="user.name"
                   (error)="$event.target.src = '/assets/images/default.png'"
                   [src]="!!user.imageUrl ? user.imageUrl : '/assets/images/default.png'"
                   alt="Supervisor" />
            </li>
          </ul>
          <p class="card-text text-white mt-1">{{ saleBoard.description }}</p>
        </div>

        <div class="col-3 d-flex align-items-center">
          <i class="{{!!saleBoard.icon ? saleBoard.icon : 'icon-notebook'}} text-white font-large-2"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<app-add-float-button
  *ngIf="
    validateUserPermission(userTypeEnum.ADMIN) || validateUserPermission(userTypeEnum.SUPERVISOR)
  "
  (clickEmmiter)="showAddSalesBoardModal()"
></app-add-float-button>
